import React from "react";
import { useUnityBuildStore } from "../../states/store";
import { AssemblyStyle, SVGShape } from "./SVGShape";
import { useStore } from "zustand";
import { Point } from "../../types/Point";
import { TRANSPARENT_COLOR } from "../../utils/constants";
import { TakeoffAssembly } from "../../types/AssemblyItemType";
import { useAssemblyStore } from "../../states/AssemblyStore";

export interface SVGPointsProps {
  cursorPositionRef: any;
}

export const SVGPoints: React.FC<SVGPointsProps> = ({ cursorPositionRef }) => {

  const filteredCountedAssemblies = useStore(useUnityBuildStore).filteredCountedAssemblies;
  const isDragging = useStore(useUnityBuildStore).isDragging;
  const isEditingPointBorderColor = useStore(useUnityBuildStore).isEditingPointBorderColor;
  const isEditingPointFillColor = useStore(useUnityBuildStore).isEditingPointFillColor;
  const isEditingLineColor = useStore(useUnityBuildStore).isEditingLineColor;
  const selectedObject = useStore(useUnityBuildStore).selectedObject;
  const setIsEditingPoint = useStore(useUnityBuildStore).setIsEditingPoint;

  const { currentTakeoffAssembly } = useStore(useAssemblyStore);

  return (
    <>
      {selectedObject &&
        filteredCountedAssemblies[0]?.assembliesList?.map(
          (assembly: TakeoffAssembly, assemblyIndex: number) =>
            assembly.pointsList?.map((point: Point, index: any) => {
              const isFaint = point.isincludedincount === false;

              const assemblyStyle: AssemblyStyle = {
                pointtype: assembly?.pointtype || "circle",
                pointsize: Number(assembly?.pointsize) || 5,
                pointbordercolor: isFaint
                  ? TRANSPARENT_COLOR
                  : assembly?.pointbordercolor,
                pointfillcolor: isFaint
                  ? TRANSPARENT_COLOR
                  : assembly?.pointfillcolor || "red",
                linedasharray: assembly?.linedasharray,
                linesize: Number(assembly?.linesize) || 3,
                linecolor: assembly?.linecolor || "red",
                verticallength: assembly?.pointsList[0].verticallength,
                isselected:
                  assembly.assemblyid === currentTakeoffAssembly?.assemblyid,
                fillopacity: .5,
              };

              return (
                <SVGShape
                  key={point.pointid}
                  point={point}
                  pointIndex={index}
                  assemblyIndex={assemblyIndex}
                  assemblyId={assembly.assemblyid}
                  assemblyStyle={assemblyStyle}
                  cursorPositionRef={cursorPositionRef}
                  verticalLength={point.verticallength || 0}
                />
              );
            })
        )}

      {/* Render shapes for the selected assembly's points */}
      {!isDragging &&
        !isEditingPointFillColor &&
        !isEditingPointBorderColor &&
        !isEditingLineColor &&
        currentTakeoffAssembly?.pointsList?.map((point: any, index: any) => {
          const isFaint = point.isincludedincount === false;
          const halfSize = currentTakeoffAssembly.pointsize
            ? Number(currentTakeoffAssembly.pointsize) / 2
            : 5;

          return currentTakeoffAssembly.pointtype === "circle" ? (
            <circle
              key={`selected-${index}-${point.x}-${point.y}`}
              cx={point.x}
              cy={point.y}
              r={currentTakeoffAssembly.pointsize || 5}
              fillOpacity={currentTakeoffAssembly.fillopacity || .5}
              fill={
                isFaint
                  ? TRANSPARENT_COLOR
                  : currentTakeoffAssembly.pointfillcolor || "red"
              }
              stroke={
                isFaint
                  ? TRANSPARENT_COLOR
                  : currentTakeoffAssembly.pointbordercolor || "black"
              }
              onContextMenu={(e) => {
                e.preventDefault();
                setIsEditingPoint({
                  isEditingPoint: true,
                  x: e.clientX,
                  y: e.clientY,
                  pointIndex: index, // This is the "pointIndex"
                  lineIndex: null,
                });
              }}
            />
          ) : (
            <rect
              key={`selected-${index}-${point.x}-${point.y}`}
              x={point.x - halfSize}
              y={point.y - halfSize}
              width={currentTakeoffAssembly?.pointsize || 10}
              height={currentTakeoffAssembly?.pointsize || 10}
              fillOpacity={currentTakeoffAssembly.fillopacity || .5}
              fill={
                isFaint
                  ? TRANSPARENT_COLOR
                  : currentTakeoffAssembly?.pointfillcolor || "red"
              }
              stroke={
                isFaint
                  ? TRANSPARENT_COLOR
                  : currentTakeoffAssembly?.pointbordercolor || "black"
              }
            />
          );
        })}
    </>
  );
};
