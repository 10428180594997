/**
 * @fileoverview gRPC-Web generated client stub for estimateAssembly
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: estimateAssembly.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as estimateAssembly_pb from './estimateAssembly_pb'; // proto import: "estimateAssembly.proto"


export class EstimateAssemblyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateEstimateAssembly = new grpcWeb.MethodDescriptor(
    '/estimateAssembly.EstimateAssemblyService/CreateEstimateAssembly',
    grpcWeb.MethodType.UNARY,
    estimateAssembly_pb.CreateEstimateAssemblyRequest,
    estimateAssembly_pb.CreateEstimateAssemblyResponse,
    (request: estimateAssembly_pb.CreateEstimateAssemblyRequest) => {
      return request.serializeBinary();
    },
    estimateAssembly_pb.CreateEstimateAssemblyResponse.deserializeBinary
  );

  createEstimateAssembly(
    request: estimateAssembly_pb.CreateEstimateAssemblyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateAssembly_pb.CreateEstimateAssemblyResponse>;

  createEstimateAssembly(
    request: estimateAssembly_pb.CreateEstimateAssemblyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateAssembly_pb.CreateEstimateAssemblyResponse) => void): grpcWeb.ClientReadableStream<estimateAssembly_pb.CreateEstimateAssemblyResponse>;

  createEstimateAssembly(
    request: estimateAssembly_pb.CreateEstimateAssemblyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateAssembly_pb.CreateEstimateAssemblyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/estimateAssembly.EstimateAssemblyService/CreateEstimateAssembly',
        request,
        metadata || {},
        this.methodDescriptorCreateEstimateAssembly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/estimateAssembly.EstimateAssemblyService/CreateEstimateAssembly',
    request,
    metadata || {},
    this.methodDescriptorCreateEstimateAssembly);
  }

  methodDescriptorUpdateEstimateAssembly = new grpcWeb.MethodDescriptor(
    '/estimateAssembly.EstimateAssemblyService/UpdateEstimateAssembly',
    grpcWeb.MethodType.UNARY,
    estimateAssembly_pb.UpdateEstimateAssemblyRequest,
    estimateAssembly_pb.UpdateEstimateAssemblyResponse,
    (request: estimateAssembly_pb.UpdateEstimateAssemblyRequest) => {
      return request.serializeBinary();
    },
    estimateAssembly_pb.UpdateEstimateAssemblyResponse.deserializeBinary
  );

  updateEstimateAssembly(
    request: estimateAssembly_pb.UpdateEstimateAssemblyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateAssembly_pb.UpdateEstimateAssemblyResponse>;

  updateEstimateAssembly(
    request: estimateAssembly_pb.UpdateEstimateAssemblyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateAssembly_pb.UpdateEstimateAssemblyResponse) => void): grpcWeb.ClientReadableStream<estimateAssembly_pb.UpdateEstimateAssemblyResponse>;

  updateEstimateAssembly(
    request: estimateAssembly_pb.UpdateEstimateAssemblyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateAssembly_pb.UpdateEstimateAssemblyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/estimateAssembly.EstimateAssemblyService/UpdateEstimateAssembly',
        request,
        metadata || {},
        this.methodDescriptorUpdateEstimateAssembly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/estimateAssembly.EstimateAssemblyService/UpdateEstimateAssembly',
    request,
    metadata || {},
    this.methodDescriptorUpdateEstimateAssembly);
  }

  methodDescriptorDeleteEstimateAssembly = new grpcWeb.MethodDescriptor(
    '/estimateAssembly.EstimateAssemblyService/DeleteEstimateAssembly',
    grpcWeb.MethodType.UNARY,
    estimateAssembly_pb.DeleteEstimateAssemblyRequest,
    estimateAssembly_pb.DeleteEstimateAssemblyResponse,
    (request: estimateAssembly_pb.DeleteEstimateAssemblyRequest) => {
      return request.serializeBinary();
    },
    estimateAssembly_pb.DeleteEstimateAssemblyResponse.deserializeBinary
  );

  deleteEstimateAssembly(
    request: estimateAssembly_pb.DeleteEstimateAssemblyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateAssembly_pb.DeleteEstimateAssemblyResponse>;

  deleteEstimateAssembly(
    request: estimateAssembly_pb.DeleteEstimateAssemblyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateAssembly_pb.DeleteEstimateAssemblyResponse) => void): grpcWeb.ClientReadableStream<estimateAssembly_pb.DeleteEstimateAssemblyResponse>;

  deleteEstimateAssembly(
    request: estimateAssembly_pb.DeleteEstimateAssemblyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateAssembly_pb.DeleteEstimateAssemblyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/estimateAssembly.EstimateAssemblyService/DeleteEstimateAssembly',
        request,
        metadata || {},
        this.methodDescriptorDeleteEstimateAssembly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/estimateAssembly.EstimateAssemblyService/DeleteEstimateAssembly',
    request,
    metadata || {},
    this.methodDescriptorDeleteEstimateAssembly);
  }

  methodDescriptorGetEstimateAssembliesByEstimateId = new grpcWeb.MethodDescriptor(
    '/estimateAssembly.EstimateAssemblyService/GetEstimateAssembliesByEstimateId',
    grpcWeb.MethodType.UNARY,
    estimateAssembly_pb.GetEstimateAssembliesByEstimateIdRequest,
    estimateAssembly_pb.GetEstimateAssembliesByEstimateIdResponse,
    (request: estimateAssembly_pb.GetEstimateAssembliesByEstimateIdRequest) => {
      return request.serializeBinary();
    },
    estimateAssembly_pb.GetEstimateAssembliesByEstimateIdResponse.deserializeBinary
  );

  getEstimateAssembliesByEstimateId(
    request: estimateAssembly_pb.GetEstimateAssembliesByEstimateIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateAssembly_pb.GetEstimateAssembliesByEstimateIdResponse>;

  getEstimateAssembliesByEstimateId(
    request: estimateAssembly_pb.GetEstimateAssembliesByEstimateIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateAssembly_pb.GetEstimateAssembliesByEstimateIdResponse) => void): grpcWeb.ClientReadableStream<estimateAssembly_pb.GetEstimateAssembliesByEstimateIdResponse>;

  getEstimateAssembliesByEstimateId(
    request: estimateAssembly_pb.GetEstimateAssembliesByEstimateIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateAssembly_pb.GetEstimateAssembliesByEstimateIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/estimateAssembly.EstimateAssemblyService/GetEstimateAssembliesByEstimateId',
        request,
        metadata || {},
        this.methodDescriptorGetEstimateAssembliesByEstimateId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/estimateAssembly.EstimateAssemblyService/GetEstimateAssembliesByEstimateId',
    request,
    metadata || {},
    this.methodDescriptorGetEstimateAssembliesByEstimateId);
  }

  methodDescriptorDeleteExtendEstimate = new grpcWeb.MethodDescriptor(
    '/estimateAssembly.EstimateAssemblyService/DeleteExtendEstimate',
    grpcWeb.MethodType.UNARY,
    estimateAssembly_pb.DeleteExtendEstimateRequest,
    estimateAssembly_pb.DeleteExtendEstimateResponse,
    (request: estimateAssembly_pb.DeleteExtendEstimateRequest) => {
      return request.serializeBinary();
    },
    estimateAssembly_pb.DeleteExtendEstimateResponse.deserializeBinary
  );

  deleteExtendEstimate(
    request: estimateAssembly_pb.DeleteExtendEstimateRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateAssembly_pb.DeleteExtendEstimateResponse>;

  deleteExtendEstimate(
    request: estimateAssembly_pb.DeleteExtendEstimateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateAssembly_pb.DeleteExtendEstimateResponse) => void): grpcWeb.ClientReadableStream<estimateAssembly_pb.DeleteExtendEstimateResponse>;

  deleteExtendEstimate(
    request: estimateAssembly_pb.DeleteExtendEstimateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateAssembly_pb.DeleteExtendEstimateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/estimateAssembly.EstimateAssemblyService/DeleteExtendEstimate',
        request,
        metadata || {},
        this.methodDescriptorDeleteExtendEstimate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/estimateAssembly.EstimateAssemblyService/DeleteExtendEstimate',
    request,
    metadata || {},
    this.methodDescriptorDeleteExtendEstimate);
  }

}

