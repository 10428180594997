/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: inference.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as inference_pb from './inference_pb'; // proto import: "inference.proto"


export class InferenceServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorRunCountInference = new grpcWeb.MethodDescriptor(
    '/InferenceService/RunCountInference',
    grpcWeb.MethodType.UNARY,
    inference_pb.RunCountInferenceRequest,
    inference_pb.RunCountInferenceResponse,
    (request: inference_pb.RunCountInferenceRequest) => {
      return request.serializeBinary();
    },
    inference_pb.RunCountInferenceResponse.deserializeBinary
  );

  runCountInference(
    request: inference_pb.RunCountInferenceRequest,
    metadata?: grpcWeb.Metadata | null): Promise<inference_pb.RunCountInferenceResponse>;

  runCountInference(
    request: inference_pb.RunCountInferenceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: inference_pb.RunCountInferenceResponse) => void): grpcWeb.ClientReadableStream<inference_pb.RunCountInferenceResponse>;

  runCountInference(
    request: inference_pb.RunCountInferenceRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: inference_pb.RunCountInferenceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InferenceService/RunCountInference',
        request,
        metadata || {},
        this.methodDescriptorRunCountInference,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InferenceService/RunCountInference',
    request,
    metadata || {},
    this.methodDescriptorRunCountInference);
  }

  methodDescriptorProcessImage = new grpcWeb.MethodDescriptor(
    '/InferenceService/ProcessImage',
    grpcWeb.MethodType.UNARY,
    inference_pb.ProcessImageRequest,
    inference_pb.ProcessImageResponse,
    (request: inference_pb.ProcessImageRequest) => {
      return request.serializeBinary();
    },
    inference_pb.ProcessImageResponse.deserializeBinary
  );

  processImage(
    request: inference_pb.ProcessImageRequest,
    metadata?: grpcWeb.Metadata | null): Promise<inference_pb.ProcessImageResponse>;

  processImage(
    request: inference_pb.ProcessImageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: inference_pb.ProcessImageResponse) => void): grpcWeb.ClientReadableStream<inference_pb.ProcessImageResponse>;

  processImage(
    request: inference_pb.ProcessImageRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: inference_pb.ProcessImageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InferenceService/ProcessImage',
        request,
        metadata || {},
        this.methodDescriptorProcessImage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InferenceService/ProcessImage',
    request,
    metadata || {},
    this.methodDescriptorProcessImage);
  }

  methodDescriptorWebSearch = new grpcWeb.MethodDescriptor(
    '/InferenceService/WebSearch',
    grpcWeb.MethodType.SERVER_STREAMING,
    inference_pb.WebSearchRequest,
    inference_pb.WebSearchResponse,
    (request: inference_pb.WebSearchRequest) => {
      return request.serializeBinary();
    },
    inference_pb.WebSearchResponse.deserializeBinary
  );

  webSearch(
    request: inference_pb.WebSearchRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<inference_pb.WebSearchResponse> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/InferenceService/WebSearch',
      request,
      metadata || {},
      this.methodDescriptorWebSearch);
  }

  methodDescriptorRunAgent = new grpcWeb.MethodDescriptor(
    '/InferenceService/RunAgent',
    grpcWeb.MethodType.SERVER_STREAMING,
    inference_pb.RunAgentRequest,
    inference_pb.RunAgentResponse,
    (request: inference_pb.RunAgentRequest) => {
      return request.serializeBinary();
    },
    inference_pb.RunAgentResponse.deserializeBinary
  );

  runAgent(
    request: inference_pb.RunAgentRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<inference_pb.RunAgentResponse> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/InferenceService/RunAgent',
      request,
      metadata || {},
      this.methodDescriptorRunAgent);
  }

}

