import { useEffect, useState, useMemo } from "react";
import { Paper, Box, Button, Tabs, Tab, Dialog, DialogContent } from "@mui/material";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { useOpenAlertCenter } from "../AlertCenter";
import {
  useGetExtendEstimates,
  useGetDirectLaborCloseouts,
  useGetLaborFactoringCloseouts,
  useGetIncidentalLaborCloseouts,
  useGetIndirectLaborCloseouts,
  useGetEquipmentCloseouts,
  useGetGeneralExpenseCloseouts,
  useGetSubcontractCloseouts,
  useGetQuoteCloseouts,
  useGetTotalFinalPriceCloseouts,
  useGetExtendChangeOrder,
  useGetDirectLaborChangeOrderCloseouts,
  useGetEquipmentChangeOrderCloseouts,
  useGetGeneralExpenseChangeOrderCloseouts,
  useGetIncidentalLaborChangeOrderCloseouts,
  useGetIndirectLaborChangeOrderCloseouts,
  useGetLaborFactoringChangeOrderCloseouts,
  useGetQuoteChangeOrderCloseouts,
  useGetSubcontractChangeOrderCloseouts,
  useGetTotalFinalPriceChangeOrderCloseouts,
} from "../../hooks/useCloseoutHooks";

// Tab Components
import { ExtentionHeader } from "./ExtentionHeader";
import { DirectLaborHeader } from "./DirectLaborHeader";
import { LaborFactoringHeader } from "./LaborFactoringHeader";
import { IncidentalLaborHeader } from "./IncidentalLaborHeader";
import { IndirectLaborHeader } from "./IndirectLaborHeader";
import { EquipmentHeader } from "./EquipmentHeader";
import { GeneralExpenseHeader } from "./GeneralExpenseHeader";
import { SubcontractHeader } from "./SubcontractHeader";
import { QuoteHeader } from "./QuoteHeader";
import { TotalPricingHeader } from "./TotalPricingHeader";
import { ScheduleOfValues } from "./ScheduleOfValues";
import { AllocationTab } from "./AllocationTab";
import ChatWindow from "../ChatWindow";
import { StyledButton } from "../MasterProjectView";
import chatBotImage from "../../images/ChatBot.png";
import { useKeyBoardEventStore } from "../../states/keyEventStore";

export const CloseoutTabs = () => {
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  const showChatWindow = useStore(useKeyBoardEventStore).showChatWindow;
  const setShowChatWindow = useStore(useKeyBoardEventStore).setShowChatWindow;;
  const userBackgroundColor = useStore(useUnityBuildStore).userBackgroundColor;
  const setIsViewingExtension = useStore(useUnityBuildStore).setIsViewingExtension;
  const isViewingAllocation = useStore(useUnityBuildStore).isViewingAllocation;
  const setIsViewingAllocation = useStore(useUnityBuildStore).setIsViewingAllocation;

  const [value, setValue] = useState(0);
  const { openAlertCenter, renderAlert } = useOpenAlertCenter();

  // Query Hooks (with manual fetching) ---estimates
  const extendEstimates = useGetExtendEstimates();
  const directLabor = useGetDirectLaborCloseouts();
  const laborFactoring = useGetLaborFactoringCloseouts();
  const incidentalLabor = useGetIncidentalLaborCloseouts();
  const indirectLabor = useGetIndirectLaborCloseouts();
  const equipment = useGetEquipmentCloseouts();
  const generalExpense = useGetGeneralExpenseCloseouts();
  const subcontract = useGetSubcontractCloseouts();
  const quote = useGetQuoteCloseouts();
  const totalPricing = useGetTotalFinalPriceCloseouts();

  // Query Hooks (with manual fetching) ---changeOrder
  const extendChangeOrder = useGetExtendChangeOrder();
  const directLaborChangeOrder = useGetDirectLaborChangeOrderCloseouts();
  const laborFactoringChangeOrder = useGetLaborFactoringChangeOrderCloseouts();
  const incidentalLaborChangeOrder =
    useGetIncidentalLaborChangeOrderCloseouts();
  const indirectLaborChangeOrder = useGetIndirectLaborChangeOrderCloseouts();
  const equipmentChangeOrder = useGetEquipmentChangeOrderCloseouts();
  const generalExpenseChangeOrder = useGetGeneralExpenseChangeOrderCloseouts();
  const subcontractChangeOrder = useGetSubcontractChangeOrderCloseouts();
  const quoteChangeOrder = useGetQuoteChangeOrderCloseouts();
  const totalPricingChangeOrder = useGetTotalFinalPriceChangeOrderCloseouts();

  // Memoized closeout to prevent re-renders
  const closeout = useMemo(
    () => [
      {
        hook: selectedEstimate?.estimateid
          ? extendEstimates
          : extendChangeOrder,
        label: "Extended Costs",
        component: <ExtentionHeader />,
      },
      {
        hook: selectedEstimate?.estimateid
          ? directLabor
          : directLaborChangeOrder,
        label: "Direct Labor",
        component: <DirectLaborHeader />,
      },
      {
        hook: selectedEstimate?.estimateid
          ? laborFactoring
          : laborFactoringChangeOrder,
        label: "Labor Factoring",
        component: <LaborFactoringHeader />,
      },
      {
        hook: selectedEstimate?.estimateid
          ? incidentalLabor
          : incidentalLaborChangeOrder,
        label: "Incidental Labor",
        component: <IncidentalLaborHeader />,
      },
      {
        hook: selectedEstimate?.estimateid
          ? indirectLabor
          : indirectLaborChangeOrder,
        label: "Indirect Labor",
        component: <IndirectLaborHeader />,
      },
      {
        hook: selectedEstimate?.estimateid ? equipment : equipmentChangeOrder,
        label: "Equipment",
        component: <EquipmentHeader />,
      },
      {
        hook: selectedEstimate?.estimateid
          ? generalExpense
          : generalExpenseChangeOrder,
        label: "General Expenses",
        component: <GeneralExpenseHeader />,
      },
      {
        hook: selectedEstimate?.estimateid
          ? subcontract
          : subcontractChangeOrder,
        label: "Subcontracts",
        component: <SubcontractHeader />,
      },
      {
        hook: selectedEstimate?.estimateid ? quote : quoteChangeOrder,
        label: "Quotes",
        component: <QuoteHeader />,
      },
      {
        hook: selectedEstimate?.estimateid
          ? totalPricing
          : totalPricingChangeOrder,
        label: "Total Pricing",
        component: <TotalPricingHeader />,
      },
      {
        hook: null,
        label: "Schedule of Values",
        component: <ScheduleOfValues />,
      },
    ],
    [
      selectedEstimate,
      extendEstimates,
      directLabor,
      laborFactoring,
      incidentalLabor,
      indirectLabor,
      equipment,
      generalExpense,
      subcontract,
      quote,
      totalPricing,
      extendChangeOrder,
      directLaborChangeOrder,
      laborFactoringChangeOrder,
      incidentalLaborChangeOrder,
      indirectLaborChangeOrder,
      generalExpenseChangeOrder,
      equipmentChangeOrder,
      quoteChangeOrder,
      subcontractChangeOrder,
      totalPricingChangeOrder,
    ]
  );

  // Fetch data when switching to a new tab
  useEffect(() => {
    const { hook, label } = closeout[value];
    if (hook?.refetch) {
      hook.refetch();
      openAlertCenter({ message: `Loading ${label}...`, duration: 3000 });
    }
    //eslint-disable-next-line
  }, [value]); // Triggers when `value` (active tab) changes

  // Show an alert when data is being fetched (including background fetching)
  useEffect(() => {
    const { hook, label } = closeout[value];
    if (hook?.isFetching) {
      openAlertCenter({ message: `Updating ${label}...`, duration: 3000 });
    }
    //eslint-disable-next-line
  }, [closeout[value]?.hook?.isFetching]); // Runs whenever `isFetching` changes

  // Handle tab switching
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Paper
        sx={{
          height: "100%",
          backgroundColor: userBackgroundColor,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Tabs */}
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="Closeout tabs"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          {closeout.map((item, index) => (
            <Tab key={index} label={item.label} />
          ))}
        </Tabs>

        {/* Alerts Rendered Here */}
        {renderAlert()}

        {/* Main Content */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            p: 2,
          }}
        >
          {closeout[value].component}
        </Box>

        {/* Controls */}
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => setIsViewingExtension(false)}
            variant="contained"
            color="secondary"
            sx={{ m: 2 }}
          >
            Close Window
          </Button>

          <Button
            onClick={() => setIsViewingAllocation(true)}
            variant="contained"
            color="secondary"
            sx={{ m: 2 }}
          >
            Allocations
          </Button>

          {/* Refresh Button to Manually Fetch Data */}
          {closeout[value].hook && (
            <Button
              onClick={() => closeout[value]?.hook?.refetch()}
              variant="contained"
              color="primary"
              sx={{ m: 2 }}
            >
              Refresh {closeout[value].label}
            </Button>
          )}
        </Box>
      </Paper>
      {/* Chat Button */}
      <Box
        sx={{
          position: "absolute",
          bottom: 20,
          left: 20,
          zIndex: 10,
        }}
      >
        <StyledButton onClick={() => setShowChatWindow(!showChatWindow)}>
          <img
            src={chatBotImage}
            alt="Chat Window Icon"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </StyledButton>
        {showChatWindow && <div style={{
          zIndex: 9,
          position: "absolute",
          bottom: 65,
          left: 10,
          padding: 2,
        }}><ChatWindow />
        </div>
        }
      </Box>
      <Dialog open={isViewingAllocation} onClose={() => setIsViewingAllocation(false)} maxWidth="lg"
        fullWidth>
        <DialogContent>
          <AllocationTab />
        </DialogContent>
      </Dialog></>

  );
};
