import { Container, Typography, List, ListItem } from "@mui/material";

export const HowDoesThisWork = () => {
    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                Hot Keys
            </Typography>
            <Typography variant="h6">
                The following hotkeys work while the cursor is hovered over a document:
            </Typography>
            <List>
                <ListItem>"C" – Toggle chat window</ListItem>
                <ListItem>"M" – Toggle measurements</ListItem>
                <ListItem>"N" – Toggle notes</ListItem>
                <ListItem>"P" – Toggle palette</ListItem>
                <ListItem>"H" – Toggle crosshair</ListItem>
                <ListItem> "Ctrl" – Toggle continuous mode</ListItem>
                <ListItem>"Shift" – Toggle snap mode</ListItem>
            </List>

            <Typography variant="h4" gutterBottom>
                Annotations
            </Typography>
            <Typography paragraph>
                An annotation is a shape drawn on top of a document. Annotations represent an associated assembly, which can be of type <strong>"count"</strong>, <strong>"length"</strong>, or <strong>"area"</strong>.
                These annotation types provide logic to determine the amount of an item to count using components called <strong>takeoff variables</strong>, which are categorized as:
            </Typography>

            <List>
                <ListItem><strong>Point</strong> – Represents a single count</ListItem>
                <ListItem><strong>Length</strong> – The total length of a line annotation</ListItem>
                <ListItem><strong>Segment</strong> – A section of a length annotation between two active points</ListItem>
                <ListItem><strong>Area</strong> – The total area of an area annotation</ListItem>
            </List>

            <Typography variant="h6" gutterBottom>
                Annotation Type and Compatible Variables:
            </Typography>
            <List>
                <ListItem><strong>Count Annotation</strong> – Uses "point"</ListItem>
                <ListItem><strong>Length Annotation</strong> – Uses "point", "length", and "segment"</ListItem>
                <ListItem><strong>Area Annotation</strong> – Uses "point", "length", "segment", and "area"</ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
                Count Annotations
            </Typography>
            <Typography paragraph>
                Count annotations are the simplest type. Each count is associated with a single point drawn on the screen in a shape chosen by the user.
            </Typography>

            <Typography variant="h5" gutterBottom>
                Length Annotations
            </Typography>
            <Typography paragraph>
                Length annotations consist of counts connected by lines, representing real-world distances.
            </Typography>

            <Typography variant="h5" gutterBottom>
                Area Annotations
            </Typography>
            <Typography paragraph>
                Area annotations consist of counts and lengths, calculating the total area in square feet based on the shape drawn.
            </Typography>
        </Container>
    );
};
