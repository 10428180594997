import { create } from "zustand";
import { Point } from "../types/Point";
import produce from "immer";

import { Estimate } from "../api/protosCompiled/projectEstimate/projectEstimate_pb";
import { Contract } from "../api/protosCompiled/contract/contract_pb";
import { ChangeOrder } from "../api/protosCompiled/changeOrder/changeOrder_pb";
import { Project } from "../api/protosCompiled/project/project_pb";
import { Object as ProtoObject } from "../api/protosCompiled/object/object_pb";
import {
  BreakOut,
  TakeoffAssemblies,
  TakeoffAssembly,
} from "../types/AssemblyItemType";

export interface AvailableObject {
  id: string;
  project_id: string;
  objectName: string;
  gcsObjectName: string;
  userScale: number;
}

export const defaultContract: Contract.AsObject = {
  contractid: "",
  customid: "",
  projectid: "",
  datecreated: "",
  lastupdated: "",
  contractname: "",
  contractdescription: "",
  associatedestimatesList: [],
  associatedchangeordersList: [],
};

export const defaultProject: Project.AsObject = {
  id: "",
  name: "",
  address: "",
  description: "",
  startdate: "",
  enddate: "",
  datecreated: "",
  openaivectorstoreid: "",
  openaiprojectassistantid: "",
  createdbyuserid: "",
  createdbyaccountid: "",
};

export const angleOptionMultipliers = [1, 5, 15, 45];
const defaultAngleOptions = Array.from(
  { length: 360 / angleOptionMultipliers[0] },
  (_, i) => i * angleOptionMultipliers[0]
);

export type EstimateStore = {
  isAddingVertical: boolean;
  setIsAddingVertical: (isAddingVertical: boolean) => void;

  countedAssemblies: TakeoffAssemblies[] | null;
  setCountedAssemblies: (assemblies: TakeoffAssemblies[] | null) => void;

  filteredCountedAssemblies: TakeoffAssemblies[];
  setFilteredCountedAssemblies: (assemblies: TakeoffAssemblies[]) => void;

  breakoutFilterObject: BreakOut | null;
  setBreakoutFilterObject: (breakoutFilterObject: BreakOut | null) => void;

  selectedBreakout: BreakOut | null;
  setSelectedBreakout: (selectedBreakout: BreakOut | null) => void;

  angleOptions: number[];
  setAngleOptions: (angleOptions: number[]) => void;

  userBackgroundColor: string;
  setUserBackgroundColor: (color: string) => void;

  isFetchingToken: boolean;
  setIsFetchingToken: (isFetchingToken: boolean) => void;

  sessionToken: string;
  setSessionToken: (token: string) => void;

  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;

  selectedProject: Project.AsObject;
  setSelectedProject: (selectedProject: Project.AsObject) => void;

  estimateList: Estimate.AsObject[];
  setEstimateList: (estimateList: Estimate.AsObject[]) => void;

  selectedEstimate: Estimate.AsObject | null;
  setSelectedEstimate: (estimate: Estimate.AsObject | null) => void;

  selectedContract: Contract.AsObject | null;
  setSelectedContract: (contract: Contract.AsObject | null) => void;

  selectedChangeOrder: ChangeOrder.AsObject | null;
  setSelectedChangeOrder: (changeOrder: ChangeOrder.AsObject | null) => void;

  selectedFilter: string;
  setSelectedFilter: (filter: string) => void;

  projectListIsMounted: boolean;
  setProjectListIsMounted: (isMounted: boolean) => void;

  showSidebarMenu: boolean;
  setShowSidebarMenu: (show: boolean) => void;

  projectList: Project.AsObject[];
  setProjectList: (projectList: Project.AsObject[]) => void;

  changeOrderList: ChangeOrder.AsObject[];
  setChangeOrderList: (changeOrderList: ChangeOrder.AsObject[]) => void;

  contractList: Contract.AsObject[];
  setContractList: (contractList: Contract.AsObject[]) => void;

  roles: any[];
  setRoles: (roles: any[]) => void;


  isViewingProjectDashboard: boolean;
  setIsViewingProjectDashboard: (isViewingProjectDashboard: boolean) => void;

  isViewingDrawingSets: boolean;
  setIsViewingDrawingSets: (isViewingDrawingSets: boolean) => void;

  isCreatingEstimate: boolean;
  setIsCreatingEstimate: (isCreatingEstimate: boolean) => void;

  isViewingEstimateForm: boolean;
  setIsViewingEstimateForm: (isViewingEstimateForm: boolean) => void;

  isViewingEstimateList: boolean;
  setIsViewingEstimateList: (isViewingEstimateList: boolean) => void;

  isViewingChangeOrderList: boolean;
  setIsViewingChangeOrderList: (isViewingChangeOrderList: boolean) => void;

  isViewingContractForm: boolean;
  setIsViewingContractForm: (isViewingContractForm: boolean) => void;

  isCreatingChangeOrder: boolean;
  setIsCreatingChangeOrder: (isCreatingChangeOrder: boolean) => void;

  isViewingChangeOrderForm: boolean;
  setIsViewingChangeOrderForm: (isViewingChangeOrderForm: boolean) => void;

  isCreatingContract: boolean;
  setIsCreatingContract: (isCreatingContract: boolean) => void;

  isViewingContractList: boolean;
  setIsViewingContractList: (isViewingContractList: boolean) => void;

  isViewingExtension: boolean;
  setIsViewingExtension: (isViewingExtension: boolean) => void;

  isViewingAllocation: boolean;
  setIsViewingAllocation: (isViewingAllocation: boolean) => void;

  //--------------------------------------------------------------------------
  //pointEditor state to trigger updates in other components
  includeAllPoints: boolean;
  setIncludeAllPoints: (includeAllPoints: boolean) => void;

  isInsertingPoint: boolean;
  setIsInsertingPoint: (isInsertingPoint: boolean) => void;

  isEditingPointFillColor: boolean;
  isEditingPointBorderColor: boolean;
  isEditingLineColor: boolean;
  setIsEditingPointStyle: (params: {
    isEditingPointFillColor?: boolean;
    isEditingPointBorderColor?: boolean;
    isEditingLineColor?: boolean;
  }) => void;

  backgroundColor: string;
  setBackgroundColor: (color: string) => void;

  //-----------------------------------------------------------

  numPages: number;
  setNumPages: (numPages: number) => void;

  currentPage: number;
  setCurrentPage: (currentPage: number) => void;

  isOpeningViewport: boolean;
  setIsOpeningViewport: (isOpeningViewport: boolean) => void;

  selectedObject: ProtoObject.AsObject | null;
  setSelectedObject: (object: ProtoObject.AsObject | null) => void;

  // selectedObjectName: string;
  // setSelectedObjectName: (object: string) => void;

  // selectedObjectId: string;
  // setSelectedObjectId: (id: string) => void;

  isCreatingNewProject: boolean;
  setIsCreatingNewProject: (creatingNewProject: boolean) => void;

  isCreatingNewClient: boolean;
  setIsCreatingNewClient: (creatingNewClient: boolean) => void;

  isCreatingNewUser: boolean;
  setIsCreatingNewUser: (creatingNewUser: boolean) => void;

  //--------------------------------------------------------------------------

  pdfDimensions: { width: number; height: number };
  setPdfDimensions: (dimensions: { width: number; height: number }) => void;

  //Tool bar states for the document viewer------------------------------------
  userScale: number;
  setUserScale: (scale: number) => void;

  measuredPoints: { x: number; y: number }[];
  setMeasuredPoints: (
    points:
      | { x: number; y: number }[]
      | ((prevPoints: { x: number; y: number }[]) => { x: number; y: number }[])
  ) => void;

  temporaryPoints: Point[];
  setTemporaryPoints: (
    points: Point[] | ((prevPoints: Point[]) => Point[])
  ) => void;

  isAnnotating: boolean;
  setIsAnnotating: (isAnnotating: boolean) => void;

  //Assembly window states-----------------------------------------------------

  showAssemblyWindow: boolean;
  setShowAssemblyWindow: (show: boolean) => void;

  pointIsSelected: boolean;
  setPointIsSelected: (pointIsSelected: boolean) => void;

  selectedTotalLength: number; // use for temporary storage of the length of the selected assembly takeff, reset to null after use
  setSelectedTotalLength: (length: number) => void;

  allIsSelected: boolean; // for the main select All button of the Assembly Manager
  setAllIsSelected: (allIsSelected: boolean) => void;

  countIsSelected: boolean; // for the main select Count button of the Assembly Manager
  setCountIsSelected: (countIsSelected: boolean) => void;

  lengthIsSelected: boolean; // for the main select Length button of the Assembly Manager
  setLengthIsSelected: (lengthIsSelected: boolean) => void;

  areaIsSelected: boolean; // for the main select Area button of the Assembly Manager
  setAreaIsSelected: (areaIsSelected: boolean) => void;

  isCreatingTakeoffAssembly: boolean; // specific to performing a takeoff with an assembly
  setIsCreatingTakeoffAssembly: (isCreatingTakeoffAssembly: boolean) => void;

  isUpdatingTakeoffAssembly: boolean; // specific to updating a takeoff
  setIsUpdatingTakeoffAssembly: (isUpdatingTakeoffAssembly: boolean) => void;

  isCounting: boolean; // for the specific  count item selection of the Assembly Manager
  setIsCounting: (isCounting: boolean) => void;

  isGettingLength: boolean; // for the specific length item selection of the Assembly Manager
  setIsGettingLength: (isGettingLength: boolean) => void;

  isGettingArea: boolean; // for the specific area item selection of the Assembly Manager
  setIsGettingArea: (isGettingArea: boolean) => void;

  //--------------------------------------------------------------------------

  cursorPosition: { x: number; y: number };
  setCursorPosition: (position: { x: number; y: number }) => void;

  isDragging: boolean;
  setIsDragging: (isDragging: boolean) => void;

  newAnnotations: TakeoffAssemblies[] | null;
  setNewAnnotations: (newAnnotations: TakeoffAssemblies[] | null) => void;

  isEditingPoint: boolean;
  isEditingStyle: boolean;
  isDeletingPoint: boolean;
  assemblyIndex: number | null;
  pointIndex: number;
  lineIndex: number;
  editingPointX: number | null;
  editingPointY: number | null;

  setIsEditingPoint: (params: {
    isEditingPoint: boolean;
    isEditingStyle?: boolean;
    assemblyIndex?: number | null;
    pointIndex?: number | null;
    lineIndex?: number | null;
    pointsList?: TakeoffAssembly["pointsList"];
    x?: number | null;
    y?: number | null;
  }) => void;

  updatePoint: (params: {
    assemblyIndex: number;
    pointIndex: number;
    newPosition?: { x: number; y: number } | null;
    isDeletingPoint?: boolean;
  }) => void;
};

export const useUnityBuildStore = create<EstimateStore>((set) => ({
  isAddingVertical: false,
  setIsAddingVertical: (isAddingVertical) => set({ isAddingVertical }),

  countedAssemblies: null,
  setCountedAssemblies: (assemblies) => {
    const sortedAssemblies = assemblies?.sort(
      (a, b) =>
        Number(a.assembliesList[0].assemblyid) -
        Number(b.assembliesList[0].assemblyid)
    );
    set({ countedAssemblies: sortedAssemblies });
  },

  filteredCountedAssemblies: [],
  setFilteredCountedAssemblies: (assemblies) => {
    if (!assemblies || assemblies.length === 0) {
      set({ filteredCountedAssemblies: [] });
      return;
    }

    const sortedAssemblies = [...assemblies].sort((a, b) => {
      if (!a.assembliesList[0] || !b.assembliesList[0]) return 0;

      // Ensure numeric comparison for `pagenumber`
      const pageNumberA = Number(a.assembliesList[0].pagenumber) || 0;
      const pageNumberB = Number(b.assembliesList[0].pagenumber) || 0;

      if (pageNumberA !== pageNumberB) {
        return pageNumberA - pageNumberB;
      }

      // Ensure `assemblyname` exists before sorting alphabetically
      const nameA = a.assembliesList[0].assemblyname || "";
      const nameB = b.assembliesList[0].assemblyname || "";

      return nameA.localeCompare(nameB);
    });

    set({ filteredCountedAssemblies: sortedAssemblies });
  },

  breakoutFilterObject: null,
  setBreakoutFilterObject: (breakoutFilterObject) =>
    set({ breakoutFilterObject: breakoutFilterObject ?? null }),

  selectedBreakout: null,
  setSelectedBreakout: (selectedBreakout) =>
    set({ selectedBreakout: selectedBreakout ?? null }),

  angleOptions: defaultAngleOptions,
  setAngleOptions: (angleOptions) =>
    set({ angleOptions: angleOptions ?? defaultAngleOptions }),

  userBackgroundColor: "#8c9da0",
  setUserBackgroundColor: (color) => set({ userBackgroundColor: color }),

  isFetchingToken: false,
  setIsFetchingToken: (isFetchingToken) => set({ isFetchingToken }),

  sessionToken: "",
  setSessionToken: (token) => set({ sessionToken: token }),

  isLoggedIn: false,
  setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn }),

  selectedProject: defaultProject,
  setSelectedProject: (selectedProject) =>
    set({ selectedProject: selectedProject }),

  selectedEstimate: null,
  setSelectedEstimate: (estimate) => set({ selectedEstimate: estimate }),

  selectedContract: null,
  setSelectedContract: (contract) => set({ selectedContract: contract }),

  selectedChangeOrder: null,
  setSelectedChangeOrder: (changeOrder) =>
    set({ selectedChangeOrder: changeOrder }),

  selectedFilter: "all",
  setSelectedFilter: (filter) => set({ selectedFilter: filter }),

  projectListIsMounted: false,
  setProjectListIsMounted: (isMounted) =>
    set({ projectListIsMounted: isMounted }),

  showSidebarMenu: false,
  setShowSidebarMenu: (show) => set({ showSidebarMenu: show }),

  projectList: [],
  setProjectList: (projectList) => set({ projectList: projectList }),

  estimateList: [],
  setEstimateList: (estimateList) => set({ estimateList: estimateList }),

  changeOrderList: [],
  setChangeOrderList: (changeOrderList) =>
    set({ changeOrderList: changeOrderList }),

  contractList: [],
  setContractList: (contractList) => set({ contractList: contractList }),

  roles: [],
  setRoles: (roles) => set({ roles: roles }),

  isViewingProjectDashboard: true,
  setIsViewingProjectDashboard: (isViewingProjectDashboard) =>
    set({ isViewingProjectDashboard: isViewingProjectDashboard }),

  isViewingDrawingSets: false,
  setIsViewingDrawingSets: (isViewingDrawingSets) =>
    set({ isViewingDrawingSets: isViewingDrawingSets }),

  isCreatingEstimate: false,
  setIsCreatingEstimate: (isCreatingEstimate) =>
    set({ isCreatingEstimate: isCreatingEstimate }),

  isViewingEstimateForm: false,
  setIsViewingEstimateForm: (isViewingEstimateForm) =>
    set({ isViewingEstimateForm: isViewingEstimateForm }),

  isViewingEstimateList: false,
  setIsViewingEstimateList: (isViewingEstimateList) =>
    set({ isViewingEstimateList: isViewingEstimateList }),

  isViewingChangeOrderList: false,
  setIsViewingChangeOrderList: (isViewingChangeOrderList) =>
    set({ isViewingChangeOrderList: isViewingChangeOrderList }),

  isViewingContractForm: false,
  setIsViewingContractForm: (isViewingContractForm) =>
    set({ isViewingContractForm: isViewingContractForm }),

  isCreatingChangeOrder: false,
  setIsCreatingChangeOrder: (isCreatingChangeOrder) =>
    set({ isCreatingChangeOrder: isCreatingChangeOrder }),

  isViewingChangeOrderForm: false,
  setIsViewingChangeOrderForm: (isViewingChangeOrderForm) =>
    set({ isViewingChangeOrderForm: isViewingChangeOrderForm }),

  isCreatingContract: false,
  setIsCreatingContract: (isCreatingContract) =>
    set({ isCreatingContract: isCreatingContract }),

  isViewingContractList: false,
  setIsViewingContractList: (isViewingContractList) =>
    set({ isViewingContractList: isViewingContractList }),

  isViewingExtension: false,
  setIsViewingExtension: (isViewingExtension) =>
    set({ isViewingExtension: isViewingExtension }),

  isViewingAllocation: false,
  setIsViewingAllocation: (isViewingAllocation) =>
    set({ isViewingAllocation: isViewingAllocation }),

  //--------------------------------------------------------------------------
  //pointEditor state to trigger updates in other components
  includeAllPoints: false,
  setIncludeAllPoints: (includeAllPoints) =>
    set({ includeAllPoints: includeAllPoints }),

  isInsertingPoint: false,
  setIsInsertingPoint: (isInsertingPoint) => set({ isInsertingPoint }),

  isEditingPointFillColor: false,
  isEditingPointBorderColor: false,
  isEditingLineColor: false,
  setIsEditingPointStyle: ({
    isEditingPointFillColor,
    isEditingPointBorderColor,
    isEditingLineColor,
  }) =>
    set({
      isEditingPointFillColor,
      isEditingPointBorderColor,
      isEditingLineColor,
    }),

  backgroundColor: "#BD10E0",
  setBackgroundColor: (color) => set({ backgroundColor: color }),

  //Assembly window states-----------------------------------------------------
  showAssemblyWindow: false,
  setShowAssemblyWindow: (show) => set({ showAssemblyWindow: show }),

  pointIsSelected: false,
  setPointIsSelected: (pointIsSelected) =>
    set({ pointIsSelected: pointIsSelected }),

  selectedTotalLength: 0,
  setSelectedTotalLength: (length) => set({ selectedTotalLength: length }),

  //--------------------------------------------------------------------------

  numPages: 1,
  setNumPages: (numPages) => set({ numPages: numPages }),

  currentPage: 1,
  setCurrentPage: (currentPage) => set({ currentPage: currentPage }),

  isOpeningViewport: false,
  setIsOpeningViewport: (isOpeningViewport) =>
    set({ isOpeningViewport: isOpeningViewport }),

  selectedObject: null,
  setSelectedObject: (selected) => set({ selectedObject: selected ?? null }),

  isCreatingNewClient: false,
  setIsCreatingNewClient: (creatingNewClient) =>
    set({ isCreatingNewClient: creatingNewClient }),

  isCreatingNewUser: false,
  setIsCreatingNewUser: (creatingNewUser) =>
    set({ isCreatingNewUser: creatingNewUser }),

  isCreatingNewProject: false,
  setIsCreatingNewProject: (creatingNewProject) =>
    set({ isCreatingNewProject: creatingNewProject }),

  pdfDimensions: { width: 0, height: 0 },
  setPdfDimensions: (dimensions) => set({ pdfDimensions: dimensions }),

  //Tool bar states for the document viewer------------------------------------
  userScale: 0,
  setUserScale: (scale) => set({ userScale: scale }),

  measuredPoints: [],
  setMeasuredPoints: (pointsOrUpdater) =>
    set((state) => ({
      measuredPoints:
        typeof pointsOrUpdater === "function"
          ? pointsOrUpdater(state.measuredPoints)
          : pointsOrUpdater,
    })),

  temporaryPoints: [],
  setTemporaryPoints: (points) =>
    set((state) => ({
      temporaryPoints:
        typeof points === "function" ? points(state.temporaryPoints) : points,
    })),

  isAnnotating: false,
  setIsAnnotating: (isAnnotating) => set({ isAnnotating: isAnnotating }),

  //--------------------------------------------------------------------------

  //Assembly window states-----------------------------------------------------

  allIsSelected: true,
  setAllIsSelected: (allIsSelected) => set({ allIsSelected: allIsSelected }),

  countIsSelected: false,
  setCountIsSelected: (countIsSelected) =>
    set({ countIsSelected: countIsSelected }),

  lengthIsSelected: false,
  setLengthIsSelected: (lengthIsSelected) =>
    set({ lengthIsSelected: lengthIsSelected }),

  areaIsSelected: false,
  setAreaIsSelected: (areaIsSelected) =>
    set({ areaIsSelected: areaIsSelected }),

  isCreatingTakeoffAssembly: false,
  setIsCreatingTakeoffAssembly: (isCreatingTakeoffAssembly) =>
    set({ isCreatingTakeoffAssembly: isCreatingTakeoffAssembly }),

  isUpdatingTakeoffAssembly: false,
  setIsUpdatingTakeoffAssembly: (isUpdatingTakeoffAssembly) =>
    set({ isUpdatingTakeoffAssembly: isUpdatingTakeoffAssembly }),

  isCounting: false,
  setIsCounting: (isCounting) => set({ isCounting: isCounting }),

  isGettingLength: false,
  setIsGettingLength: (isGettingLength) =>
    set({ isGettingLength: isGettingLength }),

  isGettingArea: false,
  setIsGettingArea: (isGettingArea) => set({ isGettingArea: isGettingArea }),

  //--------------------------------------------------------------------------

  cursorPosition: { x: 0, y: 0 },
  setCursorPosition: (position) => set({ cursorPosition: position }),

  isDragging: false,
  setIsDragging: (isDragging) => set({ isDragging }),

  newAnnotations: null,
  setNewAnnotations: (newAnnotations) => set({ newAnnotations }),

  isEditingPoint: false,
  isEditingStyle: false,
  isDeletingPoint: false,
  assemblyIndex: null,
  pointIndex: 0,
  lineIndex: 0,
  editingPointX: null,
  editingPointY: null,

  setIsEditingPoint: ({
    isEditingPoint,
    isEditingStyle,
    assemblyIndex,
    pointIndex,
    lineIndex,
    x,
    y,
  }) =>
    set((state) => ({
      ...state,
      isEditingPoint,
      isEditingStyle: isEditingStyle ?? state.isEditingStyle,
      assemblyIndex: assemblyIndex ?? state.assemblyIndex,
      pointIndex: pointIndex ?? state.pointIndex,
      lineIndex: lineIndex ?? state.lineIndex,
      editingPointX: x ?? state.editingPointX,
      editingPointY: y ?? state.editingPointY,
    })),

  updatePoint: ({ assemblyIndex, pointIndex, newPosition, isDeletingPoint }) =>
    set(
      produce((state) => {
        const targetAssembly: TakeoffAssembly =
          state.filteredCountedAssemblies[0].assembliesList[assemblyIndex];
        if (
          !targetAssembly.pointsList ||
          pointIndex >= targetAssembly.pointsList.length ||
          pointIndex < 0
        ) {
          console.error(
            "Invalid point index or points not defined:",
            pointIndex
          );
          return;
        }

        const targetPoint = targetAssembly.pointsList[pointIndex];
        if (targetPoint && !isDeletingPoint) {
          // Directly update the point's position
          targetPoint.x = newPosition?.x || 0;
          targetPoint.y = newPosition?.y || 0;
          targetPoint.y = newPosition?.y || 0;
        } else if (targetPoint && isDeletingPoint) {
          // Filter out the point to delete it
          targetAssembly.pointsList = targetAssembly.pointsList.filter(
            (_: any, idx: any) => idx !== pointIndex
          );
        }
      })
    ),
}));
