import { useCallback, useRef, useState } from "react";
import { useUnityBuildStore } from "../states/store";
import { useStore } from "zustand";
import { useAssemblyStore } from "../states/AssemblyStore";
import { v4 as uuidv4 } from "uuid";
import { EstimateAssembly } from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { ChangeOrderAssembly } from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { getAdjustedCursorPosition } from "../components/SVGelements/takeoffHelper";
import { useHandleGlobalStateState } from "./useHandleGlobalState";
import { useKeyBoardEventStore } from "../states/keyEventStore";

// This function captures the area behind the point from the PDF canvas
export const capturePDFArea = async (
  pdfContainer: HTMLCanvasElement,
  cursorPositionRef: any,
  size: number
): Promise<string> => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = size;
  canvas.height = size;

  // Calculate the scaling factor based on the relationship between the canvas size and the PDF dimensions
  const scaleX = pdfContainer.width / pdfContainer.clientWidth;
  const scaleY = pdfContainer.height / pdfContainer.clientHeight;

  // Adjust the capture coordinates based on the scaling factors
  const captureX = cursorPositionRef.current.x * scaleX - size / 2;
  const captureY = cursorPositionRef.current.y * scaleY - size / 2;

  if (context) {
    context.drawImage(
      pdfContainer,
      captureX,
      captureY,
      size,
      size,
      0,
      0,
      size,
      size
    );
    return canvas.toDataURL("image/png", 1.0);
  }
  return "";
};

export const useHandleTakeoffMouseEvents = (svgRef: any) => {
  const { currentTakeoffAssembly, setCurrentTakeoffAssembly } =
    useStore(useAssemblyStore);
  const {
    angleOptions,
    isAnnotating,
    isDragging,
    isEditingPoint,
    isEditingStyle,
    isEditingPointFillColor,
    isEditingPointBorderColor,
    isEditingLineColor,
    isInsertingPoint,
    setMeasuredPoints,
    setIsDragging,
    setIsEditingPoint,
  } = useStore(useUnityBuildStore);

  const {
    isMeasuring,
  } = useStore(useKeyBoardEventStore);

  const cursorPositionRef = useRef({ x: 0, y: 0 });
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleGlobalStates = useHandleGlobalStateState();

  const calculateCursorPosition = useCallback(
    (e: any) => {
      if (!svgRef.current) return;
      const svg = svgRef.current;

      // Create an SVGPoint and set its coordinates to the mouse position
      const point = svg.createSVGPoint();
      point.x = e.clientX;
      point.y = e.clientY;

      // Get the inverse of the current transformation matrix
      const ctm = svg.getScreenCTM()?.inverse();
      if (ctm) {
        // Transform the point to SVG coordinates
        const cursorPoint = point.matrixTransform(ctm);
        cursorPositionRef.current = { x: cursorPoint.x, y: cursorPoint.y };
        setCursorPosition({ x: cursorPoint.x, y: cursorPoint.y });
      }
    },
    [svgRef]
  );


  const handleTakeoffButton0MouseEvents = useCallback(
    async (event: MouseEvent | React.MouseEvent) => {
      event.stopPropagation();
      if (svgRef.current && !svgRef.current.matches(":hover")) {
        return;
      }
      if (!svgRef.current || event.button !== 0) return;

      if (event.altKey || event.ctrlKey) return;

      calculateCursorPosition(event);

      if (
        isDragging ||
        isEditingPoint ||
        isEditingStyle ||
        isEditingPointFillColor ||
        isEditingPointBorderColor ||
        isEditingLineColor
      ) {
        handleGlobalStates({
          resetOption: "resetTakeoff",
        });
        return;
      }

      if (!isInsertingPoint && isAnnotating && currentTakeoffAssembly) {
        const pointsArray:
          | EstimateAssembly.AsObject["pointsList"]
          | ChangeOrderAssembly.AsObject["pointsList"] = Array.isArray(
            currentTakeoffAssembly.pointsList
          )
            ? currentTakeoffAssembly.pointsList
            : [];

        const kbStore = useKeyBoardEventStore.getState();

        // Adjust the cursor position using polar tracking
        let adjustedCursorPosition = cursorPositionRef.current;

        if (
          pointsArray.length > 0 &&
          (currentTakeoffAssembly.assemblymeasurementtype === "length" ||
            currentTakeoffAssembly.assemblymeasurementtype === "area")
        ) {
          // Adjust for angle snapping if necessary
          const lastPoint = pointsArray[pointsArray.length - 1];
          adjustedCursorPosition = getAdjustedCursorPosition(
            lastPoint,
            cursorPositionRef.current,
            angleOptions,
            kbStore.snapMode,
            currentTakeoffAssembly
          );
        }

        const updatedItem = {
          ...currentTakeoffAssembly,
          pointsList: [
            ...pointsArray,
            {
              pointid: uuidv4(),
              x: adjustedCursorPosition.x,
              y: adjustedCursorPosition.y,
              angle: 0,
              verticallength: 0,
              allowtrailingline: true,
              allowtrailingmeasurement: true,
              isincludedincount: kbStore.continousMode,
              isselected: true,
              isactive: true,
            },
          ],
        };
        setCurrentTakeoffAssembly(updatedItem);
      }
      //this is broken ... we have to set it to false to be true ... like wtf
      if (!isMeasuring) {
        setMeasuredPoints((prev) => [...prev, cursorPositionRef.current]);
      }
    },
    // eslint-disable-next-line
    [svgRef, cursorPositionRef, isDragging, isEditingPoint, currentTakeoffAssembly, setCurrentTakeoffAssembly, setMeasuredPoints]
  );

  const handleTakeoffButton2MouseEvents = (e: any) => {
    if (!svgRef.current || e.button !== 2) return;
    const x = e.clientX;
    const y = e.clientY;
    setIsDragging(false);
    setIsEditingPoint({ isEditingPoint: true, x: x, y: y });
  };

  const handleMouseMove = (e: any) => {
    if (!svgRef.current) return;
    calculateCursorPosition(e);
  };

  return {
    handleTakeoffButton0MouseEvents,
    handleTakeoffButton2MouseEvents,
    handleMouseMove,
    cursorPositionRef,
    cursorPosition,
  };
};
