import React, { useState } from "react";
import { useUnityBuildStore } from "../states/store";
import { ObjectService as objs } from "../api/ObjectService";
import { useStore } from "zustand";
import { UserScaleList } from "../api/protosCompiled/object/object_pb";
import { useFetchObjects } from "../hooks/objectHooks";

export const CustomScale = () => {
  const selectedObject = useStore(useUnityBuildStore).selectedObject;

  const { refetchObjects } = useFetchObjects();

  const [customScale, setCustomScale] = useState("");

  const handleSubmit = async () => {
    if (!selectedObject) return;
    const scaleValue = parseFloat(customScale);

    if (isNaN(scaleValue) || scaleValue <= 0) {
      alert("Please enter a valid positive number for the scale.");
      return;
    }

    const userScaleList = selectedObject.userscalesList;

    try {
      const currentPage = userScaleList.length + 1; // Assume the current page is the next page in the list
      const newScale: UserScaleList.AsObject = {
        pagenumber: currentPage,
        scale: scaleValue,
      };

      // Add the new scale to the existing list
      const updatedScaleList = [...userScaleList, newScale];

      // Update the scale on the server
      await objs.updateObjectScale(selectedObject.id, updatedScaleList);

      // Refetch objects to update the state
      refetchObjects();
      alert("Scale updated successfully!");
    } catch (error) {
      console.error("Failed to update scale:", error);
      alert("Failed to update scale. Please try again.");
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 100,
        left: 25,
        width: "30vw",
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        padding: "20px",
        backgroundColor: "lightgray",
      }}
    >
      <h1>Custom Scale Widget</h1>
      <label>
        Enter custom scale (in inches per foot):
        <input
          type="text"
          value={customScale}
          onChange={(e) => setCustomScale(e.target.value)}
          placeholder="e.g., 1.5"
          style={{ marginLeft: "10px", padding: "5px" }}
        />
      </label>
      <button
        onClick={handleSubmit}
        style={{ marginTop: "20px", padding: "10px" }}
      >
        Submit
      </button>
    </div>
  );
};
