import { defaultProject, useUnityBuildStore } from "../states/store";
import { ResetStates } from "../states/resetStates";
import { useStore } from "zustand";
import { useAssemblyStore } from "../states/AssemblyStore";
import { useInferenceStore } from "../states/inferenceStore";
import { useGlobalItemStore } from "../states/globalItemStore";
import { useFolderStore } from "../states/folderStore";
import { useKeyBoardEventStore } from "../states/keyEventStore";

type ResetOptions =
  | "returnHome"
  | "returnToTakeoffList"
  | "resetTakeoff"
  | "openCloseout"
  | "resetAssembly"
  | "pointEditor";

interface SetStateFunctions<Fn extends (value: any) => void> {
  setStateFunction: Fn;
  prop: Parameters<Fn>[0];
}

interface Reset {
  resetOption: ResetOptions;
  setStateFunctions?: Array<SetStateFunctions<any>>; // Now an array of functions
}

/**
 *
 * @returns a function that resets the states based on the ResetOptions
 * We will reset the viewing states and in some instances reset state that should not persist between views
 * @setStateFunction is an optional function that can be provided to override a state after resetting
 * @bool is an optional boolean that must be provided if setStateFunction is provided
 */
export const useHandleGlobalStateState = () => {
  const unityBuildStore = useStore(useUnityBuildStore);
  const assemblyStore = useStore(useAssemblyStore);
  const itemStore = useStore(useGlobalItemStore);
  const folderStore = useStore(useFolderStore);
  const inferenceStore = useStore(useInferenceStore);
  const keyBoardEventStore = useStore(useKeyBoardEventStore);

  return (props: Reset) => {
    switch (props.resetOption) {
      case "returnHome":
        ResetStates.resetTakeoffStates(unityBuildStore, assemblyStore, keyBoardEventStore);
        ResetStates.resetMainMenuStates(unityBuildStore, assemblyStore);
        ResetStates.resetMasterProjectViewStates(
          unityBuildStore,
          assemblyStore,
          inferenceStore
        );
        ResetStates.resetCreateAssemblyStates(
          assemblyStore,
          itemStore,
          folderStore,
          unityBuildStore
        );
        unityBuildStore.setSelectedProject(defaultProject);
        unityBuildStore.setIsViewingProjectDashboard(true);
        break;
      case "returnToTakeoffList":
        ResetStates.resetTakeoffStates(unityBuildStore, assemblyStore, keyBoardEventStore);
        unityBuildStore.setIsViewingEstimateList(true);
        unityBuildStore.setIsViewingContractList(true);
        ResetStates.resetMasterProjectViewStates(
          unityBuildStore,
          assemblyStore,
          inferenceStore
        );
        break;
      case "resetTakeoff":
        ResetStates.resetTakeoffStates(unityBuildStore, assemblyStore, keyBoardEventStore);
        break;
      case "openCloseout":
        unityBuildStore.setIsViewingExtension(true);
        break;
      case "resetAssembly":
        ResetStates.resetCreateAssemblyStates(
          assemblyStore,
          itemStore,
          folderStore,
          unityBuildStore
        );
        break;
    }
    if (props?.setStateFunctions && props.setStateFunctions.length > 0) {
      props.setStateFunctions.forEach((setStateFunction) => {
        setStateFunction.setStateFunction(setStateFunction.prop);
      });
    }
  };
};
