import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { CreateItem } from "./CreateItem";
import { AssignCosts } from "./AssignCosts";

import { CreateAttribute } from "./attributes/CreateAttribute";
import { useStore } from "zustand";
import { a11yProps, CustomTabPanel } from "../../../customTabs/CustomTabPanel";
import { useFetchItemsByFileId } from "../../../hooks/useFetchItemsByFileId";
import { useGlobalItemStore } from "../../../states/globalItemStore";
import { useUnityBuildStore } from "../../../states/store";

export function CreateItemTab() {
  //zustand states
  const { userBackgroundColor } = useStore(useUnityBuildStore);
  const { currentItem } = useStore(useGlobalItemStore);

  //react-query queries
  const { data: items } = useFetchItemsByFileId(currentItem?.itemid || "");

  //local state
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        height: "100%",
        backgroundColor: userBackgroundColor,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        aria-label="scrollable auto tabs example"
        style={{ flexShrink: 0 }} // Keep tabs fixed at the top
      >
        <Tab label="Item" {...a11yProps(0)} />
        <Tab label="Costs" {...a11yProps(1)} disabled={!currentItem} />
        <Tab label="Attributes" {...a11yProps(2)} />
        {/* <Tab label="UOM" {...a11yProps(3)} /> */}
      </Tabs>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CustomTabPanel value={value} index={0}>
          <div style={{ maxHeight: "100%", overflow: "auto", padding: "16px" }}>
            <CreateItem items={items || []} />
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div style={{ height: "100%", overflow: "auto", padding: "16px" }}>
            <AssignCosts itemId={currentItem?.itemid || ""} />
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <div style={{ height: "100%", overflow: "auto", padding: "16px" }}>
            <CreateAttribute />
          </div>
        </CustomTabPanel>
        {/* <CustomTabPanel value={value} index={3}>
          <div style={{ height: "100%", overflow: "auto", padding: "16px" }}>
            <CreateUoM />
          </div>
        </CustomTabPanel> */}
      </div>
    </div>
  );
}
