import { InferenceCountObject } from "../states/inferenceStore";
import { Object as ProtoObject } from "../api/protosCompiled/object/object_pb";

/**
 * Filters inference objects by page number and selected object ID.
 * Extracts unique class names based on the class map and updates global state using Zustand.
 *
 * @param inferenceCountObjects - Array of inference objects to filter.
 * @param currentPage - The current page index to filter by.
 * @param selectedObjectId - The selected object ID to filter by.
 * @param classMap - Mapping of class IDs to class names.
 * @param selectedValue - Selected class from dropdown.
 * @param setInferenceItemFilter - Zustand action to update the inference item filter.
 * @returns An object containing filtered inference objects and unique class names.
 */
export const filterInferenceObjects = (
  inferenceCountObjects: InferenceCountObject[],
  selectedObject: ProtoObject.AsObject | null,
  classMap: Record<string, string> | undefined,
  selectedValue: string, // Selected class from dropdown
  setInferenceItemFilter: (filter: string) => void
) => {
  const uniqueClassNames = new Set<string>();

  const filteredInferenceCountObjects = inferenceCountObjects.filter(
    (inferenceObject) =>
      inferenceObject.objectid === selectedObject?.id
  );

  filteredInferenceCountObjects.forEach((inferenceObject) => {
    inferenceObject.resultsList.forEach((result) => {
      result.labelsList.forEach((label) => {
        const classId = label.toString();
        const className = classMap ? classMap[classId] : classId;

        if (!uniqueClassNames.has(className)) {
          uniqueClassNames.add(className);
        }
      });
    });
  });

  // Get the class name from the classMap
  const className = Object.keys(classMap || {}).find(
    (key) => classMap![key] === selectedValue
  );

  if (className && selectedValue) {
    setInferenceItemFilter(selectedValue);
  } else {
    console.warn("Class name not found in classMap for value:", selectedValue);
  }

  return {
    filteredInferenceCountObjects,
    uniqueClassNames: Array.from(uniqueClassNames),
  };
};
