/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: changeOrderCloseout.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as changeOrderCloseout_pb from './changeOrderCloseout_pb'; // proto import: "changeOrderCloseout.proto"


export class ChangeOrderCloseoutServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetExtendChangeOrder = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/GetExtendChangeOrder',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.GetExtendChangeOrderRequest,
    changeOrderCloseout_pb.GetExtendChangeOrderResponse,
    (request: changeOrderCloseout_pb.GetExtendChangeOrderRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.GetExtendChangeOrderResponse.deserializeBinary
  );

  getExtendChangeOrder(
    request: changeOrderCloseout_pb.GetExtendChangeOrderRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.GetExtendChangeOrderResponse>;

  getExtendChangeOrder(
    request: changeOrderCloseout_pb.GetExtendChangeOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetExtendChangeOrderResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.GetExtendChangeOrderResponse>;

  getExtendChangeOrder(
    request: changeOrderCloseout_pb.GetExtendChangeOrderRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetExtendChangeOrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/GetExtendChangeOrder',
        request,
        metadata || {},
        this.methodDescriptorGetExtendChangeOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/GetExtendChangeOrder',
    request,
    metadata || {},
    this.methodDescriptorGetExtendChangeOrder);
  }

  methodDescriptorUpdateExtendChangeOrder = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateExtendChangeOrder',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateExtendChangeOrderRequest,
    changeOrderCloseout_pb.UpdateExtendChangeOrderResponse,
    (request: changeOrderCloseout_pb.UpdateExtendChangeOrderRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateExtendChangeOrderResponse.deserializeBinary
  );

  updateExtendChangeOrder(
    request: changeOrderCloseout_pb.UpdateExtendChangeOrderRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateExtendChangeOrderResponse>;

  updateExtendChangeOrder(
    request: changeOrderCloseout_pb.UpdateExtendChangeOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateExtendChangeOrderResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateExtendChangeOrderResponse>;

  updateExtendChangeOrder(
    request: changeOrderCloseout_pb.UpdateExtendChangeOrderRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateExtendChangeOrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateExtendChangeOrder',
        request,
        metadata || {},
        this.methodDescriptorUpdateExtendChangeOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateExtendChangeOrder',
    request,
    metadata || {},
    this.methodDescriptorUpdateExtendChangeOrder);
  }

  methodDescriptorCreateDirectLaborChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/CreateDirectLaborChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.CreateDirectLaborChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.CreateDirectLaborChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.CreateDirectLaborChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.CreateDirectLaborChangeOrderCloseoutResponse.deserializeBinary
  );

  createDirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateDirectLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.CreateDirectLaborChangeOrderCloseoutResponse>;

  createDirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateDirectLaborChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateDirectLaborChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.CreateDirectLaborChangeOrderCloseoutResponse>;

  createDirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateDirectLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateDirectLaborChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/CreateDirectLaborChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateDirectLaborChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/CreateDirectLaborChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateDirectLaborChangeOrderCloseout);
  }

  methodDescriptorGetDirectLaborChangeOrderCloseouts = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/GetDirectLaborChangeOrderCloseouts',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.GetDirectLaborChangeOrderCloseoutsRequest,
    changeOrderCloseout_pb.GetDirectLaborChangeOrderCloseoutsResponse,
    (request: changeOrderCloseout_pb.GetDirectLaborChangeOrderCloseoutsRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.GetDirectLaborChangeOrderCloseoutsResponse.deserializeBinary
  );

  getDirectLaborChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetDirectLaborChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.GetDirectLaborChangeOrderCloseoutsResponse>;

  getDirectLaborChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetDirectLaborChangeOrderCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetDirectLaborChangeOrderCloseoutsResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.GetDirectLaborChangeOrderCloseoutsResponse>;

  getDirectLaborChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetDirectLaborChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetDirectLaborChangeOrderCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/GetDirectLaborChangeOrderCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetDirectLaborChangeOrderCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/GetDirectLaborChangeOrderCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetDirectLaborChangeOrderCloseouts);
  }

  methodDescriptorUpdateDirectLaborChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateDirectLaborChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateDirectLaborChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.UpdateDirectLaborChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.UpdateDirectLaborChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateDirectLaborChangeOrderCloseoutResponse.deserializeBinary
  );

  updateDirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateDirectLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateDirectLaborChangeOrderCloseoutResponse>;

  updateDirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateDirectLaborChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateDirectLaborChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateDirectLaborChangeOrderCloseoutResponse>;

  updateDirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateDirectLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateDirectLaborChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateDirectLaborChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateDirectLaborChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateDirectLaborChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateDirectLaborChangeOrderCloseout);
  }

  methodDescriptorDeleteDirectLaborChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/DeleteDirectLaborChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.DeleteDirectLaborChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.DeleteDirectLaborChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.DeleteDirectLaborChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.DeleteDirectLaborChangeOrderCloseoutResponse.deserializeBinary
  );

  deleteDirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteDirectLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.DeleteDirectLaborChangeOrderCloseoutResponse>;

  deleteDirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteDirectLaborChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteDirectLaborChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.DeleteDirectLaborChangeOrderCloseoutResponse>;

  deleteDirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteDirectLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteDirectLaborChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/DeleteDirectLaborChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteDirectLaborChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/DeleteDirectLaborChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteDirectLaborChangeOrderCloseout);
  }

  methodDescriptorCreateLaborFactoringChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/CreateLaborFactoringChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.CreateLaborFactoringChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.CreateLaborFactoringChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.CreateLaborFactoringChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.CreateLaborFactoringChangeOrderCloseoutResponse.deserializeBinary
  );

  createLaborFactoringChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateLaborFactoringChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.CreateLaborFactoringChangeOrderCloseoutResponse>;

  createLaborFactoringChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateLaborFactoringChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateLaborFactoringChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.CreateLaborFactoringChangeOrderCloseoutResponse>;

  createLaborFactoringChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateLaborFactoringChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateLaborFactoringChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/CreateLaborFactoringChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateLaborFactoringChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/CreateLaborFactoringChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateLaborFactoringChangeOrderCloseout);
  }

  methodDescriptorGetLaborFactoringChangeOrderCloseouts = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/GetLaborFactoringChangeOrderCloseouts',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.GetLaborFactoringChangeOrderCloseoutsRequest,
    changeOrderCloseout_pb.GetLaborFactoringChangeOrderCloseoutsResponse,
    (request: changeOrderCloseout_pb.GetLaborFactoringChangeOrderCloseoutsRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.GetLaborFactoringChangeOrderCloseoutsResponse.deserializeBinary
  );

  getLaborFactoringChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetLaborFactoringChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.GetLaborFactoringChangeOrderCloseoutsResponse>;

  getLaborFactoringChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetLaborFactoringChangeOrderCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetLaborFactoringChangeOrderCloseoutsResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.GetLaborFactoringChangeOrderCloseoutsResponse>;

  getLaborFactoringChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetLaborFactoringChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetLaborFactoringChangeOrderCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/GetLaborFactoringChangeOrderCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetLaborFactoringChangeOrderCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/GetLaborFactoringChangeOrderCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetLaborFactoringChangeOrderCloseouts);
  }

  methodDescriptorUpdateLaborFactoringChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateLaborFactoringChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateLaborFactoringChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.UpdateLaborFactoringChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.UpdateLaborFactoringChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateLaborFactoringChangeOrderCloseoutResponse.deserializeBinary
  );

  updateLaborFactoringChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateLaborFactoringChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateLaborFactoringChangeOrderCloseoutResponse>;

  updateLaborFactoringChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateLaborFactoringChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateLaborFactoringChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateLaborFactoringChangeOrderCloseoutResponse>;

  updateLaborFactoringChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateLaborFactoringChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateLaborFactoringChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateLaborFactoringChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateLaborFactoringChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateLaborFactoringChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateLaborFactoringChangeOrderCloseout);
  }

  methodDescriptorDeleteLaborFactoringChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/DeleteLaborFactoringChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.DeleteLaborFactoringChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.DeleteLaborFactoringChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.DeleteLaborFactoringChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.DeleteLaborFactoringChangeOrderCloseoutResponse.deserializeBinary
  );

  deleteLaborFactoringChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteLaborFactoringChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.DeleteLaborFactoringChangeOrderCloseoutResponse>;

  deleteLaborFactoringChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteLaborFactoringChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteLaborFactoringChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.DeleteLaborFactoringChangeOrderCloseoutResponse>;

  deleteLaborFactoringChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteLaborFactoringChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteLaborFactoringChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/DeleteLaborFactoringChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteLaborFactoringChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/DeleteLaborFactoringChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteLaborFactoringChangeOrderCloseout);
  }

  methodDescriptorCreateIncidentalLaborChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/CreateIncidentalLaborChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.CreateIncidentalLaborChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.CreateIncidentalLaborChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.CreateIncidentalLaborChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.CreateIncidentalLaborChangeOrderCloseoutResponse.deserializeBinary
  );

  createIncidentalLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateIncidentalLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.CreateIncidentalLaborChangeOrderCloseoutResponse>;

  createIncidentalLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateIncidentalLaborChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateIncidentalLaborChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.CreateIncidentalLaborChangeOrderCloseoutResponse>;

  createIncidentalLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateIncidentalLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateIncidentalLaborChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/CreateIncidentalLaborChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateIncidentalLaborChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/CreateIncidentalLaborChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateIncidentalLaborChangeOrderCloseout);
  }

  methodDescriptorGetIncidentalLaborChangeOrderCloseouts = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/GetIncidentalLaborChangeOrderCloseouts',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.GetIncidentalLaborChangeOrderCloseoutsRequest,
    changeOrderCloseout_pb.GetIncidentalLaborChangeOrderCloseoutsResponse,
    (request: changeOrderCloseout_pb.GetIncidentalLaborChangeOrderCloseoutsRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.GetIncidentalLaborChangeOrderCloseoutsResponse.deserializeBinary
  );

  getIncidentalLaborChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetIncidentalLaborChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.GetIncidentalLaborChangeOrderCloseoutsResponse>;

  getIncidentalLaborChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetIncidentalLaborChangeOrderCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetIncidentalLaborChangeOrderCloseoutsResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.GetIncidentalLaborChangeOrderCloseoutsResponse>;

  getIncidentalLaborChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetIncidentalLaborChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetIncidentalLaborChangeOrderCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/GetIncidentalLaborChangeOrderCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetIncidentalLaborChangeOrderCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/GetIncidentalLaborChangeOrderCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetIncidentalLaborChangeOrderCloseouts);
  }

  methodDescriptorUpdateIncidentalLaborChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateIncidentalLaborChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateIncidentalLaborChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.UpdateIncidentalLaborChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.UpdateIncidentalLaborChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateIncidentalLaborChangeOrderCloseoutResponse.deserializeBinary
  );

  updateIncidentalLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateIncidentalLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateIncidentalLaborChangeOrderCloseoutResponse>;

  updateIncidentalLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateIncidentalLaborChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateIncidentalLaborChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateIncidentalLaborChangeOrderCloseoutResponse>;

  updateIncidentalLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateIncidentalLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateIncidentalLaborChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateIncidentalLaborChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateIncidentalLaborChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateIncidentalLaborChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateIncidentalLaborChangeOrderCloseout);
  }

  methodDescriptorDeleteIncidentalLaborChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/DeleteIncidentalLaborChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.DeleteIncidentalLaborChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.DeleteIncidentalLaborChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.DeleteIncidentalLaborChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.DeleteIncidentalLaborChangeOrderCloseoutResponse.deserializeBinary
  );

  deleteIncidentalLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteIncidentalLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.DeleteIncidentalLaborChangeOrderCloseoutResponse>;

  deleteIncidentalLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteIncidentalLaborChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteIncidentalLaborChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.DeleteIncidentalLaborChangeOrderCloseoutResponse>;

  deleteIncidentalLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteIncidentalLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteIncidentalLaborChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/DeleteIncidentalLaborChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteIncidentalLaborChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/DeleteIncidentalLaborChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteIncidentalLaborChangeOrderCloseout);
  }

  methodDescriptorCreateIndirectLaborChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/CreateIndirectLaborChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.CreateIndirectLaborChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.CreateIndirectLaborChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.CreateIndirectLaborChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.CreateIndirectLaborChangeOrderCloseoutResponse.deserializeBinary
  );

  createIndirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateIndirectLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.CreateIndirectLaborChangeOrderCloseoutResponse>;

  createIndirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateIndirectLaborChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateIndirectLaborChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.CreateIndirectLaborChangeOrderCloseoutResponse>;

  createIndirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateIndirectLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateIndirectLaborChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/CreateIndirectLaborChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateIndirectLaborChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/CreateIndirectLaborChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateIndirectLaborChangeOrderCloseout);
  }

  methodDescriptorGetIndirectLaborChangeOrderCloseouts = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/GetIndirectLaborChangeOrderCloseouts',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.GetIndirectLaborChangeOrderCloseoutsRequest,
    changeOrderCloseout_pb.GetIndirectLaborChangeOrderCloseoutsResponse,
    (request: changeOrderCloseout_pb.GetIndirectLaborChangeOrderCloseoutsRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.GetIndirectLaborChangeOrderCloseoutsResponse.deserializeBinary
  );

  getIndirectLaborChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetIndirectLaborChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.GetIndirectLaborChangeOrderCloseoutsResponse>;

  getIndirectLaborChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetIndirectLaborChangeOrderCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetIndirectLaborChangeOrderCloseoutsResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.GetIndirectLaborChangeOrderCloseoutsResponse>;

  getIndirectLaborChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetIndirectLaborChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetIndirectLaborChangeOrderCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/GetIndirectLaborChangeOrderCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetIndirectLaborChangeOrderCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/GetIndirectLaborChangeOrderCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetIndirectLaborChangeOrderCloseouts);
  }

  methodDescriptorUpdateIndirectLaborChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateIndirectLaborChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateIndirectLaborChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.UpdateIndirectLaborChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.UpdateIndirectLaborChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateIndirectLaborChangeOrderCloseoutResponse.deserializeBinary
  );

  updateIndirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateIndirectLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateIndirectLaborChangeOrderCloseoutResponse>;

  updateIndirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateIndirectLaborChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateIndirectLaborChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateIndirectLaborChangeOrderCloseoutResponse>;

  updateIndirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateIndirectLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateIndirectLaborChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateIndirectLaborChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateIndirectLaborChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateIndirectLaborChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateIndirectLaborChangeOrderCloseout);
  }

  methodDescriptorDeleteIndirectLaborChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/DeleteIndirectLaborChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.DeleteIndirectLaborChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.DeleteIndirectLaborChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.DeleteIndirectLaborChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.DeleteIndirectLaborChangeOrderCloseoutResponse.deserializeBinary
  );

  deleteIndirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteIndirectLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.DeleteIndirectLaborChangeOrderCloseoutResponse>;

  deleteIndirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteIndirectLaborChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteIndirectLaborChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.DeleteIndirectLaborChangeOrderCloseoutResponse>;

  deleteIndirectLaborChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteIndirectLaborChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteIndirectLaborChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/DeleteIndirectLaborChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteIndirectLaborChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/DeleteIndirectLaborChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteIndirectLaborChangeOrderCloseout);
  }

  methodDescriptorCreateEquipmentChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/CreateEquipmentChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.CreateEquipmentChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.CreateEquipmentChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.CreateEquipmentChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.CreateEquipmentChangeOrderCloseoutResponse.deserializeBinary
  );

  createEquipmentChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateEquipmentChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.CreateEquipmentChangeOrderCloseoutResponse>;

  createEquipmentChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateEquipmentChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateEquipmentChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.CreateEquipmentChangeOrderCloseoutResponse>;

  createEquipmentChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateEquipmentChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateEquipmentChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/CreateEquipmentChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateEquipmentChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/CreateEquipmentChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateEquipmentChangeOrderCloseout);
  }

  methodDescriptorGetEquipmentChangeOrderCloseouts = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/GetEquipmentChangeOrderCloseouts',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.GetEquipmentChangeOrderCloseoutsRequest,
    changeOrderCloseout_pb.GetEquipmentChangeOrderCloseoutsResponse,
    (request: changeOrderCloseout_pb.GetEquipmentChangeOrderCloseoutsRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.GetEquipmentChangeOrderCloseoutsResponse.deserializeBinary
  );

  getEquipmentChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetEquipmentChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.GetEquipmentChangeOrderCloseoutsResponse>;

  getEquipmentChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetEquipmentChangeOrderCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetEquipmentChangeOrderCloseoutsResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.GetEquipmentChangeOrderCloseoutsResponse>;

  getEquipmentChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetEquipmentChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetEquipmentChangeOrderCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/GetEquipmentChangeOrderCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetEquipmentChangeOrderCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/GetEquipmentChangeOrderCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetEquipmentChangeOrderCloseouts);
  }

  methodDescriptorUpdateEquipmentChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateEquipmentChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateEquipmentChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.UpdateEquipmentChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.UpdateEquipmentChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateEquipmentChangeOrderCloseoutResponse.deserializeBinary
  );

  updateEquipmentChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateEquipmentChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateEquipmentChangeOrderCloseoutResponse>;

  updateEquipmentChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateEquipmentChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateEquipmentChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateEquipmentChangeOrderCloseoutResponse>;

  updateEquipmentChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateEquipmentChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateEquipmentChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateEquipmentChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateEquipmentChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateEquipmentChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateEquipmentChangeOrderCloseout);
  }

  methodDescriptorDeleteEquipmentChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/DeleteEquipmentChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.DeleteEquipmentChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.DeleteEquipmentChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.DeleteEquipmentChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.DeleteEquipmentChangeOrderCloseoutResponse.deserializeBinary
  );

  deleteEquipmentChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteEquipmentChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.DeleteEquipmentChangeOrderCloseoutResponse>;

  deleteEquipmentChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteEquipmentChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteEquipmentChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.DeleteEquipmentChangeOrderCloseoutResponse>;

  deleteEquipmentChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteEquipmentChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteEquipmentChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/DeleteEquipmentChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteEquipmentChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/DeleteEquipmentChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteEquipmentChangeOrderCloseout);
  }

  methodDescriptorCreateGeneralExpenseChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/CreateGeneralExpenseChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.CreateGeneralExpenseChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.CreateGeneralExpenseChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.CreateGeneralExpenseChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.CreateGeneralExpenseChangeOrderCloseoutResponse.deserializeBinary
  );

  createGeneralExpenseChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateGeneralExpenseChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.CreateGeneralExpenseChangeOrderCloseoutResponse>;

  createGeneralExpenseChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateGeneralExpenseChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateGeneralExpenseChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.CreateGeneralExpenseChangeOrderCloseoutResponse>;

  createGeneralExpenseChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateGeneralExpenseChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateGeneralExpenseChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/CreateGeneralExpenseChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateGeneralExpenseChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/CreateGeneralExpenseChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateGeneralExpenseChangeOrderCloseout);
  }

  methodDescriptorGetGeneralExpenseChangeOrderCloseouts = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/GetGeneralExpenseChangeOrderCloseouts',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.GetGeneralExpenseChangeOrderCloseoutsRequest,
    changeOrderCloseout_pb.GetGeneralExpenseChangeOrderCloseoutsResponse,
    (request: changeOrderCloseout_pb.GetGeneralExpenseChangeOrderCloseoutsRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.GetGeneralExpenseChangeOrderCloseoutsResponse.deserializeBinary
  );

  getGeneralExpenseChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetGeneralExpenseChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.GetGeneralExpenseChangeOrderCloseoutsResponse>;

  getGeneralExpenseChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetGeneralExpenseChangeOrderCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetGeneralExpenseChangeOrderCloseoutsResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.GetGeneralExpenseChangeOrderCloseoutsResponse>;

  getGeneralExpenseChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetGeneralExpenseChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetGeneralExpenseChangeOrderCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/GetGeneralExpenseChangeOrderCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetGeneralExpenseChangeOrderCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/GetGeneralExpenseChangeOrderCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetGeneralExpenseChangeOrderCloseouts);
  }

  methodDescriptorUpdateGeneralExpenseChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateGeneralExpenseChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateGeneralExpenseChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.UpdateGeneralExpenseChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.UpdateGeneralExpenseChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateGeneralExpenseChangeOrderCloseoutResponse.deserializeBinary
  );

  updateGeneralExpenseChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateGeneralExpenseChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateGeneralExpenseChangeOrderCloseoutResponse>;

  updateGeneralExpenseChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateGeneralExpenseChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateGeneralExpenseChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateGeneralExpenseChangeOrderCloseoutResponse>;

  updateGeneralExpenseChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateGeneralExpenseChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateGeneralExpenseChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateGeneralExpenseChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateGeneralExpenseChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateGeneralExpenseChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateGeneralExpenseChangeOrderCloseout);
  }

  methodDescriptorDeleteGeneralExpenseChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/DeleteGeneralExpenseChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.DeleteGeneralExpenseChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.DeleteGeneralExpenseChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.DeleteGeneralExpenseChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.DeleteGeneralExpenseChangeOrderCloseoutResponse.deserializeBinary
  );

  deleteGeneralExpenseChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteGeneralExpenseChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.DeleteGeneralExpenseChangeOrderCloseoutResponse>;

  deleteGeneralExpenseChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteGeneralExpenseChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteGeneralExpenseChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.DeleteGeneralExpenseChangeOrderCloseoutResponse>;

  deleteGeneralExpenseChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteGeneralExpenseChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteGeneralExpenseChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/DeleteGeneralExpenseChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteGeneralExpenseChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/DeleteGeneralExpenseChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteGeneralExpenseChangeOrderCloseout);
  }

  methodDescriptorCreateSubcontractChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/CreateSubcontractChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.CreateSubcontractChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.CreateSubcontractChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.CreateSubcontractChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.CreateSubcontractChangeOrderCloseoutResponse.deserializeBinary
  );

  createSubcontractChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateSubcontractChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.CreateSubcontractChangeOrderCloseoutResponse>;

  createSubcontractChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateSubcontractChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateSubcontractChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.CreateSubcontractChangeOrderCloseoutResponse>;

  createSubcontractChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateSubcontractChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateSubcontractChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/CreateSubcontractChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateSubcontractChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/CreateSubcontractChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateSubcontractChangeOrderCloseout);
  }

  methodDescriptorGetSubcontractChangeOrderCloseouts = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/GetSubcontractChangeOrderCloseouts',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.GetSubcontractChangeOrderCloseoutsRequest,
    changeOrderCloseout_pb.GetSubcontractChangeOrderCloseoutsResponse,
    (request: changeOrderCloseout_pb.GetSubcontractChangeOrderCloseoutsRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.GetSubcontractChangeOrderCloseoutsResponse.deserializeBinary
  );

  getSubcontractChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetSubcontractChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.GetSubcontractChangeOrderCloseoutsResponse>;

  getSubcontractChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetSubcontractChangeOrderCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetSubcontractChangeOrderCloseoutsResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.GetSubcontractChangeOrderCloseoutsResponse>;

  getSubcontractChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetSubcontractChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetSubcontractChangeOrderCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/GetSubcontractChangeOrderCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetSubcontractChangeOrderCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/GetSubcontractChangeOrderCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetSubcontractChangeOrderCloseouts);
  }

  methodDescriptorUpdateSubcontractChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateSubcontractChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateSubcontractChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.UpdateSubcontractChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.UpdateSubcontractChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateSubcontractChangeOrderCloseoutResponse.deserializeBinary
  );

  updateSubcontractChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateSubcontractChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateSubcontractChangeOrderCloseoutResponse>;

  updateSubcontractChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateSubcontractChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateSubcontractChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateSubcontractChangeOrderCloseoutResponse>;

  updateSubcontractChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateSubcontractChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateSubcontractChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateSubcontractChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateSubcontractChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateSubcontractChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateSubcontractChangeOrderCloseout);
  }

  methodDescriptorDeleteSubcontractChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/DeleteSubcontractChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.DeleteSubcontractChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.DeleteSubcontractChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.DeleteSubcontractChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.DeleteSubcontractChangeOrderCloseoutResponse.deserializeBinary
  );

  deleteSubcontractChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteSubcontractChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.DeleteSubcontractChangeOrderCloseoutResponse>;

  deleteSubcontractChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteSubcontractChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteSubcontractChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.DeleteSubcontractChangeOrderCloseoutResponse>;

  deleteSubcontractChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteSubcontractChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteSubcontractChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/DeleteSubcontractChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteSubcontractChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/DeleteSubcontractChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteSubcontractChangeOrderCloseout);
  }

  methodDescriptorCreateQuoteChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/CreateQuoteChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.CreateQuoteChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.CreateQuoteChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.CreateQuoteChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.CreateQuoteChangeOrderCloseoutResponse.deserializeBinary
  );

  createQuoteChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateQuoteChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.CreateQuoteChangeOrderCloseoutResponse>;

  createQuoteChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateQuoteChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateQuoteChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.CreateQuoteChangeOrderCloseoutResponse>;

  createQuoteChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateQuoteChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateQuoteChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/CreateQuoteChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateQuoteChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/CreateQuoteChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateQuoteChangeOrderCloseout);
  }

  methodDescriptorGetQuoteChangeOrderCloseouts = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/GetQuoteChangeOrderCloseouts',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.GetQuoteChangeOrderCloseoutsRequest,
    changeOrderCloseout_pb.GetQuoteChangeOrderCloseoutsResponse,
    (request: changeOrderCloseout_pb.GetQuoteChangeOrderCloseoutsRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.GetQuoteChangeOrderCloseoutsResponse.deserializeBinary
  );

  getQuoteChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetQuoteChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.GetQuoteChangeOrderCloseoutsResponse>;

  getQuoteChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetQuoteChangeOrderCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetQuoteChangeOrderCloseoutsResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.GetQuoteChangeOrderCloseoutsResponse>;

  getQuoteChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetQuoteChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetQuoteChangeOrderCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/GetQuoteChangeOrderCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetQuoteChangeOrderCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/GetQuoteChangeOrderCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetQuoteChangeOrderCloseouts);
  }

  methodDescriptorUpdateQuoteChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateQuoteChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateQuoteChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.UpdateQuoteChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.UpdateQuoteChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateQuoteChangeOrderCloseoutResponse.deserializeBinary
  );

  updateQuoteChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateQuoteChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateQuoteChangeOrderCloseoutResponse>;

  updateQuoteChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateQuoteChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateQuoteChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateQuoteChangeOrderCloseoutResponse>;

  updateQuoteChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateQuoteChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateQuoteChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateQuoteChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateQuoteChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateQuoteChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateQuoteChangeOrderCloseout);
  }

  methodDescriptorDeleteQuoteChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/DeleteQuoteChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.DeleteQuoteChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.DeleteQuoteChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.DeleteQuoteChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.DeleteQuoteChangeOrderCloseoutResponse.deserializeBinary
  );

  deleteQuoteChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteQuoteChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.DeleteQuoteChangeOrderCloseoutResponse>;

  deleteQuoteChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteQuoteChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteQuoteChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.DeleteQuoteChangeOrderCloseoutResponse>;

  deleteQuoteChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteQuoteChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteQuoteChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/DeleteQuoteChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteQuoteChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/DeleteQuoteChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteQuoteChangeOrderCloseout);
  }

  methodDescriptorGetTotalFinalPriceChangeOrderCloseouts = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/GetTotalFinalPriceChangeOrderCloseouts',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.GetTotalFinalPriceChangeOrderCloseoutsRequest,
    changeOrderCloseout_pb.GetTotalFinalPriceChangeOrderCloseoutsResponse,
    (request: changeOrderCloseout_pb.GetTotalFinalPriceChangeOrderCloseoutsRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.GetTotalFinalPriceChangeOrderCloseoutsResponse.deserializeBinary
  );

  getTotalFinalPriceChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetTotalFinalPriceChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.GetTotalFinalPriceChangeOrderCloseoutsResponse>;

  getTotalFinalPriceChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetTotalFinalPriceChangeOrderCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetTotalFinalPriceChangeOrderCloseoutsResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.GetTotalFinalPriceChangeOrderCloseoutsResponse>;

  getTotalFinalPriceChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetTotalFinalPriceChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetTotalFinalPriceChangeOrderCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/GetTotalFinalPriceChangeOrderCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetTotalFinalPriceChangeOrderCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/GetTotalFinalPriceChangeOrderCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetTotalFinalPriceChangeOrderCloseouts);
  }

  methodDescriptorUpdateTotalFinalPriceChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateTotalFinalPriceChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateTotalFinalPriceChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.UpdateTotalFinalPriceChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.UpdateTotalFinalPriceChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateTotalFinalPriceChangeOrderCloseoutResponse.deserializeBinary
  );

  updateTotalFinalPriceChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateTotalFinalPriceChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateTotalFinalPriceChangeOrderCloseoutResponse>;

  updateTotalFinalPriceChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateTotalFinalPriceChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateTotalFinalPriceChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateTotalFinalPriceChangeOrderCloseoutResponse>;

  updateTotalFinalPriceChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateTotalFinalPriceChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateTotalFinalPriceChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateTotalFinalPriceChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateTotalFinalPriceChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateTotalFinalPriceChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateTotalFinalPriceChangeOrderCloseout);
  }

  methodDescriptorUpdateChangeOrderLaborFactoringAllocation = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateChangeOrderLaborFactoringAllocation',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateChangeOrderLaborFactoringAllocationRequest,
    changeOrderCloseout_pb.UpdateChangeOrderLaborFactoringAllocationResponse,
    (request: changeOrderCloseout_pb.UpdateChangeOrderLaborFactoringAllocationRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateChangeOrderLaborFactoringAllocationResponse.deserializeBinary
  );

  updateChangeOrderLaborFactoringAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderLaborFactoringAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateChangeOrderLaborFactoringAllocationResponse>;

  updateChangeOrderLaborFactoringAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderLaborFactoringAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderLaborFactoringAllocationResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateChangeOrderLaborFactoringAllocationResponse>;

  updateChangeOrderLaborFactoringAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderLaborFactoringAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderLaborFactoringAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateChangeOrderLaborFactoringAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateChangeOrderLaborFactoringAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateChangeOrderLaborFactoringAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateChangeOrderLaborFactoringAllocation);
  }

  methodDescriptorUpdateChangeOrderIncidentalLaborAllocation = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateChangeOrderIncidentalLaborAllocation',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateChangeOrderIncidentalLaborAllocationRequest,
    changeOrderCloseout_pb.UpdateChangeOrderIncidentalLaborAllocationResponse,
    (request: changeOrderCloseout_pb.UpdateChangeOrderIncidentalLaborAllocationRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateChangeOrderIncidentalLaborAllocationResponse.deserializeBinary
  );

  updateChangeOrderIncidentalLaborAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderIncidentalLaborAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateChangeOrderIncidentalLaborAllocationResponse>;

  updateChangeOrderIncidentalLaborAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderIncidentalLaborAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderIncidentalLaborAllocationResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateChangeOrderIncidentalLaborAllocationResponse>;

  updateChangeOrderIncidentalLaborAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderIncidentalLaborAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderIncidentalLaborAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateChangeOrderIncidentalLaborAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateChangeOrderIncidentalLaborAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateChangeOrderIncidentalLaborAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateChangeOrderIncidentalLaborAllocation);
  }

  methodDescriptorUpdateChangeOrderIndirectLaborAllocation = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateChangeOrderIndirectLaborAllocation',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateChangeOrderIndirectLaborAllocationRequest,
    changeOrderCloseout_pb.UpdateChangeOrderIndirectLaborAllocationResponse,
    (request: changeOrderCloseout_pb.UpdateChangeOrderIndirectLaborAllocationRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateChangeOrderIndirectLaborAllocationResponse.deserializeBinary
  );

  updateChangeOrderIndirectLaborAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderIndirectLaborAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateChangeOrderIndirectLaborAllocationResponse>;

  updateChangeOrderIndirectLaborAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderIndirectLaborAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderIndirectLaborAllocationResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateChangeOrderIndirectLaborAllocationResponse>;

  updateChangeOrderIndirectLaborAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderIndirectLaborAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderIndirectLaborAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateChangeOrderIndirectLaborAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateChangeOrderIndirectLaborAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateChangeOrderIndirectLaborAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateChangeOrderIndirectLaborAllocation);
  }

  methodDescriptorUpdateChangeOrderEquipmentAllocation = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateChangeOrderEquipmentAllocation',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateChangeOrderEquipmentAllocationRequest,
    changeOrderCloseout_pb.UpdateChangeOrderEquipmentAllocationResponse,
    (request: changeOrderCloseout_pb.UpdateChangeOrderEquipmentAllocationRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateChangeOrderEquipmentAllocationResponse.deserializeBinary
  );

  updateChangeOrderEquipmentAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderEquipmentAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateChangeOrderEquipmentAllocationResponse>;

  updateChangeOrderEquipmentAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderEquipmentAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderEquipmentAllocationResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateChangeOrderEquipmentAllocationResponse>;

  updateChangeOrderEquipmentAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderEquipmentAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderEquipmentAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateChangeOrderEquipmentAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateChangeOrderEquipmentAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateChangeOrderEquipmentAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateChangeOrderEquipmentAllocation);
  }

  methodDescriptorUpdateChangeOrderGeneralExpenseAllocation = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateChangeOrderGeneralExpenseAllocation',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateChangeOrderGeneralExpenseAllocationRequest,
    changeOrderCloseout_pb.UpdateChangeOrderGeneralExpenseAllocationResponse,
    (request: changeOrderCloseout_pb.UpdateChangeOrderGeneralExpenseAllocationRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateChangeOrderGeneralExpenseAllocationResponse.deserializeBinary
  );

  updateChangeOrderGeneralExpenseAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderGeneralExpenseAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateChangeOrderGeneralExpenseAllocationResponse>;

  updateChangeOrderGeneralExpenseAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderGeneralExpenseAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderGeneralExpenseAllocationResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateChangeOrderGeneralExpenseAllocationResponse>;

  updateChangeOrderGeneralExpenseAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderGeneralExpenseAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderGeneralExpenseAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateChangeOrderGeneralExpenseAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateChangeOrderGeneralExpenseAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateChangeOrderGeneralExpenseAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateChangeOrderGeneralExpenseAllocation);
  }

  methodDescriptorUpdateChangeOrderSubcontractAllocation = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateChangeOrderSubcontractAllocation',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateChangeOrderSubcontractAllocationRequest,
    changeOrderCloseout_pb.UpdateChangeOrderSubcontractAllocationResponse,
    (request: changeOrderCloseout_pb.UpdateChangeOrderSubcontractAllocationRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateChangeOrderSubcontractAllocationResponse.deserializeBinary
  );

  updateChangeOrderSubcontractAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderSubcontractAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateChangeOrderSubcontractAllocationResponse>;

  updateChangeOrderSubcontractAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderSubcontractAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderSubcontractAllocationResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateChangeOrderSubcontractAllocationResponse>;

  updateChangeOrderSubcontractAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderSubcontractAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderSubcontractAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateChangeOrderSubcontractAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateChangeOrderSubcontractAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateChangeOrderSubcontractAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateChangeOrderSubcontractAllocation);
  }

  methodDescriptorUpdateChangeOrderQuoteAllocation = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateChangeOrderQuoteAllocation',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateChangeOrderQuoteAllocationRequest,
    changeOrderCloseout_pb.UpdateChangeOrderQuoteAllocationResponse,
    (request: changeOrderCloseout_pb.UpdateChangeOrderQuoteAllocationRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateChangeOrderQuoteAllocationResponse.deserializeBinary
  );

  updateChangeOrderQuoteAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderQuoteAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateChangeOrderQuoteAllocationResponse>;

  updateChangeOrderQuoteAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderQuoteAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderQuoteAllocationResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateChangeOrderQuoteAllocationResponse>;

  updateChangeOrderQuoteAllocation(
    request: changeOrderCloseout_pb.UpdateChangeOrderQuoteAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderQuoteAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateChangeOrderQuoteAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateChangeOrderQuoteAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateChangeOrderQuoteAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateChangeOrderQuoteAllocation);
  }

}

