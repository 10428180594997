import { useEffect, useCallback } from "react";
import { useUnityBuildStore } from "../states/store";
import { useSubmitTakeoffAssembly } from "./useSubmitTakeoffAssembly";
import { useStore } from "zustand";
import { useAssemblyStore } from "../states/AssemblyStore";
import { useHandleGlobalStateState } from "./useHandleGlobalState";
import { useKeyBoardEventStore } from "../states/keyEventStore";

export const useHandleTakeoffKeyboardEvents = (svgRef: any) => {
  const {
    isAddingVertical,
    isCreatingTakeoffAssembly,
    isUpdatingTakeoffAssembly,
    userScale,
  } = useStore(useUnityBuildStore);

  const {
    currentTakeoffAssembly,
    setCurrentTakeoffAssembly } = useStore(useAssemblyStore);

  const {
    isMeasuring,
  } = useStore(useKeyBoardEventStore);

  const submitAssembly = useSubmitTakeoffAssembly();
  const handleGlobalState = useHandleGlobalStateState();

  /**
   * Event Handler for SVG elements
   */
  const handleKeyboardEvents = useCallback(
    async (event: KeyboardEvent | React.KeyboardEvent) => {
      // Only process the event if the SVG is hovered.
      event.stopPropagation();
      if (svgRef.current && !svgRef.current.matches(":hover")) {
        console.log("SVG not hovered");
        return;
      }

      // Get the current state at the time of the event
      const kbStore = useKeyBoardEventStore.getState();

      if (event.key === "Escape") {
        handleGlobalState({
          resetOption: "resetTakeoff",
        });
        return;
      }
      if (kbStore.isMeasuring && event.key === "Enter") {
        kbStore.setIsMeasuring(false);
        return;
      }
      if (event.key === "Control") {
        kbStore.setContinousMode(!kbStore.continousMode);
      }
      if (event.key === "Shift") {
        kbStore.setSnapMode(!kbStore.snapMode);
      }
      if (event.key === "h") {
        kbStore.setEnableCrosshair(!kbStore.enableCrosshair);
      }
      if (event.key === "p") {
        kbStore.setIsViewingTakeoffPallette(!kbStore.isViewingTakeoffPallette);
      }
      if (event.key === "c") {
        kbStore.setShowChatWindow(!kbStore.showChatWindow);
      }
      if (event.key === "m") {
        kbStore.setIsMeasuring(!kbStore.isMeasuring);
      }
      try {
        if (
          event.key === "Enter" &&
          currentTakeoffAssembly &&
          !isAddingVertical
        ) {
          if (isCreatingTakeoffAssembly && isUpdatingTakeoffAssembly) {
            return;
          }
          await submitAssembly({
            shouldUpdateBreakout: isCreatingTakeoffAssembly,
            assembly: currentTakeoffAssembly,
            isCreating: isCreatingTakeoffAssembly,
            isUpdating: isUpdatingTakeoffAssembly,
            pageScale: userScale,
          });
          setCurrentTakeoffAssembly(null);
        }
      } catch (error) {
        console.error("Error submitting assembly:", error);
      }
    },
    // eslint-disable-next-line
    [
      svgRef,
      isMeasuring,
      currentTakeoffAssembly,
      isCreatingTakeoffAssembly,
      isUpdatingTakeoffAssembly,
      submitAssembly,
    ]
  );

  // Attach the event listener globally on mount
  useEffect(() => {
    const handleGlobalKeydown = (event: KeyboardEvent) =>
      handleKeyboardEvents(event);
    window.addEventListener("keydown", handleGlobalKeydown);
    return () => window.removeEventListener("keydown", handleGlobalKeydown);
  }, [handleKeyboardEvents]);

  return handleKeyboardEvents;
};
