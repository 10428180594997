import { useMutation, useQueryClient } from "react-query";
import { ContractChangeOrderAssemblyService as ccoas } from "../api/ContractChangeOrderAssemblyService";
import { ChangeOrderTakeoffAssemblies } from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";

export const useCreateContractChangeOrderAssemblyMutation = () => {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync } = useMutation(
    (data: ChangeOrderTakeoffAssemblies.AsObject) =>
      ccoas.createContractChangeOrderAssembly(data),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["countedAssemblies"]);
      },
      onError: (error) => {
        console.error("Error creating Change Order assembly", error);
      },
    }
  );

  // Return both mutate and mutateAsync for use in components or other hooks
  return { mutate, mutateAsync };
};

export const useUpdateContractChangeOrderAssemblyMutation = () => {
  const queryClient = useQueryClient();

  // Destructure mutate and mutateAsync from the useMutation return value
  const { mutate, mutateAsync } = useMutation(
    (data: ChangeOrderTakeoffAssemblies.AsObject) =>
      ccoas.updateContractChangeOrderAssembly(data),
    {
      onSuccess: () => {
        // Invalidate and refetch to ensure data is fresh
        queryClient.invalidateQueries(["countedAssemblies"]);
      },
      onError: (error) => {
        console.error("Error updating project change order assembly", error);
      },
    }
  );

  // Return both mutate and mutateAsync for flexible usage outside
  return { mutate, mutateAsync };
};

export const useDeleteContractChangeOrderAssemblyMutation = () => {
  const queryClient = useQueryClient();

  // Destructure mutate and mutateAsync from the useMutation return value
  const { mutate, mutateAsync } = useMutation(
    (assemblyId: string) => ccoas.deleteChangeOrderAssembly(assemblyId),
    {
      onSuccess: () => {
        // Invalidate and refetch to ensure data is fresh
        queryClient.invalidateQueries(["countedAssemblies"]);
      },
      onError: (error) => {
        console.error("Error deleting project change order assembly", error);
      },
    }
  );

  // Return both mutate and mutateAsync for flexible usage outside
  return { mutate, mutateAsync };
};

