import { useMutation, useQueryClient } from "react-query";
import { ProjectEstimateAssemblyService as peas } from "../api/ProjectEstimateAssemblyService";
import { EstimateTakeoffAssemblies } from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";

export const useCreateProjectEstimateAssemblyMutation = () => {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync } = useMutation(
    (data: EstimateTakeoffAssemblies.AsObject) =>
      peas.createProjectEstimateAssembly(data),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["countedAssemblies"]);
      },
      onError: (error) => {
        console.error("Error creating project estimate assembly", error);
      },
    }
  );

  // Return both mutate and mutateAsync for use in components or other hooks
  return { mutate, mutateAsync };
};

export const useUpdateProjectEstimateAssemblyMutation = () => {
  const queryClient = useQueryClient();

  // Destructure mutate and mutateAsync from the useMutation return value
  const { mutate, mutateAsync } = useMutation(
    (data: EstimateTakeoffAssemblies.AsObject) =>
      peas.updateProjectEstimateAssembly(data),
    {
      onSuccess: () => {
        // Invalidate and refetch to ensure data is fresh
        queryClient.invalidateQueries(["countedAssemblies"]);
      },
      onError: (error) => {
        console.error("Error updating project estimate assembly", error);
      },
    }
  );

  // Return both mutate and mutateAsync for flexible usage outside
  return { mutate, mutateAsync };
};

export const useDeleteProjectEstimateAssemblyMutation = () => {
  const queryClient = useQueryClient();

  // Destructure mutate and mutateAsync from the useMutation return value
  const { mutate, mutateAsync } = useMutation(
    (assemblyId: string) => peas.deleteEstimateAssembly(assemblyId),
    {
      onSuccess: () => {
        // Invalidate and refetch to ensure data is fresh
        queryClient.invalidateQueries(["countedAssemblies"]);
      },
      onError: (error) => {
        console.error("Error deleting project estimate assembly", error);
      },
    }
  );

  // Return both mutate and mutateAsync for flexible usage outside
  return { mutate, mutateAsync };
};
