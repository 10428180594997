import React, { useEffect, useState } from "react";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { Breakout } from "../../api/protosCompiled/breakout/breakout_pb";
import {
  useFetchChangeOrderBreakouts,
  useFetchEstimateBreakouts,
} from "../../hooks/useFetchBreakout";
import { TakeoffAssembly } from "../../types/AssemblyItemType";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

type BreakoutSelectorProps = {
  currentTakeoffAssembly?: TakeoffAssembly;
  context?: contextType;
  size?: "small" | "medium";
};

type contextType = "takeoffPallette" | "createAssembly";

export const BreakoutSelector = ({
  currentTakeoffAssembly,
  context = "takeoffPallette",
  size = "medium",
}: BreakoutSelectorProps) => {

  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  const selectedChangeOrder = useStore(useUnityBuildStore).selectedChangeOrder;
  const setSelectedBreakout = useStore(useUnityBuildStore).setSelectedBreakout;

  const [localBreakouts, setLocalBreakouts] = useState<Breakout.AsObject[]>([]);
  const [selectedBreakoutId, setSelectedBreakoutId] = useState<string>(
    localBreakouts[0]?.id ?? ""
  );

  // Fetch breakouts from estimate or change order
  const { data: estimateBreakouts } = useFetchEstimateBreakouts(
    selectedEstimate?.estimateid ?? ""
  );
  const { data: changeOrderBreakouts } = useFetchChangeOrderBreakouts(
    selectedChangeOrder?.contractid ?? ""
  );

  useEffect(() => {
    if (estimateBreakouts || changeOrderBreakouts) {
      setLocalBreakouts(
        estimateBreakouts?.breakoutsList ??
        changeOrderBreakouts?.breakoutsList ??
        []
      );
    }
  }, [estimateBreakouts, changeOrderBreakouts]);

  // Set the selected breakout from the takeoffAssembly when it is provided
  useEffect(() => {
    try {
      if (
        currentTakeoffAssembly?.itemsList[0]?.breakout &&
        context === "createAssembly"
      ) {
        const currentBreakoutId =
          currentTakeoffAssembly.itemsList[0]?.breakout?.breakoutid;
        setSelectedBreakoutId(currentBreakoutId);
        const currentBreakout = localBreakouts.find(
          (breakout) => breakout.id === currentBreakoutId
        );
        if (currentBreakout) {
          setSelectedBreakout(currentBreakout);
        }
      } else {
        setSelectedBreakoutId(localBreakouts[0]?.id ?? "");
      }
    } catch (error) {
      console.error("Error setting selected breakout:", error);
    }
  }, [currentTakeoffAssembly, localBreakouts, setSelectedBreakout, context]);

  const handleSetSelectedBreakout = (event: SelectChangeEvent<string>) => {
    const breakoutId = event.target.value;
    setSelectedBreakoutId(breakoutId);

    const selectedBreakout = localBreakouts.find(
      (breakout) => breakout.id === breakoutId
    );
    if (selectedBreakout) {
      setSelectedBreakout(selectedBreakout);
    } else {
      setSelectedBreakout(null);
    }
  };

  return (
    <FormControl style={{ margin: "10px", width: "95%" }}>
      <InputLabel id="demo-simple-select-label">Select Breakout</InputLabel>
      <Select
        onChange={handleSetSelectedBreakout}
        value={selectedBreakoutId}
        displayEmpty
        label="Select Breakout"
        size={size}
      >
        {localBreakouts.map((breakout) => (
          <MenuItem key={breakout.id} value={breakout.id}>
            {`${breakout.breakoutname} (${breakout.multiplier}x)`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
