/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: estimateCloseout.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as estimateCloseout_pb from './estimateCloseout_pb'; // proto import: "estimateCloseout.proto"


export class EstimateCloseoutServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetExtendEstimate = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/GetExtendEstimate',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.GetExtendEstimateRequest,
    estimateCloseout_pb.GetExtendEstimateResponse,
    (request: estimateCloseout_pb.GetExtendEstimateRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.GetExtendEstimateResponse.deserializeBinary
  );

  getExtendEstimate(
    request: estimateCloseout_pb.GetExtendEstimateRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.GetExtendEstimateResponse>;

  getExtendEstimate(
    request: estimateCloseout_pb.GetExtendEstimateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetExtendEstimateResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.GetExtendEstimateResponse>;

  getExtendEstimate(
    request: estimateCloseout_pb.GetExtendEstimateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetExtendEstimateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/GetExtendEstimate',
        request,
        metadata || {},
        this.methodDescriptorGetExtendEstimate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/GetExtendEstimate',
    request,
    metadata || {},
    this.methodDescriptorGetExtendEstimate);
  }

  methodDescriptorUpdateExtendEstimate = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateExtendEstimate',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateExtendEstimateRequest,
    estimateCloseout_pb.UpdateExtendEstimateResponse,
    (request: estimateCloseout_pb.UpdateExtendEstimateRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateExtendEstimateResponse.deserializeBinary
  );

  updateExtendEstimate(
    request: estimateCloseout_pb.UpdateExtendEstimateRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateExtendEstimateResponse>;

  updateExtendEstimate(
    request: estimateCloseout_pb.UpdateExtendEstimateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateExtendEstimateResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateExtendEstimateResponse>;

  updateExtendEstimate(
    request: estimateCloseout_pb.UpdateExtendEstimateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateExtendEstimateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateExtendEstimate',
        request,
        metadata || {},
        this.methodDescriptorUpdateExtendEstimate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateExtendEstimate',
    request,
    metadata || {},
    this.methodDescriptorUpdateExtendEstimate);
  }

  methodDescriptorCreateDirectLaborCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/CreateDirectLaborCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.CreateDirectLaborCloseoutRequest,
    estimateCloseout_pb.CreateDirectLaborCloseoutResponse,
    (request: estimateCloseout_pb.CreateDirectLaborCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.CreateDirectLaborCloseoutResponse.deserializeBinary
  );

  createDirectLaborCloseout(
    request: estimateCloseout_pb.CreateDirectLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.CreateDirectLaborCloseoutResponse>;

  createDirectLaborCloseout(
    request: estimateCloseout_pb.CreateDirectLaborCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateDirectLaborCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.CreateDirectLaborCloseoutResponse>;

  createDirectLaborCloseout(
    request: estimateCloseout_pb.CreateDirectLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateDirectLaborCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/CreateDirectLaborCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateDirectLaborCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/CreateDirectLaborCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateDirectLaborCloseout);
  }

  methodDescriptorGetDirectLaborCloseouts = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/GetDirectLaborCloseouts',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.GetDirectLaborCloseoutsRequest,
    estimateCloseout_pb.GetDirectLaborCloseoutsResponse,
    (request: estimateCloseout_pb.GetDirectLaborCloseoutsRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.GetDirectLaborCloseoutsResponse.deserializeBinary
  );

  getDirectLaborCloseouts(
    request: estimateCloseout_pb.GetDirectLaborCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.GetDirectLaborCloseoutsResponse>;

  getDirectLaborCloseouts(
    request: estimateCloseout_pb.GetDirectLaborCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetDirectLaborCloseoutsResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.GetDirectLaborCloseoutsResponse>;

  getDirectLaborCloseouts(
    request: estimateCloseout_pb.GetDirectLaborCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetDirectLaborCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/GetDirectLaborCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetDirectLaborCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/GetDirectLaborCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetDirectLaborCloseouts);
  }

  methodDescriptorUpdateDirectLaborCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateDirectLaborCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateDirectLaborCloseoutRequest,
    estimateCloseout_pb.UpdateDirectLaborCloseoutResponse,
    (request: estimateCloseout_pb.UpdateDirectLaborCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateDirectLaborCloseoutResponse.deserializeBinary
  );

  updateDirectLaborCloseout(
    request: estimateCloseout_pb.UpdateDirectLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateDirectLaborCloseoutResponse>;

  updateDirectLaborCloseout(
    request: estimateCloseout_pb.UpdateDirectLaborCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateDirectLaborCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateDirectLaborCloseoutResponse>;

  updateDirectLaborCloseout(
    request: estimateCloseout_pb.UpdateDirectLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateDirectLaborCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateDirectLaborCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateDirectLaborCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateDirectLaborCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateDirectLaborCloseout);
  }

  methodDescriptorDeleteDirectLaborCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/DeleteDirectLaborCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.DeleteDirectLaborCloseoutRequest,
    estimateCloseout_pb.DeleteDirectLaborCloseoutResponse,
    (request: estimateCloseout_pb.DeleteDirectLaborCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.DeleteDirectLaborCloseoutResponse.deserializeBinary
  );

  deleteDirectLaborCloseout(
    request: estimateCloseout_pb.DeleteDirectLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.DeleteDirectLaborCloseoutResponse>;

  deleteDirectLaborCloseout(
    request: estimateCloseout_pb.DeleteDirectLaborCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteDirectLaborCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.DeleteDirectLaborCloseoutResponse>;

  deleteDirectLaborCloseout(
    request: estimateCloseout_pb.DeleteDirectLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteDirectLaborCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/DeleteDirectLaborCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteDirectLaborCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/DeleteDirectLaborCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteDirectLaborCloseout);
  }

  methodDescriptorCreateLaborFactoringCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/CreateLaborFactoringCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.CreateLaborFactoringCloseoutRequest,
    estimateCloseout_pb.CreateLaborFactoringCloseoutResponse,
    (request: estimateCloseout_pb.CreateLaborFactoringCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.CreateLaborFactoringCloseoutResponse.deserializeBinary
  );

  createLaborFactoringCloseout(
    request: estimateCloseout_pb.CreateLaborFactoringCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.CreateLaborFactoringCloseoutResponse>;

  createLaborFactoringCloseout(
    request: estimateCloseout_pb.CreateLaborFactoringCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateLaborFactoringCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.CreateLaborFactoringCloseoutResponse>;

  createLaborFactoringCloseout(
    request: estimateCloseout_pb.CreateLaborFactoringCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateLaborFactoringCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/CreateLaborFactoringCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateLaborFactoringCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/CreateLaborFactoringCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateLaborFactoringCloseout);
  }

  methodDescriptorGetLaborFactoringCloseouts = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/GetLaborFactoringCloseouts',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.GetLaborFactoringCloseoutsRequest,
    estimateCloseout_pb.GetLaborFactoringCloseoutsResponse,
    (request: estimateCloseout_pb.GetLaborFactoringCloseoutsRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.GetLaborFactoringCloseoutsResponse.deserializeBinary
  );

  getLaborFactoringCloseouts(
    request: estimateCloseout_pb.GetLaborFactoringCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.GetLaborFactoringCloseoutsResponse>;

  getLaborFactoringCloseouts(
    request: estimateCloseout_pb.GetLaborFactoringCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetLaborFactoringCloseoutsResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.GetLaborFactoringCloseoutsResponse>;

  getLaborFactoringCloseouts(
    request: estimateCloseout_pb.GetLaborFactoringCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetLaborFactoringCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/GetLaborFactoringCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetLaborFactoringCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/GetLaborFactoringCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetLaborFactoringCloseouts);
  }

  methodDescriptorUpdateLaborFactoringCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateLaborFactoringCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateLaborFactoringCloseoutRequest,
    estimateCloseout_pb.UpdateLaborFactoringCloseoutResponse,
    (request: estimateCloseout_pb.UpdateLaborFactoringCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateLaborFactoringCloseoutResponse.deserializeBinary
  );

  updateLaborFactoringCloseout(
    request: estimateCloseout_pb.UpdateLaborFactoringCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateLaborFactoringCloseoutResponse>;

  updateLaborFactoringCloseout(
    request: estimateCloseout_pb.UpdateLaborFactoringCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateLaborFactoringCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateLaborFactoringCloseoutResponse>;

  updateLaborFactoringCloseout(
    request: estimateCloseout_pb.UpdateLaborFactoringCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateLaborFactoringCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateLaborFactoringCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateLaborFactoringCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateLaborFactoringCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateLaborFactoringCloseout);
  }

  methodDescriptorDeleteLaborFactoringCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/DeleteLaborFactoringCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.DeleteLaborFactoringCloseoutRequest,
    estimateCloseout_pb.DeleteLaborFactoringCloseoutResponse,
    (request: estimateCloseout_pb.DeleteLaborFactoringCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.DeleteLaborFactoringCloseoutResponse.deserializeBinary
  );

  deleteLaborFactoringCloseout(
    request: estimateCloseout_pb.DeleteLaborFactoringCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.DeleteLaborFactoringCloseoutResponse>;

  deleteLaborFactoringCloseout(
    request: estimateCloseout_pb.DeleteLaborFactoringCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteLaborFactoringCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.DeleteLaborFactoringCloseoutResponse>;

  deleteLaborFactoringCloseout(
    request: estimateCloseout_pb.DeleteLaborFactoringCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteLaborFactoringCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/DeleteLaborFactoringCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteLaborFactoringCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/DeleteLaborFactoringCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteLaborFactoringCloseout);
  }

  methodDescriptorCreateIncidentalLaborCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/CreateIncidentalLaborCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.CreateIncidentalLaborCloseoutRequest,
    estimateCloseout_pb.CreateIncidentalLaborCloseoutResponse,
    (request: estimateCloseout_pb.CreateIncidentalLaborCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.CreateIncidentalLaborCloseoutResponse.deserializeBinary
  );

  createIncidentalLaborCloseout(
    request: estimateCloseout_pb.CreateIncidentalLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.CreateIncidentalLaborCloseoutResponse>;

  createIncidentalLaborCloseout(
    request: estimateCloseout_pb.CreateIncidentalLaborCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateIncidentalLaborCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.CreateIncidentalLaborCloseoutResponse>;

  createIncidentalLaborCloseout(
    request: estimateCloseout_pb.CreateIncidentalLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateIncidentalLaborCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/CreateIncidentalLaborCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateIncidentalLaborCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/CreateIncidentalLaborCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateIncidentalLaborCloseout);
  }

  methodDescriptorGetIncidentalLaborCloseouts = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/GetIncidentalLaborCloseouts',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.GetIncidentalLaborCloseoutsRequest,
    estimateCloseout_pb.GetIncidentalLaborCloseoutsResponse,
    (request: estimateCloseout_pb.GetIncidentalLaborCloseoutsRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.GetIncidentalLaborCloseoutsResponse.deserializeBinary
  );

  getIncidentalLaborCloseouts(
    request: estimateCloseout_pb.GetIncidentalLaborCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.GetIncidentalLaborCloseoutsResponse>;

  getIncidentalLaborCloseouts(
    request: estimateCloseout_pb.GetIncidentalLaborCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetIncidentalLaborCloseoutsResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.GetIncidentalLaborCloseoutsResponse>;

  getIncidentalLaborCloseouts(
    request: estimateCloseout_pb.GetIncidentalLaborCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetIncidentalLaborCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/GetIncidentalLaborCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetIncidentalLaborCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/GetIncidentalLaborCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetIncidentalLaborCloseouts);
  }

  methodDescriptorUpdateIncidentalLaborCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateIncidentalLaborCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateIncidentalLaborCloseoutRequest,
    estimateCloseout_pb.UpdateIncidentalLaborCloseoutResponse,
    (request: estimateCloseout_pb.UpdateIncidentalLaborCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateIncidentalLaborCloseoutResponse.deserializeBinary
  );

  updateIncidentalLaborCloseout(
    request: estimateCloseout_pb.UpdateIncidentalLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateIncidentalLaborCloseoutResponse>;

  updateIncidentalLaborCloseout(
    request: estimateCloseout_pb.UpdateIncidentalLaborCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateIncidentalLaborCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateIncidentalLaborCloseoutResponse>;

  updateIncidentalLaborCloseout(
    request: estimateCloseout_pb.UpdateIncidentalLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateIncidentalLaborCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateIncidentalLaborCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateIncidentalLaborCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateIncidentalLaborCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateIncidentalLaborCloseout);
  }

  methodDescriptorDeleteIncidentalLaborCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/DeleteIncidentalLaborCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.DeleteIncidentalLaborCloseoutRequest,
    estimateCloseout_pb.DeleteIncidentalLaborCloseoutResponse,
    (request: estimateCloseout_pb.DeleteIncidentalLaborCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.DeleteIncidentalLaborCloseoutResponse.deserializeBinary
  );

  deleteIncidentalLaborCloseout(
    request: estimateCloseout_pb.DeleteIncidentalLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.DeleteIncidentalLaborCloseoutResponse>;

  deleteIncidentalLaborCloseout(
    request: estimateCloseout_pb.DeleteIncidentalLaborCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteIncidentalLaborCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.DeleteIncidentalLaborCloseoutResponse>;

  deleteIncidentalLaborCloseout(
    request: estimateCloseout_pb.DeleteIncidentalLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteIncidentalLaborCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/DeleteIncidentalLaborCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteIncidentalLaborCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/DeleteIncidentalLaborCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteIncidentalLaborCloseout);
  }

  methodDescriptorCreateIndirectLaborCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/CreateIndirectLaborCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.CreateIndirectLaborCloseoutRequest,
    estimateCloseout_pb.CreateIndirectLaborCloseoutResponse,
    (request: estimateCloseout_pb.CreateIndirectLaborCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.CreateIndirectLaborCloseoutResponse.deserializeBinary
  );

  createIndirectLaborCloseout(
    request: estimateCloseout_pb.CreateIndirectLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.CreateIndirectLaborCloseoutResponse>;

  createIndirectLaborCloseout(
    request: estimateCloseout_pb.CreateIndirectLaborCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateIndirectLaborCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.CreateIndirectLaborCloseoutResponse>;

  createIndirectLaborCloseout(
    request: estimateCloseout_pb.CreateIndirectLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateIndirectLaborCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/CreateIndirectLaborCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateIndirectLaborCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/CreateIndirectLaborCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateIndirectLaborCloseout);
  }

  methodDescriptorGetIndirectLaborCloseouts = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/GetIndirectLaborCloseouts',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.GetIndirectLaborCloseoutsRequest,
    estimateCloseout_pb.GetIndirectLaborCloseoutsResponse,
    (request: estimateCloseout_pb.GetIndirectLaborCloseoutsRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.GetIndirectLaborCloseoutsResponse.deserializeBinary
  );

  getIndirectLaborCloseouts(
    request: estimateCloseout_pb.GetIndirectLaborCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.GetIndirectLaborCloseoutsResponse>;

  getIndirectLaborCloseouts(
    request: estimateCloseout_pb.GetIndirectLaborCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetIndirectLaborCloseoutsResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.GetIndirectLaborCloseoutsResponse>;

  getIndirectLaborCloseouts(
    request: estimateCloseout_pb.GetIndirectLaborCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetIndirectLaborCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/GetIndirectLaborCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetIndirectLaborCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/GetIndirectLaborCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetIndirectLaborCloseouts);
  }

  methodDescriptorUpdateIndirectLaborCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateIndirectLaborCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateIndirectLaborCloseoutRequest,
    estimateCloseout_pb.UpdateIndirectLaborCloseoutResponse,
    (request: estimateCloseout_pb.UpdateIndirectLaborCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateIndirectLaborCloseoutResponse.deserializeBinary
  );

  updateIndirectLaborCloseout(
    request: estimateCloseout_pb.UpdateIndirectLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateIndirectLaborCloseoutResponse>;

  updateIndirectLaborCloseout(
    request: estimateCloseout_pb.UpdateIndirectLaborCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateIndirectLaborCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateIndirectLaborCloseoutResponse>;

  updateIndirectLaborCloseout(
    request: estimateCloseout_pb.UpdateIndirectLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateIndirectLaborCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateIndirectLaborCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateIndirectLaborCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateIndirectLaborCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateIndirectLaborCloseout);
  }

  methodDescriptorDeleteIndirectLaborCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/DeleteIndirectLaborCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.DeleteIndirectLaborCloseoutRequest,
    estimateCloseout_pb.DeleteIndirectLaborCloseoutResponse,
    (request: estimateCloseout_pb.DeleteIndirectLaborCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.DeleteIndirectLaborCloseoutResponse.deserializeBinary
  );

  deleteIndirectLaborCloseout(
    request: estimateCloseout_pb.DeleteIndirectLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.DeleteIndirectLaborCloseoutResponse>;

  deleteIndirectLaborCloseout(
    request: estimateCloseout_pb.DeleteIndirectLaborCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteIndirectLaborCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.DeleteIndirectLaborCloseoutResponse>;

  deleteIndirectLaborCloseout(
    request: estimateCloseout_pb.DeleteIndirectLaborCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteIndirectLaborCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/DeleteIndirectLaborCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteIndirectLaborCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/DeleteIndirectLaborCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteIndirectLaborCloseout);
  }

  methodDescriptorCreateEquipmentCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/CreateEquipmentCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.CreateEquipmentCloseoutRequest,
    estimateCloseout_pb.CreateEquipmentCloseoutResponse,
    (request: estimateCloseout_pb.CreateEquipmentCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.CreateEquipmentCloseoutResponse.deserializeBinary
  );

  createEquipmentCloseout(
    request: estimateCloseout_pb.CreateEquipmentCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.CreateEquipmentCloseoutResponse>;

  createEquipmentCloseout(
    request: estimateCloseout_pb.CreateEquipmentCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateEquipmentCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.CreateEquipmentCloseoutResponse>;

  createEquipmentCloseout(
    request: estimateCloseout_pb.CreateEquipmentCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateEquipmentCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/CreateEquipmentCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateEquipmentCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/CreateEquipmentCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateEquipmentCloseout);
  }

  methodDescriptorGetEquipmentCloseouts = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/GetEquipmentCloseouts',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.GetEquipmentCloseoutsRequest,
    estimateCloseout_pb.GetEquipmentCloseoutsResponse,
    (request: estimateCloseout_pb.GetEquipmentCloseoutsRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.GetEquipmentCloseoutsResponse.deserializeBinary
  );

  getEquipmentCloseouts(
    request: estimateCloseout_pb.GetEquipmentCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.GetEquipmentCloseoutsResponse>;

  getEquipmentCloseouts(
    request: estimateCloseout_pb.GetEquipmentCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetEquipmentCloseoutsResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.GetEquipmentCloseoutsResponse>;

  getEquipmentCloseouts(
    request: estimateCloseout_pb.GetEquipmentCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetEquipmentCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/GetEquipmentCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetEquipmentCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/GetEquipmentCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetEquipmentCloseouts);
  }

  methodDescriptorUpdateEquipmentCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateEquipmentCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateEquipmentCloseoutRequest,
    estimateCloseout_pb.UpdateEquipmentCloseoutResponse,
    (request: estimateCloseout_pb.UpdateEquipmentCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateEquipmentCloseoutResponse.deserializeBinary
  );

  updateEquipmentCloseout(
    request: estimateCloseout_pb.UpdateEquipmentCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateEquipmentCloseoutResponse>;

  updateEquipmentCloseout(
    request: estimateCloseout_pb.UpdateEquipmentCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEquipmentCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateEquipmentCloseoutResponse>;

  updateEquipmentCloseout(
    request: estimateCloseout_pb.UpdateEquipmentCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEquipmentCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateEquipmentCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateEquipmentCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateEquipmentCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateEquipmentCloseout);
  }

  methodDescriptorDeleteEquipmentCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/DeleteEquipmentCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.DeleteEquipmentCloseoutRequest,
    estimateCloseout_pb.DeleteEquipmentCloseoutResponse,
    (request: estimateCloseout_pb.DeleteEquipmentCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.DeleteEquipmentCloseoutResponse.deserializeBinary
  );

  deleteEquipmentCloseout(
    request: estimateCloseout_pb.DeleteEquipmentCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.DeleteEquipmentCloseoutResponse>;

  deleteEquipmentCloseout(
    request: estimateCloseout_pb.DeleteEquipmentCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteEquipmentCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.DeleteEquipmentCloseoutResponse>;

  deleteEquipmentCloseout(
    request: estimateCloseout_pb.DeleteEquipmentCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteEquipmentCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/DeleteEquipmentCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteEquipmentCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/DeleteEquipmentCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteEquipmentCloseout);
  }

  methodDescriptorCreateGeneralExpenseCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/CreateGeneralExpenseCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.CreateGeneralExpenseCloseoutRequest,
    estimateCloseout_pb.CreateGeneralExpenseCloseoutResponse,
    (request: estimateCloseout_pb.CreateGeneralExpenseCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.CreateGeneralExpenseCloseoutResponse.deserializeBinary
  );

  createGeneralExpenseCloseout(
    request: estimateCloseout_pb.CreateGeneralExpenseCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.CreateGeneralExpenseCloseoutResponse>;

  createGeneralExpenseCloseout(
    request: estimateCloseout_pb.CreateGeneralExpenseCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateGeneralExpenseCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.CreateGeneralExpenseCloseoutResponse>;

  createGeneralExpenseCloseout(
    request: estimateCloseout_pb.CreateGeneralExpenseCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateGeneralExpenseCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/CreateGeneralExpenseCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateGeneralExpenseCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/CreateGeneralExpenseCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateGeneralExpenseCloseout);
  }

  methodDescriptorGetGeneralExpenseCloseouts = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/GetGeneralExpenseCloseouts',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.GetGeneralExpenseCloseoutsRequest,
    estimateCloseout_pb.GetGeneralExpenseCloseoutsResponse,
    (request: estimateCloseout_pb.GetGeneralExpenseCloseoutsRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.GetGeneralExpenseCloseoutsResponse.deserializeBinary
  );

  getGeneralExpenseCloseouts(
    request: estimateCloseout_pb.GetGeneralExpenseCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.GetGeneralExpenseCloseoutsResponse>;

  getGeneralExpenseCloseouts(
    request: estimateCloseout_pb.GetGeneralExpenseCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetGeneralExpenseCloseoutsResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.GetGeneralExpenseCloseoutsResponse>;

  getGeneralExpenseCloseouts(
    request: estimateCloseout_pb.GetGeneralExpenseCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetGeneralExpenseCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/GetGeneralExpenseCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetGeneralExpenseCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/GetGeneralExpenseCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetGeneralExpenseCloseouts);
  }

  methodDescriptorUpdateGeneralExpenseCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateGeneralExpenseCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateGeneralExpenseCloseoutRequest,
    estimateCloseout_pb.UpdateGeneralExpenseCloseoutResponse,
    (request: estimateCloseout_pb.UpdateGeneralExpenseCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateGeneralExpenseCloseoutResponse.deserializeBinary
  );

  updateGeneralExpenseCloseout(
    request: estimateCloseout_pb.UpdateGeneralExpenseCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateGeneralExpenseCloseoutResponse>;

  updateGeneralExpenseCloseout(
    request: estimateCloseout_pb.UpdateGeneralExpenseCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateGeneralExpenseCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateGeneralExpenseCloseoutResponse>;

  updateGeneralExpenseCloseout(
    request: estimateCloseout_pb.UpdateGeneralExpenseCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateGeneralExpenseCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateGeneralExpenseCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateGeneralExpenseCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateGeneralExpenseCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateGeneralExpenseCloseout);
  }

  methodDescriptorDeleteGeneralExpenseCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/DeleteGeneralExpenseCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.DeleteGeneralExpenseCloseoutRequest,
    estimateCloseout_pb.DeleteGeneralExpenseCloseoutResponse,
    (request: estimateCloseout_pb.DeleteGeneralExpenseCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.DeleteGeneralExpenseCloseoutResponse.deserializeBinary
  );

  deleteGeneralExpenseCloseout(
    request: estimateCloseout_pb.DeleteGeneralExpenseCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.DeleteGeneralExpenseCloseoutResponse>;

  deleteGeneralExpenseCloseout(
    request: estimateCloseout_pb.DeleteGeneralExpenseCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteGeneralExpenseCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.DeleteGeneralExpenseCloseoutResponse>;

  deleteGeneralExpenseCloseout(
    request: estimateCloseout_pb.DeleteGeneralExpenseCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteGeneralExpenseCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/DeleteGeneralExpenseCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteGeneralExpenseCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/DeleteGeneralExpenseCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteGeneralExpenseCloseout);
  }

  methodDescriptorCreateSubcontractCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/CreateSubcontractCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.CreateSubcontractCloseoutRequest,
    estimateCloseout_pb.CreateSubcontractCloseoutResponse,
    (request: estimateCloseout_pb.CreateSubcontractCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.CreateSubcontractCloseoutResponse.deserializeBinary
  );

  createSubcontractCloseout(
    request: estimateCloseout_pb.CreateSubcontractCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.CreateSubcontractCloseoutResponse>;

  createSubcontractCloseout(
    request: estimateCloseout_pb.CreateSubcontractCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateSubcontractCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.CreateSubcontractCloseoutResponse>;

  createSubcontractCloseout(
    request: estimateCloseout_pb.CreateSubcontractCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateSubcontractCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/CreateSubcontractCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateSubcontractCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/CreateSubcontractCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateSubcontractCloseout);
  }

  methodDescriptorGetSubcontractCloseouts = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/GetSubcontractCloseouts',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.GetSubcontractCloseoutsRequest,
    estimateCloseout_pb.GetSubcontractCloseoutsResponse,
    (request: estimateCloseout_pb.GetSubcontractCloseoutsRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.GetSubcontractCloseoutsResponse.deserializeBinary
  );

  getSubcontractCloseouts(
    request: estimateCloseout_pb.GetSubcontractCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.GetSubcontractCloseoutsResponse>;

  getSubcontractCloseouts(
    request: estimateCloseout_pb.GetSubcontractCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetSubcontractCloseoutsResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.GetSubcontractCloseoutsResponse>;

  getSubcontractCloseouts(
    request: estimateCloseout_pb.GetSubcontractCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetSubcontractCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/GetSubcontractCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetSubcontractCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/GetSubcontractCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetSubcontractCloseouts);
  }

  methodDescriptorUpdateSubcontractCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateSubcontractCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateSubcontractCloseoutRequest,
    estimateCloseout_pb.UpdateSubcontractCloseoutResponse,
    (request: estimateCloseout_pb.UpdateSubcontractCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateSubcontractCloseoutResponse.deserializeBinary
  );

  updateSubcontractCloseout(
    request: estimateCloseout_pb.UpdateSubcontractCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateSubcontractCloseoutResponse>;

  updateSubcontractCloseout(
    request: estimateCloseout_pb.UpdateSubcontractCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateSubcontractCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateSubcontractCloseoutResponse>;

  updateSubcontractCloseout(
    request: estimateCloseout_pb.UpdateSubcontractCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateSubcontractCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateSubcontractCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateSubcontractCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateSubcontractCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateSubcontractCloseout);
  }

  methodDescriptorDeleteSubcontractCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/DeleteSubcontractCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.DeleteSubcontractCloseoutRequest,
    estimateCloseout_pb.DeleteSubcontractCloseoutResponse,
    (request: estimateCloseout_pb.DeleteSubcontractCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.DeleteSubcontractCloseoutResponse.deserializeBinary
  );

  deleteSubcontractCloseout(
    request: estimateCloseout_pb.DeleteSubcontractCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.DeleteSubcontractCloseoutResponse>;

  deleteSubcontractCloseout(
    request: estimateCloseout_pb.DeleteSubcontractCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteSubcontractCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.DeleteSubcontractCloseoutResponse>;

  deleteSubcontractCloseout(
    request: estimateCloseout_pb.DeleteSubcontractCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteSubcontractCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/DeleteSubcontractCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteSubcontractCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/DeleteSubcontractCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteSubcontractCloseout);
  }

  methodDescriptorCreateQuoteCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/CreateQuoteCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.CreateQuoteCloseoutRequest,
    estimateCloseout_pb.CreateQuoteCloseoutResponse,
    (request: estimateCloseout_pb.CreateQuoteCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.CreateQuoteCloseoutResponse.deserializeBinary
  );

  createQuoteCloseout(
    request: estimateCloseout_pb.CreateQuoteCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.CreateQuoteCloseoutResponse>;

  createQuoteCloseout(
    request: estimateCloseout_pb.CreateQuoteCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateQuoteCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.CreateQuoteCloseoutResponse>;

  createQuoteCloseout(
    request: estimateCloseout_pb.CreateQuoteCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateQuoteCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/CreateQuoteCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateQuoteCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/CreateQuoteCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateQuoteCloseout);
  }

  methodDescriptorGetQuoteCloseouts = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/GetQuoteCloseouts',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.GetQuoteCloseoutsRequest,
    estimateCloseout_pb.GetQuoteCloseoutsResponse,
    (request: estimateCloseout_pb.GetQuoteCloseoutsRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.GetQuoteCloseoutsResponse.deserializeBinary
  );

  getQuoteCloseouts(
    request: estimateCloseout_pb.GetQuoteCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.GetQuoteCloseoutsResponse>;

  getQuoteCloseouts(
    request: estimateCloseout_pb.GetQuoteCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetQuoteCloseoutsResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.GetQuoteCloseoutsResponse>;

  getQuoteCloseouts(
    request: estimateCloseout_pb.GetQuoteCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetQuoteCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/GetQuoteCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetQuoteCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/GetQuoteCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetQuoteCloseouts);
  }

  methodDescriptorUpdateQuoteCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateQuoteCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateQuoteCloseoutRequest,
    estimateCloseout_pb.UpdateQuoteCloseoutResponse,
    (request: estimateCloseout_pb.UpdateQuoteCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateQuoteCloseoutResponse.deserializeBinary
  );

  updateQuoteCloseout(
    request: estimateCloseout_pb.UpdateQuoteCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateQuoteCloseoutResponse>;

  updateQuoteCloseout(
    request: estimateCloseout_pb.UpdateQuoteCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateQuoteCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateQuoteCloseoutResponse>;

  updateQuoteCloseout(
    request: estimateCloseout_pb.UpdateQuoteCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateQuoteCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateQuoteCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateQuoteCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateQuoteCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateQuoteCloseout);
  }

  methodDescriptorDeleteQuoteCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/DeleteQuoteCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.DeleteQuoteCloseoutRequest,
    estimateCloseout_pb.DeleteQuoteCloseoutResponse,
    (request: estimateCloseout_pb.DeleteQuoteCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.DeleteQuoteCloseoutResponse.deserializeBinary
  );

  deleteQuoteCloseout(
    request: estimateCloseout_pb.DeleteQuoteCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.DeleteQuoteCloseoutResponse>;

  deleteQuoteCloseout(
    request: estimateCloseout_pb.DeleteQuoteCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteQuoteCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.DeleteQuoteCloseoutResponse>;

  deleteQuoteCloseout(
    request: estimateCloseout_pb.DeleteQuoteCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteQuoteCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/DeleteQuoteCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteQuoteCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/DeleteQuoteCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteQuoteCloseout);
  }

  methodDescriptorGetTotalFinalPriceCloseouts = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/GetTotalFinalPriceCloseouts',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.GetTotalFinalPriceCloseoutsRequest,
    estimateCloseout_pb.GetTotalFinalPriceCloseoutsResponse,
    (request: estimateCloseout_pb.GetTotalFinalPriceCloseoutsRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.GetTotalFinalPriceCloseoutsResponse.deserializeBinary
  );

  getTotalFinalPriceCloseouts(
    request: estimateCloseout_pb.GetTotalFinalPriceCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.GetTotalFinalPriceCloseoutsResponse>;

  getTotalFinalPriceCloseouts(
    request: estimateCloseout_pb.GetTotalFinalPriceCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetTotalFinalPriceCloseoutsResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.GetTotalFinalPriceCloseoutsResponse>;

  getTotalFinalPriceCloseouts(
    request: estimateCloseout_pb.GetTotalFinalPriceCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetTotalFinalPriceCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/GetTotalFinalPriceCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetTotalFinalPriceCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/GetTotalFinalPriceCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetTotalFinalPriceCloseouts);
  }

  methodDescriptorUpdateTotalFinalPriceCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateTotalFinalPriceCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateTotalFinalPriceCloseoutRequest,
    estimateCloseout_pb.UpdateTotalFinalPriceCloseoutResponse,
    (request: estimateCloseout_pb.UpdateTotalFinalPriceCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateTotalFinalPriceCloseoutResponse.deserializeBinary
  );

  updateTotalFinalPriceCloseout(
    request: estimateCloseout_pb.UpdateTotalFinalPriceCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateTotalFinalPriceCloseoutResponse>;

  updateTotalFinalPriceCloseout(
    request: estimateCloseout_pb.UpdateTotalFinalPriceCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateTotalFinalPriceCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateTotalFinalPriceCloseoutResponse>;

  updateTotalFinalPriceCloseout(
    request: estimateCloseout_pb.UpdateTotalFinalPriceCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateTotalFinalPriceCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateTotalFinalPriceCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateTotalFinalPriceCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateTotalFinalPriceCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateTotalFinalPriceCloseout);
  }

  methodDescriptorUpdateEstimateLaborFactoringAllocation = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateEstimateLaborFactoringAllocation',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateEstimateLaborFactoringAllocationRequest,
    estimateCloseout_pb.UpdateEstimateLaborFactoringAllocationResponse,
    (request: estimateCloseout_pb.UpdateEstimateLaborFactoringAllocationRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateEstimateLaborFactoringAllocationResponse.deserializeBinary
  );

  updateEstimateLaborFactoringAllocation(
    request: estimateCloseout_pb.UpdateEstimateLaborFactoringAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateEstimateLaborFactoringAllocationResponse>;

  updateEstimateLaborFactoringAllocation(
    request: estimateCloseout_pb.UpdateEstimateLaborFactoringAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateLaborFactoringAllocationResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateEstimateLaborFactoringAllocationResponse>;

  updateEstimateLaborFactoringAllocation(
    request: estimateCloseout_pb.UpdateEstimateLaborFactoringAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateLaborFactoringAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateEstimateLaborFactoringAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateEstimateLaborFactoringAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateEstimateLaborFactoringAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateEstimateLaborFactoringAllocation);
  }

  methodDescriptorUpdateEstimateIncidentalLaborAllocation = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateEstimateIncidentalLaborAllocation',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateEstimateIncidentalLaborAllocationRequest,
    estimateCloseout_pb.UpdateEstimateIncidentalLaborAllocationResponse,
    (request: estimateCloseout_pb.UpdateEstimateIncidentalLaborAllocationRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateEstimateIncidentalLaborAllocationResponse.deserializeBinary
  );

  updateEstimateIncidentalLaborAllocation(
    request: estimateCloseout_pb.UpdateEstimateIncidentalLaborAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateEstimateIncidentalLaborAllocationResponse>;

  updateEstimateIncidentalLaborAllocation(
    request: estimateCloseout_pb.UpdateEstimateIncidentalLaborAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateIncidentalLaborAllocationResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateEstimateIncidentalLaborAllocationResponse>;

  updateEstimateIncidentalLaborAllocation(
    request: estimateCloseout_pb.UpdateEstimateIncidentalLaborAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateIncidentalLaborAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateEstimateIncidentalLaborAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateEstimateIncidentalLaborAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateEstimateIncidentalLaborAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateEstimateIncidentalLaborAllocation);
  }

  methodDescriptorUpdateEstimateIndirectLaborAllocation = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateEstimateIndirectLaborAllocation',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateEstimateIndirectLaborAllocationRequest,
    estimateCloseout_pb.UpdateEstimateIndirectLaborAllocationResponse,
    (request: estimateCloseout_pb.UpdateEstimateIndirectLaborAllocationRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateEstimateIndirectLaborAllocationResponse.deserializeBinary
  );

  updateEstimateIndirectLaborAllocation(
    request: estimateCloseout_pb.UpdateEstimateIndirectLaborAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateEstimateIndirectLaborAllocationResponse>;

  updateEstimateIndirectLaborAllocation(
    request: estimateCloseout_pb.UpdateEstimateIndirectLaborAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateIndirectLaborAllocationResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateEstimateIndirectLaborAllocationResponse>;

  updateEstimateIndirectLaborAllocation(
    request: estimateCloseout_pb.UpdateEstimateIndirectLaborAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateIndirectLaborAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateEstimateIndirectLaborAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateEstimateIndirectLaborAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateEstimateIndirectLaborAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateEstimateIndirectLaborAllocation);
  }

  methodDescriptorUpdateEstimateEquipmentAllocation = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateEstimateEquipmentAllocation',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateEstimateEquipmentAllocationRequest,
    estimateCloseout_pb.UpdateEstimateEquipmentAllocationResponse,
    (request: estimateCloseout_pb.UpdateEstimateEquipmentAllocationRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateEstimateEquipmentAllocationResponse.deserializeBinary
  );

  updateEstimateEquipmentAllocation(
    request: estimateCloseout_pb.UpdateEstimateEquipmentAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateEstimateEquipmentAllocationResponse>;

  updateEstimateEquipmentAllocation(
    request: estimateCloseout_pb.UpdateEstimateEquipmentAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateEquipmentAllocationResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateEstimateEquipmentAllocationResponse>;

  updateEstimateEquipmentAllocation(
    request: estimateCloseout_pb.UpdateEstimateEquipmentAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateEquipmentAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateEstimateEquipmentAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateEstimateEquipmentAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateEstimateEquipmentAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateEstimateEquipmentAllocation);
  }

  methodDescriptorUpdateEstimateGeneralExpenseAllocation = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateEstimateGeneralExpenseAllocation',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateEstimateGeneralExpenseAllocationRequest,
    estimateCloseout_pb.UpdateEstimateGeneralExpenseAllocationResponse,
    (request: estimateCloseout_pb.UpdateEstimateGeneralExpenseAllocationRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateEstimateGeneralExpenseAllocationResponse.deserializeBinary
  );

  updateEstimateGeneralExpenseAllocation(
    request: estimateCloseout_pb.UpdateEstimateGeneralExpenseAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateEstimateGeneralExpenseAllocationResponse>;

  updateEstimateGeneralExpenseAllocation(
    request: estimateCloseout_pb.UpdateEstimateGeneralExpenseAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateGeneralExpenseAllocationResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateEstimateGeneralExpenseAllocationResponse>;

  updateEstimateGeneralExpenseAllocation(
    request: estimateCloseout_pb.UpdateEstimateGeneralExpenseAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateGeneralExpenseAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateEstimateGeneralExpenseAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateEstimateGeneralExpenseAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateEstimateGeneralExpenseAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateEstimateGeneralExpenseAllocation);
  }

  methodDescriptorUpdateEstimateSubcontractAllocation = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateEstimateSubcontractAllocation',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateEstimateSubcontractAllocationRequest,
    estimateCloseout_pb.UpdateEstimateSubcontractAllocationResponse,
    (request: estimateCloseout_pb.UpdateEstimateSubcontractAllocationRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateEstimateSubcontractAllocationResponse.deserializeBinary
  );

  updateEstimateSubcontractAllocation(
    request: estimateCloseout_pb.UpdateEstimateSubcontractAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateEstimateSubcontractAllocationResponse>;

  updateEstimateSubcontractAllocation(
    request: estimateCloseout_pb.UpdateEstimateSubcontractAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateSubcontractAllocationResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateEstimateSubcontractAllocationResponse>;

  updateEstimateSubcontractAllocation(
    request: estimateCloseout_pb.UpdateEstimateSubcontractAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateSubcontractAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateEstimateSubcontractAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateEstimateSubcontractAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateEstimateSubcontractAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateEstimateSubcontractAllocation);
  }

  methodDescriptorUpdateEstimateQuoteAllocation = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateEstimateQuoteAllocation',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateEstimateQuoteAllocationRequest,
    estimateCloseout_pb.UpdateEstimateQuoteAllocationResponse,
    (request: estimateCloseout_pb.UpdateEstimateQuoteAllocationRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateEstimateQuoteAllocationResponse.deserializeBinary
  );

  updateEstimateQuoteAllocation(
    request: estimateCloseout_pb.UpdateEstimateQuoteAllocationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateEstimateQuoteAllocationResponse>;

  updateEstimateQuoteAllocation(
    request: estimateCloseout_pb.UpdateEstimateQuoteAllocationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateQuoteAllocationResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateEstimateQuoteAllocationResponse>;

  updateEstimateQuoteAllocation(
    request: estimateCloseout_pb.UpdateEstimateQuoteAllocationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateQuoteAllocationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateEstimateQuoteAllocation',
        request,
        metadata || {},
        this.methodDescriptorUpdateEstimateQuoteAllocation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateEstimateQuoteAllocation',
    request,
    metadata || {},
    this.methodDescriptorUpdateEstimateQuoteAllocation);
  }

}

