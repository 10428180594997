import {
  ChangeOrderAssembly,
  ChangeOrderAssemblyItem,
  ChangeOrderAssemblyItemAttribute,
  ChangeOrderBreakoutMap,
  ChangeOrderItemQtyFormula,
  ChangeOrderTakeoffAssemblies,
} from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import {
  EstimateAssembly,
  EstimateAssemblyItem,
  EstimateAssemblyItemAttribute,
  EstimateBreakoutMap,
  EstimateItemQtyFormula,
  EstimateTakeoffAssemblies,
} from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { Breakout } from "../api/protosCompiled/breakout/breakout_pb";

export const measurementTypes = ["count", "length", "area"] as const;

export const takeoffVariableTypes = [
  "point",
  "segment",
  "length",
  "area",
] as const;
export type TakeoffVariableType = (typeof takeoffVariableTypes)[number];

export type MeasurementType = (typeof measurementTypes)[number];

export interface TakeoffVariableQty {
  /**
   * A point is a single unit of measurement for included points.
   */
  point: number;
  /**
   * A segment is the space between two points for included segments / points.
   */
  segment: number;
  /**
   * A length is the distance between all points that are included in an annotation.
   */
  length: number;
  /**
   * Volume is how much an item can hold for included items.
   */
  volume: number;
  /**
   * Weight is how much an item weighs for included items.
   */
  weight: number;
}

export type TakeoffAssemblies =
  | EstimateTakeoffAssemblies.AsObject
  | ChangeOrderTakeoffAssemblies.AsObject;

export type TakeoffAssembly =
  | EstimateAssembly.AsObject
  | ChangeOrderAssembly.AsObject;

export type TakeoffAssemblyItem =
  | EstimateAssemblyItem.AsObject
  | ChangeOrderAssemblyItem.AsObject;

export type TakeoffItemQtyFormula =
  | EstimateItemQtyFormula.AsObject
  | ChangeOrderItemQtyFormula.AsObject;

export type TakeoffAssemblyAttribute =
  | EstimateAssemblyItemAttribute.AsObject
  | ChangeOrderAssemblyItemAttribute.AsObject;

export type BreakOut = Breakout.AsObject;

export type BreakoutMap =
  | EstimateBreakoutMap.AsObject
  | ChangeOrderBreakoutMap.AsObject;
