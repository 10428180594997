import { FolderStore } from "./folderStore";
import { AssemblyStore } from "./AssemblyStore";
import { GlobalItemStore } from "./globalItemStore";
import { InferenceStore } from "./inferenceStore";
import { EstimateStore } from "./store";
import { KeyBoardEventStore } from "./keyEventStore";

export namespace ResetStates {
  export function resetTakeoffStates(
    estimateStore: EstimateStore,
    assemblyStore: AssemblyStore,
    keyBoardEventStore: KeyBoardEventStore
  ) {
    estimateStore.setIsAddingVertical(false);
    estimateStore.setIsCounting(false);
    keyBoardEventStore.setIsMeasuring(false);
    estimateStore.setMeasuredPoints([]);
    estimateStore.setPointIsSelected(false);
    estimateStore.setIsGettingLength(false);
    estimateStore.setIsGettingArea(false);
    estimateStore.setIsAnnotating(false);
    estimateStore.setIsCreatingTakeoffAssembly(false);
    estimateStore.setIsUpdatingTakeoffAssembly(false);
    estimateStore.setSelectedTotalLength(0);
    estimateStore.setTemporaryPoints([]);
    estimateStore.setIsDragging(false);
    estimateStore.setIsEditingPoint({
      isEditingPoint: false,
      isEditingStyle: false,
      assemblyIndex: null,
      pointIndex: 0,
      x: null,
      y: null,
    });
    estimateStore.setIsEditingPointStyle({
      isEditingPointFillColor: false,
      isEditingPointBorderColor: false,
      isEditingLineColor: false,
    });
    estimateStore.setIsViewingExtension(false);
    estimateStore.setIncludeAllPoints(false);
    estimateStore.setIsInsertingPoint(false);
    assemblyStore.setCurrentGlobalAssembly(null);
    assemblyStore.setCurrentTakeoffAssembly(null);
  }

  export function resetMasterProjectViewStates(
    store: EstimateStore,
    assemblyStore: AssemblyStore,
    store2: InferenceStore
  ) {
    store.setSelectedObject(null);
    store.setIsOpeningViewport(false);
    store.setIsCreatingChangeOrder(false);
    store.setIsCreatingEstimate(false);
    store.setSelectedChangeOrder(null);
    store.setSelectedEstimate(null);
    store.setSelectedContract(null);
    store.setSelectedChangeOrder(null);
    store.setFilteredCountedAssemblies([]);
    store.setSelectedBreakout(null);
    assemblyStore.setCurrentGlobalAssembly(null);
    assemblyStore.setCurrentTakeoffAssembly(null);
    store2.setInferenceCountObjects([]);
  }

  export function resetCreateAssemblyStates(
    assemblyStore: AssemblyStore,
    itemStore: GlobalItemStore,
    folderStore: FolderStore,
    unityBuildStore: EstimateStore
  ) {
    folderStore.setCurrentFolder(null);

    itemStore.setIsCreatingItem(false);
    itemStore.setIsViewingItemForm(false);
    itemStore.setCurrentItem(null);

    assemblyStore.setIsCreatingAssembly(false);
    assemblyStore.setFilteredItems([]);
    assemblyStore.setAssemblyItems([]);
    assemblyStore.setCurrentGlobalAssembly(null);
    assemblyStore.setCurrentTakeoffAssembly(null);

    unityBuildStore.setIsEditingPoint({
      isEditingPoint: false,
      isEditingStyle: false,
      assemblyIndex: null,
      pointIndex: 0,
      x: null,
      y: null,
    });
    unityBuildStore.setIsAnnotating(false);
  }

  export function resetMainMenuStates(
    store: EstimateStore,
    assemblyStore: AssemblyStore
  ) {
    store.setIsCreatingNewProject(false);
    store.setIsCreatingContract(false);
    store.setIsViewingEstimateList(false);
    store.setIsViewingContractList(false);
    store.setIsViewingChangeOrderList(false);
    store.setSelectedEstimate(null);
    store.setSelectedContract(null);
    store.setCurrentPage(1);
    store.setNumPages(1);
    store.setSelectedBreakout(null);
    assemblyStore.setCurrentGlobalAssembly(null);
    assemblyStore.setCurrentTakeoffAssembly(null);
  }

  export function resetAssemblyPickerStates(assemblyStore: AssemblyStore) {
    assemblyStore.setCurrentGlobalAssembly(null);
    assemblyStore.setCurrentTakeoffAssembly(null);
  }
}
