import { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Box,
} from "@mui/material";
import { BreakoutAllocation } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { Breakout } from "../../api/protosCompiled/breakout/breakout_pb";
import { allocateCost, formatCurrency } from "./closeoutHelpers";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";

type AllocationProps = {
    allocations: BreakoutAllocation.AsObject[];
    breakoutList: Breakout.AsObject[];
    costToAllocate: number;
    updateAllocation: Function;
    closeoutId: string;
};

type InputCache = {
    allocationpercentage?: string;
    allocationexpense?: string;
};

export const Allocation = (props: AllocationProps) => {
    const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
    const selectedChangeOrder = useStore(useUnityBuildStore).selectedChangeOrder;
    const estimateId =
        selectedEstimate?.estimateid ??
        selectedChangeOrder?.changeorderid ??
        "";
    const [allocations, setAllocations] = useState<BreakoutAllocation.AsObject[]>(props.allocations || []);
    // Local cache for user inputs (so we don't update computed values on every keystroke)
    const [inputCache, setInputCache] = useState<Record<string, InputCache>>({});

    // Helper: update the allocation for a given breakout and then update parent callback.
    const commitAllocationUpdate = (
        breakoutId: string,
        field: "allocationpercentage" | "allocationexpense",
        value: string
    ) => {
        const numericValue = parseFloat(value) || 0;
        setAllocations((prevAllocations) => {
            const updatedAllocations = [...prevAllocations];
            const index = updatedAllocations.findIndex((alloc) => alloc.breakoutid === breakoutId);
            let updatedAllocation: BreakoutAllocation.AsObject;

            if (index !== -1) {
                updatedAllocation = { ...updatedAllocations[index] };
            } else {
                // Find the existing allocation entry or the breakout entry if no allocation exists yet
                const existingAllocation = props.allocations.find((alloc) => alloc.breakoutid === breakoutId);

                updatedAllocation = {
                    id: existingAllocation?.id || "",
                    estimateid: estimateId,
                    closeoutid: props.closeoutId || "",
                    breakoutid: breakoutId,
                    allocationpercentage: 0,
                    allocationexpense: 0,
                };

                updatedAllocations.push(updatedAllocation);
            }

            // Update the field and calculate the counterpart.
            if (field === "allocationpercentage") {
                updatedAllocation.allocationpercentage = numericValue;
                updatedAllocation.allocationexpense = (numericValue / 100) * props.costToAllocate;
            } else if (field === "allocationexpense") {
                updatedAllocation.allocationexpense = numericValue;
                updatedAllocation.allocationpercentage = (numericValue / props.costToAllocate) * 100;
            }

            updatedAllocations[index !== -1 ? index : updatedAllocations.length - 1] = updatedAllocation;

            // Call updateAllocation with the single updated allocation
            if (props.updateAllocation) {
                const sessionToken = "";
                const updatedAllocationWithToken = {
                    ...updatedAllocation,
                    sessiontoken: sessionToken,
                };
                props.updateAllocation(updatedAllocationWithToken);
            }
            return updatedAllocations;
        });
    };

    // When a field changes, update the local input cache.
    const handleInputChange = (
        breakoutId: string,
        field: "allocationpercentage" | "allocationexpense",
        value: string
    ) => {
        setInputCache((prev) => ({
            ...prev,
            [breakoutId]: {
                ...prev[breakoutId],
                [field]: value,
            },
        }));
    };

    // When a field loses focus, commit the update.
    const handleBlur = (
        breakoutId: string,
        field: "allocationpercentage" | "allocationexpense"
    ) => {
        const value = inputCache[breakoutId]?.[field];
        if (value !== undefined) {
            commitAllocationUpdate(breakoutId, field, value);
            // Clear the cache for this field so that we fall back to the computed value.
            setInputCache((prev) => ({
                ...prev,
                [breakoutId]: {
                    ...prev[breakoutId],
                    [field]: undefined,
                },
            }));
        }
    };

    // Retrieve the allocation for a given breakout id; if not present, return default values.
    const getAllocationForBreakout = (breakoutId: string) => {
        return (
            allocations.find((alloc) => alloc.breakoutid === breakoutId) || {
                allocationpercentage: 0,
                allocationexpense: 0,
            }
        );
    };

    // Calculate total allocation percentage for validation
    const totalPercentage = allocations.reduce((sum, alloc) => sum + alloc.allocationpercentage, 0);

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Breakout Name</TableCell>
                        <TableCell>Allocation %</TableCell>
                        <TableCell>Allocation {formatCurrency(props.costToAllocate)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.breakoutList.map((breakout, idx) => {
                        const alloc = getAllocationForBreakout(breakout.id);
                        return (
                            <TableRow key={idx}>
                                <TableCell>{breakout.breakoutname || "Unknown"}</TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        // Use local input value if available; otherwise, show computed percentage.
                                        value={
                                            inputCache[breakout.id]?.allocationpercentage ??
                                            alloc.allocationpercentage.toFixed(2)
                                        }
                                        onChange={(e) =>
                                            handleInputChange(breakout.id, "allocationpercentage", e.target.value)
                                        }
                                        onBlur={() => handleBlur(breakout.id, "allocationpercentage")}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ min: 0, max: 100 }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        value={
                                            inputCache[breakout.id]?.allocationexpense ??
                                            formatCurrency(
                                                allocateCost({
                                                    costToAllocate: props.costToAllocate,
                                                    allocationPrecentage: alloc.allocationpercentage
                                                }))
                                        }
                                        onChange={(e) =>
                                            handleInputChange(
                                                breakout.id,
                                                "allocationexpense",
                                                e.target.value.replace(/[^0-9.]/g, "")
                                            )
                                        }
                                        onBlur={() => handleBlur(breakout.id, "allocationexpense")}
                                        variant="outlined"
                                        size="small"
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <Box mt={2}>
                <Typography variant="body1">
                    Total Allocation: {totalPercentage.toFixed(2)}%
                </Typography>
                {Math.abs(totalPercentage - 100) > 0.01 && (
                    <Typography variant="caption" color="error">
                        Total allocation must equal 100% of the cost.
                    </Typography>
                )}
            </Box>
        </>
    );
};