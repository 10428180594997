// source: estimateAssembly.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.estimateAssembly.CreateEstimateAssemblyRequest', null, global);
goog.exportSymbol('proto.estimateAssembly.CreateEstimateAssemblyResponse', null, global);
goog.exportSymbol('proto.estimateAssembly.DeleteEstimateAssemblyRequest', null, global);
goog.exportSymbol('proto.estimateAssembly.DeleteEstimateAssemblyResponse', null, global);
goog.exportSymbol('proto.estimateAssembly.DeleteExtendEstimateRequest', null, global);
goog.exportSymbol('proto.estimateAssembly.DeleteExtendEstimateResponse', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateAssembly', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateAssemblyItem', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateAssemblyItemAttribute', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateBreakoutMap', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateItemCost', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateItemQtyFormula', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimatePoint', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateQuoteGroup', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateTakeoffAssemblies', null, global);
goog.exportSymbol('proto.estimateAssembly.ExtendEstimate', null, global);
goog.exportSymbol('proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest', null, global);
goog.exportSymbol('proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse', null, global);
goog.exportSymbol('proto.estimateAssembly.UpdateEstimateAssemblyRequest', null, global);
goog.exportSymbol('proto.estimateAssembly.UpdateEstimateAssemblyResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateTakeoffAssemblies = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.estimateAssembly.EstimateTakeoffAssemblies.repeatedFields_, null);
};
goog.inherits(proto.estimateAssembly.EstimateTakeoffAssemblies, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateTakeoffAssemblies.displayName = 'proto.estimateAssembly.EstimateTakeoffAssemblies';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateAssembly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.estimateAssembly.EstimateAssembly.repeatedFields_, null);
};
goog.inherits(proto.estimateAssembly.EstimateAssembly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateAssembly.displayName = 'proto.estimateAssembly.EstimateAssembly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimatePoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.EstimatePoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimatePoint.displayName = 'proto.estimateAssembly.EstimatePoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.ExtendEstimate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.ExtendEstimate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.ExtendEstimate.displayName = 'proto.estimateAssembly.ExtendEstimate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateAssemblyItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.EstimateAssemblyItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateAssemblyItem.displayName = 'proto.estimateAssembly.EstimateAssemblyItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateItemCost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.EstimateItemCost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateItemCost.displayName = 'proto.estimateAssembly.EstimateItemCost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateItemQtyFormula = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.EstimateItemQtyFormula, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateItemQtyFormula.displayName = 'proto.estimateAssembly.EstimateItemQtyFormula';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.EstimateAssemblyItemAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateAssemblyItemAttribute.displayName = 'proto.estimateAssembly.EstimateAssemblyItemAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateQuoteGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.EstimateQuoteGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateQuoteGroup.displayName = 'proto.estimateAssembly.EstimateQuoteGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateBreakoutMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.EstimateBreakoutMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateBreakoutMap.displayName = 'proto.estimateAssembly.EstimateBreakoutMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.CreateEstimateAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.CreateEstimateAssemblyRequest.displayName = 'proto.estimateAssembly.CreateEstimateAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.CreateEstimateAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.CreateEstimateAssemblyResponse.displayName = 'proto.estimateAssembly.CreateEstimateAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.UpdateEstimateAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.UpdateEstimateAssemblyRequest.displayName = 'proto.estimateAssembly.UpdateEstimateAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.UpdateEstimateAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.UpdateEstimateAssemblyResponse.displayName = 'proto.estimateAssembly.UpdateEstimateAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.DeleteEstimateAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.DeleteEstimateAssemblyRequest.displayName = 'proto.estimateAssembly.DeleteEstimateAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.DeleteEstimateAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.DeleteEstimateAssemblyResponse.displayName = 'proto.estimateAssembly.DeleteEstimateAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.displayName = 'proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.repeatedFields_, null);
};
goog.inherits(proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.displayName = 'proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.DeleteExtendEstimateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.DeleteExtendEstimateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.DeleteExtendEstimateRequest.displayName = 'proto.estimateAssembly.DeleteExtendEstimateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.DeleteExtendEstimateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.DeleteExtendEstimateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.DeleteExtendEstimateResponse.displayName = 'proto.estimateAssembly.DeleteExtendEstimateResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateTakeoffAssemblies.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateTakeoffAssemblies} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    assembliesList: jspb.Message.toObjectList(msg.getAssembliesList(),
    proto.estimateAssembly.EstimateAssembly.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateTakeoffAssemblies}
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateTakeoffAssemblies;
  return proto.estimateAssembly.EstimateTakeoffAssemblies.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateTakeoffAssemblies} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateTakeoffAssemblies}
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 6:
      var value = new proto.estimateAssembly.EstimateAssembly;
      reader.readMessage(value,proto.estimateAssembly.EstimateAssembly.deserializeBinaryFromReader);
      msg.addAssemblies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateTakeoffAssemblies.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateTakeoffAssemblies} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAssembliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.estimateAssembly.EstimateAssembly.serializeBinaryToWriter
    );
  }
};


/**
 * optional string parentId = 1;
 * @return {string}
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.getParentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateTakeoffAssemblies} returns this
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.setParentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateTakeoffAssemblies} returns this
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastUpdated = 3;
 * @return {string}
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateTakeoffAssemblies} returns this
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string createdByUserId = 4;
 * @return {string}
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateTakeoffAssemblies} returns this
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string createdByAccountId = 5;
 * @return {string}
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateTakeoffAssemblies} returns this
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated EstimateAssembly assemblies = 6;
 * @return {!Array<!proto.estimateAssembly.EstimateAssembly>}
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.getAssembliesList = function() {
  return /** @type{!Array<!proto.estimateAssembly.EstimateAssembly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.estimateAssembly.EstimateAssembly, 6));
};


/**
 * @param {!Array<!proto.estimateAssembly.EstimateAssembly>} value
 * @return {!proto.estimateAssembly.EstimateTakeoffAssemblies} returns this
*/
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.setAssembliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.estimateAssembly.EstimateAssembly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.estimateAssembly.EstimateAssembly}
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.addAssemblies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.estimateAssembly.EstimateAssembly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.estimateAssembly.EstimateTakeoffAssemblies} returns this
 */
proto.estimateAssembly.EstimateTakeoffAssemblies.prototype.clearAssembliesList = function() {
  return this.setAssembliesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.estimateAssembly.EstimateAssembly.repeatedFields_ = [27,28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateAssembly.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateAssembly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateAssembly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateAssembly.toObject = function(includeInstance, msg) {
  var f, obj = {
    assemblyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assemblyname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    objectid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    projectid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 6, ""),
    totalsegmentlength: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalpointcount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalsegmentcount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    totalsqft: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    segmentlength: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    segmentcount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    measuredarea: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    pointcount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    pointtype: jspb.Message.getFieldWithDefault(msg, 15, ""),
    pointsize: jspb.Message.getFieldWithDefault(msg, 16, 0),
    pointbordercolor: jspb.Message.getFieldWithDefault(msg, 17, ""),
    pointfillcolor: jspb.Message.getFieldWithDefault(msg, 18, ""),
    linesize: jspb.Message.getFieldWithDefault(msg, 19, 0),
    linecolor: jspb.Message.getFieldWithDefault(msg, 20, ""),
    linedasharray: jspb.Message.getFieldWithDefault(msg, 21, ""),
    userscale: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 23, 0),
    assemblymeasurementtype: jspb.Message.getFieldWithDefault(msg, 24, ""),
    imageclassificationid: jspb.Message.getFieldWithDefault(msg, 25, 0),
    datecreated: jspb.Message.getFieldWithDefault(msg, 26, ""),
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.estimateAssembly.EstimatePoint.toObject, includeInstance),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.estimateAssembly.EstimateAssemblyItem.toObject, includeInstance),
    fillopacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateAssembly}
 */
proto.estimateAssembly.EstimateAssembly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateAssembly;
  return proto.estimateAssembly.EstimateAssembly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateAssembly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateAssembly}
 */
proto.estimateAssembly.EstimateAssembly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalsegmentlength(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalpointcount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalsegmentcount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalsqft(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSegmentlength(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSegmentcount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeasuredarea(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointcount(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointtype(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointsize(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointbordercolor(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointfillcolor(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLinesize(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinecolor(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinedasharray(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUserscale(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblymeasurementtype(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImageclassificationid(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 27:
      var value = new proto.estimateAssembly.EstimatePoint;
      reader.readMessage(value,proto.estimateAssembly.EstimatePoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    case 28:
      var value = new proto.estimateAssembly.EstimateAssemblyItem;
      reader.readMessage(value,proto.estimateAssembly.EstimateAssemblyItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFillopacity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateAssembly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateAssembly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateAssembly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateAssembly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssemblyname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getObjectid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTotalsegmentlength();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalpointcount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTotalsegmentcount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTotalsqft();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getSegmentlength();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getSegmentcount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getMeasuredarea();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getPointcount();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getPointtype();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPointsize();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getPointbordercolor();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPointfillcolor();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getLinesize();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getLinecolor();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getLinedasharray();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getUserscale();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getAssemblymeasurementtype();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getImageclassificationid();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.estimateAssembly.EstimatePoint.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      proto.estimateAssembly.EstimateAssemblyItem.serializeBinaryToWriter
    );
  }
  f = message.getFillopacity();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
};


/**
 * optional string assemblyId = 1;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assemblyName = 2;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getAssemblyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setAssemblyname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string objectId = 3;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getObjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setObjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string projectId = 4;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string estimateId = 5;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lastUpdated = 6;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double totalSegmentLength = 7;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getTotalsegmentlength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setTotalsegmentlength = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int32 totalPointCount = 8;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getTotalpointcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setTotalpointcount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 totalSegmentCount = 9;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getTotalsegmentcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setTotalsegmentcount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional float totalSqft = 10;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getTotalsqft = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setTotalsqft = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float segmentLength = 11;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getSegmentlength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setSegmentlength = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional int32 segmentCount = 12;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getSegmentcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setSegmentcount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional float measuredArea = 13;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getMeasuredarea = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setMeasuredarea = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional int32 pointCount = 14;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPointcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setPointcount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string pointType = 15;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPointtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setPointtype = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 pointSize = 16;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPointsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setPointsize = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string pointBorderColor = 17;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPointbordercolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setPointbordercolor = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string pointFillColor = 18;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPointfillcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setPointfillcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int32 lineSize = 19;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getLinesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setLinesize = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string lineColor = 20;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getLinecolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setLinecolor = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string lineDashArray = 21;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getLinedasharray = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setLinedasharray = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional double userScale = 22;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getUserscale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setUserscale = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional int32 pageNumber = 23;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional string assemblyMeasurementType = 24;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getAssemblymeasurementtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setAssemblymeasurementtype = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional int32 imageClassificationId = 25;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getImageclassificationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setImageclassificationid = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string dateCreated = 26;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * repeated EstimatePoint points = 27;
 * @return {!Array<!proto.estimateAssembly.EstimatePoint>}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.estimateAssembly.EstimatePoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.estimateAssembly.EstimatePoint, 27));
};


/**
 * @param {!Array<!proto.estimateAssembly.EstimatePoint>} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
*/
proto.estimateAssembly.EstimateAssembly.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.estimateAssembly.EstimatePoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.estimateAssembly.EstimatePoint}
 */
proto.estimateAssembly.EstimateAssembly.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.estimateAssembly.EstimatePoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};


/**
 * repeated EstimateAssemblyItem items = 28;
 * @return {!Array<!proto.estimateAssembly.EstimateAssemblyItem>}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.estimateAssembly.EstimateAssemblyItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.estimateAssembly.EstimateAssemblyItem, 28));
};


/**
 * @param {!Array<!proto.estimateAssembly.EstimateAssemblyItem>} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
*/
proto.estimateAssembly.EstimateAssembly.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.estimateAssembly.EstimateAssemblyItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.estimateAssembly.EstimateAssemblyItem}
 */
proto.estimateAssembly.EstimateAssembly.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.estimateAssembly.EstimateAssemblyItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional double fillOpacity = 29;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getFillopacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setFillopacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimatePoint.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimatePoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimatePoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimatePoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    angle: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    verticallength: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    allowtrailingline: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isincludedincount: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isselected: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimatePoint}
 */
proto.estimateAssembly.EstimatePoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimatePoint;
  return proto.estimateAssembly.EstimatePoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimatePoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimatePoint}
 */
proto.estimateAssembly.EstimatePoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAngle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVerticallength(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowtrailingline(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsincludedincount(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsselected(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimatePoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimatePoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimatePoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimatePoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getVerticallength();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAllowtrailingline();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsincludedincount();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsselected();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string pointId = 1;
 * @return {string}
 */
proto.estimateAssembly.EstimatePoint.prototype.getPointid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setPointid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float x = 2;
 * @return {number}
 */
proto.estimateAssembly.EstimatePoint.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float y = 3;
 * @return {number}
 */
proto.estimateAssembly.EstimatePoint.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float angle = 4;
 * @return {number}
 */
proto.estimateAssembly.EstimatePoint.prototype.getAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float verticalLength = 5;
 * @return {number}
 */
proto.estimateAssembly.EstimatePoint.prototype.getVerticallength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setVerticallength = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool allowTrailingLine = 6;
 * @return {boolean}
 */
proto.estimateAssembly.EstimatePoint.prototype.getAllowtrailingline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setAllowtrailingline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool isIncludedInCount = 7;
 * @return {boolean}
 */
proto.estimateAssembly.EstimatePoint.prototype.getIsincludedincount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setIsincludedincount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool isSelected = 8;
 * @return {boolean}
 */
proto.estimateAssembly.EstimatePoint.prototype.getIsselected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setIsselected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool isActive = 9;
 * @return {boolean}
 */
proto.estimateAssembly.EstimatePoint.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.ExtendEstimate.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.ExtendEstimate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.ExtendEstimate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.ExtendEstimate.toObject = function(includeInstance, msg) {
  var f, obj = {
    isoverrideitemunitcost: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    overrideitemunitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    isoverridehourlyproductionrate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    overridehourlyproductionrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalitemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    totalmaterialcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totallaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    breakoutid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    extensionid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    estimateid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    assemblyid: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.ExtendEstimate}
 */
proto.estimateAssembly.ExtendEstimate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.ExtendEstimate;
  return proto.estimateAssembly.ExtendEstimate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.ExtendEstimate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.ExtendEstimate}
 */
proto.estimateAssembly.ExtendEstimate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsoverrideitemunitcost(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverrideitemunitcost(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsoverridehourlyproductionrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverridehourlyproductionrate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalitemqty(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalmaterialcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotallaborhours(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionid(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.ExtendEstimate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.ExtendEstimate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.ExtendEstimate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.ExtendEstimate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsoverrideitemunitcost();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOverrideitemunitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getIsoverridehourlyproductionrate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOverridehourlyproductionrate();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTotalitemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getTotalmaterialcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTotallaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getBreakoutid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getExtensionid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional bool isOverrideItemUnitCost = 1;
 * @return {boolean}
 */
proto.estimateAssembly.ExtendEstimate.prototype.getIsoverrideitemunitcost = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.ExtendEstimate} returns this
 */
proto.estimateAssembly.ExtendEstimate.prototype.setIsoverrideitemunitcost = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional float overrideItemUnitCost = 2;
 * @return {number}
 */
proto.estimateAssembly.ExtendEstimate.prototype.getOverrideitemunitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.ExtendEstimate} returns this
 */
proto.estimateAssembly.ExtendEstimate.prototype.setOverrideitemunitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool isOverrideHourlyProductionRate = 3;
 * @return {boolean}
 */
proto.estimateAssembly.ExtendEstimate.prototype.getIsoverridehourlyproductionrate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.ExtendEstimate} returns this
 */
proto.estimateAssembly.ExtendEstimate.prototype.setIsoverridehourlyproductionrate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional float overrideHourlyProductionRate = 4;
 * @return {number}
 */
proto.estimateAssembly.ExtendEstimate.prototype.getOverridehourlyproductionrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.ExtendEstimate} returns this
 */
proto.estimateAssembly.ExtendEstimate.prototype.setOverridehourlyproductionrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float totalItemQty = 5;
 * @return {number}
 */
proto.estimateAssembly.ExtendEstimate.prototype.getTotalitemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.ExtendEstimate} returns this
 */
proto.estimateAssembly.ExtendEstimate.prototype.setTotalitemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float totalMaterialCost = 6;
 * @return {number}
 */
proto.estimateAssembly.ExtendEstimate.prototype.getTotalmaterialcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.ExtendEstimate} returns this
 */
proto.estimateAssembly.ExtendEstimate.prototype.setTotalmaterialcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float totalLaborHours = 7;
 * @return {number}
 */
proto.estimateAssembly.ExtendEstimate.prototype.getTotallaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.ExtendEstimate} returns this
 */
proto.estimateAssembly.ExtendEstimate.prototype.setTotallaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool isQuoted = 8;
 * @return {boolean}
 */
proto.estimateAssembly.ExtendEstimate.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.ExtendEstimate} returns this
 */
proto.estimateAssembly.ExtendEstimate.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string breakoutId = 9;
 * @return {string}
 */
proto.estimateAssembly.ExtendEstimate.prototype.getBreakoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.ExtendEstimate} returns this
 */
proto.estimateAssembly.ExtendEstimate.prototype.setBreakoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string extensionId = 10;
 * @return {string}
 */
proto.estimateAssembly.ExtendEstimate.prototype.getExtensionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.ExtendEstimate} returns this
 */
proto.estimateAssembly.ExtendEstimate.prototype.setExtensionid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 attributeValueId = 11;
 * @return {number}
 */
proto.estimateAssembly.ExtendEstimate.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.ExtendEstimate} returns this
 */
proto.estimateAssembly.ExtendEstimate.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string estimateId = 12;
 * @return {string}
 */
proto.estimateAssembly.ExtendEstimate.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.ExtendEstimate} returns this
 */
proto.estimateAssembly.ExtendEstimate.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string assemblyId = 13;
 * @return {string}
 */
proto.estimateAssembly.ExtendEstimate.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.ExtendEstimate} returns this
 */
proto.estimateAssembly.ExtendEstimate.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateAssemblyItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateAssemblyItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateAssemblyItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    costs: (f = msg.getCosts()) && proto.estimateAssembly.EstimateItemCost.toObject(includeInstance, f),
    itemqtyformula: (f = msg.getItemqtyformula()) && proto.estimateAssembly.EstimateItemQtyFormula.toObject(includeInstance, f),
    attribute: (f = msg.getAttribute()) && proto.estimateAssembly.EstimateAssemblyItemAttribute.toObject(includeInstance, f),
    quotegroup: (f = msg.getQuotegroup()) && proto.estimateAssembly.EstimateQuoteGroup.toObject(includeInstance, f),
    breakout: (f = msg.getBreakout()) && proto.estimateAssembly.EstimateBreakoutMap.toObject(includeInstance, f),
    extend: (f = msg.getExtend()) && proto.estimateAssembly.ExtendEstimate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateAssemblyItem}
 */
proto.estimateAssembly.EstimateAssemblyItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateAssemblyItem;
  return proto.estimateAssembly.EstimateAssemblyItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateAssemblyItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateAssemblyItem}
 */
proto.estimateAssembly.EstimateAssemblyItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemname(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 4:
      var value = new proto.estimateAssembly.EstimateItemCost;
      reader.readMessage(value,proto.estimateAssembly.EstimateItemCost.deserializeBinaryFromReader);
      msg.setCosts(value);
      break;
    case 5:
      var value = new proto.estimateAssembly.EstimateItemQtyFormula;
      reader.readMessage(value,proto.estimateAssembly.EstimateItemQtyFormula.deserializeBinaryFromReader);
      msg.setItemqtyformula(value);
      break;
    case 6:
      var value = new proto.estimateAssembly.EstimateAssemblyItemAttribute;
      reader.readMessage(value,proto.estimateAssembly.EstimateAssemblyItemAttribute.deserializeBinaryFromReader);
      msg.setAttribute(value);
      break;
    case 7:
      var value = new proto.estimateAssembly.EstimateQuoteGroup;
      reader.readMessage(value,proto.estimateAssembly.EstimateQuoteGroup.deserializeBinaryFromReader);
      msg.setQuotegroup(value);
      break;
    case 8:
      var value = new proto.estimateAssembly.EstimateBreakoutMap;
      reader.readMessage(value,proto.estimateAssembly.EstimateBreakoutMap.deserializeBinaryFromReader);
      msg.setBreakout(value);
      break;
    case 9:
      var value = new proto.estimateAssembly.ExtendEstimate;
      reader.readMessage(value,proto.estimateAssembly.ExtendEstimate.deserializeBinaryFromReader);
      msg.setExtend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateAssemblyItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateAssemblyItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateAssemblyItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCosts();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.estimateAssembly.EstimateItemCost.serializeBinaryToWriter
    );
  }
  f = message.getItemqtyformula();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.estimateAssembly.EstimateItemQtyFormula.serializeBinaryToWriter
    );
  }
  f = message.getAttribute();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.estimateAssembly.EstimateAssemblyItemAttribute.serializeBinaryToWriter
    );
  }
  f = message.getQuotegroup();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.estimateAssembly.EstimateQuoteGroup.serializeBinaryToWriter
    );
  }
  f = message.getBreakout();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.estimateAssembly.EstimateBreakoutMap.serializeBinaryToWriter
    );
  }
  f = message.getExtend();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.estimateAssembly.ExtendEstimate.serializeBinaryToWriter
    );
  }
};


/**
 * optional string itemId = 1;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.getItemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.setItemid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string itemName = 2;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.getItemname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.setItemname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isQuoted = 3;
 * @return {boolean}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional EstimateItemCost costs = 4;
 * @return {?proto.estimateAssembly.EstimateItemCost}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.getCosts = function() {
  return /** @type{?proto.estimateAssembly.EstimateItemCost} */ (
    jspb.Message.getWrapperField(this, proto.estimateAssembly.EstimateItemCost, 4));
};


/**
 * @param {?proto.estimateAssembly.EstimateItemCost|undefined} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
*/
proto.estimateAssembly.EstimateAssemblyItem.prototype.setCosts = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.clearCosts = function() {
  return this.setCosts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.hasCosts = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EstimateItemQtyFormula itemQtyFormula = 5;
 * @return {?proto.estimateAssembly.EstimateItemQtyFormula}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.getItemqtyformula = function() {
  return /** @type{?proto.estimateAssembly.EstimateItemQtyFormula} */ (
    jspb.Message.getWrapperField(this, proto.estimateAssembly.EstimateItemQtyFormula, 5));
};


/**
 * @param {?proto.estimateAssembly.EstimateItemQtyFormula|undefined} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
*/
proto.estimateAssembly.EstimateAssemblyItem.prototype.setItemqtyformula = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.clearItemqtyformula = function() {
  return this.setItemqtyformula(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.hasItemqtyformula = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional EstimateAssemblyItemAttribute attribute = 6;
 * @return {?proto.estimateAssembly.EstimateAssemblyItemAttribute}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.getAttribute = function() {
  return /** @type{?proto.estimateAssembly.EstimateAssemblyItemAttribute} */ (
    jspb.Message.getWrapperField(this, proto.estimateAssembly.EstimateAssemblyItemAttribute, 6));
};


/**
 * @param {?proto.estimateAssembly.EstimateAssemblyItemAttribute|undefined} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
*/
proto.estimateAssembly.EstimateAssemblyItem.prototype.setAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.clearAttribute = function() {
  return this.setAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.hasAttribute = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional EstimateQuoteGroup quoteGroup = 7;
 * @return {?proto.estimateAssembly.EstimateQuoteGroup}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.getQuotegroup = function() {
  return /** @type{?proto.estimateAssembly.EstimateQuoteGroup} */ (
    jspb.Message.getWrapperField(this, proto.estimateAssembly.EstimateQuoteGroup, 7));
};


/**
 * @param {?proto.estimateAssembly.EstimateQuoteGroup|undefined} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
*/
proto.estimateAssembly.EstimateAssemblyItem.prototype.setQuotegroup = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.clearQuotegroup = function() {
  return this.setQuotegroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.hasQuotegroup = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EstimateBreakoutMap breakout = 8;
 * @return {?proto.estimateAssembly.EstimateBreakoutMap}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.getBreakout = function() {
  return /** @type{?proto.estimateAssembly.EstimateBreakoutMap} */ (
    jspb.Message.getWrapperField(this, proto.estimateAssembly.EstimateBreakoutMap, 8));
};


/**
 * @param {?proto.estimateAssembly.EstimateBreakoutMap|undefined} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
*/
proto.estimateAssembly.EstimateAssemblyItem.prototype.setBreakout = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.clearBreakout = function() {
  return this.setBreakout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.hasBreakout = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ExtendEstimate extend = 9;
 * @return {?proto.estimateAssembly.ExtendEstimate}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.getExtend = function() {
  return /** @type{?proto.estimateAssembly.ExtendEstimate} */ (
    jspb.Message.getWrapperField(this, proto.estimateAssembly.ExtendEstimate, 9));
};


/**
 * @param {?proto.estimateAssembly.ExtendEstimate|undefined} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
*/
proto.estimateAssembly.EstimateAssemblyItem.prototype.setExtend = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.estimateAssembly.EstimateAssemblyItem} returns this
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.clearExtend = function() {
  return this.setExtend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.estimateAssembly.EstimateAssemblyItem.prototype.hasExtend = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateItemCost.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateItemCost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateItemCost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateItemCost.toObject = function(includeInstance, msg) {
  var f, obj = {
    uomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemunitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    hourlyproductionrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    uomname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    uomvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateItemCost}
 */
proto.estimateAssembly.EstimateItemCost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateItemCost;
  return proto.estimateAssembly.EstimateItemCost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateItemCost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateItemCost}
 */
proto.estimateAssembly.EstimateItemCost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUomid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemunitcost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHourlyproductionrate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUomname(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUomvalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateItemCost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateItemCost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateItemCost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateItemCost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUomid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemunitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getHourlyproductionrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getUomname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUomvalue();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional int32 uomId = 1;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemCost.prototype.getUomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemCost} returns this
 */
proto.estimateAssembly.EstimateItemCost.prototype.setUomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float itemUnitCost = 2;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemCost.prototype.getItemunitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemCost} returns this
 */
proto.estimateAssembly.EstimateItemCost.prototype.setItemunitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float hourlyProductionRate = 3;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemCost.prototype.getHourlyproductionrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemCost} returns this
 */
proto.estimateAssembly.EstimateItemCost.prototype.setHourlyproductionrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string uomName = 4;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemCost.prototype.getUomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemCost} returns this
 */
proto.estimateAssembly.EstimateItemCost.prototype.setUomname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float uomValue = 5;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemCost.prototype.getUomvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemCost} returns this
 */
proto.estimateAssembly.EstimateItemCost.prototype.setUomvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateItemQtyFormula.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateItemQtyFormula} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateItemQtyFormula.toObject = function(includeInstance, msg) {
  var f, obj = {
    formulaid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    forevery: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    takeoffvariabletype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    and: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    or: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    itemid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    quotegroupid: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula}
 */
proto.estimateAssembly.EstimateItemQtyFormula.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateItemQtyFormula;
  return proto.estimateAssembly.EstimateItemQtyFormula.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateItemQtyFormula} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula}
 */
proto.estimateAssembly.EstimateItemQtyFormula.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormulaid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemqty(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setForevery(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffvariabletype(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnd(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOr(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemid(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroupid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateItemQtyFormula.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateItemQtyFormula} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateItemQtyFormula.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormulaid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getForevery();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTakeoffvariabletype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAnd();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOr();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getItemid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getQuotegroupid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string formulaId = 1;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getFormulaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setFormulaid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float itemQty = 2;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getItemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setItemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float forEvery = 3;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getForevery = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setForevery = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string takeoffVariableType = 4;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getTakeoffvariabletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setTakeoffvariabletype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool and = 5;
 * @return {boolean}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getAnd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setAnd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool or = 6;
 * @return {boolean}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getOr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setOr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string itemId = 7;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getItemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setItemid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool isQuoted = 8;
 * @return {boolean}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 attributeValueId = 9;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string quoteGroupId = 10;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getQuotegroupid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setQuotegroupid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateAssemblyItemAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateAssemblyItemAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attributevaluename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    attributegroupid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    attributegroupname: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateAssemblyItemAttribute;
  return proto.estimateAssembly.EstimateAssemblyItemAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateAssemblyItemAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributevaluename(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributegroupid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateAssemblyItemAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateAssemblyItemAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAttributevaluename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAttributegroupid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAttributegroupname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 attributeValueId = 1;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute} returns this
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string attributeValueName = 2;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.getAttributevaluename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute} returns this
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.setAttributevaluename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 attributeGroupId = 3;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.getAttributegroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute} returns this
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.setAttributegroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string attributeGroupName = 4;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.getAttributegroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute} returns this
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.setAttributegroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateQuoteGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateQuoteGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateQuoteGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateQuoteGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quotegroup: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateQuoteGroup}
 */
proto.estimateAssembly.EstimateQuoteGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateQuoteGroup;
  return proto.estimateAssembly.EstimateQuoteGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateQuoteGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateQuoteGroup}
 */
proto.estimateAssembly.EstimateQuoteGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateQuoteGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateQuoteGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateQuoteGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateQuoteGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuotegroup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Id = 1;
 * @return {string}
 */
proto.estimateAssembly.EstimateQuoteGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateQuoteGroup} returns this
 */
proto.estimateAssembly.EstimateQuoteGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string quoteGroup = 2;
 * @return {string}
 */
proto.estimateAssembly.EstimateQuoteGroup.prototype.getQuotegroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateQuoteGroup} returns this
 */
proto.estimateAssembly.EstimateQuoteGroup.prototype.setQuotegroup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateBreakoutMap.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateBreakoutMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateBreakoutMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateBreakoutMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    breakoutmapid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    breakoutid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    breakoutname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    multiplier: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateBreakoutMap}
 */
proto.estimateAssembly.EstimateBreakoutMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateBreakoutMap;
  return proto.estimateAssembly.EstimateBreakoutMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateBreakoutMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateBreakoutMap}
 */
proto.estimateAssembly.EstimateBreakoutMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutmapid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutname(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMultiplier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateBreakoutMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateBreakoutMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateBreakoutMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateBreakoutMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBreakoutmapid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBreakoutid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBreakoutname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMultiplier();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string breakoutMapId = 1;
 * @return {string}
 */
proto.estimateAssembly.EstimateBreakoutMap.prototype.getBreakoutmapid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateBreakoutMap} returns this
 */
proto.estimateAssembly.EstimateBreakoutMap.prototype.setBreakoutmapid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string breakoutId = 2;
 * @return {string}
 */
proto.estimateAssembly.EstimateBreakoutMap.prototype.getBreakoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateBreakoutMap} returns this
 */
proto.estimateAssembly.EstimateBreakoutMap.prototype.setBreakoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string breakoutName = 3;
 * @return {string}
 */
proto.estimateAssembly.EstimateBreakoutMap.prototype.getBreakoutname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateBreakoutMap} returns this
 */
proto.estimateAssembly.EstimateBreakoutMap.prototype.setBreakoutname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 multiplier = 4;
 * @return {number}
 */
proto.estimateAssembly.EstimateBreakoutMap.prototype.getMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateBreakoutMap} returns this
 */
proto.estimateAssembly.EstimateBreakoutMap.prototype.setMultiplier = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.CreateEstimateAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.CreateEstimateAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    takeoffassembly: (f = msg.getTakeoffassembly()) && proto.estimateAssembly.EstimateTakeoffAssemblies.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyRequest}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.CreateEstimateAssemblyRequest;
  return proto.estimateAssembly.CreateEstimateAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.CreateEstimateAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyRequest}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.estimateAssembly.EstimateTakeoffAssemblies;
      reader.readMessage(value,proto.estimateAssembly.EstimateTakeoffAssemblies.deserializeBinaryFromReader);
      msg.setTakeoffassembly(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.CreateEstimateAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.CreateEstimateAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTakeoffassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.estimateAssembly.EstimateTakeoffAssemblies.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional EstimateTakeoffAssemblies takeoffAssembly = 1;
 * @return {?proto.estimateAssembly.EstimateTakeoffAssemblies}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.getTakeoffassembly = function() {
  return /** @type{?proto.estimateAssembly.EstimateTakeoffAssemblies} */ (
    jspb.Message.getWrapperField(this, proto.estimateAssembly.EstimateTakeoffAssemblies, 1));
};


/**
 * @param {?proto.estimateAssembly.EstimateTakeoffAssemblies|undefined} value
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyRequest} returns this
*/
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.setTakeoffassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyRequest} returns this
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.clearTakeoffassembly = function() {
  return this.setTakeoffassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.hasTakeoffassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyRequest} returns this
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.CreateEstimateAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.CreateEstimateAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyResponse}
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.CreateEstimateAssemblyResponse;
  return proto.estimateAssembly.CreateEstimateAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.CreateEstimateAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyResponse}
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.CreateEstimateAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.CreateEstimateAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyResponse} returns this
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.UpdateEstimateAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.UpdateEstimateAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    takeoffassembly: (f = msg.getTakeoffassembly()) && proto.estimateAssembly.EstimateTakeoffAssemblies.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyRequest}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.UpdateEstimateAssemblyRequest;
  return proto.estimateAssembly.UpdateEstimateAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.UpdateEstimateAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyRequest}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.estimateAssembly.EstimateTakeoffAssemblies;
      reader.readMessage(value,proto.estimateAssembly.EstimateTakeoffAssemblies.deserializeBinaryFromReader);
      msg.setTakeoffassembly(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.UpdateEstimateAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.UpdateEstimateAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTakeoffassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.estimateAssembly.EstimateTakeoffAssemblies.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional EstimateTakeoffAssemblies takeoffAssembly = 1;
 * @return {?proto.estimateAssembly.EstimateTakeoffAssemblies}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.getTakeoffassembly = function() {
  return /** @type{?proto.estimateAssembly.EstimateTakeoffAssemblies} */ (
    jspb.Message.getWrapperField(this, proto.estimateAssembly.EstimateTakeoffAssemblies, 1));
};


/**
 * @param {?proto.estimateAssembly.EstimateTakeoffAssemblies|undefined} value
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyRequest} returns this
*/
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.setTakeoffassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyRequest} returns this
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.clearTakeoffassembly = function() {
  return this.setTakeoffassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.hasTakeoffassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyRequest} returns this
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.UpdateEstimateAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.UpdateEstimateAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyResponse}
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.UpdateEstimateAssemblyResponse;
  return proto.estimateAssembly.UpdateEstimateAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.UpdateEstimateAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyResponse}
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.UpdateEstimateAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.UpdateEstimateAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyResponse} returns this
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.DeleteEstimateAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.DeleteEstimateAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assemblyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyRequest}
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.DeleteEstimateAssemblyRequest;
  return proto.estimateAssembly.DeleteEstimateAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.DeleteEstimateAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyRequest}
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.DeleteEstimateAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.DeleteEstimateAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string assemblyId = 1;
 * @return {string}
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyRequest} returns this
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyRequest} returns this
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.DeleteEstimateAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.DeleteEstimateAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyResponse}
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.DeleteEstimateAssemblyResponse;
  return proto.estimateAssembly.DeleteEstimateAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.DeleteEstimateAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyResponse}
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.DeleteEstimateAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.DeleteEstimateAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyResponse} returns this
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest;
  return proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest} returns this
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest} returns this
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    takeoffassembliesList: jspb.Message.toObjectList(msg.getTakeoffassembliesList(),
    proto.estimateAssembly.EstimateTakeoffAssemblies.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse;
  return proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.estimateAssembly.EstimateTakeoffAssemblies;
      reader.readMessage(value,proto.estimateAssembly.EstimateTakeoffAssemblies.deserializeBinaryFromReader);
      msg.addTakeoffassemblies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTakeoffassembliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.estimateAssembly.EstimateTakeoffAssemblies.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EstimateTakeoffAssemblies takeoffAssemblies = 1;
 * @return {!Array<!proto.estimateAssembly.EstimateTakeoffAssemblies>}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.prototype.getTakeoffassembliesList = function() {
  return /** @type{!Array<!proto.estimateAssembly.EstimateTakeoffAssemblies>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.estimateAssembly.EstimateTakeoffAssemblies, 1));
};


/**
 * @param {!Array<!proto.estimateAssembly.EstimateTakeoffAssemblies>} value
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse} returns this
*/
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.prototype.setTakeoffassembliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.estimateAssembly.EstimateTakeoffAssemblies=} opt_value
 * @param {number=} opt_index
 * @return {!proto.estimateAssembly.EstimateTakeoffAssemblies}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.prototype.addTakeoffassemblies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.estimateAssembly.EstimateTakeoffAssemblies, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse} returns this
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.prototype.clearTakeoffassembliesList = function() {
  return this.setTakeoffassembliesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.DeleteExtendEstimateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.DeleteExtendEstimateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.DeleteExtendEstimateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.DeleteExtendEstimateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assemblyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.DeleteExtendEstimateRequest}
 */
proto.estimateAssembly.DeleteExtendEstimateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.DeleteExtendEstimateRequest;
  return proto.estimateAssembly.DeleteExtendEstimateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.DeleteExtendEstimateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.DeleteExtendEstimateRequest}
 */
proto.estimateAssembly.DeleteExtendEstimateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.DeleteExtendEstimateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.DeleteExtendEstimateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.DeleteExtendEstimateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.DeleteExtendEstimateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string assemblyId = 1;
 * @return {string}
 */
proto.estimateAssembly.DeleteExtendEstimateRequest.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.DeleteExtendEstimateRequest} returns this
 */
proto.estimateAssembly.DeleteExtendEstimateRequest.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.estimateAssembly.DeleteExtendEstimateRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.DeleteExtendEstimateRequest} returns this
 */
proto.estimateAssembly.DeleteExtendEstimateRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.DeleteExtendEstimateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.DeleteExtendEstimateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.DeleteExtendEstimateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.DeleteExtendEstimateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.DeleteExtendEstimateResponse}
 */
proto.estimateAssembly.DeleteExtendEstimateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.DeleteExtendEstimateResponse;
  return proto.estimateAssembly.DeleteExtendEstimateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.DeleteExtendEstimateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.DeleteExtendEstimateResponse}
 */
proto.estimateAssembly.DeleteExtendEstimateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.DeleteExtendEstimateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.DeleteExtendEstimateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.DeleteExtendEstimateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.DeleteExtendEstimateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.estimateAssembly.DeleteExtendEstimateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.DeleteExtendEstimateResponse} returns this
 */
proto.estimateAssembly.DeleteExtendEstimateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.estimateAssembly);
