// ToolBarHelpers.ts

import { EstimateAssembly } from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { UserScaleList } from "../api/protosCompiled/object/object_pb";
import { ObjectService as osc } from "../api/ObjectService";
import { Object as ProtoObject } from "../api/protosCompiled/object/object_pb";
import { ResetStates } from "../states/resetStates";
import { ChangeOrderAssembly } from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { EstimateStore } from "../states/store";
import { AssemblyStore } from "../states/AssemblyStore";
import { KeyBoardEventStore } from "../states/keyEventStore";

// Namespace to group toolbar-related helper functions
export namespace ToolBarHelper {
  // Extract segment length from an assembly
  export const extractSegmentLength = (
    assembly: EstimateAssembly.AsObject | ChangeOrderAssembly.AsObject
  ) => {
    const segmentLength = assembly?.segmentlength ?? 0;
    const feet = Math.floor(segmentLength);
    const inches = Math.round((segmentLength - feet) * 12);
    return { feet, inches };
  };

  // Filter available objects based on a given id
  export const filterAvailableObjects = (
    availableObjects: ProtoObject.AsObject[],
    id: string
  ) => {
    const selectedObject = availableObjects.find((object) => object.id === id);
    return selectedObject;
  };

  // Handle object selection from the dropdown
  export const handleGetObject = async (
    e: any,
    availableObjects: ProtoObject.AsObject[],
    setSelectedObjectId: (id: string) => void,
    setSelectedObjectName: (name: string) => void,
    setUserScaleList: (scales: UserScaleList.AsObject[]) => void,
    setUserScale: (scale: number) => void,
    numPages: number,
    currentPageIndex: number,
    currentObjectRef: React.MutableRefObject<number>
  ) => {
    const [id, gcsObjectName] = e.target.value.split(",");
    setSelectedObjectId(id);
    setSelectedObjectName(gcsObjectName);

    if (availableObjects && availableObjects.length > 0) {
      const selectedObject = filterAvailableObjects(availableObjects, id);

      if (selectedObject && selectedObject.userscalesList) {
        if (selectedObject.userscalesList.length === 0) {
          const initialScales: UserScaleList.AsObject[] = Array(numPages)
            .fill(null)
            .map((_, i) => ({ pagenumber: i + 1, scale: 0 }));
          setUserScaleList(initialScales);
          await osc.updateObjectScale(id, initialScales);
        } else {
          setUserScaleList(selectedObject.userscalesList || []);
        }
        currentObjectRef.current =
          availableObjects.indexOf(selectedObject) || 0;

        const scale =
          selectedObject.userscalesList[currentPageIndex]?.scale ?? 0;
        setUserScale(scale);
      } else {
        console.error("Selected object not found");
      }
      return selectedObject;
    }
  };

  // Handle page change
  export const handleSetPage = (
    e: any,
    numPages: number,
    setInputPage: (page: number) => void,
    setCurrentPage: (page: number) => void,
    setUserScale: (scale: number) => void,
    availableObjects: any[],
    selectedObjectId: string
  ) => {
    const page = parseInt(e.target.value, 10);
    setInputPage(page);
    if (!isNaN(page) && page >= 1 && page <= numPages) {
      setCurrentPage(page);

      if (availableObjects) {
        const currentObject = availableObjects.find(
          (obj) => obj.id === selectedObjectId
        );
        const scale = currentObject?.userScale[page - 1]?.scale ?? 0;
        setUserScale(scale);
      }
    }
  };

  // Navigate to the next page
  export const handleNextPage = (
    currentPage: number,
    numPages: number,
    setCurrentPage: (page: number) => void,
    setInputPage: (page: number) => void,
    setUserScale: (scale: number) => void,
    availableObjects: any[],
    selectedObjectId: string,
    setInferenceItemFilter: (value: string) => void,
    estimateStore: EstimateStore,
    assemblyStore: AssemblyStore,
    keyboardEventStore: KeyBoardEventStore
  ) => {
    if (currentPage < numPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      setInputPage(nextPage);

      if (availableObjects) {
        const currentObject = availableObjects.find(
          (obj) => obj.id === selectedObjectId
        );
        const scale = currentObject?.userScale[nextPage - 1]?.scale ?? 0;
        setUserScale(scale);
      }
      setInferenceItemFilter("");
      ResetStates.resetTakeoffStates(estimateStore, assemblyStore, keyboardEventStore);
    }
  };

  // Navigate to the previous page
  export const handlePrevPage = (
    currentPage: number,
    setCurrentPage: (page: number) => void,
    setInputPage: (page: number) => void,
    setUserScale: (scale: number) => void,
    availableObjects: any[],
    selectedObjectId: string,
    setInferenceItemFilter: (value: string) => void,
    estimateStore: EstimateStore,
    assemblyStore: AssemblyStore,
    keyboardEventStore: KeyBoardEventStore
  ) => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      setInputPage(prevPage);

      if (availableObjects) {
        const currentObject = availableObjects.find(
          (obj) => obj.id === selectedObjectId
        );
        const scale = currentObject?.userScale[prevPage - 1]?.scale ?? 0;
        setUserScale(scale);
      }
      setInferenceItemFilter("");
      ResetStates.resetTakeoffStates(estimateStore, assemblyStore, keyboardEventStore);
    }
  };

  // Handle setting scale for a page
  export const handleSetScale = async (
    e: React.ChangeEvent<HTMLSelectElement>,
    setUserScale: (scale: number) => void,
    setIsCreatingScale: (isCreating: boolean) => void,
    selectedObject: ProtoObject.AsObject | null,
    refetchObjects: () => void
  ) => {
    if (!selectedObject) return;
    if (e.target.value === "Custom Scale") {
      setIsCreatingScale(true);
      return;
    }
    setIsCreatingScale(false);
    const userScale = parseFloat(e.target.value);
    setUserScale(userScale);

    const sc = [{
      pagenumber: 1,
      scale: userScale
    }]

    await osc.updateObjectScale(selectedObject.id, sc);
    refetchObjects();
  };
}
