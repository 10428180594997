import { clients } from "../clients/grpcClients";
import {
  CreateEstimateAssemblyRequest,
  CreateEstimateAssemblyResponse,
  EstimateAssembly,
  UpdateEstimateAssemblyRequest,
  UpdateEstimateAssemblyResponse,
  EstimatePoint,
  EstimateItemQtyFormula,
  EstimateAssemblyItemAttribute,
  GetEstimateAssembliesByEstimateIdRequest,
  GetEstimateAssembliesByEstimateIdResponse,
  EstimateBreakoutMap,
  EstimateQuoteGroup,
  EstimateTakeoffAssemblies,
  EstimateItemCost,
  EstimateAssemblyItem,
  DeleteExtendEstimateRequest,
  DeleteExtendEstimateResponse,
  DeleteEstimateAssemblyResponse,
  DeleteEstimateAssemblyRequest,
} from "./protosCompiled/estimateAssembly/estimateAssembly_pb";
import { Point } from "../types/Point";
import { useUnityBuildStore } from "../states/store";
import { last } from "lodash";
import { TakeoffAssembly } from "../types/AssemblyItemType";

export class ProjectEstimateAssemblyService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  /**
   * Convert an array of local `Point` objects into repeated `EstimatePoint` messages.
   */
  private static convertToEstimatePoints(pointsList: Point[]): EstimatePoint[] {
    return pointsList.map((p) => {
      const estimatePoint = new EstimatePoint();
      estimatePoint.setPointid(p.pointid || "");
      estimatePoint.setX(p.x || 0);
      estimatePoint.setY(p.y || 0);
      estimatePoint.setAngle(p.angle || 0);
      estimatePoint.setVerticallength(p.verticallength || 0);
      estimatePoint.setAllowtrailingline(p.allowtrailingline || false);
      estimatePoint.setIsincludedincount(p.isincludedincount || false);
      estimatePoint.setIsselected(p.isselected || false);
      estimatePoint.setIsactive(p.isactive || false);
      return estimatePoint;
    });
  }

  /**
   * Build a single `EstimateTakeoffAssemblies` message from your front-end object.
   * Key changes:
   * - We iterate over `assembly.estimateitemsList` to build repeated `EstimateItem`s
   * - Each `EstimateItem` has single fields (costs, itemQtyFormula, attribute, breakout, quoteGroup)
   */
  private static buildTakeoffAssembliesMessage(
    takeoff: EstimateTakeoffAssemblies.AsObject
  ): EstimateTakeoffAssemblies {
    const message = new EstimateTakeoffAssemblies();
    message.setParentid(takeoff.parentid || "");
    message.setEstimateid(takeoff.estimateid || "");
    message.setLastupdated(takeoff.lastupdated || "");
    message.setCreatedbyuserid(takeoff.createdbyuserid || "");
    message.setCreatedbyaccountid(takeoff.createdbyaccountid || "");

    // Convert each `EstimateAssembly.AsObject` to an `EstimateAssembly` message
    if (takeoff.assembliesList && takeoff.assembliesList.length) {
      const assemblyMessages = takeoff.assembliesList.map((asmObj) => {
        const asmMsg = new EstimateAssembly();

        asmMsg.setAssemblyid(asmObj.assemblyid || "");
        asmMsg.setAssemblyname(asmObj.assemblyname || "");
        asmMsg.setObjectid(asmObj.objectid || "");

        asmMsg.setTotalsegmentlength(asmObj.totalsegmentlength || 0);
        asmMsg.setTotalpointcount(asmObj.totalpointcount || 0);
        asmMsg.setTotalsegmentcount(asmObj.totalsegmentcount || 0);
        asmMsg.setTotalsqft(asmObj.totalsqft || 0);

        asmMsg.setSegmentlength(asmObj.segmentlength || 0);
        asmMsg.setSegmentcount(asmObj.segmentcount || 0);
        asmMsg.setMeasuredarea(asmObj.measuredarea || 0);
        asmMsg.setPointcount(asmObj.pointcount || 0);
        asmMsg.setPointtype(asmObj.pointtype || "");
        asmMsg.setPointsize(asmObj.pointsize || 0);
        asmMsg.setPointbordercolor(asmObj.pointbordercolor || "");
        asmMsg.setPointfillcolor(asmObj.pointfillcolor || "");
        asmMsg.setLinesize(asmObj.linesize || 0);
        asmMsg.setLinecolor(asmObj.linecolor || "");
        asmMsg.setLinedasharray(asmObj.linedasharray || "");
        asmMsg.setUserscale(asmObj.userscale || 0);
        asmMsg.setPagenumber(asmObj.pagenumber || 1);
        asmMsg.setAssemblyname(asmObj.assemblyname || "");
        asmMsg.setAssemblymeasurementtype(asmObj.assemblymeasurementtype || "");
        asmMsg.setImageclassificationid(asmObj.imageclassificationid || 0);
        asmMsg.setDatecreated(asmObj.datecreated || "");

        // Convert points
        const points = this.convertToEstimatePoints(asmObj.pointsList || []);
        asmMsg.setPointsList(points);

        // Build repeated EstimateItem for each item in `asmObj.estimateitemsList`
        if (asmObj.itemsList && asmObj.itemsList.length) {
          const itemMsgs = asmObj.itemsList.map((itemObj) => {
            const itemMsg = new EstimateAssemblyItem();
            itemMsg.setItemid(itemObj.itemid || "");
            itemMsg.setItemname(itemObj.itemname || "");
            itemMsg.setIsquoted(itemObj.isquoted || false);

            // Single `EstimateItemCost costs`
            if (itemObj.costs) {
              const costMsg = new EstimateItemCost();
              costMsg.setUomid(itemObj.costs.uomid || 0);
              costMsg.setItemunitcost(itemObj.costs.itemunitcost || 0);
              costMsg.setHourlyproductionrate(
                itemObj.costs.hourlyproductionrate || 0
              );
              costMsg.setUomname(itemObj.costs.uomname || "");
              costMsg.setUomvalue(itemObj.costs.uomvalue || 0);
              itemMsg.setCosts(costMsg);
            }

            // Single `EstimateItemQtyFormula itemQtyFormula`
            if (itemObj.itemqtyformula) {
              const formulaMsg = new EstimateItemQtyFormula();
              formulaMsg.setItemqty(itemObj.itemqtyformula.itemqty || 0);
              formulaMsg.setForevery(itemObj.itemqtyformula.forevery || 0);
              formulaMsg.setTakeoffvariabletype(
                itemObj.itemqtyformula.takeoffvariabletype || ""
              );
              formulaMsg.setAnd(itemObj.itemqtyformula.and || false);
              formulaMsg.setOr(itemObj.itemqtyformula.or || false);
              itemMsg.setItemqtyformula(formulaMsg);
            }

            // Single `EstimateAssemblyItemAttribute attribute`
            if (itemObj.attribute) {
              const attrMsg = new EstimateAssemblyItemAttribute();
              attrMsg.setAttributevalueid(
                itemObj.attribute.attributevalueid || 0
              );
              attrMsg.setAttributevaluename(
                itemObj.attribute.attributevaluename || ""
              );
              attrMsg.setAttributegroupid(
                itemObj.attribute.attributegroupid || 0
              );
              attrMsg.setAttributegroupname(
                itemObj.attribute.attributegroupname || ""
              );
              itemMsg.setAttribute(attrMsg);
            }

            // Single `EstimateBreakoutMap breakout`
            if (itemObj.breakout) {
              const boMsg = new EstimateBreakoutMap();
              boMsg.setBreakoutmapid(itemObj.breakout.breakoutmapid || "");
              boMsg.setBreakoutid(itemObj.breakout.breakoutid || "");
              boMsg.setBreakoutname(itemObj.breakout.breakoutname || "");
              boMsg.setMultiplier(itemObj.breakout.multiplier || 1);
              itemMsg.setBreakout(boMsg);
            }

            // Single `EstimateQuoteGroup quoteGroup`
            if (itemObj.quotegroup) {
              const qgMsg = new EstimateQuoteGroup();
              qgMsg.setId(itemObj.quotegroup.id || "");
              qgMsg.setQuotegroup(itemObj.quotegroup.quotegroup || "");
              itemMsg.setQuotegroup(qgMsg);
            }

            return itemMsg;
          });

          asmMsg.setItemsList(itemMsgs);
        }

        return asmMsg;
      });

      message.setAssembliesList(assemblyMessages);
    }

    return message;
  }

  public static getEstimateAssembliesByEstimateId = (
    estimateId: string
  ): Promise<GetEstimateAssembliesByEstimateIdResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new GetEstimateAssembliesByEstimateIdRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.estimateAssemblyServiceClient.getEstimateAssembliesByEstimateId(
        req,
        {},
        (err: any, response: GetEstimateAssembliesByEstimateIdResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static createProjectEstimateAssembly = (
    takeoff: EstimateTakeoffAssemblies.AsObject
  ): Promise<CreateEstimateAssemblyResponse.AsObject> => {
    // Convert front-end object => message
    const takeoffMsg = this.buildTakeoffAssembliesMessage(takeoff);

    const req = new CreateEstimateAssemblyRequest();
    req.setTakeoffassembly(takeoffMsg);
    req.setSessiontoken(this.getMetadata().sessionToken);

    return new Promise((resolve, reject) => {
      clients.estimateAssemblyServiceClient.createEstimateAssembly(
        req,
        {},
        (err, res) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(res.toObject());
        }
      );
    });
  };

  public static updateProjectEstimateAssembly = (
    takeoff: EstimateTakeoffAssemblies.AsObject
  ): Promise<UpdateEstimateAssemblyResponse.AsObject> => {
    const takeoffMsg = this.buildTakeoffAssembliesMessage(takeoff);

    const req = new UpdateEstimateAssemblyRequest();
    req.setTakeoffassembly(takeoffMsg);
    req.setSessiontoken(this.getMetadata().sessionToken);
    return new Promise((resolve, reject) => {
      clients.estimateAssemblyServiceClient.updateEstimateAssembly(
        req,
        {},
        (err, res) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(res.toObject());
        }
      );
    });
  };

  public static deleteEstimateAssembly = (
    assemblyId: string
  ): Promise<DeleteEstimateAssemblyResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new DeleteEstimateAssemblyRequest();
      req.setAssemblyid(assemblyId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.estimateAssemblyServiceClient.deleteEstimateAssembly(
        req,
        {},
        (err, res) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(res.toObject());
        }
      );
    });
  };

  public static deleteExtendEstimate = (
    assemblyId: string
  ): Promise<DeleteExtendEstimateResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new DeleteExtendEstimateRequest();
      req.setAssemblyid(assemblyId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.estimateAssemblyServiceClient.deleteExtendEstimate(
        req,
        {},
        (err, res) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(res.toObject());
        }
      );
    });
  };
}

/**
 * Example segmentCount calculation for the new shape
 * (still the same logic, but the relevant type changes).
 */
export const calculateSegmentCount = (assembly: TakeoffAssembly): number => {
  const pointsList = assembly.pointsList || [];

  if (pointsList.length < 2) {
    return 0;
  }

  let segmentCount = 0;

  for (let i = 1; i < pointsList.length; i++) {
    const pointTwo = pointsList[i];
    if (pointTwo.isincludedincount && pointTwo.allowtrailingline) {
      segmentCount++;
    }
  }

  if (segmentCount === 0) {
    if (pointsList.some((p) => p.allowtrailingline)) {
      segmentCount = 1;
    }
    return 0;
  }

  const lastPoint = last(pointsList);
  if (
    lastPoint &&
    lastPoint.allowtrailingline &&
    !lastPoint.isincludedincount
  ) {
    segmentCount++;
  }

  return segmentCount;
};
