import React, { useEffect, useState } from "react";
import { TotalPricingRowProps } from "./closeoutTypes";
import { formatCurrency, formatNumber } from "./closeoutHelpers";
import {
  useGetTotalFinalPriceChangeOrderCloseouts,
  useGetTotalFinalPriceCloseouts,
  useUpdateTotalFinalPriceChangeOrderCloseoutMutation,
  useUpdateTotalFinalPriceCloseoutMutation,
} from "../../hooks/useCloseoutHooks";
import { TableCell, TableRow, TextField } from "@mui/material";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";

const cloeoutNameMap = {
  extendestimate: "Extended Cost",
  directlaborcloseout: "Direct Labor",
  laborfactoringcloseout: "Labor Factoring",
  incidentallaborcloseout: "Incidental Labor",
  indirectlaborcloseout: "Indirect Labor",
  equipmentcloseout: "Equipment",
  generalexpensecloseout: "General Expense",
  subcontractcloseout: "Subcontracts",
  quotecloseout: "Quotes",
};

export const TotalPricingRow: React.FC<TotalPricingRowProps> = ({
  closeoutName,
  closeout,
  rowData,
}) => {
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  const { mutate: updateEstimateCloseout } =
    useUpdateTotalFinalPriceCloseoutMutation();
  const { isFetching: isFetchingEstimate, refetch: refetchEstimateCloseout } =
    useGetTotalFinalPriceCloseouts();

  const { mutate: updateChangeOrderCloseout } =
    useUpdateTotalFinalPriceChangeOrderCloseoutMutation();
  const { isFetching: isFetchingChangeOrder, refetch: refetchChangeOrder } =
    useGetTotalFinalPriceChangeOrderCloseouts();

  const updateCloseout = selectedEstimate?.estimateid
    ? updateEstimateCloseout
    : updateChangeOrderCloseout;

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimateCloseout
    : refetchChangeOrder;

  const isFetching = selectedEstimate?.estimateid
    ? isFetchingEstimate
    : isFetchingChangeOrder;

  // Calculate row cost from list
  const rowCost = closeout
    ? (closeout as any)[`${closeoutName}List`]?.reduce(
      (acc: number, item: any) => acc + (item.totalcost || 0),
      0
    ) || 0
    : 0;

  // Extract values
  const adjustmentValue = closeout?.adjustment ?? 0;
  const overheadValue = closeout?.overhead ?? 0;
  const marginValue = closeout?.margin ?? 0;

  // Local state for input fields
  const [adjustedCost, setAdjustedCost] = useState(
    formatCurrency(adjustmentValue)
  );
  const [overhead, setOverhead] = useState(formatCurrency(overheadValue));
  const [totalMarkup, setTotalMarkup] = useState(formatNumber(marginValue));

  // Track last known values to prevent unnecessary updates
  const [lastAdjustment, setLastAdjustment] = useState(adjustmentValue);
  const [lastOverhead, setLastOverhead] = useState(overheadValue);
  const [lastMargin, setLastMargin] = useState(marginValue);

  // Sync with props when external data updates
  useEffect(() => {
    setAdjustedCost(formatNumber(adjustmentValue));
    setOverhead(formatNumber(overheadValue));
    setTotalMarkup(formatNumber(marginValue));

    setLastAdjustment(adjustmentValue);
    setLastOverhead(overheadValue);
    setLastMargin(marginValue);
  }, [adjustmentValue, overheadValue, marginValue]);

  type Field = "adjustment" | "overhead" | "margin";

  // Handle user input
  const handleChange =
    (field: Field, setter: React.Dispatch<React.SetStateAction<string>>) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isFetching) return;
        const value = e.target.value;
        if (/^\d*\.?\d{0,2}$/.test(value) || value === "") {
          setter(value);
        }
      };

  // Optimistic update on blur, **only if the value changed**
  const handleBlur = async (field: Field, value: string) => {
    const numericValue = parseFloat(value) || 0;

    if (
      (field === "adjustment" && numericValue === lastAdjustment) ||
      (field === "overhead" && numericValue === lastOverhead) ||
      (field === "margin" && numericValue === lastMargin)
    ) {
      return; // **Avoid unnecessary updates if nothing changed**
    }

    // Update local state optimistically
    if (field === "adjustment") {
      setAdjustedCost(numericValue.toFixed(2));
      setLastAdjustment(numericValue);
    }
    if (field === "overhead") {
      setOverhead(numericValue.toFixed(2));
      setLastOverhead(numericValue);
    }
    if (field === "margin") {
      setTotalMarkup(numericValue.toFixed(2));
      setLastMargin(numericValue);
    }

    const updatedCloseout = {
      ...rowData,
      [closeoutName]: {
        ...(rowData[closeoutName] || {}),
        [field]: numericValue,
      },
    };

    updateCloseout(updatedCloseout, {
      onSuccess: () => {
        refetch();
      },
    });
  };

  return (
    <>
      <TableRow>
        <TableCell>{cloeoutNameMap[closeoutName]}</TableCell>
        <TableCell>{formatCurrency(rowCost)}</TableCell>
        <TableCell>
          <TextField
            type="text"
            value={adjustedCost}
            inputProps={{ min: 0, max: 99999999.99, step: "0.01" }}
            onChange={handleChange("adjustment", setAdjustedCost)}
            onBlur={(e) => handleBlur("adjustment", e.target.value)}
          />
        </TableCell>
        <TableCell>{formatCurrency(closeout?.adjustedcost || 0)}</TableCell>
        <TableCell>
          <TextField
            type="text"
            value={overhead}
            inputProps={{ min: 0, max: 99999999.99, step: "0.01" }}
            onChange={handleChange("overhead", setOverhead)}
            onBlur={(e) => handleBlur("overhead", e.target.value)}
          />
        </TableCell>
        <TableCell>{formatCurrency(closeout?.overheadcost || 0)}</TableCell>
        <TableCell>
          <TextField
            type="text"
            value={totalMarkup}
            inputProps={{ min: 0, max: 99999999.99, step: "0.01" }}
            onChange={handleChange("margin", setTotalMarkup)}
            onBlur={(e) => handleBlur("margin", e.target.value)}
          />
        </TableCell>
        <TableCell>{formatCurrency(closeout?.profitcost || 0)}</TableCell>
        <TableCell>{formatCurrency(closeout?.totalcost || 0)}</TableCell>
      </TableRow>
    </>
  );
};
