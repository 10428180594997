import React, { useState, useEffect, useCallback } from "react";
import { TableRow, TableCell, TextField, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DirectLaborCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import {
  useUpdateDirectLaborCloseoutMutation,
  useDeleteDirectLaborCloseoutMutation,
  useGetDirectLaborCloseouts,
  useDeleteDirectLaborChangeOrderCloseoutMutation,
  useGetDirectLaborChangeOrderCloseouts,
  useUpdateDirectLaborChangeOrderCloseoutMutation,
} from "../../hooks/useCloseoutHooks";
import { formatCurrency, formatNumber } from "./closeoutHelpers";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";

// sxSwitch remains unchanged, preserving visual cues based on value.
export const sxSwitch = (num: number) => {
  return {
    backgroundColor: num > 0 ? "lightyellow" : "lightgrey",
    border: "1px solid black",
    borderRadius: "5px",
  };
};

export type DirectLaborRowProps = {
  labor: DirectLaborCloseout.AsObject;
};

export const DirectLaborRow: React.FC<DirectLaborRowProps> = (props) => {
  const { labor } = props;
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  // Local state for the input displays.
  const [laborRateDisplay, setLaborRateDisplay] = useState<string>(
    formatCurrency(labor.laborrate)
  );
  const [distributionPercentDisplay, setDistributionPercentDisplay] =
    useState<string>(formatNumber(labor.distributionpercent));
  const [burdenPercentDisplay, setBurdenPercentDisplay] = useState<string>(
    formatNumber(labor.burdenpercent)
  );
  const [fringeDisplay, setFringeDisplay] = useState<string>(
    formatCurrency(labor.fringe)
  );

  const { mutateAsync: updateEstimateLabor } =
    useUpdateDirectLaborCloseoutMutation();
  const { mutateAsync: deleteEstimateLabor } =
    useDeleteDirectLaborCloseoutMutation();
  const { refetch: refetchEstimateLabor } = useGetDirectLaborCloseouts();

  const { mutateAsync: updateChangeOrderLabor } =
    useUpdateDirectLaborChangeOrderCloseoutMutation();
  const { mutateAsync: deleteChangeOrderLabor } =
    useDeleteDirectLaborChangeOrderCloseoutMutation();
  const { refetch: refetchChangeOrderLabor } =
    useGetDirectLaborChangeOrderCloseouts();

  const updateLabor = selectedEstimate?.estimateid
    ? updateEstimateLabor
    : updateChangeOrderLabor;

  const deleteLabor = selectedEstimate?.estimateid
    ? deleteEstimateLabor
    : deleteChangeOrderLabor;

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimateLabor
    : refetchChangeOrderLabor;

  // Sync state with incoming prop values.
  useEffect(() => {
    setLaborRateDisplay(formatCurrency(labor.laborrate));
    setDistributionPercentDisplay(formatNumber(labor.distributionpercent));
    setBurdenPercentDisplay(formatNumber(labor.burdenpercent));
    setFringeDisplay(formatCurrency(labor.fringe));
  }, [labor]);

  type Field = keyof DirectLaborCloseout.AsObject;

  // When the user leaves a field, validate and update the backend.
  const handleFieldChange = useCallback(
    async (field: Field, value: string) => {
      // Allow only numbers and up to 2 decimals.
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!regex.test(value) || Number(value) > 99999999.99) return;
      await updateLabor(
        {
          ...labor,
          [field]: value,
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    },
    [labor, updateLabor, refetch]
  );

  const handleDelete = useCallback(() => {
    deleteLabor(labor.closeoutid, {
      onSuccess: () => {
        refetch();
      },
    });
  }, [labor, deleteLabor, refetch]);

  return (
    <TableRow>
      {/* Description and Delete */}
      <TableCell>{labor.name}</TableCell>
      {/* Allocated Hours */}
      <TableCell>{formatNumber(labor.allocatedhours)}</TableCell>
      {/* Distribution % */}
      <TableCell>
        <TextField
          type="text"
          value={distributionPercentDisplay}
          onChange={(e) => setDistributionPercentDisplay(e.target.value)}
          onBlur={() =>
            handleFieldChange("distributionpercent", distributionPercentDisplay)
          }
          inputProps={{ "aria-label": "Distribution percent override" }}
          InputProps={{
            style: sxSwitch(labor.distributionpercent),
          }}
          variant="outlined"
          size="small"
        />
      </TableCell>
      {/* Labor Rate – using type "text" to preserve currency formatting */}
      <TableCell>
        <TextField
          type="text"
          value={laborRateDisplay}
          onChange={(e) => setLaborRateDisplay(e.target.value)}
          onBlur={() => handleFieldChange("laborrate", laborRateDisplay)}
          inputProps={{ "aria-label": "Labor rate override" }}
          InputProps={{ style: sxSwitch(labor.laborrate) }}
          variant="outlined"
          size="small"
        />
      </TableCell>
      {/* Subtotal */}
      <TableCell>{formatCurrency(labor.subtotalcost)}</TableCell>
      {/* Burden % */}
      <TableCell>
        <TextField
          type="text"
          value={burdenPercentDisplay}
          onChange={(e) => setBurdenPercentDisplay(e.target.value)}
          onBlur={() =>
            handleFieldChange("burdenpercent", burdenPercentDisplay)
          }
          inputProps={{ "aria-label": "Burden percent override" }}
          InputProps={{ style: sxSwitch(labor.burdenpercent) }}
          variant="outlined"
          size="small"
        />
      </TableCell>
      {/* Fringe – using type "text" to preserve currency formatting */}
      <TableCell>
        <TextField
          type="text"
          value={fringeDisplay}
          onChange={(e) => setFringeDisplay(e.target.value)}
          onBlur={() => handleFieldChange("fringe", fringeDisplay)}
          inputProps={{ "aria-label": "Fringe override" }}
          InputProps={{ style: sxSwitch(labor.fringe) }}
          variant="outlined"
          size="small"
        />
      </TableCell>
      {/* Burden Total */}
      <TableCell>{formatCurrency(labor.burdentotalcost)}</TableCell>
      {/* Fringe Total */}
      <TableCell>{formatCurrency(labor.fringetotalcost)}</TableCell>
      {/* Full Hourly Cost */}
      <TableCell>{formatCurrency(labor.totalhourlycost)}</TableCell>
      {/* Full Cost */}
      <TableCell>{formatCurrency(labor.totalcost)}</TableCell>
      <IconButton onClick={handleDelete} size="small">
        <DeleteIcon />
      </IconButton>
    </TableRow>
  );
};

export default DirectLaborRow;
