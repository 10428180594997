import { useEffect, useState, useCallback, useMemo } from "react";
import {
  useGetTotalFinalPriceChangeOrderCloseouts,
  useGetTotalFinalPriceCloseouts,
  useUpdateTotalFinalPriceChangeOrderCloseoutMutation,
  useUpdateTotalFinalPriceCloseoutMutation,
} from "../../hooks/useCloseoutHooks";
import { TotalPricingRow } from "./TotalPricingRow";
import { PricingRowListName } from "./closeoutTypes";
import { TotalFinalPriceCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatCurrency } from "./closeoutHelpers";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { TotalFinalPriceChangeOrderCloseout } from "../../api/protosCompiled/changeOrderCloseout/changeOrderCloseout_pb";
import { SellingPriceInput } from "./SellingPriceInput";

type TotalFinalCloseout =
  | TotalFinalPriceCloseout.AsObject
  | TotalFinalPriceChangeOrderCloseout.AsObject;

export const TotalPricingHeader = () => {
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;

  const { data: estimateTotalFinalPrice, refetch: refetchEstimateCloseout } =
    useGetTotalFinalPriceCloseouts();
  const { mutate: updateEstimateCloseout } =
    useUpdateTotalFinalPriceCloseoutMutation();

  const { data: changeOrderTotalFinalPrice, refetch: refetchChangeOrder } =
    useGetTotalFinalPriceChangeOrderCloseouts();
  const { mutate: updateChangeOrderCloseout } =
    useUpdateTotalFinalPriceChangeOrderCloseoutMutation();

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimateCloseout
    : refetchChangeOrder;

  const updateCloseout = selectedEstimate?.estimateid
    ? updateEstimateCloseout
    : updateChangeOrderCloseout;

  const totalFinalPrice = selectedEstimate?.estimateid
    ? estimateTotalFinalPrice
    : changeOrderTotalFinalPrice;

  // Ensure totalFinalPriceData is defined
  const totalFinalPriceData = useMemo<TotalFinalCloseout>(() => {
    return totalFinalPrice?.closeouts ?? ({} as TotalFinalCloseout);
  }, [totalFinalPrice]);

  // Function to extract the correct closeout object from the row data.
  const getCloseoutObject = (
    rowData: TotalFinalCloseout,
    closeoutName: PricingRowListName
  ) => {
    switch (closeoutName) {
      case "extendestimate":
        return rowData.extendestimate;
      case "directlaborcloseout":
        return rowData.directlaborcloseout;
      case "laborfactoringcloseout":
        return rowData.laborfactoringcloseout;
      case "incidentallaborcloseout":
        return rowData.incidentallaborcloseout;
      case "indirectlaborcloseout":
        return rowData.indirectlaborcloseout;
      case "equipmentcloseout":
        return rowData.equipmentcloseout;
      case "generalexpensecloseout":
        return rowData.generalexpensecloseout;
      case "subcontractcloseout":
        return rowData.subcontractcloseout;
      case "quotecloseout":
        return rowData.quotecloseout;
      default:
        return null;
    }
  };

  const [sellingPrice, setSellingPrice] = useState<string>(
    formatCurrency(totalFinalPriceData?.sellingprice || 0)
  );
  const [lastSellingPrice, setLastSellingPrice] = useState<string>(
    formatCurrency(totalFinalPriceData?.sellingprice || 0)
  );

  useEffect(() => {
    if (totalFinalPrice) {
      setSellingPrice(formatCurrency(totalFinalPriceData?.sellingprice || 0));
      setLastSellingPrice(
        formatCurrency(totalFinalPriceData?.sellingprice || 0)
      );
    }
  }, [totalFinalPriceData, totalFinalPrice]);

  // Define Field as the allowed key from the TotalFinalPriceCloseout.AsObject.
  type Field = keyof Pick<TotalFinalPriceCloseout.AsObject, "sellingprice">;
  // This is equivalent to: "sellingprice"

  // Change handler: update local state.
  const handleChange = useCallback(
    (field: Field, setter: React.Dispatch<React.SetStateAction<string>>) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Allow only numbers with up to 2 decimals (or empty string)
        if (/^\d*\.?\d{0,2}$/.test(value) || value === "") {
          setter(value);
        }
      },
    []
  );

  // Helper: round a string number to 2 decimals.
  const roundToTwoDecimals = (value: string): string => {
    const num = parseFloat(value);
    return isNaN(num) ? value : num.toFixed(2);
  };

  // Blur handler: if changed, update the backend.
  const handleBlur = useCallback(
    async (field: Field, value: string) => {
      const fixed = roundToTwoDecimals(value);
      setSellingPrice(fixed);
      if (fixed === lastSellingPrice) {
        return; // No change, avoid unnecessary update.
      }
      setLastSellingPrice(fixed);

      // Construct an updated closeout object.
      // Note: This object must conform to TotalFinalPriceCloseout.AsObject.
      const updatedCloseout: TotalFinalPriceCloseout.AsObject = {
        ...totalFinalPriceData,
        sellingprice: parseFloat(fixed),
      };
      updateCloseout(updatedCloseout, {
        onSuccess: () => {
          refetch();
        },
      });
    },
    [lastSellingPrice, totalFinalPriceData, updateCloseout, refetch]
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ flex: 1, overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          sx={{
            height: "100%",
            overflowY: "auto",
            border: "1px solid black",
          }}
        >
          <Table stickyHeader sx={{ width: "100%", tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell>Cost Description</TableCell>
                <TableCell>$ Cost</TableCell>
                <TableCell>% Tax / Adjust</TableCell>
                <TableCell>$ Tax / Adjust</TableCell>
                <TableCell>% Overhead</TableCell>
                <TableCell>$ Overhead</TableCell>
                <TableCell>% Profit</TableCell>
                <TableCell>$ Profit</TableCell>
                <TableCell>Total Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(
                [
                  "extendestimate",
                  "directlaborcloseout",
                  "laborfactoringcloseout",
                  "incidentallaborcloseout",
                  "indirectlaborcloseout",
                  "equipmentcloseout",
                  "generalexpensecloseout",
                  "subcontractcloseout",
                  "quotecloseout",
                ] as PricingRowListName[]
              ).map((closeoutName) => {
                const closeoutObject = getCloseoutObject(
                  totalFinalPriceData,
                  closeoutName
                );
                if (!closeoutObject) return null;

                return (
                  <TotalPricingRow
                    key={closeoutName}
                    closeoutName={closeoutName}
                    closeout={closeoutObject}
                    rowData={totalFinalPriceData}
                  />
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={8}>
                  <b>Total Cost + Tax/Adjust</b>
                </TableCell>
                <TableCell>
                  <strong>
                    {formatCurrency(totalFinalPriceData.totalcost)}
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={8}>
                  <b>Total Overhead</b>
                </TableCell>
                <TableCell>
                  <strong>
                    {formatCurrency(totalFinalPriceData.totaloverhead)}
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={8}>
                  <b>Total Profit</b>
                </TableCell>
                <TableCell>
                  <strong>
                    {formatCurrency(totalFinalPriceData.totalprofit)}
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={8}>
                  <b>Total Estimate</b>
                </TableCell>
                <TableCell>
                  <strong>
                    {formatCurrency(totalFinalPriceData.totalfinalprice)}
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={8}>
                  <b>Total Selling Price</b>
                </TableCell>
                <TableCell>
                  <SellingPriceInput
                    sellingPrice={sellingPrice}
                    setSellingPrice={setSellingPrice}
                    lastSellingPrice={lastSellingPrice}
                    setLastSellingPrice={setLastSellingPrice}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TotalPricingHeader;
