import { useEffect, useRef } from "react";
import { CompactPicker } from "react-color";
import { useUnityBuildStore } from "../../states/store";
import { useSubmitTakeoffAssembly } from "../../hooks/useSubmitTakeoffAssembly";
import { mutateSelectedAssembly } from "../../api/MutateSelectedAssembly";
import { useStore } from "zustand";
import { useAssemblyStore } from "../../states/AssemblyStore";

export const ColorPicker = () => {

  const isEditingPointFillColor = useStore(useUnityBuildStore).isEditingPointFillColor;
  const isEditingPointBorderColor = useStore(useUnityBuildStore).isEditingPointBorderColor;
  const isEditingLineColor = useStore(useUnityBuildStore).isEditingLineColor;
  const backgroundColor = useStore(useUnityBuildStore).backgroundColor;
  const userScale = useStore(useUnityBuildStore).userScale;
  const setBackgroundColor = useStore(useUnityBuildStore).setBackgroundColor;
  const currentTakeoffAssembly = useStore(useAssemblyStore).currentTakeoffAssembly;

  const submitAssembly = useSubmitTakeoffAssembly();

  const currentAssemblyRef = useRef(currentTakeoffAssembly);

  useEffect(() => {
    currentAssemblyRef.current = currentTakeoffAssembly;
  }, [currentTakeoffAssembly]);

  useEffect(() => {
    if (isEditingPointFillColor) {
      setBackgroundColor(
        currentAssemblyRef.current?.pointfillcolor || "#BD10E0"
      );
    } else if (isEditingPointBorderColor) {
      setBackgroundColor(
        currentAssemblyRef.current?.pointbordercolor || "#BD10E0"
      );
    } else if (isEditingLineColor) {
      setBackgroundColor(currentAssemblyRef.current?.linecolor || "#BD10E0");
    }
  }, [
    setBackgroundColor,
    isEditingPointBorderColor,
    isEditingPointFillColor,
    isEditingLineColor,
  ]);

  const handleOnChangeComplete = async (color: any) => {
    if (!currentAssemblyRef.current) {
      console.error("No assembly selected");
      return;
    }
    setBackgroundColor(color.hex);

    const updatedAssembly = mutateSelectedAssembly({
      assembly: currentAssemblyRef.current,
      color: color.hex,
      isEditingPointBorderColor,
      isEditingPointFillColor,
      isEditingLineColor,
    });
    try {
      await submitAssembly({
        shouldUpdateBreakout: false,
        assembly: updatedAssembly,
        isUpdating: true,
        pageScale: userScale,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CompactPicker
      color={backgroundColor}
      onChangeComplete={handleOnChangeComplete}
      height={200}
    />
  );
};
