import React, { useState, useRef, useLayoutEffect, useCallback, useEffect, useMemo } from "react";
import { Box, List, ListItem, TextField, Paper, CircularProgress } from "@mui/material";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../states/store";
import { useListMessagesOnThread } from "../hooks/useListMessagesOnThread";
import { useFetchUser } from "../hooks/useFetchUsers";
import ReactMarkdown from "react-markdown";
import chatBotImage from "../images/ChatBot.png";
import { AssistantService } from "../api/AssistantService";

type Who = "user" | "assistant";
type ChatMessage = {
  who: Who;
  text: string;
  imageUrl?: string;
};

export function ChatWindow() {
  const selectedProject = useStore(useUnityBuildStore).selectedProject;
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [userInput, setUserInput] = useState("");
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // Fetch previous messages for the thread.
  const { data: messageData, isLoading } = useListMessagesOnThread(selectedProject.id);
  const { data: user } = useFetchUser();

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    if (!messageData) return;
    const fetchMessages = async () => {
      try {
        const mappedMessages: ChatMessage[] = messageData.map((message: any) => {
          const who = message.role === "assistant" ? "assistant" : "user";
          let text = "";
          if (message.contentList && message.contentList.length > 0) {
            const content = message.contentList[0];
            if (content.type === "text" && content.text?.value) {
              text = content.text.value;
            }
          }
          const imageUrl = who === "assistant" ? chatBotImage : user?.user?.userphotourl;
          return { who, text, imageUrl };
        });
        setMessages(mappedMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    if (selectedProject.id) {
      fetchMessages();
    }
  }, [selectedProject.id, messageData, user]);

  // Append new messages to the conversation.
  const appendMessage = useCallback(
    (who: Who, text: string, imageUrl?: string) => {
      setMessages(prev => {
        // If the last message is from the same sender, concatenate the text.
        if (prev.length && prev[prev.length - 1].who === who) {
          return [
            ...prev.slice(0, prev.length - 1),
            { ...prev[prev.length - 1], text: prev[prev.length - 1].text + text }
          ];
        }
        return [...prev, { who, text, imageUrl }];
      });
    },
    []
  );

  async function sendMessage(input: string) {
    if (input.trim() !== "") {
      appendMessage("user", input.trim(), user?.user?.userphotourl);
      setUserInput("");
      try {
        await AssistantService.streamMessage({
          usermessage: input.trim(),
          projectid: selectedProject.id,
          onMessage: (chunk: string) => {
            appendMessage("assistant", chunk, chatBotImage);
          },
        });
      } catch (error) {
        console.error("Error sending message:", error);
      }
      
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (userInput.trim() !== "") {
        sendMessage(userInput.trim());
      }
    }
  };

  // Custom component to render links in new tab.
  const markdownComponents = {
    a: ({ node, ...props }: any) => (
      <a {...props} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    ),
  };

  const renderedMessages = useMemo(() => {
    return messages.map((msg, index) => (
      <ListItem key={index}>
        <Paper
          elevation={3}
          sx={{
            backgroundColor: msg.who === "user" ? "#e3f2fd" : "#fffde7",
            p: 1,
            borderRadius: 2,
            maxWidth: "80%",
            wordWrap: "break-word",
            marginLeft: msg.who === "user" ? "auto" : 0,
          }}
        >
          <ReactMarkdown components={markdownComponents}>{msg.text}</ReactMarkdown>
        </Paper>
      </ListItem>
    ));
  }, [messages]);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "1000px",
        overflow: "auto",
        position: "absolute",
        bottom: 0,
        minWidth: "400px",
        resize: "horizontal",
      }}
      elevation={0}
    >
      <Box sx={{ flexGrow: 1, overflowY: "auto", p: 2 }}>
        {isLoading ? <CircularProgress /> : <List>{renderedMessages}</List>}
        <div ref={messagesEndRef} />
      </Box>
      <Box sx={{ borderTop: 1, borderColor: "divider", p: 1 }}>
        <TextField
          multiline
          rows={4}
          placeholder="Type a message..."
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={handleKeyDown}
          variant="outlined"
          fullWidth
        />
      </Box>
    </Paper>
  );
}

export default ChatWindow;
