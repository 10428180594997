import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps, CustomTabPanel } from "../../customTabs/CustomTabPanel";
import { Allocation } from "./Allocation";
import { useStore } from "zustand";
import { useGetTotalFinalPriceCloseouts, useGetTotalFinalPriceChangeOrderCloseouts, useUpdateEstimateLaborFactoringAllocation, useUpdateChangeOrderLaborFactoringAllocation, useUpdateChangeOrderEquipmentAllocation, useUpdateChangeOrderGeneralExpenseAllocation, useUpdateChangeOrderIncidentalLaborAllocation, useUpdateChangeOrderIndirectLaborAllocation, useUpdateChangeOrderQuoteAllocation, useUpdateChangeOrderSubcontractAllocation, useUpdateEstimateEquipmentAllocation, useUpdateEstimateGeneralExpenseAllocation, useUpdateEstimateIncidentalLaborAllocation, useUpdateEstimateIndirectLaborAllocation, useUpdateEstimateQuoteAllocation, useUpdateEstimateSubcontractAllocation } from "../../hooks/useCloseoutHooks";
import { useUnityBuildStore } from "../../states/store";
import { useFetchEstimateBreakouts, useFetchChangeOrderBreakouts } from "../../hooks/useFetchBreakout";
import { EquipmentCloseout, GeneralExpenseCloseout, IncidentalLaborCloseout, IndirectLaborCloseout, LaborFactoringCloseout, QuoteCloseout, SubcontractCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";


export type AllocationList =
    LaborFactoringCloseout.AsObject |
    IncidentalLaborCloseout.AsObject |
    IndirectLaborCloseout.AsObject |
    EquipmentCloseout.AsObject |
    GeneralExpenseCloseout.AsObject |
    SubcontractCloseout.AsObject |
    QuoteCloseout.AsObject;


export function AllocationTab() {
    //local state
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
    const selectedChangeOrder = useStore(useUnityBuildStore).selectedChangeOrder;

    const { data: estimatecloseouts } = useGetTotalFinalPriceCloseouts();
    const { data: changeOrdercloseouts } = useGetTotalFinalPriceChangeOrderCloseouts();

    const closeouts = selectedEstimate?.estimateid ? estimatecloseouts : changeOrdercloseouts;

    const { data: estimateBreakouts } = useFetchEstimateBreakouts(selectedEstimate?.estimateid ?? "");
    const { data: changeOrderBreakouts } = useFetchChangeOrderBreakouts(selectedChangeOrder?.changeorderid ?? "");

    const breakouts = selectedEstimate?.estimateid ? estimateBreakouts : changeOrderBreakouts;

    React.useEffect(() => {
        console.log("closeouts", closeouts);
    }, [closeouts]);

    const { mutate: updateEstimateLaborFactoringAllocation } = useUpdateEstimateLaborFactoringAllocation();
    const { mutate: updateChangeOrderLaborFactoringAllocation } = useUpdateChangeOrderLaborFactoringAllocation();

    const updateLaborFactoringAllocation = selectedEstimate?.estimateid ? updateEstimateLaborFactoringAllocation : updateChangeOrderLaborFactoringAllocation

    const { mutate: updateEstimateIncidentalLaborAllocation } = useUpdateEstimateIncidentalLaborAllocation();
    const { mutate: updateChangeOrderIncidentalLaborAllocation } = useUpdateChangeOrderIncidentalLaborAllocation();

    const updateIncidentalLaborAllocation = selectedEstimate?.estimateid ? updateEstimateIncidentalLaborAllocation : updateChangeOrderIncidentalLaborAllocation

    const { mutate: updateEstimateIndirectLaborAllocation } = useUpdateEstimateIndirectLaborAllocation();
    const { mutate: updateChangeOrderIndirectLaborAllocation } = useUpdateChangeOrderIndirectLaborAllocation();

    const updateIndirectLaborAllocation = selectedEstimate?.estimateid ? updateEstimateIndirectLaborAllocation : updateChangeOrderIndirectLaborAllocation

    const { mutate: updateEstimateEquipmentAllocation } = useUpdateEstimateEquipmentAllocation();
    const { mutate: updateChangeOrderEquipmentAllocation } = useUpdateChangeOrderEquipmentAllocation();

    const updateEquipmentAllocation = selectedEstimate?.estimateid ? updateEstimateEquipmentAllocation : updateChangeOrderEquipmentAllocation

    const { mutate: updateEstimateGeneralExpenseAllocation } = useUpdateEstimateGeneralExpenseAllocation();
    const { mutate: updateChangeOrderGeneralExpenseAllocation } = useUpdateChangeOrderGeneralExpenseAllocation();

    const updateGeneralExpenseAllocation = selectedEstimate?.estimateid ? updateEstimateGeneralExpenseAllocation : updateChangeOrderGeneralExpenseAllocation

    const { mutate: updateEstimateSubcontractAllocation } = useUpdateEstimateSubcontractAllocation();
    const { mutate: updateChangeOrderSubcontractAllocation } = useUpdateChangeOrderSubcontractAllocation();

    const updateSubcontractAllocation = selectedEstimate?.estimateid ? updateEstimateSubcontractAllocation : updateChangeOrderSubcontractAllocation

    const { mutate: updateEstimateQuoteAllocation } = useUpdateEstimateQuoteAllocation();
    const { mutate: updateChangeOrderQuoteAllocation } = useUpdateChangeOrderQuoteAllocation();

    const updateQuoteAllocation = selectedEstimate?.estimateid ? updateEstimateQuoteAllocation : updateChangeOrderQuoteAllocation

    return (
        <>
            <div style={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Labor Factoring" {...a11yProps(0)} disabled={closeouts?.closeouts?.laborfactoringcloseout?.laborfactoringcloseoutList.length === 0} />
                    <Tab label="Incidental Labor" {...a11yProps(1)} disabled={closeouts?.closeouts?.incidentallaborcloseout?.incidentallaborcloseoutList.length === 0} />
                    <Tab label="Indirect Labor" {...a11yProps(2)} disabled={closeouts?.closeouts?.indirectlaborcloseout?.indirectlaborcloseoutList.length === 0} />
                    <Tab label="Equipment" {...a11yProps(3)} disabled={closeouts?.closeouts?.equipmentcloseout?.equipmentcloseoutList.length === 0} />
                    <Tab label="General Expenses" {...a11yProps(4)} disabled={closeouts?.closeouts?.generalexpensecloseout?.generalexpensecloseoutList.length === 0} />
                    <Tab label=" Subcontracts" {...a11yProps(5)} disabled={closeouts?.closeouts?.subcontractcloseout?.subcontractcloseoutList.length === 0} />
                    <Tab label="Quotes" {...a11yProps(6)} disabled={closeouts?.closeouts?.quotecloseout?.quotecloseoutList.length === 0} />
                </Tabs>
            </div>
            <CustomTabPanel value={value} index={0}>
                <Allocation
                    allocations={closeouts?.closeouts?.laborfactoringcloseout?.laborfactoringcloseoutList[0]?.allocationList ?? []}
                    breakoutList={breakouts?.breakoutsList ?? []}
                    costToAllocate={closeouts?.closeouts?.laborfactoringcloseout?.totalcost ?? 0}
                    updateAllocation={updateLaborFactoringAllocation}
                    closeoutId={closeouts?.closeouts?.laborfactoringcloseout?.laborfactoringcloseoutList[0]?.closeoutid ?? ""}

                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Allocation
                    allocations={closeouts?.closeouts?.incidentallaborcloseout?.incidentallaborcloseoutList[0]?.allocationList ?? []}
                    breakoutList={breakouts?.breakoutsList ?? []}
                    costToAllocate={closeouts?.closeouts?.incidentallaborcloseout?.totalcost ?? 0}
                    updateAllocation={updateIncidentalLaborAllocation}
                    closeoutId={closeouts?.closeouts?.incidentallaborcloseout?.incidentallaborcloseoutList[0]?.closeoutid ?? ""}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Allocation
                    allocations={closeouts?.closeouts?.indirectlaborcloseout?.indirectlaborcloseoutList[0]?.allocationList ?? []}
                    breakoutList={breakouts?.breakoutsList ?? []}
                    costToAllocate={closeouts?.closeouts?.indirectlaborcloseout?.totalcost ?? 0}
                    updateAllocation={updateIndirectLaborAllocation}
                    closeoutId={closeouts?.closeouts?.indirectlaborcloseout?.indirectlaborcloseoutList[0]?.closeoutid ?? ""}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <Allocation
                    allocations={closeouts?.closeouts?.equipmentcloseout?.equipmentcloseoutList[0]?.allocationList ?? []}
                    breakoutList={breakouts?.breakoutsList ?? []}
                    costToAllocate={closeouts?.closeouts?.equipmentcloseout?.totalcost ?? 0}
                    updateAllocation={updateEquipmentAllocation}
                    closeoutId={closeouts?.closeouts?.equipmentcloseout?.equipmentcloseoutList[0]?.closeoutid ?? ""}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                <Allocation
                    allocations={closeouts?.closeouts?.generalexpensecloseout?.generalexpensecloseoutList[0]?.allocationList ?? []}
                    breakoutList={breakouts?.breakoutsList ?? []}
                    costToAllocate={closeouts?.closeouts?.generalexpensecloseout?.totalcost ?? 0}
                    updateAllocation={updateGeneralExpenseAllocation}
                    closeoutId={closeouts?.closeouts?.generalexpensecloseout?.generalexpensecloseoutList[0]?.closeoutid ?? ""}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
                <Allocation
                    allocations={closeouts?.closeouts?.subcontractcloseout?.subcontractcloseoutList[0]?.allocationList ?? []}
                    breakoutList={breakouts?.breakoutsList ?? []}
                    costToAllocate={closeouts?.closeouts?.subcontractcloseout?.totalcost ?? 0}
                    updateAllocation={updateSubcontractAllocation}
                    closeoutId={closeouts?.closeouts?.subcontractcloseout?.subcontractcloseoutList[0]?.closeoutid ?? ""}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6} >
                <Allocation
                    allocations={closeouts?.closeouts?.quotecloseout?.quotecloseoutList[0]?.allocationList ?? []}
                    breakoutList={breakouts?.breakoutsList ?? []}
                    costToAllocate={closeouts?.closeouts?.quotecloseout?.totalcost ?? 0}
                    updateAllocation={updateQuoteAllocation}
                    closeoutId={closeouts?.closeouts?.quotecloseout?.quotecloseoutList[0]?.closeoutid ?? ""}
                />
            </CustomTabPanel>
        </>
    );
}
