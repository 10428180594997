import { useMutation, useQuery, useQueryClient } from "react-query";
import { GlobalAssemblyService as gas } from "../api/GlobalAssemblyService";
import { GlobalAssembly } from "../api/protosCompiled/globalAssembly/globalAssembly_pb";

export function useFetchAssembliesByFileId(fileId: string) {
  const queryClient = useQueryClient();

  const fetchAssemblies = async () => {
    const res = await gas.getAssembliesByFileId(fileId);
    return res.globalassembliesList || [];
  };

  const { data, error, isLoading } = useQuery<GlobalAssembly.AsObject[], Error>(
    ["assembliesByFileId", fileId],
    fetchAssemblies,

    {
      enabled: !!fileId,
    }
  );

  const refetchAssemblies = () => {
    queryClient.invalidateQueries(["assembliesByFileId", fileId]);
  };

  // Return the query data, refetch function, and the fetching function to call elsewhere
  return { data, error, isLoading, refetchAssemblies, fetchAssemblies };
}

export function useCreateGlobalAssemblyMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: GlobalAssembly.AsObject) => {
      console.log("creating global assembly", data);
      const response = await gas.createGlobalAssembly(data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["assembliesByFileId"]);
        queryClient.invalidateQueries("assemblyFolders");
      },
      onError: (error) => {
        console.error("Error creating or updating assembly:", error);
      },
    }
  );
}

export function useUpdateGlobalAssemblyMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: GlobalAssembly.AsObject) => gas.updateGlobalAssembly(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["assembliesByFileId"]);
        queryClient.invalidateQueries("assemblyFolders");
      },
      onError: (error) => {
        console.error("Error creating or updating assembly:", error);
      },
    }
  );
}

export function useDeleteGlobalAssemblyMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: GlobalAssembly.AsObject) =>
      gas.deleteGlobalAssembly(data.assemblyid),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["assembliesByFileId"]);
        queryClient.invalidateQueries("assemblyFolders");
      },
      onError: (error) => {
        console.error("Error deleting assembly:", error);
      },
    }
  );
}
