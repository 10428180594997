import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { IncidentalLaborRowProps } from "./closeoutTypes";
import { IncidentalLaborCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import {
  useDeleteIncidentalLaborChangeOrderCloseoutMutation,
  useDeleteIncidentalLaborCloseoutMutation,
  useGetIncidentalLaborChangeOrderCloseouts,
  useGetIncidentalLaborCloseouts,
  useUpdateIncidentalLaborChangeOrderCloseoutMutation,
  useUpdateIncidentalLaborCloseoutMutation,
} from "../../hooks/useCloseoutHooks";
import { formatCurrency, formatNumber } from "./closeoutHelpers";
import { Button, TableCell, TableRow, TextField } from "@mui/material";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";

export const IncidentalLaborRow: React.FC<IncidentalLaborRowProps> = ({
  closeout,
}) => {
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  // Local state for TextField displays.
  const [laborRateDisplay, setLaborRateDisplay] = useState<string>(
    formatCurrency(closeout.laborrate)
  );
  const [burdenPercentDisplay, setBurdenPercentDisplay] = useState<string>(
    formatNumber(closeout.burdenpercent)
  );
  const [fringeDisplay, setFringeDisplay] = useState<string>(
    formatCurrency(closeout.fringe)
  );

  const [allocatedhours, setAllocatedHours] = useState<string>(
    closeout.allocatedhours.toFixed(2)
  );

  const { mutateAsync: updateEstimateCloseout } =
    useUpdateIncidentalLaborCloseoutMutation();
  const { mutateAsync: deleteEstimateCloseout } =
    useDeleteIncidentalLaborCloseoutMutation();
  const { refetch: refetchEstimate } = useGetIncidentalLaborCloseouts();

  const { mutateAsync: updateChangeOrderCloseout } =
    useUpdateIncidentalLaborChangeOrderCloseoutMutation();
  const { mutateAsync: deleteChangeOrderCloseout } =
    useDeleteIncidentalLaborChangeOrderCloseoutMutation();
  const { refetch: refetchChangeOrder } =
    useGetIncidentalLaborChangeOrderCloseouts();

  const updateCloseout = selectedEstimate?.estimateid
    ? updateEstimateCloseout
    : updateChangeOrderCloseout;

  const deleteCloseout = selectedEstimate?.estimateid
    ? deleteEstimateCloseout
    : deleteChangeOrderCloseout;

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimate
    : refetchChangeOrder;

  // Sync local state when the labor prop changes.
  useEffect(() => {
    setAllocatedHours(formatNumber(closeout.allocatedhours));
    setLaborRateDisplay(formatCurrency(closeout.laborrate));
    setBurdenPercentDisplay(formatNumber(closeout.burdenpercent));
    setFringeDisplay(formatCurrency(closeout.fringe));
  }, [closeout]);

  type Field = keyof Pick<
    IncidentalLaborCloseout.AsObject,
    "laborrate" | "burdenpercent" | "fringe" | "allocatedhours"
  >;

  const handleFieldChange = useCallback(
    async (field: Field, value: string) => {
      // Allow only numbers with up to 2 decimals.
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!regex.test(value) || Number(value) > 99999999.99) return;
      await updateCloseout(
        {
          ...closeout,
          [field]: value,
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    },
    [closeout, updateCloseout, refetch]
  );

  const handleDelete = useCallback(() => {
    deleteCloseout(closeout.closeoutid, {
      onSuccess: () => {
        refetch();
      },
    });
  }, [closeout, deleteCloseout, refetch]);

  return (
    <TableRow>
      {/* Description */}
      <TableCell>{closeout.name}</TableCell>
      {/* Allocated Hours */}
      <TableCell>
        <TextField
          type="text"
          value={allocatedhours}
          onChange={(e) => setAllocatedHours(e.target.value)}
          onBlur={(e) => handleFieldChange("allocatedhours", e.target.value)}
        />
      </TableCell>
      {/* Labor Rate */}
      <TableCell>
        <TextField
          type="text"
          value={laborRateDisplay}
          onChange={(e) => setLaborRateDisplay(e.target.value)}
          onBlur={(e) => handleFieldChange("laborrate", e.target.value)}
        />
      </TableCell>
      {/* Subtotal */}
      <TableCell>{formatCurrency(closeout.subtotalcost)}</TableCell>
      {/* Burden Percent */}
      <TableCell>
        <TextField
          type="text"
          value={burdenPercentDisplay}
          onChange={(e) => setBurdenPercentDisplay(e.target.value)}
          onBlur={(e) => handleFieldChange("burdenpercent", e.target.value)}
        />
      </TableCell>
      {/* Fringe */}
      <TableCell>
        <TextField
          type="text"
          value={fringeDisplay}
          onChange={(e) => setFringeDisplay(e.target.value)}
          onBlur={(e) => handleFieldChange("fringe", e.target.value)}
        />
      </TableCell>
      {/* Burden Total */}
      <TableCell>{formatCurrency(closeout.burdentotalcost)}</TableCell>
      {/* Fringe Total */}
      <TableCell>{formatCurrency(closeout.fringetotalcost)}</TableCell>
      {/* Full Hourly Cost */}
      <TableCell>{formatCurrency(closeout.totalhourlycost)}</TableCell>
      {/* Full Cost */}
      <TableCell>{formatCurrency(closeout.totalcost)}</TableCell>
      <Button onClick={handleDelete} style={{ width: "40px" }}>
        <DeleteIcon />
      </Button>
    </TableRow>
  );
};
