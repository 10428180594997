// types/FolderTypes.ts

import {
  Folder,
  GetFoldersResponse,
} from "../api/protosCompiled/folder/folder_pb";
import { GlobalAssembly } from "../api/protosCompiled/globalAssembly/globalAssembly_pb";
import {
  AttributeValue,
  GlobalItem,
} from "../api/protosCompiled/globalItem/globalItem_pb";
import { TakeoffAssembly } from "./AssemblyItemType";

/**
 * The entitytype is the type of the folder, e.g., "project", "assembly", "item"
 * We use this in our query to the database to retrieve the correct folder data for the given context
 */
export type Foldertype = "project" | "assembly" | "item";

export type FolderFormParams = {
  e: React.SyntheticEvent;
  isCreating: boolean;
  isViewing: boolean;
  folder: FolderNode;
};

/**
 * Primary allows full control, secondary provides folders only.
 */
export type FolderContext = "primary" | "secondary";

export interface CollapsibleRowProps {
  context: FolderContext;
  folderType: Foldertype;
  folderData: GetFoldersResponse.AsObject;
}

interface TableProps {
  context: FolderContext;
  folder: Folder.AsObject;
}

export interface ItemTableProps extends TableProps {
  onAddItem?: (
    item: GlobalItem.AsObject,
    attribute: AttributeValue.AsObject
  ) => void;
}

export interface AssemblyTableProps extends TableProps {
  assembly?: GlobalAssembly.AsObject;
  onClose?: () => void;
}

export type AssemblyOptions = "global" | "takeoff";

export interface CreateAssemblyProps {
  assemblyOption: AssemblyOptions;
  currentAssembly?: GlobalAssembly.AsObject | TakeoffAssembly;
  // currentTakeoffAssembly?: TakeoffAssembly;
}

export interface FolderNode extends Folder.AsObject {
  children: FolderNode[]; // Children property to hold nested folders
}

export type MenuOptions =
  | "Rename"
  | "Delete Item"
  | "Delete Assembly"
  | "Delete Folder"
  | "Move"
  | "Create Folder"
  | "Create Assembly"
  | "Create Project"
  | "Edit Assembly"
  | "UOM"
  | "Attribute Group";

export const defaultFolder: FolderNode = {
  folderid: "",
  name: "",
  parentid: "",
  isfile: false,
  level: 0,
  entitytype: "",
  isactive: false,
  lastupdated: "",
  children: [],
};
