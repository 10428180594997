// source: globalAssembly.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.CreateGlobalAssemblyRequest', null, global);
goog.exportSymbol('proto.CreateGlobalAssemblyResponse', null, global);
goog.exportSymbol('proto.DeleteGlobalAssemblyRequest', null, global);
goog.exportSymbol('proto.DeleteGlobalAssemblyResponse', null, global);
goog.exportSymbol('proto.GetGlobalAssembliesByFolderIdRequest', null, global);
goog.exportSymbol('proto.GetGlobalAssembliesByFolderIdResponse', null, global);
goog.exportSymbol('proto.GlobalAssembly', null, global);
goog.exportSymbol('proto.GlobalAssemblyItem', null, global);
goog.exportSymbol('proto.GlobalAssemblyItemAttribute', null, global);
goog.exportSymbol('proto.GlobalItemCost', null, global);
goog.exportSymbol('proto.GlobalItemQtyFormula', null, global);
goog.exportSymbol('proto.GlobalQuoteGroup', null, global);
goog.exportSymbol('proto.UpdateGlobalAssemblyRequest', null, global);
goog.exportSymbol('proto.UpdateGlobalAssemblyResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GlobalAssembly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GlobalAssembly.repeatedFields_, null);
};
goog.inherits(proto.GlobalAssembly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GlobalAssembly.displayName = 'proto.GlobalAssembly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GlobalAssemblyItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GlobalAssemblyItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GlobalAssemblyItem.displayName = 'proto.GlobalAssemblyItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GlobalItemCost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GlobalItemCost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GlobalItemCost.displayName = 'proto.GlobalItemCost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GlobalItemQtyFormula = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GlobalItemQtyFormula, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GlobalItemQtyFormula.displayName = 'proto.GlobalItemQtyFormula';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GlobalAssemblyItemAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GlobalAssemblyItemAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GlobalAssemblyItemAttribute.displayName = 'proto.GlobalAssemblyItemAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GlobalQuoteGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GlobalQuoteGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GlobalQuoteGroup.displayName = 'proto.GlobalQuoteGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateGlobalAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateGlobalAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateGlobalAssemblyRequest.displayName = 'proto.CreateGlobalAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateGlobalAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateGlobalAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateGlobalAssemblyResponse.displayName = 'proto.CreateGlobalAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetGlobalAssembliesByFolderIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetGlobalAssembliesByFolderIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetGlobalAssembliesByFolderIdRequest.displayName = 'proto.GetGlobalAssembliesByFolderIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetGlobalAssembliesByFolderIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetGlobalAssembliesByFolderIdResponse.repeatedFields_, null);
};
goog.inherits(proto.GetGlobalAssembliesByFolderIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetGlobalAssembliesByFolderIdResponse.displayName = 'proto.GetGlobalAssembliesByFolderIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateGlobalAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateGlobalAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateGlobalAssemblyRequest.displayName = 'proto.UpdateGlobalAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateGlobalAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateGlobalAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateGlobalAssemblyResponse.displayName = 'proto.UpdateGlobalAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteGlobalAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteGlobalAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteGlobalAssemblyRequest.displayName = 'proto.DeleteGlobalAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteGlobalAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteGlobalAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteGlobalAssemblyResponse.displayName = 'proto.DeleteGlobalAssemblyResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GlobalAssembly.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GlobalAssembly.prototype.toObject = function(opt_includeInstance) {
  return proto.GlobalAssembly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GlobalAssembly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalAssembly.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assemblyid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assemblyname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    parentid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    level: jspb.Message.getFieldWithDefault(msg, 6, 0),
    entitytype: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isfile: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    assemblymeasurementtype: jspb.Message.getFieldWithDefault(msg, 9, ""),
    imageclassificationid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 11, ""),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 13, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.GlobalAssemblyItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GlobalAssembly}
 */
proto.GlobalAssembly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GlobalAssembly;
  return proto.GlobalAssembly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GlobalAssembly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GlobalAssembly}
 */
proto.GlobalAssembly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyname(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLevel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntitytype(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsfile(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblymeasurementtype(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImageclassificationid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 14:
      var value = new proto.GlobalAssemblyItem;
      reader.readMessage(value,proto.GlobalAssemblyItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GlobalAssembly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GlobalAssembly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GlobalAssembly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalAssembly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssemblyname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getParentid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getEntitytype();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsfile();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAssemblymeasurementtype();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getImageclassificationid();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.GlobalAssemblyItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string fileId = 1;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getFileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setFileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assemblyId = 2;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assemblyName = 3;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getAssemblyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setAssemblyname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool isActive = 4;
 * @return {boolean}
 */
proto.GlobalAssembly.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string parentId = 5;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getParentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setParentid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 level = 6;
 * @return {number}
 */
proto.GlobalAssembly.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string entityType = 7;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getEntitytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setEntitytype = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool isFile = 8;
 * @return {boolean}
 */
proto.GlobalAssembly.prototype.getIsfile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setIsfile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string assemblyMeasurementType = 9;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getAssemblymeasurementtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setAssemblymeasurementtype = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 imageClassificationId = 10;
 * @return {number}
 */
proto.GlobalAssembly.prototype.getImageclassificationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setImageclassificationid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string lastUpdated = 11;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string createdByUserId = 12;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string createdByAccountId = 13;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated GlobalAssemblyItem items = 14;
 * @return {!Array<!proto.GlobalAssemblyItem>}
 */
proto.GlobalAssembly.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.GlobalAssemblyItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GlobalAssemblyItem, 14));
};


/**
 * @param {!Array<!proto.GlobalAssemblyItem>} value
 * @return {!proto.GlobalAssembly} returns this
*/
proto.GlobalAssembly.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.GlobalAssemblyItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GlobalAssemblyItem}
 */
proto.GlobalAssembly.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.GlobalAssemblyItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GlobalAssemblyItem.prototype.toObject = function(opt_includeInstance) {
  return proto.GlobalAssemblyItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GlobalAssemblyItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalAssemblyItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    costs: (f = msg.getCosts()) && proto.GlobalItemCost.toObject(includeInstance, f),
    itemqtyformula: (f = msg.getItemqtyformula()) && proto.GlobalItemQtyFormula.toObject(includeInstance, f),
    attribute: (f = msg.getAttribute()) && proto.GlobalAssemblyItemAttribute.toObject(includeInstance, f),
    quotegroup: (f = msg.getQuotegroup()) && proto.GlobalQuoteGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GlobalAssemblyItem}
 */
proto.GlobalAssemblyItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GlobalAssemblyItem;
  return proto.GlobalAssemblyItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GlobalAssemblyItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GlobalAssemblyItem}
 */
proto.GlobalAssemblyItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemname(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 4:
      var value = new proto.GlobalItemCost;
      reader.readMessage(value,proto.GlobalItemCost.deserializeBinaryFromReader);
      msg.setCosts(value);
      break;
    case 5:
      var value = new proto.GlobalItemQtyFormula;
      reader.readMessage(value,proto.GlobalItemQtyFormula.deserializeBinaryFromReader);
      msg.setItemqtyformula(value);
      break;
    case 6:
      var value = new proto.GlobalAssemblyItemAttribute;
      reader.readMessage(value,proto.GlobalAssemblyItemAttribute.deserializeBinaryFromReader);
      msg.setAttribute(value);
      break;
    case 7:
      var value = new proto.GlobalQuoteGroup;
      reader.readMessage(value,proto.GlobalQuoteGroup.deserializeBinaryFromReader);
      msg.setQuotegroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GlobalAssemblyItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GlobalAssemblyItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GlobalAssemblyItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalAssemblyItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCosts();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.GlobalItemCost.serializeBinaryToWriter
    );
  }
  f = message.getItemqtyformula();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.GlobalItemQtyFormula.serializeBinaryToWriter
    );
  }
  f = message.getAttribute();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.GlobalAssemblyItemAttribute.serializeBinaryToWriter
    );
  }
  f = message.getQuotegroup();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.GlobalQuoteGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional string itemId = 1;
 * @return {string}
 */
proto.GlobalAssemblyItem.prototype.getItemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssemblyItem} returns this
 */
proto.GlobalAssemblyItem.prototype.setItemid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string itemName = 2;
 * @return {string}
 */
proto.GlobalAssemblyItem.prototype.getItemname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssemblyItem} returns this
 */
proto.GlobalAssemblyItem.prototype.setItemname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isQuoted = 3;
 * @return {boolean}
 */
proto.GlobalAssemblyItem.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GlobalAssemblyItem} returns this
 */
proto.GlobalAssemblyItem.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional GlobalItemCost costs = 4;
 * @return {?proto.GlobalItemCost}
 */
proto.GlobalAssemblyItem.prototype.getCosts = function() {
  return /** @type{?proto.GlobalItemCost} */ (
    jspb.Message.getWrapperField(this, proto.GlobalItemCost, 4));
};


/**
 * @param {?proto.GlobalItemCost|undefined} value
 * @return {!proto.GlobalAssemblyItem} returns this
*/
proto.GlobalAssemblyItem.prototype.setCosts = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GlobalAssemblyItem} returns this
 */
proto.GlobalAssemblyItem.prototype.clearCosts = function() {
  return this.setCosts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GlobalAssemblyItem.prototype.hasCosts = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional GlobalItemQtyFormula itemQtyFormula = 5;
 * @return {?proto.GlobalItemQtyFormula}
 */
proto.GlobalAssemblyItem.prototype.getItemqtyformula = function() {
  return /** @type{?proto.GlobalItemQtyFormula} */ (
    jspb.Message.getWrapperField(this, proto.GlobalItemQtyFormula, 5));
};


/**
 * @param {?proto.GlobalItemQtyFormula|undefined} value
 * @return {!proto.GlobalAssemblyItem} returns this
*/
proto.GlobalAssemblyItem.prototype.setItemqtyformula = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GlobalAssemblyItem} returns this
 */
proto.GlobalAssemblyItem.prototype.clearItemqtyformula = function() {
  return this.setItemqtyformula(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GlobalAssemblyItem.prototype.hasItemqtyformula = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional GlobalAssemblyItemAttribute attribute = 6;
 * @return {?proto.GlobalAssemblyItemAttribute}
 */
proto.GlobalAssemblyItem.prototype.getAttribute = function() {
  return /** @type{?proto.GlobalAssemblyItemAttribute} */ (
    jspb.Message.getWrapperField(this, proto.GlobalAssemblyItemAttribute, 6));
};


/**
 * @param {?proto.GlobalAssemblyItemAttribute|undefined} value
 * @return {!proto.GlobalAssemblyItem} returns this
*/
proto.GlobalAssemblyItem.prototype.setAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GlobalAssemblyItem} returns this
 */
proto.GlobalAssemblyItem.prototype.clearAttribute = function() {
  return this.setAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GlobalAssemblyItem.prototype.hasAttribute = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional GlobalQuoteGroup quoteGroup = 7;
 * @return {?proto.GlobalQuoteGroup}
 */
proto.GlobalAssemblyItem.prototype.getQuotegroup = function() {
  return /** @type{?proto.GlobalQuoteGroup} */ (
    jspb.Message.getWrapperField(this, proto.GlobalQuoteGroup, 7));
};


/**
 * @param {?proto.GlobalQuoteGroup|undefined} value
 * @return {!proto.GlobalAssemblyItem} returns this
*/
proto.GlobalAssemblyItem.prototype.setQuotegroup = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GlobalAssemblyItem} returns this
 */
proto.GlobalAssemblyItem.prototype.clearQuotegroup = function() {
  return this.setQuotegroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GlobalAssemblyItem.prototype.hasQuotegroup = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GlobalItemCost.prototype.toObject = function(opt_includeInstance) {
  return proto.GlobalItemCost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GlobalItemCost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalItemCost.toObject = function(includeInstance, msg) {
  var f, obj = {
    uomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemunitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    hourlyproductionrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    uomname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    uomvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GlobalItemCost}
 */
proto.GlobalItemCost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GlobalItemCost;
  return proto.GlobalItemCost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GlobalItemCost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GlobalItemCost}
 */
proto.GlobalItemCost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUomid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemunitcost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHourlyproductionrate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUomname(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUomvalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GlobalItemCost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GlobalItemCost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GlobalItemCost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalItemCost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUomid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemunitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getHourlyproductionrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getUomname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUomvalue();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional int32 uomId = 1;
 * @return {number}
 */
proto.GlobalItemCost.prototype.getUomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GlobalItemCost} returns this
 */
proto.GlobalItemCost.prototype.setUomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float itemUnitCost = 2;
 * @return {number}
 */
proto.GlobalItemCost.prototype.getItemunitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GlobalItemCost} returns this
 */
proto.GlobalItemCost.prototype.setItemunitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float hourlyProductionRate = 3;
 * @return {number}
 */
proto.GlobalItemCost.prototype.getHourlyproductionrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GlobalItemCost} returns this
 */
proto.GlobalItemCost.prototype.setHourlyproductionrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string uomName = 4;
 * @return {string}
 */
proto.GlobalItemCost.prototype.getUomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalItemCost} returns this
 */
proto.GlobalItemCost.prototype.setUomname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float uomValue = 5;
 * @return {number}
 */
proto.GlobalItemCost.prototype.getUomvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GlobalItemCost} returns this
 */
proto.GlobalItemCost.prototype.setUomvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GlobalItemQtyFormula.prototype.toObject = function(opt_includeInstance) {
  return proto.GlobalItemQtyFormula.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GlobalItemQtyFormula} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalItemQtyFormula.toObject = function(includeInstance, msg) {
  var f, obj = {
    formulaid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    forevery: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    takeoffvariabletype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    and: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    or: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    itemid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    quotegroupid: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GlobalItemQtyFormula}
 */
proto.GlobalItemQtyFormula.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GlobalItemQtyFormula;
  return proto.GlobalItemQtyFormula.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GlobalItemQtyFormula} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GlobalItemQtyFormula}
 */
proto.GlobalItemQtyFormula.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormulaid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemqty(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setForevery(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffvariabletype(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnd(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOr(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemid(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroupid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GlobalItemQtyFormula.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GlobalItemQtyFormula.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GlobalItemQtyFormula} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalItemQtyFormula.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormulaid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getForevery();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTakeoffvariabletype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAnd();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOr();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getItemid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getQuotegroupid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string formulaId = 1;
 * @return {string}
 */
proto.GlobalItemQtyFormula.prototype.getFormulaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalItemQtyFormula} returns this
 */
proto.GlobalItemQtyFormula.prototype.setFormulaid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float itemQty = 2;
 * @return {number}
 */
proto.GlobalItemQtyFormula.prototype.getItemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GlobalItemQtyFormula} returns this
 */
proto.GlobalItemQtyFormula.prototype.setItemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float forEvery = 3;
 * @return {number}
 */
proto.GlobalItemQtyFormula.prototype.getForevery = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GlobalItemQtyFormula} returns this
 */
proto.GlobalItemQtyFormula.prototype.setForevery = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string takeoffVariableType = 4;
 * @return {string}
 */
proto.GlobalItemQtyFormula.prototype.getTakeoffvariabletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalItemQtyFormula} returns this
 */
proto.GlobalItemQtyFormula.prototype.setTakeoffvariabletype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool and = 5;
 * @return {boolean}
 */
proto.GlobalItemQtyFormula.prototype.getAnd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GlobalItemQtyFormula} returns this
 */
proto.GlobalItemQtyFormula.prototype.setAnd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool or = 6;
 * @return {boolean}
 */
proto.GlobalItemQtyFormula.prototype.getOr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GlobalItemQtyFormula} returns this
 */
proto.GlobalItemQtyFormula.prototype.setOr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string itemId = 7;
 * @return {string}
 */
proto.GlobalItemQtyFormula.prototype.getItemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalItemQtyFormula} returns this
 */
proto.GlobalItemQtyFormula.prototype.setItemid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool isQuoted = 8;
 * @return {boolean}
 */
proto.GlobalItemQtyFormula.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GlobalItemQtyFormula} returns this
 */
proto.GlobalItemQtyFormula.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 attributeValueId = 9;
 * @return {number}
 */
proto.GlobalItemQtyFormula.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.GlobalItemQtyFormula} returns this
 */
proto.GlobalItemQtyFormula.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string quoteGroupId = 10;
 * @return {string}
 */
proto.GlobalItemQtyFormula.prototype.getQuotegroupid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalItemQtyFormula} returns this
 */
proto.GlobalItemQtyFormula.prototype.setQuotegroupid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GlobalAssemblyItemAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.GlobalAssemblyItemAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GlobalAssemblyItemAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalAssemblyItemAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attributevaluename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    attributegroupid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    attributegroupname: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GlobalAssemblyItemAttribute}
 */
proto.GlobalAssemblyItemAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GlobalAssemblyItemAttribute;
  return proto.GlobalAssemblyItemAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GlobalAssemblyItemAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GlobalAssemblyItemAttribute}
 */
proto.GlobalAssemblyItemAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributevaluename(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributegroupid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GlobalAssemblyItemAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GlobalAssemblyItemAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GlobalAssemblyItemAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalAssemblyItemAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAttributevaluename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAttributegroupid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAttributegroupname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 attributeValueId = 1;
 * @return {number}
 */
proto.GlobalAssemblyItemAttribute.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GlobalAssemblyItemAttribute} returns this
 */
proto.GlobalAssemblyItemAttribute.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string attributeValueName = 2;
 * @return {string}
 */
proto.GlobalAssemblyItemAttribute.prototype.getAttributevaluename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssemblyItemAttribute} returns this
 */
proto.GlobalAssemblyItemAttribute.prototype.setAttributevaluename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 attributeGroupId = 3;
 * @return {number}
 */
proto.GlobalAssemblyItemAttribute.prototype.getAttributegroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.GlobalAssemblyItemAttribute} returns this
 */
proto.GlobalAssemblyItemAttribute.prototype.setAttributegroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string attributeGroupName = 4;
 * @return {string}
 */
proto.GlobalAssemblyItemAttribute.prototype.getAttributegroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssemblyItemAttribute} returns this
 */
proto.GlobalAssemblyItemAttribute.prototype.setAttributegroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GlobalQuoteGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.GlobalQuoteGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GlobalQuoteGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalQuoteGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quotegroup: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GlobalQuoteGroup}
 */
proto.GlobalQuoteGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GlobalQuoteGroup;
  return proto.GlobalQuoteGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GlobalQuoteGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GlobalQuoteGroup}
 */
proto.GlobalQuoteGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GlobalQuoteGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GlobalQuoteGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GlobalQuoteGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalQuoteGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuotegroup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Id = 1;
 * @return {string}
 */
proto.GlobalQuoteGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalQuoteGroup} returns this
 */
proto.GlobalQuoteGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string quoteGroup = 2;
 * @return {string}
 */
proto.GlobalQuoteGroup.prototype.getQuotegroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalQuoteGroup} returns this
 */
proto.GlobalQuoteGroup.prototype.setQuotegroup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateGlobalAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateGlobalAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateGlobalAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGlobalAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    globalassembly: (f = msg.getGlobalassembly()) && proto.GlobalAssembly.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateGlobalAssemblyRequest}
 */
proto.CreateGlobalAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateGlobalAssemblyRequest;
  return proto.CreateGlobalAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateGlobalAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateGlobalAssemblyRequest}
 */
proto.CreateGlobalAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GlobalAssembly;
      reader.readMessage(value,proto.GlobalAssembly.deserializeBinaryFromReader);
      msg.setGlobalassembly(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateGlobalAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateGlobalAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateGlobalAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGlobalAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GlobalAssembly.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional GlobalAssembly globalAssembly = 1;
 * @return {?proto.GlobalAssembly}
 */
proto.CreateGlobalAssemblyRequest.prototype.getGlobalassembly = function() {
  return /** @type{?proto.GlobalAssembly} */ (
    jspb.Message.getWrapperField(this, proto.GlobalAssembly, 1));
};


/**
 * @param {?proto.GlobalAssembly|undefined} value
 * @return {!proto.CreateGlobalAssemblyRequest} returns this
*/
proto.CreateGlobalAssemblyRequest.prototype.setGlobalassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateGlobalAssemblyRequest} returns this
 */
proto.CreateGlobalAssemblyRequest.prototype.clearGlobalassembly = function() {
  return this.setGlobalassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateGlobalAssemblyRequest.prototype.hasGlobalassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.CreateGlobalAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateGlobalAssemblyRequest} returns this
 */
proto.CreateGlobalAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateGlobalAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateGlobalAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateGlobalAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGlobalAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateGlobalAssemblyResponse}
 */
proto.CreateGlobalAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateGlobalAssemblyResponse;
  return proto.CreateGlobalAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateGlobalAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateGlobalAssemblyResponse}
 */
proto.CreateGlobalAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateGlobalAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateGlobalAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateGlobalAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGlobalAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateGlobalAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateGlobalAssemblyResponse} returns this
 */
proto.CreateGlobalAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetGlobalAssembliesByFolderIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetGlobalAssembliesByFolderIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetGlobalAssembliesByFolderIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGlobalAssembliesByFolderIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    folderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetGlobalAssembliesByFolderIdRequest}
 */
proto.GetGlobalAssembliesByFolderIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetGlobalAssembliesByFolderIdRequest;
  return proto.GetGlobalAssembliesByFolderIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetGlobalAssembliesByFolderIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetGlobalAssembliesByFolderIdRequest}
 */
proto.GetGlobalAssembliesByFolderIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFolderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetGlobalAssembliesByFolderIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetGlobalAssembliesByFolderIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetGlobalAssembliesByFolderIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGlobalAssembliesByFolderIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFolderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string folderId = 1;
 * @return {string}
 */
proto.GetGlobalAssembliesByFolderIdRequest.prototype.getFolderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetGlobalAssembliesByFolderIdRequest} returns this
 */
proto.GetGlobalAssembliesByFolderIdRequest.prototype.setFolderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetGlobalAssembliesByFolderIdRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetGlobalAssembliesByFolderIdRequest} returns this
 */
proto.GetGlobalAssembliesByFolderIdRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetGlobalAssembliesByFolderIdResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetGlobalAssembliesByFolderIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetGlobalAssembliesByFolderIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetGlobalAssembliesByFolderIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGlobalAssembliesByFolderIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    globalassembliesList: jspb.Message.toObjectList(msg.getGlobalassembliesList(),
    proto.GlobalAssembly.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetGlobalAssembliesByFolderIdResponse}
 */
proto.GetGlobalAssembliesByFolderIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetGlobalAssembliesByFolderIdResponse;
  return proto.GetGlobalAssembliesByFolderIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetGlobalAssembliesByFolderIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetGlobalAssembliesByFolderIdResponse}
 */
proto.GetGlobalAssembliesByFolderIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GlobalAssembly;
      reader.readMessage(value,proto.GlobalAssembly.deserializeBinaryFromReader);
      msg.addGlobalassemblies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetGlobalAssembliesByFolderIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetGlobalAssembliesByFolderIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetGlobalAssembliesByFolderIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGlobalAssembliesByFolderIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalassembliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GlobalAssembly.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GlobalAssembly globalAssemblies = 1;
 * @return {!Array<!proto.GlobalAssembly>}
 */
proto.GetGlobalAssembliesByFolderIdResponse.prototype.getGlobalassembliesList = function() {
  return /** @type{!Array<!proto.GlobalAssembly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GlobalAssembly, 1));
};


/**
 * @param {!Array<!proto.GlobalAssembly>} value
 * @return {!proto.GetGlobalAssembliesByFolderIdResponse} returns this
*/
proto.GetGlobalAssembliesByFolderIdResponse.prototype.setGlobalassembliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GlobalAssembly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GlobalAssembly}
 */
proto.GetGlobalAssembliesByFolderIdResponse.prototype.addGlobalassemblies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GlobalAssembly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetGlobalAssembliesByFolderIdResponse} returns this
 */
proto.GetGlobalAssembliesByFolderIdResponse.prototype.clearGlobalassembliesList = function() {
  return this.setGlobalassembliesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateGlobalAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateGlobalAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateGlobalAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGlobalAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    globalassembly: (f = msg.getGlobalassembly()) && proto.GlobalAssembly.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateGlobalAssemblyRequest}
 */
proto.UpdateGlobalAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateGlobalAssemblyRequest;
  return proto.UpdateGlobalAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateGlobalAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateGlobalAssemblyRequest}
 */
proto.UpdateGlobalAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GlobalAssembly;
      reader.readMessage(value,proto.GlobalAssembly.deserializeBinaryFromReader);
      msg.setGlobalassembly(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateGlobalAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateGlobalAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateGlobalAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGlobalAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GlobalAssembly.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional GlobalAssembly globalAssembly = 1;
 * @return {?proto.GlobalAssembly}
 */
proto.UpdateGlobalAssemblyRequest.prototype.getGlobalassembly = function() {
  return /** @type{?proto.GlobalAssembly} */ (
    jspb.Message.getWrapperField(this, proto.GlobalAssembly, 1));
};


/**
 * @param {?proto.GlobalAssembly|undefined} value
 * @return {!proto.UpdateGlobalAssemblyRequest} returns this
*/
proto.UpdateGlobalAssemblyRequest.prototype.setGlobalassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateGlobalAssemblyRequest} returns this
 */
proto.UpdateGlobalAssemblyRequest.prototype.clearGlobalassembly = function() {
  return this.setGlobalassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateGlobalAssemblyRequest.prototype.hasGlobalassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateGlobalAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateGlobalAssemblyRequest} returns this
 */
proto.UpdateGlobalAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateGlobalAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateGlobalAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateGlobalAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGlobalAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateGlobalAssemblyResponse}
 */
proto.UpdateGlobalAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateGlobalAssemblyResponse;
  return proto.UpdateGlobalAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateGlobalAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateGlobalAssemblyResponse}
 */
proto.UpdateGlobalAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateGlobalAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateGlobalAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateGlobalAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGlobalAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateGlobalAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateGlobalAssemblyResponse} returns this
 */
proto.UpdateGlobalAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteGlobalAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteGlobalAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteGlobalAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGlobalAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assemblyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteGlobalAssemblyRequest}
 */
proto.DeleteGlobalAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteGlobalAssemblyRequest;
  return proto.DeleteGlobalAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteGlobalAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteGlobalAssemblyRequest}
 */
proto.DeleteGlobalAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteGlobalAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteGlobalAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteGlobalAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGlobalAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string assemblyId = 1;
 * @return {string}
 */
proto.DeleteGlobalAssemblyRequest.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteGlobalAssemblyRequest} returns this
 */
proto.DeleteGlobalAssemblyRequest.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteGlobalAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteGlobalAssemblyRequest} returns this
 */
proto.DeleteGlobalAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteGlobalAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteGlobalAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteGlobalAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGlobalAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteGlobalAssemblyResponse}
 */
proto.DeleteGlobalAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteGlobalAssemblyResponse;
  return proto.DeleteGlobalAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteGlobalAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteGlobalAssemblyResponse}
 */
proto.DeleteGlobalAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteGlobalAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteGlobalAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteGlobalAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGlobalAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteGlobalAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteGlobalAssemblyResponse} returns this
 */
proto.DeleteGlobalAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto);
