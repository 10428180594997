import { pixelResolution } from "../components/SVGelements/SVGMeasurement";
import { Point } from "../types/Point";

/**
 * Calculations is a namespace that contains various utility functions for calculations
 */
export namespace Calculations {
  /**
   * UOM is a type that represents the unit of measure of an item
   */

  /**
   * calculateDistance calculates the distance between two points and returns number of pixels between them
   * @param startPoint - The starting point of the distance
   * @param endPoint - The ending point of the distance
   * @returns The distance in pixels
   */
  export const calculateDistance = (
    startPoint: { x: number; y: number },
    endPoint: { x: number; y: number }
  ): number => {
    const dx = endPoint.x - startPoint.x;
    const dy = endPoint.y - startPoint.y;
    return Math.sqrt(dx * dx + dy * dy);
  };

  /**
   * convertPixelsToInch converts the dimensions of a viewport in pixels to inches
   * @param viewportHeight - The height of the viewport in pixels
   * @param viewportWidth - The width of the viewport in pixels
   * @param ppi - The resolution of the viewport in pixels per inch
   * @returns An object containing the height and width of the viewport in inches
   */
  export const convertPixelsToInch = (
    viewportHeight: number,
    viewportWidth: number,
    ppi: number
  ) => ({
    heightInInches: viewportHeight / ppi,
    widthInInches: viewportWidth / ppi,
  });

  /**
   * calculateTotalDistance calculates the total distance between all points in the pointsList
   * and returns the value in inches determined by the user scale
   * @param pointsList - An array of points
   * @param userScale - The user scale of the assembly
   * @param measurementType - The measurement type of the assembly
   * @returns The total distance in inches
   */
  type TotalDistanceMeasurementType = "length" | "area";
  export const calculateTotalDistance = (
    pointsList: Array<Point>,
    userScale: number,
    measurementType: TotalDistanceMeasurementType
  ): number => {
    if (!pointsList || pointsList.length < 2) {
      console.log("Not enough points to calculate total length");
      return 0;
    } else if (!userScale) {
      console.log("No user scale provided");
      return 0;
    }

    let totalVerticalLength = 0;
    for (let i = 0; i < pointsList.length; i++) {
      const point = pointsList[i];
      if (point.verticallength !== undefined) {
        totalVerticalLength += point.verticallength;
      }
    }

    let totalFeet = 0;
    const numPoints = pointsList.length;

    // Calculate distances between consecutive points
    for (let i = 0; i < numPoints - 1; i++) {
      const point = pointsList[i];
      const nextPoint = pointsList[i + 1];

      // Skip this segment if allowtrailingline is false for the next point
      if (!nextPoint.allowtrailingline) {
        continue;
      }

      const pixelDistance = Math.sqrt(
        (nextPoint.x - point.x) ** 2 + (nextPoint.y - point.y) ** 2
      );
      totalFeet += (pixelDistance / pixelResolution) * userScale; // Convert pixels to inches, then to feet using userScale
    }

    // If measurementType is "area", include the closing segment
    if (measurementType === "area") {
      const firstPoint = pointsList[0];
      const lastPoint = pointsList[numPoints - 1];

      if (lastPoint.allowtrailingline) {
        const closingPixelDistance = Math.sqrt(
          (firstPoint.x - lastPoint.x) ** 2 + (firstPoint.y - lastPoint.y) ** 2
        );
        totalFeet += (closingPixelDistance / pixelResolution) * userScale;
      }
    }

    return totalFeet + totalVerticalLength;
  };
}
