// source: estimateCloseout.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.BreakoutAllocation', null, global);
goog.exportSymbol('proto.CreateDirectLaborCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateDirectLaborCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateEquipmentCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateEquipmentCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateGeneralExpenseCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateGeneralExpenseCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateIncidentalLaborCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateIncidentalLaborCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateIndirectLaborCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateIndirectLaborCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateLaborFactoringCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateLaborFactoringCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateQuoteCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateQuoteCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateSubcontractCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateSubcontractCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteDirectLaborCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteDirectLaborCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteEquipmentCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteEquipmentCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteGeneralExpenseCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteGeneralExpenseCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteIncidentalLaborCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteIncidentalLaborCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteIndirectLaborCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteIndirectLaborCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteLaborFactoringCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteLaborFactoringCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteQuoteCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteQuoteCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteSubcontractCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteSubcontractCloseoutResponse', null, global);
goog.exportSymbol('proto.DirectLaborCloseout', null, global);
goog.exportSymbol('proto.DirectLaborToFinalPrice', null, global);
goog.exportSymbol('proto.EquipmentCloseout', null, global);
goog.exportSymbol('proto.EquipmentToFinalPrice', null, global);
goog.exportSymbol('proto.ExtendEstimate', null, global);
goog.exportSymbol('proto.ExtendEstimateToFinalPrice', null, global);
goog.exportSymbol('proto.GeneralExpenseCloseout', null, global);
goog.exportSymbol('proto.GeneralExpenseToFinalPrice', null, global);
goog.exportSymbol('proto.GetDirectLaborCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetDirectLaborCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetEquipmentCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetEquipmentCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetExtendEstimateRequest', null, global);
goog.exportSymbol('proto.GetExtendEstimateResponse', null, global);
goog.exportSymbol('proto.GetGeneralExpenseCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetGeneralExpenseCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetIncidentalLaborCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetIncidentalLaborCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetIndirectLaborCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetIndirectLaborCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetLaborFactoringCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetLaborFactoringCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetQuoteCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetQuoteCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetSubcontractCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetSubcontractCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetTotalFinalPriceCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetTotalFinalPriceCloseoutsResponse', null, global);
goog.exportSymbol('proto.IncidentalLaborCloseout', null, global);
goog.exportSymbol('proto.IncidentalLaborToFinalPrice', null, global);
goog.exportSymbol('proto.IndirectLaborCloseout', null, global);
goog.exportSymbol('proto.IndirectLaborToFinalPrice', null, global);
goog.exportSymbol('proto.LaborFactoringCloseout', null, global);
goog.exportSymbol('proto.LaborFactoringToFinalPrice', null, global);
goog.exportSymbol('proto.QuoteCloseout', null, global);
goog.exportSymbol('proto.QuoteToFinalPrice', null, global);
goog.exportSymbol('proto.SubcontractCloseout', null, global);
goog.exportSymbol('proto.SubcontractToFinalPrice', null, global);
goog.exportSymbol('proto.TotalFinalPriceCloseout', null, global);
goog.exportSymbol('proto.UpdateDirectLaborCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateDirectLaborCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateEquipmentCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateEquipmentCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateEstimateEquipmentAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateEstimateEquipmentAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateEstimateGeneralExpenseAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateEstimateGeneralExpenseAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateEstimateIncidentalLaborAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateEstimateIncidentalLaborAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateEstimateIndirectLaborAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateEstimateIndirectLaborAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateEstimateLaborFactoringAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateEstimateLaborFactoringAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateEstimateQuoteAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateEstimateQuoteAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateEstimateSubcontractAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateEstimateSubcontractAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateExtendEstimateRequest', null, global);
goog.exportSymbol('proto.UpdateExtendEstimateResponse', null, global);
goog.exportSymbol('proto.UpdateGeneralExpenseCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateGeneralExpenseCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateIncidentalLaborCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateIncidentalLaborCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateIndirectLaborCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateIndirectLaborCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateLaborFactoringCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateLaborFactoringCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateQuoteCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateQuoteCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateSubcontractCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateSubcontractCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateTotalFinalPriceCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateTotalFinalPriceCloseoutResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BreakoutAllocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BreakoutAllocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BreakoutAllocation.displayName = 'proto.BreakoutAllocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateLaborFactoringAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateLaborFactoringAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateLaborFactoringAllocationRequest.displayName = 'proto.UpdateEstimateLaborFactoringAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateLaborFactoringAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateLaborFactoringAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateLaborFactoringAllocationResponse.displayName = 'proto.UpdateEstimateLaborFactoringAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateIncidentalLaborAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateIncidentalLaborAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateIncidentalLaborAllocationRequest.displayName = 'proto.UpdateEstimateIncidentalLaborAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateIncidentalLaborAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateIncidentalLaborAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateIncidentalLaborAllocationResponse.displayName = 'proto.UpdateEstimateIncidentalLaborAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateIndirectLaborAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateIndirectLaborAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateIndirectLaborAllocationRequest.displayName = 'proto.UpdateEstimateIndirectLaborAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateIndirectLaborAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateIndirectLaborAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateIndirectLaborAllocationResponse.displayName = 'proto.UpdateEstimateIndirectLaborAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateEquipmentAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateEquipmentAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateEquipmentAllocationRequest.displayName = 'proto.UpdateEstimateEquipmentAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateEquipmentAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateEquipmentAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateEquipmentAllocationResponse.displayName = 'proto.UpdateEstimateEquipmentAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateGeneralExpenseAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateGeneralExpenseAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateGeneralExpenseAllocationRequest.displayName = 'proto.UpdateEstimateGeneralExpenseAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateGeneralExpenseAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateGeneralExpenseAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateGeneralExpenseAllocationResponse.displayName = 'proto.UpdateEstimateGeneralExpenseAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateSubcontractAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateSubcontractAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateSubcontractAllocationRequest.displayName = 'proto.UpdateEstimateSubcontractAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateSubcontractAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateSubcontractAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateSubcontractAllocationResponse.displayName = 'proto.UpdateEstimateSubcontractAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateQuoteAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateQuoteAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateQuoteAllocationRequest.displayName = 'proto.UpdateEstimateQuoteAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateQuoteAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateQuoteAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateQuoteAllocationResponse.displayName = 'proto.UpdateEstimateQuoteAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ExtendEstimate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ExtendEstimate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ExtendEstimate.displayName = 'proto.ExtendEstimate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetExtendEstimateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetExtendEstimateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetExtendEstimateRequest.displayName = 'proto.GetExtendEstimateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetExtendEstimateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetExtendEstimateResponse.repeatedFields_, null);
};
goog.inherits(proto.GetExtendEstimateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetExtendEstimateResponse.displayName = 'proto.GetExtendEstimateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateExtendEstimateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateExtendEstimateRequest.repeatedFields_, null);
};
goog.inherits(proto.UpdateExtendEstimateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateExtendEstimateRequest.displayName = 'proto.UpdateExtendEstimateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateExtendEstimateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateExtendEstimateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateExtendEstimateResponse.displayName = 'proto.UpdateExtendEstimateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DirectLaborCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DirectLaborCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DirectLaborCloseout.displayName = 'proto.DirectLaborCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LaborFactoringCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.LaborFactoringCloseout.repeatedFields_, null);
};
goog.inherits(proto.LaborFactoringCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LaborFactoringCloseout.displayName = 'proto.LaborFactoringCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IncidentalLaborCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IncidentalLaborCloseout.repeatedFields_, null);
};
goog.inherits(proto.IncidentalLaborCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IncidentalLaborCloseout.displayName = 'proto.IncidentalLaborCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndirectLaborCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IndirectLaborCloseout.repeatedFields_, null);
};
goog.inherits(proto.IndirectLaborCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndirectLaborCloseout.displayName = 'proto.IndirectLaborCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EquipmentCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EquipmentCloseout.repeatedFields_, null);
};
goog.inherits(proto.EquipmentCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EquipmentCloseout.displayName = 'proto.EquipmentCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GeneralExpenseCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GeneralExpenseCloseout.repeatedFields_, null);
};
goog.inherits(proto.GeneralExpenseCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GeneralExpenseCloseout.displayName = 'proto.GeneralExpenseCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SubcontractCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SubcontractCloseout.repeatedFields_, null);
};
goog.inherits(proto.SubcontractCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SubcontractCloseout.displayName = 'proto.SubcontractCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.QuoteCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.QuoteCloseout.repeatedFields_, null);
};
goog.inherits(proto.QuoteCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.QuoteCloseout.displayName = 'proto.QuoteCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TotalFinalPriceCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TotalFinalPriceCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TotalFinalPriceCloseout.displayName = 'proto.TotalFinalPriceCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ExtendEstimateToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ExtendEstimateToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.ExtendEstimateToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ExtendEstimateToFinalPrice.displayName = 'proto.ExtendEstimateToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DirectLaborToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DirectLaborToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.DirectLaborToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DirectLaborToFinalPrice.displayName = 'proto.DirectLaborToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LaborFactoringToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.LaborFactoringToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.LaborFactoringToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LaborFactoringToFinalPrice.displayName = 'proto.LaborFactoringToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IncidentalLaborToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IncidentalLaborToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.IncidentalLaborToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IncidentalLaborToFinalPrice.displayName = 'proto.IncidentalLaborToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndirectLaborToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IndirectLaborToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.IndirectLaborToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndirectLaborToFinalPrice.displayName = 'proto.IndirectLaborToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EquipmentToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EquipmentToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.EquipmentToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EquipmentToFinalPrice.displayName = 'proto.EquipmentToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GeneralExpenseToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GeneralExpenseToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.GeneralExpenseToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GeneralExpenseToFinalPrice.displayName = 'proto.GeneralExpenseToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SubcontractToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SubcontractToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.SubcontractToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SubcontractToFinalPrice.displayName = 'proto.SubcontractToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.QuoteToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.QuoteToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.QuoteToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.QuoteToFinalPrice.displayName = 'proto.QuoteToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateDirectLaborCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateDirectLaborCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateDirectLaborCloseoutRequest.displayName = 'proto.CreateDirectLaborCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateDirectLaborCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateDirectLaborCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateDirectLaborCloseoutResponse.displayName = 'proto.CreateDirectLaborCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDirectLaborCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetDirectLaborCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDirectLaborCloseoutsRequest.displayName = 'proto.GetDirectLaborCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDirectLaborCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetDirectLaborCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetDirectLaborCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDirectLaborCloseoutsResponse.displayName = 'proto.GetDirectLaborCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateDirectLaborCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateDirectLaborCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateDirectLaborCloseoutRequest.displayName = 'proto.UpdateDirectLaborCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateDirectLaborCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateDirectLaborCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateDirectLaborCloseoutResponse.displayName = 'proto.UpdateDirectLaborCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDirectLaborCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDirectLaborCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDirectLaborCloseoutRequest.displayName = 'proto.DeleteDirectLaborCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDirectLaborCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDirectLaborCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDirectLaborCloseoutResponse.displayName = 'proto.DeleteDirectLaborCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateLaborFactoringCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateLaborFactoringCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateLaborFactoringCloseoutRequest.displayName = 'proto.CreateLaborFactoringCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateLaborFactoringCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateLaborFactoringCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateLaborFactoringCloseoutResponse.displayName = 'proto.CreateLaborFactoringCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetLaborFactoringCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetLaborFactoringCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetLaborFactoringCloseoutsRequest.displayName = 'proto.GetLaborFactoringCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetLaborFactoringCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetLaborFactoringCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetLaborFactoringCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetLaborFactoringCloseoutsResponse.displayName = 'proto.GetLaborFactoringCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateLaborFactoringCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateLaborFactoringCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateLaborFactoringCloseoutRequest.displayName = 'proto.UpdateLaborFactoringCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateLaborFactoringCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateLaborFactoringCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateLaborFactoringCloseoutResponse.displayName = 'proto.UpdateLaborFactoringCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteLaborFactoringCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteLaborFactoringCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteLaborFactoringCloseoutRequest.displayName = 'proto.DeleteLaborFactoringCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteLaborFactoringCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteLaborFactoringCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteLaborFactoringCloseoutResponse.displayName = 'proto.DeleteLaborFactoringCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateIncidentalLaborCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateIncidentalLaborCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateIncidentalLaborCloseoutRequest.displayName = 'proto.CreateIncidentalLaborCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateIncidentalLaborCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateIncidentalLaborCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateIncidentalLaborCloseoutResponse.displayName = 'proto.CreateIncidentalLaborCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetIncidentalLaborCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetIncidentalLaborCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetIncidentalLaborCloseoutsRequest.displayName = 'proto.GetIncidentalLaborCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetIncidentalLaborCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetIncidentalLaborCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetIncidentalLaborCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetIncidentalLaborCloseoutsResponse.displayName = 'proto.GetIncidentalLaborCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateIncidentalLaborCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateIncidentalLaborCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateIncidentalLaborCloseoutRequest.displayName = 'proto.UpdateIncidentalLaborCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateIncidentalLaborCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateIncidentalLaborCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateIncidentalLaborCloseoutResponse.displayName = 'proto.UpdateIncidentalLaborCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteIncidentalLaborCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteIncidentalLaborCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteIncidentalLaborCloseoutRequest.displayName = 'proto.DeleteIncidentalLaborCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteIncidentalLaborCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteIncidentalLaborCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteIncidentalLaborCloseoutResponse.displayName = 'proto.DeleteIncidentalLaborCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateIndirectLaborCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateIndirectLaborCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateIndirectLaborCloseoutRequest.displayName = 'proto.CreateIndirectLaborCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateIndirectLaborCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateIndirectLaborCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateIndirectLaborCloseoutResponse.displayName = 'proto.CreateIndirectLaborCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetIndirectLaborCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetIndirectLaborCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetIndirectLaborCloseoutsRequest.displayName = 'proto.GetIndirectLaborCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetIndirectLaborCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetIndirectLaborCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetIndirectLaborCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetIndirectLaborCloseoutsResponse.displayName = 'proto.GetIndirectLaborCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateIndirectLaborCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateIndirectLaborCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateIndirectLaborCloseoutRequest.displayName = 'proto.UpdateIndirectLaborCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateIndirectLaborCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateIndirectLaborCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateIndirectLaborCloseoutResponse.displayName = 'proto.UpdateIndirectLaborCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteIndirectLaborCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteIndirectLaborCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteIndirectLaborCloseoutRequest.displayName = 'proto.DeleteIndirectLaborCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteIndirectLaborCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteIndirectLaborCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteIndirectLaborCloseoutResponse.displayName = 'proto.DeleteIndirectLaborCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateEquipmentCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateEquipmentCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateEquipmentCloseoutRequest.displayName = 'proto.CreateEquipmentCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateEquipmentCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateEquipmentCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateEquipmentCloseoutResponse.displayName = 'proto.CreateEquipmentCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetEquipmentCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetEquipmentCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetEquipmentCloseoutsRequest.displayName = 'proto.GetEquipmentCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetEquipmentCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetEquipmentCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetEquipmentCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetEquipmentCloseoutsResponse.displayName = 'proto.GetEquipmentCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEquipmentCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEquipmentCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEquipmentCloseoutRequest.displayName = 'proto.UpdateEquipmentCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEquipmentCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEquipmentCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEquipmentCloseoutResponse.displayName = 'proto.UpdateEquipmentCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteEquipmentCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteEquipmentCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteEquipmentCloseoutRequest.displayName = 'proto.DeleteEquipmentCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteEquipmentCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteEquipmentCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteEquipmentCloseoutResponse.displayName = 'proto.DeleteEquipmentCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateGeneralExpenseCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateGeneralExpenseCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateGeneralExpenseCloseoutRequest.displayName = 'proto.CreateGeneralExpenseCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateGeneralExpenseCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateGeneralExpenseCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateGeneralExpenseCloseoutResponse.displayName = 'proto.CreateGeneralExpenseCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetGeneralExpenseCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetGeneralExpenseCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetGeneralExpenseCloseoutsRequest.displayName = 'proto.GetGeneralExpenseCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetGeneralExpenseCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetGeneralExpenseCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetGeneralExpenseCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetGeneralExpenseCloseoutsResponse.displayName = 'proto.GetGeneralExpenseCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateGeneralExpenseCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateGeneralExpenseCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateGeneralExpenseCloseoutRequest.displayName = 'proto.UpdateGeneralExpenseCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateGeneralExpenseCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateGeneralExpenseCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateGeneralExpenseCloseoutResponse.displayName = 'proto.UpdateGeneralExpenseCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteGeneralExpenseCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteGeneralExpenseCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteGeneralExpenseCloseoutRequest.displayName = 'proto.DeleteGeneralExpenseCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteGeneralExpenseCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteGeneralExpenseCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteGeneralExpenseCloseoutResponse.displayName = 'proto.DeleteGeneralExpenseCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateSubcontractCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateSubcontractCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateSubcontractCloseoutRequest.displayName = 'proto.CreateSubcontractCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateSubcontractCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateSubcontractCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateSubcontractCloseoutResponse.displayName = 'proto.CreateSubcontractCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetSubcontractCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetSubcontractCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetSubcontractCloseoutsRequest.displayName = 'proto.GetSubcontractCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetSubcontractCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetSubcontractCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetSubcontractCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetSubcontractCloseoutsResponse.displayName = 'proto.GetSubcontractCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateSubcontractCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateSubcontractCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateSubcontractCloseoutRequest.displayName = 'proto.UpdateSubcontractCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateSubcontractCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateSubcontractCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateSubcontractCloseoutResponse.displayName = 'proto.UpdateSubcontractCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteSubcontractCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteSubcontractCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteSubcontractCloseoutRequest.displayName = 'proto.DeleteSubcontractCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteSubcontractCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteSubcontractCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteSubcontractCloseoutResponse.displayName = 'proto.DeleteSubcontractCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateQuoteCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateQuoteCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateQuoteCloseoutRequest.displayName = 'proto.CreateQuoteCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateQuoteCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateQuoteCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateQuoteCloseoutResponse.displayName = 'proto.CreateQuoteCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetQuoteCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetQuoteCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetQuoteCloseoutsRequest.displayName = 'proto.GetQuoteCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetQuoteCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetQuoteCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetQuoteCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetQuoteCloseoutsResponse.displayName = 'proto.GetQuoteCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateQuoteCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateQuoteCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateQuoteCloseoutRequest.displayName = 'proto.UpdateQuoteCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateQuoteCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateQuoteCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateQuoteCloseoutResponse.displayName = 'proto.UpdateQuoteCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteQuoteCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteQuoteCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteQuoteCloseoutRequest.displayName = 'proto.DeleteQuoteCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteQuoteCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteQuoteCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteQuoteCloseoutResponse.displayName = 'proto.DeleteQuoteCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetTotalFinalPriceCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetTotalFinalPriceCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetTotalFinalPriceCloseoutsRequest.displayName = 'proto.GetTotalFinalPriceCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetTotalFinalPriceCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetTotalFinalPriceCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetTotalFinalPriceCloseoutsResponse.displayName = 'proto.GetTotalFinalPriceCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateTotalFinalPriceCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateTotalFinalPriceCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateTotalFinalPriceCloseoutRequest.displayName = 'proto.UpdateTotalFinalPriceCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateTotalFinalPriceCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateTotalFinalPriceCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateTotalFinalPriceCloseoutResponse.displayName = 'proto.UpdateTotalFinalPriceCloseoutResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BreakoutAllocation.prototype.toObject = function(opt_includeInstance) {
  return proto.BreakoutAllocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BreakoutAllocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BreakoutAllocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    breakoutid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    allocationpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    allocationexpense: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BreakoutAllocation}
 */
proto.BreakoutAllocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BreakoutAllocation;
  return proto.BreakoutAllocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BreakoutAllocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BreakoutAllocation}
 */
proto.BreakoutAllocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocationpercentage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocationexpense(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BreakoutAllocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BreakoutAllocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BreakoutAllocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BreakoutAllocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBreakoutid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAllocationpercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAllocationexpense();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.BreakoutAllocation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.BreakoutAllocation} returns this
 */
proto.BreakoutAllocation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.BreakoutAllocation.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BreakoutAllocation} returns this
 */
proto.BreakoutAllocation.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutId = 3;
 * @return {string}
 */
proto.BreakoutAllocation.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.BreakoutAllocation} returns this
 */
proto.BreakoutAllocation.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string breakoutId = 4;
 * @return {string}
 */
proto.BreakoutAllocation.prototype.getBreakoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.BreakoutAllocation} returns this
 */
proto.BreakoutAllocation.prototype.setBreakoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float allocationPercentage = 5;
 * @return {number}
 */
proto.BreakoutAllocation.prototype.getAllocationpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.BreakoutAllocation} returns this
 */
proto.BreakoutAllocation.prototype.setAllocationpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float allocationExpense = 6;
 * @return {number}
 */
proto.BreakoutAllocation.prototype.getAllocationexpense = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.BreakoutAllocation} returns this
 */
proto.BreakoutAllocation.prototype.setAllocationexpense = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateLaborFactoringAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateLaborFactoringAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateLaborFactoringAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateLaborFactoringAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.BreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateLaborFactoringAllocationRequest}
 */
proto.UpdateEstimateLaborFactoringAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateLaborFactoringAllocationRequest;
  return proto.UpdateEstimateLaborFactoringAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateLaborFactoringAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateLaborFactoringAllocationRequest}
 */
proto.UpdateEstimateLaborFactoringAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateLaborFactoringAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateLaborFactoringAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateLaborFactoringAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateLaborFactoringAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional BreakoutAllocation allocation = 1;
 * @return {?proto.BreakoutAllocation}
 */
proto.UpdateEstimateLaborFactoringAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.BreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.BreakoutAllocation, 1));
};


/**
 * @param {?proto.BreakoutAllocation|undefined} value
 * @return {!proto.UpdateEstimateLaborFactoringAllocationRequest} returns this
*/
proto.UpdateEstimateLaborFactoringAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateEstimateLaborFactoringAllocationRequest} returns this
 */
proto.UpdateEstimateLaborFactoringAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateEstimateLaborFactoringAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateEstimateLaborFactoringAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateEstimateLaborFactoringAllocationRequest} returns this
 */
proto.UpdateEstimateLaborFactoringAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateLaborFactoringAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateLaborFactoringAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateLaborFactoringAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateLaborFactoringAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateLaborFactoringAllocationResponse}
 */
proto.UpdateEstimateLaborFactoringAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateLaborFactoringAllocationResponse;
  return proto.UpdateEstimateLaborFactoringAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateLaborFactoringAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateLaborFactoringAllocationResponse}
 */
proto.UpdateEstimateLaborFactoringAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateLaborFactoringAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateLaborFactoringAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateLaborFactoringAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateLaborFactoringAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateEstimateLaborFactoringAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateEstimateLaborFactoringAllocationResponse} returns this
 */
proto.UpdateEstimateLaborFactoringAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateIncidentalLaborAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateIncidentalLaborAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateIncidentalLaborAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateIncidentalLaborAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.BreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateIncidentalLaborAllocationRequest}
 */
proto.UpdateEstimateIncidentalLaborAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateIncidentalLaborAllocationRequest;
  return proto.UpdateEstimateIncidentalLaborAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateIncidentalLaborAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateIncidentalLaborAllocationRequest}
 */
proto.UpdateEstimateIncidentalLaborAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateIncidentalLaborAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateIncidentalLaborAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateIncidentalLaborAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateIncidentalLaborAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional BreakoutAllocation allocation = 1;
 * @return {?proto.BreakoutAllocation}
 */
proto.UpdateEstimateIncidentalLaborAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.BreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.BreakoutAllocation, 1));
};


/**
 * @param {?proto.BreakoutAllocation|undefined} value
 * @return {!proto.UpdateEstimateIncidentalLaborAllocationRequest} returns this
*/
proto.UpdateEstimateIncidentalLaborAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateEstimateIncidentalLaborAllocationRequest} returns this
 */
proto.UpdateEstimateIncidentalLaborAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateEstimateIncidentalLaborAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateEstimateIncidentalLaborAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateEstimateIncidentalLaborAllocationRequest} returns this
 */
proto.UpdateEstimateIncidentalLaborAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateIncidentalLaborAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateIncidentalLaborAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateIncidentalLaborAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateIncidentalLaborAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateIncidentalLaborAllocationResponse}
 */
proto.UpdateEstimateIncidentalLaborAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateIncidentalLaborAllocationResponse;
  return proto.UpdateEstimateIncidentalLaborAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateIncidentalLaborAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateIncidentalLaborAllocationResponse}
 */
proto.UpdateEstimateIncidentalLaborAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateIncidentalLaborAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateIncidentalLaborAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateIncidentalLaborAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateIncidentalLaborAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateEstimateIncidentalLaborAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateEstimateIncidentalLaborAllocationResponse} returns this
 */
proto.UpdateEstimateIncidentalLaborAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateIndirectLaborAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateIndirectLaborAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateIndirectLaborAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateIndirectLaborAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.BreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateIndirectLaborAllocationRequest}
 */
proto.UpdateEstimateIndirectLaborAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateIndirectLaborAllocationRequest;
  return proto.UpdateEstimateIndirectLaborAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateIndirectLaborAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateIndirectLaborAllocationRequest}
 */
proto.UpdateEstimateIndirectLaborAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateIndirectLaborAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateIndirectLaborAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateIndirectLaborAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateIndirectLaborAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional BreakoutAllocation allocation = 1;
 * @return {?proto.BreakoutAllocation}
 */
proto.UpdateEstimateIndirectLaborAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.BreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.BreakoutAllocation, 1));
};


/**
 * @param {?proto.BreakoutAllocation|undefined} value
 * @return {!proto.UpdateEstimateIndirectLaborAllocationRequest} returns this
*/
proto.UpdateEstimateIndirectLaborAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateEstimateIndirectLaborAllocationRequest} returns this
 */
proto.UpdateEstimateIndirectLaborAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateEstimateIndirectLaborAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateEstimateIndirectLaborAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateEstimateIndirectLaborAllocationRequest} returns this
 */
proto.UpdateEstimateIndirectLaborAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateIndirectLaborAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateIndirectLaborAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateIndirectLaborAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateIndirectLaborAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateIndirectLaborAllocationResponse}
 */
proto.UpdateEstimateIndirectLaborAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateIndirectLaborAllocationResponse;
  return proto.UpdateEstimateIndirectLaborAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateIndirectLaborAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateIndirectLaborAllocationResponse}
 */
proto.UpdateEstimateIndirectLaborAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateIndirectLaborAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateIndirectLaborAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateIndirectLaborAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateIndirectLaborAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateEstimateIndirectLaborAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateEstimateIndirectLaborAllocationResponse} returns this
 */
proto.UpdateEstimateIndirectLaborAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateEquipmentAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateEquipmentAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateEquipmentAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateEquipmentAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.BreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateEquipmentAllocationRequest}
 */
proto.UpdateEstimateEquipmentAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateEquipmentAllocationRequest;
  return proto.UpdateEstimateEquipmentAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateEquipmentAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateEquipmentAllocationRequest}
 */
proto.UpdateEstimateEquipmentAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateEquipmentAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateEquipmentAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateEquipmentAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateEquipmentAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional BreakoutAllocation allocation = 1;
 * @return {?proto.BreakoutAllocation}
 */
proto.UpdateEstimateEquipmentAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.BreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.BreakoutAllocation, 1));
};


/**
 * @param {?proto.BreakoutAllocation|undefined} value
 * @return {!proto.UpdateEstimateEquipmentAllocationRequest} returns this
*/
proto.UpdateEstimateEquipmentAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateEstimateEquipmentAllocationRequest} returns this
 */
proto.UpdateEstimateEquipmentAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateEstimateEquipmentAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateEstimateEquipmentAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateEstimateEquipmentAllocationRequest} returns this
 */
proto.UpdateEstimateEquipmentAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateEquipmentAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateEquipmentAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateEquipmentAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateEquipmentAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateEquipmentAllocationResponse}
 */
proto.UpdateEstimateEquipmentAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateEquipmentAllocationResponse;
  return proto.UpdateEstimateEquipmentAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateEquipmentAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateEquipmentAllocationResponse}
 */
proto.UpdateEstimateEquipmentAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateEquipmentAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateEquipmentAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateEquipmentAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateEquipmentAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateEstimateEquipmentAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateEstimateEquipmentAllocationResponse} returns this
 */
proto.UpdateEstimateEquipmentAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateGeneralExpenseAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateGeneralExpenseAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateGeneralExpenseAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateGeneralExpenseAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.BreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateGeneralExpenseAllocationRequest}
 */
proto.UpdateEstimateGeneralExpenseAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateGeneralExpenseAllocationRequest;
  return proto.UpdateEstimateGeneralExpenseAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateGeneralExpenseAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateGeneralExpenseAllocationRequest}
 */
proto.UpdateEstimateGeneralExpenseAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateGeneralExpenseAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateGeneralExpenseAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateGeneralExpenseAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateGeneralExpenseAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional BreakoutAllocation allocation = 1;
 * @return {?proto.BreakoutAllocation}
 */
proto.UpdateEstimateGeneralExpenseAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.BreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.BreakoutAllocation, 1));
};


/**
 * @param {?proto.BreakoutAllocation|undefined} value
 * @return {!proto.UpdateEstimateGeneralExpenseAllocationRequest} returns this
*/
proto.UpdateEstimateGeneralExpenseAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateEstimateGeneralExpenseAllocationRequest} returns this
 */
proto.UpdateEstimateGeneralExpenseAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateEstimateGeneralExpenseAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateEstimateGeneralExpenseAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateEstimateGeneralExpenseAllocationRequest} returns this
 */
proto.UpdateEstimateGeneralExpenseAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateGeneralExpenseAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateGeneralExpenseAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateGeneralExpenseAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateGeneralExpenseAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateGeneralExpenseAllocationResponse}
 */
proto.UpdateEstimateGeneralExpenseAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateGeneralExpenseAllocationResponse;
  return proto.UpdateEstimateGeneralExpenseAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateGeneralExpenseAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateGeneralExpenseAllocationResponse}
 */
proto.UpdateEstimateGeneralExpenseAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateGeneralExpenseAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateGeneralExpenseAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateGeneralExpenseAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateGeneralExpenseAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateEstimateGeneralExpenseAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateEstimateGeneralExpenseAllocationResponse} returns this
 */
proto.UpdateEstimateGeneralExpenseAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateSubcontractAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateSubcontractAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateSubcontractAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateSubcontractAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.BreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateSubcontractAllocationRequest}
 */
proto.UpdateEstimateSubcontractAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateSubcontractAllocationRequest;
  return proto.UpdateEstimateSubcontractAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateSubcontractAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateSubcontractAllocationRequest}
 */
proto.UpdateEstimateSubcontractAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateSubcontractAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateSubcontractAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateSubcontractAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateSubcontractAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional BreakoutAllocation allocation = 1;
 * @return {?proto.BreakoutAllocation}
 */
proto.UpdateEstimateSubcontractAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.BreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.BreakoutAllocation, 1));
};


/**
 * @param {?proto.BreakoutAllocation|undefined} value
 * @return {!proto.UpdateEstimateSubcontractAllocationRequest} returns this
*/
proto.UpdateEstimateSubcontractAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateEstimateSubcontractAllocationRequest} returns this
 */
proto.UpdateEstimateSubcontractAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateEstimateSubcontractAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateEstimateSubcontractAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateEstimateSubcontractAllocationRequest} returns this
 */
proto.UpdateEstimateSubcontractAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateSubcontractAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateSubcontractAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateSubcontractAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateSubcontractAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateSubcontractAllocationResponse}
 */
proto.UpdateEstimateSubcontractAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateSubcontractAllocationResponse;
  return proto.UpdateEstimateSubcontractAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateSubcontractAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateSubcontractAllocationResponse}
 */
proto.UpdateEstimateSubcontractAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateSubcontractAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateSubcontractAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateSubcontractAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateSubcontractAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateEstimateSubcontractAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateEstimateSubcontractAllocationResponse} returns this
 */
proto.UpdateEstimateSubcontractAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateQuoteAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateQuoteAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateQuoteAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateQuoteAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.BreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateQuoteAllocationRequest}
 */
proto.UpdateEstimateQuoteAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateQuoteAllocationRequest;
  return proto.UpdateEstimateQuoteAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateQuoteAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateQuoteAllocationRequest}
 */
proto.UpdateEstimateQuoteAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateQuoteAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateQuoteAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateQuoteAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateQuoteAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional BreakoutAllocation allocation = 1;
 * @return {?proto.BreakoutAllocation}
 */
proto.UpdateEstimateQuoteAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.BreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.BreakoutAllocation, 1));
};


/**
 * @param {?proto.BreakoutAllocation|undefined} value
 * @return {!proto.UpdateEstimateQuoteAllocationRequest} returns this
*/
proto.UpdateEstimateQuoteAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateEstimateQuoteAllocationRequest} returns this
 */
proto.UpdateEstimateQuoteAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateEstimateQuoteAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateEstimateQuoteAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateEstimateQuoteAllocationRequest} returns this
 */
proto.UpdateEstimateQuoteAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateQuoteAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateQuoteAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateQuoteAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateQuoteAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateQuoteAllocationResponse}
 */
proto.UpdateEstimateQuoteAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateQuoteAllocationResponse;
  return proto.UpdateEstimateQuoteAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateQuoteAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateQuoteAllocationResponse}
 */
proto.UpdateEstimateQuoteAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateQuoteAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateQuoteAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateQuoteAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateQuoteAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateEstimateQuoteAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateEstimateQuoteAllocationResponse} returns this
 */
proto.UpdateEstimateQuoteAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ExtendEstimate.prototype.toObject = function(opt_includeInstance) {
  return proto.ExtendEstimate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ExtendEstimate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExtendEstimate.toObject = function(includeInstance, msg) {
  var f, obj = {
    extensionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isoverrideitemunitcost: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    overrideitemunitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    isoverridehourlyproductionrate: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    overridehourlyproductionrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalitemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    totaldirectlaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    breakoutid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    itemunitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    hourlyproductionrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    uomid: jspb.Message.getFieldWithDefault(msg, 15, 0),
    attributegroupid: jspb.Message.getFieldWithDefault(msg, 16, ""),
    attributevaluename: jspb.Message.getFieldWithDefault(msg, 17, ""),
    attributegroupname: jspb.Message.getFieldWithDefault(msg, 18, ""),
    breakoutname: jspb.Message.getFieldWithDefault(msg, 19, ""),
    itemname: jspb.Message.getFieldWithDefault(msg, 20, ""),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    itemuomvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    itemuomname: jspb.Message.getFieldWithDefault(msg, 23, ""),
    assemblyid: jspb.Message.getFieldWithDefault(msg, 24, ""),
    assemblyname: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ExtendEstimate}
 */
proto.ExtendEstimate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ExtendEstimate;
  return proto.ExtendEstimate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ExtendEstimate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ExtendEstimate}
 */
proto.ExtendEstimate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemid(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsoverrideitemunitcost(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverrideitemunitcost(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsoverridehourlyproductionrate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverridehourlyproductionrate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalitemqty(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaldirectlaborhours(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutid(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemunitcost(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHourlyproductionrate(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUomid(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupid(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributevaluename(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupname(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutname(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemname(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemuomvalue(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemuomname(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ExtendEstimate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ExtendEstimate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ExtendEstimate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExtendEstimate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtensionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getItemid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsoverrideitemunitcost();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOverrideitemunitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getIsoverridehourlyproductionrate();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getOverridehourlyproductionrate();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalitemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getTotaldirectlaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getBreakoutid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getItemunitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getHourlyproductionrate();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getUomid();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getAttributegroupid();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAttributevaluename();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getAttributegroupname();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBreakoutname();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getItemname();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getItemuomvalue();
  if (f !== 0.0) {
    writer.writeFloat(
      22,
      f
    );
  }
  f = message.getItemuomname();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getAssemblyname();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional string extensionId = 1;
 * @return {string}
 */
proto.ExtendEstimate.prototype.getExtensionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setExtensionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.ExtendEstimate.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 attributeValueId = 3;
 * @return {number}
 */
proto.ExtendEstimate.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string itemId = 4;
 * @return {string}
 */
proto.ExtendEstimate.prototype.getItemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setItemid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isOverrideItemUnitCost = 5;
 * @return {boolean}
 */
proto.ExtendEstimate.prototype.getIsoverrideitemunitcost = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setIsoverrideitemunitcost = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional float overrideItemUnitCost = 6;
 * @return {number}
 */
proto.ExtendEstimate.prototype.getOverrideitemunitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setOverrideitemunitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional bool isOverrideHourlyProductionRate = 7;
 * @return {boolean}
 */
proto.ExtendEstimate.prototype.getIsoverridehourlyproductionrate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setIsoverridehourlyproductionrate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional float overrideHourlyProductionRate = 8;
 * @return {number}
 */
proto.ExtendEstimate.prototype.getOverridehourlyproductionrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setOverridehourlyproductionrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalItemQty = 9;
 * @return {number}
 */
proto.ExtendEstimate.prototype.getTotalitemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setTotalitemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float totalCost = 10;
 * @return {number}
 */
proto.ExtendEstimate.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float totalDirectLaborHours = 11;
 * @return {number}
 */
proto.ExtendEstimate.prototype.getTotaldirectlaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setTotaldirectlaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional string breakoutId = 12;
 * @return {string}
 */
proto.ExtendEstimate.prototype.getBreakoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setBreakoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional float itemUnitCost = 13;
 * @return {number}
 */
proto.ExtendEstimate.prototype.getItemunitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setItemunitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float hourlyProductionRate = 14;
 * @return {number}
 */
proto.ExtendEstimate.prototype.getHourlyproductionrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setHourlyproductionrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional int32 uomId = 15;
 * @return {number}
 */
proto.ExtendEstimate.prototype.getUomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setUomid = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string attributeGroupId = 16;
 * @return {string}
 */
proto.ExtendEstimate.prototype.getAttributegroupid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setAttributegroupid = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string attributeValueName = 17;
 * @return {string}
 */
proto.ExtendEstimate.prototype.getAttributevaluename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setAttributevaluename = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string attributeGroupName = 18;
 * @return {string}
 */
proto.ExtendEstimate.prototype.getAttributegroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setAttributegroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string breakoutName = 19;
 * @return {string}
 */
proto.ExtendEstimate.prototype.getBreakoutname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setBreakoutname = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string itemName = 20;
 * @return {string}
 */
proto.ExtendEstimate.prototype.getItemname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setItemname = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool isQuoted = 21;
 * @return {boolean}
 */
proto.ExtendEstimate.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional float itemUOMValue = 22;
 * @return {number}
 */
proto.ExtendEstimate.prototype.getItemuomvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setItemuomvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional string itemUOMName = 23;
 * @return {string}
 */
proto.ExtendEstimate.prototype.getItemuomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setItemuomname = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string assemblyId = 24;
 * @return {string}
 */
proto.ExtendEstimate.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string assemblyName = 25;
 * @return {string}
 */
proto.ExtendEstimate.prototype.getAssemblyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendEstimate} returns this
 */
proto.ExtendEstimate.prototype.setAssemblyname = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetExtendEstimateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetExtendEstimateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetExtendEstimateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetExtendEstimateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetExtendEstimateRequest}
 */
proto.GetExtendEstimateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetExtendEstimateRequest;
  return proto.GetExtendEstimateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetExtendEstimateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetExtendEstimateRequest}
 */
proto.GetExtendEstimateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetExtendEstimateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetExtendEstimateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetExtendEstimateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetExtendEstimateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetExtendEstimateRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetExtendEstimateRequest} returns this
 */
proto.GetExtendEstimateRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetExtendEstimateRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetExtendEstimateRequest} returns this
 */
proto.GetExtendEstimateRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetExtendEstimateResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetExtendEstimateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetExtendEstimateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetExtendEstimateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetExtendEstimateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    extendestimatesList: jspb.Message.toObjectList(msg.getExtendestimatesList(),
    proto.ExtendEstimate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetExtendEstimateResponse}
 */
proto.GetExtendEstimateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetExtendEstimateResponse;
  return proto.GetExtendEstimateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetExtendEstimateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetExtendEstimateResponse}
 */
proto.GetExtendEstimateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExtendEstimate;
      reader.readMessage(value,proto.ExtendEstimate.deserializeBinaryFromReader);
      msg.addExtendestimates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetExtendEstimateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetExtendEstimateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetExtendEstimateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetExtendEstimateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtendestimatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ExtendEstimate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ExtendEstimate extendEstimates = 1;
 * @return {!Array<!proto.ExtendEstimate>}
 */
proto.GetExtendEstimateResponse.prototype.getExtendestimatesList = function() {
  return /** @type{!Array<!proto.ExtendEstimate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ExtendEstimate, 1));
};


/**
 * @param {!Array<!proto.ExtendEstimate>} value
 * @return {!proto.GetExtendEstimateResponse} returns this
*/
proto.GetExtendEstimateResponse.prototype.setExtendestimatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ExtendEstimate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ExtendEstimate}
 */
proto.GetExtendEstimateResponse.prototype.addExtendestimates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ExtendEstimate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetExtendEstimateResponse} returns this
 */
proto.GetExtendEstimateResponse.prototype.clearExtendestimatesList = function() {
  return this.setExtendestimatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UpdateExtendEstimateRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateExtendEstimateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateExtendEstimateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateExtendEstimateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateExtendEstimateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    extendestimatesList: jspb.Message.toObjectList(msg.getExtendestimatesList(),
    proto.ExtendEstimate.toObject, includeInstance),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateExtendEstimateRequest}
 */
proto.UpdateExtendEstimateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateExtendEstimateRequest;
  return proto.UpdateExtendEstimateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateExtendEstimateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateExtendEstimateRequest}
 */
proto.UpdateExtendEstimateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExtendEstimate;
      reader.readMessage(value,proto.ExtendEstimate.deserializeBinaryFromReader);
      msg.addExtendestimates(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateExtendEstimateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateExtendEstimateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateExtendEstimateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateExtendEstimateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtendestimatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ExtendEstimate.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated ExtendEstimate extendEstimates = 1;
 * @return {!Array<!proto.ExtendEstimate>}
 */
proto.UpdateExtendEstimateRequest.prototype.getExtendestimatesList = function() {
  return /** @type{!Array<!proto.ExtendEstimate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ExtendEstimate, 1));
};


/**
 * @param {!Array<!proto.ExtendEstimate>} value
 * @return {!proto.UpdateExtendEstimateRequest} returns this
*/
proto.UpdateExtendEstimateRequest.prototype.setExtendestimatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ExtendEstimate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ExtendEstimate}
 */
proto.UpdateExtendEstimateRequest.prototype.addExtendestimates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ExtendEstimate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateExtendEstimateRequest} returns this
 */
proto.UpdateExtendEstimateRequest.prototype.clearExtendestimatesList = function() {
  return this.setExtendestimatesList([]);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateExtendEstimateRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateExtendEstimateRequest} returns this
 */
proto.UpdateExtendEstimateRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateExtendEstimateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateExtendEstimateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateExtendEstimateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateExtendEstimateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateExtendEstimateResponse}
 */
proto.UpdateExtendEstimateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateExtendEstimateResponse;
  return proto.UpdateExtendEstimateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateExtendEstimateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateExtendEstimateResponse}
 */
proto.UpdateExtendEstimateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateExtendEstimateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateExtendEstimateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateExtendEstimateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateExtendEstimateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateExtendEstimateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateExtendEstimateResponse} returns this
 */
proto.UpdateExtendEstimateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DirectLaborCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.DirectLaborCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DirectLaborCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DirectLaborCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultdirectlaborid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    distributionpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 12, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 13, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    subtotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    burdentotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    fringetotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    totalhourlycost: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    totaldirectlaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DirectLaborCloseout}
 */
proto.DirectLaborCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DirectLaborCloseout;
  return proto.DirectLaborCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DirectLaborCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DirectLaborCloseout}
 */
proto.DirectLaborCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultdirectlaborid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistributionpercent(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSubtotalcost(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdentotalcost(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringetotalcost(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalhourlycost(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaldirectlaborhours(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DirectLaborCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DirectLaborCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DirectLaborCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DirectLaborCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultdirectlaborid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getDistributionpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getSubtotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getBurdentotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getFringetotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getTotalhourlycost();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getTotaldirectlaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.DirectLaborCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.DirectLaborCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultDirectLaborId = 3;
 * @return {string}
 */
proto.DirectLaborCloseout.prototype.getCloseoutdefaultdirectlaborid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setCloseoutdefaultdirectlaborid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.DirectLaborCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float laborRate = 5;
 * @return {number}
 */
proto.DirectLaborCloseout.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float burdenPercent = 6;
 * @return {number}
 */
proto.DirectLaborCloseout.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float fringe = 7;
 * @return {number}
 */
proto.DirectLaborCloseout.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float distributionPercent = 8;
 * @return {number}
 */
proto.DirectLaborCloseout.prototype.getDistributionpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setDistributionpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string createdByUserId = 9;
 * @return {string}
 */
proto.DirectLaborCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string createdByAccountId = 10;
 * @return {string}
 */
proto.DirectLaborCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string dateCreated = 11;
 * @return {string}
 */
proto.DirectLaborCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string lastUpdated = 12;
 * @return {string}
 */
proto.DirectLaborCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string lastUpdatedByUserId = 13;
 * @return {string}
 */
proto.DirectLaborCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional float allocatedHours = 14;
 * @return {number}
 */
proto.DirectLaborCloseout.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float subtotalCost = 15;
 * @return {number}
 */
proto.DirectLaborCloseout.prototype.getSubtotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setSubtotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float burdenTotalCost = 16;
 * @return {number}
 */
proto.DirectLaborCloseout.prototype.getBurdentotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setBurdentotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float fringeTotalCost = 17;
 * @return {number}
 */
proto.DirectLaborCloseout.prototype.getFringetotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setFringetotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float totalHourlyCost = 18;
 * @return {number}
 */
proto.DirectLaborCloseout.prototype.getTotalhourlycost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setTotalhourlycost = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float totalCost = 19;
 * @return {number}
 */
proto.DirectLaborCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional float totalDirectLaborHours = 20;
 * @return {number}
 */
proto.DirectLaborCloseout.prototype.getTotaldirectlaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborCloseout} returns this
 */
proto.DirectLaborCloseout.prototype.setTotaldirectlaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LaborFactoringCloseout.repeatedFields_ = [22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LaborFactoringCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.LaborFactoringCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LaborFactoringCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LaborFactoringCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultlaborfactoringid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    impactpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    laborpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 12, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 13, ""),
    name: jspb.Message.getFieldWithDefault(msg, 14, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    subtotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    burdentotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    fringetotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    totalhourlycost: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    totaldirectlaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.BreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LaborFactoringCloseout}
 */
proto.LaborFactoringCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LaborFactoringCloseout;
  return proto.LaborFactoringCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LaborFactoringCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LaborFactoringCloseout}
 */
proto.LaborFactoringCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultlaborfactoringid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setImpactpercent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborpercent(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSubtotalcost(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdentotalcost(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringetotalcost(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalhourlycost(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaldirectlaborhours(value);
      break;
    case 22:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LaborFactoringCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LaborFactoringCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LaborFactoringCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LaborFactoringCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultlaborfactoringid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getImpactpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getLaborpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getSubtotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getBurdentotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getFringetotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getTotalhourlycost();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getTotaldirectlaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      21,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.LaborFactoringCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.LaborFactoringCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultLaborFactoringId = 3;
 * @return {string}
 */
proto.LaborFactoringCloseout.prototype.getCloseoutdefaultlaborfactoringid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setCloseoutdefaultlaborfactoringid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float laborRate = 4;
 * @return {number}
 */
proto.LaborFactoringCloseout.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float burdenPercent = 5;
 * @return {number}
 */
proto.LaborFactoringCloseout.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float fringe = 6;
 * @return {number}
 */
proto.LaborFactoringCloseout.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float impactPercent = 7;
 * @return {number}
 */
proto.LaborFactoringCloseout.prototype.getImpactpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setImpactpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float laborPercent = 8;
 * @return {number}
 */
proto.LaborFactoringCloseout.prototype.getLaborpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setLaborpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string createdByUserId = 9;
 * @return {string}
 */
proto.LaborFactoringCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string createdByAccountId = 10;
 * @return {string}
 */
proto.LaborFactoringCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string dateCreated = 11;
 * @return {string}
 */
proto.LaborFactoringCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string lastUpdated = 12;
 * @return {string}
 */
proto.LaborFactoringCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string lastUpdatedByUserId = 13;
 * @return {string}
 */
proto.LaborFactoringCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string name = 14;
 * @return {string}
 */
proto.LaborFactoringCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional float allocatedHours = 15;
 * @return {number}
 */
proto.LaborFactoringCloseout.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float subtotalCost = 16;
 * @return {number}
 */
proto.LaborFactoringCloseout.prototype.getSubtotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setSubtotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float burdenTotalCost = 17;
 * @return {number}
 */
proto.LaborFactoringCloseout.prototype.getBurdentotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setBurdentotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float fringeTotalCost = 18;
 * @return {number}
 */
proto.LaborFactoringCloseout.prototype.getFringetotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setFringetotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float totalHourlyCost = 19;
 * @return {number}
 */
proto.LaborFactoringCloseout.prototype.getTotalhourlycost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setTotalhourlycost = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional float totalCost = 20;
 * @return {number}
 */
proto.LaborFactoringCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional float totalDirectLaborHours = 21;
 * @return {number}
 */
proto.LaborFactoringCloseout.prototype.getTotaldirectlaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.setTotaldirectlaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * repeated BreakoutAllocation allocation = 22;
 * @return {!Array<!proto.BreakoutAllocation>}
 */
proto.LaborFactoringCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.BreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BreakoutAllocation, 22));
};


/**
 * @param {!Array<!proto.BreakoutAllocation>} value
 * @return {!proto.LaborFactoringCloseout} returns this
*/
proto.LaborFactoringCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.BreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BreakoutAllocation}
 */
proto.LaborFactoringCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.BreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LaborFactoringCloseout} returns this
 */
proto.LaborFactoringCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IncidentalLaborCloseout.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IncidentalLaborCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.IncidentalLaborCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IncidentalLaborCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IncidentalLaborCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultincidentallaborid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 10, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    name: jspb.Message.getFieldWithDefault(msg, 13, ""),
    subtotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    burdentotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    fringetotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    totalhourlycost: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    totaldirectlaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.BreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IncidentalLaborCloseout}
 */
proto.IncidentalLaborCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IncidentalLaborCloseout;
  return proto.IncidentalLaborCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IncidentalLaborCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IncidentalLaborCloseout}
 */
proto.IncidentalLaborCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultincidentallaborid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSubtotalcost(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdentotalcost(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringetotalcost(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalhourlycost(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaldirectlaborhours(value);
      break;
    case 20:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IncidentalLaborCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IncidentalLaborCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IncidentalLaborCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IncidentalLaborCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultincidentallaborid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSubtotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getBurdentotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getFringetotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getTotalhourlycost();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getTotaldirectlaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.IncidentalLaborCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.IncidentalLaborCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultIncidentalLaborId = 3;
 * @return {string}
 */
proto.IncidentalLaborCloseout.prototype.getCloseoutdefaultincidentallaborid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setCloseoutdefaultincidentallaborid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float allocatedHours = 4;
 * @return {number}
 */
proto.IncidentalLaborCloseout.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float laborRate = 5;
 * @return {number}
 */
proto.IncidentalLaborCloseout.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float burdenPercent = 6;
 * @return {number}
 */
proto.IncidentalLaborCloseout.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float fringe = 7;
 * @return {number}
 */
proto.IncidentalLaborCloseout.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string createdByUserId = 8;
 * @return {string}
 */
proto.IncidentalLaborCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string createdByAccountId = 9;
 * @return {string}
 */
proto.IncidentalLaborCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string dateCreated = 10;
 * @return {string}
 */
proto.IncidentalLaborCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string lastUpdated = 11;
 * @return {string}
 */
proto.IncidentalLaborCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string lastUpdatedByUserId = 12;
 * @return {string}
 */
proto.IncidentalLaborCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string name = 13;
 * @return {string}
 */
proto.IncidentalLaborCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional float subtotalCost = 14;
 * @return {number}
 */
proto.IncidentalLaborCloseout.prototype.getSubtotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setSubtotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float burdenTotalCost = 15;
 * @return {number}
 */
proto.IncidentalLaborCloseout.prototype.getBurdentotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setBurdentotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float fringeTotalCost = 16;
 * @return {number}
 */
proto.IncidentalLaborCloseout.prototype.getFringetotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setFringetotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float totalHourlyCost = 17;
 * @return {number}
 */
proto.IncidentalLaborCloseout.prototype.getTotalhourlycost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setTotalhourlycost = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float totalCost = 18;
 * @return {number}
 */
proto.IncidentalLaborCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float totalDirectLaborHours = 19;
 * @return {number}
 */
proto.IncidentalLaborCloseout.prototype.getTotaldirectlaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.setTotaldirectlaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * repeated BreakoutAllocation allocation = 20;
 * @return {!Array<!proto.BreakoutAllocation>}
 */
proto.IncidentalLaborCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.BreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BreakoutAllocation, 20));
};


/**
 * @param {!Array<!proto.BreakoutAllocation>} value
 * @return {!proto.IncidentalLaborCloseout} returns this
*/
proto.IncidentalLaborCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.BreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BreakoutAllocation}
 */
proto.IncidentalLaborCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.BreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IncidentalLaborCloseout} returns this
 */
proto.IncidentalLaborCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IndirectLaborCloseout.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndirectLaborCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.IndirectLaborCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndirectLaborCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndirectLaborCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultindirectlaborid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    percentageofdirectlabor: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 10, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    name: jspb.Message.getFieldWithDefault(msg, 13, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    subtotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    burdentotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    fringetotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    totalhourlycost: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    totaldirectlaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.BreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndirectLaborCloseout}
 */
proto.IndirectLaborCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndirectLaborCloseout;
  return proto.IndirectLaborCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndirectLaborCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndirectLaborCloseout}
 */
proto.IndirectLaborCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultindirectlaborid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPercentageofdirectlabor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSubtotalcost(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdentotalcost(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringetotalcost(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalhourlycost(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaldirectlaborhours(value);
      break;
    case 21:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndirectLaborCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndirectLaborCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndirectLaborCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndirectLaborCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultindirectlaborid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPercentageofdirectlabor();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getSubtotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getBurdentotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getFringetotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getTotalhourlycost();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getTotaldirectlaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.IndirectLaborCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.IndirectLaborCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultIndirectLaborId = 3;
 * @return {string}
 */
proto.IndirectLaborCloseout.prototype.getCloseoutdefaultindirectlaborid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setCloseoutdefaultindirectlaborid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float percentageOfDirectLabor = 4;
 * @return {number}
 */
proto.IndirectLaborCloseout.prototype.getPercentageofdirectlabor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setPercentageofdirectlabor = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float laborRate = 5;
 * @return {number}
 */
proto.IndirectLaborCloseout.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float burdenPercent = 6;
 * @return {number}
 */
proto.IndirectLaborCloseout.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float fringe = 7;
 * @return {number}
 */
proto.IndirectLaborCloseout.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string createdByUserId = 8;
 * @return {string}
 */
proto.IndirectLaborCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string createdByAccountId = 9;
 * @return {string}
 */
proto.IndirectLaborCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string dateCreated = 10;
 * @return {string}
 */
proto.IndirectLaborCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string lastUpdated = 11;
 * @return {string}
 */
proto.IndirectLaborCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string lastUpdatedByUserId = 12;
 * @return {string}
 */
proto.IndirectLaborCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string name = 13;
 * @return {string}
 */
proto.IndirectLaborCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional float allocatedHours = 14;
 * @return {number}
 */
proto.IndirectLaborCloseout.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float subtotalCost = 15;
 * @return {number}
 */
proto.IndirectLaborCloseout.prototype.getSubtotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setSubtotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float burdenTotalCost = 16;
 * @return {number}
 */
proto.IndirectLaborCloseout.prototype.getBurdentotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setBurdentotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float fringeTotalCost = 17;
 * @return {number}
 */
proto.IndirectLaborCloseout.prototype.getFringetotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setFringetotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float totalHourlyCost = 18;
 * @return {number}
 */
proto.IndirectLaborCloseout.prototype.getTotalhourlycost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setTotalhourlycost = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float totalCost = 19;
 * @return {number}
 */
proto.IndirectLaborCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional float totalDirectLaborHours = 20;
 * @return {number}
 */
proto.IndirectLaborCloseout.prototype.getTotaldirectlaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.setTotaldirectlaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * repeated BreakoutAllocation allocation = 21;
 * @return {!Array<!proto.BreakoutAllocation>}
 */
proto.IndirectLaborCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.BreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BreakoutAllocation, 21));
};


/**
 * @param {!Array<!proto.BreakoutAllocation>} value
 * @return {!proto.IndirectLaborCloseout} returns this
*/
proto.IndirectLaborCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.BreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BreakoutAllocation}
 */
proto.IndirectLaborCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.BreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IndirectLaborCloseout} returns this
 */
proto.IndirectLaborCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EquipmentCloseout.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EquipmentCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.EquipmentCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EquipmentCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultequipmentid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    name: jspb.Message.getFieldWithDefault(msg, 10, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    unitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.BreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EquipmentCloseout}
 */
proto.EquipmentCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EquipmentCloseout;
  return proto.EquipmentCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EquipmentCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EquipmentCloseout}
 */
proto.EquipmentCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultequipmentid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuantity(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDuration(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUnitcost(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 15:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EquipmentCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EquipmentCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EquipmentCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultequipmentid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getUnitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.EquipmentCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.EquipmentCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultEquipmentId = 3;
 * @return {string}
 */
proto.EquipmentCloseout.prototype.getCloseoutdefaultequipmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.setCloseoutdefaultequipmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string createdByUserId = 5;
 * @return {string}
 */
proto.EquipmentCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string createdByAccountId = 6;
 * @return {string}
 */
proto.EquipmentCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string dateCreated = 7;
 * @return {string}
 */
proto.EquipmentCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string lastUpdated = 8;
 * @return {string}
 */
proto.EquipmentCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string lastUpdatedByUserId = 9;
 * @return {string}
 */
proto.EquipmentCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string name = 10;
 * @return {string}
 */
proto.EquipmentCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional float quantity = 11;
 * @return {number}
 */
proto.EquipmentCloseout.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float duration = 12;
 * @return {number}
 */
proto.EquipmentCloseout.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float unitCost = 13;
 * @return {number}
 */
proto.EquipmentCloseout.prototype.getUnitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.setUnitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float totalCost = 14;
 * @return {number}
 */
proto.EquipmentCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * repeated BreakoutAllocation allocation = 15;
 * @return {!Array<!proto.BreakoutAllocation>}
 */
proto.EquipmentCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.BreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BreakoutAllocation, 15));
};


/**
 * @param {!Array<!proto.BreakoutAllocation>} value
 * @return {!proto.EquipmentCloseout} returns this
*/
proto.EquipmentCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.BreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BreakoutAllocation}
 */
proto.EquipmentCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.BreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EquipmentCloseout} returns this
 */
proto.EquipmentCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GeneralExpenseCloseout.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GeneralExpenseCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.GeneralExpenseCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GeneralExpenseCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GeneralExpenseCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultgeneralexpenseid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    quantitymultiplier: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    unitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 10, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    name: jspb.Message.getFieldWithDefault(msg, 12, ""),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.BreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GeneralExpenseCloseout}
 */
proto.GeneralExpenseCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GeneralExpenseCloseout;
  return proto.GeneralExpenseCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GeneralExpenseCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GeneralExpenseCloseout}
 */
proto.GeneralExpenseCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultgeneralexpenseid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuantity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuantitymultiplier(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUnitcost(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 14:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GeneralExpenseCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GeneralExpenseCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GeneralExpenseCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GeneralExpenseCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultgeneralexpenseid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getQuantitymultiplier();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getUnitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.GeneralExpenseCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.GeneralExpenseCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultGeneralExpenseId = 3;
 * @return {string}
 */
proto.GeneralExpenseCloseout.prototype.getCloseoutdefaultgeneralexpenseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.setCloseoutdefaultgeneralexpenseid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float quantity = 4;
 * @return {number}
 */
proto.GeneralExpenseCloseout.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float quantityMultiplier = 5;
 * @return {number}
 */
proto.GeneralExpenseCloseout.prototype.getQuantitymultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.setQuantitymultiplier = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float unitCost = 6;
 * @return {number}
 */
proto.GeneralExpenseCloseout.prototype.getUnitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.setUnitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string createdByUserId = 7;
 * @return {string}
 */
proto.GeneralExpenseCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string createdByAccountId = 8;
 * @return {string}
 */
proto.GeneralExpenseCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string dateCreated = 9;
 * @return {string}
 */
proto.GeneralExpenseCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string lastUpdated = 10;
 * @return {string}
 */
proto.GeneralExpenseCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string lastUpdatedByUserId = 11;
 * @return {string}
 */
proto.GeneralExpenseCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string name = 12;
 * @return {string}
 */
proto.GeneralExpenseCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional float totalCost = 13;
 * @return {number}
 */
proto.GeneralExpenseCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * repeated BreakoutAllocation allocation = 14;
 * @return {!Array<!proto.BreakoutAllocation>}
 */
proto.GeneralExpenseCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.BreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BreakoutAllocation, 14));
};


/**
 * @param {!Array<!proto.BreakoutAllocation>} value
 * @return {!proto.GeneralExpenseCloseout} returns this
*/
proto.GeneralExpenseCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.BreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BreakoutAllocation}
 */
proto.GeneralExpenseCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.BreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GeneralExpenseCloseout} returns this
 */
proto.GeneralExpenseCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SubcontractCloseout.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SubcontractCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.SubcontractCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SubcontractCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubcontractCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultsubcontractid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quotedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    adjustedpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    name: jspb.Message.getFieldWithDefault(msg, 11, ""),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.BreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SubcontractCloseout}
 */
proto.SubcontractCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SubcontractCloseout;
  return proto.SubcontractCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SubcontractCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SubcontractCloseout}
 */
proto.SubcontractCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultsubcontractid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuotedcost(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedpercent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 15:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SubcontractCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SubcontractCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SubcontractCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubcontractCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultsubcontractid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuotedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getAdjustedpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.SubcontractCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractCloseout} returns this
 */
proto.SubcontractCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.SubcontractCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractCloseout} returns this
 */
proto.SubcontractCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultSubcontractId = 3;
 * @return {string}
 */
proto.SubcontractCloseout.prototype.getCloseoutdefaultsubcontractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractCloseout} returns this
 */
proto.SubcontractCloseout.prototype.setCloseoutdefaultsubcontractid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float quotedCost = 4;
 * @return {number}
 */
proto.SubcontractCloseout.prototype.getQuotedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractCloseout} returns this
 */
proto.SubcontractCloseout.prototype.setQuotedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float adjustedPercent = 5;
 * @return {number}
 */
proto.SubcontractCloseout.prototype.getAdjustedpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractCloseout} returns this
 */
proto.SubcontractCloseout.prototype.setAdjustedpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string createdByUserId = 6;
 * @return {string}
 */
proto.SubcontractCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractCloseout} returns this
 */
proto.SubcontractCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string createdByAccountId = 7;
 * @return {string}
 */
proto.SubcontractCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractCloseout} returns this
 */
proto.SubcontractCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dateCreated = 8;
 * @return {string}
 */
proto.SubcontractCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractCloseout} returns this
 */
proto.SubcontractCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string lastUpdated = 9;
 * @return {string}
 */
proto.SubcontractCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractCloseout} returns this
 */
proto.SubcontractCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string lastUpdatedByUserId = 10;
 * @return {string}
 */
proto.SubcontractCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractCloseout} returns this
 */
proto.SubcontractCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string name = 11;
 * @return {string}
 */
proto.SubcontractCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractCloseout} returns this
 */
proto.SubcontractCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional float totalCost = 14;
 * @return {number}
 */
proto.SubcontractCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractCloseout} returns this
 */
proto.SubcontractCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * repeated BreakoutAllocation allocation = 15;
 * @return {!Array<!proto.BreakoutAllocation>}
 */
proto.SubcontractCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.BreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BreakoutAllocation, 15));
};


/**
 * @param {!Array<!proto.BreakoutAllocation>} value
 * @return {!proto.SubcontractCloseout} returns this
*/
proto.SubcontractCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.BreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BreakoutAllocation}
 */
proto.SubcontractCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.BreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SubcontractCloseout} returns this
 */
proto.SubcontractCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.QuoteCloseout.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.QuoteCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.QuoteCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.QuoteCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuoteCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultquoteid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quotedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    adjustedpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    name: jspb.Message.getFieldWithDefault(msg, 11, ""),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.BreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.QuoteCloseout}
 */
proto.QuoteCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.QuoteCloseout;
  return proto.QuoteCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.QuoteCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.QuoteCloseout}
 */
proto.QuoteCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultquoteid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuotedcost(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedpercent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 13:
      var value = new proto.BreakoutAllocation;
      reader.readMessage(value,proto.BreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.QuoteCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.QuoteCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.QuoteCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuoteCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultquoteid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuotedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getAdjustedpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.BreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.QuoteCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteCloseout} returns this
 */
proto.QuoteCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.QuoteCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteCloseout} returns this
 */
proto.QuoteCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultQuoteId = 3;
 * @return {string}
 */
proto.QuoteCloseout.prototype.getCloseoutdefaultquoteid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteCloseout} returns this
 */
proto.QuoteCloseout.prototype.setCloseoutdefaultquoteid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float quotedCost = 4;
 * @return {number}
 */
proto.QuoteCloseout.prototype.getQuotedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteCloseout} returns this
 */
proto.QuoteCloseout.prototype.setQuotedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float adjustedPercent = 5;
 * @return {number}
 */
proto.QuoteCloseout.prototype.getAdjustedpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteCloseout} returns this
 */
proto.QuoteCloseout.prototype.setAdjustedpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string createdByUserId = 6;
 * @return {string}
 */
proto.QuoteCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteCloseout} returns this
 */
proto.QuoteCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string createdByAccountId = 7;
 * @return {string}
 */
proto.QuoteCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteCloseout} returns this
 */
proto.QuoteCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dateCreated = 8;
 * @return {string}
 */
proto.QuoteCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteCloseout} returns this
 */
proto.QuoteCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string lastUpdated = 9;
 * @return {string}
 */
proto.QuoteCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteCloseout} returns this
 */
proto.QuoteCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string lastUpdatedByUserId = 10;
 * @return {string}
 */
proto.QuoteCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteCloseout} returns this
 */
proto.QuoteCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string name = 11;
 * @return {string}
 */
proto.QuoteCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteCloseout} returns this
 */
proto.QuoteCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional float totalCost = 12;
 * @return {number}
 */
proto.QuoteCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteCloseout} returns this
 */
proto.QuoteCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * repeated BreakoutAllocation allocation = 13;
 * @return {!Array<!proto.BreakoutAllocation>}
 */
proto.QuoteCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.BreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BreakoutAllocation, 13));
};


/**
 * @param {!Array<!proto.BreakoutAllocation>} value
 * @return {!proto.QuoteCloseout} returns this
*/
proto.QuoteCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.BreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BreakoutAllocation}
 */
proto.QuoteCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.BreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.QuoteCloseout} returns this
 */
proto.QuoteCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TotalFinalPriceCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.TotalFinalPriceCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TotalFinalPriceCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TotalFinalPriceCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalfinalpricecloseoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    extendestimate: (f = msg.getExtendestimate()) && proto.ExtendEstimateToFinalPrice.toObject(includeInstance, f),
    directlaborcloseout: (f = msg.getDirectlaborcloseout()) && proto.DirectLaborToFinalPrice.toObject(includeInstance, f),
    laborfactoringcloseout: (f = msg.getLaborfactoringcloseout()) && proto.LaborFactoringToFinalPrice.toObject(includeInstance, f),
    incidentallaborcloseout: (f = msg.getIncidentallaborcloseout()) && proto.IncidentalLaborToFinalPrice.toObject(includeInstance, f),
    indirectlaborcloseout: (f = msg.getIndirectlaborcloseout()) && proto.IndirectLaborToFinalPrice.toObject(includeInstance, f),
    equipmentcloseout: (f = msg.getEquipmentcloseout()) && proto.EquipmentToFinalPrice.toObject(includeInstance, f),
    generalexpensecloseout: (f = msg.getGeneralexpensecloseout()) && proto.GeneralExpenseToFinalPrice.toObject(includeInstance, f),
    subcontractcloseout: (f = msg.getSubcontractcloseout()) && proto.SubcontractToFinalPrice.toObject(includeInstance, f),
    quotecloseout: (f = msg.getQuotecloseout()) && proto.QuoteToFinalPrice.toObject(includeInstance, f),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    totaloverhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    totalmargin: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    totalprofit: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    totalfinalprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    sellingprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 18, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 19, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 20, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 21, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TotalFinalPriceCloseout}
 */
proto.TotalFinalPriceCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TotalFinalPriceCloseout;
  return proto.TotalFinalPriceCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TotalFinalPriceCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TotalFinalPriceCloseout}
 */
proto.TotalFinalPriceCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalfinalpricecloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = new proto.ExtendEstimateToFinalPrice;
      reader.readMessage(value,proto.ExtendEstimateToFinalPrice.deserializeBinaryFromReader);
      msg.setExtendestimate(value);
      break;
    case 4:
      var value = new proto.DirectLaborToFinalPrice;
      reader.readMessage(value,proto.DirectLaborToFinalPrice.deserializeBinaryFromReader);
      msg.setDirectlaborcloseout(value);
      break;
    case 5:
      var value = new proto.LaborFactoringToFinalPrice;
      reader.readMessage(value,proto.LaborFactoringToFinalPrice.deserializeBinaryFromReader);
      msg.setLaborfactoringcloseout(value);
      break;
    case 6:
      var value = new proto.IncidentalLaborToFinalPrice;
      reader.readMessage(value,proto.IncidentalLaborToFinalPrice.deserializeBinaryFromReader);
      msg.setIncidentallaborcloseout(value);
      break;
    case 7:
      var value = new proto.IndirectLaborToFinalPrice;
      reader.readMessage(value,proto.IndirectLaborToFinalPrice.deserializeBinaryFromReader);
      msg.setIndirectlaborcloseout(value);
      break;
    case 8:
      var value = new proto.EquipmentToFinalPrice;
      reader.readMessage(value,proto.EquipmentToFinalPrice.deserializeBinaryFromReader);
      msg.setEquipmentcloseout(value);
      break;
    case 9:
      var value = new proto.GeneralExpenseToFinalPrice;
      reader.readMessage(value,proto.GeneralExpenseToFinalPrice.deserializeBinaryFromReader);
      msg.setGeneralexpensecloseout(value);
      break;
    case 10:
      var value = new proto.SubcontractToFinalPrice;
      reader.readMessage(value,proto.SubcontractToFinalPrice.deserializeBinaryFromReader);
      msg.setSubcontractcloseout(value);
      break;
    case 11:
      var value = new proto.QuoteToFinalPrice;
      reader.readMessage(value,proto.QuoteToFinalPrice.deserializeBinaryFromReader);
      msg.setQuotecloseout(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaloverhead(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalmargin(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalprofit(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalfinalprice(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSellingprice(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TotalFinalPriceCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TotalFinalPriceCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TotalFinalPriceCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TotalFinalPriceCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalfinalpricecloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExtendestimate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ExtendEstimateToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getDirectlaborcloseout();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.DirectLaborToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getLaborfactoringcloseout();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.LaborFactoringToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getIncidentallaborcloseout();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.IncidentalLaborToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getIndirectlaborcloseout();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.IndirectLaborToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentcloseout();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.EquipmentToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getGeneralexpensecloseout();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.GeneralExpenseToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getSubcontractcloseout();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.SubcontractToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getQuotecloseout();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.QuoteToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getTotaloverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getTotalmargin();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getTotalprofit();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getTotalfinalprice();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getSellingprice();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional string totalFinalPriceCloseoutId = 1;
 * @return {string}
 */
proto.TotalFinalPriceCloseout.prototype.getTotalfinalpricecloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.setTotalfinalpricecloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.TotalFinalPriceCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ExtendEstimateToFinalPrice extendEstimate = 3;
 * @return {?proto.ExtendEstimateToFinalPrice}
 */
proto.TotalFinalPriceCloseout.prototype.getExtendestimate = function() {
  return /** @type{?proto.ExtendEstimateToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.ExtendEstimateToFinalPrice, 3));
};


/**
 * @param {?proto.ExtendEstimateToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
*/
proto.TotalFinalPriceCloseout.prototype.setExtendestimate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.clearExtendestimate = function() {
  return this.setExtendestimate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceCloseout.prototype.hasExtendestimate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DirectLaborToFinalPrice directLaborCloseout = 4;
 * @return {?proto.DirectLaborToFinalPrice}
 */
proto.TotalFinalPriceCloseout.prototype.getDirectlaborcloseout = function() {
  return /** @type{?proto.DirectLaborToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.DirectLaborToFinalPrice, 4));
};


/**
 * @param {?proto.DirectLaborToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
*/
proto.TotalFinalPriceCloseout.prototype.setDirectlaborcloseout = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.clearDirectlaborcloseout = function() {
  return this.setDirectlaborcloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceCloseout.prototype.hasDirectlaborcloseout = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional LaborFactoringToFinalPrice laborFactoringCloseout = 5;
 * @return {?proto.LaborFactoringToFinalPrice}
 */
proto.TotalFinalPriceCloseout.prototype.getLaborfactoringcloseout = function() {
  return /** @type{?proto.LaborFactoringToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.LaborFactoringToFinalPrice, 5));
};


/**
 * @param {?proto.LaborFactoringToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
*/
proto.TotalFinalPriceCloseout.prototype.setLaborfactoringcloseout = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.clearLaborfactoringcloseout = function() {
  return this.setLaborfactoringcloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceCloseout.prototype.hasLaborfactoringcloseout = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional IncidentalLaborToFinalPrice incidentalLaborCloseout = 6;
 * @return {?proto.IncidentalLaborToFinalPrice}
 */
proto.TotalFinalPriceCloseout.prototype.getIncidentallaborcloseout = function() {
  return /** @type{?proto.IncidentalLaborToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.IncidentalLaborToFinalPrice, 6));
};


/**
 * @param {?proto.IncidentalLaborToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
*/
proto.TotalFinalPriceCloseout.prototype.setIncidentallaborcloseout = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.clearIncidentallaborcloseout = function() {
  return this.setIncidentallaborcloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceCloseout.prototype.hasIncidentallaborcloseout = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional IndirectLaborToFinalPrice indirectLaborCloseout = 7;
 * @return {?proto.IndirectLaborToFinalPrice}
 */
proto.TotalFinalPriceCloseout.prototype.getIndirectlaborcloseout = function() {
  return /** @type{?proto.IndirectLaborToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.IndirectLaborToFinalPrice, 7));
};


/**
 * @param {?proto.IndirectLaborToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
*/
proto.TotalFinalPriceCloseout.prototype.setIndirectlaborcloseout = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.clearIndirectlaborcloseout = function() {
  return this.setIndirectlaborcloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceCloseout.prototype.hasIndirectlaborcloseout = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EquipmentToFinalPrice equipmentCloseout = 8;
 * @return {?proto.EquipmentToFinalPrice}
 */
proto.TotalFinalPriceCloseout.prototype.getEquipmentcloseout = function() {
  return /** @type{?proto.EquipmentToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.EquipmentToFinalPrice, 8));
};


/**
 * @param {?proto.EquipmentToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
*/
proto.TotalFinalPriceCloseout.prototype.setEquipmentcloseout = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.clearEquipmentcloseout = function() {
  return this.setEquipmentcloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceCloseout.prototype.hasEquipmentcloseout = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional GeneralExpenseToFinalPrice generalExpenseCloseout = 9;
 * @return {?proto.GeneralExpenseToFinalPrice}
 */
proto.TotalFinalPriceCloseout.prototype.getGeneralexpensecloseout = function() {
  return /** @type{?proto.GeneralExpenseToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.GeneralExpenseToFinalPrice, 9));
};


/**
 * @param {?proto.GeneralExpenseToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
*/
proto.TotalFinalPriceCloseout.prototype.setGeneralexpensecloseout = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.clearGeneralexpensecloseout = function() {
  return this.setGeneralexpensecloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceCloseout.prototype.hasGeneralexpensecloseout = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional SubcontractToFinalPrice subcontractCloseout = 10;
 * @return {?proto.SubcontractToFinalPrice}
 */
proto.TotalFinalPriceCloseout.prototype.getSubcontractcloseout = function() {
  return /** @type{?proto.SubcontractToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.SubcontractToFinalPrice, 10));
};


/**
 * @param {?proto.SubcontractToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
*/
proto.TotalFinalPriceCloseout.prototype.setSubcontractcloseout = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.clearSubcontractcloseout = function() {
  return this.setSubcontractcloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceCloseout.prototype.hasSubcontractcloseout = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional QuoteToFinalPrice quoteCloseout = 11;
 * @return {?proto.QuoteToFinalPrice}
 */
proto.TotalFinalPriceCloseout.prototype.getQuotecloseout = function() {
  return /** @type{?proto.QuoteToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.QuoteToFinalPrice, 11));
};


/**
 * @param {?proto.QuoteToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
*/
proto.TotalFinalPriceCloseout.prototype.setQuotecloseout = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.clearQuotecloseout = function() {
  return this.setQuotecloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceCloseout.prototype.hasQuotecloseout = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional float totalCost = 12;
 * @return {number}
 */
proto.TotalFinalPriceCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float totalOverhead = 13;
 * @return {number}
 */
proto.TotalFinalPriceCloseout.prototype.getTotaloverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.setTotaloverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float totalMargin = 14;
 * @return {number}
 */
proto.TotalFinalPriceCloseout.prototype.getTotalmargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.setTotalmargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float totalProfit = 15;
 * @return {number}
 */
proto.TotalFinalPriceCloseout.prototype.getTotalprofit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.setTotalprofit = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float totalFinalPrice = 16;
 * @return {number}
 */
proto.TotalFinalPriceCloseout.prototype.getTotalfinalprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.setTotalfinalprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float sellingPrice = 17;
 * @return {number}
 */
proto.TotalFinalPriceCloseout.prototype.getSellingprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.setSellingprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional string createdByUserId = 18;
 * @return {string}
 */
proto.TotalFinalPriceCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string createdByAccountId = 19;
 * @return {string}
 */
proto.TotalFinalPriceCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string dateCreated = 20;
 * @return {string}
 */
proto.TotalFinalPriceCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string lastUpdated = 21;
 * @return {string}
 */
proto.TotalFinalPriceCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string lastUpdatedByUserId = 22;
 * @return {string}
 */
proto.TotalFinalPriceCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceCloseout} returns this
 */
proto.TotalFinalPriceCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ExtendEstimateToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ExtendEstimateToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.ExtendEstimateToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ExtendEstimateToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExtendEstimateToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    extendestimateList: jspb.Message.toObjectList(msg.getExtendestimateList(),
    proto.ExtendEstimate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ExtendEstimateToFinalPrice}
 */
proto.ExtendEstimateToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ExtendEstimateToFinalPrice;
  return proto.ExtendEstimateToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ExtendEstimateToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ExtendEstimateToFinalPrice}
 */
proto.ExtendEstimateToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.ExtendEstimate;
      reader.readMessage(value,proto.ExtendEstimate.deserializeBinaryFromReader);
      msg.addExtendestimate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ExtendEstimateToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ExtendEstimateToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ExtendEstimateToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExtendEstimateToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getExtendestimateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.ExtendEstimate.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.ExtendEstimateToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimateToFinalPrice} returns this
 */
proto.ExtendEstimateToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.ExtendEstimateToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimateToFinalPrice} returns this
 */
proto.ExtendEstimateToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.ExtendEstimateToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimateToFinalPrice} returns this
 */
proto.ExtendEstimateToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.ExtendEstimateToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimateToFinalPrice} returns this
 */
proto.ExtendEstimateToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.ExtendEstimateToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimateToFinalPrice} returns this
 */
proto.ExtendEstimateToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.ExtendEstimateToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimateToFinalPrice} returns this
 */
proto.ExtendEstimateToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.ExtendEstimateToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendEstimateToFinalPrice} returns this
 */
proto.ExtendEstimateToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated ExtendEstimate extendEstimate = 10;
 * @return {!Array<!proto.ExtendEstimate>}
 */
proto.ExtendEstimateToFinalPrice.prototype.getExtendestimateList = function() {
  return /** @type{!Array<!proto.ExtendEstimate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ExtendEstimate, 10));
};


/**
 * @param {!Array<!proto.ExtendEstimate>} value
 * @return {!proto.ExtendEstimateToFinalPrice} returns this
*/
proto.ExtendEstimateToFinalPrice.prototype.setExtendestimateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.ExtendEstimate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ExtendEstimate}
 */
proto.ExtendEstimateToFinalPrice.prototype.addExtendestimate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.ExtendEstimate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ExtendEstimateToFinalPrice} returns this
 */
proto.ExtendEstimateToFinalPrice.prototype.clearExtendestimateList = function() {
  return this.setExtendestimateList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DirectLaborToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DirectLaborToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.DirectLaborToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DirectLaborToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DirectLaborToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    directlaborcloseoutList: jspb.Message.toObjectList(msg.getDirectlaborcloseoutList(),
    proto.DirectLaborCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DirectLaborToFinalPrice}
 */
proto.DirectLaborToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DirectLaborToFinalPrice;
  return proto.DirectLaborToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DirectLaborToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DirectLaborToFinalPrice}
 */
proto.DirectLaborToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.DirectLaborCloseout;
      reader.readMessage(value,proto.DirectLaborCloseout.deserializeBinaryFromReader);
      msg.addDirectlaborcloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DirectLaborToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DirectLaborToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DirectLaborToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DirectLaborToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getDirectlaborcloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.DirectLaborCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.DirectLaborToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborToFinalPrice} returns this
 */
proto.DirectLaborToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.DirectLaborToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborToFinalPrice} returns this
 */
proto.DirectLaborToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.DirectLaborToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborToFinalPrice} returns this
 */
proto.DirectLaborToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.DirectLaborToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborToFinalPrice} returns this
 */
proto.DirectLaborToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.DirectLaborToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborToFinalPrice} returns this
 */
proto.DirectLaborToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.DirectLaborToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborToFinalPrice} returns this
 */
proto.DirectLaborToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.DirectLaborToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborToFinalPrice} returns this
 */
proto.DirectLaborToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated DirectLaborCloseout directLaborCloseout = 10;
 * @return {!Array<!proto.DirectLaborCloseout>}
 */
proto.DirectLaborToFinalPrice.prototype.getDirectlaborcloseoutList = function() {
  return /** @type{!Array<!proto.DirectLaborCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DirectLaborCloseout, 10));
};


/**
 * @param {!Array<!proto.DirectLaborCloseout>} value
 * @return {!proto.DirectLaborToFinalPrice} returns this
*/
proto.DirectLaborToFinalPrice.prototype.setDirectlaborcloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.DirectLaborCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DirectLaborCloseout}
 */
proto.DirectLaborToFinalPrice.prototype.addDirectlaborcloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.DirectLaborCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DirectLaborToFinalPrice} returns this
 */
proto.DirectLaborToFinalPrice.prototype.clearDirectlaborcloseoutList = function() {
  return this.setDirectlaborcloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LaborFactoringToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LaborFactoringToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.LaborFactoringToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LaborFactoringToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LaborFactoringToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    laborfactoringcloseoutList: jspb.Message.toObjectList(msg.getLaborfactoringcloseoutList(),
    proto.LaborFactoringCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LaborFactoringToFinalPrice}
 */
proto.LaborFactoringToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LaborFactoringToFinalPrice;
  return proto.LaborFactoringToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LaborFactoringToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LaborFactoringToFinalPrice}
 */
proto.LaborFactoringToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.LaborFactoringCloseout;
      reader.readMessage(value,proto.LaborFactoringCloseout.deserializeBinaryFromReader);
      msg.addLaborfactoringcloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LaborFactoringToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LaborFactoringToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LaborFactoringToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LaborFactoringToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getLaborfactoringcloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.LaborFactoringCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.LaborFactoringToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringToFinalPrice} returns this
 */
proto.LaborFactoringToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.LaborFactoringToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringToFinalPrice} returns this
 */
proto.LaborFactoringToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.LaborFactoringToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringToFinalPrice} returns this
 */
proto.LaborFactoringToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.LaborFactoringToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringToFinalPrice} returns this
 */
proto.LaborFactoringToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.LaborFactoringToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringToFinalPrice} returns this
 */
proto.LaborFactoringToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.LaborFactoringToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringToFinalPrice} returns this
 */
proto.LaborFactoringToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.LaborFactoringToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringToFinalPrice} returns this
 */
proto.LaborFactoringToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated LaborFactoringCloseout laborFactoringCloseout = 10;
 * @return {!Array<!proto.LaborFactoringCloseout>}
 */
proto.LaborFactoringToFinalPrice.prototype.getLaborfactoringcloseoutList = function() {
  return /** @type{!Array<!proto.LaborFactoringCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.LaborFactoringCloseout, 10));
};


/**
 * @param {!Array<!proto.LaborFactoringCloseout>} value
 * @return {!proto.LaborFactoringToFinalPrice} returns this
*/
proto.LaborFactoringToFinalPrice.prototype.setLaborfactoringcloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.LaborFactoringCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LaborFactoringCloseout}
 */
proto.LaborFactoringToFinalPrice.prototype.addLaborfactoringcloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.LaborFactoringCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LaborFactoringToFinalPrice} returns this
 */
proto.LaborFactoringToFinalPrice.prototype.clearLaborfactoringcloseoutList = function() {
  return this.setLaborfactoringcloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IncidentalLaborToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IncidentalLaborToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.IncidentalLaborToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IncidentalLaborToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IncidentalLaborToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    incidentallaborcloseoutList: jspb.Message.toObjectList(msg.getIncidentallaborcloseoutList(),
    proto.IncidentalLaborCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IncidentalLaborToFinalPrice}
 */
proto.IncidentalLaborToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IncidentalLaborToFinalPrice;
  return proto.IncidentalLaborToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IncidentalLaborToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IncidentalLaborToFinalPrice}
 */
proto.IncidentalLaborToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.IncidentalLaborCloseout;
      reader.readMessage(value,proto.IncidentalLaborCloseout.deserializeBinaryFromReader);
      msg.addIncidentallaborcloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IncidentalLaborToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IncidentalLaborToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IncidentalLaborToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IncidentalLaborToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getIncidentallaborcloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.IncidentalLaborCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.IncidentalLaborToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborToFinalPrice} returns this
 */
proto.IncidentalLaborToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.IncidentalLaborToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborToFinalPrice} returns this
 */
proto.IncidentalLaborToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.IncidentalLaborToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborToFinalPrice} returns this
 */
proto.IncidentalLaborToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.IncidentalLaborToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborToFinalPrice} returns this
 */
proto.IncidentalLaborToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.IncidentalLaborToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborToFinalPrice} returns this
 */
proto.IncidentalLaborToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.IncidentalLaborToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborToFinalPrice} returns this
 */
proto.IncidentalLaborToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.IncidentalLaborToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborToFinalPrice} returns this
 */
proto.IncidentalLaborToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated IncidentalLaborCloseout incidentalLaborCloseout = 10;
 * @return {!Array<!proto.IncidentalLaborCloseout>}
 */
proto.IncidentalLaborToFinalPrice.prototype.getIncidentallaborcloseoutList = function() {
  return /** @type{!Array<!proto.IncidentalLaborCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IncidentalLaborCloseout, 10));
};


/**
 * @param {!Array<!proto.IncidentalLaborCloseout>} value
 * @return {!proto.IncidentalLaborToFinalPrice} returns this
*/
proto.IncidentalLaborToFinalPrice.prototype.setIncidentallaborcloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.IncidentalLaborCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IncidentalLaborCloseout}
 */
proto.IncidentalLaborToFinalPrice.prototype.addIncidentallaborcloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.IncidentalLaborCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IncidentalLaborToFinalPrice} returns this
 */
proto.IncidentalLaborToFinalPrice.prototype.clearIncidentallaborcloseoutList = function() {
  return this.setIncidentallaborcloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IndirectLaborToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndirectLaborToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.IndirectLaborToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndirectLaborToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndirectLaborToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    indirectlaborcloseoutList: jspb.Message.toObjectList(msg.getIndirectlaborcloseoutList(),
    proto.IndirectLaborCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndirectLaborToFinalPrice}
 */
proto.IndirectLaborToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndirectLaborToFinalPrice;
  return proto.IndirectLaborToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndirectLaborToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndirectLaborToFinalPrice}
 */
proto.IndirectLaborToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.IndirectLaborCloseout;
      reader.readMessage(value,proto.IndirectLaborCloseout.deserializeBinaryFromReader);
      msg.addIndirectlaborcloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndirectLaborToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndirectLaborToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndirectLaborToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndirectLaborToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getIndirectlaborcloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.IndirectLaborCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.IndirectLaborToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborToFinalPrice} returns this
 */
proto.IndirectLaborToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.IndirectLaborToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborToFinalPrice} returns this
 */
proto.IndirectLaborToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.IndirectLaborToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborToFinalPrice} returns this
 */
proto.IndirectLaborToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.IndirectLaborToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborToFinalPrice} returns this
 */
proto.IndirectLaborToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.IndirectLaborToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborToFinalPrice} returns this
 */
proto.IndirectLaborToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.IndirectLaborToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborToFinalPrice} returns this
 */
proto.IndirectLaborToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.IndirectLaborToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborToFinalPrice} returns this
 */
proto.IndirectLaborToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated IndirectLaborCloseout indirectLaborCloseout = 10;
 * @return {!Array<!proto.IndirectLaborCloseout>}
 */
proto.IndirectLaborToFinalPrice.prototype.getIndirectlaborcloseoutList = function() {
  return /** @type{!Array<!proto.IndirectLaborCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IndirectLaborCloseout, 10));
};


/**
 * @param {!Array<!proto.IndirectLaborCloseout>} value
 * @return {!proto.IndirectLaborToFinalPrice} returns this
*/
proto.IndirectLaborToFinalPrice.prototype.setIndirectlaborcloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.IndirectLaborCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IndirectLaborCloseout}
 */
proto.IndirectLaborToFinalPrice.prototype.addIndirectlaborcloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.IndirectLaborCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IndirectLaborToFinalPrice} returns this
 */
proto.IndirectLaborToFinalPrice.prototype.clearIndirectlaborcloseoutList = function() {
  return this.setIndirectlaborcloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EquipmentToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EquipmentToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.EquipmentToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EquipmentToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    equipmentcloseoutList: jspb.Message.toObjectList(msg.getEquipmentcloseoutList(),
    proto.EquipmentCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EquipmentToFinalPrice}
 */
proto.EquipmentToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EquipmentToFinalPrice;
  return proto.EquipmentToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EquipmentToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EquipmentToFinalPrice}
 */
proto.EquipmentToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.EquipmentCloseout;
      reader.readMessage(value,proto.EquipmentCloseout.deserializeBinaryFromReader);
      msg.addEquipmentcloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EquipmentToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EquipmentToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EquipmentToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getEquipmentcloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.EquipmentCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.EquipmentToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentToFinalPrice} returns this
 */
proto.EquipmentToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.EquipmentToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentToFinalPrice} returns this
 */
proto.EquipmentToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.EquipmentToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentToFinalPrice} returns this
 */
proto.EquipmentToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.EquipmentToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentToFinalPrice} returns this
 */
proto.EquipmentToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.EquipmentToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentToFinalPrice} returns this
 */
proto.EquipmentToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.EquipmentToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentToFinalPrice} returns this
 */
proto.EquipmentToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.EquipmentToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentToFinalPrice} returns this
 */
proto.EquipmentToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated EquipmentCloseout equipmentCloseout = 10;
 * @return {!Array<!proto.EquipmentCloseout>}
 */
proto.EquipmentToFinalPrice.prototype.getEquipmentcloseoutList = function() {
  return /** @type{!Array<!proto.EquipmentCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EquipmentCloseout, 10));
};


/**
 * @param {!Array<!proto.EquipmentCloseout>} value
 * @return {!proto.EquipmentToFinalPrice} returns this
*/
proto.EquipmentToFinalPrice.prototype.setEquipmentcloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.EquipmentCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EquipmentCloseout}
 */
proto.EquipmentToFinalPrice.prototype.addEquipmentcloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.EquipmentCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EquipmentToFinalPrice} returns this
 */
proto.EquipmentToFinalPrice.prototype.clearEquipmentcloseoutList = function() {
  return this.setEquipmentcloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GeneralExpenseToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GeneralExpenseToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.GeneralExpenseToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GeneralExpenseToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GeneralExpenseToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    generalexpensecloseoutList: jspb.Message.toObjectList(msg.getGeneralexpensecloseoutList(),
    proto.GeneralExpenseCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GeneralExpenseToFinalPrice}
 */
proto.GeneralExpenseToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GeneralExpenseToFinalPrice;
  return proto.GeneralExpenseToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GeneralExpenseToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GeneralExpenseToFinalPrice}
 */
proto.GeneralExpenseToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.GeneralExpenseCloseout;
      reader.readMessage(value,proto.GeneralExpenseCloseout.deserializeBinaryFromReader);
      msg.addGeneralexpensecloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GeneralExpenseToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GeneralExpenseToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GeneralExpenseToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GeneralExpenseToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getGeneralexpensecloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.GeneralExpenseCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.GeneralExpenseToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseToFinalPrice} returns this
 */
proto.GeneralExpenseToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.GeneralExpenseToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseToFinalPrice} returns this
 */
proto.GeneralExpenseToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.GeneralExpenseToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseToFinalPrice} returns this
 */
proto.GeneralExpenseToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.GeneralExpenseToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseToFinalPrice} returns this
 */
proto.GeneralExpenseToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.GeneralExpenseToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseToFinalPrice} returns this
 */
proto.GeneralExpenseToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.GeneralExpenseToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseToFinalPrice} returns this
 */
proto.GeneralExpenseToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.GeneralExpenseToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseToFinalPrice} returns this
 */
proto.GeneralExpenseToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated GeneralExpenseCloseout generalExpenseCloseout = 10;
 * @return {!Array<!proto.GeneralExpenseCloseout>}
 */
proto.GeneralExpenseToFinalPrice.prototype.getGeneralexpensecloseoutList = function() {
  return /** @type{!Array<!proto.GeneralExpenseCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GeneralExpenseCloseout, 10));
};


/**
 * @param {!Array<!proto.GeneralExpenseCloseout>} value
 * @return {!proto.GeneralExpenseToFinalPrice} returns this
*/
proto.GeneralExpenseToFinalPrice.prototype.setGeneralexpensecloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.GeneralExpenseCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GeneralExpenseCloseout}
 */
proto.GeneralExpenseToFinalPrice.prototype.addGeneralexpensecloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.GeneralExpenseCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GeneralExpenseToFinalPrice} returns this
 */
proto.GeneralExpenseToFinalPrice.prototype.clearGeneralexpensecloseoutList = function() {
  return this.setGeneralexpensecloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SubcontractToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SubcontractToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.SubcontractToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SubcontractToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubcontractToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    subcontractcloseoutList: jspb.Message.toObjectList(msg.getSubcontractcloseoutList(),
    proto.SubcontractCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SubcontractToFinalPrice}
 */
proto.SubcontractToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SubcontractToFinalPrice;
  return proto.SubcontractToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SubcontractToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SubcontractToFinalPrice}
 */
proto.SubcontractToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.SubcontractCloseout;
      reader.readMessage(value,proto.SubcontractCloseout.deserializeBinaryFromReader);
      msg.addSubcontractcloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SubcontractToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SubcontractToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SubcontractToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubcontractToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getSubcontractcloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.SubcontractCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.SubcontractToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractToFinalPrice} returns this
 */
proto.SubcontractToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.SubcontractToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractToFinalPrice} returns this
 */
proto.SubcontractToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.SubcontractToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractToFinalPrice} returns this
 */
proto.SubcontractToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.SubcontractToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractToFinalPrice} returns this
 */
proto.SubcontractToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.SubcontractToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractToFinalPrice} returns this
 */
proto.SubcontractToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.SubcontractToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractToFinalPrice} returns this
 */
proto.SubcontractToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.SubcontractToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractToFinalPrice} returns this
 */
proto.SubcontractToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated SubcontractCloseout subcontractCloseout = 10;
 * @return {!Array<!proto.SubcontractCloseout>}
 */
proto.SubcontractToFinalPrice.prototype.getSubcontractcloseoutList = function() {
  return /** @type{!Array<!proto.SubcontractCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SubcontractCloseout, 10));
};


/**
 * @param {!Array<!proto.SubcontractCloseout>} value
 * @return {!proto.SubcontractToFinalPrice} returns this
*/
proto.SubcontractToFinalPrice.prototype.setSubcontractcloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.SubcontractCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SubcontractCloseout}
 */
proto.SubcontractToFinalPrice.prototype.addSubcontractcloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.SubcontractCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SubcontractToFinalPrice} returns this
 */
proto.SubcontractToFinalPrice.prototype.clearSubcontractcloseoutList = function() {
  return this.setSubcontractcloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.QuoteToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.QuoteToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.QuoteToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.QuoteToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuoteToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    quotecloseoutList: jspb.Message.toObjectList(msg.getQuotecloseoutList(),
    proto.QuoteCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.QuoteToFinalPrice}
 */
proto.QuoteToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.QuoteToFinalPrice;
  return proto.QuoteToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.QuoteToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.QuoteToFinalPrice}
 */
proto.QuoteToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.QuoteCloseout;
      reader.readMessage(value,proto.QuoteCloseout.deserializeBinaryFromReader);
      msg.addQuotecloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.QuoteToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.QuoteToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.QuoteToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuoteToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getQuotecloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.QuoteCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.QuoteToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteToFinalPrice} returns this
 */
proto.QuoteToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.QuoteToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteToFinalPrice} returns this
 */
proto.QuoteToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.QuoteToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteToFinalPrice} returns this
 */
proto.QuoteToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.QuoteToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteToFinalPrice} returns this
 */
proto.QuoteToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.QuoteToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteToFinalPrice} returns this
 */
proto.QuoteToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.QuoteToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteToFinalPrice} returns this
 */
proto.QuoteToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.QuoteToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteToFinalPrice} returns this
 */
proto.QuoteToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated QuoteCloseout quoteCloseout = 10;
 * @return {!Array<!proto.QuoteCloseout>}
 */
proto.QuoteToFinalPrice.prototype.getQuotecloseoutList = function() {
  return /** @type{!Array<!proto.QuoteCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.QuoteCloseout, 10));
};


/**
 * @param {!Array<!proto.QuoteCloseout>} value
 * @return {!proto.QuoteToFinalPrice} returns this
*/
proto.QuoteToFinalPrice.prototype.setQuotecloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.QuoteCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.QuoteCloseout}
 */
proto.QuoteToFinalPrice.prototype.addQuotecloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.QuoteCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.QuoteToFinalPrice} returns this
 */
proto.QuoteToFinalPrice.prototype.clearQuotecloseoutList = function() {
  return this.setQuotecloseoutList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateDirectLaborCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateDirectLaborCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateDirectLaborCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateDirectLaborCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.DirectLaborCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateDirectLaborCloseoutRequest}
 */
proto.CreateDirectLaborCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateDirectLaborCloseoutRequest;
  return proto.CreateDirectLaborCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateDirectLaborCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateDirectLaborCloseoutRequest}
 */
proto.CreateDirectLaborCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DirectLaborCloseout;
      reader.readMessage(value,proto.DirectLaborCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateDirectLaborCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateDirectLaborCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateDirectLaborCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateDirectLaborCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DirectLaborCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional DirectLaborCloseout closeout = 1;
 * @return {?proto.DirectLaborCloseout}
 */
proto.CreateDirectLaborCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.DirectLaborCloseout} */ (
    jspb.Message.getWrapperField(this, proto.DirectLaborCloseout, 1));
};


/**
 * @param {?proto.DirectLaborCloseout|undefined} value
 * @return {!proto.CreateDirectLaborCloseoutRequest} returns this
*/
proto.CreateDirectLaborCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateDirectLaborCloseoutRequest} returns this
 */
proto.CreateDirectLaborCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateDirectLaborCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 7;
 * @return {string}
 */
proto.CreateDirectLaborCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateDirectLaborCloseoutRequest} returns this
 */
proto.CreateDirectLaborCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateDirectLaborCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateDirectLaborCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateDirectLaborCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateDirectLaborCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateDirectLaborCloseoutResponse}
 */
proto.CreateDirectLaborCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateDirectLaborCloseoutResponse;
  return proto.CreateDirectLaborCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateDirectLaborCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateDirectLaborCloseoutResponse}
 */
proto.CreateDirectLaborCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateDirectLaborCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateDirectLaborCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateDirectLaborCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateDirectLaborCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateDirectLaborCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateDirectLaborCloseoutResponse} returns this
 */
proto.CreateDirectLaborCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDirectLaborCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDirectLaborCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDirectLaborCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDirectLaborCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDirectLaborCloseoutsRequest}
 */
proto.GetDirectLaborCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDirectLaborCloseoutsRequest;
  return proto.GetDirectLaborCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDirectLaborCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDirectLaborCloseoutsRequest}
 */
proto.GetDirectLaborCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDirectLaborCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDirectLaborCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDirectLaborCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDirectLaborCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetDirectLaborCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDirectLaborCloseoutsRequest} returns this
 */
proto.GetDirectLaborCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetDirectLaborCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDirectLaborCloseoutsRequest} returns this
 */
proto.GetDirectLaborCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetDirectLaborCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDirectLaborCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDirectLaborCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDirectLaborCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDirectLaborCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.DirectLaborCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDirectLaborCloseoutsResponse}
 */
proto.GetDirectLaborCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDirectLaborCloseoutsResponse;
  return proto.GetDirectLaborCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDirectLaborCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDirectLaborCloseoutsResponse}
 */
proto.GetDirectLaborCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DirectLaborCloseout;
      reader.readMessage(value,proto.DirectLaborCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDirectLaborCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDirectLaborCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDirectLaborCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDirectLaborCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DirectLaborCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DirectLaborCloseout closeouts = 1;
 * @return {!Array<!proto.DirectLaborCloseout>}
 */
proto.GetDirectLaborCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.DirectLaborCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DirectLaborCloseout, 1));
};


/**
 * @param {!Array<!proto.DirectLaborCloseout>} value
 * @return {!proto.GetDirectLaborCloseoutsResponse} returns this
*/
proto.GetDirectLaborCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DirectLaborCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DirectLaborCloseout}
 */
proto.GetDirectLaborCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DirectLaborCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetDirectLaborCloseoutsResponse} returns this
 */
proto.GetDirectLaborCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateDirectLaborCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateDirectLaborCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateDirectLaborCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDirectLaborCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.DirectLaborCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateDirectLaborCloseoutRequest}
 */
proto.UpdateDirectLaborCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateDirectLaborCloseoutRequest;
  return proto.UpdateDirectLaborCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateDirectLaborCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateDirectLaborCloseoutRequest}
 */
proto.UpdateDirectLaborCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DirectLaborCloseout;
      reader.readMessage(value,proto.DirectLaborCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateDirectLaborCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateDirectLaborCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateDirectLaborCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDirectLaborCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DirectLaborCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DirectLaborCloseout closeout = 1;
 * @return {?proto.DirectLaborCloseout}
 */
proto.UpdateDirectLaborCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.DirectLaborCloseout} */ (
    jspb.Message.getWrapperField(this, proto.DirectLaborCloseout, 1));
};


/**
 * @param {?proto.DirectLaborCloseout|undefined} value
 * @return {!proto.UpdateDirectLaborCloseoutRequest} returns this
*/
proto.UpdateDirectLaborCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateDirectLaborCloseoutRequest} returns this
 */
proto.UpdateDirectLaborCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateDirectLaborCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateDirectLaborCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateDirectLaborCloseoutRequest} returns this
 */
proto.UpdateDirectLaborCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateDirectLaborCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateDirectLaborCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateDirectLaborCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDirectLaborCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateDirectLaborCloseoutResponse}
 */
proto.UpdateDirectLaborCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateDirectLaborCloseoutResponse;
  return proto.UpdateDirectLaborCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateDirectLaborCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateDirectLaborCloseoutResponse}
 */
proto.UpdateDirectLaborCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateDirectLaborCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateDirectLaborCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateDirectLaborCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDirectLaborCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateDirectLaborCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateDirectLaborCloseoutResponse} returns this
 */
proto.UpdateDirectLaborCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDirectLaborCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDirectLaborCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDirectLaborCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDirectLaborCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDirectLaborCloseoutRequest}
 */
proto.DeleteDirectLaborCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDirectLaborCloseoutRequest;
  return proto.DeleteDirectLaborCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDirectLaborCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDirectLaborCloseoutRequest}
 */
proto.DeleteDirectLaborCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDirectLaborCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDirectLaborCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDirectLaborCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDirectLaborCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteDirectLaborCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDirectLaborCloseoutRequest} returns this
 */
proto.DeleteDirectLaborCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteDirectLaborCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDirectLaborCloseoutRequest} returns this
 */
proto.DeleteDirectLaborCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDirectLaborCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDirectLaborCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDirectLaborCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDirectLaborCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDirectLaborCloseoutResponse}
 */
proto.DeleteDirectLaborCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDirectLaborCloseoutResponse;
  return proto.DeleteDirectLaborCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDirectLaborCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDirectLaborCloseoutResponse}
 */
proto.DeleteDirectLaborCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDirectLaborCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDirectLaborCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDirectLaborCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDirectLaborCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteDirectLaborCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteDirectLaborCloseoutResponse} returns this
 */
proto.DeleteDirectLaborCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateLaborFactoringCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateLaborFactoringCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateLaborFactoringCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateLaborFactoringCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.LaborFactoringCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateLaborFactoringCloseoutRequest}
 */
proto.CreateLaborFactoringCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateLaborFactoringCloseoutRequest;
  return proto.CreateLaborFactoringCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateLaborFactoringCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateLaborFactoringCloseoutRequest}
 */
proto.CreateLaborFactoringCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.LaborFactoringCloseout;
      reader.readMessage(value,proto.LaborFactoringCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateLaborFactoringCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateLaborFactoringCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateLaborFactoringCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateLaborFactoringCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.LaborFactoringCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional LaborFactoringCloseout closeout = 1;
 * @return {?proto.LaborFactoringCloseout}
 */
proto.CreateLaborFactoringCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.LaborFactoringCloseout} */ (
    jspb.Message.getWrapperField(this, proto.LaborFactoringCloseout, 1));
};


/**
 * @param {?proto.LaborFactoringCloseout|undefined} value
 * @return {!proto.CreateLaborFactoringCloseoutRequest} returns this
*/
proto.CreateLaborFactoringCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateLaborFactoringCloseoutRequest} returns this
 */
proto.CreateLaborFactoringCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateLaborFactoringCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 8;
 * @return {string}
 */
proto.CreateLaborFactoringCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateLaborFactoringCloseoutRequest} returns this
 */
proto.CreateLaborFactoringCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateLaborFactoringCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateLaborFactoringCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateLaborFactoringCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateLaborFactoringCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateLaborFactoringCloseoutResponse}
 */
proto.CreateLaborFactoringCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateLaborFactoringCloseoutResponse;
  return proto.CreateLaborFactoringCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateLaborFactoringCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateLaborFactoringCloseoutResponse}
 */
proto.CreateLaborFactoringCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateLaborFactoringCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateLaborFactoringCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateLaborFactoringCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateLaborFactoringCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateLaborFactoringCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateLaborFactoringCloseoutResponse} returns this
 */
proto.CreateLaborFactoringCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetLaborFactoringCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetLaborFactoringCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetLaborFactoringCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLaborFactoringCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetLaborFactoringCloseoutsRequest}
 */
proto.GetLaborFactoringCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetLaborFactoringCloseoutsRequest;
  return proto.GetLaborFactoringCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetLaborFactoringCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetLaborFactoringCloseoutsRequest}
 */
proto.GetLaborFactoringCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetLaborFactoringCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetLaborFactoringCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetLaborFactoringCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLaborFactoringCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetLaborFactoringCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetLaborFactoringCloseoutsRequest} returns this
 */
proto.GetLaborFactoringCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetLaborFactoringCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetLaborFactoringCloseoutsRequest} returns this
 */
proto.GetLaborFactoringCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetLaborFactoringCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetLaborFactoringCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetLaborFactoringCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetLaborFactoringCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLaborFactoringCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.LaborFactoringCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetLaborFactoringCloseoutsResponse}
 */
proto.GetLaborFactoringCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetLaborFactoringCloseoutsResponse;
  return proto.GetLaborFactoringCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetLaborFactoringCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetLaborFactoringCloseoutsResponse}
 */
proto.GetLaborFactoringCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.LaborFactoringCloseout;
      reader.readMessage(value,proto.LaborFactoringCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetLaborFactoringCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetLaborFactoringCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetLaborFactoringCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLaborFactoringCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.LaborFactoringCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LaborFactoringCloseout closeouts = 1;
 * @return {!Array<!proto.LaborFactoringCloseout>}
 */
proto.GetLaborFactoringCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.LaborFactoringCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.LaborFactoringCloseout, 1));
};


/**
 * @param {!Array<!proto.LaborFactoringCloseout>} value
 * @return {!proto.GetLaborFactoringCloseoutsResponse} returns this
*/
proto.GetLaborFactoringCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.LaborFactoringCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LaborFactoringCloseout}
 */
proto.GetLaborFactoringCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.LaborFactoringCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetLaborFactoringCloseoutsResponse} returns this
 */
proto.GetLaborFactoringCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateLaborFactoringCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateLaborFactoringCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateLaborFactoringCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateLaborFactoringCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.LaborFactoringCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateLaborFactoringCloseoutRequest}
 */
proto.UpdateLaborFactoringCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateLaborFactoringCloseoutRequest;
  return proto.UpdateLaborFactoringCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateLaborFactoringCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateLaborFactoringCloseoutRequest}
 */
proto.UpdateLaborFactoringCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.LaborFactoringCloseout;
      reader.readMessage(value,proto.LaborFactoringCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateLaborFactoringCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateLaborFactoringCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateLaborFactoringCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateLaborFactoringCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.LaborFactoringCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional LaborFactoringCloseout closeout = 1;
 * @return {?proto.LaborFactoringCloseout}
 */
proto.UpdateLaborFactoringCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.LaborFactoringCloseout} */ (
    jspb.Message.getWrapperField(this, proto.LaborFactoringCloseout, 1));
};


/**
 * @param {?proto.LaborFactoringCloseout|undefined} value
 * @return {!proto.UpdateLaborFactoringCloseoutRequest} returns this
*/
proto.UpdateLaborFactoringCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateLaborFactoringCloseoutRequest} returns this
 */
proto.UpdateLaborFactoringCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateLaborFactoringCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateLaborFactoringCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateLaborFactoringCloseoutRequest} returns this
 */
proto.UpdateLaborFactoringCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateLaborFactoringCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateLaborFactoringCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateLaborFactoringCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateLaborFactoringCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateLaborFactoringCloseoutResponse}
 */
proto.UpdateLaborFactoringCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateLaborFactoringCloseoutResponse;
  return proto.UpdateLaborFactoringCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateLaborFactoringCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateLaborFactoringCloseoutResponse}
 */
proto.UpdateLaborFactoringCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateLaborFactoringCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateLaborFactoringCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateLaborFactoringCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateLaborFactoringCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateLaborFactoringCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateLaborFactoringCloseoutResponse} returns this
 */
proto.UpdateLaborFactoringCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteLaborFactoringCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteLaborFactoringCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteLaborFactoringCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteLaborFactoringCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteLaborFactoringCloseoutRequest}
 */
proto.DeleteLaborFactoringCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteLaborFactoringCloseoutRequest;
  return proto.DeleteLaborFactoringCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteLaborFactoringCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteLaborFactoringCloseoutRequest}
 */
proto.DeleteLaborFactoringCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteLaborFactoringCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteLaborFactoringCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteLaborFactoringCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteLaborFactoringCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteLaborFactoringCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteLaborFactoringCloseoutRequest} returns this
 */
proto.DeleteLaborFactoringCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteLaborFactoringCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteLaborFactoringCloseoutRequest} returns this
 */
proto.DeleteLaborFactoringCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteLaborFactoringCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteLaborFactoringCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteLaborFactoringCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteLaborFactoringCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteLaborFactoringCloseoutResponse}
 */
proto.DeleteLaborFactoringCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteLaborFactoringCloseoutResponse;
  return proto.DeleteLaborFactoringCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteLaborFactoringCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteLaborFactoringCloseoutResponse}
 */
proto.DeleteLaborFactoringCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteLaborFactoringCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteLaborFactoringCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteLaborFactoringCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteLaborFactoringCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteLaborFactoringCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteLaborFactoringCloseoutResponse} returns this
 */
proto.DeleteLaborFactoringCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateIncidentalLaborCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateIncidentalLaborCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateIncidentalLaborCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIncidentalLaborCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.IncidentalLaborCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateIncidentalLaborCloseoutRequest}
 */
proto.CreateIncidentalLaborCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateIncidentalLaborCloseoutRequest;
  return proto.CreateIncidentalLaborCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateIncidentalLaborCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateIncidentalLaborCloseoutRequest}
 */
proto.CreateIncidentalLaborCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IncidentalLaborCloseout;
      reader.readMessage(value,proto.IncidentalLaborCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateIncidentalLaborCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateIncidentalLaborCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateIncidentalLaborCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIncidentalLaborCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.IncidentalLaborCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional IncidentalLaborCloseout closeout = 1;
 * @return {?proto.IncidentalLaborCloseout}
 */
proto.CreateIncidentalLaborCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.IncidentalLaborCloseout} */ (
    jspb.Message.getWrapperField(this, proto.IncidentalLaborCloseout, 1));
};


/**
 * @param {?proto.IncidentalLaborCloseout|undefined} value
 * @return {!proto.CreateIncidentalLaborCloseoutRequest} returns this
*/
proto.CreateIncidentalLaborCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateIncidentalLaborCloseoutRequest} returns this
 */
proto.CreateIncidentalLaborCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateIncidentalLaborCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 6;
 * @return {string}
 */
proto.CreateIncidentalLaborCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateIncidentalLaborCloseoutRequest} returns this
 */
proto.CreateIncidentalLaborCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateIncidentalLaborCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateIncidentalLaborCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateIncidentalLaborCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIncidentalLaborCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateIncidentalLaborCloseoutResponse}
 */
proto.CreateIncidentalLaborCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateIncidentalLaborCloseoutResponse;
  return proto.CreateIncidentalLaborCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateIncidentalLaborCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateIncidentalLaborCloseoutResponse}
 */
proto.CreateIncidentalLaborCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateIncidentalLaborCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateIncidentalLaborCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateIncidentalLaborCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIncidentalLaborCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateIncidentalLaborCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateIncidentalLaborCloseoutResponse} returns this
 */
proto.CreateIncidentalLaborCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetIncidentalLaborCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetIncidentalLaborCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetIncidentalLaborCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIncidentalLaborCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetIncidentalLaborCloseoutsRequest}
 */
proto.GetIncidentalLaborCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetIncidentalLaborCloseoutsRequest;
  return proto.GetIncidentalLaborCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetIncidentalLaborCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetIncidentalLaborCloseoutsRequest}
 */
proto.GetIncidentalLaborCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetIncidentalLaborCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetIncidentalLaborCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetIncidentalLaborCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIncidentalLaborCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetIncidentalLaborCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetIncidentalLaborCloseoutsRequest} returns this
 */
proto.GetIncidentalLaborCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetIncidentalLaborCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetIncidentalLaborCloseoutsRequest} returns this
 */
proto.GetIncidentalLaborCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetIncidentalLaborCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetIncidentalLaborCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetIncidentalLaborCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetIncidentalLaborCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIncidentalLaborCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.IncidentalLaborCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetIncidentalLaborCloseoutsResponse}
 */
proto.GetIncidentalLaborCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetIncidentalLaborCloseoutsResponse;
  return proto.GetIncidentalLaborCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetIncidentalLaborCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetIncidentalLaborCloseoutsResponse}
 */
proto.GetIncidentalLaborCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IncidentalLaborCloseout;
      reader.readMessage(value,proto.IncidentalLaborCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetIncidentalLaborCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetIncidentalLaborCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetIncidentalLaborCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIncidentalLaborCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.IncidentalLaborCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IncidentalLaborCloseout closeouts = 1;
 * @return {!Array<!proto.IncidentalLaborCloseout>}
 */
proto.GetIncidentalLaborCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.IncidentalLaborCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IncidentalLaborCloseout, 1));
};


/**
 * @param {!Array<!proto.IncidentalLaborCloseout>} value
 * @return {!proto.GetIncidentalLaborCloseoutsResponse} returns this
*/
proto.GetIncidentalLaborCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.IncidentalLaborCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IncidentalLaborCloseout}
 */
proto.GetIncidentalLaborCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.IncidentalLaborCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetIncidentalLaborCloseoutsResponse} returns this
 */
proto.GetIncidentalLaborCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateIncidentalLaborCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateIncidentalLaborCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateIncidentalLaborCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIncidentalLaborCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.IncidentalLaborCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateIncidentalLaborCloseoutRequest}
 */
proto.UpdateIncidentalLaborCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateIncidentalLaborCloseoutRequest;
  return proto.UpdateIncidentalLaborCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateIncidentalLaborCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateIncidentalLaborCloseoutRequest}
 */
proto.UpdateIncidentalLaborCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IncidentalLaborCloseout;
      reader.readMessage(value,proto.IncidentalLaborCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateIncidentalLaborCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateIncidentalLaborCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateIncidentalLaborCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIncidentalLaborCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.IncidentalLaborCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional IncidentalLaborCloseout closeout = 1;
 * @return {?proto.IncidentalLaborCloseout}
 */
proto.UpdateIncidentalLaborCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.IncidentalLaborCloseout} */ (
    jspb.Message.getWrapperField(this, proto.IncidentalLaborCloseout, 1));
};


/**
 * @param {?proto.IncidentalLaborCloseout|undefined} value
 * @return {!proto.UpdateIncidentalLaborCloseoutRequest} returns this
*/
proto.UpdateIncidentalLaborCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateIncidentalLaborCloseoutRequest} returns this
 */
proto.UpdateIncidentalLaborCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateIncidentalLaborCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateIncidentalLaborCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateIncidentalLaborCloseoutRequest} returns this
 */
proto.UpdateIncidentalLaborCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateIncidentalLaborCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateIncidentalLaborCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateIncidentalLaborCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIncidentalLaborCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateIncidentalLaborCloseoutResponse}
 */
proto.UpdateIncidentalLaborCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateIncidentalLaborCloseoutResponse;
  return proto.UpdateIncidentalLaborCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateIncidentalLaborCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateIncidentalLaborCloseoutResponse}
 */
proto.UpdateIncidentalLaborCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateIncidentalLaborCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateIncidentalLaborCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateIncidentalLaborCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIncidentalLaborCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateIncidentalLaborCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateIncidentalLaborCloseoutResponse} returns this
 */
proto.UpdateIncidentalLaborCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteIncidentalLaborCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteIncidentalLaborCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteIncidentalLaborCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIncidentalLaborCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteIncidentalLaborCloseoutRequest}
 */
proto.DeleteIncidentalLaborCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteIncidentalLaborCloseoutRequest;
  return proto.DeleteIncidentalLaborCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteIncidentalLaborCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteIncidentalLaborCloseoutRequest}
 */
proto.DeleteIncidentalLaborCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteIncidentalLaborCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteIncidentalLaborCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteIncidentalLaborCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIncidentalLaborCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteIncidentalLaborCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteIncidentalLaborCloseoutRequest} returns this
 */
proto.DeleteIncidentalLaborCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteIncidentalLaborCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteIncidentalLaborCloseoutRequest} returns this
 */
proto.DeleteIncidentalLaborCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteIncidentalLaborCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteIncidentalLaborCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteIncidentalLaborCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIncidentalLaborCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteIncidentalLaborCloseoutResponse}
 */
proto.DeleteIncidentalLaborCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteIncidentalLaborCloseoutResponse;
  return proto.DeleteIncidentalLaborCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteIncidentalLaborCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteIncidentalLaborCloseoutResponse}
 */
proto.DeleteIncidentalLaborCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteIncidentalLaborCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteIncidentalLaborCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteIncidentalLaborCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIncidentalLaborCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteIncidentalLaborCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteIncidentalLaborCloseoutResponse} returns this
 */
proto.DeleteIncidentalLaborCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateIndirectLaborCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateIndirectLaborCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateIndirectLaborCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIndirectLaborCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.IndirectLaborCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateIndirectLaborCloseoutRequest}
 */
proto.CreateIndirectLaborCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateIndirectLaborCloseoutRequest;
  return proto.CreateIndirectLaborCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateIndirectLaborCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateIndirectLaborCloseoutRequest}
 */
proto.CreateIndirectLaborCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IndirectLaborCloseout;
      reader.readMessage(value,proto.IndirectLaborCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateIndirectLaborCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateIndirectLaborCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateIndirectLaborCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIndirectLaborCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.IndirectLaborCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional IndirectLaborCloseout closeout = 1;
 * @return {?proto.IndirectLaborCloseout}
 */
proto.CreateIndirectLaborCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.IndirectLaborCloseout} */ (
    jspb.Message.getWrapperField(this, proto.IndirectLaborCloseout, 1));
};


/**
 * @param {?proto.IndirectLaborCloseout|undefined} value
 * @return {!proto.CreateIndirectLaborCloseoutRequest} returns this
*/
proto.CreateIndirectLaborCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateIndirectLaborCloseoutRequest} returns this
 */
proto.CreateIndirectLaborCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateIndirectLaborCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 6;
 * @return {string}
 */
proto.CreateIndirectLaborCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateIndirectLaborCloseoutRequest} returns this
 */
proto.CreateIndirectLaborCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateIndirectLaborCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateIndirectLaborCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateIndirectLaborCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIndirectLaborCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateIndirectLaborCloseoutResponse}
 */
proto.CreateIndirectLaborCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateIndirectLaborCloseoutResponse;
  return proto.CreateIndirectLaborCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateIndirectLaborCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateIndirectLaborCloseoutResponse}
 */
proto.CreateIndirectLaborCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateIndirectLaborCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateIndirectLaborCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateIndirectLaborCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIndirectLaborCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateIndirectLaborCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateIndirectLaborCloseoutResponse} returns this
 */
proto.CreateIndirectLaborCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetIndirectLaborCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetIndirectLaborCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetIndirectLaborCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIndirectLaborCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetIndirectLaborCloseoutsRequest}
 */
proto.GetIndirectLaborCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetIndirectLaborCloseoutsRequest;
  return proto.GetIndirectLaborCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetIndirectLaborCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetIndirectLaborCloseoutsRequest}
 */
proto.GetIndirectLaborCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetIndirectLaborCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetIndirectLaborCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetIndirectLaborCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIndirectLaborCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetIndirectLaborCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetIndirectLaborCloseoutsRequest} returns this
 */
proto.GetIndirectLaborCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetIndirectLaborCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetIndirectLaborCloseoutsRequest} returns this
 */
proto.GetIndirectLaborCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetIndirectLaborCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetIndirectLaborCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetIndirectLaborCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetIndirectLaborCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIndirectLaborCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.IndirectLaborCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetIndirectLaborCloseoutsResponse}
 */
proto.GetIndirectLaborCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetIndirectLaborCloseoutsResponse;
  return proto.GetIndirectLaborCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetIndirectLaborCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetIndirectLaborCloseoutsResponse}
 */
proto.GetIndirectLaborCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IndirectLaborCloseout;
      reader.readMessage(value,proto.IndirectLaborCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetIndirectLaborCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetIndirectLaborCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetIndirectLaborCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIndirectLaborCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.IndirectLaborCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IndirectLaborCloseout closeouts = 1;
 * @return {!Array<!proto.IndirectLaborCloseout>}
 */
proto.GetIndirectLaborCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.IndirectLaborCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IndirectLaborCloseout, 1));
};


/**
 * @param {!Array<!proto.IndirectLaborCloseout>} value
 * @return {!proto.GetIndirectLaborCloseoutsResponse} returns this
*/
proto.GetIndirectLaborCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.IndirectLaborCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IndirectLaborCloseout}
 */
proto.GetIndirectLaborCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.IndirectLaborCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetIndirectLaborCloseoutsResponse} returns this
 */
proto.GetIndirectLaborCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateIndirectLaborCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateIndirectLaborCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateIndirectLaborCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIndirectLaborCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.IndirectLaborCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateIndirectLaborCloseoutRequest}
 */
proto.UpdateIndirectLaborCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateIndirectLaborCloseoutRequest;
  return proto.UpdateIndirectLaborCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateIndirectLaborCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateIndirectLaborCloseoutRequest}
 */
proto.UpdateIndirectLaborCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IndirectLaborCloseout;
      reader.readMessage(value,proto.IndirectLaborCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateIndirectLaborCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateIndirectLaborCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateIndirectLaborCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIndirectLaborCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.IndirectLaborCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional IndirectLaborCloseout closeout = 1;
 * @return {?proto.IndirectLaborCloseout}
 */
proto.UpdateIndirectLaborCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.IndirectLaborCloseout} */ (
    jspb.Message.getWrapperField(this, proto.IndirectLaborCloseout, 1));
};


/**
 * @param {?proto.IndirectLaborCloseout|undefined} value
 * @return {!proto.UpdateIndirectLaborCloseoutRequest} returns this
*/
proto.UpdateIndirectLaborCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateIndirectLaborCloseoutRequest} returns this
 */
proto.UpdateIndirectLaborCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateIndirectLaborCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateIndirectLaborCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateIndirectLaborCloseoutRequest} returns this
 */
proto.UpdateIndirectLaborCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateIndirectLaborCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateIndirectLaborCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateIndirectLaborCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIndirectLaborCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateIndirectLaborCloseoutResponse}
 */
proto.UpdateIndirectLaborCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateIndirectLaborCloseoutResponse;
  return proto.UpdateIndirectLaborCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateIndirectLaborCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateIndirectLaborCloseoutResponse}
 */
proto.UpdateIndirectLaborCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateIndirectLaborCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateIndirectLaborCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateIndirectLaborCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIndirectLaborCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateIndirectLaborCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateIndirectLaborCloseoutResponse} returns this
 */
proto.UpdateIndirectLaborCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteIndirectLaborCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteIndirectLaborCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteIndirectLaborCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIndirectLaborCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteIndirectLaborCloseoutRequest}
 */
proto.DeleteIndirectLaborCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteIndirectLaborCloseoutRequest;
  return proto.DeleteIndirectLaborCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteIndirectLaborCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteIndirectLaborCloseoutRequest}
 */
proto.DeleteIndirectLaborCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteIndirectLaborCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteIndirectLaborCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteIndirectLaborCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIndirectLaborCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteIndirectLaborCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteIndirectLaborCloseoutRequest} returns this
 */
proto.DeleteIndirectLaborCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteIndirectLaborCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteIndirectLaborCloseoutRequest} returns this
 */
proto.DeleteIndirectLaborCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteIndirectLaborCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteIndirectLaborCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteIndirectLaborCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIndirectLaborCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteIndirectLaborCloseoutResponse}
 */
proto.DeleteIndirectLaborCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteIndirectLaborCloseoutResponse;
  return proto.DeleteIndirectLaborCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteIndirectLaborCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteIndirectLaborCloseoutResponse}
 */
proto.DeleteIndirectLaborCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteIndirectLaborCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteIndirectLaborCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteIndirectLaborCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIndirectLaborCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteIndirectLaborCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteIndirectLaborCloseoutResponse} returns this
 */
proto.DeleteIndirectLaborCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateEquipmentCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateEquipmentCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateEquipmentCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateEquipmentCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.EquipmentCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateEquipmentCloseoutRequest}
 */
proto.CreateEquipmentCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateEquipmentCloseoutRequest;
  return proto.CreateEquipmentCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateEquipmentCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateEquipmentCloseoutRequest}
 */
proto.CreateEquipmentCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EquipmentCloseout;
      reader.readMessage(value,proto.EquipmentCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateEquipmentCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateEquipmentCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateEquipmentCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateEquipmentCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.EquipmentCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional EquipmentCloseout closeout = 1;
 * @return {?proto.EquipmentCloseout}
 */
proto.CreateEquipmentCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.EquipmentCloseout} */ (
    jspb.Message.getWrapperField(this, proto.EquipmentCloseout, 1));
};


/**
 * @param {?proto.EquipmentCloseout|undefined} value
 * @return {!proto.CreateEquipmentCloseoutRequest} returns this
*/
proto.CreateEquipmentCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateEquipmentCloseoutRequest} returns this
 */
proto.CreateEquipmentCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateEquipmentCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 4;
 * @return {string}
 */
proto.CreateEquipmentCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateEquipmentCloseoutRequest} returns this
 */
proto.CreateEquipmentCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateEquipmentCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateEquipmentCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateEquipmentCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateEquipmentCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateEquipmentCloseoutResponse}
 */
proto.CreateEquipmentCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateEquipmentCloseoutResponse;
  return proto.CreateEquipmentCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateEquipmentCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateEquipmentCloseoutResponse}
 */
proto.CreateEquipmentCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateEquipmentCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateEquipmentCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateEquipmentCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateEquipmentCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateEquipmentCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateEquipmentCloseoutResponse} returns this
 */
proto.CreateEquipmentCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetEquipmentCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetEquipmentCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetEquipmentCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEquipmentCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetEquipmentCloseoutsRequest}
 */
proto.GetEquipmentCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetEquipmentCloseoutsRequest;
  return proto.GetEquipmentCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetEquipmentCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetEquipmentCloseoutsRequest}
 */
proto.GetEquipmentCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetEquipmentCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetEquipmentCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetEquipmentCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEquipmentCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetEquipmentCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetEquipmentCloseoutsRequest} returns this
 */
proto.GetEquipmentCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetEquipmentCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetEquipmentCloseoutsRequest} returns this
 */
proto.GetEquipmentCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetEquipmentCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetEquipmentCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetEquipmentCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetEquipmentCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEquipmentCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.EquipmentCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetEquipmentCloseoutsResponse}
 */
proto.GetEquipmentCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetEquipmentCloseoutsResponse;
  return proto.GetEquipmentCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetEquipmentCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetEquipmentCloseoutsResponse}
 */
proto.GetEquipmentCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EquipmentCloseout;
      reader.readMessage(value,proto.EquipmentCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetEquipmentCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetEquipmentCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetEquipmentCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEquipmentCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EquipmentCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EquipmentCloseout closeouts = 1;
 * @return {!Array<!proto.EquipmentCloseout>}
 */
proto.GetEquipmentCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.EquipmentCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EquipmentCloseout, 1));
};


/**
 * @param {!Array<!proto.EquipmentCloseout>} value
 * @return {!proto.GetEquipmentCloseoutsResponse} returns this
*/
proto.GetEquipmentCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EquipmentCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EquipmentCloseout}
 */
proto.GetEquipmentCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EquipmentCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetEquipmentCloseoutsResponse} returns this
 */
proto.GetEquipmentCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEquipmentCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEquipmentCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEquipmentCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEquipmentCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.EquipmentCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEquipmentCloseoutRequest}
 */
proto.UpdateEquipmentCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEquipmentCloseoutRequest;
  return proto.UpdateEquipmentCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEquipmentCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEquipmentCloseoutRequest}
 */
proto.UpdateEquipmentCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EquipmentCloseout;
      reader.readMessage(value,proto.EquipmentCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEquipmentCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEquipmentCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEquipmentCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEquipmentCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.EquipmentCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional EquipmentCloseout closeout = 1;
 * @return {?proto.EquipmentCloseout}
 */
proto.UpdateEquipmentCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.EquipmentCloseout} */ (
    jspb.Message.getWrapperField(this, proto.EquipmentCloseout, 1));
};


/**
 * @param {?proto.EquipmentCloseout|undefined} value
 * @return {!proto.UpdateEquipmentCloseoutRequest} returns this
*/
proto.UpdateEquipmentCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateEquipmentCloseoutRequest} returns this
 */
proto.UpdateEquipmentCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateEquipmentCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateEquipmentCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateEquipmentCloseoutRequest} returns this
 */
proto.UpdateEquipmentCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEquipmentCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEquipmentCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEquipmentCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEquipmentCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEquipmentCloseoutResponse}
 */
proto.UpdateEquipmentCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEquipmentCloseoutResponse;
  return proto.UpdateEquipmentCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEquipmentCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEquipmentCloseoutResponse}
 */
proto.UpdateEquipmentCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEquipmentCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEquipmentCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEquipmentCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEquipmentCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateEquipmentCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateEquipmentCloseoutResponse} returns this
 */
proto.UpdateEquipmentCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteEquipmentCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteEquipmentCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteEquipmentCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEquipmentCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteEquipmentCloseoutRequest}
 */
proto.DeleteEquipmentCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteEquipmentCloseoutRequest;
  return proto.DeleteEquipmentCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteEquipmentCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteEquipmentCloseoutRequest}
 */
proto.DeleteEquipmentCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteEquipmentCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteEquipmentCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteEquipmentCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEquipmentCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteEquipmentCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteEquipmentCloseoutRequest} returns this
 */
proto.DeleteEquipmentCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteEquipmentCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteEquipmentCloseoutRequest} returns this
 */
proto.DeleteEquipmentCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteEquipmentCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteEquipmentCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteEquipmentCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEquipmentCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteEquipmentCloseoutResponse}
 */
proto.DeleteEquipmentCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteEquipmentCloseoutResponse;
  return proto.DeleteEquipmentCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteEquipmentCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteEquipmentCloseoutResponse}
 */
proto.DeleteEquipmentCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteEquipmentCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteEquipmentCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteEquipmentCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEquipmentCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteEquipmentCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteEquipmentCloseoutResponse} returns this
 */
proto.DeleteEquipmentCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateGeneralExpenseCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateGeneralExpenseCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateGeneralExpenseCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGeneralExpenseCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.GeneralExpenseCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateGeneralExpenseCloseoutRequest}
 */
proto.CreateGeneralExpenseCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateGeneralExpenseCloseoutRequest;
  return proto.CreateGeneralExpenseCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateGeneralExpenseCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateGeneralExpenseCloseoutRequest}
 */
proto.CreateGeneralExpenseCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GeneralExpenseCloseout;
      reader.readMessage(value,proto.GeneralExpenseCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateGeneralExpenseCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateGeneralExpenseCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateGeneralExpenseCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGeneralExpenseCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GeneralExpenseCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional GeneralExpenseCloseout closeout = 1;
 * @return {?proto.GeneralExpenseCloseout}
 */
proto.CreateGeneralExpenseCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.GeneralExpenseCloseout} */ (
    jspb.Message.getWrapperField(this, proto.GeneralExpenseCloseout, 1));
};


/**
 * @param {?proto.GeneralExpenseCloseout|undefined} value
 * @return {!proto.CreateGeneralExpenseCloseoutRequest} returns this
*/
proto.CreateGeneralExpenseCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateGeneralExpenseCloseoutRequest} returns this
 */
proto.CreateGeneralExpenseCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateGeneralExpenseCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 4;
 * @return {string}
 */
proto.CreateGeneralExpenseCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateGeneralExpenseCloseoutRequest} returns this
 */
proto.CreateGeneralExpenseCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateGeneralExpenseCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateGeneralExpenseCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateGeneralExpenseCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGeneralExpenseCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateGeneralExpenseCloseoutResponse}
 */
proto.CreateGeneralExpenseCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateGeneralExpenseCloseoutResponse;
  return proto.CreateGeneralExpenseCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateGeneralExpenseCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateGeneralExpenseCloseoutResponse}
 */
proto.CreateGeneralExpenseCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateGeneralExpenseCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateGeneralExpenseCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateGeneralExpenseCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGeneralExpenseCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateGeneralExpenseCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateGeneralExpenseCloseoutResponse} returns this
 */
proto.CreateGeneralExpenseCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetGeneralExpenseCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetGeneralExpenseCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetGeneralExpenseCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGeneralExpenseCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetGeneralExpenseCloseoutsRequest}
 */
proto.GetGeneralExpenseCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetGeneralExpenseCloseoutsRequest;
  return proto.GetGeneralExpenseCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetGeneralExpenseCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetGeneralExpenseCloseoutsRequest}
 */
proto.GetGeneralExpenseCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetGeneralExpenseCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetGeneralExpenseCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetGeneralExpenseCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGeneralExpenseCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetGeneralExpenseCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetGeneralExpenseCloseoutsRequest} returns this
 */
proto.GetGeneralExpenseCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetGeneralExpenseCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetGeneralExpenseCloseoutsRequest} returns this
 */
proto.GetGeneralExpenseCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetGeneralExpenseCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetGeneralExpenseCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetGeneralExpenseCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetGeneralExpenseCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGeneralExpenseCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.GeneralExpenseCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetGeneralExpenseCloseoutsResponse}
 */
proto.GetGeneralExpenseCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetGeneralExpenseCloseoutsResponse;
  return proto.GetGeneralExpenseCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetGeneralExpenseCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetGeneralExpenseCloseoutsResponse}
 */
proto.GetGeneralExpenseCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GeneralExpenseCloseout;
      reader.readMessage(value,proto.GeneralExpenseCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetGeneralExpenseCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetGeneralExpenseCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetGeneralExpenseCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGeneralExpenseCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GeneralExpenseCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GeneralExpenseCloseout closeouts = 1;
 * @return {!Array<!proto.GeneralExpenseCloseout>}
 */
proto.GetGeneralExpenseCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.GeneralExpenseCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GeneralExpenseCloseout, 1));
};


/**
 * @param {!Array<!proto.GeneralExpenseCloseout>} value
 * @return {!proto.GetGeneralExpenseCloseoutsResponse} returns this
*/
proto.GetGeneralExpenseCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GeneralExpenseCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GeneralExpenseCloseout}
 */
proto.GetGeneralExpenseCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GeneralExpenseCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetGeneralExpenseCloseoutsResponse} returns this
 */
proto.GetGeneralExpenseCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateGeneralExpenseCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateGeneralExpenseCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateGeneralExpenseCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGeneralExpenseCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.GeneralExpenseCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateGeneralExpenseCloseoutRequest}
 */
proto.UpdateGeneralExpenseCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateGeneralExpenseCloseoutRequest;
  return proto.UpdateGeneralExpenseCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateGeneralExpenseCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateGeneralExpenseCloseoutRequest}
 */
proto.UpdateGeneralExpenseCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GeneralExpenseCloseout;
      reader.readMessage(value,proto.GeneralExpenseCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateGeneralExpenseCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateGeneralExpenseCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateGeneralExpenseCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGeneralExpenseCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GeneralExpenseCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional GeneralExpenseCloseout closeout = 1;
 * @return {?proto.GeneralExpenseCloseout}
 */
proto.UpdateGeneralExpenseCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.GeneralExpenseCloseout} */ (
    jspb.Message.getWrapperField(this, proto.GeneralExpenseCloseout, 1));
};


/**
 * @param {?proto.GeneralExpenseCloseout|undefined} value
 * @return {!proto.UpdateGeneralExpenseCloseoutRequest} returns this
*/
proto.UpdateGeneralExpenseCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateGeneralExpenseCloseoutRequest} returns this
 */
proto.UpdateGeneralExpenseCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateGeneralExpenseCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateGeneralExpenseCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateGeneralExpenseCloseoutRequest} returns this
 */
proto.UpdateGeneralExpenseCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateGeneralExpenseCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateGeneralExpenseCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateGeneralExpenseCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGeneralExpenseCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateGeneralExpenseCloseoutResponse}
 */
proto.UpdateGeneralExpenseCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateGeneralExpenseCloseoutResponse;
  return proto.UpdateGeneralExpenseCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateGeneralExpenseCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateGeneralExpenseCloseoutResponse}
 */
proto.UpdateGeneralExpenseCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateGeneralExpenseCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateGeneralExpenseCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateGeneralExpenseCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGeneralExpenseCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateGeneralExpenseCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateGeneralExpenseCloseoutResponse} returns this
 */
proto.UpdateGeneralExpenseCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteGeneralExpenseCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteGeneralExpenseCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteGeneralExpenseCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGeneralExpenseCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteGeneralExpenseCloseoutRequest}
 */
proto.DeleteGeneralExpenseCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteGeneralExpenseCloseoutRequest;
  return proto.DeleteGeneralExpenseCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteGeneralExpenseCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteGeneralExpenseCloseoutRequest}
 */
proto.DeleteGeneralExpenseCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteGeneralExpenseCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteGeneralExpenseCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteGeneralExpenseCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGeneralExpenseCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteGeneralExpenseCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteGeneralExpenseCloseoutRequest} returns this
 */
proto.DeleteGeneralExpenseCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteGeneralExpenseCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteGeneralExpenseCloseoutRequest} returns this
 */
proto.DeleteGeneralExpenseCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteGeneralExpenseCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteGeneralExpenseCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteGeneralExpenseCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGeneralExpenseCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteGeneralExpenseCloseoutResponse}
 */
proto.DeleteGeneralExpenseCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteGeneralExpenseCloseoutResponse;
  return proto.DeleteGeneralExpenseCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteGeneralExpenseCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteGeneralExpenseCloseoutResponse}
 */
proto.DeleteGeneralExpenseCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteGeneralExpenseCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteGeneralExpenseCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteGeneralExpenseCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGeneralExpenseCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteGeneralExpenseCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteGeneralExpenseCloseoutResponse} returns this
 */
proto.DeleteGeneralExpenseCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateSubcontractCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateSubcontractCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateSubcontractCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateSubcontractCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.SubcontractCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateSubcontractCloseoutRequest}
 */
proto.CreateSubcontractCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateSubcontractCloseoutRequest;
  return proto.CreateSubcontractCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateSubcontractCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateSubcontractCloseoutRequest}
 */
proto.CreateSubcontractCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SubcontractCloseout;
      reader.readMessage(value,proto.SubcontractCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateSubcontractCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateSubcontractCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateSubcontractCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateSubcontractCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.SubcontractCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional SubcontractCloseout closeout = 1;
 * @return {?proto.SubcontractCloseout}
 */
proto.CreateSubcontractCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.SubcontractCloseout} */ (
    jspb.Message.getWrapperField(this, proto.SubcontractCloseout, 1));
};


/**
 * @param {?proto.SubcontractCloseout|undefined} value
 * @return {!proto.CreateSubcontractCloseoutRequest} returns this
*/
proto.CreateSubcontractCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateSubcontractCloseoutRequest} returns this
 */
proto.CreateSubcontractCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateSubcontractCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 5;
 * @return {string}
 */
proto.CreateSubcontractCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateSubcontractCloseoutRequest} returns this
 */
proto.CreateSubcontractCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateSubcontractCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateSubcontractCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateSubcontractCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateSubcontractCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateSubcontractCloseoutResponse}
 */
proto.CreateSubcontractCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateSubcontractCloseoutResponse;
  return proto.CreateSubcontractCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateSubcontractCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateSubcontractCloseoutResponse}
 */
proto.CreateSubcontractCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateSubcontractCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateSubcontractCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateSubcontractCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateSubcontractCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateSubcontractCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateSubcontractCloseoutResponse} returns this
 */
proto.CreateSubcontractCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetSubcontractCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetSubcontractCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetSubcontractCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetSubcontractCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetSubcontractCloseoutsRequest}
 */
proto.GetSubcontractCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetSubcontractCloseoutsRequest;
  return proto.GetSubcontractCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetSubcontractCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetSubcontractCloseoutsRequest}
 */
proto.GetSubcontractCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetSubcontractCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetSubcontractCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetSubcontractCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetSubcontractCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetSubcontractCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetSubcontractCloseoutsRequest} returns this
 */
proto.GetSubcontractCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetSubcontractCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetSubcontractCloseoutsRequest} returns this
 */
proto.GetSubcontractCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetSubcontractCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetSubcontractCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetSubcontractCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetSubcontractCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetSubcontractCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.SubcontractCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetSubcontractCloseoutsResponse}
 */
proto.GetSubcontractCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetSubcontractCloseoutsResponse;
  return proto.GetSubcontractCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetSubcontractCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetSubcontractCloseoutsResponse}
 */
proto.GetSubcontractCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SubcontractCloseout;
      reader.readMessage(value,proto.SubcontractCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetSubcontractCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetSubcontractCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetSubcontractCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetSubcontractCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SubcontractCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SubcontractCloseout closeouts = 1;
 * @return {!Array<!proto.SubcontractCloseout>}
 */
proto.GetSubcontractCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.SubcontractCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SubcontractCloseout, 1));
};


/**
 * @param {!Array<!proto.SubcontractCloseout>} value
 * @return {!proto.GetSubcontractCloseoutsResponse} returns this
*/
proto.GetSubcontractCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SubcontractCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SubcontractCloseout}
 */
proto.GetSubcontractCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SubcontractCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetSubcontractCloseoutsResponse} returns this
 */
proto.GetSubcontractCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateSubcontractCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateSubcontractCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateSubcontractCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateSubcontractCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.SubcontractCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateSubcontractCloseoutRequest}
 */
proto.UpdateSubcontractCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateSubcontractCloseoutRequest;
  return proto.UpdateSubcontractCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateSubcontractCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateSubcontractCloseoutRequest}
 */
proto.UpdateSubcontractCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SubcontractCloseout;
      reader.readMessage(value,proto.SubcontractCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateSubcontractCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateSubcontractCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateSubcontractCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateSubcontractCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.SubcontractCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional SubcontractCloseout closeout = 1;
 * @return {?proto.SubcontractCloseout}
 */
proto.UpdateSubcontractCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.SubcontractCloseout} */ (
    jspb.Message.getWrapperField(this, proto.SubcontractCloseout, 1));
};


/**
 * @param {?proto.SubcontractCloseout|undefined} value
 * @return {!proto.UpdateSubcontractCloseoutRequest} returns this
*/
proto.UpdateSubcontractCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateSubcontractCloseoutRequest} returns this
 */
proto.UpdateSubcontractCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateSubcontractCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateSubcontractCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateSubcontractCloseoutRequest} returns this
 */
proto.UpdateSubcontractCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateSubcontractCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateSubcontractCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateSubcontractCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateSubcontractCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateSubcontractCloseoutResponse}
 */
proto.UpdateSubcontractCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateSubcontractCloseoutResponse;
  return proto.UpdateSubcontractCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateSubcontractCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateSubcontractCloseoutResponse}
 */
proto.UpdateSubcontractCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateSubcontractCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateSubcontractCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateSubcontractCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateSubcontractCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateSubcontractCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateSubcontractCloseoutResponse} returns this
 */
proto.UpdateSubcontractCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteSubcontractCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteSubcontractCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteSubcontractCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteSubcontractCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteSubcontractCloseoutRequest}
 */
proto.DeleteSubcontractCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteSubcontractCloseoutRequest;
  return proto.DeleteSubcontractCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteSubcontractCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteSubcontractCloseoutRequest}
 */
proto.DeleteSubcontractCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteSubcontractCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteSubcontractCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteSubcontractCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteSubcontractCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteSubcontractCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteSubcontractCloseoutRequest} returns this
 */
proto.DeleteSubcontractCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteSubcontractCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteSubcontractCloseoutRequest} returns this
 */
proto.DeleteSubcontractCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteSubcontractCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteSubcontractCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteSubcontractCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteSubcontractCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteSubcontractCloseoutResponse}
 */
proto.DeleteSubcontractCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteSubcontractCloseoutResponse;
  return proto.DeleteSubcontractCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteSubcontractCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteSubcontractCloseoutResponse}
 */
proto.DeleteSubcontractCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteSubcontractCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteSubcontractCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteSubcontractCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteSubcontractCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteSubcontractCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteSubcontractCloseoutResponse} returns this
 */
proto.DeleteSubcontractCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateQuoteCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateQuoteCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateQuoteCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateQuoteCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.QuoteCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateQuoteCloseoutRequest}
 */
proto.CreateQuoteCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateQuoteCloseoutRequest;
  return proto.CreateQuoteCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateQuoteCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateQuoteCloseoutRequest}
 */
proto.CreateQuoteCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.QuoteCloseout;
      reader.readMessage(value,proto.QuoteCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateQuoteCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateQuoteCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateQuoteCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateQuoteCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.QuoteCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional QuoteCloseout closeout = 1;
 * @return {?proto.QuoteCloseout}
 */
proto.CreateQuoteCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.QuoteCloseout} */ (
    jspb.Message.getWrapperField(this, proto.QuoteCloseout, 1));
};


/**
 * @param {?proto.QuoteCloseout|undefined} value
 * @return {!proto.CreateQuoteCloseoutRequest} returns this
*/
proto.CreateQuoteCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateQuoteCloseoutRequest} returns this
 */
proto.CreateQuoteCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateQuoteCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.CreateQuoteCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateQuoteCloseoutRequest} returns this
 */
proto.CreateQuoteCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateQuoteCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateQuoteCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateQuoteCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateQuoteCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateQuoteCloseoutResponse}
 */
proto.CreateQuoteCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateQuoteCloseoutResponse;
  return proto.CreateQuoteCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateQuoteCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateQuoteCloseoutResponse}
 */
proto.CreateQuoteCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateQuoteCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateQuoteCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateQuoteCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateQuoteCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateQuoteCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateQuoteCloseoutResponse} returns this
 */
proto.CreateQuoteCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetQuoteCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetQuoteCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetQuoteCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetQuoteCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetQuoteCloseoutsRequest}
 */
proto.GetQuoteCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetQuoteCloseoutsRequest;
  return proto.GetQuoteCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetQuoteCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetQuoteCloseoutsRequest}
 */
proto.GetQuoteCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetQuoteCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetQuoteCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetQuoteCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetQuoteCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetQuoteCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetQuoteCloseoutsRequest} returns this
 */
proto.GetQuoteCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetQuoteCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetQuoteCloseoutsRequest} returns this
 */
proto.GetQuoteCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetQuoteCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetQuoteCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetQuoteCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetQuoteCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetQuoteCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.QuoteCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetQuoteCloseoutsResponse}
 */
proto.GetQuoteCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetQuoteCloseoutsResponse;
  return proto.GetQuoteCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetQuoteCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetQuoteCloseoutsResponse}
 */
proto.GetQuoteCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.QuoteCloseout;
      reader.readMessage(value,proto.QuoteCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetQuoteCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetQuoteCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetQuoteCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetQuoteCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.QuoteCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QuoteCloseout closeouts = 1;
 * @return {!Array<!proto.QuoteCloseout>}
 */
proto.GetQuoteCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.QuoteCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.QuoteCloseout, 1));
};


/**
 * @param {!Array<!proto.QuoteCloseout>} value
 * @return {!proto.GetQuoteCloseoutsResponse} returns this
*/
proto.GetQuoteCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.QuoteCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.QuoteCloseout}
 */
proto.GetQuoteCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.QuoteCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetQuoteCloseoutsResponse} returns this
 */
proto.GetQuoteCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateQuoteCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateQuoteCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateQuoteCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateQuoteCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.QuoteCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateQuoteCloseoutRequest}
 */
proto.UpdateQuoteCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateQuoteCloseoutRequest;
  return proto.UpdateQuoteCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateQuoteCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateQuoteCloseoutRequest}
 */
proto.UpdateQuoteCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.QuoteCloseout;
      reader.readMessage(value,proto.QuoteCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateQuoteCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateQuoteCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateQuoteCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateQuoteCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.QuoteCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional QuoteCloseout closeout = 1;
 * @return {?proto.QuoteCloseout}
 */
proto.UpdateQuoteCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.QuoteCloseout} */ (
    jspb.Message.getWrapperField(this, proto.QuoteCloseout, 1));
};


/**
 * @param {?proto.QuoteCloseout|undefined} value
 * @return {!proto.UpdateQuoteCloseoutRequest} returns this
*/
proto.UpdateQuoteCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateQuoteCloseoutRequest} returns this
 */
proto.UpdateQuoteCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateQuoteCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateQuoteCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateQuoteCloseoutRequest} returns this
 */
proto.UpdateQuoteCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateQuoteCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateQuoteCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateQuoteCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateQuoteCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateQuoteCloseoutResponse}
 */
proto.UpdateQuoteCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateQuoteCloseoutResponse;
  return proto.UpdateQuoteCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateQuoteCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateQuoteCloseoutResponse}
 */
proto.UpdateQuoteCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateQuoteCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateQuoteCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateQuoteCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateQuoteCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateQuoteCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateQuoteCloseoutResponse} returns this
 */
proto.UpdateQuoteCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteQuoteCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteQuoteCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteQuoteCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteQuoteCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteQuoteCloseoutRequest}
 */
proto.DeleteQuoteCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteQuoteCloseoutRequest;
  return proto.DeleteQuoteCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteQuoteCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteQuoteCloseoutRequest}
 */
proto.DeleteQuoteCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteQuoteCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteQuoteCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteQuoteCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteQuoteCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteQuoteCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteQuoteCloseoutRequest} returns this
 */
proto.DeleteQuoteCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteQuoteCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteQuoteCloseoutRequest} returns this
 */
proto.DeleteQuoteCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteQuoteCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteQuoteCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteQuoteCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteQuoteCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteQuoteCloseoutResponse}
 */
proto.DeleteQuoteCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteQuoteCloseoutResponse;
  return proto.DeleteQuoteCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteQuoteCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteQuoteCloseoutResponse}
 */
proto.DeleteQuoteCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteQuoteCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteQuoteCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteQuoteCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteQuoteCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteQuoteCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteQuoteCloseoutResponse} returns this
 */
proto.DeleteQuoteCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetTotalFinalPriceCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetTotalFinalPriceCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetTotalFinalPriceCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTotalFinalPriceCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetTotalFinalPriceCloseoutsRequest}
 */
proto.GetTotalFinalPriceCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetTotalFinalPriceCloseoutsRequest;
  return proto.GetTotalFinalPriceCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetTotalFinalPriceCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetTotalFinalPriceCloseoutsRequest}
 */
proto.GetTotalFinalPriceCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetTotalFinalPriceCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetTotalFinalPriceCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetTotalFinalPriceCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTotalFinalPriceCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetTotalFinalPriceCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetTotalFinalPriceCloseoutsRequest} returns this
 */
proto.GetTotalFinalPriceCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetTotalFinalPriceCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetTotalFinalPriceCloseoutsRequest} returns this
 */
proto.GetTotalFinalPriceCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetTotalFinalPriceCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetTotalFinalPriceCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetTotalFinalPriceCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTotalFinalPriceCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeouts: (f = msg.getCloseouts()) && proto.TotalFinalPriceCloseout.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetTotalFinalPriceCloseoutsResponse}
 */
proto.GetTotalFinalPriceCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetTotalFinalPriceCloseoutsResponse;
  return proto.GetTotalFinalPriceCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetTotalFinalPriceCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetTotalFinalPriceCloseoutsResponse}
 */
proto.GetTotalFinalPriceCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TotalFinalPriceCloseout;
      reader.readMessage(value,proto.TotalFinalPriceCloseout.deserializeBinaryFromReader);
      msg.setCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetTotalFinalPriceCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetTotalFinalPriceCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetTotalFinalPriceCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTotalFinalPriceCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseouts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TotalFinalPriceCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional TotalFinalPriceCloseout closeouts = 1;
 * @return {?proto.TotalFinalPriceCloseout}
 */
proto.GetTotalFinalPriceCloseoutsResponse.prototype.getCloseouts = function() {
  return /** @type{?proto.TotalFinalPriceCloseout} */ (
    jspb.Message.getWrapperField(this, proto.TotalFinalPriceCloseout, 1));
};


/**
 * @param {?proto.TotalFinalPriceCloseout|undefined} value
 * @return {!proto.GetTotalFinalPriceCloseoutsResponse} returns this
*/
proto.GetTotalFinalPriceCloseoutsResponse.prototype.setCloseouts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetTotalFinalPriceCloseoutsResponse} returns this
 */
proto.GetTotalFinalPriceCloseoutsResponse.prototype.clearCloseouts = function() {
  return this.setCloseouts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetTotalFinalPriceCloseoutsResponse.prototype.hasCloseouts = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateTotalFinalPriceCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateTotalFinalPriceCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateTotalFinalPriceCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTotalFinalPriceCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.TotalFinalPriceCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateTotalFinalPriceCloseoutRequest}
 */
proto.UpdateTotalFinalPriceCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateTotalFinalPriceCloseoutRequest;
  return proto.UpdateTotalFinalPriceCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateTotalFinalPriceCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateTotalFinalPriceCloseoutRequest}
 */
proto.UpdateTotalFinalPriceCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TotalFinalPriceCloseout;
      reader.readMessage(value,proto.TotalFinalPriceCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateTotalFinalPriceCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateTotalFinalPriceCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateTotalFinalPriceCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTotalFinalPriceCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TotalFinalPriceCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional TotalFinalPriceCloseout closeout = 1;
 * @return {?proto.TotalFinalPriceCloseout}
 */
proto.UpdateTotalFinalPriceCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.TotalFinalPriceCloseout} */ (
    jspb.Message.getWrapperField(this, proto.TotalFinalPriceCloseout, 1));
};


/**
 * @param {?proto.TotalFinalPriceCloseout|undefined} value
 * @return {!proto.UpdateTotalFinalPriceCloseoutRequest} returns this
*/
proto.UpdateTotalFinalPriceCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateTotalFinalPriceCloseoutRequest} returns this
 */
proto.UpdateTotalFinalPriceCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateTotalFinalPriceCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateTotalFinalPriceCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateTotalFinalPriceCloseoutRequest} returns this
 */
proto.UpdateTotalFinalPriceCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateTotalFinalPriceCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateTotalFinalPriceCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateTotalFinalPriceCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTotalFinalPriceCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateTotalFinalPriceCloseoutResponse}
 */
proto.UpdateTotalFinalPriceCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateTotalFinalPriceCloseoutResponse;
  return proto.UpdateTotalFinalPriceCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateTotalFinalPriceCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateTotalFinalPriceCloseoutResponse}
 */
proto.UpdateTotalFinalPriceCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateTotalFinalPriceCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateTotalFinalPriceCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateTotalFinalPriceCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTotalFinalPriceCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateTotalFinalPriceCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateTotalFinalPriceCloseoutResponse} returns this
 */
proto.UpdateTotalFinalPriceCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto);
