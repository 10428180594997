import {
  ChangeOrderAssembly,
  ChangeOrderAssemblyItem,
  ChangeOrderAssemblyItemAttribute,
  ChangeOrderBreakoutMap,
  ChangeOrderItemCost,
  ChangeOrderItemQtyFormula,
} from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import {
  EstimateAssembly,
  EstimateAssemblyItem,
  EstimateAssemblyItemAttribute,
  EstimateBreakoutMap,
  EstimateItemCost,
  EstimateItemQtyFormula,
  EstimateQuoteGroup,
} from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { GlobalAssembly } from "../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { useAssemblyStore } from "../states/AssemblyStore";
import { useKeyBoardEventStore } from "../states/keyEventStore";
import { useUnityBuildStore } from "../states/store";
import { TakeoffAssembly } from "../types/AssemblyItemType";
import { useSetIsAssemblyMeasurementType } from "./useSetTakeoffTypeToTrueOrFalse";
import { useStore } from "zustand";

export function useSetSelectedAssemblyFromAssemblyPicker() {
  const {
    userScale,
    currentPage,
    selectedObject,
    selectedBreakout,
    selectedEstimate,
    selectedProject,
    selectedChangeOrder,
    setMeasuredPoints,
    setTemporaryPoints,
    setIsAnnotating,
    setIsCreatingTakeoffAssembly,
    setIsUpdatingTakeoffAssembly,
  } = useStore(useUnityBuildStore);

  const { setIsMeasuring } = useStore(useKeyBoardEventStore);

  const { setCurrentTakeoffAssembly } = useStore(useAssemblyStore);

  const handleSetIsAssemblyMeasurementType = useSetIsAssemblyMeasurementType();

  type SetSelectedAssemblyProps = {
    globalAssembly?: GlobalAssembly.AsObject;
    takeoffAssembly?: TakeoffAssembly;
  };

  const useHandleSetSelectedTakeoffAssembly = (
    assembly: SetSelectedAssemblyProps
  ) => {
    if (!selectedObject || !selectedBreakout) {
      console.log("selectedObject or selectedBreakout is not set");
      return;
    }
    if (assembly.takeoffAssembly) {
      // assembly is EstimateAssembly.AsObject or ChangeOrderAssembly.AsObject
      setIsAnnotating(true);
      setIsUpdatingTakeoffAssembly(true);
      handleSetIsAssemblyMeasurementType(
        assembly.takeoffAssembly?.assemblymeasurementtype || ""
      );
      setIsMeasuring(false);
      setMeasuredPoints([]);
      setCurrentTakeoffAssembly(assembly.takeoffAssembly);
    } else if (assembly.globalAssembly) {
      // assembly is GlobalAssembly.AsObject;
      setTemporaryPoints([]);
      setIsAnnotating(true);
      setIsCreatingTakeoffAssembly(true);
      handleSetIsAssemblyMeasurementType(
        assembly.globalAssembly.assemblymeasurementtype
      );

      if (selectedEstimate) {
        const newAssy = new EstimateAssembly();
        newAssy.setAssemblyid("");
        newAssy.setAssemblyname(assembly.globalAssembly.assemblyname || "");
        newAssy.setAssemblymeasurementtype(
          assembly.globalAssembly.assemblymeasurementtype || ""
        );
        newAssy.setImageclassificationid(
          assembly.globalAssembly.imageclassificationid || 0
        );
        newAssy.setObjectid(selectedObject.id);
        newAssy.setTotalsegmentlength(0);
        newAssy.setTotalpointcount(0);
        newAssy.setTotalsegmentcount(0);
        newAssy.setTotalsqft(0);
        newAssy.setSegmentlength(0);
        newAssy.setSegmentcount(0);
        newAssy.setMeasuredarea(0);
        newAssy.setPointcount(0);
        newAssy.setPointtype("circle");
        newAssy.setPointsize(5);
        newAssy.setPointbordercolor("black");
        newAssy.setPointfillcolor("blue");
        newAssy.setLinesize(2);
        newAssy.setLinecolor("blue");
        newAssy.setLinedasharray("");
        newAssy.setUserscale(userScale ?? 0);
        newAssy.setPagenumber(currentPage ?? 1);
        newAssy.setDatecreated(new Date().toISOString());
        newAssy.setPointsList([]);
        newAssy.setFillopacity(.5);

        const newItemsList = new Array<EstimateAssemblyItem>();

        for (const item of assembly.globalAssembly.itemsList) {
          const newCosts = new EstimateItemCost();
          newCosts.setUomid(item.costs?.uomid || 0);
          newCosts.setItemunitcost(item.costs?.itemunitcost || 0);
          newCosts.setHourlyproductionrate(
            item.costs?.hourlyproductionrate || 0
          );

          const newItemQtyFormula = new EstimateItemQtyFormula();
          newItemQtyFormula.setItemqty(item.itemqtyformula?.itemqty || 1);
          newItemQtyFormula.setForevery(item.itemqtyformula?.forevery || 1);
          newItemQtyFormula.setTakeoffvariabletype(
            item.itemqtyformula?.takeoffvariabletype || "point"
          );
          newItemQtyFormula.setAnd(item.itemqtyformula?.and || false);
          newItemQtyFormula.setOr(item.itemqtyformula?.or || false);
          newItemQtyFormula.setItemid(item?.itemid || "");
          newItemQtyFormula.setIsquoted(item.isquoted || false);
          newItemQtyFormula.setAttributevalueid(
            item.attribute?.attributevalueid || 0
          );
          newItemQtyFormula.setQuotegroupid(item.quotegroup?.id || "");

          const newAttribute = new EstimateAssemblyItemAttribute();
          newAttribute.setAttributegroupid(
            item.attribute?.attributegroupid || 0
          );
          newAttribute.setAttributegroupname(
            item.attribute?.attributegroupname || ""
          );
          newAttribute.setAttributevalueid(
            item.attribute?.attributevalueid || 0
          );
          newAttribute.setAttributevaluename(
            item.attribute?.attributevaluename || ""
          );

          const newQuoteGroup = new EstimateQuoteGroup();
          newQuoteGroup.setId(item.quotegroup?.id || "");
          newQuoteGroup.setQuotegroup(item.quotegroup?.quotegroup || "");

          const newBreakout = new EstimateBreakoutMap();
          newBreakout.setBreakoutid(selectedBreakout.id);
          newBreakout.setMultiplier(selectedBreakout.multiplier);
          newBreakout.setBreakoutname(selectedBreakout.breakoutname);

          const newItem = new EstimateAssemblyItem();
          newItem.setItemid(item.itemid);
          newItem.setItemname(item.itemname);
          newItem.setIsquoted(item.isquoted);
          newItem.setQuotegroup(newQuoteGroup);
          newItem.setItemqtyformula(newItemQtyFormula);
          newItem.setAttribute(newAttribute);
          newItem.setCosts(newCosts);
          newItem.setBreakout(newBreakout);

          newItemsList.push(newItem);
        }
        newAssy.setProjectid(selectedProject.id);
        newAssy.setEstimateid(selectedEstimate.estimateid);
        newAssy.setItemsList(newItemsList);

        setCurrentTakeoffAssembly({
          ...newAssy.toObject(),
        });
      } else if (selectedChangeOrder) {
        const newAssy = new ChangeOrderAssembly();
        newAssy.setAssemblyid("");
        newAssy.setAssemblyname(assembly.globalAssembly.assemblyname || "");
        newAssy.setAssemblymeasurementtype(
          assembly.globalAssembly.assemblymeasurementtype || ""
        );
        newAssy.setImageclassificationid(
          assembly.globalAssembly.imageclassificationid || 0
        );
        newAssy.setObjectid(selectedObject.id);
        newAssy.setTotalsegmentlength(0);
        newAssy.setTotalpointcount(0);
        newAssy.setTotalsegmentcount(0);
        newAssy.setTotalsqft(0);
        newAssy.setSegmentlength(0);
        newAssy.setSegmentcount(0);
        newAssy.setMeasuredarea(0);
        newAssy.setPointcount(0);
        newAssy.setPointtype("circle");
        newAssy.setPointsize(5);
        newAssy.setPointbordercolor("black");
        newAssy.setPointfillcolor("blue");
        newAssy.setLinesize(2);
        newAssy.setLinecolor("blue");
        newAssy.setLinedasharray("");
        newAssy.setUserscale(userScale ?? 0);
        newAssy.setPagenumber(currentPage ?? 1);
        newAssy.setDatecreated(new Date().toISOString());
        newAssy.setPointsList([]);
        newAssy.setFillopacity(.5);

        const newItemsList = new Array<ChangeOrderAssemblyItem>();

        for (const item of assembly.globalAssembly.itemsList) {
          const newCosts = new ChangeOrderItemCost();
          newCosts.setUomid(item.costs?.uomid || 0);
          newCosts.setItemunitcost(item.costs?.itemunitcost || 0);
          newCosts.setHourlyproductionrate(
            item.costs?.hourlyproductionrate || 0
          );

          const newItemQtyFormula = new ChangeOrderItemQtyFormula();
          newItemQtyFormula.setItemqty(item.itemqtyformula?.itemqty || 1);
          newItemQtyFormula.setForevery(item.itemqtyformula?.forevery || 1);
          newItemQtyFormula.setTakeoffvariabletype(
            item.itemqtyformula?.takeoffvariabletype || "point"
          );
          newItemQtyFormula.setAnd(item.itemqtyformula?.and || false);
          newItemQtyFormula.setOr(item.itemqtyformula?.or || false);
          newItemQtyFormula.setItemid(item?.itemid || "");
          newItemQtyFormula.setIsquoted(item.isquoted || false);
          newItemQtyFormula.setAttributevalueid(
            item.attribute?.attributevalueid || 0
          );

          const newAttribute = new ChangeOrderAssemblyItemAttribute();
          newAttribute.setAttributegroupid(
            item.attribute?.attributegroupid || 0
          );
          newAttribute.setAttributegroupname(
            item.attribute?.attributegroupname || ""
          );
          newAttribute.setAttributevalueid(
            item.attribute?.attributevalueid || 0
          );
          newAttribute.setAttributevaluename(
            item.attribute?.attributevaluename || ""
          );

          const newQuoteGroup = new EstimateQuoteGroup();
          newQuoteGroup.setId(item.quotegroup?.id || "");
          newQuoteGroup.setQuotegroup(item.quotegroup?.quotegroup || "");

          const newBreakout = new ChangeOrderBreakoutMap();
          newBreakout.setBreakoutid(selectedBreakout.id);
          newBreakout.setMultiplier(selectedBreakout.multiplier);
          newBreakout.setBreakoutname(selectedBreakout.breakoutname);

          const newItem = new ChangeOrderAssemblyItem();
          newItem.setItemid(item.itemid);
          newItem.setItemname(item.itemname);
          newItem.setIsquoted(item.isquoted);
          newItem.setQuotegroup(newQuoteGroup);
          newItem.setItemqtyformula(newItemQtyFormula);
          newItem.setAttribute(newAttribute);
          newItem.setCosts(newCosts);
          newItem.setBreakout(newBreakout);

          newItemsList.push(newItem);
        }
        newAssy.setProjectid(selectedChangeOrder.contractid); //in this case this is a projectId
        newAssy.setEstimateid(selectedChangeOrder.changeorderid);
        newAssy.setItemsList(newItemsList);

        setCurrentTakeoffAssembly({
          ...newAssy.toObject(),
        });
      }
    }
  };
  return useHandleSetSelectedTakeoffAssembly;
}
