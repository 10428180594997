import { GetExtendChangeOrderResponse } from "../../api/protosCompiled/changeOrderCloseout/changeOrderCloseout_pb";
import {
  ExtendEstimate,
  GetExtendEstimateResponse,
} from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";

import { useState } from "react";

/**
 * Formats a number to a currency string
 */
export const formatCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format;

/**
 * Formats a number to a string with 2 decimal places
 */
export const formatNumber = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format;

export const sxUnitCostOverrideValue = (item: ExtendEstimate.AsObject) => {
  if (item.isquoted) {
    return {
      backgroundColor: "lightgrey",
      border: "1px solid black",
      borderRadius: "5px",
    };
  }
  if (item.isoverrideitemunitcost) {
    return {
      backgroundColor: "yellow",
      border: "1px solid black",
      borderRadius: "5px",
    };
  }
  if (item.itemunitcost === 0) {
    return {
      backgroundColor: "red",
      border: "1px solid black",
      borderRadius: "5px",
    };
  } else {
    return {
      backgroundColor: "lightgreen",
      border: "1px solid black",
      borderRadius: "5px",
    };
  }
};

export const sxProductionCostOverrideValue = (
  item: ExtendEstimate.AsObject
) => {
  if (item.isoverridehourlyproductionrate) {
    return {
      backgroundColor: "yellow",
      border: "1px solid black",
      borderRadius: "5px",
    };
  }
  if (item.hourlyproductionrate === 0) {
    return {
      backgroundColor: "red",
      border: "1px solid black",
      borderRadius: "5px",
    };
  } else {
    return {
      backgroundColor: "lightgreen",
      border: "1px solid black",
      borderRadius: "5px",
    };
  }
};

export const useCalculateTotalMaterialAndLaborCosts = () => {
  const [totalLaborHours, setTotalLaborHours] = useState<number>(0);
  const [totalMaterialCost, setTotalMaterialCost] = useState<number>(0);

  const calculateTotalMaterialAndLaborCosts = (
    extention:
      | GetExtendEstimateResponse.AsObject
      | GetExtendChangeOrderResponse.AsObject
  ) => {
    if (extention) {
      //reduce the total labor hours
      const totalLaborHours = extention.extendestimatesList.reduce(
        (sum, data) => sum + data.totaldirectlaborhours,
        0
      );
      setTotalLaborHours(totalLaborHours);
      //reduce the total material cost
      const totalMaterialCost = extention.extendestimatesList.reduce(
        (sum, data) => sum + data.totalcost,
        0
      );

      setTotalMaterialCost(totalMaterialCost);
    }
    return { totalLaborHours, totalMaterialCost };
  };

  return calculateTotalMaterialAndLaborCosts;
};

//Perfor safe division
export const safeDivide = (numerator: number, denominator: number) => {
  const value = numerator / denominator;
  if (value === 0) {
    return 0;
  }
  return value;
};

type AllocateCostProps = {
  costToAllocate: number;
  allocationPrecentage: number;
};
export const allocateCost = (props: AllocateCostProps) => {
  return safeDivide(props.costToAllocate * props.allocationPrecentage, 100);
};
