// source: changeOrderCloseout.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.ChangeOrderBreakoutAllocation', null, global);
goog.exportSymbol('proto.CreateDirectLaborChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateDirectLaborChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateEquipmentChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateEquipmentChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateGeneralExpenseChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateGeneralExpenseChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateIncidentalLaborChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateIncidentalLaborChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateIndirectLaborChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateIndirectLaborChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateLaborFactoringChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateLaborFactoringChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateQuoteChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateQuoteChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateSubcontractChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateSubcontractChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteDirectLaborChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteDirectLaborChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteEquipmentChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteEquipmentChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteGeneralExpenseChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteGeneralExpenseChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteIncidentalLaborChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteIncidentalLaborChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteIndirectLaborChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteIndirectLaborChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteLaborFactoringChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteLaborFactoringChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteQuoteChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteQuoteChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteSubcontractChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteSubcontractChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.DirectLaborChangeOrderCloseout', null, global);
goog.exportSymbol('proto.DirectLaborChangeOrderToFinalPrice', null, global);
goog.exportSymbol('proto.EquipmentChangeOrderCloseout', null, global);
goog.exportSymbol('proto.EquipmentChangeOrderToFinalPrice', null, global);
goog.exportSymbol('proto.ExtendChangeOrder', null, global);
goog.exportSymbol('proto.ExtendChangeOrderToFinalPrice', null, global);
goog.exportSymbol('proto.GeneralExpenseChangeOrderCloseout', null, global);
goog.exportSymbol('proto.GeneralExpenseChangeOrderToFinalPrice', null, global);
goog.exportSymbol('proto.GetDirectLaborChangeOrderCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetDirectLaborChangeOrderCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetEquipmentChangeOrderCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetEquipmentChangeOrderCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetExtendChangeOrderRequest', null, global);
goog.exportSymbol('proto.GetExtendChangeOrderResponse', null, global);
goog.exportSymbol('proto.GetGeneralExpenseChangeOrderCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetGeneralExpenseChangeOrderCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetIncidentalLaborChangeOrderCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetIncidentalLaborChangeOrderCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetIndirectLaborChangeOrderCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetIndirectLaborChangeOrderCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetLaborFactoringChangeOrderCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetLaborFactoringChangeOrderCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetQuoteChangeOrderCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetQuoteChangeOrderCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetSubcontractChangeOrderCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetSubcontractChangeOrderCloseoutsResponse', null, global);
goog.exportSymbol('proto.GetTotalFinalPriceChangeOrderCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetTotalFinalPriceChangeOrderCloseoutsResponse', null, global);
goog.exportSymbol('proto.IncidentalLaborChangeOrderCloseout', null, global);
goog.exportSymbol('proto.IncidentalLaborChangeOrderToFinalPrice', null, global);
goog.exportSymbol('proto.IndirectLaborChangeOrderCloseout', null, global);
goog.exportSymbol('proto.IndirectLaborChangeOrderToFinalPrice', null, global);
goog.exportSymbol('proto.LaborFactoringChangeOrderCloseout', null, global);
goog.exportSymbol('proto.LaborFactoringChangeOrderToFinalPrice', null, global);
goog.exportSymbol('proto.QuoteChangeOrderCloseout', null, global);
goog.exportSymbol('proto.QuoteChangeOrderToFinalPrice', null, global);
goog.exportSymbol('proto.SubcontractChangeOrderCloseout', null, global);
goog.exportSymbol('proto.SubcontractChangeOrderToFinalPrice', null, global);
goog.exportSymbol('proto.TotalFinalPriceChangeOrderCloseout', null, global);
goog.exportSymbol('proto.UpdateChangeOrderEquipmentAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateChangeOrderEquipmentAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateChangeOrderGeneralExpenseAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateChangeOrderGeneralExpenseAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateChangeOrderIncidentalLaborAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateChangeOrderIncidentalLaborAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateChangeOrderIndirectLaborAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateChangeOrderIndirectLaborAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateChangeOrderLaborFactoringAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateChangeOrderLaborFactoringAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateChangeOrderQuoteAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateChangeOrderQuoteAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateChangeOrderSubcontractAllocationRequest', null, global);
goog.exportSymbol('proto.UpdateChangeOrderSubcontractAllocationResponse', null, global);
goog.exportSymbol('proto.UpdateDirectLaborChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateDirectLaborChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateEquipmentChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateEquipmentChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateExtendChangeOrderRequest', null, global);
goog.exportSymbol('proto.UpdateExtendChangeOrderResponse', null, global);
goog.exportSymbol('proto.UpdateGeneralExpenseChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateGeneralExpenseChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateIncidentalLaborChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateIncidentalLaborChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateIndirectLaborChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateIndirectLaborChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateLaborFactoringChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateLaborFactoringChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateQuoteChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateQuoteChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateSubcontractChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateSubcontractChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderBreakoutAllocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeOrderBreakoutAllocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderBreakoutAllocation.displayName = 'proto.ChangeOrderBreakoutAllocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderLaborFactoringAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderLaborFactoringAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderLaborFactoringAllocationRequest.displayName = 'proto.UpdateChangeOrderLaborFactoringAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderLaborFactoringAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderLaborFactoringAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderLaborFactoringAllocationResponse.displayName = 'proto.UpdateChangeOrderLaborFactoringAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderIncidentalLaborAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderIncidentalLaborAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderIncidentalLaborAllocationRequest.displayName = 'proto.UpdateChangeOrderIncidentalLaborAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderIncidentalLaborAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderIncidentalLaborAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderIncidentalLaborAllocationResponse.displayName = 'proto.UpdateChangeOrderIncidentalLaborAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderIndirectLaborAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderIndirectLaborAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderIndirectLaborAllocationRequest.displayName = 'proto.UpdateChangeOrderIndirectLaborAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderIndirectLaborAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderIndirectLaborAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderIndirectLaborAllocationResponse.displayName = 'proto.UpdateChangeOrderIndirectLaborAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderEquipmentAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderEquipmentAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderEquipmentAllocationRequest.displayName = 'proto.UpdateChangeOrderEquipmentAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderEquipmentAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderEquipmentAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderEquipmentAllocationResponse.displayName = 'proto.UpdateChangeOrderEquipmentAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderGeneralExpenseAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderGeneralExpenseAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderGeneralExpenseAllocationRequest.displayName = 'proto.UpdateChangeOrderGeneralExpenseAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderGeneralExpenseAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderGeneralExpenseAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderGeneralExpenseAllocationResponse.displayName = 'proto.UpdateChangeOrderGeneralExpenseAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderSubcontractAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderSubcontractAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderSubcontractAllocationRequest.displayName = 'proto.UpdateChangeOrderSubcontractAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderSubcontractAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderSubcontractAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderSubcontractAllocationResponse.displayName = 'proto.UpdateChangeOrderSubcontractAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderQuoteAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderQuoteAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderQuoteAllocationRequest.displayName = 'proto.UpdateChangeOrderQuoteAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderQuoteAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderQuoteAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderQuoteAllocationResponse.displayName = 'proto.UpdateChangeOrderQuoteAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ExtendChangeOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ExtendChangeOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ExtendChangeOrder.displayName = 'proto.ExtendChangeOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetExtendChangeOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetExtendChangeOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetExtendChangeOrderRequest.displayName = 'proto.GetExtendChangeOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetExtendChangeOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetExtendChangeOrderResponse.repeatedFields_, null);
};
goog.inherits(proto.GetExtendChangeOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetExtendChangeOrderResponse.displayName = 'proto.GetExtendChangeOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateExtendChangeOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateExtendChangeOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.UpdateExtendChangeOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateExtendChangeOrderRequest.displayName = 'proto.UpdateExtendChangeOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateExtendChangeOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateExtendChangeOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateExtendChangeOrderResponse.displayName = 'proto.UpdateExtendChangeOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DirectLaborChangeOrderCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DirectLaborChangeOrderCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DirectLaborChangeOrderCloseout.displayName = 'proto.DirectLaborChangeOrderCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LaborFactoringChangeOrderCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.LaborFactoringChangeOrderCloseout.repeatedFields_, null);
};
goog.inherits(proto.LaborFactoringChangeOrderCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LaborFactoringChangeOrderCloseout.displayName = 'proto.LaborFactoringChangeOrderCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IncidentalLaborChangeOrderCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IncidentalLaborChangeOrderCloseout.repeatedFields_, null);
};
goog.inherits(proto.IncidentalLaborChangeOrderCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IncidentalLaborChangeOrderCloseout.displayName = 'proto.IncidentalLaborChangeOrderCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndirectLaborChangeOrderCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IndirectLaborChangeOrderCloseout.repeatedFields_, null);
};
goog.inherits(proto.IndirectLaborChangeOrderCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndirectLaborChangeOrderCloseout.displayName = 'proto.IndirectLaborChangeOrderCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EquipmentChangeOrderCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EquipmentChangeOrderCloseout.repeatedFields_, null);
};
goog.inherits(proto.EquipmentChangeOrderCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EquipmentChangeOrderCloseout.displayName = 'proto.EquipmentChangeOrderCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GeneralExpenseChangeOrderCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GeneralExpenseChangeOrderCloseout.repeatedFields_, null);
};
goog.inherits(proto.GeneralExpenseChangeOrderCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GeneralExpenseChangeOrderCloseout.displayName = 'proto.GeneralExpenseChangeOrderCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SubcontractChangeOrderCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SubcontractChangeOrderCloseout.repeatedFields_, null);
};
goog.inherits(proto.SubcontractChangeOrderCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SubcontractChangeOrderCloseout.displayName = 'proto.SubcontractChangeOrderCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.QuoteChangeOrderCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.QuoteChangeOrderCloseout.repeatedFields_, null);
};
goog.inherits(proto.QuoteChangeOrderCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.QuoteChangeOrderCloseout.displayName = 'proto.QuoteChangeOrderCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TotalFinalPriceChangeOrderCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TotalFinalPriceChangeOrderCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TotalFinalPriceChangeOrderCloseout.displayName = 'proto.TotalFinalPriceChangeOrderCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ExtendChangeOrderToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ExtendChangeOrderToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.ExtendChangeOrderToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ExtendChangeOrderToFinalPrice.displayName = 'proto.ExtendChangeOrderToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DirectLaborChangeOrderToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DirectLaborChangeOrderToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.DirectLaborChangeOrderToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DirectLaborChangeOrderToFinalPrice.displayName = 'proto.DirectLaborChangeOrderToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LaborFactoringChangeOrderToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.LaborFactoringChangeOrderToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.LaborFactoringChangeOrderToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LaborFactoringChangeOrderToFinalPrice.displayName = 'proto.LaborFactoringChangeOrderToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IncidentalLaborChangeOrderToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IncidentalLaborChangeOrderToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.IncidentalLaborChangeOrderToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IncidentalLaborChangeOrderToFinalPrice.displayName = 'proto.IncidentalLaborChangeOrderToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndirectLaborChangeOrderToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IndirectLaborChangeOrderToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.IndirectLaborChangeOrderToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndirectLaborChangeOrderToFinalPrice.displayName = 'proto.IndirectLaborChangeOrderToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EquipmentChangeOrderToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EquipmentChangeOrderToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.EquipmentChangeOrderToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EquipmentChangeOrderToFinalPrice.displayName = 'proto.EquipmentChangeOrderToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GeneralExpenseChangeOrderToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GeneralExpenseChangeOrderToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.GeneralExpenseChangeOrderToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GeneralExpenseChangeOrderToFinalPrice.displayName = 'proto.GeneralExpenseChangeOrderToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SubcontractChangeOrderToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SubcontractChangeOrderToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.SubcontractChangeOrderToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SubcontractChangeOrderToFinalPrice.displayName = 'proto.SubcontractChangeOrderToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.QuoteChangeOrderToFinalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.QuoteChangeOrderToFinalPrice.repeatedFields_, null);
};
goog.inherits(proto.QuoteChangeOrderToFinalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.QuoteChangeOrderToFinalPrice.displayName = 'proto.QuoteChangeOrderToFinalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateDirectLaborChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateDirectLaborChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateDirectLaborChangeOrderCloseoutRequest.displayName = 'proto.CreateDirectLaborChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateDirectLaborChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateDirectLaborChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateDirectLaborChangeOrderCloseoutResponse.displayName = 'proto.CreateDirectLaborChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDirectLaborChangeOrderCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetDirectLaborChangeOrderCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDirectLaborChangeOrderCloseoutsRequest.displayName = 'proto.GetDirectLaborChangeOrderCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDirectLaborChangeOrderCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetDirectLaborChangeOrderCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetDirectLaborChangeOrderCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDirectLaborChangeOrderCloseoutsResponse.displayName = 'proto.GetDirectLaborChangeOrderCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateDirectLaborChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateDirectLaborChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateDirectLaborChangeOrderCloseoutRequest.displayName = 'proto.UpdateDirectLaborChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateDirectLaborChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateDirectLaborChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateDirectLaborChangeOrderCloseoutResponse.displayName = 'proto.UpdateDirectLaborChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDirectLaborChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDirectLaborChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDirectLaborChangeOrderCloseoutRequest.displayName = 'proto.DeleteDirectLaborChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDirectLaborChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDirectLaborChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDirectLaborChangeOrderCloseoutResponse.displayName = 'proto.DeleteDirectLaborChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateLaborFactoringChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateLaborFactoringChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateLaborFactoringChangeOrderCloseoutRequest.displayName = 'proto.CreateLaborFactoringChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateLaborFactoringChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateLaborFactoringChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateLaborFactoringChangeOrderCloseoutResponse.displayName = 'proto.CreateLaborFactoringChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetLaborFactoringChangeOrderCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetLaborFactoringChangeOrderCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetLaborFactoringChangeOrderCloseoutsRequest.displayName = 'proto.GetLaborFactoringChangeOrderCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetLaborFactoringChangeOrderCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetLaborFactoringChangeOrderCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetLaborFactoringChangeOrderCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetLaborFactoringChangeOrderCloseoutsResponse.displayName = 'proto.GetLaborFactoringChangeOrderCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateLaborFactoringChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateLaborFactoringChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateLaborFactoringChangeOrderCloseoutRequest.displayName = 'proto.UpdateLaborFactoringChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateLaborFactoringChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateLaborFactoringChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateLaborFactoringChangeOrderCloseoutResponse.displayName = 'proto.UpdateLaborFactoringChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteLaborFactoringChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteLaborFactoringChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteLaborFactoringChangeOrderCloseoutRequest.displayName = 'proto.DeleteLaborFactoringChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteLaborFactoringChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteLaborFactoringChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteLaborFactoringChangeOrderCloseoutResponse.displayName = 'proto.DeleteLaborFactoringChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateIncidentalLaborChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateIncidentalLaborChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateIncidentalLaborChangeOrderCloseoutRequest.displayName = 'proto.CreateIncidentalLaborChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateIncidentalLaborChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateIncidentalLaborChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateIncidentalLaborChangeOrderCloseoutResponse.displayName = 'proto.CreateIncidentalLaborChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetIncidentalLaborChangeOrderCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetIncidentalLaborChangeOrderCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetIncidentalLaborChangeOrderCloseoutsRequest.displayName = 'proto.GetIncidentalLaborChangeOrderCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetIncidentalLaborChangeOrderCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetIncidentalLaborChangeOrderCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetIncidentalLaborChangeOrderCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetIncidentalLaborChangeOrderCloseoutsResponse.displayName = 'proto.GetIncidentalLaborChangeOrderCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateIncidentalLaborChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.displayName = 'proto.UpdateIncidentalLaborChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateIncidentalLaborChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateIncidentalLaborChangeOrderCloseoutResponse.displayName = 'proto.UpdateIncidentalLaborChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteIncidentalLaborChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.displayName = 'proto.DeleteIncidentalLaborChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteIncidentalLaborChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteIncidentalLaborChangeOrderCloseoutResponse.displayName = 'proto.DeleteIncidentalLaborChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateIndirectLaborChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateIndirectLaborChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateIndirectLaborChangeOrderCloseoutRequest.displayName = 'proto.CreateIndirectLaborChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateIndirectLaborChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateIndirectLaborChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateIndirectLaborChangeOrderCloseoutResponse.displayName = 'proto.CreateIndirectLaborChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetIndirectLaborChangeOrderCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetIndirectLaborChangeOrderCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetIndirectLaborChangeOrderCloseoutsRequest.displayName = 'proto.GetIndirectLaborChangeOrderCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetIndirectLaborChangeOrderCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetIndirectLaborChangeOrderCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetIndirectLaborChangeOrderCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetIndirectLaborChangeOrderCloseoutsResponse.displayName = 'proto.GetIndirectLaborChangeOrderCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateIndirectLaborChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateIndirectLaborChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateIndirectLaborChangeOrderCloseoutRequest.displayName = 'proto.UpdateIndirectLaborChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateIndirectLaborChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateIndirectLaborChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateIndirectLaborChangeOrderCloseoutResponse.displayName = 'proto.UpdateIndirectLaborChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteIndirectLaborChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteIndirectLaborChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteIndirectLaborChangeOrderCloseoutRequest.displayName = 'proto.DeleteIndirectLaborChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteIndirectLaborChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteIndirectLaborChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteIndirectLaborChangeOrderCloseoutResponse.displayName = 'proto.DeleteIndirectLaborChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateEquipmentChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateEquipmentChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateEquipmentChangeOrderCloseoutRequest.displayName = 'proto.CreateEquipmentChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateEquipmentChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateEquipmentChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateEquipmentChangeOrderCloseoutResponse.displayName = 'proto.CreateEquipmentChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetEquipmentChangeOrderCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetEquipmentChangeOrderCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetEquipmentChangeOrderCloseoutsRequest.displayName = 'proto.GetEquipmentChangeOrderCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetEquipmentChangeOrderCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetEquipmentChangeOrderCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetEquipmentChangeOrderCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetEquipmentChangeOrderCloseoutsResponse.displayName = 'proto.GetEquipmentChangeOrderCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEquipmentChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEquipmentChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEquipmentChangeOrderCloseoutRequest.displayName = 'proto.UpdateEquipmentChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEquipmentChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEquipmentChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEquipmentChangeOrderCloseoutResponse.displayName = 'proto.UpdateEquipmentChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteEquipmentChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteEquipmentChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteEquipmentChangeOrderCloseoutRequest.displayName = 'proto.DeleteEquipmentChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteEquipmentChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteEquipmentChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteEquipmentChangeOrderCloseoutResponse.displayName = 'proto.DeleteEquipmentChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateGeneralExpenseChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateGeneralExpenseChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateGeneralExpenseChangeOrderCloseoutRequest.displayName = 'proto.CreateGeneralExpenseChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateGeneralExpenseChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateGeneralExpenseChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateGeneralExpenseChangeOrderCloseoutResponse.displayName = 'proto.CreateGeneralExpenseChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetGeneralExpenseChangeOrderCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetGeneralExpenseChangeOrderCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetGeneralExpenseChangeOrderCloseoutsRequest.displayName = 'proto.GetGeneralExpenseChangeOrderCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetGeneralExpenseChangeOrderCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetGeneralExpenseChangeOrderCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetGeneralExpenseChangeOrderCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetGeneralExpenseChangeOrderCloseoutsResponse.displayName = 'proto.GetGeneralExpenseChangeOrderCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateGeneralExpenseChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.displayName = 'proto.UpdateGeneralExpenseChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateGeneralExpenseChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateGeneralExpenseChangeOrderCloseoutResponse.displayName = 'proto.UpdateGeneralExpenseChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteGeneralExpenseChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.displayName = 'proto.DeleteGeneralExpenseChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteGeneralExpenseChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteGeneralExpenseChangeOrderCloseoutResponse.displayName = 'proto.DeleteGeneralExpenseChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateSubcontractChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateSubcontractChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateSubcontractChangeOrderCloseoutRequest.displayName = 'proto.CreateSubcontractChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateSubcontractChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateSubcontractChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateSubcontractChangeOrderCloseoutResponse.displayName = 'proto.CreateSubcontractChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetSubcontractChangeOrderCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetSubcontractChangeOrderCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetSubcontractChangeOrderCloseoutsRequest.displayName = 'proto.GetSubcontractChangeOrderCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetSubcontractChangeOrderCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetSubcontractChangeOrderCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetSubcontractChangeOrderCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetSubcontractChangeOrderCloseoutsResponse.displayName = 'proto.GetSubcontractChangeOrderCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateSubcontractChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateSubcontractChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateSubcontractChangeOrderCloseoutRequest.displayName = 'proto.UpdateSubcontractChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateSubcontractChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateSubcontractChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateSubcontractChangeOrderCloseoutResponse.displayName = 'proto.UpdateSubcontractChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteSubcontractChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteSubcontractChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteSubcontractChangeOrderCloseoutRequest.displayName = 'proto.DeleteSubcontractChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteSubcontractChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteSubcontractChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteSubcontractChangeOrderCloseoutResponse.displayName = 'proto.DeleteSubcontractChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateQuoteChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateQuoteChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateQuoteChangeOrderCloseoutRequest.displayName = 'proto.CreateQuoteChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateQuoteChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateQuoteChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateQuoteChangeOrderCloseoutResponse.displayName = 'proto.CreateQuoteChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetQuoteChangeOrderCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetQuoteChangeOrderCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetQuoteChangeOrderCloseoutsRequest.displayName = 'proto.GetQuoteChangeOrderCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetQuoteChangeOrderCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetQuoteChangeOrderCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetQuoteChangeOrderCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetQuoteChangeOrderCloseoutsResponse.displayName = 'proto.GetQuoteChangeOrderCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateQuoteChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateQuoteChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateQuoteChangeOrderCloseoutRequest.displayName = 'proto.UpdateQuoteChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateQuoteChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateQuoteChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateQuoteChangeOrderCloseoutResponse.displayName = 'proto.UpdateQuoteChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteQuoteChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteQuoteChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteQuoteChangeOrderCloseoutRequest.displayName = 'proto.DeleteQuoteChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteQuoteChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteQuoteChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteQuoteChangeOrderCloseoutResponse.displayName = 'proto.DeleteQuoteChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetTotalFinalPriceChangeOrderCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.displayName = 'proto.GetTotalFinalPriceChangeOrderCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetTotalFinalPriceChangeOrderCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.displayName = 'proto.GetTotalFinalPriceChangeOrderCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.displayName = 'proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse.displayName = 'proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderBreakoutAllocation.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderBreakoutAllocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderBreakoutAllocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderBreakoutAllocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    breakoutid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    allocationpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    allocationexpense: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderBreakoutAllocation}
 */
proto.ChangeOrderBreakoutAllocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderBreakoutAllocation;
  return proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderBreakoutAllocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderBreakoutAllocation}
 */
proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocationpercentage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocationexpense(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderBreakoutAllocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderBreakoutAllocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBreakoutid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAllocationpercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAllocationexpense();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.ChangeOrderBreakoutAllocation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderBreakoutAllocation} returns this
 */
proto.ChangeOrderBreakoutAllocation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.ChangeOrderBreakoutAllocation.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderBreakoutAllocation} returns this
 */
proto.ChangeOrderBreakoutAllocation.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutId = 3;
 * @return {string}
 */
proto.ChangeOrderBreakoutAllocation.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderBreakoutAllocation} returns this
 */
proto.ChangeOrderBreakoutAllocation.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string breakoutId = 4;
 * @return {string}
 */
proto.ChangeOrderBreakoutAllocation.prototype.getBreakoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderBreakoutAllocation} returns this
 */
proto.ChangeOrderBreakoutAllocation.prototype.setBreakoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float allocationPercentage = 5;
 * @return {number}
 */
proto.ChangeOrderBreakoutAllocation.prototype.getAllocationpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderBreakoutAllocation} returns this
 */
proto.ChangeOrderBreakoutAllocation.prototype.setAllocationpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float allocationExpense = 6;
 * @return {number}
 */
proto.ChangeOrderBreakoutAllocation.prototype.getAllocationexpense = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderBreakoutAllocation} returns this
 */
proto.ChangeOrderBreakoutAllocation.prototype.setAllocationexpense = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderLaborFactoringAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderLaborFactoringAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderLaborFactoringAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderLaborFactoringAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.ChangeOrderBreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderLaborFactoringAllocationRequest}
 */
proto.UpdateChangeOrderLaborFactoringAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderLaborFactoringAllocationRequest;
  return proto.UpdateChangeOrderLaborFactoringAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderLaborFactoringAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderLaborFactoringAllocationRequest}
 */
proto.UpdateChangeOrderLaborFactoringAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderLaborFactoringAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderLaborFactoringAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderLaborFactoringAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderLaborFactoringAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChangeOrderBreakoutAllocation allocation = 1;
 * @return {?proto.ChangeOrderBreakoutAllocation}
 */
proto.UpdateChangeOrderLaborFactoringAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.ChangeOrderBreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderBreakoutAllocation, 1));
};


/**
 * @param {?proto.ChangeOrderBreakoutAllocation|undefined} value
 * @return {!proto.UpdateChangeOrderLaborFactoringAllocationRequest} returns this
*/
proto.UpdateChangeOrderLaborFactoringAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateChangeOrderLaborFactoringAllocationRequest} returns this
 */
proto.UpdateChangeOrderLaborFactoringAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateChangeOrderLaborFactoringAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateChangeOrderLaborFactoringAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateChangeOrderLaborFactoringAllocationRequest} returns this
 */
proto.UpdateChangeOrderLaborFactoringAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderLaborFactoringAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderLaborFactoringAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderLaborFactoringAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderLaborFactoringAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderLaborFactoringAllocationResponse}
 */
proto.UpdateChangeOrderLaborFactoringAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderLaborFactoringAllocationResponse;
  return proto.UpdateChangeOrderLaborFactoringAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderLaborFactoringAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderLaborFactoringAllocationResponse}
 */
proto.UpdateChangeOrderLaborFactoringAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderLaborFactoringAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderLaborFactoringAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderLaborFactoringAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderLaborFactoringAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateChangeOrderLaborFactoringAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateChangeOrderLaborFactoringAllocationResponse} returns this
 */
proto.UpdateChangeOrderLaborFactoringAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderIncidentalLaborAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderIncidentalLaborAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderIncidentalLaborAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderIncidentalLaborAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.ChangeOrderBreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderIncidentalLaborAllocationRequest}
 */
proto.UpdateChangeOrderIncidentalLaborAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderIncidentalLaborAllocationRequest;
  return proto.UpdateChangeOrderIncidentalLaborAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderIncidentalLaborAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderIncidentalLaborAllocationRequest}
 */
proto.UpdateChangeOrderIncidentalLaborAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderIncidentalLaborAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderIncidentalLaborAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderIncidentalLaborAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderIncidentalLaborAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChangeOrderBreakoutAllocation allocation = 1;
 * @return {?proto.ChangeOrderBreakoutAllocation}
 */
proto.UpdateChangeOrderIncidentalLaborAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.ChangeOrderBreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderBreakoutAllocation, 1));
};


/**
 * @param {?proto.ChangeOrderBreakoutAllocation|undefined} value
 * @return {!proto.UpdateChangeOrderIncidentalLaborAllocationRequest} returns this
*/
proto.UpdateChangeOrderIncidentalLaborAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateChangeOrderIncidentalLaborAllocationRequest} returns this
 */
proto.UpdateChangeOrderIncidentalLaborAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateChangeOrderIncidentalLaborAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateChangeOrderIncidentalLaborAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateChangeOrderIncidentalLaborAllocationRequest} returns this
 */
proto.UpdateChangeOrderIncidentalLaborAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderIncidentalLaborAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderIncidentalLaborAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderIncidentalLaborAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderIncidentalLaborAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderIncidentalLaborAllocationResponse}
 */
proto.UpdateChangeOrderIncidentalLaborAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderIncidentalLaborAllocationResponse;
  return proto.UpdateChangeOrderIncidentalLaborAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderIncidentalLaborAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderIncidentalLaborAllocationResponse}
 */
proto.UpdateChangeOrderIncidentalLaborAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderIncidentalLaborAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderIncidentalLaborAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderIncidentalLaborAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderIncidentalLaborAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateChangeOrderIncidentalLaborAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateChangeOrderIncidentalLaborAllocationResponse} returns this
 */
proto.UpdateChangeOrderIncidentalLaborAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderIndirectLaborAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderIndirectLaborAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderIndirectLaborAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderIndirectLaborAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.ChangeOrderBreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderIndirectLaborAllocationRequest}
 */
proto.UpdateChangeOrderIndirectLaborAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderIndirectLaborAllocationRequest;
  return proto.UpdateChangeOrderIndirectLaborAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderIndirectLaborAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderIndirectLaborAllocationRequest}
 */
proto.UpdateChangeOrderIndirectLaborAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderIndirectLaborAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderIndirectLaborAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderIndirectLaborAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderIndirectLaborAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChangeOrderBreakoutAllocation allocation = 1;
 * @return {?proto.ChangeOrderBreakoutAllocation}
 */
proto.UpdateChangeOrderIndirectLaborAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.ChangeOrderBreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderBreakoutAllocation, 1));
};


/**
 * @param {?proto.ChangeOrderBreakoutAllocation|undefined} value
 * @return {!proto.UpdateChangeOrderIndirectLaborAllocationRequest} returns this
*/
proto.UpdateChangeOrderIndirectLaborAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateChangeOrderIndirectLaborAllocationRequest} returns this
 */
proto.UpdateChangeOrderIndirectLaborAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateChangeOrderIndirectLaborAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateChangeOrderIndirectLaborAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateChangeOrderIndirectLaborAllocationRequest} returns this
 */
proto.UpdateChangeOrderIndirectLaborAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderIndirectLaborAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderIndirectLaborAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderIndirectLaborAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderIndirectLaborAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderIndirectLaborAllocationResponse}
 */
proto.UpdateChangeOrderIndirectLaborAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderIndirectLaborAllocationResponse;
  return proto.UpdateChangeOrderIndirectLaborAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderIndirectLaborAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderIndirectLaborAllocationResponse}
 */
proto.UpdateChangeOrderIndirectLaborAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderIndirectLaborAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderIndirectLaborAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderIndirectLaborAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderIndirectLaborAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateChangeOrderIndirectLaborAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateChangeOrderIndirectLaborAllocationResponse} returns this
 */
proto.UpdateChangeOrderIndirectLaborAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderEquipmentAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderEquipmentAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderEquipmentAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderEquipmentAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.ChangeOrderBreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderEquipmentAllocationRequest}
 */
proto.UpdateChangeOrderEquipmentAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderEquipmentAllocationRequest;
  return proto.UpdateChangeOrderEquipmentAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderEquipmentAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderEquipmentAllocationRequest}
 */
proto.UpdateChangeOrderEquipmentAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderEquipmentAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderEquipmentAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderEquipmentAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderEquipmentAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChangeOrderBreakoutAllocation allocation = 1;
 * @return {?proto.ChangeOrderBreakoutAllocation}
 */
proto.UpdateChangeOrderEquipmentAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.ChangeOrderBreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderBreakoutAllocation, 1));
};


/**
 * @param {?proto.ChangeOrderBreakoutAllocation|undefined} value
 * @return {!proto.UpdateChangeOrderEquipmentAllocationRequest} returns this
*/
proto.UpdateChangeOrderEquipmentAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateChangeOrderEquipmentAllocationRequest} returns this
 */
proto.UpdateChangeOrderEquipmentAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateChangeOrderEquipmentAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateChangeOrderEquipmentAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateChangeOrderEquipmentAllocationRequest} returns this
 */
proto.UpdateChangeOrderEquipmentAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderEquipmentAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderEquipmentAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderEquipmentAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderEquipmentAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderEquipmentAllocationResponse}
 */
proto.UpdateChangeOrderEquipmentAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderEquipmentAllocationResponse;
  return proto.UpdateChangeOrderEquipmentAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderEquipmentAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderEquipmentAllocationResponse}
 */
proto.UpdateChangeOrderEquipmentAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderEquipmentAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderEquipmentAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderEquipmentAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderEquipmentAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateChangeOrderEquipmentAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateChangeOrderEquipmentAllocationResponse} returns this
 */
proto.UpdateChangeOrderEquipmentAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderGeneralExpenseAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderGeneralExpenseAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderGeneralExpenseAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderGeneralExpenseAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.ChangeOrderBreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderGeneralExpenseAllocationRequest}
 */
proto.UpdateChangeOrderGeneralExpenseAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderGeneralExpenseAllocationRequest;
  return proto.UpdateChangeOrderGeneralExpenseAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderGeneralExpenseAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderGeneralExpenseAllocationRequest}
 */
proto.UpdateChangeOrderGeneralExpenseAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderGeneralExpenseAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderGeneralExpenseAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderGeneralExpenseAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderGeneralExpenseAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChangeOrderBreakoutAllocation allocation = 1;
 * @return {?proto.ChangeOrderBreakoutAllocation}
 */
proto.UpdateChangeOrderGeneralExpenseAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.ChangeOrderBreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderBreakoutAllocation, 1));
};


/**
 * @param {?proto.ChangeOrderBreakoutAllocation|undefined} value
 * @return {!proto.UpdateChangeOrderGeneralExpenseAllocationRequest} returns this
*/
proto.UpdateChangeOrderGeneralExpenseAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateChangeOrderGeneralExpenseAllocationRequest} returns this
 */
proto.UpdateChangeOrderGeneralExpenseAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateChangeOrderGeneralExpenseAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateChangeOrderGeneralExpenseAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateChangeOrderGeneralExpenseAllocationRequest} returns this
 */
proto.UpdateChangeOrderGeneralExpenseAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderGeneralExpenseAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderGeneralExpenseAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderGeneralExpenseAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderGeneralExpenseAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderGeneralExpenseAllocationResponse}
 */
proto.UpdateChangeOrderGeneralExpenseAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderGeneralExpenseAllocationResponse;
  return proto.UpdateChangeOrderGeneralExpenseAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderGeneralExpenseAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderGeneralExpenseAllocationResponse}
 */
proto.UpdateChangeOrderGeneralExpenseAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderGeneralExpenseAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderGeneralExpenseAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderGeneralExpenseAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderGeneralExpenseAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateChangeOrderGeneralExpenseAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateChangeOrderGeneralExpenseAllocationResponse} returns this
 */
proto.UpdateChangeOrderGeneralExpenseAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderSubcontractAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderSubcontractAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderSubcontractAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderSubcontractAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.ChangeOrderBreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderSubcontractAllocationRequest}
 */
proto.UpdateChangeOrderSubcontractAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderSubcontractAllocationRequest;
  return proto.UpdateChangeOrderSubcontractAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderSubcontractAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderSubcontractAllocationRequest}
 */
proto.UpdateChangeOrderSubcontractAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderSubcontractAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderSubcontractAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderSubcontractAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderSubcontractAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChangeOrderBreakoutAllocation allocation = 1;
 * @return {?proto.ChangeOrderBreakoutAllocation}
 */
proto.UpdateChangeOrderSubcontractAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.ChangeOrderBreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderBreakoutAllocation, 1));
};


/**
 * @param {?proto.ChangeOrderBreakoutAllocation|undefined} value
 * @return {!proto.UpdateChangeOrderSubcontractAllocationRequest} returns this
*/
proto.UpdateChangeOrderSubcontractAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateChangeOrderSubcontractAllocationRequest} returns this
 */
proto.UpdateChangeOrderSubcontractAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateChangeOrderSubcontractAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateChangeOrderSubcontractAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateChangeOrderSubcontractAllocationRequest} returns this
 */
proto.UpdateChangeOrderSubcontractAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderSubcontractAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderSubcontractAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderSubcontractAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderSubcontractAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderSubcontractAllocationResponse}
 */
proto.UpdateChangeOrderSubcontractAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderSubcontractAllocationResponse;
  return proto.UpdateChangeOrderSubcontractAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderSubcontractAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderSubcontractAllocationResponse}
 */
proto.UpdateChangeOrderSubcontractAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderSubcontractAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderSubcontractAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderSubcontractAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderSubcontractAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateChangeOrderSubcontractAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateChangeOrderSubcontractAllocationResponse} returns this
 */
proto.UpdateChangeOrderSubcontractAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderQuoteAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderQuoteAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderQuoteAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderQuoteAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocation: (f = msg.getAllocation()) && proto.ChangeOrderBreakoutAllocation.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderQuoteAllocationRequest}
 */
proto.UpdateChangeOrderQuoteAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderQuoteAllocationRequest;
  return proto.UpdateChangeOrderQuoteAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderQuoteAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderQuoteAllocationRequest}
 */
proto.UpdateChangeOrderQuoteAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.setAllocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderQuoteAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderQuoteAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderQuoteAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderQuoteAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChangeOrderBreakoutAllocation allocation = 1;
 * @return {?proto.ChangeOrderBreakoutAllocation}
 */
proto.UpdateChangeOrderQuoteAllocationRequest.prototype.getAllocation = function() {
  return /** @type{?proto.ChangeOrderBreakoutAllocation} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderBreakoutAllocation, 1));
};


/**
 * @param {?proto.ChangeOrderBreakoutAllocation|undefined} value
 * @return {!proto.UpdateChangeOrderQuoteAllocationRequest} returns this
*/
proto.UpdateChangeOrderQuoteAllocationRequest.prototype.setAllocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateChangeOrderQuoteAllocationRequest} returns this
 */
proto.UpdateChangeOrderQuoteAllocationRequest.prototype.clearAllocation = function() {
  return this.setAllocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateChangeOrderQuoteAllocationRequest.prototype.hasAllocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateChangeOrderQuoteAllocationRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateChangeOrderQuoteAllocationRequest} returns this
 */
proto.UpdateChangeOrderQuoteAllocationRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderQuoteAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderQuoteAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderQuoteAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderQuoteAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderQuoteAllocationResponse}
 */
proto.UpdateChangeOrderQuoteAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderQuoteAllocationResponse;
  return proto.UpdateChangeOrderQuoteAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderQuoteAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderQuoteAllocationResponse}
 */
proto.UpdateChangeOrderQuoteAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderQuoteAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderQuoteAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderQuoteAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderQuoteAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateChangeOrderQuoteAllocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateChangeOrderQuoteAllocationResponse} returns this
 */
proto.UpdateChangeOrderQuoteAllocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ExtendChangeOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.ExtendChangeOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ExtendChangeOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExtendChangeOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    extensionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isoverrideitemunitcost: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    overrideitemunitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    isoverridehourlyproductionrate: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    overridehourlyproductionrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalitemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    totaldirectlaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    breakoutid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    itemunitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    hourlyproductionrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    uomid: jspb.Message.getFieldWithDefault(msg, 15, 0),
    attributegroupid: jspb.Message.getFieldWithDefault(msg, 16, ""),
    attributevaluename: jspb.Message.getFieldWithDefault(msg, 17, ""),
    attributegroupname: jspb.Message.getFieldWithDefault(msg, 18, ""),
    breakoutname: jspb.Message.getFieldWithDefault(msg, 19, ""),
    itemname: jspb.Message.getFieldWithDefault(msg, 20, ""),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    itemuomvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    itemuomname: jspb.Message.getFieldWithDefault(msg, 23, ""),
    assemblyid: jspb.Message.getFieldWithDefault(msg, 24, ""),
    assemblyname: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ExtendChangeOrder}
 */
proto.ExtendChangeOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ExtendChangeOrder;
  return proto.ExtendChangeOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ExtendChangeOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ExtendChangeOrder}
 */
proto.ExtendChangeOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemid(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsoverrideitemunitcost(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverrideitemunitcost(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsoverridehourlyproductionrate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverridehourlyproductionrate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalitemqty(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaldirectlaborhours(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutid(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemunitcost(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHourlyproductionrate(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUomid(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupid(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributevaluename(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupname(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutname(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemname(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemuomvalue(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemuomname(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ExtendChangeOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ExtendChangeOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ExtendChangeOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExtendChangeOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtensionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getItemid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsoverrideitemunitcost();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOverrideitemunitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getIsoverridehourlyproductionrate();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getOverridehourlyproductionrate();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalitemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getTotaldirectlaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getBreakoutid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getItemunitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getHourlyproductionrate();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getUomid();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getAttributegroupid();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAttributevaluename();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getAttributegroupname();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBreakoutname();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getItemname();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getItemuomvalue();
  if (f !== 0.0) {
    writer.writeFloat(
      22,
      f
    );
  }
  f = message.getItemuomname();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getAssemblyname();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional string extensionId = 1;
 * @return {string}
 */
proto.ExtendChangeOrder.prototype.getExtensionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setExtensionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.ExtendChangeOrder.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 attributeValueId = 3;
 * @return {number}
 */
proto.ExtendChangeOrder.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string itemId = 4;
 * @return {string}
 */
proto.ExtendChangeOrder.prototype.getItemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setItemid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isOverrideItemUnitCost = 5;
 * @return {boolean}
 */
proto.ExtendChangeOrder.prototype.getIsoverrideitemunitcost = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setIsoverrideitemunitcost = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional float overrideItemUnitCost = 6;
 * @return {number}
 */
proto.ExtendChangeOrder.prototype.getOverrideitemunitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setOverrideitemunitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional bool isOverrideHourlyProductionRate = 7;
 * @return {boolean}
 */
proto.ExtendChangeOrder.prototype.getIsoverridehourlyproductionrate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setIsoverridehourlyproductionrate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional float overrideHourlyProductionRate = 8;
 * @return {number}
 */
proto.ExtendChangeOrder.prototype.getOverridehourlyproductionrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setOverridehourlyproductionrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalItemQty = 9;
 * @return {number}
 */
proto.ExtendChangeOrder.prototype.getTotalitemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setTotalitemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float totalCost = 10;
 * @return {number}
 */
proto.ExtendChangeOrder.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float totalDirectLaborHours = 11;
 * @return {number}
 */
proto.ExtendChangeOrder.prototype.getTotaldirectlaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setTotaldirectlaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional string breakoutId = 12;
 * @return {string}
 */
proto.ExtendChangeOrder.prototype.getBreakoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setBreakoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional float itemUnitCost = 13;
 * @return {number}
 */
proto.ExtendChangeOrder.prototype.getItemunitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setItemunitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float hourlyProductionRate = 14;
 * @return {number}
 */
proto.ExtendChangeOrder.prototype.getHourlyproductionrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setHourlyproductionrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional int32 uomId = 15;
 * @return {number}
 */
proto.ExtendChangeOrder.prototype.getUomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setUomid = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string attributeGroupId = 16;
 * @return {string}
 */
proto.ExtendChangeOrder.prototype.getAttributegroupid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setAttributegroupid = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string attributeValueName = 17;
 * @return {string}
 */
proto.ExtendChangeOrder.prototype.getAttributevaluename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setAttributevaluename = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string attributeGroupName = 18;
 * @return {string}
 */
proto.ExtendChangeOrder.prototype.getAttributegroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setAttributegroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string breakoutName = 19;
 * @return {string}
 */
proto.ExtendChangeOrder.prototype.getBreakoutname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setBreakoutname = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string itemName = 20;
 * @return {string}
 */
proto.ExtendChangeOrder.prototype.getItemname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setItemname = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool isQuoted = 21;
 * @return {boolean}
 */
proto.ExtendChangeOrder.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional float itemUOMValue = 22;
 * @return {number}
 */
proto.ExtendChangeOrder.prototype.getItemuomvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setItemuomvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional string itemUOMName = 23;
 * @return {string}
 */
proto.ExtendChangeOrder.prototype.getItemuomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setItemuomname = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string assemblyId = 24;
 * @return {string}
 */
proto.ExtendChangeOrder.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string assemblyName = 25;
 * @return {string}
 */
proto.ExtendChangeOrder.prototype.getAssemblyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtendChangeOrder} returns this
 */
proto.ExtendChangeOrder.prototype.setAssemblyname = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetExtendChangeOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetExtendChangeOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetExtendChangeOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetExtendChangeOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetExtendChangeOrderRequest}
 */
proto.GetExtendChangeOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetExtendChangeOrderRequest;
  return proto.GetExtendChangeOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetExtendChangeOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetExtendChangeOrderRequest}
 */
proto.GetExtendChangeOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetExtendChangeOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetExtendChangeOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetExtendChangeOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetExtendChangeOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetExtendChangeOrderRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetExtendChangeOrderRequest} returns this
 */
proto.GetExtendChangeOrderRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetExtendChangeOrderRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetExtendChangeOrderRequest} returns this
 */
proto.GetExtendChangeOrderRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetExtendChangeOrderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetExtendChangeOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetExtendChangeOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetExtendChangeOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetExtendChangeOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    extendestimatesList: jspb.Message.toObjectList(msg.getExtendestimatesList(),
    proto.ExtendChangeOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetExtendChangeOrderResponse}
 */
proto.GetExtendChangeOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetExtendChangeOrderResponse;
  return proto.GetExtendChangeOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetExtendChangeOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetExtendChangeOrderResponse}
 */
proto.GetExtendChangeOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExtendChangeOrder;
      reader.readMessage(value,proto.ExtendChangeOrder.deserializeBinaryFromReader);
      msg.addExtendestimates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetExtendChangeOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetExtendChangeOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetExtendChangeOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetExtendChangeOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtendestimatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ExtendChangeOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ExtendChangeOrder extendEstimates = 1;
 * @return {!Array<!proto.ExtendChangeOrder>}
 */
proto.GetExtendChangeOrderResponse.prototype.getExtendestimatesList = function() {
  return /** @type{!Array<!proto.ExtendChangeOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ExtendChangeOrder, 1));
};


/**
 * @param {!Array<!proto.ExtendChangeOrder>} value
 * @return {!proto.GetExtendChangeOrderResponse} returns this
*/
proto.GetExtendChangeOrderResponse.prototype.setExtendestimatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ExtendChangeOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ExtendChangeOrder}
 */
proto.GetExtendChangeOrderResponse.prototype.addExtendestimates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ExtendChangeOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetExtendChangeOrderResponse} returns this
 */
proto.GetExtendChangeOrderResponse.prototype.clearExtendestimatesList = function() {
  return this.setExtendestimatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UpdateExtendChangeOrderRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateExtendChangeOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateExtendChangeOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateExtendChangeOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateExtendChangeOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    extendestimatesList: jspb.Message.toObjectList(msg.getExtendestimatesList(),
    proto.ExtendChangeOrder.toObject, includeInstance),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateExtendChangeOrderRequest}
 */
proto.UpdateExtendChangeOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateExtendChangeOrderRequest;
  return proto.UpdateExtendChangeOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateExtendChangeOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateExtendChangeOrderRequest}
 */
proto.UpdateExtendChangeOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExtendChangeOrder;
      reader.readMessage(value,proto.ExtendChangeOrder.deserializeBinaryFromReader);
      msg.addExtendestimates(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateExtendChangeOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateExtendChangeOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateExtendChangeOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateExtendChangeOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtendestimatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ExtendChangeOrder.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated ExtendChangeOrder extendEstimates = 1;
 * @return {!Array<!proto.ExtendChangeOrder>}
 */
proto.UpdateExtendChangeOrderRequest.prototype.getExtendestimatesList = function() {
  return /** @type{!Array<!proto.ExtendChangeOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ExtendChangeOrder, 1));
};


/**
 * @param {!Array<!proto.ExtendChangeOrder>} value
 * @return {!proto.UpdateExtendChangeOrderRequest} returns this
*/
proto.UpdateExtendChangeOrderRequest.prototype.setExtendestimatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ExtendChangeOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ExtendChangeOrder}
 */
proto.UpdateExtendChangeOrderRequest.prototype.addExtendestimates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ExtendChangeOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateExtendChangeOrderRequest} returns this
 */
proto.UpdateExtendChangeOrderRequest.prototype.clearExtendestimatesList = function() {
  return this.setExtendestimatesList([]);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateExtendChangeOrderRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateExtendChangeOrderRequest} returns this
 */
proto.UpdateExtendChangeOrderRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateExtendChangeOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateExtendChangeOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateExtendChangeOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateExtendChangeOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateExtendChangeOrderResponse}
 */
proto.UpdateExtendChangeOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateExtendChangeOrderResponse;
  return proto.UpdateExtendChangeOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateExtendChangeOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateExtendChangeOrderResponse}
 */
proto.UpdateExtendChangeOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateExtendChangeOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateExtendChangeOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateExtendChangeOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateExtendChangeOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateExtendChangeOrderResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateExtendChangeOrderResponse} returns this
 */
proto.UpdateExtendChangeOrderResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DirectLaborChangeOrderCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.DirectLaborChangeOrderCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DirectLaborChangeOrderCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DirectLaborChangeOrderCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultdirectlaborid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    distributionpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 12, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 13, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    subtotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    burdentotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    fringetotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    totalhourlycost: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    totaldirectlaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DirectLaborChangeOrderCloseout}
 */
proto.DirectLaborChangeOrderCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DirectLaborChangeOrderCloseout;
  return proto.DirectLaborChangeOrderCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DirectLaborChangeOrderCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DirectLaborChangeOrderCloseout}
 */
proto.DirectLaborChangeOrderCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultdirectlaborid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistributionpercent(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSubtotalcost(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdentotalcost(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringetotalcost(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalhourlycost(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaldirectlaborhours(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DirectLaborChangeOrderCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DirectLaborChangeOrderCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DirectLaborChangeOrderCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DirectLaborChangeOrderCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultdirectlaborid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getDistributionpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getSubtotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getBurdentotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getFringetotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getTotalhourlycost();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getTotaldirectlaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultDirectLaborId = 3;
 * @return {string}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getCloseoutdefaultdirectlaborid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setCloseoutdefaultdirectlaborid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float laborRate = 5;
 * @return {number}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float burdenPercent = 6;
 * @return {number}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float fringe = 7;
 * @return {number}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float distributionPercent = 8;
 * @return {number}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getDistributionpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setDistributionpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string createdByUserId = 9;
 * @return {string}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string createdByAccountId = 10;
 * @return {string}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string dateCreated = 11;
 * @return {string}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string lastUpdated = 12;
 * @return {string}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string lastUpdatedByUserId = 13;
 * @return {string}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional float allocatedHours = 14;
 * @return {number}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float subtotalCost = 15;
 * @return {number}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getSubtotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setSubtotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float burdenTotalCost = 16;
 * @return {number}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getBurdentotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setBurdentotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float fringeTotalCost = 17;
 * @return {number}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getFringetotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setFringetotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float totalHourlyCost = 18;
 * @return {number}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getTotalhourlycost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setTotalhourlycost = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float totalCost = 19;
 * @return {number}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional float totalDirectLaborHours = 20;
 * @return {number}
 */
proto.DirectLaborChangeOrderCloseout.prototype.getTotaldirectlaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderCloseout} returns this
 */
proto.DirectLaborChangeOrderCloseout.prototype.setTotaldirectlaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LaborFactoringChangeOrderCloseout.repeatedFields_ = [22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.LaborFactoringChangeOrderCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LaborFactoringChangeOrderCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LaborFactoringChangeOrderCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultlaborfactoringid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    impactpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    laborpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 12, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 13, ""),
    name: jspb.Message.getFieldWithDefault(msg, 14, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    subtotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    burdentotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    fringetotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    totalhourlycost: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    totaldirectlaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.ChangeOrderBreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LaborFactoringChangeOrderCloseout}
 */
proto.LaborFactoringChangeOrderCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LaborFactoringChangeOrderCloseout;
  return proto.LaborFactoringChangeOrderCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LaborFactoringChangeOrderCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LaborFactoringChangeOrderCloseout}
 */
proto.LaborFactoringChangeOrderCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultlaborfactoringid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setImpactpercent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborpercent(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSubtotalcost(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdentotalcost(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringetotalcost(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalhourlycost(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaldirectlaborhours(value);
      break;
    case 22:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LaborFactoringChangeOrderCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LaborFactoringChangeOrderCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LaborFactoringChangeOrderCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultlaborfactoringid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getImpactpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getLaborpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getSubtotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getBurdentotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getFringetotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getTotalhourlycost();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getTotaldirectlaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      21,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultLaborFactoringId = 3;
 * @return {string}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getCloseoutdefaultlaborfactoringid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setCloseoutdefaultlaborfactoringid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float laborRate = 4;
 * @return {number}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float burdenPercent = 5;
 * @return {number}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float fringe = 6;
 * @return {number}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float impactPercent = 7;
 * @return {number}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getImpactpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setImpactpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float laborPercent = 8;
 * @return {number}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getLaborpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setLaborpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string createdByUserId = 9;
 * @return {string}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string createdByAccountId = 10;
 * @return {string}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string dateCreated = 11;
 * @return {string}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string lastUpdated = 12;
 * @return {string}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string lastUpdatedByUserId = 13;
 * @return {string}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string name = 14;
 * @return {string}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional float allocatedHours = 15;
 * @return {number}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float subtotalCost = 16;
 * @return {number}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getSubtotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setSubtotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float burdenTotalCost = 17;
 * @return {number}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getBurdentotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setBurdentotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float fringeTotalCost = 18;
 * @return {number}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getFringetotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setFringetotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float totalHourlyCost = 19;
 * @return {number}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getTotalhourlycost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setTotalhourlycost = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional float totalCost = 20;
 * @return {number}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional float totalDirectLaborHours = 21;
 * @return {number}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getTotaldirectlaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.setTotaldirectlaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * repeated ChangeOrderBreakoutAllocation allocation = 22;
 * @return {!Array<!proto.ChangeOrderBreakoutAllocation>}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.ChangeOrderBreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderBreakoutAllocation, 22));
};


/**
 * @param {!Array<!proto.ChangeOrderBreakoutAllocation>} value
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
*/
proto.LaborFactoringChangeOrderCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.ChangeOrderBreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderBreakoutAllocation}
 */
proto.LaborFactoringChangeOrderCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.ChangeOrderBreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LaborFactoringChangeOrderCloseout} returns this
 */
proto.LaborFactoringChangeOrderCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IncidentalLaborChangeOrderCloseout.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.IncidentalLaborChangeOrderCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IncidentalLaborChangeOrderCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IncidentalLaborChangeOrderCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultincidentallaborid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 10, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    name: jspb.Message.getFieldWithDefault(msg, 13, ""),
    subtotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    burdentotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    fringetotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    totalhourlycost: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    totaldirectlaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.ChangeOrderBreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IncidentalLaborChangeOrderCloseout}
 */
proto.IncidentalLaborChangeOrderCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IncidentalLaborChangeOrderCloseout;
  return proto.IncidentalLaborChangeOrderCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IncidentalLaborChangeOrderCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IncidentalLaborChangeOrderCloseout}
 */
proto.IncidentalLaborChangeOrderCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultincidentallaborid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSubtotalcost(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdentotalcost(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringetotalcost(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalhourlycost(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaldirectlaborhours(value);
      break;
    case 20:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IncidentalLaborChangeOrderCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IncidentalLaborChangeOrderCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IncidentalLaborChangeOrderCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultincidentallaborid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSubtotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getBurdentotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getFringetotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getTotalhourlycost();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getTotaldirectlaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultIncidentalLaborId = 3;
 * @return {string}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getCloseoutdefaultincidentallaborid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setCloseoutdefaultincidentallaborid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float allocatedHours = 4;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float laborRate = 5;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float burdenPercent = 6;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float fringe = 7;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string createdByUserId = 8;
 * @return {string}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string createdByAccountId = 9;
 * @return {string}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string dateCreated = 10;
 * @return {string}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string lastUpdated = 11;
 * @return {string}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string lastUpdatedByUserId = 12;
 * @return {string}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string name = 13;
 * @return {string}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional float subtotalCost = 14;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getSubtotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setSubtotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float burdenTotalCost = 15;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getBurdentotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setBurdentotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float fringeTotalCost = 16;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getFringetotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setFringetotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float totalHourlyCost = 17;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getTotalhourlycost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setTotalhourlycost = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float totalCost = 18;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float totalDirectLaborHours = 19;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getTotaldirectlaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.setTotaldirectlaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * repeated ChangeOrderBreakoutAllocation allocation = 20;
 * @return {!Array<!proto.ChangeOrderBreakoutAllocation>}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.ChangeOrderBreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderBreakoutAllocation, 20));
};


/**
 * @param {!Array<!proto.ChangeOrderBreakoutAllocation>} value
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
*/
proto.IncidentalLaborChangeOrderCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.ChangeOrderBreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderBreakoutAllocation}
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.ChangeOrderBreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IncidentalLaborChangeOrderCloseout} returns this
 */
proto.IncidentalLaborChangeOrderCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IndirectLaborChangeOrderCloseout.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.IndirectLaborChangeOrderCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndirectLaborChangeOrderCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndirectLaborChangeOrderCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultindirectlaborid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    percentageofdirectlabor: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 10, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    name: jspb.Message.getFieldWithDefault(msg, 13, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    subtotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    burdentotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    fringetotalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    totalhourlycost: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    totaldirectlaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.ChangeOrderBreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndirectLaborChangeOrderCloseout}
 */
proto.IndirectLaborChangeOrderCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndirectLaborChangeOrderCloseout;
  return proto.IndirectLaborChangeOrderCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndirectLaborChangeOrderCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndirectLaborChangeOrderCloseout}
 */
proto.IndirectLaborChangeOrderCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultindirectlaborid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPercentageofdirectlabor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSubtotalcost(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdentotalcost(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringetotalcost(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalhourlycost(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaldirectlaborhours(value);
      break;
    case 21:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndirectLaborChangeOrderCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndirectLaborChangeOrderCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndirectLaborChangeOrderCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultindirectlaborid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPercentageofdirectlabor();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getSubtotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getBurdentotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getFringetotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getTotalhourlycost();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getTotaldirectlaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultIndirectLaborId = 3;
 * @return {string}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getCloseoutdefaultindirectlaborid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setCloseoutdefaultindirectlaborid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float percentageOfDirectLabor = 4;
 * @return {number}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getPercentageofdirectlabor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setPercentageofdirectlabor = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float laborRate = 5;
 * @return {number}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float burdenPercent = 6;
 * @return {number}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float fringe = 7;
 * @return {number}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string createdByUserId = 8;
 * @return {string}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string createdByAccountId = 9;
 * @return {string}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string dateCreated = 10;
 * @return {string}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string lastUpdated = 11;
 * @return {string}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string lastUpdatedByUserId = 12;
 * @return {string}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string name = 13;
 * @return {string}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional float allocatedHours = 14;
 * @return {number}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float subtotalCost = 15;
 * @return {number}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getSubtotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setSubtotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float burdenTotalCost = 16;
 * @return {number}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getBurdentotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setBurdentotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float fringeTotalCost = 17;
 * @return {number}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getFringetotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setFringetotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float totalHourlyCost = 18;
 * @return {number}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getTotalhourlycost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setTotalhourlycost = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float totalCost = 19;
 * @return {number}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional float totalDirectLaborHours = 20;
 * @return {number}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getTotaldirectlaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.setTotaldirectlaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * repeated ChangeOrderBreakoutAllocation allocation = 21;
 * @return {!Array<!proto.ChangeOrderBreakoutAllocation>}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.ChangeOrderBreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderBreakoutAllocation, 21));
};


/**
 * @param {!Array<!proto.ChangeOrderBreakoutAllocation>} value
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
*/
proto.IndirectLaborChangeOrderCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.ChangeOrderBreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderBreakoutAllocation}
 */
proto.IndirectLaborChangeOrderCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.ChangeOrderBreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IndirectLaborChangeOrderCloseout} returns this
 */
proto.IndirectLaborChangeOrderCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EquipmentChangeOrderCloseout.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EquipmentChangeOrderCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.EquipmentChangeOrderCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EquipmentChangeOrderCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentChangeOrderCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultequipmentid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    name: jspb.Message.getFieldWithDefault(msg, 10, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    unitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.ChangeOrderBreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EquipmentChangeOrderCloseout}
 */
proto.EquipmentChangeOrderCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EquipmentChangeOrderCloseout;
  return proto.EquipmentChangeOrderCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EquipmentChangeOrderCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EquipmentChangeOrderCloseout}
 */
proto.EquipmentChangeOrderCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultequipmentid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuantity(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDuration(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUnitcost(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 15:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EquipmentChangeOrderCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EquipmentChangeOrderCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EquipmentChangeOrderCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentChangeOrderCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultequipmentid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getUnitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.EquipmentChangeOrderCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.EquipmentChangeOrderCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultEquipmentId = 3;
 * @return {string}
 */
proto.EquipmentChangeOrderCloseout.prototype.getCloseoutdefaultequipmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.setCloseoutdefaultequipmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string createdByUserId = 5;
 * @return {string}
 */
proto.EquipmentChangeOrderCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string createdByAccountId = 6;
 * @return {string}
 */
proto.EquipmentChangeOrderCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string dateCreated = 7;
 * @return {string}
 */
proto.EquipmentChangeOrderCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string lastUpdated = 8;
 * @return {string}
 */
proto.EquipmentChangeOrderCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string lastUpdatedByUserId = 9;
 * @return {string}
 */
proto.EquipmentChangeOrderCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string name = 10;
 * @return {string}
 */
proto.EquipmentChangeOrderCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional float quantity = 11;
 * @return {number}
 */
proto.EquipmentChangeOrderCloseout.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float duration = 12;
 * @return {number}
 */
proto.EquipmentChangeOrderCloseout.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float unitCost = 13;
 * @return {number}
 */
proto.EquipmentChangeOrderCloseout.prototype.getUnitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.setUnitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float totalCost = 14;
 * @return {number}
 */
proto.EquipmentChangeOrderCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * repeated ChangeOrderBreakoutAllocation allocation = 15;
 * @return {!Array<!proto.ChangeOrderBreakoutAllocation>}
 */
proto.EquipmentChangeOrderCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.ChangeOrderBreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderBreakoutAllocation, 15));
};


/**
 * @param {!Array<!proto.ChangeOrderBreakoutAllocation>} value
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
*/
proto.EquipmentChangeOrderCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.ChangeOrderBreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderBreakoutAllocation}
 */
proto.EquipmentChangeOrderCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.ChangeOrderBreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EquipmentChangeOrderCloseout} returns this
 */
proto.EquipmentChangeOrderCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GeneralExpenseChangeOrderCloseout.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.GeneralExpenseChangeOrderCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GeneralExpenseChangeOrderCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GeneralExpenseChangeOrderCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultgeneralexpenseid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    quantitymultiplier: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    unitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 10, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    name: jspb.Message.getFieldWithDefault(msg, 12, ""),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.ChangeOrderBreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GeneralExpenseChangeOrderCloseout}
 */
proto.GeneralExpenseChangeOrderCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GeneralExpenseChangeOrderCloseout;
  return proto.GeneralExpenseChangeOrderCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GeneralExpenseChangeOrderCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GeneralExpenseChangeOrderCloseout}
 */
proto.GeneralExpenseChangeOrderCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultgeneralexpenseid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuantity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuantitymultiplier(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUnitcost(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 14:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GeneralExpenseChangeOrderCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GeneralExpenseChangeOrderCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GeneralExpenseChangeOrderCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultgeneralexpenseid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getQuantitymultiplier();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getUnitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultGeneralExpenseId = 3;
 * @return {string}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getCloseoutdefaultgeneralexpenseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.setCloseoutdefaultgeneralexpenseid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float quantity = 4;
 * @return {number}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float quantityMultiplier = 5;
 * @return {number}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getQuantitymultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.setQuantitymultiplier = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float unitCost = 6;
 * @return {number}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getUnitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.setUnitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string createdByUserId = 7;
 * @return {string}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string createdByAccountId = 8;
 * @return {string}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string dateCreated = 9;
 * @return {string}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string lastUpdated = 10;
 * @return {string}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string lastUpdatedByUserId = 11;
 * @return {string}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string name = 12;
 * @return {string}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional float totalCost = 13;
 * @return {number}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * repeated ChangeOrderBreakoutAllocation allocation = 14;
 * @return {!Array<!proto.ChangeOrderBreakoutAllocation>}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.ChangeOrderBreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderBreakoutAllocation, 14));
};


/**
 * @param {!Array<!proto.ChangeOrderBreakoutAllocation>} value
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
*/
proto.GeneralExpenseChangeOrderCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.ChangeOrderBreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderBreakoutAllocation}
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.ChangeOrderBreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GeneralExpenseChangeOrderCloseout} returns this
 */
proto.GeneralExpenseChangeOrderCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SubcontractChangeOrderCloseout.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SubcontractChangeOrderCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.SubcontractChangeOrderCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SubcontractChangeOrderCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubcontractChangeOrderCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultsubcontractid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quotedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    adjustedpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    name: jspb.Message.getFieldWithDefault(msg, 11, ""),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.ChangeOrderBreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SubcontractChangeOrderCloseout}
 */
proto.SubcontractChangeOrderCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SubcontractChangeOrderCloseout;
  return proto.SubcontractChangeOrderCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SubcontractChangeOrderCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SubcontractChangeOrderCloseout}
 */
proto.SubcontractChangeOrderCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultsubcontractid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuotedcost(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedpercent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 15:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SubcontractChangeOrderCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SubcontractChangeOrderCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SubcontractChangeOrderCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubcontractChangeOrderCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultsubcontractid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuotedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getAdjustedpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.SubcontractChangeOrderCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
 */
proto.SubcontractChangeOrderCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.SubcontractChangeOrderCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
 */
proto.SubcontractChangeOrderCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultSubcontractId = 3;
 * @return {string}
 */
proto.SubcontractChangeOrderCloseout.prototype.getCloseoutdefaultsubcontractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
 */
proto.SubcontractChangeOrderCloseout.prototype.setCloseoutdefaultsubcontractid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float quotedCost = 4;
 * @return {number}
 */
proto.SubcontractChangeOrderCloseout.prototype.getQuotedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
 */
proto.SubcontractChangeOrderCloseout.prototype.setQuotedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float adjustedPercent = 5;
 * @return {number}
 */
proto.SubcontractChangeOrderCloseout.prototype.getAdjustedpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
 */
proto.SubcontractChangeOrderCloseout.prototype.setAdjustedpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string createdByUserId = 6;
 * @return {string}
 */
proto.SubcontractChangeOrderCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
 */
proto.SubcontractChangeOrderCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string createdByAccountId = 7;
 * @return {string}
 */
proto.SubcontractChangeOrderCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
 */
proto.SubcontractChangeOrderCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dateCreated = 8;
 * @return {string}
 */
proto.SubcontractChangeOrderCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
 */
proto.SubcontractChangeOrderCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string lastUpdated = 9;
 * @return {string}
 */
proto.SubcontractChangeOrderCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
 */
proto.SubcontractChangeOrderCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string lastUpdatedByUserId = 10;
 * @return {string}
 */
proto.SubcontractChangeOrderCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
 */
proto.SubcontractChangeOrderCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string name = 11;
 * @return {string}
 */
proto.SubcontractChangeOrderCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
 */
proto.SubcontractChangeOrderCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional float totalCost = 14;
 * @return {number}
 */
proto.SubcontractChangeOrderCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
 */
proto.SubcontractChangeOrderCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * repeated ChangeOrderBreakoutAllocation allocation = 15;
 * @return {!Array<!proto.ChangeOrderBreakoutAllocation>}
 */
proto.SubcontractChangeOrderCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.ChangeOrderBreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderBreakoutAllocation, 15));
};


/**
 * @param {!Array<!proto.ChangeOrderBreakoutAllocation>} value
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
*/
proto.SubcontractChangeOrderCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.ChangeOrderBreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderBreakoutAllocation}
 */
proto.SubcontractChangeOrderCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.ChangeOrderBreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SubcontractChangeOrderCloseout} returns this
 */
proto.SubcontractChangeOrderCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.QuoteChangeOrderCloseout.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.QuoteChangeOrderCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.QuoteChangeOrderCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.QuoteChangeOrderCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuoteChangeOrderCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeoutdefaultquoteid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quotedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    adjustedpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    name: jspb.Message.getFieldWithDefault(msg, 11, ""),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    allocationList: jspb.Message.toObjectList(msg.getAllocationList(),
    proto.ChangeOrderBreakoutAllocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.QuoteChangeOrderCloseout}
 */
proto.QuoteChangeOrderCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.QuoteChangeOrderCloseout;
  return proto.QuoteChangeOrderCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.QuoteChangeOrderCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.QuoteChangeOrderCloseout}
 */
proto.QuoteChangeOrderCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseoutdefaultquoteid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuotedcost(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedpercent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 13:
      var value = new proto.ChangeOrderBreakoutAllocation;
      reader.readMessage(value,proto.ChangeOrderBreakoutAllocation.deserializeBinaryFromReader);
      msg.addAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.QuoteChangeOrderCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.QuoteChangeOrderCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.QuoteChangeOrderCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuoteChangeOrderCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseoutdefaultquoteid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuotedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getAdjustedpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getAllocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.ChangeOrderBreakoutAllocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string closeoutId = 1;
 * @return {string}
 */
proto.QuoteChangeOrderCloseout.prototype.getCloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteChangeOrderCloseout} returns this
 */
proto.QuoteChangeOrderCloseout.prototype.setCloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.QuoteChangeOrderCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteChangeOrderCloseout} returns this
 */
proto.QuoteChangeOrderCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeoutDefaultQuoteId = 3;
 * @return {string}
 */
proto.QuoteChangeOrderCloseout.prototype.getCloseoutdefaultquoteid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteChangeOrderCloseout} returns this
 */
proto.QuoteChangeOrderCloseout.prototype.setCloseoutdefaultquoteid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float quotedCost = 4;
 * @return {number}
 */
proto.QuoteChangeOrderCloseout.prototype.getQuotedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteChangeOrderCloseout} returns this
 */
proto.QuoteChangeOrderCloseout.prototype.setQuotedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float adjustedPercent = 5;
 * @return {number}
 */
proto.QuoteChangeOrderCloseout.prototype.getAdjustedpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteChangeOrderCloseout} returns this
 */
proto.QuoteChangeOrderCloseout.prototype.setAdjustedpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string createdByUserId = 6;
 * @return {string}
 */
proto.QuoteChangeOrderCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteChangeOrderCloseout} returns this
 */
proto.QuoteChangeOrderCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string createdByAccountId = 7;
 * @return {string}
 */
proto.QuoteChangeOrderCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteChangeOrderCloseout} returns this
 */
proto.QuoteChangeOrderCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dateCreated = 8;
 * @return {string}
 */
proto.QuoteChangeOrderCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteChangeOrderCloseout} returns this
 */
proto.QuoteChangeOrderCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string lastUpdated = 9;
 * @return {string}
 */
proto.QuoteChangeOrderCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteChangeOrderCloseout} returns this
 */
proto.QuoteChangeOrderCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string lastUpdatedByUserId = 10;
 * @return {string}
 */
proto.QuoteChangeOrderCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteChangeOrderCloseout} returns this
 */
proto.QuoteChangeOrderCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string name = 11;
 * @return {string}
 */
proto.QuoteChangeOrderCloseout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteChangeOrderCloseout} returns this
 */
proto.QuoteChangeOrderCloseout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional float totalCost = 12;
 * @return {number}
 */
proto.QuoteChangeOrderCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteChangeOrderCloseout} returns this
 */
proto.QuoteChangeOrderCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * repeated ChangeOrderBreakoutAllocation allocation = 13;
 * @return {!Array<!proto.ChangeOrderBreakoutAllocation>}
 */
proto.QuoteChangeOrderCloseout.prototype.getAllocationList = function() {
  return /** @type{!Array<!proto.ChangeOrderBreakoutAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderBreakoutAllocation, 13));
};


/**
 * @param {!Array<!proto.ChangeOrderBreakoutAllocation>} value
 * @return {!proto.QuoteChangeOrderCloseout} returns this
*/
proto.QuoteChangeOrderCloseout.prototype.setAllocationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.ChangeOrderBreakoutAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderBreakoutAllocation}
 */
proto.QuoteChangeOrderCloseout.prototype.addAllocation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.ChangeOrderBreakoutAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.QuoteChangeOrderCloseout} returns this
 */
proto.QuoteChangeOrderCloseout.prototype.clearAllocationList = function() {
  return this.setAllocationList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.TotalFinalPriceChangeOrderCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TotalFinalPriceChangeOrderCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TotalFinalPriceChangeOrderCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalfinalpricecloseoutid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    extendestimate: (f = msg.getExtendestimate()) && proto.ExtendChangeOrderToFinalPrice.toObject(includeInstance, f),
    directlaborcloseout: (f = msg.getDirectlaborcloseout()) && proto.DirectLaborChangeOrderToFinalPrice.toObject(includeInstance, f),
    laborfactoringcloseout: (f = msg.getLaborfactoringcloseout()) && proto.LaborFactoringChangeOrderToFinalPrice.toObject(includeInstance, f),
    incidentallaborcloseout: (f = msg.getIncidentallaborcloseout()) && proto.IncidentalLaborChangeOrderToFinalPrice.toObject(includeInstance, f),
    indirectlaborcloseout: (f = msg.getIndirectlaborcloseout()) && proto.IndirectLaborChangeOrderToFinalPrice.toObject(includeInstance, f),
    equipmentcloseout: (f = msg.getEquipmentcloseout()) && proto.EquipmentChangeOrderToFinalPrice.toObject(includeInstance, f),
    generalexpensecloseout: (f = msg.getGeneralexpensecloseout()) && proto.GeneralExpenseChangeOrderToFinalPrice.toObject(includeInstance, f),
    subcontractcloseout: (f = msg.getSubcontractcloseout()) && proto.SubcontractChangeOrderToFinalPrice.toObject(includeInstance, f),
    quotecloseout: (f = msg.getQuotecloseout()) && proto.QuoteChangeOrderToFinalPrice.toObject(includeInstance, f),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    totaloverhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    totalmargin: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    totalprofit: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    totalfinalprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    sellingprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 18, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 19, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 20, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 21, ""),
    lastupdatedbyuserid: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TotalFinalPriceChangeOrderCloseout}
 */
proto.TotalFinalPriceChangeOrderCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TotalFinalPriceChangeOrderCloseout;
  return proto.TotalFinalPriceChangeOrderCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TotalFinalPriceChangeOrderCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TotalFinalPriceChangeOrderCloseout}
 */
proto.TotalFinalPriceChangeOrderCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalfinalpricecloseoutid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = new proto.ExtendChangeOrderToFinalPrice;
      reader.readMessage(value,proto.ExtendChangeOrderToFinalPrice.deserializeBinaryFromReader);
      msg.setExtendestimate(value);
      break;
    case 4:
      var value = new proto.DirectLaborChangeOrderToFinalPrice;
      reader.readMessage(value,proto.DirectLaborChangeOrderToFinalPrice.deserializeBinaryFromReader);
      msg.setDirectlaborcloseout(value);
      break;
    case 5:
      var value = new proto.LaborFactoringChangeOrderToFinalPrice;
      reader.readMessage(value,proto.LaborFactoringChangeOrderToFinalPrice.deserializeBinaryFromReader);
      msg.setLaborfactoringcloseout(value);
      break;
    case 6:
      var value = new proto.IncidentalLaborChangeOrderToFinalPrice;
      reader.readMessage(value,proto.IncidentalLaborChangeOrderToFinalPrice.deserializeBinaryFromReader);
      msg.setIncidentallaborcloseout(value);
      break;
    case 7:
      var value = new proto.IndirectLaborChangeOrderToFinalPrice;
      reader.readMessage(value,proto.IndirectLaborChangeOrderToFinalPrice.deserializeBinaryFromReader);
      msg.setIndirectlaborcloseout(value);
      break;
    case 8:
      var value = new proto.EquipmentChangeOrderToFinalPrice;
      reader.readMessage(value,proto.EquipmentChangeOrderToFinalPrice.deserializeBinaryFromReader);
      msg.setEquipmentcloseout(value);
      break;
    case 9:
      var value = new proto.GeneralExpenseChangeOrderToFinalPrice;
      reader.readMessage(value,proto.GeneralExpenseChangeOrderToFinalPrice.deserializeBinaryFromReader);
      msg.setGeneralexpensecloseout(value);
      break;
    case 10:
      var value = new proto.SubcontractChangeOrderToFinalPrice;
      reader.readMessage(value,proto.SubcontractChangeOrderToFinalPrice.deserializeBinaryFromReader);
      msg.setSubcontractcloseout(value);
      break;
    case 11:
      var value = new proto.QuoteChangeOrderToFinalPrice;
      reader.readMessage(value,proto.QuoteChangeOrderToFinalPrice.deserializeBinaryFromReader);
      msg.setQuotecloseout(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaloverhead(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalmargin(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalprofit(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalfinalprice(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSellingprice(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedbyuserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TotalFinalPriceChangeOrderCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TotalFinalPriceChangeOrderCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TotalFinalPriceChangeOrderCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalfinalpricecloseoutid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExtendestimate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ExtendChangeOrderToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getDirectlaborcloseout();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.DirectLaborChangeOrderToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getLaborfactoringcloseout();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.LaborFactoringChangeOrderToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getIncidentallaborcloseout();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.IncidentalLaborChangeOrderToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getIndirectlaborcloseout();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.IndirectLaborChangeOrderToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentcloseout();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.EquipmentChangeOrderToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getGeneralexpensecloseout();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.GeneralExpenseChangeOrderToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getSubcontractcloseout();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.SubcontractChangeOrderToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getQuotecloseout();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.QuoteChangeOrderToFinalPrice.serializeBinaryToWriter
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getTotaloverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getTotalmargin();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getTotalprofit();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getTotalfinalprice();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getSellingprice();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getLastupdatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional string totalFinalPriceCloseoutId = 1;
 * @return {string}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getTotalfinalpricecloseoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.setTotalfinalpricecloseoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ExtendChangeOrderToFinalPrice extendEstimate = 3;
 * @return {?proto.ExtendChangeOrderToFinalPrice}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getExtendestimate = function() {
  return /** @type{?proto.ExtendChangeOrderToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.ExtendChangeOrderToFinalPrice, 3));
};


/**
 * @param {?proto.ExtendChangeOrderToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
*/
proto.TotalFinalPriceChangeOrderCloseout.prototype.setExtendestimate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.clearExtendestimate = function() {
  return this.setExtendestimate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.hasExtendestimate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DirectLaborChangeOrderToFinalPrice directLaborCloseout = 4;
 * @return {?proto.DirectLaborChangeOrderToFinalPrice}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getDirectlaborcloseout = function() {
  return /** @type{?proto.DirectLaborChangeOrderToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.DirectLaborChangeOrderToFinalPrice, 4));
};


/**
 * @param {?proto.DirectLaborChangeOrderToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
*/
proto.TotalFinalPriceChangeOrderCloseout.prototype.setDirectlaborcloseout = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.clearDirectlaborcloseout = function() {
  return this.setDirectlaborcloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.hasDirectlaborcloseout = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional LaborFactoringChangeOrderToFinalPrice laborFactoringCloseout = 5;
 * @return {?proto.LaborFactoringChangeOrderToFinalPrice}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getLaborfactoringcloseout = function() {
  return /** @type{?proto.LaborFactoringChangeOrderToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.LaborFactoringChangeOrderToFinalPrice, 5));
};


/**
 * @param {?proto.LaborFactoringChangeOrderToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
*/
proto.TotalFinalPriceChangeOrderCloseout.prototype.setLaborfactoringcloseout = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.clearLaborfactoringcloseout = function() {
  return this.setLaborfactoringcloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.hasLaborfactoringcloseout = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional IncidentalLaborChangeOrderToFinalPrice incidentalLaborCloseout = 6;
 * @return {?proto.IncidentalLaborChangeOrderToFinalPrice}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getIncidentallaborcloseout = function() {
  return /** @type{?proto.IncidentalLaborChangeOrderToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.IncidentalLaborChangeOrderToFinalPrice, 6));
};


/**
 * @param {?proto.IncidentalLaborChangeOrderToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
*/
proto.TotalFinalPriceChangeOrderCloseout.prototype.setIncidentallaborcloseout = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.clearIncidentallaborcloseout = function() {
  return this.setIncidentallaborcloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.hasIncidentallaborcloseout = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional IndirectLaborChangeOrderToFinalPrice indirectLaborCloseout = 7;
 * @return {?proto.IndirectLaborChangeOrderToFinalPrice}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getIndirectlaborcloseout = function() {
  return /** @type{?proto.IndirectLaborChangeOrderToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.IndirectLaborChangeOrderToFinalPrice, 7));
};


/**
 * @param {?proto.IndirectLaborChangeOrderToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
*/
proto.TotalFinalPriceChangeOrderCloseout.prototype.setIndirectlaborcloseout = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.clearIndirectlaborcloseout = function() {
  return this.setIndirectlaborcloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.hasIndirectlaborcloseout = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EquipmentChangeOrderToFinalPrice equipmentCloseout = 8;
 * @return {?proto.EquipmentChangeOrderToFinalPrice}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getEquipmentcloseout = function() {
  return /** @type{?proto.EquipmentChangeOrderToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.EquipmentChangeOrderToFinalPrice, 8));
};


/**
 * @param {?proto.EquipmentChangeOrderToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
*/
proto.TotalFinalPriceChangeOrderCloseout.prototype.setEquipmentcloseout = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.clearEquipmentcloseout = function() {
  return this.setEquipmentcloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.hasEquipmentcloseout = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional GeneralExpenseChangeOrderToFinalPrice generalExpenseCloseout = 9;
 * @return {?proto.GeneralExpenseChangeOrderToFinalPrice}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getGeneralexpensecloseout = function() {
  return /** @type{?proto.GeneralExpenseChangeOrderToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.GeneralExpenseChangeOrderToFinalPrice, 9));
};


/**
 * @param {?proto.GeneralExpenseChangeOrderToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
*/
proto.TotalFinalPriceChangeOrderCloseout.prototype.setGeneralexpensecloseout = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.clearGeneralexpensecloseout = function() {
  return this.setGeneralexpensecloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.hasGeneralexpensecloseout = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional SubcontractChangeOrderToFinalPrice subcontractCloseout = 10;
 * @return {?proto.SubcontractChangeOrderToFinalPrice}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getSubcontractcloseout = function() {
  return /** @type{?proto.SubcontractChangeOrderToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.SubcontractChangeOrderToFinalPrice, 10));
};


/**
 * @param {?proto.SubcontractChangeOrderToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
*/
proto.TotalFinalPriceChangeOrderCloseout.prototype.setSubcontractcloseout = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.clearSubcontractcloseout = function() {
  return this.setSubcontractcloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.hasSubcontractcloseout = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional QuoteChangeOrderToFinalPrice quoteCloseout = 11;
 * @return {?proto.QuoteChangeOrderToFinalPrice}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getQuotecloseout = function() {
  return /** @type{?proto.QuoteChangeOrderToFinalPrice} */ (
    jspb.Message.getWrapperField(this, proto.QuoteChangeOrderToFinalPrice, 11));
};


/**
 * @param {?proto.QuoteChangeOrderToFinalPrice|undefined} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
*/
proto.TotalFinalPriceChangeOrderCloseout.prototype.setQuotecloseout = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.clearQuotecloseout = function() {
  return this.setQuotecloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.hasQuotecloseout = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional float totalCost = 12;
 * @return {number}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float totalOverhead = 13;
 * @return {number}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getTotaloverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.setTotaloverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float totalMargin = 14;
 * @return {number}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getTotalmargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.setTotalmargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float totalProfit = 15;
 * @return {number}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getTotalprofit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.setTotalprofit = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float totalFinalPrice = 16;
 * @return {number}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getTotalfinalprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.setTotalfinalprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float sellingPrice = 17;
 * @return {number}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getSellingprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.setSellingprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional string createdByUserId = 18;
 * @return {string}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string createdByAccountId = 19;
 * @return {string}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string dateCreated = 20;
 * @return {string}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string lastUpdated = 21;
 * @return {string}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string lastUpdatedByUserId = 22;
 * @return {string}
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.getLastupdatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalFinalPriceChangeOrderCloseout} returns this
 */
proto.TotalFinalPriceChangeOrderCloseout.prototype.setLastupdatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ExtendChangeOrderToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ExtendChangeOrderToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.ExtendChangeOrderToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ExtendChangeOrderToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExtendChangeOrderToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    extendestimateList: jspb.Message.toObjectList(msg.getExtendestimateList(),
    proto.ExtendChangeOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ExtendChangeOrderToFinalPrice}
 */
proto.ExtendChangeOrderToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ExtendChangeOrderToFinalPrice;
  return proto.ExtendChangeOrderToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ExtendChangeOrderToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ExtendChangeOrderToFinalPrice}
 */
proto.ExtendChangeOrderToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.ExtendChangeOrder;
      reader.readMessage(value,proto.ExtendChangeOrder.deserializeBinaryFromReader);
      msg.addExtendestimate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ExtendChangeOrderToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ExtendChangeOrderToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ExtendChangeOrderToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExtendChangeOrderToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getExtendestimateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.ExtendChangeOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.ExtendChangeOrderToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrderToFinalPrice} returns this
 */
proto.ExtendChangeOrderToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.ExtendChangeOrderToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrderToFinalPrice} returns this
 */
proto.ExtendChangeOrderToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.ExtendChangeOrderToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrderToFinalPrice} returns this
 */
proto.ExtendChangeOrderToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.ExtendChangeOrderToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrderToFinalPrice} returns this
 */
proto.ExtendChangeOrderToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.ExtendChangeOrderToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrderToFinalPrice} returns this
 */
proto.ExtendChangeOrderToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.ExtendChangeOrderToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrderToFinalPrice} returns this
 */
proto.ExtendChangeOrderToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.ExtendChangeOrderToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtendChangeOrderToFinalPrice} returns this
 */
proto.ExtendChangeOrderToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated ExtendChangeOrder extendEstimate = 10;
 * @return {!Array<!proto.ExtendChangeOrder>}
 */
proto.ExtendChangeOrderToFinalPrice.prototype.getExtendestimateList = function() {
  return /** @type{!Array<!proto.ExtendChangeOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ExtendChangeOrder, 10));
};


/**
 * @param {!Array<!proto.ExtendChangeOrder>} value
 * @return {!proto.ExtendChangeOrderToFinalPrice} returns this
*/
proto.ExtendChangeOrderToFinalPrice.prototype.setExtendestimateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.ExtendChangeOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ExtendChangeOrder}
 */
proto.ExtendChangeOrderToFinalPrice.prototype.addExtendestimate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.ExtendChangeOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ExtendChangeOrderToFinalPrice} returns this
 */
proto.ExtendChangeOrderToFinalPrice.prototype.clearExtendestimateList = function() {
  return this.setExtendestimateList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DirectLaborChangeOrderToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.DirectLaborChangeOrderToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DirectLaborChangeOrderToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DirectLaborChangeOrderToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    directlaborcloseoutList: jspb.Message.toObjectList(msg.getDirectlaborcloseoutList(),
    proto.DirectLaborChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DirectLaborChangeOrderToFinalPrice}
 */
proto.DirectLaborChangeOrderToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DirectLaborChangeOrderToFinalPrice;
  return proto.DirectLaborChangeOrderToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DirectLaborChangeOrderToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DirectLaborChangeOrderToFinalPrice}
 */
proto.DirectLaborChangeOrderToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.DirectLaborChangeOrderCloseout;
      reader.readMessage(value,proto.DirectLaborChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addDirectlaborcloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DirectLaborChangeOrderToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DirectLaborChangeOrderToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DirectLaborChangeOrderToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getDirectlaborcloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.DirectLaborChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderToFinalPrice} returns this
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderToFinalPrice} returns this
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderToFinalPrice} returns this
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderToFinalPrice} returns this
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderToFinalPrice} returns this
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderToFinalPrice} returns this
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborChangeOrderToFinalPrice} returns this
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated DirectLaborChangeOrderCloseout directLaborCloseout = 10;
 * @return {!Array<!proto.DirectLaborChangeOrderCloseout>}
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.getDirectlaborcloseoutList = function() {
  return /** @type{!Array<!proto.DirectLaborChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DirectLaborChangeOrderCloseout, 10));
};


/**
 * @param {!Array<!proto.DirectLaborChangeOrderCloseout>} value
 * @return {!proto.DirectLaborChangeOrderToFinalPrice} returns this
*/
proto.DirectLaborChangeOrderToFinalPrice.prototype.setDirectlaborcloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.DirectLaborChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DirectLaborChangeOrderCloseout}
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.addDirectlaborcloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.DirectLaborChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DirectLaborChangeOrderToFinalPrice} returns this
 */
proto.DirectLaborChangeOrderToFinalPrice.prototype.clearDirectlaborcloseoutList = function() {
  return this.setDirectlaborcloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LaborFactoringChangeOrderToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.LaborFactoringChangeOrderToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LaborFactoringChangeOrderToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LaborFactoringChangeOrderToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    laborfactoringcloseoutList: jspb.Message.toObjectList(msg.getLaborfactoringcloseoutList(),
    proto.LaborFactoringChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LaborFactoringChangeOrderToFinalPrice}
 */
proto.LaborFactoringChangeOrderToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LaborFactoringChangeOrderToFinalPrice;
  return proto.LaborFactoringChangeOrderToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LaborFactoringChangeOrderToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LaborFactoringChangeOrderToFinalPrice}
 */
proto.LaborFactoringChangeOrderToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.LaborFactoringChangeOrderCloseout;
      reader.readMessage(value,proto.LaborFactoringChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addLaborfactoringcloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LaborFactoringChangeOrderToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LaborFactoringChangeOrderToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LaborFactoringChangeOrderToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getLaborfactoringcloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.LaborFactoringChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderToFinalPrice} returns this
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderToFinalPrice} returns this
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderToFinalPrice} returns this
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderToFinalPrice} returns this
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderToFinalPrice} returns this
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderToFinalPrice} returns this
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringChangeOrderToFinalPrice} returns this
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated LaborFactoringChangeOrderCloseout laborFactoringCloseout = 10;
 * @return {!Array<!proto.LaborFactoringChangeOrderCloseout>}
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.getLaborfactoringcloseoutList = function() {
  return /** @type{!Array<!proto.LaborFactoringChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.LaborFactoringChangeOrderCloseout, 10));
};


/**
 * @param {!Array<!proto.LaborFactoringChangeOrderCloseout>} value
 * @return {!proto.LaborFactoringChangeOrderToFinalPrice} returns this
*/
proto.LaborFactoringChangeOrderToFinalPrice.prototype.setLaborfactoringcloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.LaborFactoringChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LaborFactoringChangeOrderCloseout}
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.addLaborfactoringcloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.LaborFactoringChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LaborFactoringChangeOrderToFinalPrice} returns this
 */
proto.LaborFactoringChangeOrderToFinalPrice.prototype.clearLaborfactoringcloseoutList = function() {
  return this.setLaborfactoringcloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IncidentalLaborChangeOrderToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.IncidentalLaborChangeOrderToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IncidentalLaborChangeOrderToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IncidentalLaborChangeOrderToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    incidentallaborcloseoutList: jspb.Message.toObjectList(msg.getIncidentallaborcloseoutList(),
    proto.IncidentalLaborChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IncidentalLaborChangeOrderToFinalPrice}
 */
proto.IncidentalLaborChangeOrderToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IncidentalLaborChangeOrderToFinalPrice;
  return proto.IncidentalLaborChangeOrderToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IncidentalLaborChangeOrderToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IncidentalLaborChangeOrderToFinalPrice}
 */
proto.IncidentalLaborChangeOrderToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.IncidentalLaborChangeOrderCloseout;
      reader.readMessage(value,proto.IncidentalLaborChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addIncidentallaborcloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IncidentalLaborChangeOrderToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IncidentalLaborChangeOrderToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IncidentalLaborChangeOrderToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getIncidentallaborcloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.IncidentalLaborChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderToFinalPrice} returns this
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderToFinalPrice} returns this
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderToFinalPrice} returns this
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderToFinalPrice} returns this
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderToFinalPrice} returns this
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderToFinalPrice} returns this
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborChangeOrderToFinalPrice} returns this
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated IncidentalLaborChangeOrderCloseout incidentalLaborCloseout = 10;
 * @return {!Array<!proto.IncidentalLaborChangeOrderCloseout>}
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.getIncidentallaborcloseoutList = function() {
  return /** @type{!Array<!proto.IncidentalLaborChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IncidentalLaborChangeOrderCloseout, 10));
};


/**
 * @param {!Array<!proto.IncidentalLaborChangeOrderCloseout>} value
 * @return {!proto.IncidentalLaborChangeOrderToFinalPrice} returns this
*/
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.setIncidentallaborcloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.IncidentalLaborChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IncidentalLaborChangeOrderCloseout}
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.addIncidentallaborcloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.IncidentalLaborChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IncidentalLaborChangeOrderToFinalPrice} returns this
 */
proto.IncidentalLaborChangeOrderToFinalPrice.prototype.clearIncidentallaborcloseoutList = function() {
  return this.setIncidentallaborcloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IndirectLaborChangeOrderToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.IndirectLaborChangeOrderToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndirectLaborChangeOrderToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndirectLaborChangeOrderToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    indirectlaborcloseoutList: jspb.Message.toObjectList(msg.getIndirectlaborcloseoutList(),
    proto.IndirectLaborChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndirectLaborChangeOrderToFinalPrice}
 */
proto.IndirectLaborChangeOrderToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndirectLaborChangeOrderToFinalPrice;
  return proto.IndirectLaborChangeOrderToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndirectLaborChangeOrderToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndirectLaborChangeOrderToFinalPrice}
 */
proto.IndirectLaborChangeOrderToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.IndirectLaborChangeOrderCloseout;
      reader.readMessage(value,proto.IndirectLaborChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addIndirectlaborcloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndirectLaborChangeOrderToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndirectLaborChangeOrderToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndirectLaborChangeOrderToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getIndirectlaborcloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.IndirectLaborChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderToFinalPrice} returns this
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderToFinalPrice} returns this
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderToFinalPrice} returns this
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderToFinalPrice} returns this
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderToFinalPrice} returns this
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderToFinalPrice} returns this
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborChangeOrderToFinalPrice} returns this
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated IndirectLaborChangeOrderCloseout indirectLaborCloseout = 10;
 * @return {!Array<!proto.IndirectLaborChangeOrderCloseout>}
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.getIndirectlaborcloseoutList = function() {
  return /** @type{!Array<!proto.IndirectLaborChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IndirectLaborChangeOrderCloseout, 10));
};


/**
 * @param {!Array<!proto.IndirectLaborChangeOrderCloseout>} value
 * @return {!proto.IndirectLaborChangeOrderToFinalPrice} returns this
*/
proto.IndirectLaborChangeOrderToFinalPrice.prototype.setIndirectlaborcloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.IndirectLaborChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IndirectLaborChangeOrderCloseout}
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.addIndirectlaborcloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.IndirectLaborChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IndirectLaborChangeOrderToFinalPrice} returns this
 */
proto.IndirectLaborChangeOrderToFinalPrice.prototype.clearIndirectlaborcloseoutList = function() {
  return this.setIndirectlaborcloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EquipmentChangeOrderToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.EquipmentChangeOrderToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EquipmentChangeOrderToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentChangeOrderToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    equipmentcloseoutList: jspb.Message.toObjectList(msg.getEquipmentcloseoutList(),
    proto.EquipmentChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EquipmentChangeOrderToFinalPrice}
 */
proto.EquipmentChangeOrderToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EquipmentChangeOrderToFinalPrice;
  return proto.EquipmentChangeOrderToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EquipmentChangeOrderToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EquipmentChangeOrderToFinalPrice}
 */
proto.EquipmentChangeOrderToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.EquipmentChangeOrderCloseout;
      reader.readMessage(value,proto.EquipmentChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addEquipmentcloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EquipmentChangeOrderToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EquipmentChangeOrderToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentChangeOrderToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getEquipmentcloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.EquipmentChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentChangeOrderToFinalPrice} returns this
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentChangeOrderToFinalPrice} returns this
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentChangeOrderToFinalPrice} returns this
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentChangeOrderToFinalPrice} returns this
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentChangeOrderToFinalPrice} returns this
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentChangeOrderToFinalPrice} returns this
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentChangeOrderToFinalPrice} returns this
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated EquipmentChangeOrderCloseout equipmentCloseout = 10;
 * @return {!Array<!proto.EquipmentChangeOrderCloseout>}
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.getEquipmentcloseoutList = function() {
  return /** @type{!Array<!proto.EquipmentChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EquipmentChangeOrderCloseout, 10));
};


/**
 * @param {!Array<!proto.EquipmentChangeOrderCloseout>} value
 * @return {!proto.EquipmentChangeOrderToFinalPrice} returns this
*/
proto.EquipmentChangeOrderToFinalPrice.prototype.setEquipmentcloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.EquipmentChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EquipmentChangeOrderCloseout}
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.addEquipmentcloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.EquipmentChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EquipmentChangeOrderToFinalPrice} returns this
 */
proto.EquipmentChangeOrderToFinalPrice.prototype.clearEquipmentcloseoutList = function() {
  return this.setEquipmentcloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GeneralExpenseChangeOrderToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.GeneralExpenseChangeOrderToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GeneralExpenseChangeOrderToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GeneralExpenseChangeOrderToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    generalexpensecloseoutList: jspb.Message.toObjectList(msg.getGeneralexpensecloseoutList(),
    proto.GeneralExpenseChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GeneralExpenseChangeOrderToFinalPrice}
 */
proto.GeneralExpenseChangeOrderToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GeneralExpenseChangeOrderToFinalPrice;
  return proto.GeneralExpenseChangeOrderToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GeneralExpenseChangeOrderToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GeneralExpenseChangeOrderToFinalPrice}
 */
proto.GeneralExpenseChangeOrderToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.GeneralExpenseChangeOrderCloseout;
      reader.readMessage(value,proto.GeneralExpenseChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addGeneralexpensecloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GeneralExpenseChangeOrderToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GeneralExpenseChangeOrderToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GeneralExpenseChangeOrderToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getGeneralexpensecloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.GeneralExpenseChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseChangeOrderToFinalPrice} returns this
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseChangeOrderToFinalPrice} returns this
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseChangeOrderToFinalPrice} returns this
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseChangeOrderToFinalPrice} returns this
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseChangeOrderToFinalPrice} returns this
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseChangeOrderToFinalPrice} returns this
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseChangeOrderToFinalPrice} returns this
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated GeneralExpenseChangeOrderCloseout generalExpenseCloseout = 10;
 * @return {!Array<!proto.GeneralExpenseChangeOrderCloseout>}
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.getGeneralexpensecloseoutList = function() {
  return /** @type{!Array<!proto.GeneralExpenseChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GeneralExpenseChangeOrderCloseout, 10));
};


/**
 * @param {!Array<!proto.GeneralExpenseChangeOrderCloseout>} value
 * @return {!proto.GeneralExpenseChangeOrderToFinalPrice} returns this
*/
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.setGeneralexpensecloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.GeneralExpenseChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GeneralExpenseChangeOrderCloseout}
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.addGeneralexpensecloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.GeneralExpenseChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GeneralExpenseChangeOrderToFinalPrice} returns this
 */
proto.GeneralExpenseChangeOrderToFinalPrice.prototype.clearGeneralexpensecloseoutList = function() {
  return this.setGeneralexpensecloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SubcontractChangeOrderToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.SubcontractChangeOrderToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SubcontractChangeOrderToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubcontractChangeOrderToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    subcontractcloseoutList: jspb.Message.toObjectList(msg.getSubcontractcloseoutList(),
    proto.SubcontractChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SubcontractChangeOrderToFinalPrice}
 */
proto.SubcontractChangeOrderToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SubcontractChangeOrderToFinalPrice;
  return proto.SubcontractChangeOrderToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SubcontractChangeOrderToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SubcontractChangeOrderToFinalPrice}
 */
proto.SubcontractChangeOrderToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.SubcontractChangeOrderCloseout;
      reader.readMessage(value,proto.SubcontractChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addSubcontractcloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SubcontractChangeOrderToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SubcontractChangeOrderToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubcontractChangeOrderToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getSubcontractcloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.SubcontractChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractChangeOrderToFinalPrice} returns this
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractChangeOrderToFinalPrice} returns this
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractChangeOrderToFinalPrice} returns this
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractChangeOrderToFinalPrice} returns this
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractChangeOrderToFinalPrice} returns this
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractChangeOrderToFinalPrice} returns this
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractChangeOrderToFinalPrice} returns this
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated SubcontractChangeOrderCloseout subcontractCloseout = 10;
 * @return {!Array<!proto.SubcontractChangeOrderCloseout>}
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.getSubcontractcloseoutList = function() {
  return /** @type{!Array<!proto.SubcontractChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SubcontractChangeOrderCloseout, 10));
};


/**
 * @param {!Array<!proto.SubcontractChangeOrderCloseout>} value
 * @return {!proto.SubcontractChangeOrderToFinalPrice} returns this
*/
proto.SubcontractChangeOrderToFinalPrice.prototype.setSubcontractcloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.SubcontractChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SubcontractChangeOrderCloseout}
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.addSubcontractcloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.SubcontractChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SubcontractChangeOrderToFinalPrice} returns this
 */
proto.SubcontractChangeOrderToFinalPrice.prototype.clearSubcontractcloseoutList = function() {
  return this.setSubcontractcloseoutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.QuoteChangeOrderToFinalPrice.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.QuoteChangeOrderToFinalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.QuoteChangeOrderToFinalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.QuoteChangeOrderToFinalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuoteChangeOrderToFinalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    margin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    overheadcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    quotecloseoutList: jspb.Message.toObjectList(msg.getQuotecloseoutList(),
    proto.QuoteChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.QuoteChangeOrderToFinalPrice}
 */
proto.QuoteChangeOrderToFinalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.QuoteChangeOrderToFinalPrice;
  return proto.QuoteChangeOrderToFinalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.QuoteChangeOrderToFinalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.QuoteChangeOrderToFinalPrice}
 */
proto.QuoteChangeOrderToFinalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverhead(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMargin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProfitcost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 10:
      var value = new proto.QuoteChangeOrderCloseout;
      reader.readMessage(value,proto.QuoteChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addQuotecloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.QuoteChangeOrderToFinalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.QuoteChangeOrderToFinalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.QuoteChangeOrderToFinalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuoteChangeOrderToFinalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMargin();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getOverheadcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getProfitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getQuotecloseoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.QuoteChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional float adjustment = 3;
 * @return {number}
 */
proto.QuoteChangeOrderToFinalPrice.prototype.getAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteChangeOrderToFinalPrice} returns this
 */
proto.QuoteChangeOrderToFinalPrice.prototype.setAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overhead = 4;
 * @return {number}
 */
proto.QuoteChangeOrderToFinalPrice.prototype.getOverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteChangeOrderToFinalPrice} returns this
 */
proto.QuoteChangeOrderToFinalPrice.prototype.setOverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float margin = 5;
 * @return {number}
 */
proto.QuoteChangeOrderToFinalPrice.prototype.getMargin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteChangeOrderToFinalPrice} returns this
 */
proto.QuoteChangeOrderToFinalPrice.prototype.setMargin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float adjustedCost = 6;
 * @return {number}
 */
proto.QuoteChangeOrderToFinalPrice.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteChangeOrderToFinalPrice} returns this
 */
proto.QuoteChangeOrderToFinalPrice.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float overheadCost = 7;
 * @return {number}
 */
proto.QuoteChangeOrderToFinalPrice.prototype.getOverheadcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteChangeOrderToFinalPrice} returns this
 */
proto.QuoteChangeOrderToFinalPrice.prototype.setOverheadcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float profitCost = 8;
 * @return {number}
 */
proto.QuoteChangeOrderToFinalPrice.prototype.getProfitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteChangeOrderToFinalPrice} returns this
 */
proto.QuoteChangeOrderToFinalPrice.prototype.setProfitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.QuoteChangeOrderToFinalPrice.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteChangeOrderToFinalPrice} returns this
 */
proto.QuoteChangeOrderToFinalPrice.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated QuoteChangeOrderCloseout quoteCloseout = 10;
 * @return {!Array<!proto.QuoteChangeOrderCloseout>}
 */
proto.QuoteChangeOrderToFinalPrice.prototype.getQuotecloseoutList = function() {
  return /** @type{!Array<!proto.QuoteChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.QuoteChangeOrderCloseout, 10));
};


/**
 * @param {!Array<!proto.QuoteChangeOrderCloseout>} value
 * @return {!proto.QuoteChangeOrderToFinalPrice} returns this
*/
proto.QuoteChangeOrderToFinalPrice.prototype.setQuotecloseoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.QuoteChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.QuoteChangeOrderCloseout}
 */
proto.QuoteChangeOrderToFinalPrice.prototype.addQuotecloseout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.QuoteChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.QuoteChangeOrderToFinalPrice} returns this
 */
proto.QuoteChangeOrderToFinalPrice.prototype.clearQuotecloseoutList = function() {
  return this.setQuotecloseoutList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateDirectLaborChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateDirectLaborChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateDirectLaborChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateDirectLaborChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.DirectLaborChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateDirectLaborChangeOrderCloseoutRequest}
 */
proto.CreateDirectLaborChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateDirectLaborChangeOrderCloseoutRequest;
  return proto.CreateDirectLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateDirectLaborChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateDirectLaborChangeOrderCloseoutRequest}
 */
proto.CreateDirectLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DirectLaborChangeOrderCloseout;
      reader.readMessage(value,proto.DirectLaborChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateDirectLaborChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateDirectLaborChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateDirectLaborChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateDirectLaborChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DirectLaborChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional DirectLaborChangeOrderCloseout closeout = 1;
 * @return {?proto.DirectLaborChangeOrderCloseout}
 */
proto.CreateDirectLaborChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.DirectLaborChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.DirectLaborChangeOrderCloseout, 1));
};


/**
 * @param {?proto.DirectLaborChangeOrderCloseout|undefined} value
 * @return {!proto.CreateDirectLaborChangeOrderCloseoutRequest} returns this
*/
proto.CreateDirectLaborChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateDirectLaborChangeOrderCloseoutRequest} returns this
 */
proto.CreateDirectLaborChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateDirectLaborChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 7;
 * @return {string}
 */
proto.CreateDirectLaborChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateDirectLaborChangeOrderCloseoutRequest} returns this
 */
proto.CreateDirectLaborChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateDirectLaborChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateDirectLaborChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateDirectLaborChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateDirectLaborChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateDirectLaborChangeOrderCloseoutResponse}
 */
proto.CreateDirectLaborChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateDirectLaborChangeOrderCloseoutResponse;
  return proto.CreateDirectLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateDirectLaborChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateDirectLaborChangeOrderCloseoutResponse}
 */
proto.CreateDirectLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateDirectLaborChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateDirectLaborChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateDirectLaborChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateDirectLaborChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateDirectLaborChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateDirectLaborChangeOrderCloseoutResponse} returns this
 */
proto.CreateDirectLaborChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDirectLaborChangeOrderCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDirectLaborChangeOrderCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDirectLaborChangeOrderCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDirectLaborChangeOrderCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDirectLaborChangeOrderCloseoutsRequest}
 */
proto.GetDirectLaborChangeOrderCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDirectLaborChangeOrderCloseoutsRequest;
  return proto.GetDirectLaborChangeOrderCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDirectLaborChangeOrderCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDirectLaborChangeOrderCloseoutsRequest}
 */
proto.GetDirectLaborChangeOrderCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDirectLaborChangeOrderCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDirectLaborChangeOrderCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDirectLaborChangeOrderCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDirectLaborChangeOrderCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetDirectLaborChangeOrderCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDirectLaborChangeOrderCloseoutsRequest} returns this
 */
proto.GetDirectLaborChangeOrderCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetDirectLaborChangeOrderCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDirectLaborChangeOrderCloseoutsRequest} returns this
 */
proto.GetDirectLaborChangeOrderCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetDirectLaborChangeOrderCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDirectLaborChangeOrderCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDirectLaborChangeOrderCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDirectLaborChangeOrderCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDirectLaborChangeOrderCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.DirectLaborChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDirectLaborChangeOrderCloseoutsResponse}
 */
proto.GetDirectLaborChangeOrderCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDirectLaborChangeOrderCloseoutsResponse;
  return proto.GetDirectLaborChangeOrderCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDirectLaborChangeOrderCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDirectLaborChangeOrderCloseoutsResponse}
 */
proto.GetDirectLaborChangeOrderCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DirectLaborChangeOrderCloseout;
      reader.readMessage(value,proto.DirectLaborChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDirectLaborChangeOrderCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDirectLaborChangeOrderCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDirectLaborChangeOrderCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDirectLaborChangeOrderCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DirectLaborChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DirectLaborChangeOrderCloseout closeouts = 1;
 * @return {!Array<!proto.DirectLaborChangeOrderCloseout>}
 */
proto.GetDirectLaborChangeOrderCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.DirectLaborChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DirectLaborChangeOrderCloseout, 1));
};


/**
 * @param {!Array<!proto.DirectLaborChangeOrderCloseout>} value
 * @return {!proto.GetDirectLaborChangeOrderCloseoutsResponse} returns this
*/
proto.GetDirectLaborChangeOrderCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DirectLaborChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DirectLaborChangeOrderCloseout}
 */
proto.GetDirectLaborChangeOrderCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DirectLaborChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetDirectLaborChangeOrderCloseoutsResponse} returns this
 */
proto.GetDirectLaborChangeOrderCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateDirectLaborChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateDirectLaborChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateDirectLaborChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDirectLaborChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.DirectLaborChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateDirectLaborChangeOrderCloseoutRequest}
 */
proto.UpdateDirectLaborChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateDirectLaborChangeOrderCloseoutRequest;
  return proto.UpdateDirectLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateDirectLaborChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateDirectLaborChangeOrderCloseoutRequest}
 */
proto.UpdateDirectLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DirectLaborChangeOrderCloseout;
      reader.readMessage(value,proto.DirectLaborChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateDirectLaborChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateDirectLaborChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateDirectLaborChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDirectLaborChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DirectLaborChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DirectLaborChangeOrderCloseout closeout = 1;
 * @return {?proto.DirectLaborChangeOrderCloseout}
 */
proto.UpdateDirectLaborChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.DirectLaborChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.DirectLaborChangeOrderCloseout, 1));
};


/**
 * @param {?proto.DirectLaborChangeOrderCloseout|undefined} value
 * @return {!proto.UpdateDirectLaborChangeOrderCloseoutRequest} returns this
*/
proto.UpdateDirectLaborChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateDirectLaborChangeOrderCloseoutRequest} returns this
 */
proto.UpdateDirectLaborChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateDirectLaborChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateDirectLaborChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateDirectLaborChangeOrderCloseoutRequest} returns this
 */
proto.UpdateDirectLaborChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateDirectLaborChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateDirectLaborChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateDirectLaborChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDirectLaborChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateDirectLaborChangeOrderCloseoutResponse}
 */
proto.UpdateDirectLaborChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateDirectLaborChangeOrderCloseoutResponse;
  return proto.UpdateDirectLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateDirectLaborChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateDirectLaborChangeOrderCloseoutResponse}
 */
proto.UpdateDirectLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateDirectLaborChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateDirectLaborChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateDirectLaborChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDirectLaborChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateDirectLaborChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateDirectLaborChangeOrderCloseoutResponse} returns this
 */
proto.UpdateDirectLaborChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDirectLaborChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDirectLaborChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDirectLaborChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDirectLaborChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDirectLaborChangeOrderCloseoutRequest}
 */
proto.DeleteDirectLaborChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDirectLaborChangeOrderCloseoutRequest;
  return proto.DeleteDirectLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDirectLaborChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDirectLaborChangeOrderCloseoutRequest}
 */
proto.DeleteDirectLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDirectLaborChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDirectLaborChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDirectLaborChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDirectLaborChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteDirectLaborChangeOrderCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDirectLaborChangeOrderCloseoutRequest} returns this
 */
proto.DeleteDirectLaborChangeOrderCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteDirectLaborChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDirectLaborChangeOrderCloseoutRequest} returns this
 */
proto.DeleteDirectLaborChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDirectLaborChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDirectLaborChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDirectLaborChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDirectLaborChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDirectLaborChangeOrderCloseoutResponse}
 */
proto.DeleteDirectLaborChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDirectLaborChangeOrderCloseoutResponse;
  return proto.DeleteDirectLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDirectLaborChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDirectLaborChangeOrderCloseoutResponse}
 */
proto.DeleteDirectLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDirectLaborChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDirectLaborChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDirectLaborChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDirectLaborChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteDirectLaborChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteDirectLaborChangeOrderCloseoutResponse} returns this
 */
proto.DeleteDirectLaborChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateLaborFactoringChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateLaborFactoringChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateLaborFactoringChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateLaborFactoringChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.LaborFactoringChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateLaborFactoringChangeOrderCloseoutRequest}
 */
proto.CreateLaborFactoringChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateLaborFactoringChangeOrderCloseoutRequest;
  return proto.CreateLaborFactoringChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateLaborFactoringChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateLaborFactoringChangeOrderCloseoutRequest}
 */
proto.CreateLaborFactoringChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.LaborFactoringChangeOrderCloseout;
      reader.readMessage(value,proto.LaborFactoringChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateLaborFactoringChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateLaborFactoringChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateLaborFactoringChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateLaborFactoringChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.LaborFactoringChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional LaborFactoringChangeOrderCloseout closeout = 1;
 * @return {?proto.LaborFactoringChangeOrderCloseout}
 */
proto.CreateLaborFactoringChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.LaborFactoringChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.LaborFactoringChangeOrderCloseout, 1));
};


/**
 * @param {?proto.LaborFactoringChangeOrderCloseout|undefined} value
 * @return {!proto.CreateLaborFactoringChangeOrderCloseoutRequest} returns this
*/
proto.CreateLaborFactoringChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateLaborFactoringChangeOrderCloseoutRequest} returns this
 */
proto.CreateLaborFactoringChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateLaborFactoringChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 8;
 * @return {string}
 */
proto.CreateLaborFactoringChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateLaborFactoringChangeOrderCloseoutRequest} returns this
 */
proto.CreateLaborFactoringChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateLaborFactoringChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateLaborFactoringChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateLaborFactoringChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateLaborFactoringChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateLaborFactoringChangeOrderCloseoutResponse}
 */
proto.CreateLaborFactoringChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateLaborFactoringChangeOrderCloseoutResponse;
  return proto.CreateLaborFactoringChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateLaborFactoringChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateLaborFactoringChangeOrderCloseoutResponse}
 */
proto.CreateLaborFactoringChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateLaborFactoringChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateLaborFactoringChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateLaborFactoringChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateLaborFactoringChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateLaborFactoringChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateLaborFactoringChangeOrderCloseoutResponse} returns this
 */
proto.CreateLaborFactoringChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetLaborFactoringChangeOrderCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetLaborFactoringChangeOrderCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetLaborFactoringChangeOrderCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLaborFactoringChangeOrderCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetLaborFactoringChangeOrderCloseoutsRequest}
 */
proto.GetLaborFactoringChangeOrderCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetLaborFactoringChangeOrderCloseoutsRequest;
  return proto.GetLaborFactoringChangeOrderCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetLaborFactoringChangeOrderCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetLaborFactoringChangeOrderCloseoutsRequest}
 */
proto.GetLaborFactoringChangeOrderCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetLaborFactoringChangeOrderCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetLaborFactoringChangeOrderCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetLaborFactoringChangeOrderCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLaborFactoringChangeOrderCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetLaborFactoringChangeOrderCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetLaborFactoringChangeOrderCloseoutsRequest} returns this
 */
proto.GetLaborFactoringChangeOrderCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetLaborFactoringChangeOrderCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetLaborFactoringChangeOrderCloseoutsRequest} returns this
 */
proto.GetLaborFactoringChangeOrderCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetLaborFactoringChangeOrderCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetLaborFactoringChangeOrderCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetLaborFactoringChangeOrderCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetLaborFactoringChangeOrderCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLaborFactoringChangeOrderCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.LaborFactoringChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetLaborFactoringChangeOrderCloseoutsResponse}
 */
proto.GetLaborFactoringChangeOrderCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetLaborFactoringChangeOrderCloseoutsResponse;
  return proto.GetLaborFactoringChangeOrderCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetLaborFactoringChangeOrderCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetLaborFactoringChangeOrderCloseoutsResponse}
 */
proto.GetLaborFactoringChangeOrderCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.LaborFactoringChangeOrderCloseout;
      reader.readMessage(value,proto.LaborFactoringChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetLaborFactoringChangeOrderCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetLaborFactoringChangeOrderCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetLaborFactoringChangeOrderCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLaborFactoringChangeOrderCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.LaborFactoringChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LaborFactoringChangeOrderCloseout closeouts = 1;
 * @return {!Array<!proto.LaborFactoringChangeOrderCloseout>}
 */
proto.GetLaborFactoringChangeOrderCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.LaborFactoringChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.LaborFactoringChangeOrderCloseout, 1));
};


/**
 * @param {!Array<!proto.LaborFactoringChangeOrderCloseout>} value
 * @return {!proto.GetLaborFactoringChangeOrderCloseoutsResponse} returns this
*/
proto.GetLaborFactoringChangeOrderCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.LaborFactoringChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LaborFactoringChangeOrderCloseout}
 */
proto.GetLaborFactoringChangeOrderCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.LaborFactoringChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetLaborFactoringChangeOrderCloseoutsResponse} returns this
 */
proto.GetLaborFactoringChangeOrderCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateLaborFactoringChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateLaborFactoringChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateLaborFactoringChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateLaborFactoringChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.LaborFactoringChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateLaborFactoringChangeOrderCloseoutRequest}
 */
proto.UpdateLaborFactoringChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateLaborFactoringChangeOrderCloseoutRequest;
  return proto.UpdateLaborFactoringChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateLaborFactoringChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateLaborFactoringChangeOrderCloseoutRequest}
 */
proto.UpdateLaborFactoringChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.LaborFactoringChangeOrderCloseout;
      reader.readMessage(value,proto.LaborFactoringChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateLaborFactoringChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateLaborFactoringChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateLaborFactoringChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateLaborFactoringChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.LaborFactoringChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional LaborFactoringChangeOrderCloseout closeout = 1;
 * @return {?proto.LaborFactoringChangeOrderCloseout}
 */
proto.UpdateLaborFactoringChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.LaborFactoringChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.LaborFactoringChangeOrderCloseout, 1));
};


/**
 * @param {?proto.LaborFactoringChangeOrderCloseout|undefined} value
 * @return {!proto.UpdateLaborFactoringChangeOrderCloseoutRequest} returns this
*/
proto.UpdateLaborFactoringChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateLaborFactoringChangeOrderCloseoutRequest} returns this
 */
proto.UpdateLaborFactoringChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateLaborFactoringChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateLaborFactoringChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateLaborFactoringChangeOrderCloseoutRequest} returns this
 */
proto.UpdateLaborFactoringChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateLaborFactoringChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateLaborFactoringChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateLaborFactoringChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateLaborFactoringChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateLaborFactoringChangeOrderCloseoutResponse}
 */
proto.UpdateLaborFactoringChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateLaborFactoringChangeOrderCloseoutResponse;
  return proto.UpdateLaborFactoringChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateLaborFactoringChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateLaborFactoringChangeOrderCloseoutResponse}
 */
proto.UpdateLaborFactoringChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateLaborFactoringChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateLaborFactoringChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateLaborFactoringChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateLaborFactoringChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateLaborFactoringChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateLaborFactoringChangeOrderCloseoutResponse} returns this
 */
proto.UpdateLaborFactoringChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteLaborFactoringChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteLaborFactoringChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteLaborFactoringChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteLaborFactoringChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteLaborFactoringChangeOrderCloseoutRequest}
 */
proto.DeleteLaborFactoringChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteLaborFactoringChangeOrderCloseoutRequest;
  return proto.DeleteLaborFactoringChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteLaborFactoringChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteLaborFactoringChangeOrderCloseoutRequest}
 */
proto.DeleteLaborFactoringChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteLaborFactoringChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteLaborFactoringChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteLaborFactoringChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteLaborFactoringChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteLaborFactoringChangeOrderCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteLaborFactoringChangeOrderCloseoutRequest} returns this
 */
proto.DeleteLaborFactoringChangeOrderCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteLaborFactoringChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteLaborFactoringChangeOrderCloseoutRequest} returns this
 */
proto.DeleteLaborFactoringChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteLaborFactoringChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteLaborFactoringChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteLaborFactoringChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteLaborFactoringChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteLaborFactoringChangeOrderCloseoutResponse}
 */
proto.DeleteLaborFactoringChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteLaborFactoringChangeOrderCloseoutResponse;
  return proto.DeleteLaborFactoringChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteLaborFactoringChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteLaborFactoringChangeOrderCloseoutResponse}
 */
proto.DeleteLaborFactoringChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteLaborFactoringChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteLaborFactoringChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteLaborFactoringChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteLaborFactoringChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteLaborFactoringChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteLaborFactoringChangeOrderCloseoutResponse} returns this
 */
proto.DeleteLaborFactoringChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateIncidentalLaborChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateIncidentalLaborChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateIncidentalLaborChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIncidentalLaborChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.IncidentalLaborChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateIncidentalLaborChangeOrderCloseoutRequest}
 */
proto.CreateIncidentalLaborChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateIncidentalLaborChangeOrderCloseoutRequest;
  return proto.CreateIncidentalLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateIncidentalLaborChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateIncidentalLaborChangeOrderCloseoutRequest}
 */
proto.CreateIncidentalLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IncidentalLaborChangeOrderCloseout;
      reader.readMessage(value,proto.IncidentalLaborChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateIncidentalLaborChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateIncidentalLaborChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateIncidentalLaborChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIncidentalLaborChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.IncidentalLaborChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional IncidentalLaborChangeOrderCloseout closeout = 1;
 * @return {?proto.IncidentalLaborChangeOrderCloseout}
 */
proto.CreateIncidentalLaborChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.IncidentalLaborChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.IncidentalLaborChangeOrderCloseout, 1));
};


/**
 * @param {?proto.IncidentalLaborChangeOrderCloseout|undefined} value
 * @return {!proto.CreateIncidentalLaborChangeOrderCloseoutRequest} returns this
*/
proto.CreateIncidentalLaborChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateIncidentalLaborChangeOrderCloseoutRequest} returns this
 */
proto.CreateIncidentalLaborChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateIncidentalLaborChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 6;
 * @return {string}
 */
proto.CreateIncidentalLaborChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateIncidentalLaborChangeOrderCloseoutRequest} returns this
 */
proto.CreateIncidentalLaborChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateIncidentalLaborChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateIncidentalLaborChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateIncidentalLaborChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIncidentalLaborChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateIncidentalLaborChangeOrderCloseoutResponse}
 */
proto.CreateIncidentalLaborChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateIncidentalLaborChangeOrderCloseoutResponse;
  return proto.CreateIncidentalLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateIncidentalLaborChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateIncidentalLaborChangeOrderCloseoutResponse}
 */
proto.CreateIncidentalLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateIncidentalLaborChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateIncidentalLaborChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateIncidentalLaborChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIncidentalLaborChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateIncidentalLaborChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateIncidentalLaborChangeOrderCloseoutResponse} returns this
 */
proto.CreateIncidentalLaborChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetIncidentalLaborChangeOrderCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetIncidentalLaborChangeOrderCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetIncidentalLaborChangeOrderCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIncidentalLaborChangeOrderCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetIncidentalLaborChangeOrderCloseoutsRequest}
 */
proto.GetIncidentalLaborChangeOrderCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetIncidentalLaborChangeOrderCloseoutsRequest;
  return proto.GetIncidentalLaborChangeOrderCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetIncidentalLaborChangeOrderCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetIncidentalLaborChangeOrderCloseoutsRequest}
 */
proto.GetIncidentalLaborChangeOrderCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetIncidentalLaborChangeOrderCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetIncidentalLaborChangeOrderCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetIncidentalLaborChangeOrderCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIncidentalLaborChangeOrderCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetIncidentalLaborChangeOrderCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetIncidentalLaborChangeOrderCloseoutsRequest} returns this
 */
proto.GetIncidentalLaborChangeOrderCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetIncidentalLaborChangeOrderCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetIncidentalLaborChangeOrderCloseoutsRequest} returns this
 */
proto.GetIncidentalLaborChangeOrderCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetIncidentalLaborChangeOrderCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetIncidentalLaborChangeOrderCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetIncidentalLaborChangeOrderCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetIncidentalLaborChangeOrderCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIncidentalLaborChangeOrderCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.IncidentalLaborChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetIncidentalLaborChangeOrderCloseoutsResponse}
 */
proto.GetIncidentalLaborChangeOrderCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetIncidentalLaborChangeOrderCloseoutsResponse;
  return proto.GetIncidentalLaborChangeOrderCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetIncidentalLaborChangeOrderCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetIncidentalLaborChangeOrderCloseoutsResponse}
 */
proto.GetIncidentalLaborChangeOrderCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IncidentalLaborChangeOrderCloseout;
      reader.readMessage(value,proto.IncidentalLaborChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetIncidentalLaborChangeOrderCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetIncidentalLaborChangeOrderCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetIncidentalLaborChangeOrderCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIncidentalLaborChangeOrderCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.IncidentalLaborChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IncidentalLaborChangeOrderCloseout closeouts = 1;
 * @return {!Array<!proto.IncidentalLaborChangeOrderCloseout>}
 */
proto.GetIncidentalLaborChangeOrderCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.IncidentalLaborChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IncidentalLaborChangeOrderCloseout, 1));
};


/**
 * @param {!Array<!proto.IncidentalLaborChangeOrderCloseout>} value
 * @return {!proto.GetIncidentalLaborChangeOrderCloseoutsResponse} returns this
*/
proto.GetIncidentalLaborChangeOrderCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.IncidentalLaborChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IncidentalLaborChangeOrderCloseout}
 */
proto.GetIncidentalLaborChangeOrderCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.IncidentalLaborChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetIncidentalLaborChangeOrderCloseoutsResponse} returns this
 */
proto.GetIncidentalLaborChangeOrderCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateIncidentalLaborChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.IncidentalLaborChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateIncidentalLaborChangeOrderCloseoutRequest}
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateIncidentalLaborChangeOrderCloseoutRequest;
  return proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateIncidentalLaborChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateIncidentalLaborChangeOrderCloseoutRequest}
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IncidentalLaborChangeOrderCloseout;
      reader.readMessage(value,proto.IncidentalLaborChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateIncidentalLaborChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.IncidentalLaborChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional IncidentalLaborChangeOrderCloseout closeout = 1;
 * @return {?proto.IncidentalLaborChangeOrderCloseout}
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.IncidentalLaborChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.IncidentalLaborChangeOrderCloseout, 1));
};


/**
 * @param {?proto.IncidentalLaborChangeOrderCloseout|undefined} value
 * @return {!proto.UpdateIncidentalLaborChangeOrderCloseoutRequest} returns this
*/
proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateIncidentalLaborChangeOrderCloseoutRequest} returns this
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateIncidentalLaborChangeOrderCloseoutRequest} returns this
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateIncidentalLaborChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateIncidentalLaborChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateIncidentalLaborChangeOrderCloseoutResponse}
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateIncidentalLaborChangeOrderCloseoutResponse;
  return proto.UpdateIncidentalLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateIncidentalLaborChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateIncidentalLaborChangeOrderCloseoutResponse}
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateIncidentalLaborChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateIncidentalLaborChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateIncidentalLaborChangeOrderCloseoutResponse} returns this
 */
proto.UpdateIncidentalLaborChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteIncidentalLaborChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteIncidentalLaborChangeOrderCloseoutRequest}
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteIncidentalLaborChangeOrderCloseoutRequest;
  return proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteIncidentalLaborChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteIncidentalLaborChangeOrderCloseoutRequest}
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteIncidentalLaborChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteIncidentalLaborChangeOrderCloseoutRequest} returns this
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteIncidentalLaborChangeOrderCloseoutRequest} returns this
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteIncidentalLaborChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteIncidentalLaborChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteIncidentalLaborChangeOrderCloseoutResponse}
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteIncidentalLaborChangeOrderCloseoutResponse;
  return proto.DeleteIncidentalLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteIncidentalLaborChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteIncidentalLaborChangeOrderCloseoutResponse}
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteIncidentalLaborChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteIncidentalLaborChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteIncidentalLaborChangeOrderCloseoutResponse} returns this
 */
proto.DeleteIncidentalLaborChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateIndirectLaborChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateIndirectLaborChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateIndirectLaborChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIndirectLaborChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.IndirectLaborChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateIndirectLaborChangeOrderCloseoutRequest}
 */
proto.CreateIndirectLaborChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateIndirectLaborChangeOrderCloseoutRequest;
  return proto.CreateIndirectLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateIndirectLaborChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateIndirectLaborChangeOrderCloseoutRequest}
 */
proto.CreateIndirectLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IndirectLaborChangeOrderCloseout;
      reader.readMessage(value,proto.IndirectLaborChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateIndirectLaborChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateIndirectLaborChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateIndirectLaborChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIndirectLaborChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.IndirectLaborChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional IndirectLaborChangeOrderCloseout closeout = 1;
 * @return {?proto.IndirectLaborChangeOrderCloseout}
 */
proto.CreateIndirectLaborChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.IndirectLaborChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.IndirectLaborChangeOrderCloseout, 1));
};


/**
 * @param {?proto.IndirectLaborChangeOrderCloseout|undefined} value
 * @return {!proto.CreateIndirectLaborChangeOrderCloseoutRequest} returns this
*/
proto.CreateIndirectLaborChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateIndirectLaborChangeOrderCloseoutRequest} returns this
 */
proto.CreateIndirectLaborChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateIndirectLaborChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 6;
 * @return {string}
 */
proto.CreateIndirectLaborChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateIndirectLaborChangeOrderCloseoutRequest} returns this
 */
proto.CreateIndirectLaborChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateIndirectLaborChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateIndirectLaborChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateIndirectLaborChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIndirectLaborChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateIndirectLaborChangeOrderCloseoutResponse}
 */
proto.CreateIndirectLaborChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateIndirectLaborChangeOrderCloseoutResponse;
  return proto.CreateIndirectLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateIndirectLaborChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateIndirectLaborChangeOrderCloseoutResponse}
 */
proto.CreateIndirectLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateIndirectLaborChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateIndirectLaborChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateIndirectLaborChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateIndirectLaborChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateIndirectLaborChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateIndirectLaborChangeOrderCloseoutResponse} returns this
 */
proto.CreateIndirectLaborChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetIndirectLaborChangeOrderCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetIndirectLaborChangeOrderCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetIndirectLaborChangeOrderCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIndirectLaborChangeOrderCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetIndirectLaborChangeOrderCloseoutsRequest}
 */
proto.GetIndirectLaborChangeOrderCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetIndirectLaborChangeOrderCloseoutsRequest;
  return proto.GetIndirectLaborChangeOrderCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetIndirectLaborChangeOrderCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetIndirectLaborChangeOrderCloseoutsRequest}
 */
proto.GetIndirectLaborChangeOrderCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetIndirectLaborChangeOrderCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetIndirectLaborChangeOrderCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetIndirectLaborChangeOrderCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIndirectLaborChangeOrderCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetIndirectLaborChangeOrderCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetIndirectLaborChangeOrderCloseoutsRequest} returns this
 */
proto.GetIndirectLaborChangeOrderCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetIndirectLaborChangeOrderCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetIndirectLaborChangeOrderCloseoutsRequest} returns this
 */
proto.GetIndirectLaborChangeOrderCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetIndirectLaborChangeOrderCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetIndirectLaborChangeOrderCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetIndirectLaborChangeOrderCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetIndirectLaborChangeOrderCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIndirectLaborChangeOrderCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.IndirectLaborChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetIndirectLaborChangeOrderCloseoutsResponse}
 */
proto.GetIndirectLaborChangeOrderCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetIndirectLaborChangeOrderCloseoutsResponse;
  return proto.GetIndirectLaborChangeOrderCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetIndirectLaborChangeOrderCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetIndirectLaborChangeOrderCloseoutsResponse}
 */
proto.GetIndirectLaborChangeOrderCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IndirectLaborChangeOrderCloseout;
      reader.readMessage(value,proto.IndirectLaborChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetIndirectLaborChangeOrderCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetIndirectLaborChangeOrderCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetIndirectLaborChangeOrderCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetIndirectLaborChangeOrderCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.IndirectLaborChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IndirectLaborChangeOrderCloseout closeouts = 1;
 * @return {!Array<!proto.IndirectLaborChangeOrderCloseout>}
 */
proto.GetIndirectLaborChangeOrderCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.IndirectLaborChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IndirectLaborChangeOrderCloseout, 1));
};


/**
 * @param {!Array<!proto.IndirectLaborChangeOrderCloseout>} value
 * @return {!proto.GetIndirectLaborChangeOrderCloseoutsResponse} returns this
*/
proto.GetIndirectLaborChangeOrderCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.IndirectLaborChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IndirectLaborChangeOrderCloseout}
 */
proto.GetIndirectLaborChangeOrderCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.IndirectLaborChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetIndirectLaborChangeOrderCloseoutsResponse} returns this
 */
proto.GetIndirectLaborChangeOrderCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateIndirectLaborChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateIndirectLaborChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateIndirectLaborChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIndirectLaborChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.IndirectLaborChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateIndirectLaborChangeOrderCloseoutRequest}
 */
proto.UpdateIndirectLaborChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateIndirectLaborChangeOrderCloseoutRequest;
  return proto.UpdateIndirectLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateIndirectLaborChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateIndirectLaborChangeOrderCloseoutRequest}
 */
proto.UpdateIndirectLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IndirectLaborChangeOrderCloseout;
      reader.readMessage(value,proto.IndirectLaborChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateIndirectLaborChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateIndirectLaborChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateIndirectLaborChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIndirectLaborChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.IndirectLaborChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional IndirectLaborChangeOrderCloseout closeout = 1;
 * @return {?proto.IndirectLaborChangeOrderCloseout}
 */
proto.UpdateIndirectLaborChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.IndirectLaborChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.IndirectLaborChangeOrderCloseout, 1));
};


/**
 * @param {?proto.IndirectLaborChangeOrderCloseout|undefined} value
 * @return {!proto.UpdateIndirectLaborChangeOrderCloseoutRequest} returns this
*/
proto.UpdateIndirectLaborChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateIndirectLaborChangeOrderCloseoutRequest} returns this
 */
proto.UpdateIndirectLaborChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateIndirectLaborChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateIndirectLaborChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateIndirectLaborChangeOrderCloseoutRequest} returns this
 */
proto.UpdateIndirectLaborChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateIndirectLaborChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateIndirectLaborChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateIndirectLaborChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIndirectLaborChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateIndirectLaborChangeOrderCloseoutResponse}
 */
proto.UpdateIndirectLaborChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateIndirectLaborChangeOrderCloseoutResponse;
  return proto.UpdateIndirectLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateIndirectLaborChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateIndirectLaborChangeOrderCloseoutResponse}
 */
proto.UpdateIndirectLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateIndirectLaborChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateIndirectLaborChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateIndirectLaborChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIndirectLaborChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateIndirectLaborChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateIndirectLaborChangeOrderCloseoutResponse} returns this
 */
proto.UpdateIndirectLaborChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteIndirectLaborChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteIndirectLaborChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteIndirectLaborChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIndirectLaborChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteIndirectLaborChangeOrderCloseoutRequest}
 */
proto.DeleteIndirectLaborChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteIndirectLaborChangeOrderCloseoutRequest;
  return proto.DeleteIndirectLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteIndirectLaborChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteIndirectLaborChangeOrderCloseoutRequest}
 */
proto.DeleteIndirectLaborChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteIndirectLaborChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteIndirectLaborChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteIndirectLaborChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIndirectLaborChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteIndirectLaborChangeOrderCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteIndirectLaborChangeOrderCloseoutRequest} returns this
 */
proto.DeleteIndirectLaborChangeOrderCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteIndirectLaborChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteIndirectLaborChangeOrderCloseoutRequest} returns this
 */
proto.DeleteIndirectLaborChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteIndirectLaborChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteIndirectLaborChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteIndirectLaborChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIndirectLaborChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteIndirectLaborChangeOrderCloseoutResponse}
 */
proto.DeleteIndirectLaborChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteIndirectLaborChangeOrderCloseoutResponse;
  return proto.DeleteIndirectLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteIndirectLaborChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteIndirectLaborChangeOrderCloseoutResponse}
 */
proto.DeleteIndirectLaborChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteIndirectLaborChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteIndirectLaborChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteIndirectLaborChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteIndirectLaborChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteIndirectLaborChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteIndirectLaborChangeOrderCloseoutResponse} returns this
 */
proto.DeleteIndirectLaborChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateEquipmentChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateEquipmentChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateEquipmentChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateEquipmentChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.EquipmentChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateEquipmentChangeOrderCloseoutRequest}
 */
proto.CreateEquipmentChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateEquipmentChangeOrderCloseoutRequest;
  return proto.CreateEquipmentChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateEquipmentChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateEquipmentChangeOrderCloseoutRequest}
 */
proto.CreateEquipmentChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EquipmentChangeOrderCloseout;
      reader.readMessage(value,proto.EquipmentChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateEquipmentChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateEquipmentChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateEquipmentChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateEquipmentChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.EquipmentChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional EquipmentChangeOrderCloseout closeout = 1;
 * @return {?proto.EquipmentChangeOrderCloseout}
 */
proto.CreateEquipmentChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.EquipmentChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.EquipmentChangeOrderCloseout, 1));
};


/**
 * @param {?proto.EquipmentChangeOrderCloseout|undefined} value
 * @return {!proto.CreateEquipmentChangeOrderCloseoutRequest} returns this
*/
proto.CreateEquipmentChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateEquipmentChangeOrderCloseoutRequest} returns this
 */
proto.CreateEquipmentChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateEquipmentChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 4;
 * @return {string}
 */
proto.CreateEquipmentChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateEquipmentChangeOrderCloseoutRequest} returns this
 */
proto.CreateEquipmentChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateEquipmentChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateEquipmentChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateEquipmentChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateEquipmentChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateEquipmentChangeOrderCloseoutResponse}
 */
proto.CreateEquipmentChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateEquipmentChangeOrderCloseoutResponse;
  return proto.CreateEquipmentChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateEquipmentChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateEquipmentChangeOrderCloseoutResponse}
 */
proto.CreateEquipmentChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateEquipmentChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateEquipmentChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateEquipmentChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateEquipmentChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateEquipmentChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateEquipmentChangeOrderCloseoutResponse} returns this
 */
proto.CreateEquipmentChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetEquipmentChangeOrderCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetEquipmentChangeOrderCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetEquipmentChangeOrderCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEquipmentChangeOrderCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetEquipmentChangeOrderCloseoutsRequest}
 */
proto.GetEquipmentChangeOrderCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetEquipmentChangeOrderCloseoutsRequest;
  return proto.GetEquipmentChangeOrderCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetEquipmentChangeOrderCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetEquipmentChangeOrderCloseoutsRequest}
 */
proto.GetEquipmentChangeOrderCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetEquipmentChangeOrderCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetEquipmentChangeOrderCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetEquipmentChangeOrderCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEquipmentChangeOrderCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetEquipmentChangeOrderCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetEquipmentChangeOrderCloseoutsRequest} returns this
 */
proto.GetEquipmentChangeOrderCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetEquipmentChangeOrderCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetEquipmentChangeOrderCloseoutsRequest} returns this
 */
proto.GetEquipmentChangeOrderCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetEquipmentChangeOrderCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetEquipmentChangeOrderCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetEquipmentChangeOrderCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetEquipmentChangeOrderCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEquipmentChangeOrderCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.EquipmentChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetEquipmentChangeOrderCloseoutsResponse}
 */
proto.GetEquipmentChangeOrderCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetEquipmentChangeOrderCloseoutsResponse;
  return proto.GetEquipmentChangeOrderCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetEquipmentChangeOrderCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetEquipmentChangeOrderCloseoutsResponse}
 */
proto.GetEquipmentChangeOrderCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EquipmentChangeOrderCloseout;
      reader.readMessage(value,proto.EquipmentChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetEquipmentChangeOrderCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetEquipmentChangeOrderCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetEquipmentChangeOrderCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEquipmentChangeOrderCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EquipmentChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EquipmentChangeOrderCloseout closeouts = 1;
 * @return {!Array<!proto.EquipmentChangeOrderCloseout>}
 */
proto.GetEquipmentChangeOrderCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.EquipmentChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EquipmentChangeOrderCloseout, 1));
};


/**
 * @param {!Array<!proto.EquipmentChangeOrderCloseout>} value
 * @return {!proto.GetEquipmentChangeOrderCloseoutsResponse} returns this
*/
proto.GetEquipmentChangeOrderCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EquipmentChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EquipmentChangeOrderCloseout}
 */
proto.GetEquipmentChangeOrderCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EquipmentChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetEquipmentChangeOrderCloseoutsResponse} returns this
 */
proto.GetEquipmentChangeOrderCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEquipmentChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEquipmentChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEquipmentChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEquipmentChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.EquipmentChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEquipmentChangeOrderCloseoutRequest}
 */
proto.UpdateEquipmentChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEquipmentChangeOrderCloseoutRequest;
  return proto.UpdateEquipmentChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEquipmentChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEquipmentChangeOrderCloseoutRequest}
 */
proto.UpdateEquipmentChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EquipmentChangeOrderCloseout;
      reader.readMessage(value,proto.EquipmentChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEquipmentChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEquipmentChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEquipmentChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEquipmentChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.EquipmentChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional EquipmentChangeOrderCloseout closeout = 1;
 * @return {?proto.EquipmentChangeOrderCloseout}
 */
proto.UpdateEquipmentChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.EquipmentChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.EquipmentChangeOrderCloseout, 1));
};


/**
 * @param {?proto.EquipmentChangeOrderCloseout|undefined} value
 * @return {!proto.UpdateEquipmentChangeOrderCloseoutRequest} returns this
*/
proto.UpdateEquipmentChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateEquipmentChangeOrderCloseoutRequest} returns this
 */
proto.UpdateEquipmentChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateEquipmentChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateEquipmentChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateEquipmentChangeOrderCloseoutRequest} returns this
 */
proto.UpdateEquipmentChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEquipmentChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEquipmentChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEquipmentChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEquipmentChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEquipmentChangeOrderCloseoutResponse}
 */
proto.UpdateEquipmentChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEquipmentChangeOrderCloseoutResponse;
  return proto.UpdateEquipmentChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEquipmentChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEquipmentChangeOrderCloseoutResponse}
 */
proto.UpdateEquipmentChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEquipmentChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEquipmentChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEquipmentChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEquipmentChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateEquipmentChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateEquipmentChangeOrderCloseoutResponse} returns this
 */
proto.UpdateEquipmentChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteEquipmentChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteEquipmentChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteEquipmentChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEquipmentChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteEquipmentChangeOrderCloseoutRequest}
 */
proto.DeleteEquipmentChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteEquipmentChangeOrderCloseoutRequest;
  return proto.DeleteEquipmentChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteEquipmentChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteEquipmentChangeOrderCloseoutRequest}
 */
proto.DeleteEquipmentChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteEquipmentChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteEquipmentChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteEquipmentChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEquipmentChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteEquipmentChangeOrderCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteEquipmentChangeOrderCloseoutRequest} returns this
 */
proto.DeleteEquipmentChangeOrderCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteEquipmentChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteEquipmentChangeOrderCloseoutRequest} returns this
 */
proto.DeleteEquipmentChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteEquipmentChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteEquipmentChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteEquipmentChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEquipmentChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteEquipmentChangeOrderCloseoutResponse}
 */
proto.DeleteEquipmentChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteEquipmentChangeOrderCloseoutResponse;
  return proto.DeleteEquipmentChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteEquipmentChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteEquipmentChangeOrderCloseoutResponse}
 */
proto.DeleteEquipmentChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteEquipmentChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteEquipmentChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteEquipmentChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEquipmentChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteEquipmentChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteEquipmentChangeOrderCloseoutResponse} returns this
 */
proto.DeleteEquipmentChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateGeneralExpenseChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateGeneralExpenseChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateGeneralExpenseChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGeneralExpenseChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.GeneralExpenseChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateGeneralExpenseChangeOrderCloseoutRequest}
 */
proto.CreateGeneralExpenseChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateGeneralExpenseChangeOrderCloseoutRequest;
  return proto.CreateGeneralExpenseChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateGeneralExpenseChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateGeneralExpenseChangeOrderCloseoutRequest}
 */
proto.CreateGeneralExpenseChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GeneralExpenseChangeOrderCloseout;
      reader.readMessage(value,proto.GeneralExpenseChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateGeneralExpenseChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateGeneralExpenseChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateGeneralExpenseChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGeneralExpenseChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GeneralExpenseChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional GeneralExpenseChangeOrderCloseout closeout = 1;
 * @return {?proto.GeneralExpenseChangeOrderCloseout}
 */
proto.CreateGeneralExpenseChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.GeneralExpenseChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.GeneralExpenseChangeOrderCloseout, 1));
};


/**
 * @param {?proto.GeneralExpenseChangeOrderCloseout|undefined} value
 * @return {!proto.CreateGeneralExpenseChangeOrderCloseoutRequest} returns this
*/
proto.CreateGeneralExpenseChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateGeneralExpenseChangeOrderCloseoutRequest} returns this
 */
proto.CreateGeneralExpenseChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateGeneralExpenseChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 4;
 * @return {string}
 */
proto.CreateGeneralExpenseChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateGeneralExpenseChangeOrderCloseoutRequest} returns this
 */
proto.CreateGeneralExpenseChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateGeneralExpenseChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateGeneralExpenseChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateGeneralExpenseChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGeneralExpenseChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateGeneralExpenseChangeOrderCloseoutResponse}
 */
proto.CreateGeneralExpenseChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateGeneralExpenseChangeOrderCloseoutResponse;
  return proto.CreateGeneralExpenseChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateGeneralExpenseChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateGeneralExpenseChangeOrderCloseoutResponse}
 */
proto.CreateGeneralExpenseChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateGeneralExpenseChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateGeneralExpenseChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateGeneralExpenseChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGeneralExpenseChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateGeneralExpenseChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateGeneralExpenseChangeOrderCloseoutResponse} returns this
 */
proto.CreateGeneralExpenseChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetGeneralExpenseChangeOrderCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetGeneralExpenseChangeOrderCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetGeneralExpenseChangeOrderCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGeneralExpenseChangeOrderCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetGeneralExpenseChangeOrderCloseoutsRequest}
 */
proto.GetGeneralExpenseChangeOrderCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetGeneralExpenseChangeOrderCloseoutsRequest;
  return proto.GetGeneralExpenseChangeOrderCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetGeneralExpenseChangeOrderCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetGeneralExpenseChangeOrderCloseoutsRequest}
 */
proto.GetGeneralExpenseChangeOrderCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetGeneralExpenseChangeOrderCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetGeneralExpenseChangeOrderCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetGeneralExpenseChangeOrderCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGeneralExpenseChangeOrderCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetGeneralExpenseChangeOrderCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetGeneralExpenseChangeOrderCloseoutsRequest} returns this
 */
proto.GetGeneralExpenseChangeOrderCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetGeneralExpenseChangeOrderCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetGeneralExpenseChangeOrderCloseoutsRequest} returns this
 */
proto.GetGeneralExpenseChangeOrderCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetGeneralExpenseChangeOrderCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetGeneralExpenseChangeOrderCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetGeneralExpenseChangeOrderCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetGeneralExpenseChangeOrderCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGeneralExpenseChangeOrderCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.GeneralExpenseChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetGeneralExpenseChangeOrderCloseoutsResponse}
 */
proto.GetGeneralExpenseChangeOrderCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetGeneralExpenseChangeOrderCloseoutsResponse;
  return proto.GetGeneralExpenseChangeOrderCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetGeneralExpenseChangeOrderCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetGeneralExpenseChangeOrderCloseoutsResponse}
 */
proto.GetGeneralExpenseChangeOrderCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GeneralExpenseChangeOrderCloseout;
      reader.readMessage(value,proto.GeneralExpenseChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetGeneralExpenseChangeOrderCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetGeneralExpenseChangeOrderCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetGeneralExpenseChangeOrderCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGeneralExpenseChangeOrderCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GeneralExpenseChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GeneralExpenseChangeOrderCloseout closeouts = 1;
 * @return {!Array<!proto.GeneralExpenseChangeOrderCloseout>}
 */
proto.GetGeneralExpenseChangeOrderCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.GeneralExpenseChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GeneralExpenseChangeOrderCloseout, 1));
};


/**
 * @param {!Array<!proto.GeneralExpenseChangeOrderCloseout>} value
 * @return {!proto.GetGeneralExpenseChangeOrderCloseoutsResponse} returns this
*/
proto.GetGeneralExpenseChangeOrderCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GeneralExpenseChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GeneralExpenseChangeOrderCloseout}
 */
proto.GetGeneralExpenseChangeOrderCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GeneralExpenseChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetGeneralExpenseChangeOrderCloseoutsResponse} returns this
 */
proto.GetGeneralExpenseChangeOrderCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateGeneralExpenseChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.GeneralExpenseChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateGeneralExpenseChangeOrderCloseoutRequest}
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateGeneralExpenseChangeOrderCloseoutRequest;
  return proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateGeneralExpenseChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateGeneralExpenseChangeOrderCloseoutRequest}
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GeneralExpenseChangeOrderCloseout;
      reader.readMessage(value,proto.GeneralExpenseChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateGeneralExpenseChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GeneralExpenseChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional GeneralExpenseChangeOrderCloseout closeout = 1;
 * @return {?proto.GeneralExpenseChangeOrderCloseout}
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.GeneralExpenseChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.GeneralExpenseChangeOrderCloseout, 1));
};


/**
 * @param {?proto.GeneralExpenseChangeOrderCloseout|undefined} value
 * @return {!proto.UpdateGeneralExpenseChangeOrderCloseoutRequest} returns this
*/
proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateGeneralExpenseChangeOrderCloseoutRequest} returns this
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateGeneralExpenseChangeOrderCloseoutRequest} returns this
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateGeneralExpenseChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateGeneralExpenseChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateGeneralExpenseChangeOrderCloseoutResponse}
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateGeneralExpenseChangeOrderCloseoutResponse;
  return proto.UpdateGeneralExpenseChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateGeneralExpenseChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateGeneralExpenseChangeOrderCloseoutResponse}
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateGeneralExpenseChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateGeneralExpenseChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateGeneralExpenseChangeOrderCloseoutResponse} returns this
 */
proto.UpdateGeneralExpenseChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteGeneralExpenseChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteGeneralExpenseChangeOrderCloseoutRequest}
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteGeneralExpenseChangeOrderCloseoutRequest;
  return proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteGeneralExpenseChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteGeneralExpenseChangeOrderCloseoutRequest}
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteGeneralExpenseChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteGeneralExpenseChangeOrderCloseoutRequest} returns this
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteGeneralExpenseChangeOrderCloseoutRequest} returns this
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteGeneralExpenseChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteGeneralExpenseChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteGeneralExpenseChangeOrderCloseoutResponse}
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteGeneralExpenseChangeOrderCloseoutResponse;
  return proto.DeleteGeneralExpenseChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteGeneralExpenseChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteGeneralExpenseChangeOrderCloseoutResponse}
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteGeneralExpenseChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteGeneralExpenseChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteGeneralExpenseChangeOrderCloseoutResponse} returns this
 */
proto.DeleteGeneralExpenseChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateSubcontractChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateSubcontractChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateSubcontractChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateSubcontractChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.SubcontractChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateSubcontractChangeOrderCloseoutRequest}
 */
proto.CreateSubcontractChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateSubcontractChangeOrderCloseoutRequest;
  return proto.CreateSubcontractChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateSubcontractChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateSubcontractChangeOrderCloseoutRequest}
 */
proto.CreateSubcontractChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SubcontractChangeOrderCloseout;
      reader.readMessage(value,proto.SubcontractChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateSubcontractChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateSubcontractChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateSubcontractChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateSubcontractChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.SubcontractChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional SubcontractChangeOrderCloseout closeout = 1;
 * @return {?proto.SubcontractChangeOrderCloseout}
 */
proto.CreateSubcontractChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.SubcontractChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.SubcontractChangeOrderCloseout, 1));
};


/**
 * @param {?proto.SubcontractChangeOrderCloseout|undefined} value
 * @return {!proto.CreateSubcontractChangeOrderCloseoutRequest} returns this
*/
proto.CreateSubcontractChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateSubcontractChangeOrderCloseoutRequest} returns this
 */
proto.CreateSubcontractChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateSubcontractChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 5;
 * @return {string}
 */
proto.CreateSubcontractChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateSubcontractChangeOrderCloseoutRequest} returns this
 */
proto.CreateSubcontractChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateSubcontractChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateSubcontractChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateSubcontractChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateSubcontractChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateSubcontractChangeOrderCloseoutResponse}
 */
proto.CreateSubcontractChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateSubcontractChangeOrderCloseoutResponse;
  return proto.CreateSubcontractChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateSubcontractChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateSubcontractChangeOrderCloseoutResponse}
 */
proto.CreateSubcontractChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateSubcontractChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateSubcontractChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateSubcontractChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateSubcontractChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateSubcontractChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateSubcontractChangeOrderCloseoutResponse} returns this
 */
proto.CreateSubcontractChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetSubcontractChangeOrderCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetSubcontractChangeOrderCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetSubcontractChangeOrderCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetSubcontractChangeOrderCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetSubcontractChangeOrderCloseoutsRequest}
 */
proto.GetSubcontractChangeOrderCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetSubcontractChangeOrderCloseoutsRequest;
  return proto.GetSubcontractChangeOrderCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetSubcontractChangeOrderCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetSubcontractChangeOrderCloseoutsRequest}
 */
proto.GetSubcontractChangeOrderCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetSubcontractChangeOrderCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetSubcontractChangeOrderCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetSubcontractChangeOrderCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetSubcontractChangeOrderCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetSubcontractChangeOrderCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetSubcontractChangeOrderCloseoutsRequest} returns this
 */
proto.GetSubcontractChangeOrderCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetSubcontractChangeOrderCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetSubcontractChangeOrderCloseoutsRequest} returns this
 */
proto.GetSubcontractChangeOrderCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetSubcontractChangeOrderCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetSubcontractChangeOrderCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetSubcontractChangeOrderCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetSubcontractChangeOrderCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetSubcontractChangeOrderCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.SubcontractChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetSubcontractChangeOrderCloseoutsResponse}
 */
proto.GetSubcontractChangeOrderCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetSubcontractChangeOrderCloseoutsResponse;
  return proto.GetSubcontractChangeOrderCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetSubcontractChangeOrderCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetSubcontractChangeOrderCloseoutsResponse}
 */
proto.GetSubcontractChangeOrderCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SubcontractChangeOrderCloseout;
      reader.readMessage(value,proto.SubcontractChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetSubcontractChangeOrderCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetSubcontractChangeOrderCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetSubcontractChangeOrderCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetSubcontractChangeOrderCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SubcontractChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SubcontractChangeOrderCloseout closeouts = 1;
 * @return {!Array<!proto.SubcontractChangeOrderCloseout>}
 */
proto.GetSubcontractChangeOrderCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.SubcontractChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SubcontractChangeOrderCloseout, 1));
};


/**
 * @param {!Array<!proto.SubcontractChangeOrderCloseout>} value
 * @return {!proto.GetSubcontractChangeOrderCloseoutsResponse} returns this
*/
proto.GetSubcontractChangeOrderCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SubcontractChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SubcontractChangeOrderCloseout}
 */
proto.GetSubcontractChangeOrderCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SubcontractChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetSubcontractChangeOrderCloseoutsResponse} returns this
 */
proto.GetSubcontractChangeOrderCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateSubcontractChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateSubcontractChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateSubcontractChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateSubcontractChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.SubcontractChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateSubcontractChangeOrderCloseoutRequest}
 */
proto.UpdateSubcontractChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateSubcontractChangeOrderCloseoutRequest;
  return proto.UpdateSubcontractChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateSubcontractChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateSubcontractChangeOrderCloseoutRequest}
 */
proto.UpdateSubcontractChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SubcontractChangeOrderCloseout;
      reader.readMessage(value,proto.SubcontractChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateSubcontractChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateSubcontractChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateSubcontractChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateSubcontractChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.SubcontractChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional SubcontractChangeOrderCloseout closeout = 1;
 * @return {?proto.SubcontractChangeOrderCloseout}
 */
proto.UpdateSubcontractChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.SubcontractChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.SubcontractChangeOrderCloseout, 1));
};


/**
 * @param {?proto.SubcontractChangeOrderCloseout|undefined} value
 * @return {!proto.UpdateSubcontractChangeOrderCloseoutRequest} returns this
*/
proto.UpdateSubcontractChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateSubcontractChangeOrderCloseoutRequest} returns this
 */
proto.UpdateSubcontractChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateSubcontractChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateSubcontractChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateSubcontractChangeOrderCloseoutRequest} returns this
 */
proto.UpdateSubcontractChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateSubcontractChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateSubcontractChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateSubcontractChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateSubcontractChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateSubcontractChangeOrderCloseoutResponse}
 */
proto.UpdateSubcontractChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateSubcontractChangeOrderCloseoutResponse;
  return proto.UpdateSubcontractChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateSubcontractChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateSubcontractChangeOrderCloseoutResponse}
 */
proto.UpdateSubcontractChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateSubcontractChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateSubcontractChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateSubcontractChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateSubcontractChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateSubcontractChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateSubcontractChangeOrderCloseoutResponse} returns this
 */
proto.UpdateSubcontractChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteSubcontractChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteSubcontractChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteSubcontractChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteSubcontractChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteSubcontractChangeOrderCloseoutRequest}
 */
proto.DeleteSubcontractChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteSubcontractChangeOrderCloseoutRequest;
  return proto.DeleteSubcontractChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteSubcontractChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteSubcontractChangeOrderCloseoutRequest}
 */
proto.DeleteSubcontractChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteSubcontractChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteSubcontractChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteSubcontractChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteSubcontractChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteSubcontractChangeOrderCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteSubcontractChangeOrderCloseoutRequest} returns this
 */
proto.DeleteSubcontractChangeOrderCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteSubcontractChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteSubcontractChangeOrderCloseoutRequest} returns this
 */
proto.DeleteSubcontractChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteSubcontractChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteSubcontractChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteSubcontractChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteSubcontractChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteSubcontractChangeOrderCloseoutResponse}
 */
proto.DeleteSubcontractChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteSubcontractChangeOrderCloseoutResponse;
  return proto.DeleteSubcontractChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteSubcontractChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteSubcontractChangeOrderCloseoutResponse}
 */
proto.DeleteSubcontractChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteSubcontractChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteSubcontractChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteSubcontractChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteSubcontractChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteSubcontractChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteSubcontractChangeOrderCloseoutResponse} returns this
 */
proto.DeleteSubcontractChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateQuoteChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateQuoteChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateQuoteChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateQuoteChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.QuoteChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateQuoteChangeOrderCloseoutRequest}
 */
proto.CreateQuoteChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateQuoteChangeOrderCloseoutRequest;
  return proto.CreateQuoteChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateQuoteChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateQuoteChangeOrderCloseoutRequest}
 */
proto.CreateQuoteChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.QuoteChangeOrderCloseout;
      reader.readMessage(value,proto.QuoteChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateQuoteChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateQuoteChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateQuoteChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateQuoteChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.QuoteChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional QuoteChangeOrderCloseout closeout = 1;
 * @return {?proto.QuoteChangeOrderCloseout}
 */
proto.CreateQuoteChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.QuoteChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.QuoteChangeOrderCloseout, 1));
};


/**
 * @param {?proto.QuoteChangeOrderCloseout|undefined} value
 * @return {!proto.CreateQuoteChangeOrderCloseoutRequest} returns this
*/
proto.CreateQuoteChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateQuoteChangeOrderCloseoutRequest} returns this
 */
proto.CreateQuoteChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateQuoteChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.CreateQuoteChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateQuoteChangeOrderCloseoutRequest} returns this
 */
proto.CreateQuoteChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateQuoteChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateQuoteChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateQuoteChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateQuoteChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateQuoteChangeOrderCloseoutResponse}
 */
proto.CreateQuoteChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateQuoteChangeOrderCloseoutResponse;
  return proto.CreateQuoteChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateQuoteChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateQuoteChangeOrderCloseoutResponse}
 */
proto.CreateQuoteChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateQuoteChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateQuoteChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateQuoteChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateQuoteChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateQuoteChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateQuoteChangeOrderCloseoutResponse} returns this
 */
proto.CreateQuoteChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetQuoteChangeOrderCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetQuoteChangeOrderCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetQuoteChangeOrderCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetQuoteChangeOrderCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetQuoteChangeOrderCloseoutsRequest}
 */
proto.GetQuoteChangeOrderCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetQuoteChangeOrderCloseoutsRequest;
  return proto.GetQuoteChangeOrderCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetQuoteChangeOrderCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetQuoteChangeOrderCloseoutsRequest}
 */
proto.GetQuoteChangeOrderCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetQuoteChangeOrderCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetQuoteChangeOrderCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetQuoteChangeOrderCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetQuoteChangeOrderCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetQuoteChangeOrderCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetQuoteChangeOrderCloseoutsRequest} returns this
 */
proto.GetQuoteChangeOrderCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetQuoteChangeOrderCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetQuoteChangeOrderCloseoutsRequest} returns this
 */
proto.GetQuoteChangeOrderCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetQuoteChangeOrderCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetQuoteChangeOrderCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetQuoteChangeOrderCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetQuoteChangeOrderCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetQuoteChangeOrderCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeoutsList: jspb.Message.toObjectList(msg.getCloseoutsList(),
    proto.QuoteChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetQuoteChangeOrderCloseoutsResponse}
 */
proto.GetQuoteChangeOrderCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetQuoteChangeOrderCloseoutsResponse;
  return proto.GetQuoteChangeOrderCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetQuoteChangeOrderCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetQuoteChangeOrderCloseoutsResponse}
 */
proto.GetQuoteChangeOrderCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.QuoteChangeOrderCloseout;
      reader.readMessage(value,proto.QuoteChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetQuoteChangeOrderCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetQuoteChangeOrderCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetQuoteChangeOrderCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetQuoteChangeOrderCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.QuoteChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QuoteChangeOrderCloseout closeouts = 1;
 * @return {!Array<!proto.QuoteChangeOrderCloseout>}
 */
proto.GetQuoteChangeOrderCloseoutsResponse.prototype.getCloseoutsList = function() {
  return /** @type{!Array<!proto.QuoteChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.QuoteChangeOrderCloseout, 1));
};


/**
 * @param {!Array<!proto.QuoteChangeOrderCloseout>} value
 * @return {!proto.GetQuoteChangeOrderCloseoutsResponse} returns this
*/
proto.GetQuoteChangeOrderCloseoutsResponse.prototype.setCloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.QuoteChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.QuoteChangeOrderCloseout}
 */
proto.GetQuoteChangeOrderCloseoutsResponse.prototype.addCloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.QuoteChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetQuoteChangeOrderCloseoutsResponse} returns this
 */
proto.GetQuoteChangeOrderCloseoutsResponse.prototype.clearCloseoutsList = function() {
  return this.setCloseoutsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateQuoteChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateQuoteChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateQuoteChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateQuoteChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.QuoteChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateQuoteChangeOrderCloseoutRequest}
 */
proto.UpdateQuoteChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateQuoteChangeOrderCloseoutRequest;
  return proto.UpdateQuoteChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateQuoteChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateQuoteChangeOrderCloseoutRequest}
 */
proto.UpdateQuoteChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.QuoteChangeOrderCloseout;
      reader.readMessage(value,proto.QuoteChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateQuoteChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateQuoteChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateQuoteChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateQuoteChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.QuoteChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional QuoteChangeOrderCloseout closeout = 1;
 * @return {?proto.QuoteChangeOrderCloseout}
 */
proto.UpdateQuoteChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.QuoteChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.QuoteChangeOrderCloseout, 1));
};


/**
 * @param {?proto.QuoteChangeOrderCloseout|undefined} value
 * @return {!proto.UpdateQuoteChangeOrderCloseoutRequest} returns this
*/
proto.UpdateQuoteChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateQuoteChangeOrderCloseoutRequest} returns this
 */
proto.UpdateQuoteChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateQuoteChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateQuoteChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateQuoteChangeOrderCloseoutRequest} returns this
 */
proto.UpdateQuoteChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateQuoteChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateQuoteChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateQuoteChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateQuoteChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateQuoteChangeOrderCloseoutResponse}
 */
proto.UpdateQuoteChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateQuoteChangeOrderCloseoutResponse;
  return proto.UpdateQuoteChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateQuoteChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateQuoteChangeOrderCloseoutResponse}
 */
proto.UpdateQuoteChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateQuoteChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateQuoteChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateQuoteChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateQuoteChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateQuoteChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateQuoteChangeOrderCloseoutResponse} returns this
 */
proto.UpdateQuoteChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteQuoteChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteQuoteChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteQuoteChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteQuoteChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteQuoteChangeOrderCloseoutRequest}
 */
proto.DeleteQuoteChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteQuoteChangeOrderCloseoutRequest;
  return proto.DeleteQuoteChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteQuoteChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteQuoteChangeOrderCloseoutRequest}
 */
proto.DeleteQuoteChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteQuoteChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteQuoteChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteQuoteChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteQuoteChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteQuoteChangeOrderCloseoutRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteQuoteChangeOrderCloseoutRequest} returns this
 */
proto.DeleteQuoteChangeOrderCloseoutRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteQuoteChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteQuoteChangeOrderCloseoutRequest} returns this
 */
proto.DeleteQuoteChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteQuoteChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteQuoteChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteQuoteChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteQuoteChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteQuoteChangeOrderCloseoutResponse}
 */
proto.DeleteQuoteChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteQuoteChangeOrderCloseoutResponse;
  return proto.DeleteQuoteChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteQuoteChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteQuoteChangeOrderCloseoutResponse}
 */
proto.DeleteQuoteChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteQuoteChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteQuoteChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteQuoteChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteQuoteChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteQuoteChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteQuoteChangeOrderCloseoutResponse} returns this
 */
proto.DeleteQuoteChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetTotalFinalPriceChangeOrderCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetTotalFinalPriceChangeOrderCloseoutsRequest}
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetTotalFinalPriceChangeOrderCloseoutsRequest;
  return proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetTotalFinalPriceChangeOrderCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetTotalFinalPriceChangeOrderCloseoutsRequest}
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetTotalFinalPriceChangeOrderCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetTotalFinalPriceChangeOrderCloseoutsRequest} returns this
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetTotalFinalPriceChangeOrderCloseoutsRequest} returns this
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetTotalFinalPriceChangeOrderCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeouts: (f = msg.getCloseouts()) && proto.TotalFinalPriceChangeOrderCloseout.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetTotalFinalPriceChangeOrderCloseoutsResponse}
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetTotalFinalPriceChangeOrderCloseoutsResponse;
  return proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetTotalFinalPriceChangeOrderCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetTotalFinalPriceChangeOrderCloseoutsResponse}
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TotalFinalPriceChangeOrderCloseout;
      reader.readMessage(value,proto.TotalFinalPriceChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetTotalFinalPriceChangeOrderCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseouts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TotalFinalPriceChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional TotalFinalPriceChangeOrderCloseout closeouts = 1;
 * @return {?proto.TotalFinalPriceChangeOrderCloseout}
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.prototype.getCloseouts = function() {
  return /** @type{?proto.TotalFinalPriceChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.TotalFinalPriceChangeOrderCloseout, 1));
};


/**
 * @param {?proto.TotalFinalPriceChangeOrderCloseout|undefined} value
 * @return {!proto.GetTotalFinalPriceChangeOrderCloseoutsResponse} returns this
*/
proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.prototype.setCloseouts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetTotalFinalPriceChangeOrderCloseoutsResponse} returns this
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.prototype.clearCloseouts = function() {
  return this.setCloseouts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetTotalFinalPriceChangeOrderCloseoutsResponse.prototype.hasCloseouts = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeout: (f = msg.getCloseout()) && proto.TotalFinalPriceChangeOrderCloseout.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest}
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest;
  return proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest}
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TotalFinalPriceChangeOrderCloseout;
      reader.readMessage(value,proto.TotalFinalPriceChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setCloseout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TotalFinalPriceChangeOrderCloseout.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional TotalFinalPriceChangeOrderCloseout closeout = 1;
 * @return {?proto.TotalFinalPriceChangeOrderCloseout}
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.prototype.getCloseout = function() {
  return /** @type{?proto.TotalFinalPriceChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.TotalFinalPriceChangeOrderCloseout, 1));
};


/**
 * @param {?proto.TotalFinalPriceChangeOrderCloseout|undefined} value
 * @return {!proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest} returns this
*/
proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.prototype.setCloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest} returns this
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.prototype.clearCloseout = function() {
  return this.setCloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.prototype.hasCloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest} returns this
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse}
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse;
  return proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse}
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse} returns this
 */
proto.UpdateTotalFinalPriceChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto);
