import React, { useCallback, useEffect, useState } from "react";
import { TableRow, TableCell, TextField, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { LaborFactoringRowProps } from "./closeoutTypes";
import { formatCurrency, formatNumber } from "./closeoutHelpers";
import { LaborFactoringCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import {
  useDeleteLaborFactoringChangeOrderCloseoutMutation,
  useDeleteLaborFactoringCloseoutMutation,
  useGetLaborFactoringChangeOrderCloseouts,
  useGetLaborFactoringCloseouts,
  useUpdateLaborFactoringChangeOrderCloseoutMutation,
  useUpdateLaborFactoringCloseoutMutation,
} from "../../hooks/useCloseoutHooks";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";

export const LaborFactoringRow: React.FC<LaborFactoringRowProps> = ({
  closeout,
}) => {
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  // Local state for input displays.
  const [laborRateDisplay, setLaborRateDisplay] = useState<string>(
    formatCurrency(closeout.laborrate)
  );
  const [laborPercentDisplay, setLaborPercentDisplay] = useState<string>(
    formatNumber(closeout.laborpercent)
  );
  const [impactPercentDisplay, setImpactPercentDisplay] = useState<string>(
    formatNumber(closeout.impactpercent)
  );
  const [burdenPercentDisplay, setBurdenPercentDisplay] = useState<string>(
    formatNumber(closeout.burdenpercent)
  );
  const [fringeDisplay, setFringeDisplay] = useState<string>(
    formatCurrency(closeout.fringe)
  );

  const { mutateAsync: updateEstimateLabor } =
    useUpdateLaborFactoringCloseoutMutation();
  const { mutateAsync: deleteEstimateLabor } =
    useDeleteLaborFactoringCloseoutMutation();
  const { refetch: refetchEstimate } = useGetLaborFactoringCloseouts();

  const { mutateAsync: updateChangeOrderLabor } =
    useUpdateLaborFactoringChangeOrderCloseoutMutation();
  const { mutateAsync: deleteChangeOrderLabor } =
    useDeleteLaborFactoringChangeOrderCloseoutMutation();
  const { refetch: refetchChangeOrder } =
    useGetLaborFactoringChangeOrderCloseouts();

  const updateLabor = selectedEstimate?.estimateid
    ? updateEstimateLabor
    : updateChangeOrderLabor;

  const deleteLabor = selectedEstimate?.estimateid
    ? deleteEstimateLabor
    : deleteChangeOrderLabor;

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimate
    : refetchChangeOrder;

  // Sync local state when the labor prop changes.
  useEffect(() => {
    setLaborPercentDisplay(formatNumber(closeout.laborpercent));
    setImpactPercentDisplay(formatNumber(closeout.impactpercent));
    setLaborRateDisplay(formatCurrency(closeout.laborrate));
    setBurdenPercentDisplay(formatNumber(closeout.burdenpercent));
    setFringeDisplay(formatCurrency(closeout.fringe));
  }, [closeout]);

  type Field = keyof Pick<
    LaborFactoringCloseout.AsObject,
    "laborrate" | "burdenpercent" | "fringe" | "impactpercent" | "laborpercent"
  >;

  const handleFieldChange = useCallback(
    async (field: Field, value: string) => {
      // Allow only numbers with up to 2 decimals.
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!regex.test(value) || Number(value) > 99999999.99) return;
      await updateLabor(
        {
          ...closeout,
          [field]: value,
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    },
    [closeout, updateLabor, refetch]
  );

  const handleDelete = useCallback(() => {
    deleteLabor(closeout.closeoutid, {
      onSuccess: () => {
        refetch();
      },
    });
  }, [closeout, deleteLabor, refetch]);

  return (
    <TableRow>
      {/* Description */}
      <TableCell>{closeout.name}</TableCell>
      {/* Allocated Hours */}
      <TableCell>{formatNumber(closeout.allocatedhours)}</TableCell>
      {/* Labor % */}
      <TableCell>
        <TextField
          type="text"
          value={laborPercentDisplay}
          onChange={(e) => setLaborPercentDisplay(e.target.value)}
          onBlur={(e) => handleFieldChange("laborpercent", e.target.value)}
          variant="outlined"
          size="small"
          fullWidth
          inputProps={{
            min: 0,
            max: 99999999.99,
            step: "0.01",
            "aria-label": "Labor percent override",
          }}
        />
      </TableCell>
      {/* Impact % */}
      <TableCell>
        <TextField
          type="text"
          value={impactPercentDisplay}
          onChange={(e) => setImpactPercentDisplay(e.target.value)}
          onBlur={(e) => handleFieldChange("impactpercent", e.target.value)}
          variant="outlined"
          size="small"
          fullWidth
          inputProps={{
            min: 0,
            max: 99999999.99,
            step: "0.01",
            "aria-label": "Impact percent override",
          }}
        />
      </TableCell>
      {/* Labor Rate */}
      <TableCell>
        <TextField
          type="text"
          value={laborRateDisplay}
          onChange={(e) => setLaborRateDisplay(e.target.value)}
          onBlur={(e) => handleFieldChange("laborrate", e.target.value)}
          variant="outlined"
          size="small"
          fullWidth
          inputProps={{
            min: 0,
            max: 99999999.99,
            step: "0.01",
            "aria-label": "Labor rate override",
          }}
        />
      </TableCell>
      {/* Subtotal */}
      <TableCell>{formatCurrency(closeout.subtotalcost)}</TableCell>
      {/* Burden Percent */}
      <TableCell>
        <TextField
          type="text"
          value={burdenPercentDisplay}
          onChange={(e) => setBurdenPercentDisplay(e.target.value)}
          onBlur={(e) => handleFieldChange("burdenpercent", e.target.value)}
          variant="outlined"
          size="small"
          fullWidth
          inputProps={{
            min: 0,
            max: 99999999.99,
            step: "0.01",
            "aria-label": "Burden percent override",
          }}
        />
      </TableCell>
      {/* Fringe */}
      <TableCell>
        <TextField
          type="text"
          value={fringeDisplay}
          onChange={(e) => setFringeDisplay(e.target.value)}
          onBlur={(e) => handleFieldChange("fringe", e.target.value)}
          variant="outlined"
          size="small"
          fullWidth
          inputProps={{
            min: 0,
            max: 99999999.99,
            step: "0.01",
            "aria-label": "Fringe override",
          }}
        />
      </TableCell>
      {/* Burden Total */}
      <TableCell>{formatCurrency(closeout.burdentotalcost)}</TableCell>
      {/* Fringe Total */}
      <TableCell>{formatCurrency(closeout.fringetotalcost)}</TableCell>
      {/* Full Hourly Cost */}
      <TableCell>{formatCurrency(closeout.totalhourlycost)}</TableCell>
      {/* Full Cost */}
      <TableCell>{formatCurrency(closeout.totalcost)}</TableCell>
      <IconButton onClick={handleDelete} size="small">
        <DeleteIcon />
      </IconButton>
    </TableRow>
  );
};

export default LaborFactoringRow;
