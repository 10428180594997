import React, { useState, useEffect } from "react";
import {
  AttributeValue,
  AttributeVariable,
} from "../../../../api/protosCompiled/globalItem/globalItem_pb";
import { useMutation, useQueryClient } from "react-query";
import { ItemService } from "../../../../api/GlobalItemService";
import { useFetchAllowedVariables } from "../../../../hooks/useFetchAllowedVariables";

interface ManageAttributeDialogProps {
  isOpen: boolean;
  onClose: () => void;
  attributeValue: AttributeValue.AsObject | null;
}

export const ManageAttributeDialog: React.FC<ManageAttributeDialogProps> = ({
  isOpen,
  onClose,
  attributeValue,
}) => {
  const queryClient = useQueryClient();
  const { data: allowedVariables } = useFetchAllowedVariables();

  const [attributeName, setAttributeName] = useState<string>("");
  const [groupId, setGroupId] = useState<number>(0);
  const [variables, setVariables] = useState<AttributeVariable.AsObject[]>([]);

  useEffect(() => {
    if (attributeValue) {
      setAttributeName(attributeValue.attributevaluename);
      setGroupId(attributeValue.attributegroupid || 0);
      setVariables(attributeValue.attributevariablesList || []);
    } else {
      setAttributeName("");
      setGroupId(0);
      setVariables([]);
    }
  }, [attributeValue]);

  const { mutateAsync: createOrUpdateAttributeValueMutate } = useMutation(
    ItemService.createOrUpdateAttributeValue,
    {
      onSuccess: () => queryClient.invalidateQueries("attributeGroups"),
    }
  );

  const { mutateAsync: deleteAttributeVariableMutate } = useMutation(
    ItemService.deleteAttributeVariables,
    {
      onSuccess: () => queryClient.invalidateQueries("attributeGroups"),
    }
  );

  const handleSave = async () => {
    if (!attributeName) {
      alert("Attribute name is required.");
      return;
    }

    if (!groupId) {
      alert(
        "Attribute group ID is missing. Please ensure a valid group is selected."
      );
      return;
    }

    const updatedAttributeValue: AttributeValue.AsObject = {
      itemattributevaluemapid: attributeValue?.itemattributevaluemapid || 0,
      attributevalueid: attributeValue?.attributevalueid || 0,
      attributevaluename: attributeName,
      attributevariablesList: variables,
      attributegroupid: groupId,
      attributegroupname: attributeValue?.attributegroupname || "",
      upc: attributeValue?.upc || "",
    };

    try {
      await createOrUpdateAttributeValueMutate(updatedAttributeValue);
      onClose();
    } catch (error) {
      console.error("Error saving attribute value:", error);
    }
  };

  const addVariable = () => {
    if (!allowedVariables || allowedVariables.length === 0) {
      alert("No allowed variables available.");
      return;
    }

    const usedVariableIds = variables.map((v) => v.variableid);
    const availableVariables = allowedVariables.filter(
      (av) => !usedVariableIds.includes(av.id)
    );

    if (availableVariables.length === 0) {
      alert("No more variables available to add.");
      return;
    }

    const newVariable: AttributeVariable.AsObject = {
      itemattributevaluevariablemapid: 0,
      variableid: availableVariables[0].id,
      variable: availableVariables[0],
      variablevalue: 0,
    };

    setVariables((prevVariables) => [...prevVariables, newVariable]);
  };

  const updateVariable = (
    index: number,
    key: keyof AttributeVariable.AsObject,
    value: AttributeVariable.AsObject[keyof AttributeVariable.AsObject]
  ) => {
    setVariables((prevVariables) => {
      const updatedVariables = [...prevVariables];
      updatedVariables[index] = {
        ...updatedVariables[index],
        [key]: value,
      };
      return updatedVariables;
    });
  };

  const removeVariable = async (variable: AttributeVariable.AsObject) => {
    if (variable.itemattributevaluevariablemapid) {
      try {
        await deleteAttributeVariableMutate(
          variable.itemattributevaluevariablemapid
        );
      } catch (error) {
        console.error("Error deleting variable:", error);
        return;
      }
    }
    setVariables(variables.filter((v) => v !== variable));
  };

  /**
   * Returns only variables that are not yet used,
   * plus the one that's currently selected (so it doesn't disappear from the <select>).
   */
  const getAvailableVariables = (currentVariableId: number) => {
    const usedVariableIds = variables.map((v) => v.variableid);
    return allowedVariables?.filter(
      (av) => av.id === currentVariableId || !usedVariableIds.includes(av.id)
    );
  };

  if (!isOpen) return null;

  return (
    <div
      style={{
        width: "80vw",
        maxWidth: "750px",
        height: "90vh", // we limit the height here
        margin: "5vh auto",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header */}
      <div
        style={{
          padding: "20px",
          borderBottom: "1px solid #ccc",
          backgroundColor: "#ffffff",
          position: "sticky",
          top: 0,
          zIndex: 10,
        }}
      >
        <h2 style={{ margin: 0, textAlign: "center" }}>
          {attributeValue ? "Edit Attribute" : "Create Attribute"}
        </h2>
      </div>

      {/* Main Content (scrollable) */}
      <div
        style={{
          flex: "1 1 auto",
          overflowY: "auto", // enable vertical scrolling here
          padding: "20px",
        }}
      >
        {/* Attribute Name */}
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "8px",
              fontWeight: "bold",
            }}
          >
            Attribute Name:
          </label>
          <input
            type="text"
            value={attributeName}
            onChange={(e) => setAttributeName(e.target.value)}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              boxSizing: "border-box",
            }}
          />
        </div>

        {/* Variables Section */}
        <div style={{ marginBottom: "20px" }}>
          <h3 style={{ marginBottom: "10px" }}>Variables</h3>
          <button
            onClick={addVariable}
            style={{
              display: "block",
              padding: "10px 15px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              margin: "10px auto 0",
            }}
          >
            Add Variable
          </button>

          {/* Single Table Row with multiple "subcells" */}
          <div style={{ marginTop: "20px", overflowX: "auto" }}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                tableLayout: "fixed",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      padding: "8px",
                      borderBottom: "1px solid #eee",
                      verticalAlign: "top",
                    }}
                  >
                    {variables.map((v, index) => (
                      <div
                        key={index}
                        style={{
                          display: "inline-block",
                          marginRight: "24px",
                          marginBottom: "16px",
                          verticalAlign: "top",
                        }}
                      >
                        {/* Variable Selection */}
                        <div style={{ marginBottom: "8px" }}>
                          <label
                            style={{ display: "block", fontWeight: "bold" }}
                          >
                            Variable
                          </label>
                          <select
                            value={v.variableid}
                            onChange={(e) => {
                              const selectedVariable = allowedVariables?.find(
                                (av) => av.id === parseInt(e.target.value)
                              );
                              if (selectedVariable) {
                                updateVariable(
                                  index,
                                  "variable",
                                  selectedVariable
                                );
                                updateVariable(
                                  index,
                                  "variableid",
                                  selectedVariable.id
                                );
                              }
                            }}
                            style={{
                              width: "100%",
                              padding: "8px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                          >
                            {getAvailableVariables(v.variableid)?.map((av) => (
                              <option key={av.id} value={av.id}>
                                {av.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Variable Value */}
                        <div style={{ marginBottom: "8px" }}>
                          <label
                            style={{ display: "block", fontWeight: "bold" }}
                          >
                            Value
                          </label>
                          <input
                            type="number"
                            value={v.variablevalue}
                            onChange={(e) =>
                              updateVariable(
                                index,
                                "variablevalue",
                                parseFloat(e.target.value)
                              )
                            }
                            style={{
                              width: "100%",
                              padding: "8px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              boxSizing: "border-box",
                            }}
                          />
                        </div>

                        {/* Remove Button */}
                        <button
                          onClick={() => removeVariable(v)}
                          style={{
                            padding: "6px 10px",
                            backgroundColor: "#dc3545",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Footer (sticky at bottom) */}
      <div
        style={{
          padding: "10px 20px",
          borderTop: "1px solid #ccc",
          backgroundColor: "#ffffff",
          position: "sticky",
          bottom: 0,
          zIndex: 10,
          textAlign: "right",
        }}
      >
        <button
          onClick={onClose}
          style={{
            padding: "10px 15px",
            backgroundColor: "#6c757d",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            marginRight: "10px",
            cursor: "pointer",
          }}
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          style={{
            padding: "10px 15px",
            backgroundColor: "#28a745",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};
