import { clients } from "../clients/grpcClients";
import {
  CreateChangeOrderAssemblyRequest,
  CreateChangeOrderAssemblyResponse,
  ChangeOrderAssembly,
  UpdateChangeOrderAssemblyRequest,
  UpdateChangeOrderAssemblyResponse,
  ChangeOrderPoint,
  ChangeOrderItemQtyFormula,
  ChangeOrderAssemblyItemAttribute,
  GetChangeOrderAssembliesByChangeOrderIdResponse,
  GetChangeOrderAssembliesByChangeOrderIdRequest,
  ChangeOrderBreakoutMap,
  ChangeOrderQuoteGroup,
  ChangeOrderTakeoffAssemblies,
  ChangeOrderAssemblyItem,
  ChangeOrderItemCost,
  DeleteExtendChangeOrderRequest,
  DeleteExtendChangeOrderResponse,
  DeleteChangeOrderAssemblyResponse,
  DeleteChangeOrderAssemblyRequest,
} from "./protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { Point } from "../types/Point";
import { useUnityBuildStore } from "../states/store";

export class ContractChangeOrderAssemblyService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  /**
   * Convert local Point objects to repeated ChangeOrderPoint messages.
   */
  private static convertToChangeOrderPoints(
    pointsList: Point[]
  ): ChangeOrderPoint[] {
    return pointsList.map((p) => {
      const changeOrderPoint = new ChangeOrderPoint();
      changeOrderPoint.setPointid(p.pointid || "");
      changeOrderPoint.setX(p.x || 0);
      changeOrderPoint.setY(p.y || 0);
      changeOrderPoint.setAngle(p.angle || 0);
      changeOrderPoint.setVerticallength(p.verticallength || 0);
      changeOrderPoint.setAllowtrailingline(p.allowtrailingline || false);
      changeOrderPoint.setIsincludedincount(p.isincludedincount || false);
      changeOrderPoint.setIsselected(p.isselected || false);
      changeOrderPoint.setIsactive(p.isactive || false);
      return changeOrderPoint;
    });
  }

  /**
   * Build a top-level ChangeOrderTakeoffAssemblies message from your front-end object shape.
   * Then, for each assembly, build a ChangeOrderAssembly message.
   * For each item, build a ChangeOrderItem message, setting single fields as needed.
   */
  private static buildChangeOrderTakeoffAssemblies(
    takeoff: ChangeOrderTakeoffAssemblies.AsObject
  ): ChangeOrderTakeoffAssemblies {
    const takeoffMsg = new ChangeOrderTakeoffAssemblies();
    takeoffMsg.setParentid(takeoff.parentid || ""); //in this case this is a contractId
    takeoffMsg.setEstimateid(takeoff.estimateid || ""); //in this case this is a changeOrderId
    takeoffMsg.setLastupdated(takeoff.lastupdated || "");
    takeoffMsg.setCreatedbyuserid(takeoff.createdbyuserid || "");
    takeoffMsg.setCreatedbyaccountid(takeoff.createdbyaccountid || "");

    // Build repeated assemblies
    if (takeoff.assembliesList && takeoff.assembliesList.length) {
      const assemblyMsgs = takeoff.assembliesList.map((asmObj) => {
        const asmMsg = new ChangeOrderAssembly();
        asmMsg.setAssemblyid(asmObj.assemblyid || "");
        asmMsg.setAssemblyname(asmObj.assemblyname || "");
        asmMsg.setObjectid(asmObj.objectid || "");
        asmMsg.setTotalsegmentlength(asmObj.totalsegmentlength || 0);
        asmMsg.setTotalpointcount(asmObj.totalpointcount || 0);
        asmMsg.setTotalsegmentcount(asmObj.totalsegmentcount || 0);
        asmMsg.setTotalsqft(asmObj.totalsqft || 0);
        asmMsg.setSegmentlength(asmObj.segmentlength || 0);
        asmMsg.setSegmentcount(asmObj.segmentcount || 0);
        asmMsg.setMeasuredarea(asmObj.measuredarea || 0);
        asmMsg.setPointcount(asmObj.pointcount || 0);
        asmMsg.setPointtype(asmObj.pointtype || "");
        asmMsg.setPointsize(asmObj.pointsize || 0);
        asmMsg.setPointbordercolor(asmObj.pointbordercolor || "");
        asmMsg.setPointfillcolor(asmObj.pointfillcolor || "");
        asmMsg.setLinesize(asmObj.linesize || 0);
        asmMsg.setLinecolor(asmObj.linecolor || "");
        asmMsg.setLinedasharray(asmObj.linedasharray || "");
        asmMsg.setUserscale(asmObj.userscale || 0);
        asmMsg.setPagenumber(asmObj.pagenumber || 1);
        asmMsg.setAssemblyname(asmObj.assemblyname || "");
        asmMsg.setAssemblymeasurementtype(asmObj.assemblymeasurementtype || "");
        asmMsg.setImageclassificationid(asmObj.imageclassificationid || 0);
        asmMsg.setDatecreated(asmObj.datecreated || "");

        // Convert points
        const pointsList = this.convertToChangeOrderPoints(
          asmObj.pointsList || []
        );
        asmMsg.setPointsList(pointsList);

        // Build repeated "ChangeOrderItem" from asmObj.changeorderitemsList
        if (asmObj.itemsList && asmObj.itemsList.length) {
          const itemMsgs = asmObj.itemsList.map((itemObj) => {
            const itemMsg = new ChangeOrderAssemblyItem();
            itemMsg.setItemid(itemObj.itemid || "");
            itemMsg.setItemname(itemObj.itemname || "");
            itemMsg.setIsquoted(itemObj.isquoted || false);

            // Single "ChangeOrderItemCost costs"
            if (itemObj.costs) {
              const costMsg = new ChangeOrderItemCost();
              costMsg.setUomid(itemObj.costs.uomid || 0);
              costMsg.setItemunitcost(itemObj.costs.itemunitcost || 0);
              costMsg.setHourlyproductionrate(
                itemObj.costs.hourlyproductionrate || 0
              );
              costMsg.setUomname(itemObj.costs.uomname || "");
              costMsg.setUomvalue(itemObj.costs.uomvalue || 0);
              itemMsg.setCosts(costMsg);
            }

            // Single "ChangeOrderItemQtyFormula itemQtyFormula"
            if (itemObj.itemqtyformula) {
              const formulaMsg = new ChangeOrderItemQtyFormula();
              formulaMsg.setItemqty(itemObj.itemqtyformula.itemqty || 0);
              formulaMsg.setForevery(itemObj.itemqtyformula.forevery || 0);
              formulaMsg.setTakeoffvariabletype(
                itemObj.itemqtyformula.takeoffvariabletype || ""
              );
              formulaMsg.setAnd(itemObj.itemqtyformula.and || false);
              formulaMsg.setOr(itemObj.itemqtyformula.or || false);
              itemMsg.setItemqtyformula(formulaMsg);
            }

            // Single "ChangeOrderAssemblyItemAttribute attribute"
            if (itemObj.attribute) {
              const attrMsg = new ChangeOrderAssemblyItemAttribute();
              attrMsg.setAttributevalueid(
                itemObj.attribute.attributevalueid || 0
              );
              attrMsg.setAttributevaluename(
                itemObj.attribute.attributevaluename || ""
              );
              attrMsg.setAttributegroupid(
                itemObj.attribute.attributegroupid || 0
              );
              attrMsg.setAttributegroupname(
                itemObj.attribute.attributegroupname || ""
              );
              itemMsg.setAttribute(attrMsg);
            }

            // Single "ChangeOrderBreakoutMap breakout"
            if (itemObj.breakout) {
              const boMsg = new ChangeOrderBreakoutMap();
              boMsg.setBreakoutmapid(itemObj.breakout.breakoutmapid || "");
              boMsg.setBreakoutid(itemObj.breakout.breakoutid || "");
              boMsg.setBreakoutname(itemObj.breakout.breakoutname || "");
              boMsg.setMultiplier(itemObj.breakout.multiplier || 1);
              itemMsg.setBreakout(boMsg);
            }

            // Single "ChangeOrderQuoteGroup quoteGroup"
            if (itemObj.quotegroup) {
              const qgMsg = new ChangeOrderQuoteGroup();
              qgMsg.setId(itemObj.quotegroup.id || "");
              qgMsg.setQuotegroup(itemObj.quotegroup.quotegroup || "");
              itemMsg.setQuotegroup(qgMsg);
            }

            return itemMsg;
          });
          asmMsg.setItemsList(itemMsgs);
        }

        return asmMsg;
      });

      takeoffMsg.setAssembliesList(assemblyMsgs);
    }

    return takeoffMsg;
  }

  public static getChangeOrderAssembliesByChangeOrderId = (
    changeOrderId: string
  ): Promise<GetChangeOrderAssembliesByChangeOrderIdResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new GetChangeOrderAssembliesByChangeOrderIdRequest();
      req.setEstimateid(changeOrderId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.changeOrderAssemblyServiceClient.getChangeOrderAssembliesByChangeOrderId(
        req,
        {},
        (
          err: any,
          response: GetChangeOrderAssembliesByChangeOrderIdResponse
        ) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static createContractChangeOrderAssembly = (
    takeoff: ChangeOrderTakeoffAssemblies.AsObject
  ): Promise<CreateChangeOrderAssemblyResponse.AsObject> => {
    // Convert front-end object => message
    const takeoffMsg = this.buildChangeOrderTakeoffAssemblies(takeoff);

    const req = new CreateChangeOrderAssemblyRequest();
    req.setTakeoffassembly(takeoffMsg);
    req.setSessiontoken(this.getMetadata().sessionToken);

    return new Promise((resolve, reject) => {
      clients.changeOrderAssemblyServiceClient.createChangeOrderAssembly(
        req,
        {},
        (err, res) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(res.toObject());
        }
      );
    });
  };

  public static updateContractChangeOrderAssembly = (
    takeoff: ChangeOrderTakeoffAssemblies.AsObject
  ): Promise<UpdateChangeOrderAssemblyResponse.AsObject> => {
    const takeoffMsg = this.buildChangeOrderTakeoffAssemblies(takeoff);

    const req = new UpdateChangeOrderAssemblyRequest();
    req.setTakeoffassembly(takeoffMsg);
    req.setSessiontoken(this.getMetadata().sessionToken);

    return new Promise((resolve, reject) => {
      clients.changeOrderAssemblyServiceClient.updateChangeOrderAssembly(
        req,
        {},
        (err, res) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(res.toObject());
        }
      );
    });
  };

  public static deleteChangeOrderAssembly = (
    assemblyId: string
  ): Promise<DeleteChangeOrderAssemblyResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new DeleteChangeOrderAssemblyRequest();
      req.setAssemblyid(assemblyId);
      req.setSessiontoken(this.getMetadata().sessionToken);
      console.log("deleting assembly", req.toObject());
      clients.changeOrderAssemblyServiceClient.deleteChangeOrderAssembly(
        req,
        {},
        (err, res) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(res.toObject());
        }
      );
    });
  };

  public static deleteExtendChangeOrder = (
    assemblyId: string
  ): Promise<DeleteExtendChangeOrderResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new DeleteExtendChangeOrderRequest();
      req.setAssemblyid(assemblyId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.changeOrderAssemblyServiceClient.deleteExtendChangeOrder(
        req,
        {},
        (err, res) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(res.toObject());
        }
      );
    });
  };
}
