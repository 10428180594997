import React from "react";
import { useUnityBuildStore } from "../../states/store";
import { useHandleTakeoffKeyboardEvents } from "../../hooks/useHandleTakeoffKeyboardEvents";
import { SVGPoints } from "./SVGPoints";
import { useHandleTakeoffMouseEvents } from "../../hooks/useHandleTakeoffMouseEvents";
import { SVGMeasurement } from "./SVGMeasurement";
import { SVGCrosshair } from "./SVGCrosshair";
import { SVGPointLine } from "./SVGPointLine";
import { useStore } from "zustand";
import { SVGInferenceBoxes } from "./SVGInferenceBoxes";
import { useKeyBoardEventStore } from "../../states/keyEventStore";

export const TakeoffSVG: React.FC = () => {
  const svgRef = React.useRef<SVGSVGElement>(null);
  const handleKeyboardEvents = useHandleTakeoffKeyboardEvents(svgRef);

  const selectedObject = useStore(useUnityBuildStore).selectedObject;
  const pdfDimensions = useStore(useUnityBuildStore).pdfDimensions;

  const { enableCrosshair, isMeasuring } =
    useStore(useKeyBoardEventStore);

  const {
    handleTakeoffButton0MouseEvents,
    handleTakeoffButton2MouseEvents,
    handleMouseMove,
    cursorPositionRef,
    cursorPosition,
  } = useHandleTakeoffMouseEvents(svgRef);

  const handleMouseButton = (e: any) => {
    switch (e.button) {
      case 0:
        handleTakeoffButton0MouseEvents(e);
        break;
      case 2:
        handleTakeoffButton2MouseEvents(e);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <svg
        ref={svgRef}
        tabIndex={0}
        id="svgCanvas"
        onMouseDown={handleMouseButton}
        onMouseMove={handleMouseMove}
        onKeyDown={handleKeyboardEvents}
        onContextMenu={(event) => event.preventDefault()}
        style={{
          position: "absolute",
          width: `${pdfDimensions.width}px`,
          height: `${pdfDimensions.height}px`,
          pointerEvents: "auto",
          zIndex: 2,
          cursor: "crosshair",
        }}
      >
        {enableCrosshair && <SVGCrosshair cursorPosition={cursorPosition} />}
        <SVGPointLine cursorPositionRef={cursorPositionRef} />
        <SVGPoints cursorPositionRef={cursorPositionRef} />
        {/* Render inference bounding boxes */}
        {selectedObject && <SVGInferenceBoxes />}
        {isMeasuring && (
          <SVGMeasurement cursorPositionRef={cursorPositionRef} />
        )}
      </svg>
    </>
  );
};
