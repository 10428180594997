//InferenceService.ts
import { clients } from "../clients/grpcClients";
import {
  ProcessImageRequest,
  ProcessImageResponse,
  RunCountInferenceRequest,
  RunCountInferenceResponse,
} from "./protosCompiled/inference/inference_pb";
import { useUnityBuildStore } from "../states/store";

//gRPC API
export class InferenceService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static runCountInference = (
    objectid: string,
    pagenumbersList: number[],
    gcsbucketname: string,
    gcsobjectnamesList: string[]
  ): Promise<RunCountInferenceResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const request = new RunCountInferenceRequest();
      request.setObjectid(objectid);
      request.setPagenumbersList(pagenumbersList);
      request.setGcsbucketname(gcsbucketname);
      request.setGcsobjectnamesList(gcsobjectnamesList);
      request.setSessiontoken(this.getMetadata().sessionToken);

      clients.inferenceServiceClient.runCountInference(
        request,
        {},
        (err: any, response: RunCountInferenceResponse) => {
          if (err) {
            console.error("gRPC Error:", err);
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static processImage = (
    gcsObjectName: string
  ): Promise<ProcessImageResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const request = new ProcessImageRequest();
      request.setGcsobjectname(gcsObjectName);
      request.setSessiontoken(this.getMetadata().sessionToken);

      console.log(request.toObject());
      clients.inferenceServiceClient.processImage(
        request,
        {},
        (err: any, response: ProcessImageResponse) => {
          if (err) {
            console.error("gRPC Error:", err);
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };
}
