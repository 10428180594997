import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { QuoteRowProps } from "./closeoutTypes";
import {
  useUpdateQuoteCloseoutMutation,
  useDeleteQuoteCloseoutMutation,
  useGetQuoteCloseouts,
  useDeleteQuoteChangeOrderCloseoutMutation,
  useGetQuoteChangeOrderCloseouts,
  useUpdateQuoteChangeOrderCloseoutMutation,
} from "../../hooks/useCloseoutHooks";
import { QuoteCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { formatCurrency, formatNumber } from "./closeoutHelpers";
import { Button, TableCell, TableRow, TextField } from "@mui/material";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";

export const QuoteRow: React.FC<QuoteRowProps> = ({ closeout }) => {
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  const [quotedCost, setQuotedCost] = useState<string>(
    formatCurrency(closeout.quotedcost)
  );
  const [adjustedPercent, setAdjustedPercent] = useState<string>(
    formatNumber(closeout.adjustedpercent)
  );

  const { mutateAsync: updateEstimateCloseout } =
    useUpdateQuoteCloseoutMutation();
  const { mutateAsync: deleteEstimateCloseout } =
    useDeleteQuoteCloseoutMutation();
  const { refetch: refetchEstimateCloseout } = useGetQuoteCloseouts();

  const { mutateAsync: updateChangeOrderCloseout } =
    useUpdateQuoteChangeOrderCloseoutMutation();
  const { mutateAsync: deleteChangeOrderCloseout } =
    useDeleteQuoteChangeOrderCloseoutMutation();
  const { refetch: refetchChangeOrderCloseout } =
    useGetQuoteChangeOrderCloseouts();

  const updateCloseout = selectedEstimate?.estimateid
    ? updateEstimateCloseout
    : updateChangeOrderCloseout;

  const deleteCloseout = selectedEstimate?.estimateid
    ? deleteEstimateCloseout
    : deleteChangeOrderCloseout;

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimateCloseout
    : refetchChangeOrderCloseout;

  useEffect(() => {
    setQuotedCost(formatCurrency(closeout.quotedcost));
    setAdjustedPercent(formatNumber(closeout.adjustedpercent));
  }, [closeout]);

  type Field = keyof Pick<
    QuoteCloseout.AsObject,
    "quotedcost" | "adjustedpercent"
  >;

  const handleFieldChange = useCallback(
    async (field: Field, value: string) => {
      // Allow only numbers with up to 2 decimals.
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!regex.test(value) || Number(value) > 99999999.99) return;
      await updateCloseout(
        {
          ...closeout,
          [field]: value,
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    },
    [closeout, updateCloseout, refetch]
  );

  const handleDelete = useCallback(() => {
    deleteCloseout(closeout.closeoutid, {
      onSuccess: () => {
        refetch();
      },
    });
  }, [closeout, deleteCloseout, refetch]);

  return (
    <>
      <TableRow>
        <TableCell>{closeout.name}</TableCell>
        <TableCell>
          <TextField
            type="text"
            value={quotedCost}
            onBlur={(e) => handleFieldChange("quotedcost", e.target.value)}
            onChange={(e) => setQuotedCost(e.target.value)}
          />
        </TableCell>
        <TableCell>
          <TextField
            type="text"
            value={adjustedPercent}
            onBlur={(e) => handleFieldChange("adjustedpercent", e.target.value)}
            onChange={(e) => setAdjustedPercent(e.target.value)}
          />
        </TableCell>
        <TableCell>{formatCurrency(closeout.totalcost)}</TableCell>
        <Button onClick={handleDelete} style={{ width: "40px" }}>
          <DeleteIcon />
        </Button>
      </TableRow>
    </>
  );
};
