import React from "react";
import { useUnityBuildStore } from "../../states/store";
import { SVGCursorProps } from "../../types/SVGCursorProps";
import { Calculations } from "../../utils/calculations";
import { useStore } from "zustand";
import { useKeyBoardEventStore } from "../../states/keyEventStore";

export const pixelResolution = 72;

export const SVGMeasurement: React.FC<SVGCursorProps> = ({
  cursorPositionRef,
}) => {

  const measuredPoints = useStore(useUnityBuildStore).measuredPoints;
  const userScale = useStore(useUnityBuildStore).userScale;
  const isMeasuring = useStore(useKeyBoardEventStore).isMeasuring;

  const generateTickPoints = (startPoint: any, endPoint: any) => {
    if (!startPoint || !endPoint || isNaN(startPoint.x) || isNaN(endPoint.x)) {
      return [];
    }
    const dx = endPoint.x - startPoint.x;
    const dy = endPoint.y - startPoint.y;
    const angle = Math.atan2(dy, dx);
    const tickLength = 20;
    const tickOffset = -10;
    const perpendicularAngle = angle - Math.PI / 4;

    return [
      {
        x1: startPoint.x + tickOffset * Math.cos(perpendicularAngle),
        y1: startPoint.y + tickOffset * Math.sin(perpendicularAngle),
        x2:
          startPoint.x +
          (tickOffset + tickLength) * Math.cos(perpendicularAngle),
        y2:
          startPoint.y +
          (tickOffset + tickLength) * Math.sin(perpendicularAngle),
      },
      {
        x1: endPoint.x + tickOffset * Math.cos(perpendicularAngle),
        y1: endPoint.y + tickOffset * Math.sin(perpendicularAngle),
        x2:
          endPoint.x +
          (tickOffset + tickLength) * Math.cos(perpendicularAngle),
        y2:
          endPoint.y +
          (tickOffset + tickLength) * Math.sin(perpendicularAngle),
      },
    ];
  };

  return (
    <g>
      {measuredPoints.map((point, i) => {
        if (i < measuredPoints.length - 1) {
          const startPoint = point;
          const endPoint = measuredPoints[i + 1];
          const pixelDistance = Calculations.calculateDistance(
            startPoint,
            endPoint
          );
          const inchDistance = pixelDistance / pixelResolution;
          const realWorldDistanceInFeet = inchDistance * userScale;
          const feet = Math.floor(realWorldDistanceInFeet);
          const inches = Math.round((realWorldDistanceInFeet - feet) * 12);

          const ticks = generateTickPoints(startPoint, endPoint);

          return (
            <React.Fragment key={i}>
              <line
                x1={startPoint.x}
                y1={startPoint.y}
                x2={endPoint.x}
                y2={endPoint.y}
                stroke="black"
                strokeWidth={2}
              />
              <rect
                x={((startPoint.x + endPoint.x) / 2) - 20} // Center the rect properly
                y={(startPoint.y + endPoint.y) / 2 - 15}    // Position slightly above
                width="40"  // Adjust width to fit the text
                height="12" // Adjust height for better spacing
                fill="white"
                opacity={0.75}
                strokeWidth="1"
              />
              <text
                x={(startPoint.x + endPoint.x) / 2}
                y={(startPoint.y + endPoint.y) / 2 - 5}
                fill="black"
                fontSize="12"
                textAnchor="middle"
              >
                {`${feet}' ${inches}"`}
              </text>
              {ticks.map((tick, i) => (
                <line
                  key={i}
                  x1={tick.x1}
                  y1={tick.y1}
                  x2={tick.x2}
                  y2={tick.y2}
                  stroke="black"
                  strokeWidth={2}
                />
              ))}
            </React.Fragment>
          );
        }
        return null;
      })}

      {/* 🔥 Live measurement as user moves cursor */}
      {isMeasuring &&
        measuredPoints.length > 0 &&
        cursorPositionRef.current?.x !== undefined &&
        cursorPositionRef.current?.y !== undefined && (
          <>
            <line
              x1={measuredPoints[measuredPoints.length - 1].x}
              y1={measuredPoints[measuredPoints.length - 1].y}
              x2={cursorPositionRef.current.x}
              y2={cursorPositionRef.current.y}
              stroke="red"
              strokeWidth={2}
              className="temporary-measurement-line"
            />
            {/* 🔹 Live measurement text */}
            <rect
              x={(measuredPoints[measuredPoints.length - 1].x +
                cursorPositionRef.current.x) / 2 - 20}
              y={(measuredPoints[measuredPoints.length - 1].y +
                cursorPositionRef.current.y) / 2 - 15}
              width="40"  // Adjust width to fit the text
              height="12" // Adjust height for better spacing
              fill="white"
              opacity={0.75}
              strokeWidth="1"
            />
            <text
              x={(measuredPoints[measuredPoints.length - 1].x +
                cursorPositionRef.current.x) / 2}
              y={(measuredPoints[measuredPoints.length - 1].y +
                cursorPositionRef.current.y) / 2 - 5}
              fill="red"
              fontSize="12"
              textAnchor="middle"
            >
              {(() => {
                const pixelDistance = Calculations.calculateDistance(
                  measuredPoints[measuredPoints.length - 1],
                  cursorPositionRef.current
                );
                const inchDistance = pixelDistance / pixelResolution;
                const realWorldDistanceInFeet = inchDistance * userScale;
                const feet = Math.floor(realWorldDistanceInFeet);
                const inches = Math.round(
                  (realWorldDistanceInFeet - feet) * 12
                );
                return `${feet}' ${inches}"`;
              })()}
            </text>

            {/* 🔹 Ticks for live measurement */}
            {generateTickPoints(
              measuredPoints[measuredPoints.length - 1],
              cursorPositionRef.current
            ).map((tick, i) =>
              tick.x1 && tick.y1 && tick.x2 && tick.y2 ? (
                <line
                  key={i}
                  x1={tick.x1}
                  y1={tick.y1}
                  x2={tick.x2}
                  y2={tick.y2}
                  stroke="red"
                  strokeWidth={2}
                />
              ) : null
            )}
          </>
        )}
    </g>
  );
};
