import { useUnityBuildStore } from "../states/store";
import { useCreateProjectEstimateAssemblyMutation, useUpdateProjectEstimateAssemblyMutation } from "./useCreateProjectEstimateAssemblyMutation";
import { useCreateContractChangeOrderAssemblyMutation, useUpdateContractChangeOrderAssemblyMutation } from "./useCreateContractChangeOrderAssemblyMutation";
import { useQueryClient } from "react-query";
import {
  EstimateAssembly,
  EstimateBreakoutMap,
} from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import {
  ChangeOrderAssembly,
  ChangeOrderBreakoutMap,
} from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { Calculations } from "../utils/calculations";
import { useStore } from "zustand";

import { calculateSegmentCount } from "../api/ProjectEstimateAssemblyService";
import {
  calculatePolygonArea,
  convertPointToRealWorld,
} from "../components/SVGelements/takeoffHelper";
import { TakeoffAssemblies, TakeoffAssembly } from "../types/AssemblyItemType";

type submitAssemblyProps = {
  assembly: EstimateAssembly.AsObject | ChangeOrderAssembly.AsObject;
  shouldUpdateBreakout: boolean;
  pageScale: number;
  isCreating?: boolean;
  isUpdating?: boolean;
};

export const useSubmitTakeoffAssembly = () => {
  const queryClient = useQueryClient();
  const {
    selectedBreakout,
    selectedEstimate,
    selectedContract,
    selectedChangeOrder,
    selectedProject,
    currentPage,
  } = useStore(useUnityBuildStore);

  const { mutateAsync: createEstimateAssembly } =
    useCreateProjectEstimateAssemblyMutation();
  const { mutateAsync: updateEstimateAssembly } =
    useUpdateProjectEstimateAssemblyMutation();
  const { mutateAsync: createChangeOrderAssembly } =
    useCreateContractChangeOrderAssemblyMutation();
  const { mutateAsync: updateChangeOrderAssembly } =
    useUpdateContractChangeOrderAssemblyMutation();

  const submitAssembly = async (props: submitAssemblyProps) => {
    try {
      if (props.isCreating && props.isUpdating) {
        if (props.isCreating === props.isUpdating) {
          console.error(
            `isCreating is ${props.isCreating} and isUpdating is ${props.isUpdating}, these two values cannot be the same`
          );
          return;
        }
        if (!selectedProject && !selectedContract) {
          console.error(
            "Cannot create assembly without a project or contract selected"
          );
          return;
        }
      }
      let calculatedTotalLength = 0;
      if (
        props.assembly.assemblymeasurementtype === "length" ||
        props.assembly.assemblymeasurementtype === "area"
      ) {
        calculatedTotalLength = Calculations.calculateTotalDistance(
          props.assembly.pointsList,
          props.pageScale,
          props.assembly.assemblymeasurementtype
        );
      }

      let sqft = 0;

      if (props.assembly.assemblymeasurementtype === "area") {
        const realWorldPoints = props.assembly.pointsList.map((point) =>
          convertPointToRealWorld(point, props.pageScale)
        );
        sqft = calculatePolygonArea(realWorldPoints);
      }

      // Calculate pointCount
      const pointCount = props.assembly.pointsList?.filter(
        (point) => point.isincludedincount
      ).length;

      const segmentCount = calculateSegmentCount(props.assembly);

      // Prepare the optimistic update
      const optimisticAssembly: TakeoffAssemblies = {
        ...props.assembly,
        parentid: "",
        estimateid: "",
        createdbyuserid: "",
        createdbyaccountid: "",
        lastupdated: "",
        assembliesList: [
          {
            ...props.assembly,
            pointcount: pointCount,
            segmentlength: calculatedTotalLength,
            segmentcount: segmentCount,
            measuredarea: sqft,
            userscale: props.pageScale,
            pagenumber: currentPage,
            itemsList: (props.assembly.itemsList || []).map((item) => ({
              ...item,
              breakout: {
                breakoutmapid: item.breakout?.breakoutmapid ?? "",

                breakoutid: props.shouldUpdateBreakout
                  ? selectedBreakout?.id ?? ""
                  : item.breakout?.breakoutid ?? "",

                breakoutname: props.shouldUpdateBreakout
                  ? selectedBreakout?.breakoutname ?? ""
                  : item.breakout?.breakoutname ?? "",

                multiplier: props.shouldUpdateBreakout
                  ? selectedBreakout?.multiplier ?? 1
                  : item.breakout?.multiplier ?? 1,
              } as
                | EstimateBreakoutMap.AsObject
                | ChangeOrderBreakoutMap.AsObject,
            })),
          },
        ],
      };

      // Begin optimistic update
      await queryClient.cancelQueries(["countedAssemblies"]);
      const previousAssemblies = queryClient.getQueryData([
        "countedAssemblies",
      ]);

      queryClient.setQueryData(
        ["countedAssemblies"],
        (oldAssemblies: any[] | undefined) => {
          // Ensure oldAssemblies is not undefined
          const old = oldAssemblies || [];

          if (props.isCreating) {
            // For creation, add the optimisticAssembly to the array
            return [...old, optimisticAssembly];
          } else if (props.isUpdating) {
            // For updates, find the assembly by its ID and update it
            return old.map((assembly: TakeoffAssembly) =>
              assembly.assemblyid ===
                optimisticAssembly.assembliesList[0].assemblyid
                ? optimisticAssembly
                : assembly
            );
          }
          return old; // Return the old state by default
        }
      );

      try {
        if (selectedEstimate && selectedProject) {
          if (props.isCreating) {
            await createEstimateAssembly({
              ...optimisticAssembly,
              parentid: selectedProject.id,
              estimateid: selectedEstimate.estimateid,
            });
          } else if (props.isUpdating) {
            await updateEstimateAssembly({
              ...optimisticAssembly,
              parentid: selectedProject.id,
              estimateid: selectedEstimate.estimateid,
            });
          }
        } else if (selectedChangeOrder && selectedContract) {
          if (props.isCreating) {
            await createChangeOrderAssembly({
              ...optimisticAssembly,
              parentid: selectedContract.contractid,
              estimateid: selectedChangeOrder.changeorderid,
            });
          } else if (props.isUpdating) {
            await updateChangeOrderAssembly({
              ...optimisticAssembly,
              parentid: selectedContract.contractid,
              estimateid: selectedChangeOrder.changeorderid,
            });
          }
        }
      } catch (error) {
        queryClient.setQueryData(["countedAssemblies"], previousAssemblies);
        throw error;
      } finally {
        queryClient.invalidateQueries(["countedAssemblies"]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return submitAssembly;
};
