import {
  ExtendEstimate,
  CreateDirectLaborCloseoutRequest,
  CreateDirectLaborCloseoutResponse,
  DirectLaborCloseout,
  GetDirectLaborCloseoutsRequest,
  GetDirectLaborCloseoutsResponse,
  UpdateDirectLaborCloseoutRequest,
  UpdateDirectLaborCloseoutResponse,
  DeleteDirectLaborCloseoutRequest,
  DeleteDirectLaborCloseoutResponse,
  LaborFactoringCloseout,
  IndirectLaborCloseout,
  GetIndirectLaborCloseoutsRequest,
  DeleteIndirectLaborCloseoutRequest,
  EquipmentCloseout,
  GetEquipmentCloseoutsRequest,
  DeleteEquipmentCloseoutRequest,
  GeneralExpenseCloseout,
  GetGeneralExpenseCloseoutsRequest,
  DeleteGeneralExpenseCloseoutRequest,
  SubcontractCloseout,
  GetSubcontractCloseoutsRequest,
  DeleteSubcontractCloseoutRequest,
  QuoteCloseout,
  GetQuoteCloseoutsRequest,
  DeleteQuoteCloseoutRequest,
  CreateLaborFactoringCloseoutResponse,
  CreateLaborFactoringCloseoutRequest,
  GetLaborFactoringCloseoutsRequest,
  GetLaborFactoringCloseoutsResponse,
  UpdateLaborFactoringCloseoutResponse,
  DeleteLaborFactoringCloseoutResponse,
  CreateIndirectLaborCloseoutResponse,
  GetIndirectLaborCloseoutsResponse,
  DeleteIndirectLaborCloseoutResponse,
  CreateEquipmentCloseoutResponse,
  GetEquipmentCloseoutsResponse,
  UpdateEquipmentCloseoutResponse,
  DeleteEquipmentCloseoutResponse,
  CreateGeneralExpenseCloseoutResponse,
  GetGeneralExpenseCloseoutsResponse,
  UpdateGeneralExpenseCloseoutResponse,
  DeleteGeneralExpenseCloseoutResponse,
  GetSubcontractCloseoutsResponse,
  UpdateSubcontractCloseoutResponse,
  DeleteSubcontractCloseoutResponse,
  CreateQuoteCloseoutResponse,
  DeleteQuoteCloseoutResponse,
  UpdateQuoteCloseoutResponse,
  UpdateLaborFactoringCloseoutRequest,
  DeleteLaborFactoringCloseoutRequest,
  CreateIndirectLaborCloseoutRequest,
  UpdateIndirectLaborCloseoutRequest,
  UpdateIndirectLaborCloseoutResponse,
  CreateEquipmentCloseoutRequest,
  UpdateEquipmentCloseoutRequest,
  CreateGeneralExpenseCloseoutRequest,
  UpdateGeneralExpenseCloseoutRequest,
  CreateSubcontractCloseoutResponse,
  CreateSubcontractCloseoutRequest,
  UpdateSubcontractCloseoutRequest,
  CreateQuoteCloseoutRequest,
  GetQuoteCloseoutsResponse,
  UpdateQuoteCloseoutRequest,
  CreateIncidentalLaborCloseoutRequest,
  CreateIncidentalLaborCloseoutResponse,
  DeleteIncidentalLaborCloseoutRequest,
  DeleteIncidentalLaborCloseoutResponse,
  GetIncidentalLaborCloseoutsRequest,
  GetIncidentalLaborCloseoutsResponse,
  IncidentalLaborCloseout,
  UpdateIncidentalLaborCloseoutRequest,
  UpdateIncidentalLaborCloseoutResponse,
  GetTotalFinalPriceCloseoutsRequest,
  GetTotalFinalPriceCloseoutsResponse,
  TotalFinalPriceCloseout,
  UpdateTotalFinalPriceCloseoutRequest,
  UpdateTotalFinalPriceCloseoutResponse,
  ExtendEstimateToFinalPrice,
  DirectLaborToFinalPrice,
  EquipmentToFinalPrice,
  GeneralExpenseToFinalPrice,
  IncidentalLaborToFinalPrice,
  IndirectLaborToFinalPrice,
  LaborFactoringToFinalPrice,
  QuoteToFinalPrice,
  SubcontractToFinalPrice,
  UpdateExtendEstimateRequest,
  UpdateExtendEstimateResponse,
  GetExtendEstimateResponse,
  GetExtendEstimateRequest,
  UpdateEstimateLaborFactoringAllocationRequest,
  UpdateEstimateLaborFactoringAllocationResponse,
  BreakoutAllocation,
  UpdateEstimateIncidentalLaborAllocationRequest,
  UpdateEstimateIncidentalLaborAllocationResponse,
  UpdateEstimateIndirectLaborAllocationRequest,
  UpdateEstimateIndirectLaborAllocationResponse,
  UpdateEstimateEquipmentAllocationRequest,
  UpdateEstimateEquipmentAllocationResponse,
  UpdateEstimateGeneralExpenseAllocationRequest,
  UpdateEstimateGeneralExpenseAllocationResponse,
  UpdateEstimateQuoteAllocationRequest,
  UpdateEstimateQuoteAllocationResponse,
  UpdateEstimateSubcontractAllocationRequest,
  UpdateEstimateSubcontractAllocationResponse,
} from "./protosCompiled/estimateCloseout/estimateCloseout_pb";
import { clients } from "../clients/grpcClients";

import { useUnityBuildStore } from "../states/store";
import {
  GetExtendChangeOrderResponse,
  GetExtendChangeOrderRequest,
  UpdateExtendChangeOrderRequest,
  UpdateExtendChangeOrderResponse,
  ExtendChangeOrder,
  GetDirectLaborChangeOrderCloseoutsResponse,
  GetDirectLaborChangeOrderCloseoutsRequest,
  DirectLaborChangeOrderCloseout,
  UpdateDirectLaborChangeOrderCloseoutResponse,
  UpdateDirectLaborChangeOrderCloseoutRequest,
  DeleteDirectLaborChangeOrderCloseoutResponse,
  DeleteDirectLaborChangeOrderCloseoutRequest,
  GetLaborFactoringChangeOrderCloseoutsResponse,
  GetLaborFactoringChangeOrderCloseoutsRequest,
  DeleteLaborFactoringChangeOrderCloseoutRequest,
  DeleteLaborFactoringChangeOrderCloseoutResponse,
  LaborFactoringChangeOrderCloseout,
  UpdateLaborFactoringChangeOrderCloseoutRequest,
  UpdateLaborFactoringChangeOrderCloseoutResponse,
  UpdateEquipmentChangeOrderCloseoutRequest,
  EquipmentChangeOrderCloseout,
  GetEquipmentChangeOrderCloseoutsRequest,
  GetEquipmentChangeOrderCloseoutsResponse,
  UpdateEquipmentChangeOrderCloseoutResponse,
  DeleteEquipmentChangeOrderCloseoutRequest,
  DeleteEquipmentChangeOrderCloseoutResponse,
  GeneralExpenseChangeOrderCloseout,
  GetGeneralExpenseChangeOrderCloseoutsRequest,
  GetGeneralExpenseChangeOrderCloseoutsResponse,
  UpdateGeneralExpenseChangeOrderCloseoutRequest,
  UpdateGeneralExpenseChangeOrderCloseoutResponse,
  DeleteGeneralExpenseChangeOrderCloseoutRequest,
  DeleteGeneralExpenseChangeOrderCloseoutResponse,
  DeleteQuoteChangeOrderCloseoutRequest,
  DeleteQuoteChangeOrderCloseoutResponse,
  DeleteSubcontractChangeOrderCloseoutRequest,
  DeleteSubcontractChangeOrderCloseoutResponse,
  DirectLaborChangeOrderToFinalPrice,
  EquipmentChangeOrderToFinalPrice,
  ExtendChangeOrderToFinalPrice,
  GeneralExpenseChangeOrderToFinalPrice,
  GetQuoteChangeOrderCloseoutsRequest,
  GetQuoteChangeOrderCloseoutsResponse,
  GetSubcontractChangeOrderCloseoutsRequest,
  GetSubcontractChangeOrderCloseoutsResponse,
  GetTotalFinalPriceChangeOrderCloseoutsRequest,
  GetTotalFinalPriceChangeOrderCloseoutsResponse,
  IncidentalLaborChangeOrderToFinalPrice,
  IndirectLaborChangeOrderToFinalPrice,
  LaborFactoringChangeOrderToFinalPrice,
  QuoteChangeOrderCloseout,
  QuoteChangeOrderToFinalPrice,
  SubcontractChangeOrderCloseout,
  SubcontractChangeOrderToFinalPrice,
  TotalFinalPriceChangeOrderCloseout,
  UpdateQuoteChangeOrderCloseoutRequest,
  UpdateQuoteChangeOrderCloseoutResponse,
  UpdateSubcontractChangeOrderCloseoutRequest,
  UpdateSubcontractChangeOrderCloseoutResponse,
  UpdateTotalFinalPriceChangeOrderCloseoutRequest,
  UpdateTotalFinalPriceChangeOrderCloseoutResponse,
  GetIndirectLaborChangeOrderCloseoutsResponse,
  GetIndirectLaborChangeOrderCloseoutsRequest,
  IndirectLaborChangeOrderCloseout,
  UpdateIndirectLaborChangeOrderCloseoutRequest,
  UpdateIndirectLaborChangeOrderCloseoutResponse,
  DeleteIndirectLaborChangeOrderCloseoutRequest,
  DeleteIndirectLaborChangeOrderCloseoutResponse,
  CreateIncidentalLaborChangeOrderCloseoutRequest,
  CreateIncidentalLaborChangeOrderCloseoutResponse,
  GetIncidentalLaborChangeOrderCloseoutsRequest,
  GetIncidentalLaborChangeOrderCloseoutsResponse,
  IncidentalLaborChangeOrderCloseout,
  DeleteIncidentalLaborChangeOrderCloseoutRequest,
  DeleteIncidentalLaborChangeOrderCloseoutResponse,
  UpdateIncidentalLaborChangeOrderCloseoutRequest,
  UpdateIncidentalLaborChangeOrderCloseoutResponse,
  CreateIndirectLaborChangeOrderCloseoutRequest,
  CreateIndirectLaborChangeOrderCloseoutResponse,
  CreateDirectLaborChangeOrderCloseoutResponse,
  CreateDirectLaborChangeOrderCloseoutRequest,
  CreateLaborFactoringChangeOrderCloseoutResponse,
  CreateLaborFactoringChangeOrderCloseoutRequest,
  CreateEquipmentChangeOrderCloseoutResponse,
  CreateEquipmentChangeOrderCloseoutRequest,
  CreateGeneralExpenseChangeOrderCloseoutResponse,
  CreateGeneralExpenseChangeOrderCloseoutRequest,
  CreateSubcontractChangeOrderCloseoutResponse,
  CreateSubcontractChangeOrderCloseoutRequest,
  CreateQuoteChangeOrderCloseoutResponse,
  CreateQuoteChangeOrderCloseoutRequest,
  ChangeOrderBreakoutAllocation,
  UpdateChangeOrderLaborFactoringAllocationRequest,
  UpdateChangeOrderLaborFactoringAllocationResponse,
  UpdateChangeOrderEquipmentAllocationRequest,
  UpdateChangeOrderEquipmentAllocationResponse,
  UpdateChangeOrderGeneralExpenseAllocationRequest,
  UpdateChangeOrderGeneralExpenseAllocationResponse,
  UpdateChangeOrderIncidentalLaborAllocationRequest,
  UpdateChangeOrderIncidentalLaborAllocationResponse,
  UpdateChangeOrderIndirectLaborAllocationRequest,
  UpdateChangeOrderIndirectLaborAllocationResponse,
  UpdateChangeOrderQuoteAllocationRequest,
  UpdateChangeOrderQuoteAllocationResponse,
  UpdateChangeOrderSubcontractAllocationRequest,
  UpdateChangeOrderSubcontractAllocationResponse,
} from "./protosCompiled/changeOrderCloseout/changeOrderCloseout_pb";

const estClient = clients.estimateCloseoutServiceClient;
const coClient = clients.changeOrderCloseoutServiceClient;

export class CloseoutService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static async getExtendEstimates(
    estimateId: string
  ): Promise<GetExtendEstimateResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetExtendEstimateRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.getExtendEstimate(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateExtendEstimates(
    data: UpdateExtendEstimateRequest.AsObject
  ): Promise<UpdateExtendEstimateResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new UpdateExtendEstimateRequest();
      req.setSessiontoken(this.getMetadata().sessionToken);
      const extendEstimatesProto: ExtendEstimate[] =
        data.extendestimatesList?.map((item) => {
          const ext = new ExtendEstimate();

          ext.setExtensionid(item.extensionid);
          ext.setIsoverrideitemunitcost(item?.isoverrideitemunitcost || false);
          ext.setOverrideitemunitcost(item?.overrideitemunitcost || 0);
          ext.setIsoverridehourlyproductionrate(
            item?.isoverridehourlyproductionrate || false
          );
          ext.setOverridehourlyproductionrate(
            item?.overridehourlyproductionrate || 0
          );

          ext.setAssemblyid(item.assemblyid);
          ext.setAssemblyname(item.assemblyname);
          ext.setAttributegroupid(item.attributegroupid);
          ext.setAttributegroupname(item.attributegroupname);
          ext.setAttributevalueid(item.attributevalueid);
          ext.setAttributevaluename(item.attributevaluename);
          ext.setBreakoutid(item.breakoutid);
          ext.setBreakoutname(item.breakoutname);
          ext.setEstimateid(item.estimateid);
          ext.setItemid(item.itemid);
          ext.setItemname(item.itemname);
          ext.setItemunitcost(item.itemunitcost);
          ext.setItemuomname(item.itemuomname);
          ext.setItemuomvalue(item.itemuomvalue);
          ext.setUomid(item.uomid);

          return ext;
        });

      req.setExtendestimatesList(extendEstimatesProto);

      console.log("updateExtendEstimates", req.toObject());
      estClient.updateExtendEstimate(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async createDirectLaborCloseout(
    data: DirectLaborCloseout
  ): Promise<CreateDirectLaborCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateDirectLaborCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);
      estClient.createDirectLaborCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getDirectLaborCloseouts(
    estimateid: string
  ): Promise<GetDirectLaborCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetDirectLaborCloseoutsRequest();
      req.setEstimateid(estimateid);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.getDirectLaborCloseouts(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateDirectLaborCloseout(
    data: DirectLaborCloseout.AsObject
  ): Promise<UpdateDirectLaborCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new DirectLaborCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultdirectlaborid(
        data.closeoutdefaultdirectlaborid
      );
      closeout.setLaborrate(data.laborrate || 0);
      closeout.setBurdenpercent(data.burdenpercent || 0);
      closeout.setFringe(data.fringe || 0);
      closeout.setDistributionpercent(data.distributionpercent || 0);

      const req = new UpdateDirectLaborCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      estClient.updateDirectLaborCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async deleteDirectLaborCloseout(
    directLaborCloseoutId: string
  ): Promise<DeleteDirectLaborCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteDirectLaborCloseoutRequest();
      req.setId(directLaborCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.deleteDirectLaborCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async createLaborFactoringCloseout(
    data: LaborFactoringCloseout
  ): Promise<CreateLaborFactoringCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateLaborFactoringCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.createLaborFactoringCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getLaborFactoringCloseouts(
    estimateid: string
  ): Promise<GetLaborFactoringCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetLaborFactoringCloseoutsRequest();
      req.setEstimateid(estimateid);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.getLaborFactoringCloseouts(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateLaborFactoringCloseout(
    data: LaborFactoringCloseout.AsObject
  ): Promise<UpdateLaborFactoringCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new LaborFactoringCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultlaborfactoringid(
        data.closeoutdefaultlaborfactoringid
      );
      closeout.setLaborrate(data.laborrate || 0);
      closeout.setBurdenpercent(data.burdenpercent || 0);
      closeout.setFringe(data.fringe || 0);
      closeout.setImpactpercent(data.impactpercent || 0);
      closeout.setLaborpercent(data.laborpercent || 0);

      const req = new UpdateLaborFactoringCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.updateLaborFactoringCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async deleteLaborFactoringCloseout(
    laborFactoringCloseoutId: string
  ): Promise<DeleteLaborFactoringCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteLaborFactoringCloseoutRequest();
      req.setId(laborFactoringCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.deleteLaborFactoringCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async createIncidentalLaborCloseout(
    data: IncidentalLaborCloseout
  ): Promise<CreateIncidentalLaborCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateIncidentalLaborCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.createIncidentalLaborCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getIncidentalLaborCloseouts(
    estimateid: string
  ): Promise<GetIncidentalLaborCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetIncidentalLaborCloseoutsRequest();
      req.setEstimateid(estimateid);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.getIncidentalLaborCloseouts(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateIncidentalLaborCloseout(
    data: IncidentalLaborCloseout.AsObject
  ): Promise<UpdateIncidentalLaborCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new IncidentalLaborCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultincidentallaborid(
        data.closeoutdefaultincidentallaborid
      );
      closeout.setLaborrate(data.laborrate || 0);
      closeout.setBurdenpercent(data.burdenpercent || 0);
      closeout.setFringe(data.fringe || 0);
      closeout.setBurdenpercent(data.burdenpercent || 0);
      closeout.setAllocatedhours(data.allocatedhours || 0);

      const req = new UpdateIncidentalLaborCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.updateIncidentalLaborCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async deleteIncidentalLaborCloseout(
    incidentalLaborCloseoutId: string
  ): Promise<DeleteIncidentalLaborCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteIncidentalLaborCloseoutRequest();
      req.setId(incidentalLaborCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.deleteIncidentalLaborCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async createIndirectLaborCloseout(
    data: IndirectLaborCloseout
  ): Promise<CreateIndirectLaborCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateIndirectLaborCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.createIndirectLaborCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getIndirectLaborCloseouts(
    estimateid: string
  ): Promise<GetIndirectLaborCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetIndirectLaborCloseoutsRequest();
      req.setEstimateid(estimateid);
      req.setSessiontoken(this.getMetadata().sessionToken);

      const metadata = CloseoutService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      estClient.getIndirectLaborCloseouts(
        req,
        {},
        (err, response: GetIndirectLaborCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateIndirectLaborCloseout(
    data: IndirectLaborCloseout.AsObject
  ): Promise<UpdateIndirectLaborCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new IndirectLaborCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultindirectlaborid(
        data.closeoutdefaultindirectlaborid
      );
      closeout.setLaborrate(data.laborrate || 0);
      closeout.setBurdenpercent(data.burdenpercent || 0);
      closeout.setFringe(data.fringe || 0);
      closeout.setBurdenpercent(data.burdenpercent || 0);
      closeout.setPercentageofdirectlabor(data.percentageofdirectlabor || 0);

      const req = new UpdateIndirectLaborCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      estClient.updateIndirectLaborCloseout(
        req,
        {},
        (err, response: UpdateIndirectLaborCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async deleteIndirectLaborCloseout(
    indirectLaborCloseoutId: string
  ): Promise<DeleteIndirectLaborCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteIndirectLaborCloseoutRequest();
      req.setId(indirectLaborCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.deleteIndirectLaborCloseout(
        req,
        {},
        (err, response: DeleteIndirectLaborCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async createEquipmentCloseout(
    data: EquipmentCloseout
  ): Promise<CreateEquipmentCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateEquipmentCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.createEquipmentCloseout(
        req,
        {},
        (err, response: CreateEquipmentCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async getEquipmentCloseouts(
    estimateid: string
  ): Promise<GetEquipmentCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetEquipmentCloseoutsRequest();
      req.setEstimateid(estimateid);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.getEquipmentCloseouts(
        req,
        {},
        (err, response: GetEquipmentCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateEquipmentCloseout(
    data: EquipmentCloseout.AsObject
  ): Promise<UpdateEquipmentCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new EquipmentCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultequipmentid(data.closeoutdefaultequipmentid);
      closeout.setQuantity(data.quantity || 0);
      closeout.setDuration(data.duration || 0);
      closeout.setUnitcost(data.unitcost || 0);

      const req = new UpdateEquipmentCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      estClient.updateEquipmentCloseout(
        req,
        {},
        (err, response: UpdateEquipmentCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async deleteEquipmentCloseout(
    equipmentCloseoutId: string
  ): Promise<DeleteEquipmentCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteEquipmentCloseoutRequest();
      req.setId(equipmentCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);
      estClient.deleteEquipmentCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async createGeneralExpenseCloseout(
    data: GeneralExpenseCloseout
  ): Promise<CreateGeneralExpenseCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateGeneralExpenseCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.createGeneralExpenseCloseout(
        req,
        {},
        (err, response: CreateGeneralExpenseCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async getGeneralExpenseCloseouts(
    estimateid: string
  ): Promise<GetGeneralExpenseCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetGeneralExpenseCloseoutsRequest();
      req.setEstimateid(estimateid);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.getGeneralExpenseCloseouts(
        req,
        {},
        (err, response: GetGeneralExpenseCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateGeneralExpenseCloseout(
    data: GeneralExpenseCloseout.AsObject
  ): Promise<UpdateGeneralExpenseCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new GeneralExpenseCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultgeneralexpenseid(
        data.closeoutdefaultgeneralexpenseid
      );
      closeout.setQuantity(data.quantity || 0);
      closeout.setQuantitymultiplier(data.quantitymultiplier || 0);
      closeout.setUnitcost(data.unitcost || 0);

      const req = new UpdateGeneralExpenseCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.updateGeneralExpenseCloseout(
        req,
        {},
        (err, response: UpdateGeneralExpenseCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async deleteGeneralExpenseCloseout(
    generalExpenseCloseoutId: string
  ): Promise<DeleteGeneralExpenseCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteGeneralExpenseCloseoutRequest();
      req.setId(generalExpenseCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);
      estClient.deleteGeneralExpenseCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async createSubcontractCloseout(
    data: SubcontractCloseout
  ): Promise<CreateSubcontractCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateSubcontractCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.createSubcontractCloseout(
        req,
        {},
        (err, response: CreateSubcontractCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async getSubcontractCloseouts(
    estimateid: string
  ): Promise<GetSubcontractCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetSubcontractCloseoutsRequest();
      req.setEstimateid(estimateid);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.getSubcontractCloseouts(
        req,
        {},
        (err, response: GetSubcontractCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateSubcontractCloseout(
    data: SubcontractCloseout.AsObject
  ): Promise<UpdateSubcontractCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new SubcontractCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultsubcontractid(
        data.closeoutdefaultsubcontractid
      );
      closeout.setQuotedcost(data.quotedcost || 0);
      closeout.setAdjustedpercent(data.adjustedpercent || 0);

      const req = new UpdateSubcontractCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.updateSubcontractCloseout(
        req,
        {},
        (err, response: UpdateSubcontractCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async deleteSubcontractCloseout(
    subcontractcloseoutid: string
  ): Promise<DeleteSubcontractCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteSubcontractCloseoutRequest();
      req.setId(subcontractcloseoutid);
      req.setSessiontoken(this.getMetadata().sessionToken);
      estClient.deleteSubcontractCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async createQuoteCloseout(
    data: QuoteCloseout
  ): Promise<CreateQuoteCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateQuoteCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.createQuoteCloseout(
        req,
        {},
        (err, response: CreateQuoteCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async getQuoteCloseouts(
    estimateid: string
  ): Promise<GetQuoteCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetQuoteCloseoutsRequest();
      req.setEstimateid(estimateid);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.getQuoteCloseouts(
        req,
        {},
        (err, response: GetQuoteCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateQuoteCloseout(
    data: QuoteCloseout.AsObject
  ): Promise<UpdateQuoteCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new QuoteCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultquoteid(data.closeoutdefaultquoteid);

      closeout.setQuotedcost(data.quotedcost || 0);
      closeout.setAdjustedpercent(data.adjustedpercent || 0);

      const req = new UpdateQuoteCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      estClient.updateQuoteCloseout(
        req,
        {},
        (err, response: UpdateQuoteCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async deleteQuoteCloseout(
    quoteCloseoutId: string
  ): Promise<DeleteQuoteCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteQuoteCloseoutRequest();
      req.setId(quoteCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);
      estClient.deleteQuoteCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getTotalFinalPriceCloseouts(
    estimateId: string
  ): Promise<GetTotalFinalPriceCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetTotalFinalPriceCloseoutsRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.getTotalFinalPriceCloseouts(
        req,
        {},
        (err, response: GetTotalFinalPriceCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateTotalFinalPriceCloseout(
    data: TotalFinalPriceCloseout.AsObject
  ): Promise<UpdateTotalFinalPriceCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new TotalFinalPriceCloseout();

      const extendestimate = new ExtendEstimateToFinalPrice();
      extendestimate.setAdjustment(data.extendestimate?.adjustment || 0);
      extendestimate.setOverhead(data.extendestimate?.overhead || 0);
      extendestimate.setMargin(data.extendestimate?.margin || 0);

      const directlaborcloseout = new DirectLaborToFinalPrice();
      directlaborcloseout.setAdjustment(
        data.directlaborcloseout?.adjustment || 0
      );
      directlaborcloseout.setOverhead(data.directlaborcloseout?.overhead || 0);
      directlaborcloseout.setMargin(data.directlaborcloseout?.margin || 0);

      const laborfactoringcloseout = new LaborFactoringToFinalPrice();
      laborfactoringcloseout.setAdjustment(
        data.laborfactoringcloseout?.adjustment || 0
      );
      laborfactoringcloseout.setOverhead(
        data.laborfactoringcloseout?.overhead || 0
      );
      laborfactoringcloseout.setMargin(
        data.laborfactoringcloseout?.margin || 0
      );

      const incidentallaborcloseout = new IncidentalLaborToFinalPrice();
      incidentallaborcloseout.setAdjustment(
        data.incidentallaborcloseout?.adjustment || 0
      );
      incidentallaborcloseout.setOverhead(
        data.incidentallaborcloseout?.overhead || 0
      );
      incidentallaborcloseout.setMargin(
        data.incidentallaborcloseout?.margin || 0
      );

      const indirectlaborcloseout = new IndirectLaborToFinalPrice();
      indirectlaborcloseout.setAdjustment(
        data.indirectlaborcloseout?.adjustment || 0
      );
      indirectlaborcloseout.setOverhead(
        data.indirectlaborcloseout?.overhead || 0
      );
      indirectlaborcloseout.setMargin(data.indirectlaborcloseout?.margin || 0);

      const equipmentcloseout = new EquipmentToFinalPrice();
      equipmentcloseout.setAdjustment(data.equipmentcloseout?.adjustment || 0);
      equipmentcloseout.setOverhead(data.equipmentcloseout?.overhead || 0);
      equipmentcloseout.setMargin(data.equipmentcloseout?.margin || 0);

      const generalexpensecloseout = new GeneralExpenseToFinalPrice();
      generalexpensecloseout.setAdjustment(
        data.generalexpensecloseout?.adjustment || 0
      );
      generalexpensecloseout.setOverhead(
        data.generalexpensecloseout?.overhead || 0
      );
      generalexpensecloseout.setMargin(
        data.generalexpensecloseout?.margin || 0
      );

      const subcontractcloseout = new SubcontractToFinalPrice();
      subcontractcloseout.setAdjustment(
        data.subcontractcloseout?.adjustment || 0
      );
      subcontractcloseout.setOverhead(data.subcontractcloseout?.overhead || 0);
      subcontractcloseout.setMargin(data.subcontractcloseout?.margin || 0);

      const quotecloseout = new QuoteToFinalPrice();
      quotecloseout.setAdjustment(data.quotecloseout?.adjustment || 0);
      quotecloseout.setOverhead(data.quotecloseout?.overhead || 0);
      quotecloseout.setMargin(data.quotecloseout?.margin || 0);

      closeout.setExtendestimate(extendestimate);
      closeout.setDirectlaborcloseout(directlaborcloseout);
      closeout.setLaborfactoringcloseout(laborfactoringcloseout);
      closeout.setIncidentallaborcloseout(incidentallaborcloseout);
      closeout.setIndirectlaborcloseout(indirectlaborcloseout);
      closeout.setEquipmentcloseout(equipmentcloseout);
      closeout.setGeneralexpensecloseout(generalexpensecloseout);
      closeout.setSubcontractcloseout(subcontractcloseout);
      closeout.setQuotecloseout(quotecloseout);
      closeout.setTotalfinalprice(data.totalfinalprice);
      closeout.setTotaloverhead(data.totaloverhead);
      closeout.setTotalmargin(data.totalmargin);
      closeout.setSellingprice(data.sellingprice);
      closeout.setTotalfinalpricecloseoutid(data.totalfinalpricecloseoutid);

      const req = new UpdateTotalFinalPriceCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      estClient.updateTotalFinalPriceCloseout(
        req,
        {},
        (err, response: UpdateTotalFinalPriceCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  //--------------------Change Order Closeout hooks --------------------//

  public static async getExtendChangeOrder(
    estimateId: string
  ): Promise<GetExtendChangeOrderResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetExtendChangeOrderRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.getExtendChangeOrder(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateExtendChangeOrder(
    data: UpdateExtendChangeOrderRequest.AsObject
  ): Promise<UpdateExtendChangeOrderResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new UpdateExtendChangeOrderRequest();
      req.setSessiontoken(this.getMetadata().sessionToken);
      const extendEstimatesProto: ExtendChangeOrder[] =
        data.extendestimatesList.map((item) => {
          const ext = new ExtendChangeOrder();
          ext.setExtensionid(item.extensionid);
          ext.setIsoverrideitemunitcost(item?.isoverrideitemunitcost || false);
          ext.setOverrideitemunitcost(item?.overrideitemunitcost || 0);
          ext.setIsoverridehourlyproductionrate(
            item?.isoverridehourlyproductionrate || false
          );
          ext.setOverridehourlyproductionrate(
            item?.overridehourlyproductionrate || 0
          );

          return ext;
        });

      req.setExtendestimatesList(extendEstimatesProto);
      coClient.updateExtendChangeOrder(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getDirectLaborChangeOrderCloseouts(
    estimateId: string
  ): Promise<GetDirectLaborChangeOrderCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetDirectLaborChangeOrderCloseoutsRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.getDirectLaborChangeOrderCloseouts(
        req,
        {},
        (err, response: GetDirectLaborChangeOrderCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async createDirectLaborChangeOrderCloseout(
    data: DirectLaborChangeOrderCloseout
  ): Promise<CreateDirectLaborChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateDirectLaborChangeOrderCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.createDirectLaborChangeOrderCloseout(
        req,
        {},
        (err, response: CreateDirectLaborChangeOrderCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateDirectLaborChangeOrderCloseout(
    data: DirectLaborChangeOrderCloseout.AsObject
  ): Promise<UpdateDirectLaborChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new DirectLaborChangeOrderCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultdirectlaborid(
        data.closeoutdefaultdirectlaborid
      );
      closeout.setLaborrate(data.laborrate || 0);
      closeout.setBurdenpercent(data.burdenpercent || 0);
      closeout.setFringe(data.fringe || 0);
      closeout.setDistributionpercent(data.distributionpercent || 0);

      const req = new UpdateDirectLaborChangeOrderCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.updateDirectLaborChangeOrderCloseout(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async deleteDirectLaborChangeOrderCloseout(
    directLaborCloseoutId: string
  ): Promise<DeleteDirectLaborChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteDirectLaborChangeOrderCloseoutRequest();
      req.setId(directLaborCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.deleteDirectLaborChangeOrderCloseout(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async getLaborFactoringChangeOrderCloseouts(
    estimateId: string
  ): Promise<GetLaborFactoringChangeOrderCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetLaborFactoringChangeOrderCloseoutsRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.getLaborFactoringChangeOrderCloseouts(
        req,
        {},
        (err, response: GetLaborFactoringChangeOrderCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async createLaborFactoringChangeOrderCloseout(
    data: LaborFactoringChangeOrderCloseout
  ): Promise<CreateLaborFactoringChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateLaborFactoringChangeOrderCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.createLaborFactoringChangeOrderCloseout(
        req,
        {},
        (err, response: CreateLaborFactoringChangeOrderCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateLaborFactoringChangeOrderCloseout(
    data: LaborFactoringChangeOrderCloseout.AsObject
  ): Promise<UpdateLaborFactoringChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new LaborFactoringChangeOrderCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultlaborfactoringid(
        data.closeoutdefaultlaborfactoringid
      );
      closeout.setLaborrate(data.laborrate || 0);
      closeout.setBurdenpercent(data.burdenpercent || 0);
      closeout.setFringe(data.fringe || 0);
      closeout.setImpactpercent(data.impactpercent || 0);
      closeout.setLaborpercent(data.laborpercent || 0);

      const req = new UpdateLaborFactoringChangeOrderCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.updateLaborFactoringChangeOrderCloseout(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async deleteLaborFactoringChangeOrderCloseout(
    laborFactoringCloseoutId: string
  ): Promise<DeleteLaborFactoringChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteLaborFactoringChangeOrderCloseoutRequest();
      req.setId(laborFactoringCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.deleteLaborFactoringChangeOrderCloseout(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async getIndirectLaborChangeOrderCloseouts(
    estimateId: string
  ): Promise<GetIndirectLaborChangeOrderCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetIndirectLaborChangeOrderCloseoutsRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.getIndirectLaborChangeOrderCloseouts(
        req,
        {},
        (err, response: GetIndirectLaborChangeOrderCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async createIndirectLaborChangeOrderCloseout(
    data: IndirectLaborChangeOrderCloseout
  ): Promise<CreateIndirectLaborChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateIndirectLaborChangeOrderCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.createIndirectLaborChangeOrderCloseout(
        req,
        {},
        (err, response: CreateIndirectLaborChangeOrderCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateIndirectLaborChangeOrderCloseout(
    data: IndirectLaborChangeOrderCloseout.AsObject
  ): Promise<UpdateIndirectLaborChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new IndirectLaborChangeOrderCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultindirectlaborid(
        data.closeoutdefaultindirectlaborid
      );
      closeout.setPercentageofdirectlabor(data.percentageofdirectlabor || 0);
      closeout.setLaborrate(data.laborrate || 0);
      closeout.setBurdenpercent(data.burdenpercent || 0);
      closeout.setFringe(data.fringe || 0);
      closeout.setCreatedbyuserid(data.createdbyuserid || "");
      closeout.setCreatedbyaccountid(data.createdbyaccountid || "");
      closeout.setDatecreated(data.datecreated || "");
      closeout.setLastupdated(data.lastupdated || "");
      closeout.setLastupdatedbyuserid(data.lastupdatedbyuserid || "");
      closeout.setAllocatedhours(data.allocatedhours || 0);
      closeout.setSubtotalcost(data.subtotalcost || 0);
      closeout.setBurdentotalcost(data.burdentotalcost || 0);
      closeout.setFringetotalcost(data.fringetotalcost || 0);
      closeout.setTotalhourlycost(data.totalhourlycost || 0);
      closeout.setTotalcost(data.totalcost || 0);
      closeout.setTotaldirectlaborhours(data.totaldirectlaborhours || 0);

      const req = new UpdateIndirectLaborChangeOrderCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.updateIndirectLaborChangeOrderCloseout(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async deleteIndirectLaborChangeOrderCloseout(
    indirectLaborCloseoutId: string
  ): Promise<DeleteIndirectLaborChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteIndirectLaborChangeOrderCloseoutRequest();
      req.setId(indirectLaborCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.deleteIndirectLaborChangeOrderCloseout(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async getIncidentalLaborChangeOrderCloseouts(
    estimateId: string
  ): Promise<GetIncidentalLaborChangeOrderCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetIncidentalLaborChangeOrderCloseoutsRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.getIncidentalLaborChangeOrderCloseouts(
        req,
        {},
        (err, response: GetIncidentalLaborChangeOrderCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async createIncidentalLaborChangeOrderCloseout(
    data: IncidentalLaborChangeOrderCloseout
  ): Promise<CreateIncidentalLaborChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateIncidentalLaborChangeOrderCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.createIncidentalLaborChangeOrderCloseout(
        req,
        {},
        (err, response: CreateIncidentalLaborChangeOrderCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateIncidentalLaborChangeOrderCloseout(
    data: IncidentalLaborChangeOrderCloseout.AsObject
  ): Promise<UpdateIncidentalLaborChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new IncidentalLaborChangeOrderCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultincidentallaborid(
        data.closeoutdefaultincidentallaborid
      );
      closeout.setLaborrate(data.laborrate || 0);
      closeout.setBurdenpercent(data.burdenpercent || 0);
      closeout.setFringe(data.fringe || 0);
      closeout.setBurdenpercent(data.burdenpercent || 0);
      closeout.setAllocatedhours(data.allocatedhours || 0);

      const req = new UpdateIncidentalLaborChangeOrderCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.updateIncidentalLaborChangeOrderCloseout(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async deleteIncidentalLaborChangeOrderCloseout(
    incidentalLaborCloseoutId: string
  ): Promise<DeleteIncidentalLaborChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteIncidentalLaborChangeOrderCloseoutRequest();
      req.setId(incidentalLaborCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.deleteIncidentalLaborChangeOrderCloseout(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async getEquipmentChangeOrderCloseouts(
    estimateId: string
  ): Promise<GetEquipmentChangeOrderCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetEquipmentChangeOrderCloseoutsRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.getEquipmentChangeOrderCloseouts(
        req,
        {},
        (err, response: GetEquipmentChangeOrderCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async createEquipmentChangeOrderCloseout(
    data: EquipmentChangeOrderCloseout
  ): Promise<CreateEquipmentChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateEquipmentChangeOrderCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.createEquipmentChangeOrderCloseout(
        req,
        {},
        (err, response: CreateEquipmentChangeOrderCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateEquipmentChangeOrderCloseout(
    data: EquipmentChangeOrderCloseout.AsObject
  ): Promise<UpdateEquipmentChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new EquipmentChangeOrderCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultequipmentid(data.closeoutdefaultequipmentid);
      closeout.setQuantity(data.quantity || 0);
      closeout.setDuration(data.duration || 0);
      closeout.setUnitcost(data.unitcost || 0);

      const req = new UpdateEquipmentChangeOrderCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.updateEquipmentChangeOrderCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async deleteEquipmentChangeOrderCloseout(
    equipmentCloseoutId: string
  ): Promise<DeleteEquipmentChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteEquipmentChangeOrderCloseoutRequest();
      req.setId(equipmentCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.deleteEquipmentChangeOrderCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getGeneralExpenseChangeOrderCloseouts(
    estimateId: string
  ): Promise<GetGeneralExpenseChangeOrderCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetGeneralExpenseChangeOrderCloseoutsRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.getGeneralExpenseChangeOrderCloseouts(
        req,
        {},
        (err, response: GetGeneralExpenseChangeOrderCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async createGeneralExpenseChangeOrderCloseout(
    data: GeneralExpenseChangeOrderCloseout
  ): Promise<CreateGeneralExpenseChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateGeneralExpenseChangeOrderCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.createGeneralExpenseChangeOrderCloseout(
        req,
        {},
        (err, response: CreateGeneralExpenseChangeOrderCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateGeneralExpenseChangeOrderCloseout(
    data: GeneralExpenseChangeOrderCloseout.AsObject
  ): Promise<UpdateGeneralExpenseChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new GeneralExpenseChangeOrderCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultgeneralexpenseid(
        data.closeoutdefaultgeneralexpenseid
      );
      closeout.setQuantity(data.quantity || 0);
      closeout.setQuantitymultiplier(data.quantitymultiplier || 0);
      closeout.setUnitcost(data.unitcost || 0);

      const req = new UpdateGeneralExpenseChangeOrderCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.updateGeneralExpenseChangeOrderCloseout(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async deleteGeneralExpenseChangeOrderCloseout(
    generalExpenseCloseoutId: string
  ): Promise<DeleteGeneralExpenseChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteGeneralExpenseChangeOrderCloseoutRequest();
      req.setId(generalExpenseCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.deleteGeneralExpenseChangeOrderCloseout(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async getSubcontractChangeOrderCloseouts(
    estimateId: string
  ): Promise<GetSubcontractChangeOrderCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetSubcontractChangeOrderCloseoutsRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.getSubcontractChangeOrderCloseouts(
        req,
        {},
        (err, response: GetSubcontractChangeOrderCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async createSubcontractChangeOrderCloseout(
    data: SubcontractChangeOrderCloseout
  ): Promise<CreateSubcontractChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateSubcontractChangeOrderCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.createSubcontractChangeOrderCloseout(
        req,
        {},
        (err, response: CreateSubcontractChangeOrderCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateSubcontractChangeOrderCloseout(
    data: SubcontractChangeOrderCloseout.AsObject
  ): Promise<UpdateSubcontractChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new SubcontractChangeOrderCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultsubcontractid(
        data.closeoutdefaultsubcontractid
      );
      closeout.setQuotedcost(data.quotedcost || 0);
      closeout.setAdjustedpercent(data.adjustedpercent || 0);

      const req = new UpdateSubcontractChangeOrderCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.updateSubcontractChangeOrderCloseout(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async deleteSubcontractChangeOrderCloseout(
    subcontractcloseoutId: string
  ): Promise<DeleteSubcontractChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteSubcontractChangeOrderCloseoutRequest();
      req.setId(subcontractcloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.deleteSubcontractChangeOrderCloseout(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async getQuoteChangeOrderCloseouts(
    estimateId: string
  ): Promise<GetQuoteChangeOrderCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetQuoteChangeOrderCloseoutsRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.getQuoteChangeOrderCloseouts(
        req,
        {},
        (err, response: GetQuoteChangeOrderCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async createQuoteChangeOrderCloseout(
    data: QuoteChangeOrderCloseout
  ): Promise<CreateQuoteChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateQuoteChangeOrderCloseoutRequest();
      req.setCloseout(data);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.createQuoteChangeOrderCloseout(
        req,
        {},
        (err, response: CreateQuoteChangeOrderCloseoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateQuoteChangeOrderCloseout(
    data: QuoteChangeOrderCloseout.AsObject
  ): Promise<UpdateQuoteChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new QuoteChangeOrderCloseout();
      closeout.setCloseoutid(data.closeoutid);
      closeout.setEstimateid(data.estimateid);
      closeout.setCloseoutdefaultquoteid(data.closeoutdefaultquoteid);

      closeout.setQuotedcost(data.quotedcost || 0);
      closeout.setAdjustedpercent(data.adjustedpercent || 0);

      const req = new UpdateQuoteChangeOrderCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.updateQuoteChangeOrderCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async deleteQuoteChangeOrderCloseout(
    quoteCloseoutId: string
  ): Promise<DeleteQuoteChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteQuoteChangeOrderCloseoutRequest();
      req.setId(quoteCloseoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.deleteQuoteChangeOrderCloseout(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getTotalFinalPriceChangeOrderCloseouts(
    estimateId: string
  ): Promise<GetTotalFinalPriceChangeOrderCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetTotalFinalPriceChangeOrderCloseoutsRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.getTotalFinalPriceChangeOrderCloseouts(
        req,
        {},
        (err, response: GetTotalFinalPriceChangeOrderCloseoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateTotalFinalPriceChangeOrderCloseout(
    data: TotalFinalPriceChangeOrderCloseout.AsObject
  ): Promise<UpdateTotalFinalPriceChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const closeout = new TotalFinalPriceChangeOrderCloseout();

      const extendestimate = new ExtendChangeOrderToFinalPrice();
      extendestimate.setAdjustment(data.extendestimate?.adjustment || 0);
      extendestimate.setOverhead(data.extendestimate?.overhead || 0);
      extendestimate.setMargin(data.extendestimate?.margin || 0);

      const directlaborcloseout = new DirectLaborChangeOrderToFinalPrice();
      directlaborcloseout.setAdjustment(
        data.directlaborcloseout?.adjustment || 0
      );
      directlaborcloseout.setOverhead(data.directlaborcloseout?.overhead || 0);
      directlaborcloseout.setMargin(data.directlaborcloseout?.margin || 0);

      const laborfactoringcloseout =
        new LaborFactoringChangeOrderToFinalPrice();
      laborfactoringcloseout.setAdjustment(
        data.laborfactoringcloseout?.adjustment || 0
      );
      laborfactoringcloseout.setOverhead(
        data.laborfactoringcloseout?.overhead || 0
      );
      laborfactoringcloseout.setMargin(
        data.laborfactoringcloseout?.margin || 0
      );

      const incidentallaborcloseout =
        new IncidentalLaborChangeOrderToFinalPrice();
      incidentallaborcloseout.setAdjustment(
        data.incidentallaborcloseout?.adjustment || 0
      );
      incidentallaborcloseout.setOverhead(
        data.incidentallaborcloseout?.overhead || 0
      );
      incidentallaborcloseout.setMargin(
        data.incidentallaborcloseout?.margin || 0
      );

      const indirectlaborcloseout = new IndirectLaborChangeOrderToFinalPrice();
      indirectlaborcloseout.setAdjustment(
        data.indirectlaborcloseout?.adjustment || 0
      );
      indirectlaborcloseout.setOverhead(
        data.indirectlaborcloseout?.overhead || 0
      );
      indirectlaborcloseout.setMargin(data.indirectlaborcloseout?.margin || 0);

      const equipmentcloseout = new EquipmentChangeOrderToFinalPrice();
      equipmentcloseout.setAdjustment(data.equipmentcloseout?.adjustment || 0);
      equipmentcloseout.setOverhead(data.equipmentcloseout?.overhead || 0);
      equipmentcloseout.setMargin(data.equipmentcloseout?.margin || 0);

      const generalexpensecloseout =
        new GeneralExpenseChangeOrderToFinalPrice();
      generalexpensecloseout.setAdjustment(
        data.generalexpensecloseout?.adjustment || 0
      );
      generalexpensecloseout.setOverhead(
        data.generalexpensecloseout?.overhead || 0
      );
      generalexpensecloseout.setMargin(
        data.generalexpensecloseout?.margin || 0
      );

      const subcontractcloseout = new SubcontractChangeOrderToFinalPrice();
      subcontractcloseout.setAdjustment(
        data.subcontractcloseout?.adjustment || 0
      );
      subcontractcloseout.setOverhead(data.subcontractcloseout?.overhead || 0);
      subcontractcloseout.setMargin(data.subcontractcloseout?.margin || 0);

      const quotecloseout = new QuoteChangeOrderToFinalPrice();
      quotecloseout.setAdjustment(data.quotecloseout?.adjustment || 0);
      quotecloseout.setOverhead(data.quotecloseout?.overhead || 0);
      quotecloseout.setMargin(data.quotecloseout?.margin || 0);

      closeout.setExtendestimate(extendestimate);
      closeout.setDirectlaborcloseout(directlaborcloseout);
      closeout.setLaborfactoringcloseout(laborfactoringcloseout);
      closeout.setIncidentallaborcloseout(incidentallaborcloseout);
      closeout.setIndirectlaborcloseout(indirectlaborcloseout);
      closeout.setEquipmentcloseout(equipmentcloseout);
      closeout.setGeneralexpensecloseout(generalexpensecloseout);
      closeout.setSubcontractcloseout(subcontractcloseout);
      closeout.setQuotecloseout(quotecloseout);
      closeout.setTotalfinalprice(data.totalfinalprice);
      closeout.setTotaloverhead(data.totaloverhead);
      closeout.setTotalmargin(data.totalmargin);
      closeout.setTotalprofit(data.totalprofit);
      closeout.setTotalfinalpricecloseoutid(data.totalfinalpricecloseoutid);

      const req = new UpdateTotalFinalPriceChangeOrderCloseoutRequest();
      req.setCloseout(closeout);
      req.setSessiontoken(this.getMetadata().sessionToken);
      coClient.updateTotalFinalPriceChangeOrderCloseout(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateEstimateLaborFactoringAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateEstimateLaborFactoringAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new BreakoutAllocation();
      allocation.setId(data.id);
      allocation.setEstimateid(data.estimateid);
      allocation.setBreakoutid(data.breakoutid);
      allocation.setCloseoutid(data.closeoutid);
      allocation.setAllocationpercentage(data.allocationpercentage);
      allocation.setAllocationexpense(data.allocationexpense);

      const req = new UpdateEstimateLaborFactoringAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      console.log("Update Estimate Allocation Request", req.toObject());
      estClient.updateEstimateLaborFactoringAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateEstimateIncidentalLaborAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateEstimateIncidentalLaborAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new BreakoutAllocation();
      allocation.setId(data.id || "");
      allocation.setEstimateid(data.estimateid || "");
      allocation.setBreakoutid(data.breakoutid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setAllocationpercentage(data.allocationpercentage || 0);
      allocation.setAllocationexpense(data.allocationexpense || 0);

      const req = new UpdateEstimateIncidentalLaborAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.updateEstimateIncidentalLaborAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateEstimateIndirectLaborAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateEstimateIndirectLaborAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new BreakoutAllocation();
      allocation.setId(data.id || "");
      allocation.setEstimateid(data.estimateid || "");
      allocation.setBreakoutid(data.breakoutid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setAllocationpercentage(data.allocationpercentage || 0);
      allocation.setAllocationexpense(data.allocationexpense || 0);

      const req = new UpdateEstimateIndirectLaborAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.updateEstimateIndirectLaborAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateEstimateEquipmentAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateEstimateEquipmentAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new BreakoutAllocation();
      allocation.setId(data.id || "");
      allocation.setEstimateid(data.estimateid || "");
      allocation.setBreakoutid(data.breakoutid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setAllocationpercentage(data.allocationpercentage || 0);
      allocation.setAllocationexpense(data.allocationexpense || 0);

      const req = new UpdateEstimateEquipmentAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.updateEstimateEquipmentAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateEstimateGeneralExpenseAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateEstimateGeneralExpenseAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new BreakoutAllocation();
      allocation.setId(data.id || "");
      allocation.setEstimateid(data.estimateid || "");
      allocation.setBreakoutid(data.breakoutid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setAllocationpercentage(data.allocationpercentage || 0);
      allocation.setAllocationexpense(data.allocationexpense || 0);

      const req = new UpdateEstimateGeneralExpenseAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.updateEstimateGeneralExpenseAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateEstimateSubcontractAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateEstimateSubcontractAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new BreakoutAllocation();
      allocation.setId(data.id || "");
      allocation.setEstimateid(data.estimateid || "");
      allocation.setBreakoutid(data.breakoutid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setAllocationpercentage(data.allocationpercentage || 0);
      allocation.setAllocationexpense(data.allocationexpense || 0);

      const req = new UpdateEstimateSubcontractAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.updateEstimateSubcontractAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateEstimateQuoteAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateEstimateQuoteAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new BreakoutAllocation();
      allocation.setId(data.id || "");
      allocation.setEstimateid(data.estimateid || "");
      allocation.setBreakoutid(data.breakoutid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setAllocationpercentage(data.allocationpercentage || 0);
      allocation.setAllocationexpense(data.allocationexpense || 0);

      const req = new UpdateEstimateQuoteAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      estClient.updateEstimateQuoteAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateChangeOrderLaborFactoringAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateChangeOrderLaborFactoringAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new ChangeOrderBreakoutAllocation();
      allocation.setId(data.id || "");
      allocation.setEstimateid(data.estimateid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setBreakoutid(data.breakoutid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setAllocationpercentage(data.allocationpercentage || 0);
      allocation.setAllocationexpense(data.allocationexpense || 0);

      const req = new UpdateChangeOrderLaborFactoringAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.updateChangeOrderLaborFactoringAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateChangeOrderIncidentalLaborAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateChangeOrderIncidentalLaborAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new ChangeOrderBreakoutAllocation();
      allocation.setId(data.id || "");
      allocation.setEstimateid(data.estimateid || "");
      allocation.setBreakoutid(data.breakoutid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setAllocationpercentage(data.allocationpercentage || 0);
      allocation.setAllocationexpense(data.allocationexpense || 0);

      const req = new UpdateChangeOrderIncidentalLaborAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.updateChangeOrderIncidentalLaborAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateChangeOrderIndirectLaborAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateChangeOrderIndirectLaborAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new ChangeOrderBreakoutAllocation();
      allocation.setId(data.id || "");
      allocation.setEstimateid(data.estimateid || "");
      allocation.setBreakoutid(data.breakoutid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setAllocationpercentage(data.allocationpercentage || 0);
      allocation.setAllocationexpense(data.allocationexpense || 0);

      const req = new UpdateChangeOrderIndirectLaborAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.updateChangeOrderIndirectLaborAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateChangeOrderEquipmentAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateChangeOrderEquipmentAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new ChangeOrderBreakoutAllocation();
      allocation.setId(data.id || "");
      allocation.setEstimateid(data.estimateid || "");
      allocation.setBreakoutid(data.breakoutid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setAllocationpercentage(data.allocationpercentage || 0);
      allocation.setAllocationexpense(data.allocationexpense || 0);

      const req = new UpdateChangeOrderEquipmentAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.updateChangeOrderEquipmentAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateChangeOrderGeneralExpenseAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateChangeOrderGeneralExpenseAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new ChangeOrderBreakoutAllocation();
      allocation.setId(data.id || "");
      allocation.setEstimateid(data.estimateid || "");
      allocation.setBreakoutid(data.breakoutid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setAllocationpercentage(data.allocationpercentage || 0);
      allocation.setAllocationexpense(data.allocationexpense || 0);

      const req = new UpdateChangeOrderGeneralExpenseAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.updateChangeOrderGeneralExpenseAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateChangeOrderSubcontractAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateChangeOrderSubcontractAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new ChangeOrderBreakoutAllocation();
      allocation.setId(data.id || "");
      allocation.setEstimateid(data.estimateid || "");
      allocation.setBreakoutid(data.breakoutid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setAllocationpercentage(data.allocationpercentage || 0);
      allocation.setAllocationexpense(data.allocationexpense || 0);

      const req = new UpdateChangeOrderSubcontractAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.updateChangeOrderSubcontractAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateChangeOrderQuoteAllocation(
    data: BreakoutAllocation.AsObject
  ): Promise<UpdateChangeOrderQuoteAllocationResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const allocation = new BreakoutAllocation();
      allocation.setId(data.id || "");
      allocation.setEstimateid(data.estimateid || "");
      allocation.setBreakoutid(data.breakoutid || "");
      allocation.setCloseoutid(data.closeoutid || "");
      allocation.setAllocationpercentage(data.allocationpercentage || 0);
      allocation.setAllocationexpense(data.allocationexpense || 0);

      const req = new UpdateChangeOrderQuoteAllocationRequest();
      req.setAllocation(allocation);
      req.setSessiontoken(this.getMetadata().sessionToken);

      coClient.updateChangeOrderQuoteAllocation(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }
}
