import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";
import {
  AddGCSObjectsToVectorStoreRequest,
  AddGCSObjectsToVectorStoreResponse,
  ListMessagesOnThreadRequest,
  ListMessagesOnThreadResponse,
  SendMessageRequest,
  SendMessageResponse,
  StreamMessageRequest,
  StreamMessageResponse,
} from "./protosCompiled/assistants/assistants_pb";

const client = clients.assistantServiceClient;

export class AssistantService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static sendAIMessage = ({
    usermessage,
    objectid,
    objectdata,
    projectid,
  }: {
    usermessage: string;
    objectid?: string;
    objectdata?: Uint8Array;
    projectid: string;
  }): Promise<SendMessageResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new SendMessageRequest();
      try {
        req.setUsermessage(usermessage);
        req.setSessiontoken(this.getMetadata().sessionToken);
        req.setProjectid(projectid);
      } catch (error) {
        alert("Error setting required fields");
        return;
      }
      try {
        req.setObjectid(objectid || "");
        req.setObjectdata(objectdata || new Uint8Array());
      } catch (error) {
        alert("Error setting optional fields");
        return;
      }
      client.sendMessage(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  };

  public static addGCSObjectsToVectorStore = ({
    projectid,
    objectid,
  }: {
    projectid: string;
    objectid: string;
  }): Promise<AddGCSObjectsToVectorStoreResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const request = new AddGCSObjectsToVectorStoreRequest();
      request.setSessiontoken(this.getMetadata().sessionToken);
      request.setProjectid(projectid);
      request.setObjectid(objectid);

      client.addGCSObjectsToVectorStore(request, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        console.log("GCS Objects Added to Vector Store", response.toObject());
        resolve(response.toObject());
      });
    });
  };

  static listMessagesOnThread = ({
    projectid,
  }: {
    projectid: string;
  }): Promise<ListMessagesOnThreadResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const request = new ListMessagesOnThreadRequest();
      request.setSessiontoken(this.getMetadata().sessionToken);
      request.setProjectid(projectid);
      console.log("projectid", projectid);
      client.listMessagesOnThread(request, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  };

  public static streamMessage = ({
    usermessage,
    objectid,
    objectdata,
    projectid,
    onMessage,
  }: {
    usermessage: string;
    objectid?: string;
    objectdata?: Uint8Array;
    projectid: string;
    onMessage?: (message: string) => void;
  }): Promise<void> => {
    return new Promise((resolve, reject) => {
      const req = new StreamMessageRequest();
      try {
        req.setUsermessage(usermessage);
        req.setSessiontoken(this.getMetadata().sessionToken);
        req.setProjectid(projectid);
      } catch (error) {
        alert("Error setting required fields");
        reject(error);
        return;
      }
      try {
        req.setObjectid(objectid || "");
        req.setObjectdata(objectdata || new Uint8Array());
      } catch (error) {
        alert("Error setting optional fields");
        reject(error);
        return;
      }

      const stream = client.streamMessage(req, {});

      stream.on("data", (response: StreamMessageResponse) => {
        const content = response.getResponsemessage();
        if (content) {
          onMessage && onMessage(content);
        }
        // You can further process the streamed response here
        return;
      });

      stream.on("error", (err: Error) => {
        console.error("Stream encountered an error:", err);
        reject(err);
      });

      stream.on("end", () => {
        resolve();
      });
    });
  };
}
