import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { GeneralExpenseRowProps } from "./closeoutTypes";
import { GeneralExpenseCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { formatCurrency } from "./closeoutHelpers";
import {
  useDeleteGeneralExpenseChangeOrderCloseoutMutation,
  useDeleteGeneralExpenseCloseoutMutation,
  useGetGeneralExpenseChangeOrderCloseouts,
  useGetGeneralExpenseCloseouts,
  useUpdateGeneralExpenseChangeOrderCloseoutMutation,
  useUpdateGeneralExpenseCloseoutMutation,
} from "../../hooks/useCloseoutHooks";
import { Button, TableCell, TableRow, TextField } from "@mui/material";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";

export const GeneralExpenseRow: React.FC<GeneralExpenseRowProps> = ({
  closeout,
}) => {
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  const [quantity, setQuantity] = useState<string>(
    closeout.quantity.toFixed(2)
  );
  const [quantityMultiplier, setQuantityMultiplier] = useState<string>(
    closeout.quantitymultiplier.toFixed(2)
  );

  const [unitcost, setUnitCost] = useState<string>(
    closeout.unitcost.toFixed(2)
  );

  const { mutateAsync: updateEstimateLabor } =
    useUpdateGeneralExpenseCloseoutMutation();
  const { mutateAsync: deleteEstimateLabor } =
    useDeleteGeneralExpenseCloseoutMutation();
  const { refetch: refetchEstimateCloseout } = useGetGeneralExpenseCloseouts();

  const { mutateAsync: updateChangeOrderLabor } =
    useUpdateGeneralExpenseChangeOrderCloseoutMutation();
  const { mutateAsync: deleteChangeOrderLabor } =
    useDeleteGeneralExpenseChangeOrderCloseoutMutation();
  const { refetch: refetchChangeOrderCloseout } =
    useGetGeneralExpenseChangeOrderCloseouts();

  const updateLabor = selectedEstimate?.estimateid
    ? updateEstimateLabor
    : updateChangeOrderLabor;

  const deleteLabor = selectedEstimate?.estimateid
    ? deleteEstimateLabor
    : deleteChangeOrderLabor;

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimateCloseout
    : refetchChangeOrderCloseout;

  useEffect(() => {
    setQuantity(closeout.quantity.toFixed(2));
    setQuantityMultiplier(closeout.quantitymultiplier.toFixed(2));
    setUnitCost(closeout.unitcost.toFixed(2));
  }, [closeout]);

  type Field = keyof Pick<
    GeneralExpenseCloseout.AsObject,
    "quantity" | "quantitymultiplier" | "unitcost"
  >;
  const handleFieldChange = useCallback(
    async (field: Field, value: string) => {
      // Allow only numbers with up to 2 decimals.
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!regex.test(value) || Number(value) > 99999999.99) return;
      await updateLabor(
        {
          ...closeout,
          [field]: value,
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    },
    [closeout, updateLabor, refetch]
  );

  const handleDelete = useCallback(() => {
    deleteLabor(closeout.closeoutid, {
      onSuccess: () => {
        refetch();
      },
    });
  }, [closeout, deleteLabor, refetch]);

  return (
    <TableRow>
      {/* Description */}
      <TableCell>{closeout.name}</TableCell>
      {/* Quantity */}
      <TableCell>
        <TextField
          type="text"
          value={quantity}
          onBlur={(e) => handleFieldChange("quantity", e.target.value)}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </TableCell>
      {/* Quantity Multiplier */}
      <TableCell>
        <TextField
          type="text"
          value={quantityMultiplier}
          onBlur={(e) =>
            handleFieldChange("quantitymultiplier", e.target.value)
          }
          onChange={(e) => setQuantityMultiplier(e.target.value)}
        />
      </TableCell>
      {/* Unit Cost */}
      <TableCell>
        <TextField
          type="text"
          value={unitcost}
          onBlur={(e) => handleFieldChange("unitcost", e.target.value)}
          onChange={(e) => setUnitCost(e.target.value)}
        />
      </TableCell>
      {/* Total Cost */}
      <TableCell>{formatCurrency(closeout.totalcost)}</TableCell>
      <Button onClick={handleDelete} style={{ width: "40px" }}>
        <DeleteIcon />
      </Button>
    </TableRow>
  );
};
