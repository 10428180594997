import { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { useStore } from "zustand";
import { useSubmitTakeoffAssembly } from "../../hooks/useSubmitTakeoffAssembly";
import { useUnityBuildStore } from "../../states/store";
import { useSetSelectedAssemblyFromAssemblyPicker } from "../../hooks/useSetSelectedAssembly";
import { useAssemblyStore } from "../../states/AssemblyStore";
import { keepBoxInView } from "../../utils/keepBoxInView";
import { EstimatePoint } from "../../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { ChangeOrderPoint } from "../../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { TakeoffAssembly } from "../../types/AssemblyItemType";
import { useHandleGlobalStateState } from "../../hooks/useHandleGlobalState";

/**
 * Combined type for convenience:
 * Represents a single point in either Estimate or ChangeOrder assemblies.
 */
export type Point = EstimatePoint.AsObject | ChangeOrderPoint.AsObject;

export const PointEditor = () => {
  const store = useStore(useUnityBuildStore);

  const assemblyIndex = useStore(useUnityBuildStore).assemblyIndex;
  const pointIndex = useStore(useUnityBuildStore).pointIndex;
  const editingPointX = useStore(useUnityBuildStore).editingPointX;
  const editingPointY = useStore(useUnityBuildStore).editingPointY;
  const includeAllPoints = useStore(useUnityBuildStore).includeAllPoints;
  const userScale = useStore(useUnityBuildStore).userScale;
  const isAnnotating = useStore(useUnityBuildStore).isAnnotating;
  const setIsEditingPoint = useStore(useUnityBuildStore).setIsEditingPoint;
  const setIsAnnotating = useStore(useUnityBuildStore).setIsAnnotating;
  const setIncludeAllPoints = useStore(useUnityBuildStore).setIncludeAllPoints;
  const setIsInsertingPoint = useStore(useUnityBuildStore).setIsInsertingPoint;

  const currentTakeoffAssembly = useStore(useAssemblyStore).currentTakeoffAssembly;

  const submitAssembly = useSubmitTakeoffAssembly();
  const setSelectedAssembly = useSetSelectedAssemblyFromAssemblyPicker();

  const [verticalLength, setVerticalLength] = useState<number>(0);

  // Local checkbox states
  const [localIsIncludedInCount, setLocalIsIncludedInCount] = useState(false);
  const [localAllowTrailingLine, setLocalAllowTrailingLine] = useState(false);

  // If we have a valid pointIndex => "point editing"
  const isPointEditing = pointIndex !== null && pointIndex !== undefined;

  /**
   * On mount/update:
   *   Read the point's verticalLength, isIncludedInCount, and allowTrailingLine
   */
  useEffect(() => {
    if (
      currentTakeoffAssembly &&
      currentTakeoffAssembly.pointsList &&
      isPointEditing
    ) {
      const pt = currentTakeoffAssembly.pointsList[pointIndex];
      setVerticalLength(pt?.verticallength ?? 0);
      setLocalIsIncludedInCount(pt?.isincludedincount ?? false);
      setLocalAllowTrailingLine(pt?.allowtrailingline ?? false);
    }
  }, [currentTakeoffAssembly, pointIndex, isPointEditing]);

  // --------------------------
  //    Existing Functionality
  // --------------------------
  const handleAddToRun = (assembly: TakeoffAssembly) => {
    setIsEditingPoint({ isEditingPoint: false, isEditingStyle: false });
    setIsAnnotating(true);
    setSelectedAssembly({
      takeoffAssembly: assembly,
    });
  };
  const handleGlobalState = useHandleGlobalStateState();

  const handleCompleteRun = async () => {
    if (currentTakeoffAssembly) {
      try {
        if (currentTakeoffAssembly.assemblyid) {
          await submitAssembly({
            shouldUpdateBreakout: false,
            assembly: currentTakeoffAssembly,
            isUpdating: true,
            pageScale: userScale,
          });
        } else {
          await submitAssembly({
            shouldUpdateBreakout: true,
            assembly: currentTakeoffAssembly,
            isCreating: true,
            pageScale: userScale,
          });
        }
        handleGlobalState({
          resetOption: "resetTakeoff",
        });
      } catch (error) {
        console.error("Error submitting assembly:", error);
      }
    }
    setIsAnnotating(false);
    setIsEditingPoint({ isEditingPoint: false, isEditingStyle: false });
  };

  const handleEditStyle = () => {
    setIsEditingPoint({ isEditingPoint: false, isEditingStyle: true });
  };

  const handleDeletePoint = async (assemblyIdx: number, pIndex: number) => {
    if (!currentTakeoffAssembly) return;

    let updatedPoints: Point[] = [...currentTakeoffAssembly.pointsList];

    // If "Include All Points" is checked => remove ALL points
    if (includeAllPoints) {
      updatedPoints = [];
    } else {
      updatedPoints = updatedPoints.filter((_, idx) => idx !== pIndex);
    }

    const updatedAssembly = {
      ...currentTakeoffAssembly,
      pointsList: updatedPoints,
    };

    await submitAssembly({
      shouldUpdateBreakout: false,
      assembly: updatedAssembly,
      isUpdating: true,
      pageScale: userScale,
    });
    handleGlobalState({
      resetOption: "resetTakeoff",
    });
  };

  const handleAddVertical = async (pIndex: number) => {
    if (!currentTakeoffAssembly) return;
    store.setIsAddingVertical(true);

    let updatedPoints = [...currentTakeoffAssembly.pointsList];

    if (includeAllPoints) {
      // apply verticalLength to all
      updatedPoints = updatedPoints.map((pt) => ({
        ...pt,
        verticallength: verticalLength,
      }));
    } else {
      // apply verticalLength only to the selected point
      updatedPoints[pIndex] = {
        ...updatedPoints[pIndex],
        verticallength: verticalLength,
      };
    }

    const updatedAssembly = {
      ...currentTakeoffAssembly,
      pointsList: updatedPoints,
    };

    await submitAssembly({
      shouldUpdateBreakout: false,
      assembly: updatedAssembly,
      isUpdating: true,
      pageScale: userScale,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && isPointEditing) {
      handleAddVertical(pointIndex!);
    }
  };

  const handleVerticalLengthChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(e.target.value);
    setVerticalLength(isNaN(value) ? 0 : value);
  };

  const handleIncludeAllPointsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIncludeAllPoints(e.target.checked);
  };

  const handleSetInsertPoint = () => {
    setIsInsertingPoint(true);
    setIsEditingPoint({ isEditingPoint: false });
  };

  // -------------
  //  NEW LOGIC
  // -------------
  /** handleToggleIncludeInCount:
   *   Only changes 'isincludedincount' for:
   *   - The single point (pointIndex) if "includeAllPoints" = false
   *   - All points if "includeAllPoints" = true
   */
  const handleToggleIncludeInCount = async (checked: boolean) => {
    if (!currentTakeoffAssembly) return;
    let updatedPoints = [...currentTakeoffAssembly.pointsList];

    if (includeAllPoints) {
      updatedPoints = updatedPoints.map((pt) => ({
        ...pt,
        isincludedincount: checked,
      }));
    } else if (isPointEditing) {
      const orig = updatedPoints[pointIndex];
      updatedPoints[pointIndex] = {
        ...orig,
        isincludedincount: checked,
      };
    }

    const updatedAssembly = {
      ...currentTakeoffAssembly,
      pointsList: updatedPoints,
    };
    await submitAssembly({
      shouldUpdateBreakout: false,
      assembly: updatedAssembly,
      isUpdating: true,
      pageScale: userScale,
    });
    // Update local state so checkbox stays in sync
    setLocalIsIncludedInCount(checked);
  };

  /** handleToggleAllowTrailingLine:
   *   Only changes 'allowtrailingline' for:
   *   - The single point (pointIndex) if "includeAllPoints" = false
   *   - All points if "includeAllPoints" = true
   */
  const handleToggleAllowTrailingLine = async (checked: boolean) => {
    if (!currentTakeoffAssembly) return;
    let updatedPoints = [...currentTakeoffAssembly.pointsList];

    if (includeAllPoints) {
      updatedPoints = updatedPoints.map((pt) => ({
        ...pt,
        allowtrailingline: checked,
      }));
    } else if (isPointEditing) {
      // In the new approach, "AllowTrailingLine" is also stored on the same point
      const orig = updatedPoints[pointIndex];
      updatedPoints[pointIndex] = {
        ...orig,
        allowtrailingline: checked,
      };
    }

    const updatedAssembly = {
      ...currentTakeoffAssembly,
      pointsList: updatedPoints,
    };
    await submitAssembly({
      shouldUpdateBreakout: false,
      assembly: updatedAssembly,
      isUpdating: true,
      pageScale: userScale,
    });
    // Update local state so checkbox stays in sync
    setLocalAllowTrailingLine(checked);
  };

  // --------------------------------
  // Box positioning + rendering
  // --------------------------------
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const boxWidth = 200;
  const boxHeight = 300;

  const { x: adjustedX, y: adjustedY } = keepBoxInView(
    editingPointX,
    editingPointY,
    boxWidth,
    boxHeight,
    windowWidth,
    windowHeight
  );

  return (
    <Paper
      onContextMenu={(e) => e.preventDefault()}
      elevation={10}
      style={{
        position: "absolute",
        top: adjustedY + 5,
        left: adjustedX + 5,
        backgroundColor: store.userBackgroundColor,
        padding: "1rem",
        width: boxWidth,
        height: boxHeight,
        border: "1px solid black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 9999, // ensure it's on top
      }}
    >
      {/* Add to Run / Complete Run */}
      <button
        style={{ cursor: "pointer", width: "100px" }}
        onClick={
          isAnnotating
            ? handleCompleteRun
            : () => handleAddToRun(currentTakeoffAssembly as TakeoffAssembly)
        }
      >
        {isAnnotating ? "Complete Run" : "Add to Run"}
      </button>

      {/* Insert Point for length/area assemblies */}
      {currentTakeoffAssembly?.assemblymeasurementtype === "length" ||
        currentTakeoffAssembly?.assemblymeasurementtype === "area" ? (
        <button
          style={{ cursor: "pointer", width: "100px" }}
          onClick={handleSetInsertPoint}
        >
          Insert Point
        </button>
      ) : null}

      {/* Edit Style */}
      <button
        style={{ cursor: "pointer", width: "100px" }}
        onClick={handleEditStyle}
      >
        Edit Style
      </button>

      <div style={{ width: "100%", textAlign: "center" }}>
        _______________________
        {/* Include All Points */}
        <div style={{ marginBottom: "10px" }}>
          <label>
            Include all points:
            <input
              type="checkbox"
              checked={includeAllPoints}
              onChange={handleIncludeAllPointsChange}
              style={{ marginLeft: "10px" }}
            />
          </label>
        </div>
        {/* "Allow Trailing Line" => stored in the same point now */}
        {currentTakeoffAssembly?.assemblymeasurementtype === "length" && (
          <div style={{ marginBottom: "5px" }}>
            <label style={{ cursor: "pointer" }}>
              <input
                type="checkbox"
                checked={localAllowTrailingLine}
                onChange={(e) =>
                  handleToggleAllowTrailingLine(e.target.checked)
                }
                style={{ marginRight: "5px" }}
              />
              Allow Trailing Line
            </label>
          </div>
        )}
        {/* "Include in Count" => same single point */}
        <div style={{ marginBottom: "5px" }}>
          <label style={{ cursor: "pointer" }}>
            <input
              type="checkbox"
              checked={localIsIncludedInCount}
              onChange={(e) => handleToggleIncludeInCount(e.target.checked)}
              style={{ marginRight: "5px" }}
            />
            Include in Count
          </label>
        </div>
        {/* Delete Point */}
        <button
          style={{ cursor: "pointer", width: "100px", marginBottom: "10px" }}
          onClick={() => handleDeletePoint(assemblyIndex!, pointIndex!)}
        >
          Delete Point
        </button>
        {/* If measurement type is "length", show vertical length input */}
        {currentTakeoffAssembly?.assemblymeasurementtype === "length" && (
          <div style={{ marginBottom: "10px" }}>
            Add length in feet (enter):
            <input
              type="number"
              value={verticalLength}
              onChange={handleVerticalLengthChange}
              onKeyDown={handleKeyDown}
              style={{ cursor: "pointer", width: "100px", marginLeft: "10px" }}
            />
          </div>
        )}
      </div>
    </Paper>
  );
};
