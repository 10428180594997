import { useCallback, useState } from "react";
import { useStore } from "zustand";
import { EquipmentRow } from "./EquipmentRow";
import { EquipmentCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { useGetDefaultEquipmentExpenseTypes } from "../../hooks/useDefaultCloseoutHooks";
import { useUnityBuildStore } from "../../states/store";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  useCreateEquipmentChangeOrderCloseoutMutation,
  useCreateEquipmentCloseoutMutation,
  useGetEquipmentChangeOrderCloseouts,
  useGetEquipmentCloseouts,
} from "../../hooks/useCloseoutHooks";
import { EquipmentChangeOrderCloseout } from "../../api/protosCompiled/changeOrderCloseout/changeOrderCloseout_pb";

export const EquipmentHeader = () => {
  // Zustand state
  const { selectedEstimate, selectedChangeOrder } =
    useStore(useUnityBuildStore);

  // React-query state & hooks
  const { data: closeoutDefaults } = useGetDefaultEquipmentExpenseTypes();

  const { data: estimateCloseout, refetch: refetchEstimateCloseout } =
    useGetEquipmentCloseouts();
  const { data: changeOrderCloseout, refetch: refetchChangeOrderCloseout } =
    useGetEquipmentChangeOrderCloseouts();

  const { mutateAsync: createEstimateCloseout } =
    useCreateEquipmentCloseoutMutation();
  const { mutateAsync: createChangeOrderCloseout } =
    useCreateEquipmentChangeOrderCloseoutMutation();

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimateCloseout
    : refetchChangeOrderCloseout;

  const createCloseout = selectedEstimate?.estimateid
    ? createEstimateCloseout
    : createChangeOrderCloseout;

  const closeout = selectedEstimate?.estimateid
    ? estimateCloseout
    : changeOrderCloseout;

  const newCloseoutType = selectedEstimate?.estimateid
    ? new EquipmentCloseout()
    : new EquipmentChangeOrderCloseout();

  const takeoffId = selectedEstimate?.estimateid
    ? selectedEstimate?.estimateid
    : selectedChangeOrder?.changeorderid ?? "";

  const handleAddDefaultType = useCallback(
    (e: SelectChangeEvent<string>) => {
      const newValue = e.target.value;
      const defaultCloseoutType = closeoutDefaults?.find(
        (closeoutDefault) => closeoutDefault.name === newValue
      );
      if (defaultCloseoutType) {
        newCloseoutType.setCloseoutid(defaultCloseoutType.id);
        newCloseoutType.setEstimateid(takeoffId);
        newCloseoutType.setCloseoutdefaultequipmentid(defaultCloseoutType.id);
        newCloseoutType.setQuantity(0);
        newCloseoutType.setDuration(0);
        newCloseoutType.setUnitcost(defaultCloseoutType.unitcost);

        createCloseout(newCloseoutType, {
          onSuccess: () => {
            refetch();
          },
        }).catch((err) => console.error("Error creating factored labor:", err));
      }
    },
    // eslint-disable-next-line
    [
      closeoutDefaults,
      selectedEstimate,
      selectedChangeOrder,
      createCloseout,
      refetch,
    ]
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FormControl fullWidth variant="filled" sx={{ mb: 1 }}>
        <InputLabel id="labor-select-label">Select Equipment Type</InputLabel>
        <Select
          labelId="labor-select-label"
          id="labor-select"
          label="Select Equipment Type"
          value={"Select Equipment Type"}
          onChange={handleAddDefaultType}
        >
          <MenuItem value="" disabled selected hidden>
            Select labor type
          </MenuItem>
          {closeoutDefaults &&
            closeoutDefaults.map((closeoutItem, index) => (
              <MenuItem value={closeoutItem.name} key={index}>
                {closeoutItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <TableContainer
        component={Paper}
        sx={{
          height: "100%",
          overflowY: "auto",
          border: "1px solid black",
        }}
      >
        <Table stickyHeader sx={{ width: "100%", tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Unit Cost</TableCell>
              <TableCell>Total Cost</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {closeout?.closeoutsList.map((closeoutItem, index) => (
              <EquipmentRow
                key={`${index}-${closeoutItem.closeoutdefaultequipmentid}`}
                closeout={closeoutItem}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
