import {
  useCreateGlobalAssemblyMutation,
  useDeleteGlobalAssemblyMutation,
  useFetchAssembliesByFileId,
} from "../../../hooks/useFetchAssembliesByFileId";
import { useSetSelectedAssemblyFromAssemblyPicker } from "../../../hooks/useSetSelectedAssembly";
import {
  Button,
  CircularProgress,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import { AssemblyTableProps } from "../../../types/FolderTypes";
import { GlobalAssembly } from "../../../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { useStore } from "zustand";
import { useAssemblyStore } from "../../../states/AssemblyStore";
import { useGlobalItemStore } from "../../../states/globalItemStore";
import { useHandleMoveItemOrAssemblyFile } from "../folderManager/helpers";
import { useFolderStore } from "../../../states/folderStore";
import { useHandleGlobalStateState } from "../../../hooks/useHandleGlobalState";

export function AssemblyTable({ context, folder }: AssemblyTableProps) {
  //zustand states
  const isMoveMode = useStore(useFolderStore).isMoveMode;
  const setIsMoveMode = useStore(useFolderStore).setIsMoveMode;
  const setIsViewingItemForm = useStore(useGlobalItemStore).setIsViewingItemForm;
  const setIsCreatingItem = useStore(useGlobalItemStore).setIsCreatingItem;
  const isCreatingAssembly = useStore(useAssemblyStore).isCreatingAssembly;
  const setIsCreatingAssembly = useStore(useAssemblyStore).setIsCreatingAssembly;
  const setIsEditMode = useStore(useAssemblyStore).setIsEditMode;
  const setIsViewingAssemblyForm = useStore(useAssemblyStore).setIsViewingAssemblyForm;
  const setAssemblyOption = useStore(useAssemblyStore).setAssemblyOption;
  const setCurrentGlobalAssembly = useStore(useAssemblyStore).setCurrentGlobalAssembly;

  const moveCurrentAssemblyFile = useHandleMoveItemOrAssemblyFile();

  //react-query states
  const { data: globalAssemblies, isLoading } = useFetchAssembliesByFileId(
    folder.folderid
  );

  const handleGlobalState = useHandleGlobalStateState();

  const { mutate: createGlobalAssembly } = useCreateGlobalAssemblyMutation();

  //hooks
  //this setter is for selecting a global assembly and appending to it the default values required for takeoff assemblies
  const setSelectedAssembly = useSetSelectedAssemblyFromAssemblyPicker();
  const { mutate: deleteGlobalAssembly } = useDeleteGlobalAssemblyMutation();

  const handleSetSelectedAssembly = (assembly: GlobalAssembly.AsObject) => {
    setSelectedAssembly({
      globalAssembly: assembly,
    });
  };

  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    assembly: GlobalAssembly.AsObject
  ) => {
    deleteGlobalAssembly(assembly);
    setIsMoveMode(false);
  };

  const handleCopy = (assembly: GlobalAssembly.AsObject) => {
    createGlobalAssembly(assembly);
    setIsMoveMode(false);
  };

  if (!globalAssemblies || isLoading) {
    return (
      <tr>
        <td colSpan={3}>
          <CircularProgress />
        </td>
      </tr>
    );
  }

  return (
    <>
      {globalAssemblies.map((assembly: GlobalAssembly.AsObject) => (
        <TableRow
          hover
          key={assembly.assemblyid}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!isCreatingAssembly) {
              handleSetSelectedAssembly(assembly);
            }
          }}
          sx={{ cursor: "pointer" }}
        >
          <TableCell>
            {assembly.itemsList.length === 0 && (
              <span style={{ color: "red" }}>No Items! </span>
            )}
            {assembly.assemblyname}
          </TableCell>

          <TableCell>{assembly.assemblymeasurementtype}</TableCell>

          {context === "primary" && (
            <TableCell>
              <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }}>
                {isMoveMode && (
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => setIsMoveMode(false)}
                  >
                    Cancel Move
                  </Button>
                )}

                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleGlobalState({
                      resetOption: "resetAssembly",
                      setStateFunctions: [
                        {
                          setStateFunction: setCurrentGlobalAssembly,
                          prop: null,
                        },
                        {
                          setStateFunction: setCurrentGlobalAssembly,
                          prop: assembly,
                        },
                        {
                          setStateFunction: setIsViewingAssemblyForm,
                          prop: true,
                        },
                        {
                          setStateFunction: setIsViewingItemForm,
                          prop: false,
                        },
                        {
                          setStateFunction: setIsCreatingItem,
                          prop: false,
                        },
                        {
                          setStateFunction: setAssemblyOption,
                          prop: "global",
                        },
                        {
                          setStateFunction: setIsCreatingAssembly,
                          prop: false,
                        },
                        {
                          setStateFunction: setIsEditMode,
                          prop: true,
                        }
                      ],
                    });
                  }}
                >
                  Edit
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  color="warning"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    moveCurrentAssemblyFile({
                      e,
                      assembly,
                      entitytype: "assembly",
                    });
                  }}
                >
                  Move
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  color="info"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleCopy(assembly);
                  }}
                >
                  Copy
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleDelete(e, assembly);
                  }}
                >
                  Delete
                </Button>
              </Stack>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
}
