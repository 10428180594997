import { useStore } from "zustand";
import { useInferenceStore } from "../../states/inferenceStore"; // Update this path as needed
import { useUnityBuildStore } from "../../states/store";
import React from "react";
import { useFetchObjects } from "../../hooks/objectHooks";

export const SVGInferenceBoxes: React.FC = () => {

  const inferenceCountObjects = useStore(useInferenceStore).inferenceCountObjects;
  const classMap = useStore(useInferenceStore).classMap;
  const confidanceThreshold = useStore(useInferenceStore).confidanceThreshold;
  const inferenceItemFilter = useStore(useInferenceStore).inferenceItemFilter;

  const selectedObject = useStore(useUnityBuildStore).selectedObject;

  const { data: availableObjects } = useFetchObjects();


  const renderBoundingBoxes = () => {
    if (!availableObjects) return;
    // Filter inference objects based on the currentPage and gcsObjectName
    return inferenceCountObjects
      .filter(
        (inferenceObject) =>
          (inferenceObject.pagenumbersList.includes(1) &&
            inferenceObject.gcsobjectnamesList[0] ===
            selectedObject?.gcsobjectname) ||
          []
      )
      .flatMap((inferenceObject, index) => {
        return inferenceObject.resultsList.map((result, resultIndex) => {
          return result.boxesList.map((box, boxIndex) => {
            const classId = result.labelsList[boxIndex];
            const className = classMap ? classMap[classId] : classId; // Retrieve the class name from the map or use the ID if not available
            const score = result.scoresList[boxIndex];

            // Apply filtering: check if className matches inferenceItemFilter
            if (inferenceItemFilter && className !== inferenceItemFilter) {
              return null;
            }

            // Translate the image coordinates to the browser's screen coordinates
            const browserDpi = 72;
            const imageDpi = 200;
            const convertedScreenCoord = imageDpi / browserDpi;

            // Calculate the location and dimensions of the bounding box
            const x = box.xmin / convertedScreenCoord;
            const y = box.ymin / convertedScreenCoord;
            const width = (box.xmax - box.xmin) / convertedScreenCoord;
            const height = (box.ymax - box.ymin) / convertedScreenCoord;

            return (
              <React.Fragment key={`${index}-${resultIndex}-${boxIndex}`}>
                {score > confidanceThreshold && (
                  <g>
                    {/* Render rectangle bounding box */}
                    <rect
                      x={x}
                      y={y}
                      width={width}
                      height={height}
                      fill="none"
                      stroke="red"
                      strokeWidth="2"
                    />
                    {/* Render text label above the rectangle */}
                    <text
                      x={x}
                      y={y - 5}
                      fill="red"
                      fontSize="12"
                      fontFamily="sans-serif"
                    >
                      {className} ({(score * 100).toFixed(1)}%)
                    </text>
                  </g>
                )}
              </React.Fragment>
            );
          });
        });
      })
      .filter(Boolean); // Remove null entries from the result to avoid rendering empty fragments
  };

  return <>{renderBoundingBoxes()}</>;
};
