import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  Button,
} from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { useDebounce } from "use-debounce";
import { ExtentionRow } from "./ExtentionRow";
import { AssignCosts } from "../assembliesAndItems/items/AssignCosts";
import {
  formatCurrency,
  formatNumber,
  useCalculateTotalMaterialAndLaborCosts,
} from "./closeoutHelpers";
import {
  useGetExtendEstimates,
  useGetExtendChangeOrder,
} from "../../hooks/useCloseoutHooks";

export const ExtentionHeader: React.FC = () => {
  // Local state for search and dialog
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string>("");
  const [extensionLaborHours, setExtensionLaborHours] = useState<number>(0);
  const [extensionMaterialCost, setExtensionMaterialCost] = useState<number>(0);

  // Get extension data (for estimate or change order)
  const { data: estimateExtension, refetch: refetchEstimateExtension } =
    useGetExtendEstimates();
  const { data: changeOrderExtension, refetch: refetchChangeOrderExtension } =
    useGetExtendChangeOrder();
  const extensionData = changeOrderExtension || estimateExtension;
  const refetchData = changeOrderExtension
    ? refetchChangeOrderExtension
    : refetchEstimateExtension;

  const calculateTotalMaterialAndLaborCosts =
    useCalculateTotalMaterialAndLaborCosts();

  // When new data arrives, calculate totals
  useEffect(() => {
    if (extensionData) {
      const { totalLaborHours, totalMaterialCost } =
        calculateTotalMaterialAndLaborCosts(extensionData);
      setExtensionLaborHours(totalLaborHours);
      setExtensionMaterialCost(totalMaterialCost);
    }
  }, [extensionData, calculateTotalMaterialAndLaborCosts]);

  // Search input handler
  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
    },
    []
  );

  // Open/close the "AssignCosts" dialog
  const handleOpenDialog = useCallback((itemId: string) => {
    setSelectedItemId(itemId);
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    setSelectedItemId("");
    refetchData();
  }, [refetchData]);

  // Filter the data based on debounced search term
  const filteredItems = useMemo(() => {
    if (!extensionData?.extendestimatesList) return [];
    return extensionData.extendestimatesList.filter(
      (item) =>
        item.itemname
          .toLowerCase()
          .includes(debouncedSearchTerm.toLowerCase()) ||
        (item.attributegroupname &&
          item.attributegroupname
            .toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase()))
    );
  }, [extensionData, debouncedSearchTerm]);

  // Group filtered items by breakout id
  const groupedItems = useMemo(() => {
    return filteredItems.reduce((acc, item) => {
      const key = item.breakoutid || "No Breakout";
      if (!acc[key]) {
        acc[key] = {
          breakoutName: item.breakoutname || `Breakout ID: ${key}`,
          items: [] as typeof filteredItems,
        };
      }
      acc[key].items.push(item);
      return acc;
    }, {} as Record<string, { breakoutName: string; items: any[] }>);
  }, [filteredItems]);


// Flatten groups into an array with a header row per breakout
// and aggregated data rows (one per unique item name within that breakout).
const flattenedRows = useMemo(() => {
  const rows: Array<
    | { type: "header"; key: string; label: string }
    | { type: "row"; key: string; data: any }
  > = [];

  // Iterate over each breakout group.
  Object.entries(groupedItems).forEach(([breakoutKey, group]) => {
    // Insert a header row for the breakout.
    rows.push({ type: "header", key: breakoutKey, label: group.breakoutName });

    // Aggregate items by a unique key (using itemname in this example).
    const aggregated = group.items.reduce<Record<string, any>>((acc, item) => {
      const key = item.itemname || "Unknown Item";
      if (!acc[key]) {
        // Create the aggregated object and store the underlying extension in an array.
        acc[key] = {
          ...item,
          totalitemqty: Number(item.totalitemqty) || 0,
          totalcost: Number(item.totalcost) || 0,
          totaldirectlaborhours: Number(item.totaldirectlaborhours) || 0,
          aggregatedExtensions: [item],
        };
      } else {
        // Sum the numeric values...
        acc[key].totalitemqty += Number(item.totalitemqty) || 0;
        acc[key].totalcost += Number(item.totalcost) || 0;
        acc[key].totaldirectlaborhours += Number(item.totaldirectlaborhours) || 0;
        // ...and add this item to the aggregation array.
        acc[key].aggregatedExtensions.push(item);
      }
      return acc;
    }, {});

    // Insert aggregated rows.
    Object.values(aggregated).forEach((aggItem) => {
      rows.push({
        type: "row",
        key: `${aggItem.itemid}-${breakoutKey}`,
        data: aggItem,
      });
    });
  });
  return rows;
}, [groupedItems]);



  // CSV export helper to escape values that contain commas or quotes
  const escapeCSV = (value: string | number): string => {
    const stringValue = value.toString();
    if (stringValue.search(/("|,|\n)/g) >= 0) {
      return `"${stringValue.replace(/"/g, '""')}"`;
    }
    return stringValue;
  };

  // Function to export CSV
  const exportCSV = useCallback(() => {
    // Define the CSV header row. You can adjust the column names as needed.
    let csvContent =
      "Type,Name,Attribute Group,Item Qty,Item UOM,Item Cost Per UOM,Total Material Cost,Hourly Production Rate,Total Labor Hours\n";
    flattenedRows.forEach((row) => {
      if (row.type === "header") {
        // For header rows, we only print the label.
        csvContent += `header,${escapeCSV(row.label)},,,,,,,\n`;
      } else {
        // For data rows, extract the values from the item.
        const item = row.data;
        const effectiveUnitCost = item.isoverrideitemunitcost
          ? item.overrideitemunitcost
          : item.itemunitcost;
        const effectiveProductionRate = item.isoverridehourlyproductionrate
          ? item.overridehourlyproductionrate
          : item.hourlyproductionrate;
        const itemName = item.itemname || "";
        const attributeGroup = item.attributegroupname || "";
        const totalItemQty = item.totalitemqty || "";
        const itemUOM = item.itemuomname || "";
        const totalMaterialCost = item.totalcost || "";
        const totalLaborHours = item.totaldirectlaborhours || "";
        csvContent += `row,${escapeCSV(itemName)},${escapeCSV(
          attributeGroup
        )},${totalItemQty},${escapeCSV(
          itemUOM
        )},${effectiveUnitCost},${totalMaterialCost},${effectiveProductionRate},${totalLaborHours}\n`;
      }
    });
    // Create a blob and trigger a download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "extension_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [flattenedRows]);

  // Virtualized row renderer (applied by react-window)
  const RowRenderer = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const row = flattenedRows[index];
      if (row.type === "header") {
        return (
          <div
            style={{
              ...style,
              backgroundColor: "#f4f4f4",
              display: "flex",
              justifyContent: "center",
              paddingLeft: 8,
            }}
            key={row.key}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {row.label}
            </Typography>
          </div>
        );
      } else {
        return (
          <div style={style} key={row.key}>
            <ExtentionRow
              item={row.data}
              onOpenCreateItemTab={handleOpenDialog}
            />
          </div>
        );
      }
    },
    [flattenedRows, handleOpenDialog]
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Search Input and Export CSV Button */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <TextField
          placeholder="Search Items"
          value={searchTerm}
          onChange={handleSearchChange}
          variant="outlined"
          size="small"
          fullWidth
          sx={{ mr: 2 }}
        />
        <Button variant="contained" onClick={exportCSV}>
          Export CSV
        </Button>
      </Box>

      {/* Table Header */}
      <Box sx={{ border: "1px solid black", backgroundColor: "white" }}>
        <Box sx={{ display: "flex", fontWeight: "bold", padding: "8px" }}>
          <Box sx={{ flex: 2, display: "flex", justifyContent: "center" }}>
            Name
          </Box>
          <Box sx={{ flex: 2, display: "flex", justifyContent: "center" }}>
            Attribute Group
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            Item Qty
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            Item UOM
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            Item Cost Per UOM
          </Box>
          <Box sx={{ flex: 2, display: "flex", justifyContent: "center" }}>
            Total Material Cost: {formatCurrency(extensionMaterialCost)}
          </Box>
          <Box sx={{ flex: 2, display: "flex", justifyContent: "center" }}>
            Hourly Production Rate Per Item UOM
          </Box>
          <Box sx={{ flex: 2, display: "flex", justifyContent: "center" }}>
            Total Labor Hours: {formatNumber(extensionLaborHours)}
          </Box>
          <Box sx={{ flex: 2, display: "flex", justifyContent: "center" }}>
            Actions
          </Box>
        </Box>
      </Box>

      {/* Virtualized Table Body */}
      <Box sx={{ flex: 1, border: "1px solid black", background: "white" }}>
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => (
            <List
              height={height}
              itemCount={flattenedRows.length}
              itemSize={50}
              width={width}
            >
              {RowRenderer}
            </List>
          )}
        </AutoSizer>
      </Box>

      {/* Dialog for Assigning Costs */}
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <AssignCosts itemId={selectedItemId} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
