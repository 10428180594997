// source: changeOrderAssembly.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderAssembly', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderAssemblyItem', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderBreakoutMap', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderItemCost', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderItemQtyFormula', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderPoint', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderQuoteGroup', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies', null, global);
goog.exportSymbol('proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest', null, global);
goog.exportSymbol('proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse', null, global);
goog.exportSymbol('proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest', null, global);
goog.exportSymbol('proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse', null, global);
goog.exportSymbol('proto.changeOrderAssembly.DeleteExtendChangeOrderRequest', null, global);
goog.exportSymbol('proto.changeOrderAssembly.DeleteExtendChangeOrderResponse', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ExtendChangeOrder', null, global);
goog.exportSymbol('proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest', null, global);
goog.exportSymbol('proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse', null, global);
goog.exportSymbol('proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest', null, global);
goog.exportSymbol('proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.repeatedFields_, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.displayName = 'proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderAssembly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.changeOrderAssembly.ChangeOrderAssembly.repeatedFields_, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderAssembly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderAssembly.displayName = 'proto.changeOrderAssembly.ChangeOrderAssembly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderPoint.displayName = 'proto.changeOrderAssembly.ChangeOrderPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ExtendChangeOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.ExtendChangeOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ExtendChangeOrder.displayName = 'proto.changeOrderAssembly.ExtendChangeOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderAssemblyItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderAssemblyItem.displayName = 'proto.changeOrderAssembly.ChangeOrderAssemblyItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderItemCost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderItemCost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderItemCost.displayName = 'proto.changeOrderAssembly.ChangeOrderItemCost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderItemQtyFormula, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderItemQtyFormula.displayName = 'proto.changeOrderAssembly.ChangeOrderItemQtyFormula';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.displayName = 'proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderQuoteGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderQuoteGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderQuoteGroup.displayName = 'proto.changeOrderAssembly.ChangeOrderQuoteGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderBreakoutMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderBreakoutMap.displayName = 'proto.changeOrderAssembly.ChangeOrderBreakoutMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.displayName = 'proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.displayName = 'proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.displayName = 'proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.displayName = 'proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.displayName = 'proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.displayName = 'proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.displayName = 'proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.repeatedFields_, null);
};
goog.inherits(proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.displayName = 'proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.DeleteExtendChangeOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.displayName = 'proto.changeOrderAssembly.DeleteExtendChangeOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.DeleteExtendChangeOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.DeleteExtendChangeOrderResponse.displayName = 'proto.changeOrderAssembly.DeleteExtendChangeOrderResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdbyuserid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdbyaccountid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    assembliesList: jspb.Message.toObjectList(msg.getAssembliesList(),
    proto.changeOrderAssembly.ChangeOrderAssembly.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies}
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies;
  return proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies}
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyuserid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedbyaccountid(value);
      break;
    case 6:
      var value = new proto.changeOrderAssembly.ChangeOrderAssembly;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderAssembly.deserializeBinaryFromReader);
      msg.addAssemblies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedbyuserid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedbyaccountid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAssembliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.changeOrderAssembly.ChangeOrderAssembly.serializeBinaryToWriter
    );
  }
};


/**
 * optional string parentId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.getParentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies} returns this
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.setParentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimateId = 2;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies} returns this
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastUpdated = 3;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies} returns this
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string createdByUserId = 4;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.getCreatedbyuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies} returns this
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.setCreatedbyuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string createdByAccountId = 5;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.getCreatedbyaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies} returns this
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.setCreatedbyaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated ChangeOrderAssembly assemblies = 6;
 * @return {!Array<!proto.changeOrderAssembly.ChangeOrderAssembly>}
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.getAssembliesList = function() {
  return /** @type{!Array<!proto.changeOrderAssembly.ChangeOrderAssembly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.changeOrderAssembly.ChangeOrderAssembly, 6));
};


/**
 * @param {!Array<!proto.changeOrderAssembly.ChangeOrderAssembly>} value
 * @return {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies} returns this
*/
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.setAssembliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.changeOrderAssembly.ChangeOrderAssembly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly}
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.addAssemblies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.changeOrderAssembly.ChangeOrderAssembly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies} returns this
 */
proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.prototype.clearAssembliesList = function() {
  return this.setAssembliesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.changeOrderAssembly.ChangeOrderAssembly.repeatedFields_ = [27,28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderAssembly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderAssembly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderAssembly.toObject = function(includeInstance, msg) {
  var f, obj = {
    assemblyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assemblyname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    objectid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    projectid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 6, ""),
    totalsegmentlength: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalpointcount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalsegmentcount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    totalsqft: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    segmentlength: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    segmentcount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    measuredarea: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    pointcount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    pointtype: jspb.Message.getFieldWithDefault(msg, 15, ""),
    pointsize: jspb.Message.getFieldWithDefault(msg, 16, 0),
    pointbordercolor: jspb.Message.getFieldWithDefault(msg, 17, ""),
    pointfillcolor: jspb.Message.getFieldWithDefault(msg, 18, ""),
    linesize: jspb.Message.getFieldWithDefault(msg, 19, 0),
    linecolor: jspb.Message.getFieldWithDefault(msg, 20, ""),
    linedasharray: jspb.Message.getFieldWithDefault(msg, 21, ""),
    userscale: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 23, 0),
    assemblymeasurementtype: jspb.Message.getFieldWithDefault(msg, 24, ""),
    imageclassificationid: jspb.Message.getFieldWithDefault(msg, 25, 0),
    datecreated: jspb.Message.getFieldWithDefault(msg, 26, ""),
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.changeOrderAssembly.ChangeOrderPoint.toObject, includeInstance),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.changeOrderAssembly.ChangeOrderAssemblyItem.toObject, includeInstance),
    fillopacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderAssembly;
  return proto.changeOrderAssembly.ChangeOrderAssembly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderAssembly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalsegmentlength(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalpointcount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalsegmentcount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalsqft(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSegmentlength(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSegmentcount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeasuredarea(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointcount(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointtype(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointsize(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointbordercolor(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointfillcolor(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLinesize(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinecolor(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinedasharray(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUserscale(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblymeasurementtype(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImageclassificationid(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 27:
      var value = new proto.changeOrderAssembly.ChangeOrderPoint;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderPoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    case 28:
      var value = new proto.changeOrderAssembly.ChangeOrderAssemblyItem;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderAssemblyItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFillopacity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderAssembly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderAssembly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderAssembly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssemblyname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getObjectid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTotalsegmentlength();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalpointcount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTotalsegmentcount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTotalsqft();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getSegmentlength();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getSegmentcount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getMeasuredarea();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getPointcount();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getPointtype();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPointsize();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getPointbordercolor();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPointfillcolor();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getLinesize();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getLinecolor();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getLinedasharray();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getUserscale();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getAssemblymeasurementtype();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getImageclassificationid();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.changeOrderAssembly.ChangeOrderPoint.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      proto.changeOrderAssembly.ChangeOrderAssemblyItem.serializeBinaryToWriter
    );
  }
  f = message.getFillopacity();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
};


/**
 * optional string assemblyId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assemblyName = 2;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getAssemblyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setAssemblyname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string objectId = 3;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getObjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setObjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string projectId = 4;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string estimateId = 5;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lastUpdated = 6;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double totalSegmentLength = 7;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getTotalsegmentlength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setTotalsegmentlength = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int32 totalPointCount = 8;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getTotalpointcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setTotalpointcount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 totalSegmentCount = 9;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getTotalsegmentcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setTotalsegmentcount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional float totalSqft = 10;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getTotalsqft = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setTotalsqft = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float segmentLength = 11;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getSegmentlength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setSegmentlength = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional int32 segmentCount = 12;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getSegmentcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setSegmentcount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional float measuredArea = 13;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getMeasuredarea = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setMeasuredarea = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional int32 pointCount = 14;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPointcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPointcount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string pointType = 15;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPointtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPointtype = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 pointSize = 16;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPointsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPointsize = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string pointBorderColor = 17;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPointbordercolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPointbordercolor = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string pointFillColor = 18;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPointfillcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPointfillcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int32 lineSize = 19;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getLinesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setLinesize = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string lineColor = 20;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getLinecolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setLinecolor = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string lineDashArray = 21;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getLinedasharray = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setLinedasharray = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional double userScale = 22;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getUserscale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setUserscale = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional int32 pageNumber = 23;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional string assemblyMeasurementType = 24;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getAssemblymeasurementtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setAssemblymeasurementtype = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional int32 imageClassificationId = 25;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getImageclassificationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setImageclassificationid = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string dateCreated = 26;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * repeated ChangeOrderPoint points = 27;
 * @return {!Array<!proto.changeOrderAssembly.ChangeOrderPoint>}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.changeOrderAssembly.ChangeOrderPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.changeOrderAssembly.ChangeOrderPoint, 27));
};


/**
 * @param {!Array<!proto.changeOrderAssembly.ChangeOrderPoint>} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
*/
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.changeOrderAssembly.ChangeOrderPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.changeOrderAssembly.ChangeOrderPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};


/**
 * repeated ChangeOrderAssemblyItem items = 28;
 * @return {!Array<!proto.changeOrderAssembly.ChangeOrderAssemblyItem>}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.changeOrderAssembly.ChangeOrderAssemblyItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.changeOrderAssembly.ChangeOrderAssemblyItem, 28));
};


/**
 * @param {!Array<!proto.changeOrderAssembly.ChangeOrderAssemblyItem>} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
*/
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.changeOrderAssembly.ChangeOrderAssemblyItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.changeOrderAssembly.ChangeOrderAssemblyItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional double fillOpacity = 29;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getFillopacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setFillopacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    angle: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    verticallength: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    allowtrailingline: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isincludedincount: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isselected: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint}
 */
proto.changeOrderAssembly.ChangeOrderPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderPoint;
  return proto.changeOrderAssembly.ChangeOrderPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint}
 */
proto.changeOrderAssembly.ChangeOrderPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAngle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVerticallength(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowtrailingline(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsincludedincount(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsselected(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getVerticallength();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAllowtrailingline();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsincludedincount();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsselected();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string pointId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getPointid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setPointid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float x = 2;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float y = 3;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float angle = 4;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float verticalLength = 5;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getVerticallength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setVerticallength = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool allowTrailingLine = 6;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getAllowtrailingline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setAllowtrailingline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool isIncludedInCount = 7;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getIsincludedincount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setIsincludedincount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool isSelected = 8;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getIsselected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setIsselected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool isActive = 9;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ExtendChangeOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ExtendChangeOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ExtendChangeOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    isoverrideitemunitcost: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    overrideitemunitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    isoverridehourlyproductionrate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    overridehourlyproductionrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalitemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    totalmaterialcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totallaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    breakoutid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    extensionid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    estimateid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    assemblyid: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder}
 */
proto.changeOrderAssembly.ExtendChangeOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ExtendChangeOrder;
  return proto.changeOrderAssembly.ExtendChangeOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ExtendChangeOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder}
 */
proto.changeOrderAssembly.ExtendChangeOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsoverrideitemunitcost(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverrideitemunitcost(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsoverridehourlyproductionrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverridehourlyproductionrate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalitemqty(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalmaterialcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotallaborhours(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionid(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ExtendChangeOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ExtendChangeOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ExtendChangeOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsoverrideitemunitcost();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOverrideitemunitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getIsoverridehourlyproductionrate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOverridehourlyproductionrate();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTotalitemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getTotalmaterialcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTotallaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getBreakoutid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getExtensionid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional bool isOverrideItemUnitCost = 1;
 * @return {boolean}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.getIsoverrideitemunitcost = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder} returns this
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.setIsoverrideitemunitcost = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional float overrideItemUnitCost = 2;
 * @return {number}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.getOverrideitemunitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder} returns this
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.setOverrideitemunitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool isOverrideHourlyProductionRate = 3;
 * @return {boolean}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.getIsoverridehourlyproductionrate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder} returns this
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.setIsoverridehourlyproductionrate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional float overrideHourlyProductionRate = 4;
 * @return {number}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.getOverridehourlyproductionrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder} returns this
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.setOverridehourlyproductionrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float totalItemQty = 5;
 * @return {number}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.getTotalitemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder} returns this
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.setTotalitemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float totalMaterialCost = 6;
 * @return {number}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.getTotalmaterialcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder} returns this
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.setTotalmaterialcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float totalLaborHours = 7;
 * @return {number}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.getTotallaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder} returns this
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.setTotallaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool isQuoted = 8;
 * @return {boolean}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder} returns this
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string breakoutId = 9;
 * @return {string}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.getBreakoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder} returns this
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.setBreakoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string extensionId = 10;
 * @return {string}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.getExtensionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder} returns this
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.setExtensionid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 attributeValueId = 11;
 * @return {number}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder} returns this
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string estimateId = 12;
 * @return {string}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder} returns this
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string assemblyId = 13;
 * @return {string}
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ExtendChangeOrder} returns this
 */
proto.changeOrderAssembly.ExtendChangeOrder.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderAssemblyItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    costs: (f = msg.getCosts()) && proto.changeOrderAssembly.ChangeOrderItemCost.toObject(includeInstance, f),
    itemqtyformula: (f = msg.getItemqtyformula()) && proto.changeOrderAssembly.ChangeOrderItemQtyFormula.toObject(includeInstance, f),
    attribute: (f = msg.getAttribute()) && proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.toObject(includeInstance, f),
    quotegroup: (f = msg.getQuotegroup()) && proto.changeOrderAssembly.ChangeOrderQuoteGroup.toObject(includeInstance, f),
    breakout: (f = msg.getBreakout()) && proto.changeOrderAssembly.ChangeOrderBreakoutMap.toObject(includeInstance, f),
    extend: (f = msg.getExtend()) && proto.changeOrderAssembly.ExtendChangeOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderAssemblyItem;
  return proto.changeOrderAssembly.ChangeOrderAssemblyItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemname(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 4:
      var value = new proto.changeOrderAssembly.ChangeOrderItemCost;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderItemCost.deserializeBinaryFromReader);
      msg.setCosts(value);
      break;
    case 5:
      var value = new proto.changeOrderAssembly.ChangeOrderItemQtyFormula;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderItemQtyFormula.deserializeBinaryFromReader);
      msg.setItemqtyformula(value);
      break;
    case 6:
      var value = new proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.deserializeBinaryFromReader);
      msg.setAttribute(value);
      break;
    case 7:
      var value = new proto.changeOrderAssembly.ChangeOrderQuoteGroup;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderQuoteGroup.deserializeBinaryFromReader);
      msg.setQuotegroup(value);
      break;
    case 8:
      var value = new proto.changeOrderAssembly.ChangeOrderBreakoutMap;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderBreakoutMap.deserializeBinaryFromReader);
      msg.setBreakout(value);
      break;
    case 9:
      var value = new proto.changeOrderAssembly.ExtendChangeOrder;
      reader.readMessage(value,proto.changeOrderAssembly.ExtendChangeOrder.deserializeBinaryFromReader);
      msg.setExtend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderAssemblyItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCosts();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.changeOrderAssembly.ChangeOrderItemCost.serializeBinaryToWriter
    );
  }
  f = message.getItemqtyformula();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.changeOrderAssembly.ChangeOrderItemQtyFormula.serializeBinaryToWriter
    );
  }
  f = message.getAttribute();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.serializeBinaryToWriter
    );
  }
  f = message.getQuotegroup();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.changeOrderAssembly.ChangeOrderQuoteGroup.serializeBinaryToWriter
    );
  }
  f = message.getBreakout();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.changeOrderAssembly.ChangeOrderBreakoutMap.serializeBinaryToWriter
    );
  }
  f = message.getExtend();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.changeOrderAssembly.ExtendChangeOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional string itemId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.getItemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.setItemid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string itemName = 2;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.getItemname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.setItemname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isQuoted = 3;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional ChangeOrderItemCost costs = 4;
 * @return {?proto.changeOrderAssembly.ChangeOrderItemCost}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.getCosts = function() {
  return /** @type{?proto.changeOrderAssembly.ChangeOrderItemCost} */ (
    jspb.Message.getWrapperField(this, proto.changeOrderAssembly.ChangeOrderItemCost, 4));
};


/**
 * @param {?proto.changeOrderAssembly.ChangeOrderItemCost|undefined} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
*/
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.setCosts = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.clearCosts = function() {
  return this.setCosts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.hasCosts = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ChangeOrderItemQtyFormula itemQtyFormula = 5;
 * @return {?proto.changeOrderAssembly.ChangeOrderItemQtyFormula}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.getItemqtyformula = function() {
  return /** @type{?proto.changeOrderAssembly.ChangeOrderItemQtyFormula} */ (
    jspb.Message.getWrapperField(this, proto.changeOrderAssembly.ChangeOrderItemQtyFormula, 5));
};


/**
 * @param {?proto.changeOrderAssembly.ChangeOrderItemQtyFormula|undefined} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
*/
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.setItemqtyformula = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.clearItemqtyformula = function() {
  return this.setItemqtyformula(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.hasItemqtyformula = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ChangeOrderAssemblyItemAttribute attribute = 6;
 * @return {?proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.getAttribute = function() {
  return /** @type{?proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} */ (
    jspb.Message.getWrapperField(this, proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute, 6));
};


/**
 * @param {?proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute|undefined} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
*/
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.setAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.clearAttribute = function() {
  return this.setAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.hasAttribute = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ChangeOrderQuoteGroup quoteGroup = 7;
 * @return {?proto.changeOrderAssembly.ChangeOrderQuoteGroup}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.getQuotegroup = function() {
  return /** @type{?proto.changeOrderAssembly.ChangeOrderQuoteGroup} */ (
    jspb.Message.getWrapperField(this, proto.changeOrderAssembly.ChangeOrderQuoteGroup, 7));
};


/**
 * @param {?proto.changeOrderAssembly.ChangeOrderQuoteGroup|undefined} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
*/
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.setQuotegroup = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.clearQuotegroup = function() {
  return this.setQuotegroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.hasQuotegroup = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ChangeOrderBreakoutMap breakout = 8;
 * @return {?proto.changeOrderAssembly.ChangeOrderBreakoutMap}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.getBreakout = function() {
  return /** @type{?proto.changeOrderAssembly.ChangeOrderBreakoutMap} */ (
    jspb.Message.getWrapperField(this, proto.changeOrderAssembly.ChangeOrderBreakoutMap, 8));
};


/**
 * @param {?proto.changeOrderAssembly.ChangeOrderBreakoutMap|undefined} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
*/
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.setBreakout = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.clearBreakout = function() {
  return this.setBreakout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.hasBreakout = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ExtendChangeOrder extend = 9;
 * @return {?proto.changeOrderAssembly.ExtendChangeOrder}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.getExtend = function() {
  return /** @type{?proto.changeOrderAssembly.ExtendChangeOrder} */ (
    jspb.Message.getWrapperField(this, proto.changeOrderAssembly.ExtendChangeOrder, 9));
};


/**
 * @param {?proto.changeOrderAssembly.ExtendChangeOrder|undefined} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
*/
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.setExtend = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.clearExtend = function() {
  return this.setExtend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItem.prototype.hasExtend = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderItemCost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderItemCost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderItemCost.toObject = function(includeInstance, msg) {
  var f, obj = {
    uomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemunitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    hourlyproductionrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    uomname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    uomvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderItemCost;
  return proto.changeOrderAssembly.ChangeOrderItemCost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderItemCost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUomid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemunitcost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHourlyproductionrate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUomname(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUomvalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderItemCost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderItemCost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderItemCost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUomid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemunitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getHourlyproductionrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getUomname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUomvalue();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional int32 uomId = 1;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.getUomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.setUomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float itemUnitCost = 2;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.getItemunitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.setItemunitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float hourlyProductionRate = 3;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.getHourlyproductionrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.setHourlyproductionrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string uomName = 4;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.getUomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.setUomname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float uomValue = 5;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.getUomvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.setUomvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderItemQtyFormula.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.toObject = function(includeInstance, msg) {
  var f, obj = {
    formulaid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    forevery: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    takeoffvariabletype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    and: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    or: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    itemid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    quotegroupid: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderItemQtyFormula;
  return proto.changeOrderAssembly.ChangeOrderItemQtyFormula.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormulaid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemqty(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setForevery(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffvariabletype(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnd(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOr(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemid(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroupid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderItemQtyFormula.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormulaid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getForevery();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTakeoffvariabletype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAnd();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOr();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getItemid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getQuotegroupid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string formulaId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getFormulaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setFormulaid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float itemQty = 2;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getItemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setItemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float forEvery = 3;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getForevery = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setForevery = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string takeoffVariableType = 4;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getTakeoffvariabletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setTakeoffvariabletype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool and = 5;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getAnd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setAnd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool or = 6;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getOr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setOr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string itemId = 7;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getItemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setItemid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool isQuoted = 8;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 attributeValueId = 9;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string quoteGroupId = 10;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getQuotegroupid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setQuotegroupid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attributevaluename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    attributegroupid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    attributegroupname: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute;
  return proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributevaluename(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributegroupid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAttributevaluename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAttributegroupid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAttributegroupname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 attributeValueId = 1;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string attributeValueName = 2;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.getAttributevaluename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.setAttributevaluename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 attributeGroupId = 3;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.getAttributegroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.setAttributegroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string attributeGroupName = 4;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.getAttributegroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.setAttributegroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderQuoteGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderQuoteGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderQuoteGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderQuoteGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quotegroup: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderQuoteGroup}
 */
proto.changeOrderAssembly.ChangeOrderQuoteGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderQuoteGroup;
  return proto.changeOrderAssembly.ChangeOrderQuoteGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderQuoteGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderQuoteGroup}
 */
proto.changeOrderAssembly.ChangeOrderQuoteGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderQuoteGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderQuoteGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderQuoteGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderQuoteGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuotegroup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Id = 1;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderQuoteGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderQuoteGroup} returns this
 */
proto.changeOrderAssembly.ChangeOrderQuoteGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string quoteGroup = 2;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderQuoteGroup.prototype.getQuotegroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderQuoteGroup} returns this
 */
proto.changeOrderAssembly.ChangeOrderQuoteGroup.prototype.setQuotegroup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderBreakoutMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderBreakoutMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    breakoutmapid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    breakoutid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    breakoutname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    multiplier: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderBreakoutMap}
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderBreakoutMap;
  return proto.changeOrderAssembly.ChangeOrderBreakoutMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderBreakoutMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderBreakoutMap}
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutmapid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBreakoutname(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMultiplier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderBreakoutMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderBreakoutMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBreakoutmapid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBreakoutid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBreakoutname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMultiplier();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string breakoutMapId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.prototype.getBreakoutmapid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderBreakoutMap} returns this
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.prototype.setBreakoutmapid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string breakoutId = 2;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.prototype.getBreakoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderBreakoutMap} returns this
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.prototype.setBreakoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string breakoutName = 3;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.prototype.getBreakoutname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderBreakoutMap} returns this
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.prototype.setBreakoutname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 multiplier = 4;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.prototype.getMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderBreakoutMap} returns this
 */
proto.changeOrderAssembly.ChangeOrderBreakoutMap.prototype.setMultiplier = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    takeoffassembly: (f = msg.getTakeoffassembly()) && proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest;
  return proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.deserializeBinaryFromReader);
      msg.setTakeoffassembly(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTakeoffassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChangeOrderTakeoffAssemblies takeoffAssembly = 1;
 * @return {?proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.getTakeoffassembly = function() {
  return /** @type{?proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies} */ (
    jspb.Message.getWrapperField(this, proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies, 1));
};


/**
 * @param {?proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies|undefined} value
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest} returns this
*/
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.setTakeoffassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest} returns this
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.clearTakeoffassembly = function() {
  return this.setTakeoffassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.hasTakeoffassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest} returns this
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse;
  return proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse} returns this
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    takeoffassembly: (f = msg.getTakeoffassembly()) && proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest;
  return proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.deserializeBinaryFromReader);
      msg.setTakeoffassembly(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTakeoffassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChangeOrderTakeoffAssemblies takeoffAssembly = 1;
 * @return {?proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.getTakeoffassembly = function() {
  return /** @type{?proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies} */ (
    jspb.Message.getWrapperField(this, proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies, 1));
};


/**
 * @param {?proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies|undefined} value
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest} returns this
*/
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.setTakeoffassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest} returns this
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.clearTakeoffassembly = function() {
  return this.setTakeoffassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.hasTakeoffassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest} returns this
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse;
  return proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse} returns this
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assemblyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest;
  return proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string assemblyId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest} returns this
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest} returns this
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse;
  return proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse} returns this
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest;
  return proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest} returns this
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest} returns this
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    takeoffassembliesList: jspb.Message.toObjectList(msg.getTakeoffassembliesList(),
    proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse;
  return proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.deserializeBinaryFromReader);
      msg.addTakeoffassemblies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTakeoffassembliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChangeOrderTakeoffAssemblies takeoffAssemblies = 1;
 * @return {!Array<!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies>}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.prototype.getTakeoffassembliesList = function() {
  return /** @type{!Array<!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies, 1));
};


/**
 * @param {!Array<!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies>} value
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse} returns this
*/
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.prototype.setTakeoffassembliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies=} opt_value
 * @param {number=} opt_index
 * @return {!proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.prototype.addTakeoffassemblies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.changeOrderAssembly.ChangeOrderTakeoffAssemblies, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse} returns this
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.prototype.clearTakeoffassembliesList = function() {
  return this.setTakeoffassembliesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.DeleteExtendChangeOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assemblyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.DeleteExtendChangeOrderRequest}
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.DeleteExtendChangeOrderRequest;
  return proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.DeleteExtendChangeOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.DeleteExtendChangeOrderRequest}
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.DeleteExtendChangeOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string assemblyId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.DeleteExtendChangeOrderRequest} returns this
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.DeleteExtendChangeOrderRequest} returns this
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.DeleteExtendChangeOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.DeleteExtendChangeOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.DeleteExtendChangeOrderResponse}
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.DeleteExtendChangeOrderResponse;
  return proto.changeOrderAssembly.DeleteExtendChangeOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.DeleteExtendChangeOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.DeleteExtendChangeOrderResponse}
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.DeleteExtendChangeOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.DeleteExtendChangeOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.DeleteExtendChangeOrderResponse} returns this
 */
proto.changeOrderAssembly.DeleteExtendChangeOrderResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.changeOrderAssembly);
