import React, { useCallback } from "react";
import { useGetDefaultDirectLaborTypes } from "../../hooks/useDefaultCloseoutHooks";
import {
  useCreateDirectLaborChangeOrderCloseoutMutation,
  useCreateDirectLaborCloseoutMutation,
  useGetDirectLaborChangeOrderCloseouts,
  useGetDirectLaborCloseouts,
} from "../../hooks/useCloseoutHooks";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { formatNumber } from "./closeoutHelpers";
import { DirectLaborCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  SelectChangeEvent,
} from "@mui/material";
import { DirectLaborRow } from "./DirectLaborRow";
import { DirectLaborChangeOrderCloseout } from "../../api/protosCompiled/changeOrderCloseout/changeOrderCloseout_pb";

export const DirectLaborHeader = () => {
  //zustand state
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  const selectedChangeOrder = useStore(useUnityBuildStore).selectedChangeOrder;

  // React-query state & hooks
  const { data: laborDefaults } = useGetDefaultDirectLaborTypes();

  const { data: estimateLabor, refetch: refetchEstimateLabor } =
    useGetDirectLaborCloseouts();

  const { data: changeOrderLabor, refetch: refetchChangeOrderLabor } =
    useGetDirectLaborChangeOrderCloseouts();

  const { mutateAsync: createEstimateLabor } =
    useCreateDirectLaborCloseoutMutation();

  const { mutateAsync: createChangeOrderLabor } =
    useCreateDirectLaborChangeOrderCloseoutMutation();

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimateLabor
    : refetchChangeOrderLabor;

  const createLabor = selectedEstimate?.estimateid
    ? createEstimateLabor
    : createChangeOrderLabor;

  const closeout = selectedEstimate?.estimateid
    ? estimateLabor
    : changeOrderLabor;

  const newCloseoutType = selectedEstimate?.estimateid
    ? new DirectLaborCloseout()
    : new DirectLaborChangeOrderCloseout();

  const takeoffId = selectedEstimate?.estimateid
    ? selectedEstimate?.estimateid
    : selectedChangeOrder?.changeorderid ?? "";

  const handleAddDefaultType = useCallback(
    (e: SelectChangeEvent<string>, child: React.ReactNode) => {
      const newValue = e.target.value;
      const defaultLaborType = laborDefaults?.find(
        (laborType) => laborType.name === newValue
      );
      if (defaultLaborType) {
        newCloseoutType.setCloseoutid(defaultLaborType.id);
        newCloseoutType.setEstimateid(takeoffId);
        newCloseoutType.setCloseoutdefaultdirectlaborid(defaultLaborType.id);
        newCloseoutType.setLaborrate(defaultLaborType.laborrate);
        newCloseoutType.setBurdenpercent(defaultLaborType.burdenpercent);
        newCloseoutType.setFringe(defaultLaborType.fringe);
        newCloseoutType.setDistributionpercent(
          defaultLaborType.distributionpercent
        );
        createLabor(newCloseoutType, {
          onSuccess: () => {
            refetch();
          },
        }).catch((err) => console.error("Error creating direct labor:", err));
      }
    },
    // eslint-disable-next-line
    [laborDefaults, selectedEstimate, selectedChangeOrder, createLabor, refetch]
  );

  const totalDistributionPercent = closeout?.closeoutsList.reduce(
    (sum, labor) => sum + labor.distributionpercent,
    0
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FormControl fullWidth variant="filled" sx={{ mb: 1 }}>
        <InputLabel id="labor-select-label">Select Labor Type</InputLabel>
        <Select
          labelId="labor-select-label"
          id="labor-select"
          label="Select Labor Type"
          value={"Select Labor Type"}
          onChange={handleAddDefaultType}
        >
          <MenuItem value="">
            <em>Select Labor Type</em>
          </MenuItem>
          {laborDefaults &&
            laborDefaults.map((laborType) => (
              <MenuItem key={laborType.id} value={laborType.name}>
                {laborType.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {/* Table Container wrapped in a Box that grows */}
      <Box sx={{ flex: 1, overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          sx={{
            height: "100%",
            overflowY: "auto",
            border: "1px solid black",
          }}
        >
          <Table
            stickyHeader
            size="small"
            sx={{ width: "100%", tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>
                  Hours:{" "}
                  {formatNumber(
                    closeout?.closeoutsList[0]?.totaldirectlaborhours || 0
                  )}
                </TableCell>
                <TableCell
                  style={{
                    color: totalDistributionPercent
                      ? totalDistributionPercent < 100
                        ? "red"
                        : totalDistributionPercent > 100
                          ? "orange"
                          : "green"
                      : "black",
                  }}
                >
                  Distribution %:{" "}
                  {formatNumber(Number(totalDistributionPercent))}
                </TableCell>
                <TableCell>Labor Rate</TableCell>
                <TableCell>Subtotal</TableCell>
                <TableCell>Burden %</TableCell>
                <TableCell>Fringe</TableCell>
                <TableCell>Burden Total</TableCell>
                <TableCell>Fringe Total</TableCell>
                <TableCell>Hourly Cost</TableCell>
                <TableCell>Total Cost</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {closeout?.closeoutsList.map((labor, index) => (
                <DirectLaborRow
                  key={labor.closeoutid || index}
                  labor={labor}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DirectLaborHeader;
