import { useState } from "react";
import { ItemService } from "../../../api/GlobalItemService";
import {
  GlobalItem,
  ItemCost,
} from "../../../api/protosCompiled/globalItem/globalItem_pb";
import { useFetchItemsByFileId } from "../../../hooks/useFetchItemsByFileId";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from "@mui/material";

type AssignCostsProps = {
  itemId: string;
};

/**
 * Convert the stored or user-entered UPC into an 11-digit display string.
 *
 * 1) Strip all non-digits.
 * 2) If > 11 digits, keep only the last 11.
 * 3) If < 11 digits, pad left with zeros to length 11.
 */
export function convertUPCToDisplay(
  upcValue: number | string | undefined
): string {
  if (!upcValue) return "";

  // 1) Convert to string, remove non-digits
  let numericStr = String(upcValue).replace(/\D/g, "");

  // 2) If longer than 11, keep last 11
  if (numericStr.length > 11) {
    numericStr = numericStr.slice(numericStr.length - 11);
  }

  // 3) If shorter than 11, pad left with zeros
  numericStr = numericStr.padStart(11, "0");

  return numericStr;
}

/**
 * Convert what the user typed (possibly 8, 10, 12+ digits) to a numeric UPC for the DB.
 *
 * 1) Strip non-digits.
 * 2) If > 11 digits, keep last 11.
 * 3) If < 11 digits, pad left with zeros.
 * 4) Convert to number, or default to 0 if empty/invalid.
 */
export function convertDisplayToUPC(displayUPC: string): number {
  if (!displayUPC) return 0;
  // 1) Remove any non-numeric characters
  let numericStr = displayUPC.replace(/\D/g, "");

  // 2) If longer than 11, keep last 11
  if (numericStr.length > 11) {
    numericStr = numericStr.slice(numericStr.length - 11);
  }

  // 3) If shorter, pad left
  numericStr = numericStr.padStart(11, "0");

  // 4) Parse
  return numericStr ? parseInt(numericStr, 10) : 0;
}

export const AssignCosts = ({ itemId }: AssignCostsProps) => {
  const [editedCosts, setEditedCosts] = useState<{ [key: string]: any }>({});
  // const [editedUPCs, setEditedUPCs] = useState<{ [key: string]: string }>({});

  const { data: items } = useFetchItemsByFileId(itemId);

  // Handle changes to the input fields
  const handleCostInputChange = (
    attributeId: string,
    field: string,
    value: string
  ) => {
    // Only allow valid numeric input or empty input
    if (/^(\d+(\.\d*)?|\.\d*)?$/.test(value)) {
      setEditedCosts((prev) => ({
        ...prev,
        [attributeId]: {
          ...prev[attributeId],
          [field]: value,
        },
      }));
    }
  };

  const handleCostBlur = async (
    item: GlobalItem.AsObject,
    attributeId: string,
    e: any
  ) => {
    e.preventDefault();
    try {
      const matchingCost = item.costsList.find(
        (cost) => cost.itemattributevalueid === parseInt(attributeId)
      );
      if (matchingCost) {
        const editedCost: ItemCost.AsObject = editedCosts[attributeId] || {};

        const updatedCost: ItemCost.AsObject = {
          ...matchingCost,
          itemid: item.itemid,
          itemunitcost:
            editedCost.itemunitcost !== undefined
              ? editedCost.itemunitcost
              : matchingCost.itemunitcost,
          hourlyproductionrate:
            editedCost.hourlyproductionrate !== undefined
              ? editedCost.hourlyproductionrate
              : matchingCost.hourlyproductionrate,
        };
        // Pass only the updated cost object to updateItemCost
        await ItemService.updateItemCost(updatedCost);
      } else {
        console.error("No matching cost found for attributeId:", attributeId);
      }
    } catch (error) {
      console.error("Error updating item costs:", error);
    }
  };

  // Handle changes to the UPC field
  // const handleAttributeUPCValueChange = (
  //   attributeId: string,
  //   value: string
  // ) => {
  //   setEditedUPCs((prev) => ({
  //     ...prev,
  //     [attributeId]: value,
  //   }));
  // };

  // Handle blur for UPC updates
  // const handleAttributeUPCValueBlur = async (
  //   item: GlobalItem.AsObject,
  //   attributeId: string
  // ) => {
  //   try {
  //     const matchingAttribute = item.attributesList.find(
  //       (attr) => attr.attributevalueid.toString() === attributeId
  //     );

  //     if (!matchingAttribute) {
  //       console.error("No matching attribute found:", attributeId);
  //       return;
  //     }

  //     // 1) Convert to numeric (store in DB):
  //     const numericUPC = convertDisplayToUPC(editedUPCs[attributeId]);
  //     await ItemService.updateUPC(
  //       item.itemid,
  //       matchingAttribute.attributevalueid,
  //       numericUPC
  //     );

  //     // 2) For consistent display, convert back to 11-digit string:
  //     setEditedUPCs((prev) => ({
  //       ...prev,
  //       [attributeId]: convertUPCToDisplay(numericUPC),
  //     }));
  //   } catch (error) {
  //     console.error("Error updating attribute UPC:", error);
  //   }
  // };

  return (
    <TableContainer
      component={Paper}
      sx={{
        height: "700px",
        position: "relative",
        border: "1px solid black",
        overflowY: "auto",
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Attribute</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>UOM</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Unit Cost</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              Hourly Production Rate
            </TableCell>
            {/* <TableCell sx={{ fontWeight: "bold" }}>UPC</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((item) =>
            item.attributesList.map((attribute, index) => {
              const matchingCost = item.costsList.find(
                (cost) =>
                  cost.itemattributevalueid === attribute.attributevalueid
              );

              return (
                <TableRow key={`${item.itemid}-${attribute.attributevalueid}`}>
                  <TableCell>
                    {attribute.attributevaluename} - {item.itemname}
                  </TableCell>
                  <TableCell>{matchingCost?.uomname ?? "N/A"}</TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="itemunitcost"
                      value={
                        editedCosts[attribute.attributevalueid]?.itemunitcost ??
                        matchingCost?.itemunitcost?.toFixed(2) ??
                        ""
                      }
                      onChange={(e) =>
                        handleCostInputChange(
                          attribute.attributevalueid.toString(),
                          "itemunitcost",
                          e.target.value
                        )
                      }
                      onBlur={(e) =>
                        handleCostBlur(
                          item,
                          attribute.attributevalueid.toString(),
                          e
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="hourlyproductionrate"
                      value={
                        editedCosts[attribute.attributevalueid]
                          ?.hourlyproductionrate ??
                        matchingCost?.hourlyproductionrate?.toFixed(2) ??
                        ""
                      }
                      onChange={(e) =>
                        handleCostInputChange(
                          attribute.attributevalueid.toString(),
                          "hourlyproductionrate",
                          e.target.value
                        )
                      }
                      onBlur={(e) =>
                        handleCostBlur(
                          item,
                          attribute.attributevalueid.toString(),
                          e
                        )
                      }
                    />
                  </TableCell>
                  {/* <TableCell>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="upc"
                      value={
                        editedUPCs[attribute.attributevalueid] ??
                        attribute.upc ??
                        ""
                      }
                      onChange={(e) =>
                        handleAttributeUPCValueChange(
                          attribute.attributevalueid.toString(),
                          e.target.value
                        )
                      }
                      onBlur={() =>
                        handleAttributeUPCValueBlur(
                          item,
                          attribute.attributevalueid.toString()
                        )
                      }
                    />
                  </TableCell> */}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
