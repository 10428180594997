import { useUnityBuildStore } from "../states/store";
import { ResetStates } from "../states/resetStates";
import { useStore } from "zustand";
import { useAssemblyStore } from "../states/AssemblyStore";
import { useKeyBoardEventStore } from "../states/keyEventStore";

export const useHandleSetCreateStates = () => {
  const store1 = useStore(useUnityBuildStore);
  const assemblyStore = useStore(useAssemblyStore);
  const keyBoardEventStore = useStore(useKeyBoardEventStore);

  return (setStateFunction: Function, bool: boolean) => {
    ResetStates.resetTakeoffStates(store1, assemblyStore, keyBoardEventStore);
    setStateFunction(bool);
  };
};
