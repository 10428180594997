import { useCallback } from "react";
import { useStore } from "zustand";
import { GeneralExpenseRow } from "./GeneralExpenseRow";
import { GeneralExpenseCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { useGetDefaultGeneralExpenseTypes } from "../../hooks/useDefaultCloseoutHooks";
import { useUnityBuildStore } from "../../states/store";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  useCreateGeneralExpenseChangeOrderCloseoutMutation,
  useCreateGeneralExpenseCloseoutMutation,
  useGetGeneralExpenseChangeOrderCloseouts,
  useGetGeneralExpenseCloseouts,
} from "../../hooks/useCloseoutHooks";
import { GeneralExpenseChangeOrderCloseout } from "../../api/protosCompiled/changeOrderCloseout/changeOrderCloseout_pb";

export const GeneralExpenseHeader = () => {
  // Zustand state
  const { selectedEstimate, selectedChangeOrder } =
    useStore(useUnityBuildStore);

  const { data: closeoutDefaults } = useGetDefaultGeneralExpenseTypes();

  const { data: estimateCloseout, refetch: refetchEstimateCloseout } =
    useGetGeneralExpenseCloseouts();

  const { data: changeOrderCloseout, refetch: refetchChangeOrderCloseout } =
    useGetGeneralExpenseChangeOrderCloseouts();

  const { mutateAsync: createEstimateCloseout } =
    useCreateGeneralExpenseCloseoutMutation();

  const { mutateAsync: createChangeOrderCloseout } =
    useCreateGeneralExpenseChangeOrderCloseoutMutation();

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimateCloseout
    : refetchChangeOrderCloseout;

  const createCloseout = selectedEstimate?.estimateid
    ? createEstimateCloseout
    : createChangeOrderCloseout;

  const closeout = selectedEstimate?.estimateid
    ? estimateCloseout
    : changeOrderCloseout;

  const newCloseoutType = selectedEstimate?.estimateid
    ? new GeneralExpenseCloseout()
    : new GeneralExpenseChangeOrderCloseout();

  const takeoffId = selectedEstimate?.estimateid
    ? selectedEstimate?.estimateid
    : selectedChangeOrder?.changeorderid ?? "";

  const handleAddDefaultType = useCallback(
    (e: SelectChangeEvent<string>) => {
      const newValue = e.target.value;
      const defaultLaborType = closeoutDefaults?.find(
        (closeoutDefault) => closeoutDefault.name === newValue
      );
      if (defaultLaborType) {
        newCloseoutType.setCloseoutid(defaultLaborType.id);
        newCloseoutType.setEstimateid(takeoffId);
        newCloseoutType.setCloseoutdefaultgeneralexpenseid(defaultLaborType.id);
        newCloseoutType.setQuantity(0);
        newCloseoutType.setQuantitymultiplier(0);
        newCloseoutType.setUnitcost(defaultLaborType.unitcost);

        createCloseout(newCloseoutType, {
          onSuccess: () => {
            refetch();
          },
        }).catch((err) => console.error("Error creating factored labor:", err));
      }
    },
    // eslint-disable-next-line
    [
      closeoutDefaults,
      selectedEstimate,
      selectedChangeOrder,
      createCloseout,
      refetch,
    ]
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FormControl fullWidth variant="filled" sx={{ mb: 1 }}>
        <InputLabel id="labor-select-label">
          Select General Expense Type
        </InputLabel>
        <Select
          labelId="labor-select-label"
          id="labor-select"
          label="Select General Expense Type"
          value={"Select General Expense Type"}
          onChange={handleAddDefaultType}
        >
          <MenuItem value="" disabled selected hidden>
            Select labor type
          </MenuItem>
          {closeoutDefaults &&
            closeoutDefaults.map((closeoutItem, index) => (
              <MenuItem value={closeoutItem.name} key={index}>
                {closeoutItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {/* Table Container wrapped in a Box that grows */}
      <Box sx={{ flex: 1, overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          sx={{
            height: "100%",
            overflowY: "auto",
            border: "1px solid black",
          }}
        >
          <Table stickyHeader sx={{ width: "100%", tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Quantity Multiplier</TableCell>
                <TableCell>Unit Cost</TableCell>
                <TableCell>Total Cost</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {closeout?.closeoutsList.map((closeoutItem, index) => (
                <GeneralExpenseRow key={index} closeout={closeoutItem} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
