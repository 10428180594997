import { useState } from "react";
import MaximizeIcon from "@mui/icons-material/Maximize";
import { useUnityBuildStore } from "../../states/store";
import { RenderTakeoffList } from "./RenderTakeoffList";
import { useStore } from "zustand";

export function AuditTrail() {

  const showAssemblyWindow = useStore(useUnityBuildStore).showAssemblyWindow;
  const setSelectedFilter = useStore(useUnityBuildStore).setSelectedFilter;
  const setShowAssemblyWindow = useStore(useUnityBuildStore).setShowAssemblyWindow;

  const [searchTerm, setSearchTerm] = useState("");

  const handleSetFilter = (filterType: any) => {
    setSelectedFilter(filterType);
  };

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      {showAssemblyWindow ? (
        <button onClick={() => setShowAssemblyWindow(false)}>
          <MaximizeIcon />
        </button>
      ) : (
        <>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                width: "95%",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          </div>
          {/* Ensure scrolling within the content area */}
          <div style={{ flexGrow: 1, overflowY: "auto" }}>
            <RenderTakeoffList
              searchTerm={searchTerm}
              setFilter={handleSetFilter}
            />
          </div>
        </>
      )}
    </div>
  );
}
