import {
  CreateOrUpdateItemRequest,
  CreateOrUpdateItemResponse,
  GetAllGlobalItemsRequest,
  GetAllGlobalItemsResponse,
  GetAttributesRequest,
  GetAttributesResponse,
  GetItemAttributesRequest,
  GetItemAttributesResponse,
  GetItemsByFileIdRequest,
  GetItemsByFileIdResponse,
  GetItemsRequest,
  GetItemsResponse,
  CreateOrUpdateUoMRequest,
  CreateOrUpdateUoMResponse,
  DeleteUoMRequest,
  DeleteUoMResponse,
  GetUoMsRequest,
  GetUoMsResponse,
  CreateOrUpdateAttributeGroupRequest,
  CreateOrUpdateAttributeGroupResponse,
  DeleteAttributeGroupRequest,
  DeleteAttributeGroupResponse,
  GetAttributeGroupsRequest,
  GetAttributeGroupsResponse,
  CreateOrUpdateAttributeValueRequest,
  CreateOrUpdateAttributeValueResponse,
  DeleteAttributeValueRequest,
  DeleteAttributeValueResponse,
  GetAttributeValuesRequest,
  GetAttributeValuesResponse,
  GlobalItem,
  UoM,
  AttributeGroup,
  AttributeValue,
  ItemCost,
  UpdateItemCostResponse,
  UpdateItemCostRequest,
  AttributeVariable,
  DeleteAttributeVariablesRequest,
  DeleteAttributeVariablesResponse,
  AllowedVariable,
  GetAllowedVariablesResponse,
  GetAllowedVariablesRequest,
  UpdateUPCRequest,
  UpdateUPCResponse,
} from "./protosCompiled/globalItem/globalItem_pb";

import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";

const client = clients.globalItemServiceClient;

export class ItemService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  // createOrUpdateGlobalItem creates or updates a global item in the database
  public static async createOrUpdateGlobalItem(
    item: GlobalItem
  ): Promise<CreateOrUpdateItemResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateOrUpdateItemRequest();
      req.setItem(item);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.createOrUpdateItem(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  // getGlobalItems fetches a list of items by their IDs
  public static async getGlobalItems(
    ids: string[]
  ): Promise<GetItemsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetItemsRequest();
      req.setIdsList(ids);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getGlobalItems(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  // getAllGlobalItems gets all items from the database based on active status
  public static async getAllGlobalItems(
    isActive: boolean
  ): Promise<GetAllGlobalItemsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetAllGlobalItemsRequest();
      req.setIsactive(isActive);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getAllGlobalItems(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async createOrUpdateUoM(
    uom: UoM.AsObject
  ): Promise<CreateOrUpdateUoMResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateOrUpdateUoMRequest();
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      // Convert AsObject back to UoM protobuf message
      const uomMessage = new UoM();
      uomMessage.setId(uom.id);
      uomMessage.setName(uom.name);
      uomMessage.setValue(uom.value);

      req.setUom(uomMessage);

      client.createOrUpdateUoM(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getUoMs(): Promise<GetUoMsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetUoMsRequest();
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getUoMs(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async deleteUoM(
    uomId: number
  ): Promise<DeleteUoMResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteUoMRequest();
      req.setUomid(uomId);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.deleteUoM(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async createOrUpdateAttributeGroup(
    attributeGroup: AttributeGroup.AsObject
  ): Promise<CreateOrUpdateAttributeGroupResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const attributeGroupMessage = new AttributeGroup();
      attributeGroupMessage.setAttributegroupid(
        attributeGroup.attributegroupid
      );
      attributeGroupMessage.setAttributegroupname(
        attributeGroup.attributegroupname
      );
      attributeGroupMessage.setMasterformatid(attributeGroup.masterformatid);

      const req = new CreateOrUpdateAttributeGroupRequest();
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);
      req.setAttributegroup(attributeGroupMessage);
      client.createOrUpdateAttributeGroup(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getAttributeGroups(): Promise<GetAttributeGroupsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetAttributeGroupsRequest();
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getAttributeGroups(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async deleteAttributeGroup(
    attributeGroupId: number
  ): Promise<DeleteAttributeGroupResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteAttributeGroupRequest();
      req.setAttributegroupid(attributeGroupId);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);
      client.deleteAttributeGroup(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async createOrUpdateAttributeValue(
    attributeValue: AttributeValue.AsObject
  ): Promise<CreateOrUpdateAttributeValueResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const attributeValueMessage = new AttributeValue();
      attributeValueMessage.setAttributevalueid(
        attributeValue.attributevalueid
      );
      attributeValueMessage.setAttributevaluename(
        attributeValue.attributevaluename
      );
      attributeValueMessage.setAttributegroupid(
        attributeValue.attributegroupid
      );
      attributeValueMessage.setAttributegroupname(
        attributeValue.attributegroupname
      );
      attributeValueMessage.setUpc(attributeValue.upc);
      attributeValueMessage.setItemattributevaluemapid(
        attributeValue.itemattributevaluemapid
      );

      // Convert AttributeVariable.AsObject to AttributeVariable message
      const attributeVariables = attributeValue.attributevariablesList.map(
        (varObj) => {
          const attrVar = new AttributeVariable();
          attrVar.setItemattributevaluevariablemapid(
            varObj.itemattributevaluevariablemapid
          );
          attrVar.setVariablevalue(varObj.variablevalue);

          // Convert AllowedVariable.AsObject to AllowedVariable message
          const allowedVariable = new AllowedVariable();
          allowedVariable.setId(varObj.variable?.id || 0);
          allowedVariable.setName(varObj.variable?.name || "");
          attrVar.setVariable(allowedVariable);

          return attrVar;
        }
      );

      attributeValueMessage.setAttributevariablesList(attributeVariables);

      const req = new CreateOrUpdateAttributeValueRequest();
      req.setAttributevalue(attributeValueMessage);
      req.setAttributegroupid(attributeValue.attributegroupid); // Ensure group ID in the request
      req.setSessiontoken(ItemService.getMetadata().sessionToken);

      client.createOrUpdateAttributeValue(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getAttributeValues(
    attributeGroupId: number
  ): Promise<GetAttributeValuesResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetAttributeValuesRequest();
      req.setAttributegroupid(attributeGroupId);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getAttributeValues(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async deleteAttributeValue(
    attributeValueId: number
  ): Promise<DeleteAttributeValueResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteAttributeValueRequest();
      req.setAttributevalueid(attributeValueId);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.deleteAttributeValue(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async deleteAttributeVariables(
    attributeValueVariableMapId: number
  ): Promise<DeleteAttributeVariablesResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteAttributeVariablesRequest();
      req.setItemattributevaluemapid(attributeValueVariableMapId);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.deleteAttributeVariables(req, {}, (err, response) => {
        if (err) {
          console.error("Error in deleteAttributeVariables:", err); // Debugging log
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getItemAttributes(
    itemId: string
  ): Promise<GetItemAttributesResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetItemAttributesRequest();
      req.setItemid(itemId);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getItemAttributes(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getAttributes(): Promise<GetAttributesResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetAttributesRequest();
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);
      client.getAttributes(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getItemsByFileId(
    fileId: string
  ): Promise<GetItemsByFileIdResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetItemsByFileIdRequest();
      req.setFileid(fileId);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getItemsByFileId(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateItemCost(
    itemCost: ItemCost.AsObject
  ): Promise<UpdateItemCostResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new UpdateItemCostRequest();
      req.setItemcostid(itemCost.itemcostid);
      req.setItemid(itemCost.itemid);
      req.setUomid(itemCost.uomid);
      req.setAttributevalueid(itemCost.itemattributevalueid);
      req.setItemunitcost(itemCost.itemunitcost);
      req.setHourlyproductionrate(itemCost.hourlyproductionrate);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.updateItemCost(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getAllowedVariables(): Promise<GetAllowedVariablesResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetAllowedVariablesRequest();
      req.setSessiontoken(this.getMetadata().sessionToken);

      client.getAllowedVariables(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateUPC(
    itemId: string,
    attributeValueId: number,
    upc: number
  ): Promise<UpdateUPCResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new UpdateUPCRequest();
      req.setItemid(itemId);
      req.setAttributevalueid(attributeValueId);
      req.setUpc(upc);
      req.setSessiontoken(this.getMetadata().sessionToken);

      client.updateUPC(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }
}
