import { useEffect, useCallback, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { TakeoffSVG } from "./SVGelements/TakeoffSVG";
import { useUnityBuildStore } from "../states/store";
import { ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import "../App.css";
import { useFetchObject } from "../hooks/useFetchObject";
import { HIGH_RES_SCALE } from "../utils/constants";
import { useStore } from "zustand";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

export const DocumentViewport = () => {

  const isDragging = useStore(useUnityBuildStore).isDragging;
  const selectedObject = useStore(useUnityBuildStore).selectedObject;
  const currentPage = useStore(useUnityBuildStore).currentPage;
  const setNumPages = useStore(useUnityBuildStore).setNumPages;
  const setPdfDimensions = useStore(useUnityBuildStore).setPdfDimensions;
  const setCurrentPage = useStore(useUnityBuildStore).setCurrentPage;

  const { data: blobURL, isLoading, isError } = useFetchObject(selectedObject?.gcsobjectname ?? "");
  const transformWrapperRef = useRef<ReactZoomPanPinchRef | null>(null);

  const [containerDimensions, setContainerDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [originalDimensions, setOriginalDimensions] = useState<{ width: number; height: number } | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setContainerDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fileExtension = selectedObject?.gcsobjectname?.split(".").pop()?.toLowerCase();

  /** 
   * Unified function to apply transformations for both PDFs and Images
   */
  const setTransformForContent = useCallback(
    (contentWidth: number, contentHeight: number) => {
      if (transformWrapperRef.current) {
        const { width: containerWidth, height: containerHeight } = containerDimensions;
        const scaleX = containerWidth / contentWidth;
        const scaleY = containerHeight / contentHeight;
        const scale = Math.min(scaleX, scaleY) * 0.9; // Scale down slightly for better fit
        const x = (containerWidth - contentWidth * scale) / 2;
        const y = (containerHeight - contentHeight * scale) / 2;
        transformWrapperRef.current.setTransform(x, y, scale);
      }
    },
    [containerDimensions]
  );

  /**
   * Handle PDF loading and apply the same transformation logic
   */
  const onDocumentLoadSuccess = useCallback(
    (pdf: any) => {
      setNumPages(pdf.numPages);
      setCurrentPage(1);
      pdf.getPage(1).then((page: any) => {
        const viewport = page.getViewport({ scale: 1 });
        const dimensions = { width: viewport.width, height: viewport.height };
        setPdfDimensions(dimensions);
        setOriginalDimensions(dimensions);
        setTransformForContent(dimensions.width, dimensions.height); // Apply same transform logic
      });
    },
    [setNumPages, setCurrentPage, setPdfDimensions, setTransformForContent]
  );

  /**
   * Handle Image Loading with the same transform logic
   */
  useEffect(() => {
    if (["png", "jpg", "jpeg"].includes(fileExtension || "")) {
      if (selectedObject?.width && selectedObject?.height) {
        const dimensions = { width: selectedObject.width, height: selectedObject.height };
        setPdfDimensions(dimensions);
        setOriginalDimensions(dimensions);
        setTransformForContent(dimensions.width, dimensions.height); // Apply same transform logic
      }
    }
  }, [selectedObject, fileExtension, setPdfDimensions, setTransformForContent]);

  let content: JSX.Element;

  if (isLoading) {
    content = <div>Loading...</div>;
  } else if (isError) {
    content = <div>Error loading document</div>;
  } else if (fileExtension === "pdf") {
    if (!originalDimensions) {
      content = (
        <Document file={blobURL} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={currentPage} renderAnnotationLayer={false} scale={1} />
        </Document>
      );
    } else {
      content = (
        <div
          style={{
            width: `${originalDimensions.width}px`,
            height: `${originalDimensions.height}px`,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              transform: `scale(${1 / HIGH_RES_SCALE})`,
              transformOrigin: "top left",
            }}
          >
            <Document file={blobURL} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={currentPage} renderAnnotationLayer={false} scale={HIGH_RES_SCALE} />
            </Document>
          </div>
        </div>
      );
    }
  } else if (["png", "jpg", "jpeg"].includes(fileExtension || "")) {
    content = (
      <div
        style={{
          width: selectedObject?.width ?? "auto",
          height: selectedObject?.height ?? "auto",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          className="document-image"
          src={blobURL as string}
          alt="document"
          style={{
            width: "100%",
            height: "100%",
            display: "block",
            margin: "0 auto",
            pointerEvents: "auto",
          }}
        />
      </div>
    );
  } else {
    content = <div>Unsupported file type</div>;
  }

  return (
    <TransformWrapper
      ref={transformWrapperRef}
      initialScale={1}
      minScale={0.25}
      maxScale={5}
      limitToBounds={false}
      doubleClick={{ disabled: true }}
      panning={{ velocityDisabled: true, disabled: isDragging }}
      wheel={{ smoothStep: 0.002 }}
    >
      {() => (
        <TransformComponent
          wrapperStyle={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            position: "relative",
            backgroundColor: "rgb(124,121,121)",
          }}
          contentStyle={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          {content}
          <TakeoffSVG />
        </TransformComponent>
      )}
    </TransformWrapper>
  );
};
