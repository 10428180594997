import { useEffect } from "react";
import { ListEstimates } from "./estimateAndChangeOrders/ListEstimates";
import { DocumentViewport } from "./DocumentViewport";
import { useUnityBuildStore } from "../states/store";
import { PointEditor } from "./SVGelements/PointEditor";
import { ColorAndStyleEditor } from "./SVGelements/ColorAndStyleEditor";
import { useStore } from "zustand";
import { CloseoutTabs } from "./closeout/CloseoutTabs";
import { ToolBar } from "./ToolBar";
import { ObjectService } from "../api/ObjectService";
import { ListChangeOrders } from "./estimateAndChangeOrders/ListChangeOrders";
import { Button, Dialog, DialogContent, styled } from "@mui/material";
import TakeoffPallette from "./takeoffPallette/TakeoffPalletteTab";
import { useAssemblyStore } from "../states/AssemblyStore";
import ChatWindow from "./ChatWindow";
import chatBotImage from "../images/ChatBot.png";
import { useKeyBoardEventStore } from "../states/keyEventStore";

export function MasterProjectView() {

  const selectedObject = useStore(useUnityBuildStore).selectedObject;
  const currentPage = useStore(useUnityBuildStore).currentPage;
  const userBackgroundColor = useStore(useUnityBuildStore).userBackgroundColor;
  const selectedProject = useStore(useUnityBuildStore).selectedProject;
  const isOpeningViewport = useStore(useUnityBuildStore).isOpeningViewport;
  const isEditingPoint = useStore(useUnityBuildStore).isEditingPoint;
  const isEditingStyle = useStore(useUnityBuildStore).isEditingStyle;
  const isViewingEstimateList = useStore(useUnityBuildStore).isViewingEstimateList;
  const isAnnotating = useStore(useUnityBuildStore).isAnnotating;
  const isViewingExtension = useStore(useUnityBuildStore).isViewingExtension;
  const isViewingChangeOrderList = useStore(useUnityBuildStore).isViewingChangeOrderList;
  const selectedContract = useStore(useUnityBuildStore).selectedContract;
  const selectedChangeOrder = useStore(useUnityBuildStore).selectedChangeOrder;
  const countedAssemblies = useStore(useUnityBuildStore).countedAssemblies;
  const setFilteredCountedAssemblies = useStore(useUnityBuildStore).setFilteredCountedAssemblies;
  const setIsViewingExtension = useStore(useUnityBuildStore).setIsViewingExtension;

  const isViewingTakeoffPallette = useStore(useKeyBoardEventStore).isViewingTakeoffPallette;
  const showChatWindow = useStore(useKeyBoardEventStore).showChatWindow;
  const setShowChatWindow = useStore(useKeyBoardEventStore).setShowChatWindow;

  const currentTakeoffAssembly = useStore(useAssemblyStore).currentTakeoffAssembly;

  useEffect(() => {
    if (countedAssemblies && selectedObject && currentPage) {
      const assemblies = ObjectService.filterAssemblyByObjectIdandPageNumber(
        countedAssemblies,
        selectedObject.id,
        currentPage
      );
      setFilteredCountedAssemblies(assemblies);
    }
  }, [
    countedAssemblies,
    selectedObject,
    setFilteredCountedAssemblies,
    currentPage,
  ]);

  return (
    <>
      {isViewingEstimateList && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "50%" }}>
            <ListEstimates />
          </div>
          {(!selectedContract?.contractid ||
            !selectedChangeOrder?.changeorderid) && (
              <div style={{ width: "50%" }}>
                {/* <ListContracts /> */}
                Coming Soon! Contract & Change Order Management
              </div>
            )}
        </div>
      )}

      {selectedProject.id && isOpeningViewport && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          {/* ToolBar at the top */}
          <div style={{ flexShrink: 0, zIndex: 10 }}>
            <ToolBar />
            {/* TakeoffPallette positioned correctly */}
            {isViewingTakeoffPallette && (
              <div
                className="TakeoffPalletteContainer"
                style={{
                  backgroundColor: userBackgroundColor,
                }}
              >
                <TakeoffPallette />
              </div>
            )}
          </div>

          {/* Chat Window Icon */}
          <div
            style={{
              position: "absolute",
              bottom: 10,
              left: 10,
              zIndex: 10,
              padding: 2,
            }}
          >
            <StyledButton onClick={() => setShowChatWindow(!showChatWindow)}>
              <img
                src={chatBotImage}
                alt="Chat Window Icon"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "40%",
                  objectFit: "cover",
                }}
              />
            </StyledButton>

          </div>
          {showChatWindow && <div style={{
            zIndex: 9,
            position: "absolute",
            bottom: 65,
            left: 10,
            padding: 2,
          }}><ChatWindow />
          </div>
          }

          {/* Content area */}
          <div style={{ display: "flex", flexGrow: 1 }}>
            {/* DocumentViewport takes the remaining width */}
            <div style={{ flexGrow: 1, overflow: "hidden" }}>
              <DocumentViewport />

            </div>
          </div>
        </div>
      )}

      {isEditingPoint && currentTakeoffAssembly && <PointEditor />}
      {isAnnotating === false && isEditingStyle && currentTakeoffAssembly && (
        <ColorAndStyleEditor />
      )}
      <Dialog
        open={isViewingExtension}
        onClose={() => setIsViewingExtension(false)}
        fullScreen={true}
        style={{
          height: "100vh",
          backgroundColor: userBackgroundColor,
        }}
      >
        <DialogContent>
          <CloseoutTabs />
        </DialogContent>
      </Dialog>
      {isViewingExtension && <CloseoutTabs />}
      {isViewingChangeOrderList && <ListChangeOrders />}
    </>
  );
}


export const StyledButton = styled(Button)({
  width: 50,
  height: 50,
  borderRadius: "50%",
  padding: 5,
  minWidth: "unset",
  backgroundColor: "#f5f5f5",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#e0e0e0",
    transform: "scale(1.1)",
    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
  },
});
