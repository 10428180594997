import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { SubcontractRowProps } from "./closeoutTypes";
import {
  useUpdateSubcontractCloseoutMutation,
  useDeleteSubcontractCloseoutMutation,
  useGetSubcontractCloseouts,
  useDeleteSubcontractChangeOrderCloseoutMutation,
  useGetSubcontractChangeOrderCloseouts,
  useUpdateSubcontractChangeOrderCloseoutMutation,
} from "../../hooks/useCloseoutHooks";
import { formatCurrency, formatNumber } from "./closeoutHelpers";
import { SubcontractCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { Button, TableCell, TableRow, TextField } from "@mui/material";
import { useUnityBuildStore } from "../../states/store";
import { useStore } from "zustand";

export const SubcontractRow: React.FC<SubcontractRowProps> = ({ closeout }) => {
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  const [quotedCost, setQuotedCost] = useState<string>(
    formatCurrency(closeout.quotedcost)
  );

  const [adjustedPercent, setAdjustedPercent] = useState<string>(
    formatNumber(closeout.adjustedpercent)
  );

  const { mutateAsync: updateEstimateCloseout } =
    useUpdateSubcontractCloseoutMutation();
  const { mutateAsync: deleteEstimateCloseout } =
    useDeleteSubcontractCloseoutMutation();
  const { refetch: refetchEstimateCloseout } = useGetSubcontractCloseouts();

  const { mutateAsync: updateChangeOrderCloseout } =
    useUpdateSubcontractChangeOrderCloseoutMutation();
  const { mutateAsync: deleteChangeOrderCloseout } =
    useDeleteSubcontractChangeOrderCloseoutMutation();
  const { refetch: refetchChangeOrderCloseout } =
    useGetSubcontractChangeOrderCloseouts();

  const updateCloseout = selectedEstimate?.estimateid
    ? updateEstimateCloseout
    : updateChangeOrderCloseout;

  const deleteCloseout = selectedEstimate?.estimateid
    ? deleteEstimateCloseout
    : deleteChangeOrderCloseout;

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimateCloseout
    : refetchChangeOrderCloseout;

  useEffect(() => {
    setQuotedCost(formatCurrency(closeout.quotedcost));
    setAdjustedPercent(formatNumber(closeout.adjustedpercent));
  }, [closeout]);

  type Field = keyof Pick<
    SubcontractCloseout.AsObject,
    "quotedcost" | "adjustedpercent"
  >;

  const handleFieldChange = useCallback(
    async (field: Field, value: string) => {
      // Allow only numbers with up to 2 decimals.
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!regex.test(value) || Number(value) > 99999999.99) return;
      await updateCloseout(
        {
          ...closeout,
          [field]: value,
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    },
    [closeout, updateCloseout, refetch]
  );

  const handleDelete = useCallback(() => {
    deleteCloseout(closeout.closeoutid, {
      onSuccess: () => {
        refetch();
      },
    });
  }, [closeout, deleteCloseout, refetch]);

  return (
    <TableRow>
      {/* Description */}
      <TableCell>{closeout.name}</TableCell>
      {/* Quoted Cost */}
      <TableCell>
        <TextField
          type="text"
          value={quotedCost}
          onBlur={(e) => handleFieldChange("quotedcost", e.target.value)}
          onChange={(e) => setQuotedCost(e.target.value)}
        />
      </TableCell>
      {/* Adjusted Percent */}
      <TableCell>
        <TextField
          type="text"
          value={adjustedPercent}
          onBlur={(e) => handleFieldChange("adjustedpercent", e.target.value)}
          onChange={(e) => setAdjustedPercent(e.target.value)}
        />
      </TableCell>
      {/* Total Cost */}
      <TableCell>{formatCurrency(closeout.totalcost)}</TableCell>
      <Button onClick={handleDelete} style={{ width: "40px" }}>
        <DeleteIcon />
      </Button>
    </TableRow>
  );
};
