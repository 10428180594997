import { useState } from "react";
import { DefaultCloseoutTypeKey } from "./CloseoutTypes";
import { DEFAULT_CLOSEOUT_TYPE_OPTIONS } from "./CloseoutTypes";
import { renderTable } from "./closetoutHelpers";
import { MenuItem, Select, Table, TableBody } from "@mui/material";

export const CloseoutSettings = () => {
  const [selectedType, setSelectedType] =
    useState<DefaultCloseoutTypeKey>("directlaborList");
  const [isClearingFocus, setIsClearingFocus] = useState(false);

  const clearFocus = () => {
    setIsClearingFocus(true);
    const activeElement = document.activeElement;
    if (activeElement instanceof HTMLElement) {
      activeElement.blur(); // Clear focus from the currently active element
    }
    setTimeout(() => setIsClearingFocus(false), 0); // Reset after clearing
  };

  const handleChange = (e: any) => {
    const newValue = e.target.value as DefaultCloseoutTypeKey;
    setSelectedType(newValue);
  };

  return (
    <div
      style={{
        width: "95%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Select value={selectedType} onFocus={clearFocus} onChange={handleChange}>
        {DEFAULT_CLOSEOUT_TYPE_OPTIONS.map((opt) => (
          <MenuItem key={opt.key} value={opt.key}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
      <Table>
        <TableBody>
          {renderTable(selectedType, clearFocus, isClearingFocus)}
        </TableBody>
      </Table>
    </div>
  );
};
