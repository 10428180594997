import React, { useEffect, useState } from "react";
import { useUnityBuildStore } from "../../../states/store";
import { useStore } from "zustand";
import { Inference } from "../../../hooks/inferenceHooks";
import { FormulaHeader } from "./FormulaHeader";

import {
  CreateAssemblyProps,
  Foldertype,
} from "../../../types/FolderTypes";
import {
  useCreateGlobalAssemblyMutation,
  useUpdateGlobalAssemblyMutation,
} from "../../../hooks/useFetchAssembliesByFileId";
import { useSubmitTakeoffAssembly } from "../../../hooks/useSubmitTakeoffAssembly";

import { useFetchUser } from "../../../hooks/useFetchUsers";
import { userIsDevAccount } from "../../../utils/accessControl";
import { UB_Account_User } from "../../../api/protosCompiled/ub_account/ub_account_pb";

import {
  useGlobalItemStore,
} from "../../../states/globalItemStore";
import {
  defaultGlobalAssembly,
  useAssemblyStore,
} from "../../../states/AssemblyStore";
import { useFolderStore } from "../../../states/folderStore";
import { GlobalAssembly } from "../../../api/protosCompiled/globalAssembly/globalAssembly_pb";
import ItemTab from "../items/ItemTab";
import { CreateItemTab } from "../items/CreateItemTab";
import { AddedItemRow } from "../items/AddedItemRow";
import { BreakoutSelector } from "../../takeoffPallette/BreakoutSelector";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ProjectEstimateAssemblyService as EstimateAssemblyService } from "../../../api/ProjectEstimateAssemblyService";
import { ContractChangeOrderAssemblyService as ChangeOrderAssemblyService } from "../../../api/ContractChangeOrderAssemblyService";
import { useHandleGlobalStateState } from "../../../hooks/useHandleGlobalState";
import { TakeoffAssembly } from "../../../types/AssemblyItemType";
import { StyledButton } from "../../MasterProjectView";
import chatBotImage from "../../../images/ChatBot.png";
import ChatWindow from "../../ChatWindow";
import { useKeyBoardEventStore } from "../../../states/keyEventStore";

export function CreateAssembly({
  assemblyOption,
  currentAssembly,
}: CreateAssemblyProps) {
  //zustand states
  const { userBackgroundColor, selectedEstimate, selectedChangeOrder, userScale } =
    useStore(useUnityBuildStore);

  const { showChatWindow, setShowChatWindow } = useStore(useKeyBoardEventStore);

  const {
    isCreatingAssembly,
    isEditMode,
    setIsEditMode,
    setAssemblyOption,
    setIsViewingAssemblyForm,
    setIsCreatingAssembly,
    setCurrentGlobalAssembly,
    setCurrentTakeoffAssembly,
  } = useStore(useAssemblyStore);

  const {
    isViewingItemForm,
    isCreatingItem,
    setIsCreatingItem,
    setIsViewingItemForm,
    setCurrentItem,
  } = useStore(useGlobalItemStore);

  const { currentFolder, setCurrentFolder } = useStore(useFolderStore);

  //react-query states
  const { data: user } = useFetchUser();
  const { data: classifications } = Inference.useFetchClass();
  const submitAssembly = useSubmitTakeoffAssembly();

  //react-query mutations
  const { mutateAsync: createGlobalAssembly } =
    useCreateGlobalAssemblyMutation();
  const { mutateAsync: updateGlobalAssembly } =
    useUpdateGlobalAssemblyMutation();

  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [assemblyName, setAssemblyName] = useState("");
  const [takeoffType, setTakeoffType] = useState("");
  const [classification, setClassification] = useState("");

  const handleSetViewingState = useHandleGlobalStateState();

  //effect to set the form to the current item
  useEffect(() => {
    if (currentAssembly) {
      setAssemblyName(currentAssembly?.assemblyname);
      setTakeoffType(currentAssembly?.assemblymeasurementtype);
      setClassification(
        currentAssembly?.imageclassificationid?.toString()
      );
    } else {
      //set to a default global assembly
      setCurrentGlobalAssembly(defaultGlobalAssembly);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAssembly]);

  /**
  * Handles the form state and what we can do in a given state
  */
  const handleItemOrAssemblyMode = () => {
    if (isCreatingAssembly) {
      setIsCreatingAssembly(false);
      setIsCreatingItem(true);
      setIsViewingItemForm(true);
    } else if (!isCreatingAssembly) {
      setIsCreatingAssembly(true);
      setIsCreatingItem(false);
      setIsViewingItemForm(false);
    }
    setIsEditMode(false);
    setCurrentItem(null);
    setCurrentTakeoffAssembly(null);
    setCurrentGlobalAssembly(null);
    setCurrentFolder(null);
  };

  const handleSetAssemblyName = (assemblyname: string) => {
    setAssemblyName(assemblyname);
    if (currentAssembly && assemblyOption === "global") {
      setCurrentGlobalAssembly((prev) =>
        prev
          ? {
            ...prev,
            assemblyname,
          }
          : null
      );
    } else if (currentAssembly && assemblyOption === "takeoff") {
      setCurrentTakeoffAssembly((prev) =>
        prev
          ? {
            ...prev,
            assemblyname,
          }
          : null
      );
    }
  };

  const handleMeasurementTypeChange = (e: any) => {
    const value = e.target.value;
    if (currentAssembly && assemblyOption === "global") {
      setCurrentGlobalAssembly((prev) =>
        prev
          ? {
            ...prev,
            assemblymeasurementtype: value,
          }
          : null
      );
    } else if (currentAssembly && assemblyOption === "takeoff") {
      setCurrentTakeoffAssembly((prev) =>
        prev
          ? {
            ...prev,
            assemblymeasurementtype: value,
          }
          : null
      );
    }
  };

  const handleClassificationChange = (e: any) => {
    const value = Number(e.target.value);
    if (currentAssembly && assemblyOption === "global") {
      setCurrentGlobalAssembly((prev) =>
        prev
          ? {
            ...prev,
            imageclassificationid: value,
          }
          : null
      );
    } else if (currentAssembly && assemblyOption === "takeoff") {
      setCurrentTakeoffAssembly((prev) =>
        prev
          ? {
            ...prev,
            imageclassificationid: value,
          }
          : null
      );
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!shouldSubmit) return;

    if (
      currentAssembly?.itemsList.length === 0
    ) {
      alert("Please add items to your assembly");
      return;
    }
    try {
      if (currentAssembly && assemblyOption === "global") {
        if (!isCreatingAssembly) {
          await updateGlobalAssembly(currentAssembly as GlobalAssembly.AsObject);
        } else if (isCreatingAssembly) {
          const entityType: Foldertype = "assembly";
          const newAssembly: GlobalAssembly.AsObject = {
            ...currentAssembly as GlobalAssembly.AsObject,
            assemblymeasurementtype:
              currentAssembly.assemblymeasurementtype || "count",
            isfile: true,
            imageclassificationid:
              currentAssembly.imageclassificationid || 1,
            entitytype: entityType,
            parentid: currentFolder?.folderid || "",
            level: currentFolder ? currentFolder.level + 1 : 0,
          };

          await createGlobalAssembly(newAssembly);
        }
      } else if (!isCreatingAssembly && assemblyOption === "takeoff") {
        if (currentAssembly) {
          await submitAssembly({
            assembly: currentAssembly as TakeoffAssembly,
            shouldUpdateBreakout: true,
            isUpdating: true,
            isCreating: false,
            pageScale: userScale,
          });
          if (selectedEstimate) {
            // Delete the extension related to the assembly id on update
            // This prevents scenarios where deleteing an item from an assembly results
            // in those items being stranded in the extend_estimate table
            // it will be re-created new when the extenstion is viewed again
            EstimateAssemblyService.deleteExtendEstimate(
              currentAssembly?.assemblyid
            );
          } else if (selectedChangeOrder) {
            ChangeOrderAssemblyService.deleteExtendChangeOrder(
              currentAssembly?.assemblyid
            );
          }
        }
      }
    } catch (error) {
      console.error("Error creating or updating assembly:", error);
    }

    handleSetViewingState({
      resetOption: "resetAssembly",
      setStateFunctions: [
        {
          setStateFunction: setAssemblyName,
          prop: "",
        },
        {
          setStateFunction: setIsCreatingAssembly,
          prop: true,
        },
        {
          setStateFunction: setAssemblyOption,
          prop: "global",
        },
        {
          setStateFunction: setIsEditMode,
          prop: false,
        },
      ],
    });
  };

  const handleClickSubmit = () => {
    setShouldSubmit(true);
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleSetViewingState({
      resetOption: "resetAssembly",
      setStateFunctions: [
        {
          setStateFunction: setIsViewingAssemblyForm,
          prop: false,
        },
        {
          setStateFunction: setIsEditMode,
          prop: false,
        },
      ],
    });
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          backgroundColor: userBackgroundColor,
        }}
      >
        {/* Chat Button */}
        <Box
          sx={{
            position: "absolute",
            bottom: 20,
            left: 20,
            zIndex: 10,
          }}
        >
          <StyledButton onClick={() => setShowChatWindow(!showChatWindow)}>
            <img
              src={chatBotImage}
              alt="Chat Window Icon"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </StyledButton>
          {showChatWindow && <div style={{
            zIndex: 9,
            position: "absolute",
            bottom: 65,
            left: 10,
            padding: 2,
          }}><ChatWindow />
          </div>
          }
        </Box>

        <form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          className="assemblyForm"
        >
          {/* Input and Select Fields */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TextField
              id="outlined-required"
              label="Required Name"
              value={assemblyName}
              onChange={(e) => handleSetAssemblyName(e.target.value)}
              style={{ width: "50%", margin: "10px" }}
              required
            />
            <FormControl style={{ margin: "10px", width: "50%" }}>
              <InputLabel id="demo-simple-select-label">
                Takeoff Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Takeoff Type"
                value={takeoffType}
                onChange={handleMeasurementTypeChange}
              >
                <MenuItem value={"count"}>Count</MenuItem>
                <MenuItem value={"length"}>Length</MenuItem>
                <MenuItem value={"area"}>Area</MenuItem>
              </Select>
            </FormControl>

            {user &&
              !userIsDevAccount(
                user?.ubaccountid,
                user?.user as UB_Account_User.AsObject
              ) && (
                <>
                  <FormControl style={{ margin: "10px", width: "50%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Classification
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Classification"
                      value={classification}
                      onChange={handleClassificationChange}
                    >
                      {/* Add "N/A" option at the top */}
                      <MenuItem value="">N/A</MenuItem>

                      {/* Render the rest of the classifications */}
                      {classifications?.map((classification, index) => (
                        <MenuItem value={classification.id} key={index}>
                          {classification.classname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            {/*breakout selection*/}
            {currentAssembly && (
              <BreakoutSelector
                currentTakeoffAssembly={currentAssembly as TakeoffAssembly}
                context="createAssembly"
              />
            )}
            <div style={{ display: "flex" }}>
              <Button
                type="button"
                color={
                  isCreatingAssembly
                    ? "primary"
                    : isCreatingItem
                      ? "secondary"
                      : "error"
                }
                variant="contained"
                onClick={handleItemOrAssemblyMode}
                style={{ margin: "5px", width: "200px" }}
              >
                {isCreatingAssembly
                  ? "Assembly Mode"
                  : isCreatingItem
                    ? "Item Mode"
                    : `Edit Mode`}
              </Button>
              <Button
                type="submit"
                color="success"
                variant="contained"
                onClick={handleClickSubmit}
                style={{ margin: "5px", width: "200px" }}
              >
                Save Assembly
              </Button>

              <Button
                type="button"
                onClick={handleClose}
                color="error"
                variant="contained"
                style={{ margin: "5px", width: "200px" }}
              >
                Close
              </Button>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", margin: "10px" }}>
            <span>
              <strong>
                {currentFolder && !isEditMode
                  ? `Creating an ${currentFolder.entitytype} in folder: ${currentFolder.name}`
                  : !currentFolder && !isEditMode
                    ? `Navigate to the ${isCreatingAssembly ? "assembly tab, then select a folder to create an assembly" : "item tab, then select a folder to create an item"}`
                    : isEditMode
                      ? `Editing an item or assembly`
                      : 'We are doing something else'}
              </strong>
            </span>
          </div>

          <div style={{ display: "flex", flexDirection: "row", height: "95%" }}>
            <div
              className="scrollableTableContainer"
              style={{
                width: "50%",
                overflowY: "auto",
                height: "99%",
                border: "1px solid black",
              }}
            >
              <ItemTab />
            </div>

            {/* Conditionally show CreateItemTab OR ItemQtyFormulas */}
            {isViewingItemForm ? (
              // If the user wants to create/edit an item, show <CreateItemTab> in the right pane
              <div
                className="scrollableTableContainer"
                style={{
                  width: "50%",
                  overflowY: "auto",
                  height: "99%",
                  border: "1px solid black",
                }}
              >
                <CreateItemTab />
              </div>
            ) : (
              <div
                className="scrollableTableContainer"
                style={{
                  width: "50%",
                  overflowY: "auto",
                  height: "100%",
                  border: "1px solid black",
                }}
              >
                <table>
                  <thead>
                    <FormulaHeader />
                  </thead>
                  <tbody>
                    {currentAssembly ? (
                      currentAssembly.itemsList?.length > 0 ? (
                        currentAssembly?.itemsList.map((item, index) => (
                          <AddedItemRow
                            key={`${item.itemid}-${index}`}
                            index={index}
                            item={item}
                          />
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6}>No items in global assembly</td>
                        </tr>
                      )
                    ) : currentAssembly ? ((
                      <tr>
                        <td colSpan={6}>No items in takeoff assembly</td>
                      </tr>
                    )
                    ) : (
                      <tr>
                        <td colSpan={6}>No assembly selected</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
