import { useCallback } from "react";
import { LaborFactoringRow } from "./LaborFactoringRow";
import { LaborFactoringCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { useStore } from "zustand";
import { useGetDefaultLaborFactoringTypes } from "../../hooks/useDefaultCloseoutHooks";
import {
  useCreateLaborFactoringChangeOrderCloseoutMutation,
  useCreateLaborFactoringCloseoutMutation,
  useGetLaborFactoringChangeOrderCloseouts,
  useGetLaborFactoringCloseouts,
} from "../../hooks/useCloseoutHooks";
import { useUnityBuildStore } from "../../states/store";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { LaborFactoringChangeOrderCloseout } from "../../api/protosCompiled/changeOrderCloseout/changeOrderCloseout_pb";

export const LaborFactoringHeader = () => {
  // Zustand state
  const { selectedEstimate, selectedChangeOrder } =
    useStore(useUnityBuildStore);

  // React-query state & hooks
  const { data: laborDefaults } = useGetDefaultLaborFactoringTypes();

  const { data: estimateLabor, refetch: refetchEstimateLaborCloseout } =
    useGetLaborFactoringCloseouts();

  const { data: changeOrderLabor, refetch: refetchChangeOrderLaborCloseout } =
    useGetLaborFactoringChangeOrderCloseouts();

  const { mutateAsync: createEstimateLabor } =
    useCreateLaborFactoringCloseoutMutation();

  const { mutateAsync: createChangeOrderLabor } =
    useCreateLaborFactoringChangeOrderCloseoutMutation();

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimateLaborCloseout
    : refetchChangeOrderLaborCloseout;

  const createLabor = selectedEstimate?.estimateid
    ? createEstimateLabor
    : createChangeOrderLabor;

  const closeout = selectedEstimate?.estimateid
    ? estimateLabor
    : changeOrderLabor;

  const newCloseoutType = selectedEstimate?.estimateid
    ? new LaborFactoringCloseout()
    : new LaborFactoringChangeOrderCloseout();

  const takeoffId = selectedEstimate?.estimateid
    ? selectedEstimate?.estimateid
    : selectedChangeOrder?.changeorderid ?? "";

  const handleAddDefaultType = useCallback(
    (e: SelectChangeEvent<string>) => {
      const newValue = e.target.value;
      const defaultLaborType = laborDefaults?.find(
        (laborType) => laborType.name === newValue
      );
      if (defaultLaborType) {
        newCloseoutType.setCloseoutid(defaultLaborType.id);
        newCloseoutType.setEstimateid(takeoffId);
        newCloseoutType.setCloseoutdefaultlaborfactoringid(defaultLaborType.id);
        newCloseoutType.setLaborrate(defaultLaborType.laborrate);
        newCloseoutType.setBurdenpercent(defaultLaborType.burdenpercent);
        newCloseoutType.setFringe(defaultLaborType.fringe);
        newCloseoutType.setImpactpercent(defaultLaborType.impactpercent);
        newCloseoutType.setLaborpercent(defaultLaborType.laborpercent);
        createLabor(newCloseoutType, {
          onSuccess: () => {
            refetch();
          },
        }).catch((err) => console.error("Error creating factored labor:", err));
      }
    },
    // eslint-disable-next-line
    [laborDefaults, selectedEstimate, selectedChangeOrder, createLabor, refetch]
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FormControl fullWidth variant="filled" sx={{ mb: 1 }}>
        <InputLabel id="labor-select-label">Select Labor Type</InputLabel>
        <Select
          labelId="labor-select-label"
          id="labor-select"
          label="Select Labor Type"
          value={"Select Labor Type"}
          onChange={handleAddDefaultType}
        >
          <MenuItem value="">
            <em>Select Labor Type</em>
          </MenuItem>
          {laborDefaults &&
            laborDefaults
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((laborType) => (
                <MenuItem key={laborType.id} value={laborType.name}>
                  {laborType.name}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      {/* Table Container wrapped in a Box that grows */}
      <Box sx={{ flex: 1, overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          sx={{
            height: "100%",
            overflowY: "auto",
            border: "1px solid black",
          }}
        >
          <Table stickyHeader sx={{ width: "100%", tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Allocated Hours</TableCell>
                <TableCell>% of Direct Labor Hours</TableCell>
                <TableCell>% of Direct Labor Impact</TableCell>
                <TableCell>Labor Rate</TableCell>
                <TableCell>Subtotal</TableCell>
                <TableCell>Burden %</TableCell>
                <TableCell>Fringe</TableCell>
                <TableCell>Burden Total</TableCell>
                <TableCell>Fringe Total</TableCell>
                <TableCell>Hourly Cost</TableCell>
                <TableCell>Total Cost</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {closeout?.closeoutsList.map((closeoutItem, index) => (
                <LaborFactoringRow
                  key={`${index}-${closeoutItem.closeoutdefaultlaborfactoringid}`}
                  closeout={closeoutItem}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
