import { useState, useEffect, useCallback } from "react";
import { Snackbar, Alert, AlertTitle } from "@mui/material";

export const useOpenAlertCenter = () => {
  const [alertQueue, setAlertQueue] = useState<
    { message: string; duration?: number }[]
  >([]);
  const [open, setOpen] = useState(false);
  const [currentAlert, setCurrentAlert] = useState<{
    message: string;
    duration?: number;
  } | null>(null);

  // Function to add an alert to the queue
  const openAlertCenter = useCallback(
    (props: { message: string; duration?: number }) => {
      setAlertQueue((prevQueue) => [...prevQueue, props]);
    },
    []
  );

  // Show the next alert when the current one closes
  useEffect(() => {
    if (!open && alertQueue.length > 0) {
      const nextAlert = alertQueue[0];
      setCurrentAlert(nextAlert);
      setAlertQueue((prevQueue) => prevQueue.slice(1));
      setOpen(true);
    }
  }, [open, alertQueue]);

  // Ensure the alert closes properly and triggers the next one
  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return; // Keep alert open if clicked outside
    setOpen(false);
    setTimeout(() => {
      if (alertQueue.length > 0) {
        const nextAlert = alertQueue[0];
        setCurrentAlert(nextAlert);
        setAlertQueue((prevQueue) => prevQueue.slice(1));
        setOpen(true);
      }
    }, 500); // Delay next alert to avoid flickering
  };

  const renderAlert = () => (
    <Snackbar
      open={open}
      onClose={handleClose}
      transitionDuration={1000}
      autoHideDuration={currentAlert?.duration || 3000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity="info">
        <AlertTitle>{currentAlert?.message}</AlertTitle>
      </Alert>
    </Snackbar>
  );

  return { openAlertCenter, renderAlert };
};
