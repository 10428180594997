import { useMutation, useQuery, useQueryClient } from "react-query";
import { CloseoutService as cs } from "../api/CloseoutService";
import {
  BreakoutAllocation,
  DirectLaborCloseout,
  EquipmentCloseout,
  GeneralExpenseCloseout,
  GetDirectLaborCloseoutsResponse,
  GetEquipmentCloseoutsResponse,
  GetExtendEstimateResponse,
  GetGeneralExpenseCloseoutsResponse,
  GetIncidentalLaborCloseoutsResponse,
  GetIndirectLaborCloseoutsResponse,
  GetLaborFactoringCloseoutsResponse,
  GetQuoteCloseoutsResponse,
  GetSubcontractCloseoutsResponse,
  GetTotalFinalPriceCloseoutsResponse,
  IncidentalLaborCloseout,
  IndirectLaborCloseout,
  LaborFactoringCloseout,
  QuoteCloseout,
  SubcontractCloseout,
  TotalFinalPriceCloseout,
  UpdateEstimateEquipmentAllocationRequest,
  UpdateEstimateGeneralExpenseAllocationRequest,
  UpdateEstimateIncidentalLaborAllocationRequest,
  UpdateEstimateIndirectLaborAllocationRequest,
  UpdateEstimateQuoteAllocationRequest,
  UpdateEstimateSubcontractAllocationRequest,
  UpdateExtendEstimateRequest,
} from "../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../states/store";
import {
  DirectLaborChangeOrderCloseout,
  EquipmentChangeOrderCloseout,
  GeneralExpenseChangeOrderCloseout,
  GetDirectLaborChangeOrderCloseoutsResponse,
  GetEquipmentChangeOrderCloseoutsResponse,
  GetExtendChangeOrderResponse,
  GetGeneralExpenseChangeOrderCloseoutsResponse,
  GetIncidentalLaborChangeOrderCloseoutsResponse,
  GetIndirectLaborChangeOrderCloseoutsResponse,
  GetLaborFactoringChangeOrderCloseoutsResponse,
  GetQuoteChangeOrderCloseoutsResponse,
  GetSubcontractChangeOrderCloseoutsResponse,
  GetTotalFinalPriceChangeOrderCloseoutsResponse,
  IncidentalLaborChangeOrderCloseout,
  IndirectLaborChangeOrderCloseout,
  LaborFactoringChangeOrderCloseout,
  QuoteChangeOrderCloseout,
  SubcontractChangeOrderCloseout,
  TotalFinalPriceChangeOrderCloseout,
  UpdateChangeOrderEquipmentAllocationRequest,
  UpdateChangeOrderGeneralExpenseAllocationRequest,
  UpdateChangeOrderIncidentalLaborAllocationRequest,
  UpdateChangeOrderIndirectLaborAllocationRequest,
  UpdateChangeOrderLaborFactoringAllocationRequest,
  UpdateChangeOrderQuoteAllocationRequest,
  UpdateChangeOrderSubcontractAllocationRequest,
  UpdateExtendChangeOrderRequest,
} from "../api/protosCompiled/changeOrderCloseout/changeOrderCloseout_pb";

/** 
We never need to create an extension in the front end.
The back end will create and update the extension when we request it.
*/
export const useGetExtendEstimates = () => {
  const { selectedEstimate } = useStore(useUnityBuildStore);

  const { data, error, isLoading, isError, isFetching, refetch } =
    useQuery<GetExtendEstimateResponse.AsObject | null>(
      ["extension", selectedEstimate?.estimateid],
      async () => {
        if (!selectedEstimate?.estimateid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getExtendEstimates(
            selectedEstimate.estimateid
          );

          // Validate response (optional, depending on your API structure)
          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching estimate extension:", e);
          throw e; // Let react-query handle errors
        }
      },
      {
        enabled: !!selectedEstimate?.estimateid,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  // Return data along with additional states
  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError, // Helpful flag to indicate if valid data is present
    refetch,
  };
};

/**
 *
 * @returns
 */
export const useUpdateEstimateExtensionMutation = () => {
  const { selectedEstimate } = useStore(useUnityBuildStore);
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateExtendEstimateRequest.AsObject) =>
      cs.updateExtendEstimates(data),
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries([
          "extension",
          selectedEstimate?.estimateid,
        ]);
      },

      onError: (error) => {
        console.error("Error updating estimateExtension", error);
      },
    }
  );
};

//--------------------Direct Labor hooks --------------------//

export const useCreateDirectLaborCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: DirectLaborCloseout) => cs.createDirectLaborCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("directLabor");
      },
      onError: (error) => {
        console.error("Error creating direct labor closeout:", error);
      },
    }
  );
};

export const useGetDirectLaborCloseouts = () => {
  const { selectedEstimate } = useStore(useUnityBuildStore);

  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetDirectLaborCloseoutsResponse.AsObject | null>(
      ["directLabor", selectedEstimate?.estimateid],
      async () => {
        if (!selectedEstimate?.estimateid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getDirectLaborCloseouts(
            selectedEstimate.estimateid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching direct labor closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
    isFetching,
  };
};

export const useUpdateDirectLaborCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: DirectLaborCloseout.AsObject) =>
      cs.updateDirectLaborCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("directLabor");
      },
      onError: (error) => {
        console.error("Error updating direct labor closeout:", error);
      },
    }
  );
};

export const useDeleteDirectLaborCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (directLaborCloseoutId: string) =>
      cs.deleteDirectLaborCloseout(directLaborCloseoutId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("directLabor");
      },
      onError: (error) => {
        console.error("Error deleting direct labor closeout:", error);
      },
    }
  );
};

export const useCreateLaborFactoringCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: LaborFactoringCloseout) =>
      cs.createLaborFactoringCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("laborFactoring");
      },
      onError: (error) => {
        console.error("Error creating labor factoring closeout:", error);
      },
    }
  );
};

export const useGetLaborFactoringCloseouts = () => {
  const { selectedEstimate } = useStore(useUnityBuildStore);

  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetLaborFactoringCloseoutsResponse.AsObject | null>(
      ["laborFactoring", selectedEstimate?.estimateid],
      async () => {
        if (!selectedEstimate?.estimateid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getLaborFactoringCloseouts(
            selectedEstimate.estimateid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching labor factoring closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useUpdateLaborFactoringCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: LaborFactoringCloseout.AsObject) =>
      cs.updateLaborFactoringCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("laborFactoring");
      },
      onError: (error) => {
        console.error("Error updating labor factoring closeout:", error);
      },
    }
  );
};

export const useDeleteLaborFactoringCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => cs.deleteLaborFactoringCloseout(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("laborFactoring");
      },
      onError: (error) => {
        console.error("Error deleting labor factoring closeout:", error);
      },
    }
  );
};

export const useCreateIncidentalLaborCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: IncidentalLaborCloseout) =>
      cs.createIncidentalLaborCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("incidentalLabor");
      },
      onError: (error) => {
        console.error("Error creating incidental labor closeout:", error);
      },
    }
  );
};

export const useGetIncidentalLaborCloseouts = () => {
  const { selectedEstimate } = useStore(useUnityBuildStore);

  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetIncidentalLaborCloseoutsResponse.AsObject | null>(
      ["incidentalLabor", selectedEstimate?.estimateid],
      async () => {
        if (!selectedEstimate?.estimateid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getIncidentalLaborCloseouts(
            selectedEstimate.estimateid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching incidental labor closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useUpdateIncidentalLaborCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: IncidentalLaborCloseout.AsObject) =>
      cs.updateIncidentalLaborCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("incidentalLabor");
      },
      onError: (error) => {
        console.error("Error updating incidental labor closeout:", error);
      },
    }
  );
};

export const useDeleteIncidentalLaborCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => cs.deleteIncidentalLaborCloseout(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("incidentalLabor");
      },
      onError: (error) => {
        console.error("Error deleting incidental labor closeout:", error);
      },
    }
  );
};

export const useCreateIndirectLaborCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: IndirectLaborCloseout) => cs.createIndirectLaborCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("indirectLabor");
      },
      onError: (error) => {
        console.error("Error creating indirect labor closeout:", error);
      },
    }
  );
};

export const useGetIndirectLaborCloseouts = () => {
  const { selectedEstimate } = useStore(useUnityBuildStore);

  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetIndirectLaborCloseoutsResponse.AsObject | null>(
      ["indirectLabor", selectedEstimate?.estimateid],
      async () => {
        if (!selectedEstimate?.estimateid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getIndirectLaborCloseouts(
            selectedEstimate.estimateid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching indirect labor closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useUpdateIndirectLaborCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: IndirectLaborCloseout.AsObject) =>
      cs.updateIndirectLaborCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("indirectLabor");
      },
      onError: (error) => {
        console.error("Error updating indirect labor closeout:", error);
      },
    }
  );
};

export const useDeleteIndirectLaborCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(async (id: string) => cs.deleteIndirectLaborCloseout(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("indirectLabor");
    },
    onError: (error) => {
      console.error("Error deleting indirect labor closeout:", error);
    },
  });
};

export const useCreateEquipmentCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: EquipmentCloseout) => cs.createEquipmentCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("equipment");
      },
      onError: (error) => {
        console.error("Error creating equipment closeout:", error);
      },
    }
  );
};

export const useGetEquipmentCloseouts = () => {
  const { selectedEstimate } = useStore(useUnityBuildStore);

  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetEquipmentCloseoutsResponse.AsObject | null>(
      ["equipment", selectedEstimate?.estimateid],
      async () => {
        if (!selectedEstimate?.estimateid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getEquipmentCloseouts(
            selectedEstimate.estimateid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching equipment closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useUpdateEquipmentCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: EquipmentCloseout.AsObject) =>
      cs.updateEquipmentCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("equipment");
      },
      onError: (error) => {
        console.error("Error updating equipment closeout:", error);
      },
    }
  );
};

export const useDeleteEquipmentCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(async (id: string) => cs.deleteEquipmentCloseout(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("equipment");
    },
    onError: (error) => {
      console.error("Error deleting equipment closeout:", error);
    },
  });
};

export const useCreateGeneralExpenseCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: GeneralExpenseCloseout) => cs.createGeneralExpenseCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("generalExpense");
      },
      onError: (error) => {
        console.error("Error creating general expense closeout:", error);
      },
    }
  );
};

export const useGetGeneralExpenseCloseouts = () => {
  const { selectedEstimate } = useStore(useUnityBuildStore);

  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetGeneralExpenseCloseoutsResponse.AsObject | null>(
      ["generalExpense", selectedEstimate?.estimateid],
      async () => {
        if (!selectedEstimate?.estimateid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getGeneralExpenseCloseouts(
            selectedEstimate.estimateid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching general expense closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useUpdateGeneralExpenseCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: GeneralExpenseCloseout.AsObject) =>
      cs.updateGeneralExpenseCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("generalExpense");
      },
      onError: (error) => {
        console.error("Error updating general expense closeout:", error);
      },
    }
  );
};

export const useDeleteGeneralExpenseCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => cs.deleteGeneralExpenseCloseout(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("generalExpense");
      },
      onError: (error) => {
        console.error("Error deleting general expense closeout:", error);
      },
    }
  );
};

export const useCreateSubcontractCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: SubcontractCloseout) => cs.createSubcontractCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("subcontract");
      },
      onError: (error) => {
        console.error("Error creating subcontract closeout:", error);
      },
    }
  );
};

export const useGetSubcontractCloseouts = () => {
  const { selectedEstimate } = useStore(useUnityBuildStore);

  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetSubcontractCloseoutsResponse.AsObject | null>(
      ["subcontract", selectedEstimate?.estimateid],
      async () => {
        if (!selectedEstimate?.estimateid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getSubcontractCloseouts(
            selectedEstimate.estimateid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching subcontract closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useUpdateSubcontractCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: SubcontractCloseout.AsObject) => cs.updateSubcontractCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("subcontract");
      },
      onError: (error) => {
        console.error("Error updating subcontract closeout:", error);
      },
    }
  );
};

export const useDeleteSubcontractCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(async (id: string) => cs.deleteSubcontractCloseout(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("subcontract");
    },
    onError: (error) => {
      console.error("Error deleting subcontract closeout:", error);
    },
  });
};

export const useCreateQuoteCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: QuoteCloseout) => cs.createQuoteCloseout(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("quote");
    },
    onError: (error) => {
      console.error("Error creating quote closeout:", error);
    },
  });
};

export const useGetQuoteCloseouts = () => {
  const { selectedEstimate } = useStore(useUnityBuildStore);

  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetQuoteCloseoutsResponse.AsObject | null>(
      ["quote", selectedEstimate?.estimateid],
      async () => {
        if (!selectedEstimate?.estimateid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getQuoteCloseouts(
            selectedEstimate.estimateid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching quote closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useUpdateQuoteCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: QuoteCloseout.AsObject) => cs.updateQuoteCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("quote");
      },
      onError: (error) => {
        console.error("Error updating quote closeout:", error);
      },
    }
  );
};

export const useDeleteQuoteCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(async (id: string) => cs.deleteQuoteCloseout(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("quote");
    },
    onError: (error) => {
      console.error("Error deleting quote closeout:", error);
    },
  });
};

export const useGetTotalFinalPriceCloseouts = () => {
  const { selectedEstimate } = useStore(useUnityBuildStore);

  const { data, error, isLoading, isError, isFetching, refetch } =
    useQuery<GetTotalFinalPriceCloseoutsResponse.AsObject | null>(
      ["totalFinalPrice", selectedEstimate?.estimateid],
      async () => {
        if (!selectedEstimate?.estimateid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getTotalFinalPriceCloseouts(
            selectedEstimate.estimateid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching total final price closeout:", e);
          throw e;
        }
      },
      {
        enabled: !!selectedEstimate?.estimateid,
        refetchOnWindowFocus: false,
      }
    );

  return {
    data,
    error,
    isLoading,
    isFetching,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useUpdateTotalFinalPriceCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (updatedCloseout: TotalFinalPriceCloseout.AsObject) => {
      return cs.updateTotalFinalPriceCloseout(updatedCloseout);
    },
    {
      onMutate: async (updatedCloseout) => {
        await queryClient.cancelQueries("totalFinalPrice");

        const previousData =
          queryClient.getQueryData<TotalFinalPriceCloseout.AsObject>(
            "totalFinalPrice"
          );

        // **Optimistically update the cache**
        queryClient.setQueryData<TotalFinalPriceCloseout.AsObject>(
          "totalFinalPrice",
          (oldData) =>
            oldData
              ? {
                ...oldData,
                ...updatedCloseout, // Merge new changes
              }
              : updatedCloseout
        );

        return { previousData };
      },
      onError: (error, _, context) => {
        console.error("Error updating total final price closeout:", error);
        if (context?.previousData) {
          queryClient.setQueryData("totalFinalPrice", context.previousData);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries("totalFinalPrice");
      },
    }
  );
};

//--------------------Change Order Closeout hooks --------------------//

export const useGetExtendChangeOrder = () => {
  const { selectedChangeOrder } = useStore(useUnityBuildStore);

  const { data, error, isLoading, isError, isFetching, refetch } =
    useQuery<GetExtendChangeOrderResponse.AsObject | null>(
      ["extension", selectedChangeOrder?.changeorderid],
      async () => {
        if (!selectedChangeOrder?.changeorderid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getExtendChangeOrder(
            selectedChangeOrder.changeorderid
          );

          // Validate response (optional, depending on your API structure)
          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching estimate extension:", e);
          throw e; // Let react-query handle errors
        }
      },
      {
        enabled: !!selectedChangeOrder?.changeorderid,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  // Return data along with additional states
  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError, // Helpful flag to indicate if valid data is present
    refetch,
  };
};

export const useUpdateChangeOrderExtensionMutation = () => {
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  const queryClient = useQueryClient();

  return useMutation(
    async (data: UpdateExtendChangeOrderRequest.AsObject) =>
      cs.updateExtendChangeOrder(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "changeOrderExtension",
          selectedChangeOrder?.changeorderid,
        ]);
      },

      onError: (error) => {
        console.error("Error updating change order extension:", error);
      },
    }
  );
};

export const useGetDirectLaborChangeOrderCloseouts = () => {
  const { selectedChangeOrder } = useStore(useUnityBuildStore);

  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetDirectLaborChangeOrderCloseoutsResponse.AsObject | null>(
      ["directLabor", selectedChangeOrder?.changeorderid],
      async () => {
        if (!selectedChangeOrder?.changeorderid) {
          throw new Error("No change order ID is selected.");
        }

        try {
          const response = await cs.getDirectLaborChangeOrderCloseouts(
            selectedChangeOrder.changeorderid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching direct labor closeout:", e);
          throw e;
        }
      },
      {
        enabled: !!selectedChangeOrder?.changeorderid,
        refetchOnWindowFocus: false,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
    isFetching,
  };
};

export const useCreateDirectLaborChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  return useMutation(
    async (data: DirectLaborChangeOrderCloseout) =>
      cs.createDirectLaborChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "directLabor",
          selectedChangeOrder?.changeorderid,
        ]);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
};

export const useUpdateDirectLaborChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  return useMutation(
    async (data: DirectLaborChangeOrderCloseout.AsObject) =>
      cs.updateDirectLaborChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "directLabor",
          selectedChangeOrder?.changeorderid,
        ]);
      },
      onError: (error) => {
        console.error("Error updating direct labor closeout:", error);
      },
    }
  );
};

export const useDeleteDirectLaborChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  return useMutation(
    async (directLaborCloseoutId: string) =>
      cs.deleteDirectLaborChangeOrderCloseout(directLaborCloseoutId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "directLabor",
          selectedChangeOrder?.changeorderid,
        ]);
      },
      onError: (error) => {
        console.error("Error deleting direct labor closeout:", error);
      },
    }
  );
};

export const useGetLaborFactoringChangeOrderCloseouts = () => {
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetLaborFactoringChangeOrderCloseoutsResponse.AsObject | null>(
      ["laborFactoring", selectedChangeOrder?.changeorderid],
      async () => {
        if (!selectedChangeOrder?.changeorderid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getLaborFactoringChangeOrderCloseouts(
            selectedChangeOrder?.changeorderid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching labor factoring closeout:", e);
          throw e;
        }
      },
      {
        enabled: !!selectedChangeOrder?.changeorderid,
        refetchOnWindowFocus: false,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useCreateLaborFactoringChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  return useMutation(
    async (data: LaborFactoringChangeOrderCloseout) =>
      cs.createLaborFactoringChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "laborFactoring",
          selectedChangeOrder?.changeorderid,
        ]);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
};

export const useUpdateLaborFactoringChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  const { selectedChangeOrder } = useStore(useUnityBuildStore);

  return useMutation(
    async (data: LaborFactoringChangeOrderCloseout.AsObject) =>
      cs.updateLaborFactoringChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "laborFactoring",
          selectedChangeOrder?.changeorderid,
        ]);
      },
      onError: (error) => {
        console.error("Error updating labor factoring closeout:", error);
      },
    }
  );
};

export const useDeleteLaborFactoringChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  const { selectedChangeOrder } = useStore(useUnityBuildStore);

  return useMutation(
    async (id: string) => cs.deleteLaborFactoringChangeOrderCloseout(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "laborFactoring",
          selectedChangeOrder?.changeorderid,
        ]);
      },
      onError: (error) => {
        console.error("Error deleting labor factoring closeout:", error);
      },
    }
  );
};

export const useGetIncidentalLaborChangeOrderCloseouts = () => {
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetIncidentalLaborChangeOrderCloseoutsResponse.AsObject | null>(
      ["incidentalLabor", selectedChangeOrder?.changeorderid],
      async () => {
        if (!selectedChangeOrder?.changeorderid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getIncidentalLaborChangeOrderCloseouts(
            selectedChangeOrder?.changeorderid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching incidental labor closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useCreateIncidentalLaborChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: IncidentalLaborChangeOrderCloseout) =>
      cs.createIncidentalLaborChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("incidentalLabor");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
};

export const useUpdateIncidentalLaborChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: IncidentalLaborChangeOrderCloseout.AsObject) =>
      cs.updateIncidentalLaborChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("incidentalLabor");
      },
      onError: (error) => {
        console.error("Error updating incidental labor closeout:", error);
      },
    }
  );
};

export const useDeleteIncidentalLaborChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => cs.deleteIncidentalLaborChangeOrderCloseout(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("incidentalLabor");
      },
      onError: (error) => {
        console.error("Error deleting incidental labor closeout:", error);
      },
    }
  );
};

export const useGetIndirectLaborChangeOrderCloseouts = () => {
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetIndirectLaborChangeOrderCloseoutsResponse.AsObject | null>(
      ["indirectLabor", selectedChangeOrder?.changeorderid],
      async () => {
        if (!selectedChangeOrder?.changeorderid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getIndirectLaborChangeOrderCloseouts(
            selectedChangeOrder?.changeorderid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching indirect labor closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useCreateIndirectLaborChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: IndirectLaborChangeOrderCloseout) =>
      cs.createIndirectLaborChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("indirectLabor");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
};

export const useUpdateIndirectLaborChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: IndirectLaborChangeOrderCloseout.AsObject) =>
      cs.updateIndirectLaborChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("indirectLabor");
      },
      onError: (error) => {
        console.error("Error updating indirect labor closeout:", error);
      },
    }
  );
};

export const useDeleteIndirectLaborChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => cs.deleteIndirectLaborChangeOrderCloseout(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("indirectLabor");
      },
      onError: (error) => {
        console.error("Error deleting indirect labor closeout:", error);
      },
    }
  );
};

export const useGetEquipmentChangeOrderCloseouts = () => {
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetEquipmentChangeOrderCloseoutsResponse.AsObject | null>(
      ["equipment", selectedChangeOrder?.changeorderid],
      async () => {
        if (!selectedChangeOrder?.changeorderid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getEquipmentChangeOrderCloseouts(
            selectedChangeOrder?.changeorderid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching equipment closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useCreateEquipmentChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: EquipmentChangeOrderCloseout) =>
      cs.createEquipmentChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("equipment");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
};

export const useUpdateEquipmentChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: EquipmentChangeOrderCloseout.AsObject) =>
      cs.updateEquipmentChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("equipment");
      },
      onError: (error) => {
        console.error("Error updating equipment closeout:", error);
      },
    }
  );
};

export const useDeleteEquipmentChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => cs.deleteEquipmentChangeOrderCloseout(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("equipment");
      },
      onError: (error) => {
        console.error("Error deleting equipment closeout:", error);
      },
    }
  );
};

export const useGetGeneralExpenseChangeOrderCloseouts = () => {
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetGeneralExpenseChangeOrderCloseoutsResponse.AsObject | null>(
      ["generalExpense", selectedChangeOrder?.changeorderid],
      async () => {
        if (!selectedChangeOrder?.changeorderid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getGeneralExpenseChangeOrderCloseouts(
            selectedChangeOrder?.changeorderid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching general expense closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useCreateGeneralExpenseChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: GeneralExpenseChangeOrderCloseout) =>
      cs.createGeneralExpenseChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("generalExpense");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
};

export const useUpdateGeneralExpenseChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: GeneralExpenseChangeOrderCloseout.AsObject) =>
      cs.updateGeneralExpenseChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("generalExpense");
      },
      onError: (error) => {
        console.error("Error updating general expense closeout:", error);
      },
    }
  );
};

export const useDeleteGeneralExpenseChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => cs.deleteGeneralExpenseChangeOrderCloseout(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("generalExpense");
      },
      onError: (error) => {
        console.error("Error deleting general expense closeout:", error);
      },
    }
  );
};

export const useGetSubcontractChangeOrderCloseouts = () => {
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetSubcontractChangeOrderCloseoutsResponse.AsObject | null>(
      ["subcontract", selectedChangeOrder?.changeorderid],
      async () => {
        if (!selectedChangeOrder?.changeorderid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getSubcontractChangeOrderCloseouts(
            selectedChangeOrder?.changeorderid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching subcontract closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useCreateSubcontractChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: SubcontractChangeOrderCloseout) =>
      cs.createSubcontractChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("subcontract");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
};

export const useUpdateSubcontractChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: SubcontractChangeOrderCloseout.AsObject) =>
      cs.updateSubcontractChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("subcontract");
      },
      onError: (error) => {
        console.error("Error updating subcontract closeout:", error);
      },
    }
  );
};

export const useDeleteSubcontractChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => cs.deleteSubcontractChangeOrderCloseout(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("subcontract");
      },
      onError: (error) => {
        console.error("Error deleting subcontract closeout:", error);
      },
    }
  );
};

export const useGetQuoteChangeOrderCloseouts = () => {
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetQuoteChangeOrderCloseoutsResponse.AsObject | null>(
      ["quote", selectedChangeOrder?.changeorderid],
      async () => {
        if (!selectedChangeOrder?.changeorderid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getQuoteChangeOrderCloseouts(
            selectedChangeOrder?.changeorderid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching quote closeout:", e);
          throw e;
        }
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useCreateQuoteChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: QuoteChangeOrderCloseout) =>
      cs.createQuoteChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("quote");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
};

export const useUpdateQuoteChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: QuoteChangeOrderCloseout.AsObject) =>
      cs.updateQuoteChangeOrderCloseout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("quote");
      },
      onError: (error) => {
        console.error("Error updating quote closeout:", error);
      },
    }
  );
};

export const useDeleteQuoteChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => cs.deleteQuoteChangeOrderCloseout(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("quote");
      },
      onError: (error) => {
        console.error("Error deleting quote closeout:", error);
      },
    }
  );
};

export const useGetTotalFinalPriceChangeOrderCloseouts = () => {
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  const { data, error, isLoading, isError, refetch, isFetching } =
    useQuery<GetTotalFinalPriceChangeOrderCloseoutsResponse.AsObject | null>(
      ["totalFinalPrice", selectedChangeOrder?.changeorderid],
      async () => {
        if (!selectedChangeOrder?.changeorderid) {
          throw new Error("No estimate ID is selected.");
        }

        try {
          const response = await cs.getTotalFinalPriceChangeOrderCloseouts(
            selectedChangeOrder?.changeorderid
          );

          if (!response || typeof response !== "object") {
            throw new Error("Invalid response from the API.");
          }

          return response;
        } catch (e) {
          console.error("Error fetching total final price closeout:", e);
          throw e;
        }
      },
      {
        enabled: !!selectedChangeOrder?.changeorderid,
        refetchOnWindowFocus: false,
      }
    );

  return {
    isFetching,
    data,
    error,
    isLoading,
    isError,
    hasData: !!data && !isError,
    refetch,
  };
};

export const useUpdateTotalFinalPriceChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (updatedCloseout: TotalFinalPriceChangeOrderCloseout.AsObject) => {
      return cs.updateTotalFinalPriceChangeOrderCloseout(updatedCloseout);
    },
    {
      onMutate: async (updatedCloseout) => {
        await queryClient.cancelQueries("totalFinalPrice");

        const previousData =
          queryClient.getQueryData<TotalFinalPriceChangeOrderCloseout.AsObject>(
            "totalFinalPrice"
          );

        // **Optimistically update the cache**
        queryClient.setQueryData<TotalFinalPriceChangeOrderCloseout.AsObject>(
          "totalFinalPrice",
          (oldData) =>
            oldData
              ? {
                ...oldData,
                ...updatedCloseout, // Merge new changes
              }
              : updatedCloseout
        );

        return { previousData };
      },
      onError: (error, _, context) => {
        console.error("Error updating total final price closeout:", error);
        if (context?.previousData) {
          queryClient.setQueryData("totalFinalPrice", context.previousData);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries("totalFinalPrice");
      },
    }
  );
};

export const useUpdateEstimateLaborFactoringAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedEstimate } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateEstimateLaborFactoringAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating estimate allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedEstimate?.estimateid]);
      },
    }
  );
};

export const useUpdateEstimateIncidentalLaborAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedEstimate } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateEstimateIncidentalLaborAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating estimate allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedEstimate?.estimateid]);
      },
    }
  );
};

export const useUpdateEstimateIndirectLaborAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedEstimate } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateEstimateIndirectLaborAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating estimate allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedEstimate?.estimateid]);
      },
    }
  );
};

export const useUpdateEstimateEquipmentAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedEstimate } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateEstimateEquipmentAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating estimate allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedEstimate?.estimateid]);
      },
    }
  );
};

export const useUpdateEstimateGeneralExpenseAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedEstimate } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateEstimateGeneralExpenseAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating estimate allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedEstimate?.estimateid]);
      },
    }
  );
};

export const useUpdateEstimateSubcontractAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedEstimate } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateEstimateSubcontractAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating estimate allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedEstimate?.estimateid]);
      },
    }
  );
};

export const useUpdateEstimateQuoteAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedEstimate } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateEstimateQuoteAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating estimate allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedEstimate?.estimateid]);
      },
    }
  );
};

export const useUpdateChangeOrderLaborFactoringAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateChangeOrderLaborFactoringAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating change order allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedChangeOrder?.changeorderid]);
      },
    }
  );
};

export const useUpdateChangeOrderIncidentalLaborAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateChangeOrderIncidentalLaborAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating change order allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedChangeOrder?.changeorderid]);
      },
    }
  );
};

export const useUpdateChangeOrderIndirectLaborAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateChangeOrderIndirectLaborAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating change order allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedChangeOrder?.changeorderid]);
      },
    }
  );
};

export const useUpdateChangeOrderEquipmentAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateChangeOrderEquipmentAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating change order allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedChangeOrder?.changeorderid]);
      },
    }
  );
};

export const useUpdateChangeOrderGeneralExpenseAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateChangeOrderGeneralExpenseAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating change order allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedChangeOrder?.changeorderid]);
      },
    }
  );
};

export const useUpdateChangeOrderSubcontractAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateChangeOrderSubcontractAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating change order allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedChangeOrder?.changeorderid]);
      },
    }
  );
};

export const useUpdateChangeOrderQuoteAllocation = () => {
  const queryClient = useQueryClient();
  const { selectedChangeOrder } = useStore(useUnityBuildStore);
  return useMutation(
    (data: BreakoutAllocation.AsObject) =>
      cs.updateChangeOrderQuoteAllocation(data),
    {
      onError: (error) => {
        console.error("Error updating change order allocation:", error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["totalFinalPrice", selectedChangeOrder?.changeorderid]);
      },
    }
  );
};
