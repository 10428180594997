import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useRef, useState } from "react";
import { FolderIcon } from "../../../icons/folderIcon";
import { ItemTable } from "../items/ItemTable";
import React from "react";
import {
  CollapsibleRowProps,
  FolderNode,
} from "../../../types/FolderTypes";
import {
  buildFolderTree,
  deleteFolderHelper,
  createFolderHelper,
  mapFoldersToFolderNodes,
  renameFolderHelper,
  isCreatingInFolder,
  useHandleMoveCurrentFolder,
} from "./helpers";
import { AssemblyTable } from "../assemblies/AssemblyTable";
import { useStore } from "zustand";
import { useAssemblyStore } from "../../../states/AssemblyStore";
import {
  useUpdateProjectFolder,
  useUpdateAssemblyFolder,
  useUpdateItemFolder,
  useCreateAssemblyFolderMutation,
  useCreateItemFolderMutation,
  useCreateProjectFolderMutation,
  useDeleteAssemblyFolderMutation,
  useDeleteItemFolderMutation,
  useDeleteProjectFolderMutation,
} from "../../../hooks/useFetchFolders";
import { useGlobalItemStore } from "../../../states/globalItemStore";
import { useFolderStore } from "../../../states/folderStore";

/**
 * FolderNodes is responsible for rendering the folders from the FolderService
 * the data we pass to it is dependent on the queryies entity type
 */
export const FolderNodes = ({
  context,
  folderType,
  folderData,
}: CollapsibleRowProps) => {
  //zustand states
  const isCreatingAssembly = useStore(useAssemblyStore).isCreatingAssembly;
  const isViewingItemForm = useStore(useGlobalItemStore).isViewingItemForm;
  const isMoveMode = useStore(useFolderStore).isMoveMode;
  const setIsMoveMode = useStore(useFolderStore).setIsMoveMode;
  const currentFolder = useStore(useFolderStore).currentFolder;
  const setCurrentFolder = useStore(useFolderStore).setCurrentFolder;
  const openRows = useStore(useFolderStore).openRows;
  const editingFolderId = useStore(useFolderStore).editingFolderId;
  const setEditingFolderId = useStore(useFolderStore).setEditingFolderId;
  const toggleRow = useStore(useFolderStore).toggleRow;
  const isEditMode = useStore(useAssemblyStore).isEditMode;

  const [tempFolderName, setTempFolderName] = useState<string>("");

  // const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);
  const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  //react-query mutations
  const { mutate: createProjectFolder } = useCreateProjectFolderMutation();
  const { mutate: createAssemblyFolder } = useCreateAssemblyFolderMutation();
  const { mutate: createItemFolder } = useCreateItemFolderMutation();

  const { mutate: updateProjectFolder } = useUpdateProjectFolder();
  const { mutate: updateAssemblyFolder } = useUpdateAssemblyFolder();
  const { mutate: updateItemFolder } = useUpdateItemFolder();

  const { mutate: deleteProjectFolder } = useDeleteProjectFolderMutation();
  const { mutate: deleteAssemblyFolder } = useDeleteAssemblyFolderMutation();
  const { mutate: deleteItemFolder } = useDeleteItemFolderMutation();

  //hooks
  const moveCurrentFolder = useHandleMoveCurrentFolder();

  const folderTree: FolderNode[] = folderData?.foldersList?.length
    ? buildFolderTree(
      folderType,
      mapFoldersToFolderNodes(folderData.foldersList)
    )
    : [];

  const handleSetMoveModeforCurrentFolder = (
    e: React.MouseEvent<HTMLButtonElement>,
    folder: FolderNode
  ) => {
    e.stopPropagation();
    if (isMoveMode && currentFolder) {
      console.warn("Already in move mode for another folder.");
      return;
    }
    setIsMoveMode(true);
    setCurrentFolder(folder);
  };

  //handles the create folder action
  const handleCreateFolder = (
    e: React.MouseEvent<HTMLButtonElement>,
    folder: FolderNode
  ) => {
    e.stopPropagation();
    createFolderHelper(
      e,
      folder,
      createProjectFolder,
      createAssemblyFolder,
      createItemFolder
    );
  };

  const handleRenameFolder = (
    e: React.KeyboardEvent<HTMLInputElement>,
    folder: FolderNode
  ) => {
    e.stopPropagation();
    renameFolderHelper(
      e,
      folder,
      updateProjectFolder,
      updateAssemblyFolder,
      updateItemFolder
    );
  };

  //handles the delete folder action
  const deleteFolder = (
    e: React.MouseEvent<HTMLButtonElement>,
    folder: FolderNode
  ) => {
    e.stopPropagation();
    deleteFolderHelper(
      e,
      folder,
      deleteProjectFolder,
      deleteAssemblyFolder,
      deleteItemFolder
    );
  };

  //renders the folder rows
  const renderFolderRow = (folder: FolderNode, nestedDepth: number) => {
    const isOpen = openRows[folder.folderid] || false;

    const handleEditStart = () => {
      setEditingFolderId(folder.folderid);
      setTempFolderName(folder.name);
    };

    const handleEditFinish = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleRenameFolder(e, folder);
        setEditingFolderId(null);
      }
    };

    const handleEditCancel = () => {
      setEditingFolderId(null);
      setTempFolderName("");
    };

    // Render the folder row
    const folderRow = (
      <tr
        key={folder.folderid}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (editingFolderId === folder.folderid) {
            return;
          }
          if (clickTimeoutRef.current) {
            clearTimeout(clickTimeoutRef.current);
            clickTimeoutRef.current = null;
            handleEditStart();
          } else {
            clickTimeoutRef.current = setTimeout(() => {
              if (isMoveMode && currentFolder) {
                moveCurrentFolder({
                  e,
                  isMoveMode,
                  setIsMoveMode,
                  destinationFolder: folder,
                  folderBeingMoved: currentFolder,
                });
              } else {
                toggleRow(folder.folderid);
              }
              clickTimeoutRef.current = null;
            }, 250);

            if (!isEditMode) {
              if (isCreatingAssembly && folder.entitytype === "assembly") {
                setCurrentFolder(folder);
              } else if (!isCreatingAssembly && folder.entitytype === "item") {
                setCurrentFolder(folder);
              }
            }
          }
        }}
        style={{
          cursor: "pointer",
          backgroundColor:
            !isMoveMode && isCreatingInFolder(folder, isViewingItemForm)
              ? "lightGreen"
              : "",
        }}
      >
        <td
          style={{
            textAlign: "left",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            paddingLeft: `${nestedDepth * 20}px`,
          }}
        >
          <span style={{ marginRight: "10px" }}>
            <FolderIcon isOpen={isOpen} />
          </span>
          {editingFolderId === folder.folderid ? (
            <input
              type="text"
              value={tempFolderName}
              onChange={(e) => setTempFolderName(e.target.value)}
              onKeyDown={handleEditFinish}
              onBlur={handleEditCancel}
              autoFocus
              style={{
                width: "100%",
                padding: "4px",
                fontSize: "inherit",
              }}
            />
          ) : (
            folder.name
          )}
        </td>
        <td style={{ textAlign: "right" }}>
          {isMoveMode && currentFolder?.folderid === folder.folderid && (
            <strong>Select the destination folder</strong>
          )}
          {isMoveMode && currentFolder?.folderid === folder.folderid && (
            <button onClick={() => setIsMoveMode(false)}>Cancel</button>
          )}
          {context === "primary" && folder.level !== 5 && (
            <button
              className="button"
              style={{ color: "green" }}
              onClick={(e) => handleCreateFolder(e, folder)}
            >
              Create Folder
            </button>
          )}
          {folder.parentid && context === "primary" && (
            <>
              <button
                className="button"
                style={{ color: "blue" }}
                onClick={(e) => handleSetMoveModeforCurrentFolder(e, folder)}
                disabled={
                  isMoveMode && currentFolder?.folderid === folder.folderid
                }
              >
                Move Folder
              </button>
            </>
          )}

          {folder.parentid && context === "primary" && (
            <>
              <button
                className="button"
                onClick={(e) => deleteFolder(e, folder)}
              >
                <span style={{ color: "red" }}>Delete</span>
              </button>
            </>
          )}
        </td>
      </tr>
    );

    // Render children if the folder is open
    const childRows = isOpen ? (
      <tr>
        <td colSpan={2} style={{ padding: 0 }}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <table key={Math.random()} style={{ width: "100%" }}>
              <tbody>{renderChildRows(folder.children, nestedDepth + 1)}</tbody>
            </table>
          </Collapse>
        </td>
      </tr>
    ) : null;

    return (
      <React.Fragment key={folder.folderid}>
        {folderRow}
        {childRows}
      </React.Fragment>
    );
  };

  const renderChildRows = (
    children: (FolderNode | undefined)[],
    nestedDepth: number
  ) => {
    const definedChildren = children.filter(
      (child): child is FolderNode => child !== undefined
    );

    const assemblyFiles = definedChildren.filter(
      (child) => child.isfile && child.entitytype === "assembly"
    );
    const itemFiles = definedChildren.filter(
      (child) => child.isfile && child.entitytype === "item"
    );
    const otherChildren = definedChildren.filter((child) => !child.isfile);

    const rows = [];

    // Render non-item and non-assembly folders
    otherChildren.forEach((child) => {
      rows.push(renderFolderRow(child, nestedDepth));
    });

    // Render grouped item files under a single header
    if (itemFiles.length > 0) {
      rows.push(
        <TableRow key={`item-header-${nestedDepth}`}>
          <TableCell colSpan={4} sx={{ paddingLeft: `${nestedDepth * 2}rem` }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{ width: "15%", fontWeight: "bold" }}
                  >
                    Attribute
                  </TableCell>
                  <TableCell component="th" sx={{ fontWeight: "bold" }}>
                    Description
                  </TableCell>
                  <TableCell
                    component="th"
                    sx={{ width: "15%", fontWeight: "bold" }}
                  >
                    UOM
                  </TableCell>
                  <TableCell
                    component="th"
                    sx={{ width: "15%", fontWeight: "bold" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemFiles?.map((item, index) => (
                  <ItemTable
                    context={context}
                    key={item.folderid || index}
                    folder={item}
                  />
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      );
    }

    // Render grouped assembly files under a single header
    if (assemblyFiles.length > 0) {
      rows.push(
        <TableRow key={`assembly-header-${nestedDepth}`}>
          <TableCell colSpan={3} sx={{ paddingLeft: `${nestedDepth * 2}rem` }}>
            <Table sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell component="th" sx={{ fontWeight: "bold" }}>
                    Assembly Name (Click to Takeoff)
                  </TableCell>
                  <TableCell component="th" sx={{ fontWeight: "bold" }}>
                    Measurement Type
                  </TableCell>
                  {context === "primary" && (
                    <TableCell component="th" sx={{ fontWeight: "bold" }}>
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {assemblyFiles.map((assemblyFolder) => (
                  <AssemblyTable
                    context={context}
                    key={assemblyFolder.folderid}
                    folder={assemblyFolder}
                  />
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      );
    }

    return rows;
  };

  return (
    <>
      {/* Folder Tree */}
      <table style={{ width: "100%" }}>
        <tbody>{folderTree.map((folder) => renderFolderRow(folder, 0))}</tbody>
      </table>
    </>
  );
};
