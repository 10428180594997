import React from "react";
import { AttributeValue } from "../../../../api/protosCompiled/globalItem/globalItem_pb";
import { UB_AccountUser } from "../../../../api/protosCompiled/ub_users/ub_users_pb";
import { sortAndGroupAttributes } from "./helper";

interface AttributeValuesTableProps {
  attributeValues: AttributeValue.AsObject[];
  user: UB_AccountUser.AsObject | null;
  onEdit: (attributeValue: AttributeValue.AsObject | null) => void;
  onDelete: (attributeValueId: number) => void;
}

export const AttributeValuesTable: React.FC<AttributeValuesTableProps> = ({
  attributeValues,
  user,
  onEdit,
  onDelete,
}) => {
  const handleAddAttributeValue = () => {
    onEdit(null); // pass null to create a new one
  };

  const groupedEntries = Object.entries(
    sortAndGroupAttributes(attributeValues)
  );

  return (
    <div
      className="scrollableCDiv"
      style={{
        height: "500px",
        border: "1px solid black",
        margin: "5px",
        overflow: "auto",
      }}
    >
      <table style={{ minWidth: "400px" }}>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleAddAttributeValue()
          }}
          style={{ marginBottom: "8px" }}
        >
          Add Attribute Value
        </button>

        <thead>
          <tr>
            <th>Value Name</th>
            <th>Variables</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {user && groupedEntries.length > 0 ? (
            groupedEntries.map(([name, values]) => {
              // 3) Deduplicate records by `attributevalueid` in each group
              const uniqueRecords = Array.from(
                new Map(values.map((v) => [v.attributevalueid, v])).values()
              );

              return (
                <tr key={name}>
                  {/* First Cell: The group name */}
                  <td>{name}</td>

                  {/* Second Cell: Variables from all unique records in this group */}
                  <td>
                    {uniqueRecords.map((attributeValue) => (
                      <div
                        key={attributeValue.attributevalueid}
                        style={{ marginBottom: "8px" }}
                      >
                        {attributeValue.attributevariablesList.map((v) => (
                          <div key={v.itemattributevaluevariablemapid}>
                            {v.variable?.name || "Unknown Variable"}:{" "}
                            {v.variablevalue}
                          </div>
                        ))}
                      </div>
                    ))}
                  </td>

                  {/* Third Cell: Individual Edit/Delete for each distinct record */}
                  <td>
                    {uniqueRecords.map((attributeValue) => (
                      <div
                        key={attributeValue.attributevalueid}
                        style={{ marginBottom: "8px" }}
                      >
                        <button onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onEdit(attributeValue)
                        }}>
                          Edit
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onDelete(attributeValue.attributevalueid)
                          }

                          }
                          style={{ marginLeft: "4px" }}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={3} style={{ textAlign: "center" }}>
                <p>No attribute values found.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
