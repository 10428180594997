import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";
import {
  CreateGlobalAssemblyRequest,
  CreateGlobalAssemblyResponse,
  DeleteGlobalAssemblyRequest,
  DeleteGlobalAssemblyResponse,
  UpdateGlobalAssemblyRequest,
  UpdateGlobalAssemblyResponse,
  GetGlobalAssembliesByFolderIdResponse,
  GetGlobalAssembliesByFolderIdRequest,
  GlobalAssembly,
  GlobalAssemblyItem,
  GlobalItemCost,
  GlobalItemQtyFormula,
  GlobalAssemblyItemAttribute,
  GlobalQuoteGroup,
} from "./protosCompiled/globalAssembly/globalAssembly_pb";

const client = clients.globalAssemblyServiceClient;

export class GlobalAssemblyService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  /**
   * A private helper that converts your front-end `GlobalAssembly.AsObject`
   * into a `GlobalAssembly` protobuf message.
   *
   * Both create/update can call this, so we avoid repeating code.
   */
  private static buildGlobalAssemblyMessage(
    assemblyObj: GlobalAssembly.AsObject
  ): GlobalAssembly {
    const msg = new GlobalAssembly();

    // Top-level fields
    msg.setFileid(assemblyObj.fileid);
    msg.setAssemblyid(assemblyObj.assemblyid);
    msg.setAssemblyname(assemblyObj.assemblyname);
    msg.setIsactive(assemblyObj.isactive);
    msg.setParentid(assemblyObj.parentid);
    msg.setLevel(assemblyObj.level);
    msg.setEntitytype(assemblyObj.entitytype);
    msg.setIsfile(assemblyObj.isfile);
    msg.setAssemblymeasurementtype(assemblyObj.assemblymeasurementtype);
    msg.setImageclassificationid(assemblyObj.imageclassificationid);
    msg.setLastupdated(assemblyObj.lastupdated);
    msg.setCreatedbyuserid(assemblyObj.createdbyuserid);
    msg.setCreatedbyaccountid(assemblyObj.createdbyaccountid);

    // Build repeated GlobalAssemblyItem => itemsList
    if (assemblyObj.itemsList && assemblyObj.itemsList.length) {
      const itemMsgs = assemblyObj.itemsList.map((itemObj) => {
        const itemMsg = new GlobalAssemblyItem();

        // Basic fields
        itemMsg.setItemid(itemObj.itemid);
        itemMsg.setItemname(itemObj.itemname);
        itemMsg.setIsquoted(itemObj.isquoted || false);

        // Costs
        if (itemObj.costs) {
          const costMsg = new GlobalItemCost();
          costMsg.setUomid(itemObj.costs.uomid);
          costMsg.setItemunitcost(itemObj.costs.itemunitcost);
          costMsg.setHourlyproductionrate(itemObj.costs.hourlyproductionrate);
          costMsg.setUomname(itemObj.costs.uomname);
          costMsg.setUomvalue(itemObj.costs.uomvalue);
          itemMsg.setCosts(costMsg);
        }

        // itemQtyFormula
        if (itemObj.itemqtyformula) {
          const formulaMsg = new GlobalItemQtyFormula();
          formulaMsg.setItemqty(itemObj.itemqtyformula.itemqty);
          formulaMsg.setForevery(itemObj.itemqtyformula.forevery);
          formulaMsg.setTakeoffvariabletype(
            itemObj.itemqtyformula.takeoffvariabletype
          );
          formulaMsg.setAnd(itemObj.itemqtyformula.and || false);
          formulaMsg.setOr(itemObj.itemqtyformula.or || false);
          itemMsg.setItemqtyformula(formulaMsg);
        }

        // attribute
        if (itemObj.attribute) {
          const attrMsg = new GlobalAssemblyItemAttribute();
          attrMsg.setAttributevalueid(itemObj.attribute.attributevalueid);
          attrMsg.setAttributevaluename(itemObj.attribute.attributevaluename);
          attrMsg.setAttributegroupid(itemObj.attribute.attributegroupid);
          attrMsg.setAttributegroupname(itemObj.attribute.attributegroupname);
          itemMsg.setAttribute(attrMsg);
        }

        // quoteGroup
        if (itemObj.quotegroup) {
          const qgMsg = new GlobalQuoteGroup();
          qgMsg.setId(itemObj.quotegroup.id || "");
          qgMsg.setQuotegroup(itemObj.quotegroup.quotegroup || "");
          itemMsg.setQuotegroup(qgMsg);
        }

        return itemMsg;
      });

      msg.setItemsList(itemMsgs);
    }

    return msg;
  }

  /**
   * Create a new GlobalAssembly
   */
  public static async createGlobalAssembly(
    assembly: GlobalAssembly.AsObject
  ): Promise<CreateGlobalAssemblyResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateGlobalAssemblyRequest();
      // Build the message using our helper
      const globalAssemblyMsg = this.buildGlobalAssemblyMessage(assembly);

      req.setGlobalassembly(globalAssemblyMsg);
      req.setSessiontoken(this.getMetadata().sessionToken);
      client.createGlobalAssembly(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  /**
   * Update an existing GlobalAssembly
   */
  public static async updateGlobalAssembly(
    assembly: GlobalAssembly.AsObject
  ): Promise<UpdateGlobalAssemblyResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new UpdateGlobalAssemblyRequest();
      // Build the message using our helper
      const globalAssemblyMsg = this.buildGlobalAssemblyMessage(assembly);

      req.setGlobalassembly(globalAssemblyMsg);
      req.setSessiontoken(this.getMetadata().sessionToken);

      client.updateGlobalAssembly(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  /**
   * Delete a GlobalAssembly by assemblyId
   */
  public static async deleteGlobalAssembly(
    assemblyId: string
  ): Promise<DeleteGlobalAssemblyResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteGlobalAssemblyRequest();
      req.setAssemblyid(assemblyId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      console.log("req", req.toObject());
      client.deleteGlobalAssembly(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  /**
   * Fetch assemblies by folderId
   */
  public static async getAssembliesByFileId(
    fileId: string
  ): Promise<GetGlobalAssembliesByFolderIdResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetGlobalAssembliesByFolderIdRequest();
      req.setFolderid(fileId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      client.getGlobalAssembliesByFolderId(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }
}
