import { useState } from "react";
import {
  AttributeGroup,
  AttributeValue,
} from "../../../../api/protosCompiled/globalItem/globalItem_pb";
import { ItemService } from "../../../../api/GlobalItemService";
import { useFetchAttributeGroups } from "../../../../hooks/useFetchAttributeGroups";
import { useMutation, useQueryClient } from "react-query";
import { useFetchUser } from "../../../../hooks/useFetchUsers";
import { useGetMasterFormatDivision } from "../../../../hooks/useGetMasterFormatDivision";
import { MasterFormatDivision } from "../../../../api/protosCompiled/masterFormatDivision/masterFormatDivision_pb";
import { filterAttributeGroups } from "./helper";
import { AttributeValuesTable } from "./AttributeValuesTable";
import { UB_AccountUser } from "../../../../api/protosCompiled/ub_users/ub_users_pb";
import { AttributesTable } from "./AttributesTable";
import { ManageAttributeDialog } from "./ManageAttributeDialog";
import { Dialog, DialogContent } from "@mui/material";

export const handleError = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
  alert("Error creating or updating attribute group")
}

export const CreateAttribute = () => {
  const queryClient = useQueryClient();
  const { data: user } = useFetchUser();
  const { data: attributeGroups } = useFetchAttributeGroups();
  const { data: masterFormatDivision } = useGetMasterFormatDivision();

  // State variables
  const [newAttributeGroupName, setNewAttributeGroupName] = useState("");
  const [editingAttributeGroupId, setEditingAttributeGroupId] = useState<
    number | null
  >(null);
  const [selectedDivision, setSelectedDivision] =
    useState<MasterFormatDivision.AsObject | null>(null);
  const [filterDivision, setFilterDivision] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedAttributeValue, setSelectedAttributeValue] =
    useState<AttributeValue.AsObject | null>(null);

  const handleOpenDialog = (attributeValue: AttributeValue.AsObject | null) => {
    if (!editingAttributeGroupId) {
      alert("No attribute group is selected. Please select a group first.");
      return;
    }
    setSelectedAttributeValue({
      attributegroupid: editingAttributeGroupId,
      attributevalueid: attributeValue?.attributevalueid || 0,
      itemattributevaluemapid: attributeValue?.itemattributevaluemapid || 0,
      attributevaluename: attributeValue?.attributevaluename || "",
      attributevariablesList: attributeValue?.attributevariablesList || [],
      upc: attributeValue?.upc || "",
      attributegroupname: attributeValue?.attributegroupname || "",
    });
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedAttributeValue(null);
  };

  // Filtered attribute groups
  const filteredAttributeGroups = filterAttributeGroups(
    filterDivision,
    attributeGroups
  );

  // Find the selected attribute group
  const selectedAttributeGroup = attributeGroups?.find(
    (group) => group.attributegroupid === editingAttributeGroupId
  );

  // Extract attribute values
  const attributeValues = selectedAttributeGroup?.attributevaluesList || [];

  // Mutations
  const { mutateAsync: createOrUpdateAttributeGroupMutate } = useMutation(
    ItemService.createOrUpdateAttributeGroup,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("attributeGroups");
        if (response && response.attributegroupid) {
          setEditingAttributeGroupId(response.attributegroupid);
        }
      },
    }
  );

  const { mutateAsync: deleteAttributeGroupMutate } = useMutation(
    ItemService.deleteAttributeGroup,
    {
      onSuccess: (_, attributeGroupId) => {
        queryClient.invalidateQueries("attributeGroups");
        if (editingAttributeGroupId === attributeGroupId) {
          setEditingAttributeGroupId(null);
          setNewAttributeGroupName("");
        }
      },
    }
  );

  const { mutateAsync: deleteAttributeValueMutate } = useMutation(
    ItemService.deleteAttributeValue,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("attributeGroups");
      },
    }
  );

  const handleCreateOrUpdateAttributeGroup = async () => {
    if (!newAttributeGroupName) return;

    const attributeGroupData: AttributeGroup.AsObject = {
      attributegroupid: editingAttributeGroupId || 0,
      attributegroupname: newAttributeGroupName,
      attributevaluesList: attributeValues, // Pass existing attribute values
      masterformatid: selectedDivision?.id || "",
    };

    try {
      await createOrUpdateAttributeGroupMutate(attributeGroupData);
    } catch (error) {
      console.error("Error creating or updating Attribute Group:", error);
    }
  };

  const handleEditAttributeGroup = (
    attributeGroup: AttributeGroup.AsObject
  ) => {
    setNewAttributeGroupName(attributeGroup.attributegroupname);
    setEditingAttributeGroupId(attributeGroup.attributegroupid);

    // Ensure all attribute values have the correct group ID
    if (attributeGroup.attributevaluesList) {
      attributeGroup.attributevaluesList.forEach((attrValue) => {
        attrValue.attributegroupid = attributeGroup.attributegroupid;
      });
    }

    // Find and set the selected division based on masterformatid
    const division = masterFormatDivision?.find(
      (div) => div.id === attributeGroup.masterformatid
    );
    setSelectedDivision(division || null);
  };

  const handleDeleteAttributeGroup = async (attributeGroupId: number) => {
    try {
      await deleteAttributeGroupMutate(attributeGroupId);
    } catch (error) {
      console.error("Error deleting Attribute Group:", error);
    }
  };

  const handleDeleteAttributeValue = async (attributeValueId: number) => {
    try {
      await deleteAttributeValueMutate(attributeValueId);
    } catch (error) {
      console.error("Error deleting Attribute Value:", error);
    }
  };

  /**
   * RESET button handler:
   * Clear out the editing group, but keep the user's division selection/filters.
   */
  const handleReset = () => {
    setEditingAttributeGroupId(null);
    setNewAttributeGroupName("");
    // We do NOT reset filterDivision or selectedDivision here,
    // ensuring that context remains.
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <h4 style={{ marginBottom: "20px" }}>Manage Attribute Groups</h4>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        <label style={{ marginRight: "10px" }}>
          Attribute Group Name:
          <input
            type="text"
            placeholder="Attribute Group Name"
            value={newAttributeGroupName}
            onChange={(e) => setNewAttributeGroupName(e.target.value)}
            style={{
              width: "300px",
              marginLeft: "10px",
              padding: "5px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          />
        </label>
        <select
          value={selectedDivision?.divisionnumber || ""}
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const selectedValue = e.target.value;
            const selected = masterFormatDivision?.find(
              (division) => division.divisionnumber.toString() === selectedValue
            );
            setSelectedDivision(selected || null);
          }}
          style={{
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            marginRight: "10px",
          }}
        >
          <option value="">Select Division</option>
          {masterFormatDivision?.map((division) => (
            <option
              key={division.divisionnumber}
              value={division.divisionnumber}
            >
              {`${division.divisionnumber} - ${division.divisionname}`}
            </option>
          ))}
        </select>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleCreateOrUpdateAttributeGroup()
          }}
          style={{
            padding: "10px 15px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          {editingAttributeGroupId
            ? "Update Attribute Group"
            : "Add Attribute Group"}
        </button>

        {/* New Reset Button */}
        {editingAttributeGroupId !== null && (
          <button
            onClick={handleReset}
            style={{
              padding: "10px 15px",
              backgroundColor: "#f44336",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              marginLeft: "10px",
            }}
          >
            Reset
          </button>
        )}
      </div>

      <div style={{ marginBottom: "20px" }}>
        <h5 style={{ marginBottom: "10px" }}>
          Manage Values for Attribute Group
        </h5>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <label style={{ marginRight: "10px" }}>Filter by Division:</label>
          <select
            value={filterDivision}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setFilterDivision(e.target.value)
            }}
            style={{
              padding: "5px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginLeft: "10px",
            }}
          >
            <option value="">All Divisions</option>
            {masterFormatDivision?.map((division) => (
              <option key={division.id} value={division.id}>
                {`${division.divisionnumber} - ${division.divisionname}`}
              </option>
            ))}
          </select>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <AttributesTable
            attributeGroups={filteredAttributeGroups || []}
            user={user as UB_AccountUser.AsObject}
            onEdit={handleEditAttributeGroup}
            onDelete={handleDeleteAttributeGroup}
          />
          <AttributeValuesTable
            attributeValues={attributeValues}
            user={user as UB_AccountUser.AsObject}
            onEdit={(attrValue) => handleOpenDialog(attrValue)}
            onDelete={(attrValueId) => handleDeleteAttributeValue(attrValueId)}
          />
        </div>
      </div>

      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="attribute-dialog-title"
      >
        <DialogContent
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1300,
          }}
        >
          <ManageAttributeDialog
            isOpen={isDialogOpen}
            onClose={handleDialogClose}
            attributeValue={selectedAttributeValue}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
