import React, { useState, useEffect, useCallback } from "react";
import { Box, TextField, Button } from "@mui/material";
import {
  formatCurrency,
  formatNumber,
  sxProductionCostOverrideValue,
  sxUnitCostOverrideValue,
} from "./closeoutHelpers";
import {
  useUpdateEstimateExtensionMutation,
  useUpdateChangeOrderExtensionMutation,
  useGetExtendEstimates,
  useGetExtendChangeOrder,
} from "../../hooks/useCloseoutHooks";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";

export const ExtentionRow: React.FC<{
  item: any;
  onOpenCreateItemTab: (itemId: string) => void;
}> = React.memo(({ item, onOpenCreateItemTab }) => {
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;

  const [unitCostDisplay, setUnitCostDisplay] = useState<string>(
    formatCurrency(item.isoverrideitemunitcost
      ? item.overrideitemunitcost
      : item.itemunitcost || 0)
  );
  const [productionRateDisplay, setProductionRateDisplay] = useState<string>(
    item.isoverridehourlyproductionrate
      ? item.overridehourlyproductionrate.toFixed(2)
      : item.hourlyproductionrate?.toFixed(2) || "0.00"
  );

  const { mutate: updateEstimateExtension } =
    useUpdateEstimateExtensionMutation();
  const { mutate: updateChangeOrderExtension } =
    useUpdateChangeOrderExtensionMutation();
  const { refetch: refetchEstimateExtension } = useGetExtendEstimates();
  const { refetch: refetchChangeOrderExtension } = useGetExtendChangeOrder();

  const refetchExtension = selectedEstimate?.estimateid
    ? refetchEstimateExtension
    : refetchChangeOrderExtension;
  const updateExtension = selectedEstimate?.estimateid
    ? updateEstimateExtension
    : updateChangeOrderExtension;

  // Determine if this row represents multiple (aggregated) items.
  const isAggregated =
    Array.isArray(item.aggregatedExtensions) && item.aggregatedExtensions.length > 1;

  useEffect(() => {
    setUnitCostDisplay(
      formatCurrency(item.isoverrideitemunitcost
        ? item.overrideitemunitcost
        : item.itemunitcost || 0)
    );
    setProductionRateDisplay(
      item.isoverridehourlyproductionrate
        ? item.overridehourlyproductionrate.toFixed(2)
        : item.hourlyproductionrate?.toFixed(2) || "0.00"
    );
  }, [item]);

  const handleUnitCostChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!regex.test(value) || Number(value) > 99999999.99) return;
      setUnitCostDisplay(value);
    },
    []
  );

  const commitUnitCostChange = useCallback(() => {
    if (isAggregated) {
      // For aggregated rows, update every underlying extension.
      const updatedItems = item.aggregatedExtensions.map((ext: any) => ({
        ...ext,
        isoverrideitemunitcost: true,
        overrideitemunitcost: Number(unitCostDisplay),
      }));
      updateExtension(
        { sessiontoken: "", extendestimatesList: updatedItems },
        {
          onSuccess: () => {
            refetchExtension();
          },
        }
      );
    } else {
      const updatedItem = {
        ...item,
        isoverrideitemunitcost: true,
        overrideitemunitcost: Number(unitCostDisplay),
      };
      updateExtension(
        { sessiontoken: "", extendestimatesList: [updatedItem] },
        {
          onSuccess: () => {
            refetchExtension();
          },
        }
      );
    }
  }, [item, unitCostDisplay, updateExtension, refetchExtension, isAggregated]);

  const handleProductionRateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!regex.test(value) || Number(value) > 99999999.99) return;
      setProductionRateDisplay(value);
    },
    []
  );

  const commitProductionRateChange = useCallback(() => {
    if (isAggregated) {
      const updatedItems = item.aggregatedExtensions.map((ext: any) => ({
        ...ext,
        isoverridehourlyproductionrate: true,
        overridehourlyproductionrate: Number(productionRateDisplay),
      }));
      updateExtension(
        { sessiontoken: "", extendestimatesList: updatedItems },
        {
          onSuccess: () => {
            refetchExtension();
          },
        }
      );
    } else {
      const updatedItem = {
        ...item,
        isoverridehourlyproductionrate: true,
        overridehourlyproductionrate: Number(productionRateDisplay),
      };
      updateExtension(
        { sessiontoken: "", extendestimatesList: [updatedItem] },
        {
          onSuccess: () => {
            refetchExtension();
          },
        }
      );
    }
  }, [item, productionRateDisplay, updateExtension, refetchExtension, isAggregated]);

  const handleResetOverrides = useCallback(() => {
    if (isAggregated) {
      const updatedItems = item.aggregatedExtensions.map((ext: any) => ({
        ...ext,
        isoverrideitemunitcost: false,
        overrideitemunitcost: 0,
        isoverridehourlyproductionrate: false,
        overridehourlyproductionrate: 0,
      }));
      updateExtension(
        { sessiontoken: "", extendestimatesList: updatedItems },
        {
          onSuccess: () => {
            refetchExtension();
          },
        }
      );
    } else {
      const updatedItem = {
        ...item,
        isoverrideitemunitcost: false,
        overrideitemunitcost: 0,
        isoverridehourlyproductionrate: false,
        overridehourlyproductionrate: 0,
      };
      updateExtension(
        { sessiontoken: "", extendestimatesList: [updatedItem] },
        {
          onSuccess: () => {
            refetchExtension();
          },
        }
      );
    }
  }, [item, updateExtension, refetchExtension, isAggregated]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        padding: "4px 8px",
        textOverflow: "ellipsis",
      }}
    >
      <Box
        sx={{
          flex: 2,
          textAlign: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        title={item.itemname || "N/A"}
      >
        {item.itemname || "N/A"}
      </Box>
      <Box
        sx={{
          flex: 2,
          textAlign: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        title={item.attributegroupname || "N/A"}
      >
        {item.attributegroupname || "N/A"}
      </Box>

      <Box sx={{ flex: 1, textAlign: "center" }}>
        {item.totalitemqty || 0}
      </Box>
      <Box sx={{ flex: 1, textAlign: "center" }}>
        {item.itemuomname || "N/A"}
      </Box>
      <Box sx={{ flex: 1, textAlign: "center" }}>
        <TextField
          type="text"
          size="small"
          value={unitCostDisplay}
          onChange={handleUnitCostChange}
          onBlur={commitUnitCostChange}
          inputProps={{
            min: 0,
            max: 99999999.99,
            step: "0.01",
            "aria-label": "Unit cost override",
          }}
          InputProps={{ style: sxUnitCostOverrideValue(item) }}
          disabled={item.isquoted}
        />
      </Box>
      <Box sx={{ flex: 2, textAlign: "center" }}>
        {formatCurrency(item.totalcost) || 0}
      </Box>
      <Box sx={{ flex: 2, textAlign: "center" }}>
        <TextField
          type="number"
          size="small"
          value={productionRateDisplay}
          onChange={handleProductionRateChange}
          onBlur={commitProductionRateChange}
          inputProps={{
            min: 0,
            max: 99999999.99,
            step: "0.01",
            "aria-label": "Hourly production rate override",
          }}
          InputProps={{ style: sxProductionCostOverrideValue(item) }}
        />
      </Box>
      <Box sx={{ flex: 2, textAlign: "center" }}>
        {formatNumber(item.totaldirectlaborhours)}
      </Box>
      <Box sx={{ flex: 2, textAlign: "center" }}>
        <Button
          onClick={() => onOpenCreateItemTab(item.itemid)}
          variant="contained"
          size="small"
        >
          Edit Item
        </Button>
        <Button
          onClick={handleResetOverrides}
          variant="outlined"
          color="secondary"
          size="small"
          sx={{ ml: 1 }}
        >
          Re-extend
        </Button>
      </Box>
    </Box>
  );
});
