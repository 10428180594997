import {
  Box,
  Button,
  Popover,
  Typography,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { useStore } from "zustand";
import { useHandleSetCreateStates } from "../hooks/useHandleSetCreateStates";
import { useHandleGlobalStateState } from "../hooks/useHandleGlobalState";
import { useUnityBuildStore } from "../states/store";
import { UserPicture } from "./UserPicture";
import { Settings } from "./settings/Settings";
import LogIn from "./LogIn";
import { useFetchUser } from "../hooks/useFetchUsers";
import PersonIcon from "@mui/icons-material/Person";
import { useAssemblyStore } from "../states/AssemblyStore";
import { CreateAssembly } from "./assembliesAndItems/assemblies/CreateAssembly";
import { GlobalAssembly } from "../api/protosCompiled/globalAssembly/globalAssembly_pb";

export function MainMenu() {

  const userBackgroundColor = useStore(useUnityBuildStore).userBackgroundColor;
  const isFetchingToken = useStore(useUnityBuildStore).isFetchingToken;
  const isLoggedIn = useStore(useUnityBuildStore).isLoggedIn;
  const selectedProject = useStore(useUnityBuildStore).selectedProject;
  const isOpeningViewport = useStore(useUnityBuildStore).isOpeningViewport;
  const isViewingExtension = useStore(useUnityBuildStore).isViewingExtension;
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  const selectedContract = useStore(useUnityBuildStore).selectedContract;
  const selectedChangeOrder = useStore(useUnityBuildStore).selectedChangeOrder;
  const setIsViewingExtension = useStore(useUnityBuildStore).setIsViewingExtension;

  const assemblyOption = useStore(useAssemblyStore).assemblyOption;
  const currentGlobalAssembly = useStore(useAssemblyStore).currentGlobalAssembly;
  const isViewingAssemblyForm = useStore(useAssemblyStore).isViewingAssemblyForm;
  const setIsViewingAssemblyForm = useStore(useAssemblyStore).setIsViewingAssemblyForm;
  const setAssemblyOption = useStore(useAssemblyStore).setAssemblyOption;
  const setIsCreatingAssembly = useStore(useAssemblyStore).setIsCreatingAssembly;

  const { data: user, isLoading: isLoadingUser } = useFetchUser();

  const handleSetGlobalState = useHandleGlobalStateState();
  const handleSetCreateStates = useHandleSetCreateStates();

  const [loginDialogOpen, setLoginDialogOpen] = React.useState(false);

  const handleOpenLoginDialog = () => {
    setLoginDialogOpen(true);
  };

  const handleCloseLoginDialog = () => {
    setLoginDialogOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSettings = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "settings-popover" : undefined;

  return (
    <Box
      sx={{
        backgroundColor: userBackgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between", // Pushes elements to edges
        padding: "8px", // Optional: Adjust padding to your liking
        zIndex: 1000,
      }}
    >
      {/* Render CreateAssembly when currentTakeoffAssembly is set and menuOption is "Edit Assembly" */}
      <Dialog
        open={isViewingAssemblyForm && assemblyOption === "global"}
        fullScreen={true}
        style={{
          height: "100vh",
          backgroundColor: userBackgroundColor,
        }}
      >
        <DialogContent>
          <CreateAssembly
            assemblyOption="global"
            currentAssembly={
              currentGlobalAssembly as GlobalAssembly.AsObject
            }
          />
        </DialogContent>
      </Dialog>
      {isLoggedIn ? (
        <>
          {isLoadingUser ? <CircularProgress /> : null}
          {/* Left Section: Icon and Buttons */}
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Button onClick={handleOpenSettings}>
              {!isLoadingUser && user && user?.user?.userphotourl ? (
                <UserPicture user={user as any} />
              ) : (
                <PersonIcon />
              )}
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseSettings}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Settings
                onCloseMenu={handleCloseSettings}
                onLogout={() => setLoginDialogOpen(false)}
              />
            </Popover>
            <Button
              onClick={() =>
                handleSetGlobalState({
                  resetOption: "resetAssembly",
                  setStateFunctions: [
                    {
                      setStateFunction: setIsViewingAssemblyForm,
                      prop: true,
                    },
                    {
                      setStateFunction: setIsCreatingAssembly,
                      prop: true,
                    },
                    {
                      setStateFunction: setAssemblyOption,
                      prop: "global",
                    },
                  ],
                })
              }
              color="inherit"
              sx={{
                border: "1px solid black",
                margin: "2px",
                position: "relative",
              }}
            >
              Items & Assemblies
            </Button>
          </div>

          {selectedProject.id && (
            <Button
              color="inherit"
              sx={{ border: "1px solid black", margin: "2px" }}
              onClick={() =>
                handleSetGlobalState({
                  resetOption: "returnHome",
                })
              }
            >
              Project List
            </Button>
          )}
          {selectedProject.id && (
            <>
              {(selectedEstimate?.estimateid ||
                selectedChangeOrder?.changeorderid ||
                selectedContract?.contractid) && (
                  <Button
                    color="inherit"
                    sx={{ border: "1px solid black", margin: "2px" }}
                    onClick={() =>
                      handleSetGlobalState({
                        resetOption: "returnToTakeoffList",
                      })
                    }
                  >
                    Takeoff Lists
                  </Button>
                )}
              {isOpeningViewport && (
                <Button
                  color="inherit"
                  sx={{ border: "1px solid black", margin: "2px" }}
                  onClick={() =>
                    handleSetCreateStates(
                      setIsViewingExtension,
                      !isViewingExtension
                    )
                  }
                >
                  Closeout
                </Button>
              )}
              {/* Right Section: Project/Estimate Info */}
              <div style={{ marginLeft: "auto", alignSelf: "center" }}>
                {selectedProject.name && (
                  <>Project: {selectedProject.name} / </>
                )}
                {selectedEstimate?.name && (
                  <>Estimate: {selectedEstimate.name}</>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {isFetchingToken ? (
            <Typography>Loading...</Typography>
          ) : (
            <Button
              type="submit"
              variant="contained"
              onClick={handleOpenLoginDialog}
            >
              Login
            </Button>
          )}
          <Dialog
            open={loginDialogOpen}
            onClose={handleCloseLoginDialog}
            aria-labelledby="login-dialog-title"
          >
            <DialogContent>
              <LogIn />
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
}
