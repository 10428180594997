import "../App.css";
import AppBar from "@mui/material/AppBar";
import { angleOptionMultipliers, useUnityBuildStore } from "../states/store";
import { ResetStates } from "../states/resetStates";
import { useSubmitTakeoffAssembly } from "../hooks/useSubmitTakeoffAssembly";
import { useFetchCountedAssemblies } from "../hooks/useFetchCountedAssemblies";
import { useEffect, useRef, useState } from "react";
import { CustomScale } from "./CustomScale";
import { useStore } from "zustand";
import { useAssemblyStore } from "../states/AssemblyStore";
import { SCALE_OPTIONS } from "../utils/constants";
import { ToolBarHelper } from "../hooks/toolbarHooks";
import { useFetchObjects } from "../hooks/objectHooks";
import { DocumentManagerToggle } from "./documentComponents/DocumentManagerToggle";
import { IconButton } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Object as ProtoObject } from "../api/protosCompiled/object/object_pb";
import { useKeyBoardEventStore } from "../states/keyEventStore";

type ExtendedObject = ProtoObject.AsObject & {
  previewUrl: string;
};

export const ToolBar = () => {
  const ubs = useStore(useUnityBuildStore);
  const userBackgroundColor = useStore(useUnityBuildStore).userBackgroundColor;
  const setAngleOptions = useStore(useUnityBuildStore).setAngleOptions;
  const isAnnotating = useStore(useUnityBuildStore).isAnnotating;
  const setIsAnnotating = useStore(useUnityBuildStore).setIsAnnotating;
  const setUserScale = useStore(useUnityBuildStore).setUserScale;
  const selectedObject = useStore(useUnityBuildStore).selectedObject;
  const setSelectedObject = useStore(useUnityBuildStore).setSelectedObject;
  const keyBoardEventStore = useStore(useKeyBoardEventStore);
  const globalAssemblyStore = useStore(useAssemblyStore);

  const enableCrosshair = useStore(useKeyBoardEventStore).enableCrosshair;
  const isMeasuring = useStore(useKeyBoardEventStore).isMeasuring;
  const isViewingTakeoffPallette = useStore(useKeyBoardEventStore).isViewingTakeoffPallette;
  const setIsViewingTakeoffPallette = useStore(useKeyBoardEventStore).setIsViewingTakeoffPallette;
  const setIsMeasuring = useStore(useKeyBoardEventStore).setIsMeasuring;
  const snapMode = useStore(useKeyBoardEventStore).snapMode;
  const continousMode = useStore(useKeyBoardEventStore).continousMode;
  const setEnableCrosshair = useStore(useKeyBoardEventStore).setEnableCrosshair;
  const setContinousMode = useStore(useKeyBoardEventStore).setContinousMode;

  const countedAssemblies = useStore(useUnityBuildStore).countedAssemblies;

  const { data: fetchedAssemblies } = useFetchCountedAssemblies();
  const { data: availableObjects, refetchObjects } = useFetchObjects();

  const [isCreatingScale, setIsCreatingScale] = useState<boolean>(false);
  const [isViewingFileManager, setIsViewingFileManager] = useState(false);

  const [selectedDocument, setSelectedDocument] =
    useState<ExtendedObject | null>(null);

  const submitAssembly = useSubmitTakeoffAssembly();

  const [documentsWithTakeoff, setDocumentsWithTakeoff] = useState<string[]>(
    []
  );

  const [shouldSubmit, setShouldSubmit] = useState(false);

  const localScale = useRef<number>(0);

  //we must set the local and global scales to the same value when the selected object changes
  useEffect(() => {
    localScale.current = selectedObject?.userscalesList[0]?.scale || 0;
    setUserScale(selectedObject?.userscalesList[0]?.scale || 0);
    // eslint-disable-next-line
  }, [selectedObject]);

  // Update documentsWithTakeoff
  useEffect(() => {
    if (countedAssemblies) {
      const uniqueObjectIds = countedAssemblies.map(
        (assembly) => assembly.assembliesList[0].objectid
      );
      setDocumentsWithTakeoff(uniqueObjectIds);
    }
  }, [countedAssemblies]);

  // Return true if object id is in the list of documents with takeoff
  const isDocumentWithTakeoff = (id: string) => {
    return documentsWithTakeoff.includes(id);
  };

  // When localScale changes, update assemblies
  useEffect(() => {
    if (!shouldSubmit) return;

    const submitAssembliesWithUpdatedScale = async () => {
      try {
        if (!fetchedAssemblies || fetchedAssemblies.length === 0 || localScale.current <= 0) return;
        for (const takeoff of fetchedAssemblies) {
          for (const assembly of takeoff.assembliesList) {
            if (
              (assembly.assemblymeasurementtype === "length" ||
                assembly.assemblymeasurementtype === "area") &&
              assembly.objectid === selectedObject?.id
            ) {
              await submitAssembly({
                shouldUpdateBreakout: false,
                assembly,
                isUpdating: true,
                isCreating: false,
                pageScale: localScale.current,
              });
            }
          }
        }
        setUserScale(localScale.current);
        setShouldSubmit(false);
        refetchObjects();
      } catch (error) {
        console.error("Error updating assemblies with new scale:", error);
      }
    };

    submitAssembliesWithUpdatedScale();
    //eslint-disable-next-line
  }, [shouldSubmit, fetchedAssemblies, selectedObject?.id]);


  // Toggle the takeoff palette visibility
  const handleIsViewingTakeoffPallette = () => {
    setIsViewingTakeoffPallette(!isViewingTakeoffPallette);
  };

  // Toggle file manager
  const handleFileManager = () => {
    setIsViewingFileManager(!isViewingFileManager);
  };

  const handleGetObject = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (availableObjects) {
      setSelectedObject(availableObjects[e.target.selectedIndex - 1] || null);
      setSelectedDocument(availableObjects[e.target.selectedIndex - 1] || null);
    }
  };

  // Open the doc in a new tab
  const handleOpenDocument = () => {
    if (selectedDocument?.previewUrl) {
      window.open(selectedDocument.previewUrl, "_blank", "noopener,noreferrer");
    } else {
      alert("Please select a document to open.");
    }
  };

  // Set scale from a <select>
  const handleSetScale = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setShouldSubmit(true);
    localScale.current = parseFloat(e.target.value);
    ToolBarHelper.handleSetScale(
      e,
      setUserScale,
      setIsCreatingScale,
      selectedObject,
      refetchObjects
    );
  };

  // Toggle crosshair
  const handleEnableCrosshair = () => {
    setEnableCrosshair(!enableCrosshair);
  };

  // Start/stop measuring
  const handleMeasure = () => {
    ResetStates.resetTakeoffStates(ubs, globalAssemblyStore, keyBoardEventStore);
    setIsAnnotating(!isAnnotating);
    setIsMeasuring(!isMeasuring);
  };

  // Angle snaps
  const handleSetSnaps = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let angleOption = parseInt(e.target.value, 10);
    const angleOptions = Array.from(
      { length: 360 / angleOption },
      (_, i) => i * angleOption
    );
    setAngleOptions(angleOptions);
  };

  // Our toggle for "continuous mode" or "non-continuous mode"
  const handleToggleContinuousMode = () => {
    setContinousMode(!continousMode); // flip it in the Zustand store
  };

  return (
    <>
      <AppBar position="relative">
        <div
          className="toolbar"
          style={{ backgroundColor: userBackgroundColor }}
        >
          <label
            key="drawings"
            style={{ marginRight: "5px", marginLeft: "5px" }}
          >
            <button onClick={handleFileManager}>Document:</button>
          </label>
          <select
            onChange={handleGetObject}
            style={{ margin: "1px", cursor: "pointer", width: "200px" }}
            defaultValue=""
          >
            <option value="" disabled>
              Select a document
            </option>
            {availableObjects &&
              availableObjects.map((option, index) => (
                <option
                  key={index}
                  value={`${option.id},${option.gcsobjectname}`}
                  style={{
                    backgroundColor: isDocumentWithTakeoff(option.id)
                      ? "lightYellow"
                      : "white",
                    padding: "15px 10px",
                    fontWeight: selectedDocument?.id === option.id ? "bold" : "normal",
                  }}
                >
                  {option.objectname}
                </option>
              ))}
          </select>
          {selectedDocument ? (
            <IconButton
              onClick={handleOpenDocument}
              style={{ marginLeft: "5px" }}
              aria-label="Open document"
            >
              <OpenInNewIcon />
            </IconButton>
          ) : null}
          <label key="Scale" style={{ marginRight: "5px", marginLeft: "5px" }}>
            Scale:
            <select
              onChange={handleSetScale}
              value={localScale.current || 0}
              style={{ margin: "1px", cursor: "pointer" }}
            >
              {SCALE_OPTIONS.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </label>
          <button
            onClick={handleEnableCrosshair}
            className="button"
            style={{ color: enableCrosshair ? "blue" : "black" }}
          >
            Crosshair
          </button>
          <button
            onClick={handleMeasure}
            className="button"
            style={{ color: isMeasuring ? "blue" : "black" }}
          >
            Measure
          </button>

          <label style={{ marginRight: "5px", marginLeft: "5px" }}>
            Angle Snap:
            <select
              onChange={handleSetSnaps}
              style={{
                marginLeft: "10px",
                backgroundColor: snapMode ? "green" : "white",
              }}
            >
              {angleOptionMultipliers.map((angle) => (
                <option key={angle} value={angle}>
                  {angle}
                </option>
              ))}
            </select>
          </label>

          {/* Toggle continuous mode */}
          <button onClick={handleToggleContinuousMode}>
            {!continousMode ? "o / o" : "o-o-o"}
          </button>
          <span>{!continousMode ? "Non-Continuous Mode" : "Continuous Mode"}</span>
          <button
            onClick={handleIsViewingTakeoffPallette}
            style={{ position: "absolute", right: "20px" }}
          >
            {isViewingTakeoffPallette ? "Hide" : "Show"} Takeoff Pallette
          </button>
        </div>
        {isCreatingScale && <CustomScale />}
      </AppBar>

      {isViewingFileManager && (
        <DocumentManagerToggle onClose={() => setIsViewingFileManager(false)} />
      )}
    </>
  );
};
