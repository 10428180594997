import React, { useState, useEffect } from "react";
import { Delete } from "@mui/icons-material";
import { useStore } from "zustand";
import { AttributeValue } from "../../../api/protosCompiled/globalItem/globalItem_pb";
import { useGetDefaultQuoteExpenseTypes } from "../../../hooks/useDefaultCloseoutHooks";
import { useFetchItemsByFileId } from "../../../hooks/useFetchItemsByFileId";
import { EstimateQuoteGroup } from "../../../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { ChangeOrderQuoteGroup } from "../../../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { Button } from "@mui/material";
import {
  TakeoffAssemblyItem,
  TakeoffVariableType,
  takeoffVariableTypes,
} from "../../../types/AssemblyItemType";
import {
  GlobalAssemblyItem,
  GlobalQuoteGroup,
} from "../../../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { useAssemblyStore } from "../../../states/AssemblyStore";

export interface AddedItemRowProps {
  item: GlobalAssemblyItem.AsObject | TakeoffAssemblyItem;
  index: number;
}

type QuotGroupUnion =
  | GlobalQuoteGroup.AsObject
  | EstimateQuoteGroup.AsObject
  | ChangeOrderQuoteGroup.AsObject
  | null;

export const AddedItemRow: React.FC<AddedItemRowProps> = ({ item, index }) => {
  const currentGlobalAssembly = useStore(useAssemblyStore).currentGlobalAssembly;
  const currentTakeoffAssembly = useStore(useAssemblyStore).currentTakeoffAssembly;
  const setCurrentGlobalAssembly = useStore(useAssemblyStore).setCurrentGlobalAssembly;
  const setCurrentTakeoffAssembly = useStore(useAssemblyStore).setCurrentTakeoffAssembly;

  const { data: defaultQuoteGroups } = useGetDefaultQuoteExpenseTypes();
  const { data: items } = useFetchItemsByFileId(item.itemid);

  const [isQuoted, setIsQuoted] = useState<boolean>(item.isquoted || false);
  const [selectedAttributeValueId, setSelectedAttributeValueId] =
    useState<string>("");
  const [quantity, setQuantity] = useState<number>(
    item.itemqtyformula?.itemqty || 1
  );
  const [forEvery, setForEvery] = useState<number>(
    item.itemqtyformula?.forevery || 1
  );
  const defaultTakeoffVariable: TakeoffVariableType = "point";
  const [takeoffVariable, setTakeoffVariable] = useState<string>(
    item.itemqtyformula?.takeoffvariabletype || defaultTakeoffVariable
  );
  const [quoteGroup, setQuoteGroup] = useState<QuotGroupUnion>(null);

  // Initialize the state with the item values on mount
  useEffect(() => {
    if (item.attribute) {
      // Set selectedAttributeValueId to the attributevalueid of the current attribute
      setSelectedAttributeValueId(item.attribute.attributevalueid.toString());
    } else if (
      items &&
      items.length > 0 &&
      items[0].attributesList.length > 0
    ) {
      // If item doesn't have attributes, use the first attribute from items[0]
      setSelectedAttributeValueId(
        items[0].attributesList[0].attributevalueid.toString()
      );
    }
    setQuantity(item.itemqtyformula?.itemqty || 1);
    setForEvery(item.itemqtyformula?.forevery || 1);
    setTakeoffVariable(item.itemqtyformula?.takeoffvariabletype || "point");
    setIsQuoted(item.isquoted || false);
    setQuoteGroup(item?.quotegroup || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGlobalAssembly, currentTakeoffAssembly]);

  // Update formulas in currentGlobalAssembly or currentTakeoffAssembly
  useEffect(() => {
    const updateAssemblyItems = (assembly: any, setAssembly: any) => {
      const updatedFormulas = assembly?.itemsList?.map(
        (formula: any, idx: any) => {
          if (idx === index) {
            const attributesList: AttributeValue.AsObject[] =
              items && items.length > 0 ? items[0].attributesList : [];
            const selectedAttribute = attributesList.find(
              (attr) =>
                attr.attributevalueid.toString() === selectedAttributeValueId
            );

            return {
              ...formula,
              itemname: item.itemname,
              attribute: selectedAttribute || formula.attribute,
              isquoted: isQuoted,
              itemqtyformula: {
                ...formula.itemqtyformula,
                itemqty: quantity,
                forevery: forEvery,
                takeoffvariabletype: takeoffVariable,
                itemid: formula.itemid,
                isquoted: formula.isquoted || false,
                attributevalueid: selectedAttribute?.attributevalueid || 0,
                quotegroupid: selectedAttribute?.attributegroupid || "",
              },
              quotegroup: {
                id: quoteGroup?.id || "",
                quotegroup: quoteGroup?.quotegroup || "",
              },
            };
          }
          return formula;
        }
      );

      if (assembly && updatedFormulas) {
        setAssembly({
          ...assembly,
          itemsList: updatedFormulas,
        });
      }
    };

    if (currentGlobalAssembly) {
      updateAssemblyItems(currentGlobalAssembly, setCurrentGlobalAssembly);
    } else if (currentTakeoffAssembly) {
      updateAssemblyItems(currentTakeoffAssembly, setCurrentTakeoffAssembly);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedAttributeValueId,
    isQuoted,
    quantity,
    forEvery,
    takeoffVariable,
    quoteGroup,
    items,
  ]);

  const handleQuoteGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const selectedGroup = defaultQuoteGroups?.find(
      (group) => group.quotegroup === e.target.value
    );

    if (!selectedGroup) {
      console.warn("Selected quote group not found in defaultQuoteGroups.");
      return;
    }

    setQuoteGroup(selectedGroup);
  };

  // Handle isQuoted change
  const handleIsQuotedChange = () => {
    const toggleQuotedState = (assembly: any, setAssembly: any) => {
      const updatedFormulas = assembly?.itemsList?.map(
        (item: any, idx: number) => {
          if (idx === index) {
            return {
              ...item,
              isquoted: !isQuoted,
            };
          }
          return item;
        }
      );

      if (assembly && updatedFormulas) {
        setAssembly({
          ...assembly,
          itemsList: updatedFormulas,
        });
      }
    };

    if (currentGlobalAssembly) {
      toggleQuotedState(currentGlobalAssembly, setCurrentGlobalAssembly);
    } else if (currentTakeoffAssembly) {
      toggleQuotedState(currentTakeoffAssembly, setCurrentTakeoffAssembly);
    }

    setIsQuoted(!isQuoted);
  };

  const handleDeleteAssemblyItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (currentGlobalAssembly) {
      setCurrentGlobalAssembly((prevAssembly: any) => {
        const updatedFormulas = prevAssembly?.itemsList?.filter(
          (_: any, idx: number) => idx !== index
        );

        return {
          ...prevAssembly,
          itemsList: updatedFormulas,
        };
      });
    } else if (currentTakeoffAssembly) {
      setCurrentTakeoffAssembly((prevAssembly: any) => {
        const updatedFormulas = prevAssembly?.itemsList?.filter(
          (_: any, idx: number) => idx !== index
        );

        return {
          ...prevAssembly,
          itemsList: updatedFormulas,
        };
      });
    }
  };

  return (
    <>
      <tr key={item.itemid + index}>
        {/* isQuoted Checkbox */}
        <td>
          <input
            type="checkbox"
            checked={item.isquoted}
            onChange={handleIsQuotedChange}
          />
        </td>

        {/* Item/Description Select */}
        <td>
          <select
            value={selectedAttributeValueId}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setSelectedAttributeValueId(e.target.value);
            }}
            style={{ width: "95%" }}
          >
            {items &&
              items.length > 0 &&
              items[0].attributesList?.map((attr) => (
                <option
                  key={attr.attributevalueid}
                  value={attr.attributevalueid.toString()}
                >
                  {generateAttributeLabel(attr, item)}
                </option>
              ))}
          </select>

          {/* Conditional quoteGroup selection */}
          {isQuoted && defaultQuoteGroups && (
            <div style={{ marginTop: "5px" }}>
              <label htmlFor={`quoteGroup-${index}`}>Quote Group:</label>
              <select
                id={`quoteGroup-${index}`}
                value={quoteGroup ? quoteGroup.quotegroup : ""}
                onChange={handleQuoteGroupChange}
                style={{ width: "95%" }}
              >
                {defaultQuoteGroups?.map((group) => (
                  <option key={group.quotegroup} value={group.quotegroup}>
                    {group.quotegroup}
                  </option>
                ))}
              </select>
            </div>
          )}
        </td>

        {/* Quantity */}
        <td>
          <input
            aria-label="Qty"
            type="number"
            step="any"
            value={Math.round(quantity * 100) / 100}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const value = e.target.value;
              const parsedValue = parseFloat(value);
              setQuantity(isNaN(parsedValue) ? 0 : parsedValue);
            }}
          />
        </td>

        {/* For every */}
        <td>
          <input
            type="number"
            step="any"
            value={Math.round(forEvery * 100) / 100}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const value = e.target.value;
              const parsedValue = parseFloat(value);
              setForEvery(isNaN(parsedValue) ? 1 : parsedValue);
            }}
          />
        </td>

        {/* Takeoff variable */}
        <td>
          <select
            value={takeoffVariable}
            onChange={(e) => setTakeoffVariable(e.target.value)}
          >
            {currentGlobalAssembly?.assemblymeasurementtype === "count" || currentTakeoffAssembly?.assemblymeasurementtype === "count" ? (
              <option value="point">point</option>
            ) : currentGlobalAssembly?.assemblymeasurementtype === "length" || currentTakeoffAssembly?.assemblymeasurementtype === "length" ? (
              // Exclude "area" for length measurement type
              takeoffVariableTypes
                .filter((type) => type !== "area")
                .map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))
            ) : (
              // Include all options for other types (e.g., "area")
              takeoffVariableTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))
            )}
          </select>
        </td>

        {/* Delete Button */}
        <td>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleDeleteAssemblyItem(e);
            }}
          >
            <Delete />
          </Button>
        </td>
      </tr>
    </>
  );
};

// Generate the label for each attribute
export type AttributeValueName = Pick<AttributeValue.AsObject, "attributevaluename">;

export const generateAttributeLabel = (attr: AttributeValueName, item: GlobalAssemblyItem.AsObject) => {
  return `${attr.attributevaluename.trim()} - ${item.itemname.trim()}`;
}

