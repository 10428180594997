import { useState } from "react";
import { SketchPicker } from "react-color";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { MainAdminPanel } from "./MainAdminPanel";
import { userIsDevAccount } from "../../utils/accessControl";
import { useFetchUser } from "../../hooks/useFetchUsers";
import { Button, Dialog, DialogContent } from "@mui/material";
import { useHandleLogOut } from "./useHandleLogOut";
import { AccountSettings } from "./AccountSettings";
import { CloseoutSettings } from "./CloseoutSettings";
import { UB_Account_User } from "../../api/protosCompiled/ub_account/ub_account_pb";
import { HowDoesThisWork } from "./HowDoesThisWork";

type SettingPanelOptions =
  | "adminPanel"
  | "userSettings"
  | "closeoutSettings"
  | "accountSettings"
  | "logout"
  | "howDoesThisWork";

export type SettingsProps = {
  onCloseMenu: () => void;
  onLogout: () => void;
};

export const Settings = ({ onCloseMenu, onLogout }: SettingsProps) => {
  const { userBackgroundColor, setUserBackgroundColor } =
    useStore(useUnityBuildStore);

  const { data: user } = useFetchUser();

  const [isAdminPanelOpen, setIsAdminPanelOpen] = useState(false);
  const [isUserSettingsOpen, setIsUserSettingsOpen] = useState(false);
  const [isCloseoutSettingsOpen, setIsCloseoutSettingsOpen] = useState(false);
  const [isAccountSettingsOpen, setIsAccountSettingsOpen] = useState(false);
  const [isHowDoesThisWorkOpen, setIsHowDoesThisWorkOpen] = useState(false);

  const handleColorChange = (color: any) => {
    setUserBackgroundColor(color.hex);
  };

  const handleLogOut = useHandleLogOut({ onCloseMenu, onLogout });

  const HandleOpenSetting = (option: SettingPanelOptions) => {
    switch (option) {
      case "adminPanel":
        setIsAdminPanelOpen(!isAdminPanelOpen);
        break;
      case "userSettings":
        setIsUserSettingsOpen(!isUserSettingsOpen);
        break;
      case "closeoutSettings":
        setIsCloseoutSettingsOpen(!isCloseoutSettingsOpen);
        break;
      case "accountSettings":
        setIsAccountSettingsOpen(!isAccountSettingsOpen);
        break;
      case "howDoesThisWork":
        setIsHowDoesThisWorkOpen(!isHowDoesThisWorkOpen);
        break;
      case "logout":
        handleLogOut();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignContent: "center",
        }}
      >
        <h4>Settings</h4>
        {user &&
          userIsDevAccount(
            user?.ubaccountid,
            user?.user as UB_Account_User.AsObject
          ) && (
            <Button onClick={HandleOpenSetting.bind(null, "adminPanel")}>
              Admin Panel
            </Button>
          )}
        <Button onClick={HandleOpenSetting.bind(null, "howDoesThisWork")}>
          Instructions
        </Button>
        <Button onClick={HandleOpenSetting.bind(null, "closeoutSettings")}>
          Closeout Settings
        </Button>
        <Button onClick={HandleOpenSetting.bind(null, "accountSettings")}>
          Account Settings
        </Button>
        <Button onClick={handleLogOut}>Log Out</Button>
        {!isAdminPanelOpen && (
          <div>
            <label>Background Color:</label>
            <SketchPicker
              color={userBackgroundColor}
              onChange={handleColorChange}
            />
          </div>
        )}
      </div>
      {/* -------------ADMIN PANEL OPENING DIALOGUE -------------- */}
      <Dialog
        open={isAdminPanelOpen}
        onClose={HandleOpenSetting.bind(null, "adminPanel")}
        aria-labelledby="admin-panel-title"
      >
        <DialogContent>
          <MainAdminPanel />
        </DialogContent>
      </Dialog>
      {/* -------------CLOSEOUT SETTINGS OPENING DIALOGUE -------------- */}
      <Dialog
        open={isCloseoutSettingsOpen}
        onClose={HandleOpenSetting.bind(null, "closeoutSettings")}
        aria-labelledby="closeout-settings-title"
        maxWidth="lg" // Ensure the dialog has enough width
        fullWidth // Make it span the full width available within the maxWidth
      >
        <DialogContent
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "900px",
            }}
          >
            <CloseoutSettings />
          </div>
        </DialogContent>
      </Dialog>
      {/* -------------ACCOUNT SETTINGS OPENING DIALOGUE -------------- */}
      <Dialog
        open={isAccountSettingsOpen}
        onClose={HandleOpenSetting.bind(null, "accountSettings")}
        aria-labelledby="account-settings-title"
        maxWidth="lg" // Ensure the dialog has enough width
        fullWidth // Make it span the full width available within the maxWidth
      >
        <DialogContent
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "900px",
            }}
          >
            <AccountSettings />
          </div>
        </DialogContent>
      </Dialog>
      {/* -------------HOW DOES THIS WORK OPENING DIALOGUE -------------- */}
      <Dialog
        open={isHowDoesThisWorkOpen}
        onClose={HandleOpenSetting.bind(null, "howDoesThisWork")}
        aria-labelledby="how-does-this-work-title"
        maxWidth="lg" // Ensure the dialog has enough width
        fullWidth // Make it span the full width available within the maxWidth
      >
        <DialogContent
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "900px",
            }}
          >
            <HowDoesThisWork />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
