import { useQuery } from "react-query";
import { ObjectService as objs } from "../api/ObjectService";
import { GenerateGETSignedUrlRequest } from "../api/protosCompiled/object/object_pb";
import { useUnityBuildStore } from "../states/store";

export const useFetchObject = (selectedObjectName: string | null) => {
  const { sessionToken } = useUnityBuildStore();

  return useQuery(
    ["object", selectedObjectName],
    async () => {
      if (!selectedObjectName || !sessionToken) return null;

      const request = new GenerateGETSignedUrlRequest();
      request.setSessiontoken(sessionToken);
      request.setMethod("GET");
      request.setGcsobjectname(selectedObjectName);

      return await objs.handleGETGCSRequest(request, selectedObjectName);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedObjectName,
      //We should never need to refetch a document
      staleTime: Infinity,
      onError: (error) => {
        console.error("Error fetching object URL:", error);
      },
    }
  );
};
