import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useUnityBuildStore } from "../../states/store";
import {
  useCreateEstimateMutation,
  useUpdateEstimateMutation,
} from "../../hooks/useCreateProjectEstimateMutation";
import { useStore } from "zustand";
import { Estimate } from "../../api/protosCompiled/projectEstimate/projectEstimate_pb";
import { useFetchEstimates } from "../../hooks/useFetchProjectEstimates";
import { useGetMasterFormatDivision } from "../../hooks/useGetMasterFormatDivision";
import { MenuItem, Select } from "@mui/material";
import { MasterFormatDivision } from "../../api/protosCompiled/masterFormatDivision/masterFormatDivision_pb";

// Helper function to format date to "yyyy-MM-dd"
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0]; // Extract only the date part
};

export const CreateEstimate: React.FC = () => {

  const isCreatingEstimate = useStore(useUnityBuildStore).isCreatingEstimate;
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  const selectedProject = useStore(useUnityBuildStore).selectedProject;
  const setSelectedEstimate = useStore(useUnityBuildStore).setSelectedEstimate;
  const setIsCreatingEstimate = useStore(useUnityBuildStore).setIsCreatingEstimate;
  const setIsViewingEstimateForm = useStore(useUnityBuildStore).setIsViewingEstimateForm;

  const { data: estimates } = useFetchEstimates();
  const { data: masterFormatDivision } = useGetMasterFormatDivision();

  const { mutate: createEstimate } = useCreateEstimateMutation();
  const { mutate: updateEstimate } = useUpdateEstimateMutation();

  const [description, setDescription] = useState("");
  const [duedate, setDueDate] = useState<string>(
    formatDate(new Date().toISOString())
  );
  const [name, setName] = useState("");
  const [customid, setCustomId] = useState("");
  const [selectedDivision, setSelectedDivision] =
    useState<MasterFormatDivision.AsObject | null>(null);

  useEffect(() => {
    if (!isCreatingEstimate) {
      const selectedEstimateObj = estimates?.find(
        (estimate) =>
          estimate.estimate?.estimateid.trim() ===
          selectedEstimate?.estimateid.trim()
      );
      if (!selectedEstimateObj || !selectedEstimateObj.estimate) {
        setSelectedEstimate(null);
      } else {
        setSelectedEstimate(selectedEstimateObj.estimate);
        setDescription(selectedEstimateObj.estimate?.description ?? "");
        setDueDate(formatDate(selectedEstimateObj.estimate?.duedate ?? ""));
        setName(selectedEstimateObj.estimate?.name ?? "");
        setCustomId(selectedEstimateObj.estimate?.customid ?? "");
        setSelectedDivision(
          selectedEstimateObj.estimate?.masterformatdivision ?? null
        );
      }
    }
    // eslint-disable-next-line
  }, [selectedEstimate, estimates]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Ensure all fields are valid
    if (!selectedDivision) {
      alert("Please select a Master Format Division.");
      return;
    }

    const formData: Estimate.AsObject = {
      estimateid: selectedEstimate?.estimateid ?? "",
      description: description,
      duedate: duedate,
      projectid: selectedProject?.id || "",
      datecreated: selectedEstimate?.datecreated ?? "",
      name: name,
      customid: customid,
      createdbyuserid: selectedEstimate?.createdbyuserid ?? "",
      createdbyaccountid: selectedEstimate?.createdbyaccountid ?? "",
      lastupdated: "",
      masterformatdivision: selectedDivision,
    };

    if (isCreatingEstimate) {
      createEstimate(formData);
    } else {
      updateEstimate(formData);
    }
    setIsViewingEstimateForm(false);
    setIsCreatingEstimate(false);
    resetForm();
  };

  const handleEstimateFormCancel = () => {
    setIsCreatingEstimate(false);
    setIsViewingEstimateForm(false);
  };

  const resetForm = () => {
    setDescription("");
    setDueDate(formatDate(new Date().toISOString()));
    setName("");
    setCustomId("");
    setSelectedDivision(null);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="name"
        label="Estimate Name"
        rows={4}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Select
        required
        fullWidth
        value={selectedDivision?.id || ""}
        label="Division"
        displayEmpty
        onChange={(e) => {
          const selected = masterFormatDivision?.find(
            (division) => division.id === e.target.value
          );
          setSelectedDivision(selected || null);
        }}
      >
        <MenuItem value="" disabled>
          <em>Select Division</em>
        </MenuItem>
        {masterFormatDivision?.map((division) => (
          <MenuItem key={division.id} value={division.id}>
            {`${division.divisionnumber} - ${division.divisionname}`}
          </MenuItem>
        ))}
      </Select>

      <TextField
        margin="normal"
        fullWidth
        id="description"
        label="Estimate Description"
        multiline
        rows={4}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <TextField
        margin="normal"
        fullWidth
        id="customId"
        label="Estimate ID"
        value={customid}
        onChange={(e) => setCustomId(e.target.value)}
      />
      <TextField
        margin="normal"
        fullWidth
        id="dueDate"
        label="Due Date"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        value={duedate}
        onChange={(e) => setDueDate(e.target.value)}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
        {isCreatingEstimate ? "Create Estimate" : "Update Estimate"}
      </Button>
      <Button onClick={handleEstimateFormCancel}>Cancel</Button>
    </Box>
  );
};
