import { create } from "zustand";
import { GlobalItem } from "../api/protosCompiled/globalItem/globalItem_pb";

export type GlobalItemStore = {
  isCreatingItem: boolean;
  setIsCreatingItem: (isCreatingItem: boolean) => void;

  isViewingItemForm: boolean;
  setIsViewingItemForm: (isViewingItemForm: boolean) => void;

  currentItem: GlobalItem.AsObject | null;
  setCurrentItem: (item: GlobalItem.AsObject | null) => void;
};

export const useGlobalItemStore = create<GlobalItemStore>((set) => ({
  isCreatingItem: false,
  setIsCreatingItem: (isCreatingItem) => set({ isCreatingItem }),

  // The item form is also the assembly form, so toggleing
  isViewingItemForm: false,
  setIsViewingItemForm: (isViewingItemForm) => set({ isViewingItemForm }),

  currentItem: null,
  setCurrentItem: (item) => set({ currentItem: item }),
}));

export const defaultGlobalItem: GlobalItem.AsObject = {
  itemid: "", //in this case it is the file id
  itemname: "",
  isquoted: false,
  isactive: true,
  lastupdated: "",
  parentid: "",
  level: 0,
  entitytype: "",
  isfile: false,
  attributesList: [],
  costsList: [],
};
