import { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TableFooter,
  MenuItem,
  Select,
} from "@mui/material";
import { useGetTotalFinalPriceCloseouts, useGetTotalFinalPriceChangeOrderCloseouts } from "../../hooks/useCloseoutHooks";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { formatCurrency, formatNumber, safeDivide } from "./closeoutHelpers";
import { BreakoutAllocation } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";

export type AggregatedExtensionData = {
  breakoutId: string;
  breakoutName: string;
  totalCost: number;
  totalLaborHours: number;
};

type FooterTotals = {
  totalLaborHours: number;
};

type AllocationMethod = "percentage" | "fixed" | "default";

export const ScheduleOfValues = () => {
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  const [aggregatedExtensionData, setAggregatedExtensionData] = useState<AggregatedExtensionData[]>([]);
  const { data: estimatecloseouts } = useGetTotalFinalPriceCloseouts();
  const { data: changeOrdercloseouts } = useGetTotalFinalPriceChangeOrderCloseouts();

  const [allocationMethodValue, setAllocationMethodValue] = useState<AllocationMethod>("fixed");
  const [forceUpdate, setForceUpdate] = useState(false);

  const [totalsFullyAllocated, setTotalsFullyAllocated] = useState<boolean>(false);

  const closeouts = selectedEstimate?.estimateid ? estimatecloseouts : changeOrdercloseouts;

  useEffect(() => {
    if (
      closeouts &&
      (
        (!hasValidAllocation(closeouts.closeouts?.laborfactoringcloseout?.laborfactoringcloseoutList)) ||
        (!hasValidAllocation(closeouts.closeouts?.incidentallaborcloseout?.incidentallaborcloseoutList)) ||
        (!hasValidAllocation(closeouts.closeouts?.indirectlaborcloseout?.indirectlaborcloseoutList)) ||
        (!hasValidAllocation(closeouts.closeouts?.equipmentcloseout?.equipmentcloseoutList)) ||
        (!hasValidAllocation(closeouts.closeouts?.generalexpensecloseout?.generalexpensecloseoutList)) ||
        (!hasValidAllocation(closeouts.closeouts?.subcontractcloseout?.subcontractcloseoutList)) ||
        (!hasValidAllocation(closeouts.closeouts?.quotecloseout?.quotecloseoutList))
      )
    ) {
      setTotalsFullyAllocated(false);
    } else {
      setTotalsFullyAllocated(true);
    }
    //eslint-disable-next-line
  }, [closeouts]);

  // Helper function to check if allocation is valid
  const hasValidAllocation = (closeoutList: any) => {
    if (!closeoutList || closeoutList.length === 0) return true; // No allocations needed
    return isApproximately100(closeoutList[0]?.allocationList ?? []);
  };

  // Helper function to check if sum is approximately 100
  const isApproximately100 = (allocationList: BreakoutAllocation.AsObject[]) => {
    if (!allocationList || allocationList.length === 0) return false;

    const sum = allocationList.reduce((sum, data) => sum + data.allocationpercentage, 0);

    // Allow small floating point errors
    return Math.abs(sum - 100) < 0.01; // Adjust tolerance as needed
  };



  useEffect(() => {
    if (closeouts && closeouts?.closeouts?.extendestimate?.extendestimateList) {
      const breakoutMap: Record<string, AggregatedExtensionData> = {};
      const extensionList = closeouts.closeouts.extendestimate.extendestimateList;

      extensionList.forEach(ext => {
        const breakoutName = ext.breakoutname || "Unknown";
        const breakoutId = ext.breakoutid || "";

        if (!breakoutMap[breakoutName]) {
          breakoutMap[breakoutName] = {
            breakoutId,
            breakoutName,
            totalCost: 0,
            totalLaborHours: 0,
          };
        }

        breakoutMap[breakoutName].totalCost += ext.totalcost || 0;
        breakoutMap[breakoutName].totalLaborHours += ext.totaldirectlaborhours || 0;
      });

      const aggregatedData = Object.values(breakoutMap).sort((a, b) => a.breakoutName.localeCompare(b.breakoutName));
      setAggregatedExtensionData(aggregatedData);
    }
  }, [closeouts, estimatecloseouts, changeOrdercloseouts, allocationMethodValue, forceUpdate]);


  const totalLaborHours: FooterTotals = aggregatedExtensionData.reduce(
    (totals, data) => {
      totals.totalLaborHours += data.totalLaborHours;
      return totals;
    },
    { totalLaborHours: 0 }
  );

  const allocationPercentage = (
    data: AggregatedExtensionData,
    totalCosts: number[],
    allocationMethod: AllocationMethod,
    allocationList: BreakoutAllocation.AsObject[]
  ) => {

    if (!totalCosts || totalCosts.length === 0) {
      return { directLaborPercentage: 0, allocatedLaborHours: 0 };
    }

    let percentage = 0;
    let allocatedValue = 0;
    const totalCost = totalCosts.reduce((sum, value) => sum + value, 0);

    if (allocationMethod === "percentage" || allocationMethod === "default") {
      percentage = safeDivide(data.totalLaborHours, totalCosts[0]);
      allocatedValue = data.totalLaborHours * safeDivide(totalCosts[0], totalLaborHours.totalLaborHours);
    } else if (allocationMethod === "fixed" && allocationList.length > 0) {
      const percent = allocationList.find((alloc) => alloc.breakoutid === data.breakoutId)?.allocationpercentage || 0;
      percentage = safeDivide(percent, 100);
      allocatedValue = totalCost * percentage
    }

    return {
      directLaborPercentage: percentage * 100, // Convert back to percentage for display
      allocatedValue: allocatedValue,
    };
  };

  const sumTotal = (values: number[]) => {
    return values.reduce((sum, data) => sum + data, 0);
  };

  const handleAllocationChange = (e: any) => {
    setAllocationMethodValue(e.target.value as AllocationMethod);
    setForceUpdate((prev) => !prev);  // Toggle boolean state to force re-render
  };

  const calculateMaterialCostForBreakout = (breakoutId: string) => {
    //Examine each entry of extendestimateList and sum the total as a map of breakoutId to total cost
    const breakouts = closeouts?.closeouts?.extendestimate?.extendestimateList.filter((b) => b.breakoutid === breakoutId) || [];
    const breakoutMap: Record<string, number> = {};
    breakouts.forEach((b) => {
      if (!breakoutMap[b.breakoutid]) {
        breakoutMap[b.breakoutid] = 0;
      }
      breakoutMap[b.breakoutid] += b.totalcost || 0;
    });

    //get the sum of the total cost for all breakouts
    const sumTotalCost = closeouts?.closeouts?.extendestimate?.extendestimateList.reduce((sum, b) => sum + (b.totalcost || 0), 0) || 0;

    //Get the total cost from the closeout object
    const totalCost = closeouts?.closeouts?.extendestimate?.totalcost || 0;

    //Get the multiplier to apply to the breakoutMap
    const multiplier = safeDivide(totalCost, sumTotalCost);

    //Multiply the breakoutMap by the multiplier
    const value = Object.values(breakoutMap).reduce((sum, b) => sum + b * multiplier, 0);

    return value;

  };

  return (
    <div style={{
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    }}>
      <Typography variant="h6" align="center" gutterBottom>
        Allocation Method: {allocationMethodValue === "percentage" ? "% of Direct Labor" : "Fixed Allocations"}
        <Typography variant="body2" align="center" gutterBottom>
          {allocationMethodValue === "percentage"
            ? "(We auto allocate closeout expenses based on the % of direct labor for a given breakout.)"
            : "(We use fixed allocations from each cost category’s allocation list.)"}
        </Typography>

      </Typography>
      <div style={{ margin: "0 auto 16px", width: "250px" }}>
        <Select
          fullWidth
          value={allocationMethodValue}
          onChange={handleAllocationChange}
        >
          <MenuItem value="percentage">% of Direct Labor</MenuItem>
          <MenuItem value="fixed">Fixed Allocations</MenuItem>
        </Select>

      </div>
      {!totalsFullyAllocated && allocationMethodValue === "fixed" && (
        <Typography
          variant="body2"
          align="center"
          gutterBottom
          sx={{
            fontWeight: "bold", // Make the text bold
            color: "red", // Change the text color to red
            backgroundColor: "#fff3f3", // Add a light red background
            padding: "8px", // Add some padding for better readability
            borderRadius: "4px" // Optionally round the corners
          }}
        >
          Some closeouts are over or under allocated. Select "Allocations" at the bottom of the page to allocate closeouts.
        </Typography>
      )}
      <TableContainer component={Paper} style={{ marginTop: "16px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Breakout Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Total Estimate / Selling Price</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Material Cost</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Direct Labor %</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Direct Labor Hours</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Labor Factoring Hours</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Incidental Labor Hours</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Indirect Labor Hours</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Direct Labor Cost</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Labor Factoring Cost</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Incidental Labor Cost</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Indirect Labor Cost</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Equipment Cost</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>General Expense Cost</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Subcontract Cost</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Quote Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {aggregatedExtensionData.map((data, index) => {

              return (
                <TableRow key={index}>
                  <TableCell>{data.breakoutName}</TableCell>

                  {/*Total Estimate / Selling Price*/}
                  <TableCell style={{ color: !totalsFullyAllocated && allocationMethodValue === "fixed" ? "red" : "black" }}>{allocationMethodValue === "percentage"
                    ? formatCurrency(allocationPercentage(
                      data,
                      [
                        closeouts?.closeouts?.totalfinalprice || 0,
                      ], allocationMethodValue, []).allocatedValue ?? 0)
                    : formatCurrency(sumTotal(
                      [
                        calculateMaterialCostForBreakout(data.breakoutId),
                        allocationPercentage(data, [closeouts?.closeouts?.directlaborcloseout?.totalcost || 0], "default", []).allocatedValue ?? 0,
                        allocationPercentage(data, [closeouts?.closeouts?.laborfactoringcloseout?.totalcost || 0], allocationMethodValue,
                          closeouts?.closeouts?.laborfactoringcloseout?.laborfactoringcloseoutList[0]?.allocationList || []
                        ).allocatedValue ?? 0,
                        allocationPercentage(data, [closeouts?.closeouts?.incidentallaborcloseout?.totalcost || 0], allocationMethodValue,
                          closeouts?.closeouts?.incidentallaborcloseout?.incidentallaborcloseoutList[0]?.allocationList || []
                        ).allocatedValue ?? 0,
                        allocationPercentage(data, [closeouts?.closeouts?.indirectlaborcloseout?.totalcost || 0], allocationMethodValue,
                          closeouts?.closeouts?.indirectlaborcloseout?.indirectlaborcloseoutList[0]?.allocationList || []
                        ).allocatedValue ?? 0,
                        allocationPercentage(data, [closeouts?.closeouts?.equipmentcloseout?.totalcost || 0], allocationMethodValue,
                          closeouts?.closeouts?.equipmentcloseout?.equipmentcloseoutList[0]?.allocationList || []
                        ).allocatedValue ?? 0,
                        allocationPercentage(data, [closeouts?.closeouts?.generalexpensecloseout?.totalcost || 0], allocationMethodValue,
                          closeouts?.closeouts?.generalexpensecloseout?.generalexpensecloseoutList[0]?.allocationList || []
                        ).allocatedValue ?? 0,
                        allocationPercentage(data, [closeouts?.closeouts?.subcontractcloseout?.totalcost || 0], allocationMethodValue,
                          closeouts?.closeouts?.subcontractcloseout?.subcontractcloseoutList[0]?.allocationList || []
                        ).allocatedValue ?? 0,
                        allocationPercentage(data, [closeouts?.closeouts?.quotecloseout?.totalcost || 0], allocationMethodValue,
                          closeouts?.closeouts?.quotecloseout?.quotecloseoutList[0]?.allocationList || []
                        ).allocatedValue ?? 0,

                      ])
                    )}
                  </TableCell>

                  {/*Material Cost*/}
                  <TableCell>{formatCurrency(calculateMaterialCostForBreakout(data.breakoutId))}</TableCell>

                  {/*Direct Labor %*/}
                  <TableCell>{allocationPercentage(data, [totalLaborHours.totalLaborHours], "default", []).directLaborPercentage?.toFixed(2)}%</TableCell>

                  {/*Direct Labor Hours*/}
                  <TableCell>{formatNumber(allocationPercentage(data, [closeouts?.closeouts?.directlaborcloseout?.directlaborcloseoutList.reduce(
                    (sum, data) => sum + data.allocatedhours,
                    0
                  ) || 0], "default", []).allocatedValue ?? 0)}</TableCell>

                  {/*Labor Factoring Hours*/}
                  <TableCell>{formatNumber(allocationPercentage(data, [closeouts?.closeouts?.laborfactoringcloseout?.laborfactoringcloseoutList.reduce(
                    (sum, data) => sum + data.allocatedhours,
                    0
                  ) || 0], allocationMethodValue, closeouts?.closeouts?.laborfactoringcloseout?.laborfactoringcloseoutList[0]?.allocationList || []).allocatedValue ?? 0)}</TableCell>

                  {/*Incidental Labor Hours*/}
                  <TableCell>{formatNumber(allocationPercentage(data, [closeouts?.closeouts?.incidentallaborcloseout?.incidentallaborcloseoutList.reduce(
                    (sum, data) => sum + data.allocatedhours,
                    0
                  ) || 0], allocationMethodValue, closeouts?.closeouts?.incidentallaborcloseout?.incidentallaborcloseoutList[0]?.allocationList || []).allocatedValue ?? 0)}</TableCell>

                  {/*Indirect Labor Hours*/}
                  <TableCell>{formatNumber(allocationPercentage(data, [closeouts?.closeouts?.indirectlaborcloseout?.indirectlaborcloseoutList.reduce(
                    (sum, data) => sum + data.allocatedhours,
                    0
                  ) || 0], allocationMethodValue, closeouts?.closeouts?.indirectlaborcloseout?.indirectlaborcloseoutList[0]?.allocationList || []).allocatedValue ?? 0)}</TableCell>

                  {/*Direct Labor Cost*/}
                  <TableCell>{formatCurrency(allocationPercentage(data, [closeouts?.closeouts?.directlaborcloseout?.totalcost || 0], "default", []).allocatedValue ?? 0)}</TableCell>

                  {/*Labor Factoring Cost*/}
                  <TableCell>{formatCurrency(allocationPercentage(data, [closeouts?.closeouts?.laborfactoringcloseout?.totalcost || 0], allocationMethodValue,
                    closeouts?.closeouts?.laborfactoringcloseout?.laborfactoringcloseoutList[0]?.allocationList || []
                  ).allocatedValue ?? 0)}</TableCell>

                  {/*Incidental Labor Cost*/}
                  <TableCell>{formatCurrency(allocationPercentage(data, [closeouts?.closeouts?.incidentallaborcloseout?.totalcost || 0], allocationMethodValue,
                    closeouts?.closeouts?.incidentallaborcloseout?.incidentallaborcloseoutList[0]?.allocationList || []
                  ).allocatedValue ?? 0)}</TableCell>

                  {/*Indirect Labor Cost*/}
                  <TableCell>{formatCurrency(allocationPercentage(data, [closeouts?.closeouts?.indirectlaborcloseout?.totalcost || 0], allocationMethodValue,
                    closeouts?.closeouts?.indirectlaborcloseout?.indirectlaborcloseoutList[0]?.allocationList || []
                  ).allocatedValue ?? 0)}</TableCell>

                  {/*Equipment Cost*/}
                  <TableCell>{formatCurrency(allocationPercentage(data, [closeouts?.closeouts?.equipmentcloseout?.totalcost || 0], allocationMethodValue,
                    closeouts?.closeouts?.equipmentcloseout?.equipmentcloseoutList[0]?.allocationList || []
                  ).allocatedValue ?? 0)}</TableCell>

                  {/*General Expense Cost*/}
                  <TableCell>{formatCurrency(allocationPercentage(data, [closeouts?.closeouts?.generalexpensecloseout?.totalcost || 0], allocationMethodValue,
                    closeouts?.closeouts?.generalexpensecloseout?.generalexpensecloseoutList[0]?.allocationList || []
                  ).allocatedValue ?? 0)}</TableCell>

                  {/*Subcontract Cost*/}
                  <TableCell>{formatCurrency(allocationPercentage(data, [closeouts?.closeouts?.subcontractcloseout?.totalcost || 0], allocationMethodValue,
                    closeouts?.closeouts?.subcontractcloseout?.subcontractcloseoutList[0]?.allocationList || []
                  ).allocatedValue ?? 0)}</TableCell>

                  {/*Quote Cost*/}
                  <TableCell>{formatCurrency(allocationPercentage(data, [closeouts?.closeouts?.quotecloseout?.totalcost || 0], allocationMethodValue,
                    closeouts?.closeouts?.quotecloseout?.quotecloseoutList[0]?.allocationList || []
                  ).allocatedValue ?? 0)}</TableCell>

                </TableRow>
              );
            })}
          </TableBody>

          {/*----------------------------------Footer----------------------------------------*/}
          <TableFooter>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Totals</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatCurrency(closeouts?.closeouts?.sellingprice || closeouts?.closeouts?.totalfinalprice || 0)}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatCurrency(closeouts?.closeouts?.extendestimate?.totalcost || 0)}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{totalLaborHours.totalLaborHours.toFixed(2)}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatNumber(closeouts?.closeouts?.directlaborcloseout?.directlaborcloseoutList.reduce(
                (sum, data) => sum + data.allocatedhours,
                0
              ) || 0)}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatNumber(closeouts?.closeouts?.laborfactoringcloseout?.laborfactoringcloseoutList.reduce(
                (sum, data) => sum + data.allocatedhours,
                0
              ) || 0)}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatNumber(closeouts?.closeouts?.incidentallaborcloseout?.incidentallaborcloseoutList.reduce(
                (sum, data) => sum + data.allocatedhours,
                0
              ) || 0)}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatNumber(closeouts?.closeouts?.indirectlaborcloseout?.indirectlaborcloseoutList.reduce(
                (sum, data) => sum + data.allocatedhours,
                0
              ) || 0)}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatCurrency(closeouts?.closeouts?.directlaborcloseout?.totalcost || 0)}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatCurrency(closeouts?.closeouts?.laborfactoringcloseout?.totalcost || 0)}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatCurrency(closeouts?.closeouts?.incidentallaborcloseout?.totalcost || 0)}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatCurrency(closeouts?.closeouts?.indirectlaborcloseout?.totalcost || 0)}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatCurrency(closeouts?.closeouts?.equipmentcloseout?.totalcost || 0)}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatCurrency(closeouts?.closeouts?.generalexpensecloseout?.totalcost || 0)}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatCurrency(closeouts?.closeouts?.subcontractcloseout?.totalcost || 0)}</TableCell>
              <TableCell>{formatCurrency(closeouts?.closeouts?.quotecloseout?.totalcost || 0)}</TableCell>
            </TableRow>
            <TableRow sx={{ columnSpan: 6 }}>
              <TableCell style={{ fontWeight: "bold" }}>Total Labor Hours:</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{formatNumber(sumTotal([
                closeouts?.closeouts?.directlaborcloseout?.directlaborcloseoutList.reduce(
                  (sum, data) => sum + data.allocatedhours,
                  0
                ) || 0,
                closeouts?.closeouts?.laborfactoringcloseout?.laborfactoringcloseoutList.reduce(
                  (sum, data) => sum + data.allocatedhours,
                  0
                ) || 0,
                closeouts?.closeouts?.incidentallaborcloseout?.incidentallaborcloseoutList.reduce(
                  (sum, data) => sum + data.allocatedhours,
                  0
                ) || 0,
                closeouts?.closeouts?.indirectlaborcloseout?.indirectlaborcloseoutList.reduce(
                  (sum, data) => sum + data.allocatedhours,
                  0
                ) || 0,
              ]))}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
