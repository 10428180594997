import { useGoogleLogin } from "@react-oauth/google";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../states/store";
import { clients } from "../clients/grpcClients";

export function useGoogle_Login() {
  const {
    setIsFetchingToken,
    setSessionToken,
    setIsLoggedIn,
    setProjectListIsMounted,
  } = useStore(useUnityBuildStore);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => handleSetToken(codeResponse),
    flow: "auth-code",
    onError: (error) => console.error(error),
  });

  const handleSetToken = async (codeResponse: any) => {
    try {
      setIsFetchingToken(true);
      try {
        clients.getUserTokenRequest.setCode(codeResponse.code);
      } catch (error) {
        console.error("error", error);
      }

      // Make the gRPC call
      let response = null;
      try {
        response = await clients.userTokenServiceClient.getToken(
          clients.getUserTokenRequest
        );
      } catch (error) {
        console.error("error", error);
      }

      if (response && response.getSessionToken()) {
        setIsFetchingToken(false);
        setSessionToken(response.getSessionToken());
        setIsLoggedIn(true);
        setProjectListIsMounted(true);
      } else {
        setIsFetchingToken(false);
        setSessionToken("");
        setIsLoggedIn(false);
        setProjectListIsMounted(false);
      }
    } catch (error) {
      console.error("error", error);
      setIsFetchingToken(false);
    }
  };

  return login;
}
