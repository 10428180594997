import { useEffect } from "react";
import { useQuery } from "react-query";
import { ContractChangeOrderAssemblyService as ccoas } from "../api/ContractChangeOrderAssemblyService";
import { ProjectEstimateAssemblyService as peas } from "../api/ProjectEstimateAssemblyService";
import { useUnityBuildStore } from "../states/store";
import { v4 as uuidv4 } from "uuid";
import { useStore } from "zustand";

export function useFetchCountedAssemblies() {
  const { setCountedAssemblies, selectedEstimate, selectedChangeOrder } =
    useStore(useUnityBuildStore);

  const queryKey = [
    "countedAssemblies",
    selectedEstimate?.estimateid,
    selectedChangeOrder?.changeorderid,
  ];

  const { data, error, isLoading } = useQuery(
    queryKey,
    async () => {
      if (selectedEstimate?.estimateid && selectedChangeOrder?.changeorderid) {
        console.error(
          `Both selectedEstimateId ${selectedEstimate.estimateid} and selectedChangeOrderId ${selectedChangeOrder.changeorderid} are selected, only one should be selected`
        );
        return;
      }
      if (selectedEstimate?.estimateid) {
        const response = await peas.getEstimateAssembliesByEstimateId(
          selectedEstimate.estimateid
        );
        return response.takeoffassembliesList;
      } else if (selectedChangeOrder?.changeorderid) {
        const response = await ccoas.getChangeOrderAssembliesByChangeOrderId(
          selectedChangeOrder.changeorderid
        );

        return response.takeoffassembliesList;
      }
      throw new Error("No valid ID available for fetching countedAssemblies");
    },
    {
      enabled:
        !!selectedEstimate?.estimateid || !!selectedChangeOrder?.changeorderid,
    }
  );

  //Update global state when data changes, we use filteredCountedAssemblies for rendering
  useEffect(() => {
    if (data) {
      try {
        const assembliesWithUUIDs = data.map((assembly: any) => ({
          ...assembly,
          pointsList: (assembly.pointsList || []).map((point: any) => ({
            ...point,
            id: point.id || uuidv4(),
          })),
        }));
        setCountedAssemblies(assembliesWithUUIDs);
      } catch (error) {
        console.error("Error updating countedAssemblies state", error);
      }
    }
  }, [data, setCountedAssemblies]);

  return { data, error, isLoading };
}

export function useFetchAssemblyStats() {
  const { selectedEstimate, selectedChangeOrder } =
    useStore(useUnityBuildStore);

  const queryKey = [
    "assemblyStats",
    selectedEstimate?.estimateid,
    selectedChangeOrder?.changeorderid,
  ];

  const { data, error, isLoading } = useQuery(
    queryKey,
    async () => {
      if (selectedEstimate?.estimateid && selectedChangeOrder?.changeorderid) {
        console.error(
          `Both selectedEstimateId ${selectedEstimate.estimateid} and selectedChangeOrderId ${selectedChangeOrder.changeorderid} are selected, only one should be selected`
        );
        return;
      }
      if (selectedEstimate?.estimateid) {
        const response = await peas.getEstimateAssembliesByEstimateId(
          selectedEstimate.estimateid
        );
        return response.takeoffassembliesList;
      } else if (selectedChangeOrder?.changeorderid) {
        const response = await ccoas.getChangeOrderAssembliesByChangeOrderId(
          selectedChangeOrder.changeorderid
        );
        return response.takeoffassembliesList;
      }
      throw new Error("No valid ID available for fetching countedAssemblies");
    },
    {
      enabled:
        !!selectedEstimate?.estimateid || !!selectedChangeOrder?.changeorderid,
    }
  );
  return { data, error, isLoading };
}
