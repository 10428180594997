import { pdfjs } from "react-pdf";
import { clients } from "../clients/grpcClients";
import {
  DeleteObjectRequest,
  DeleteObjectResponse,
  GenerateGETSignedUrlRequest,
  GeneratePUTSignedUrlRequest,
  GetAllObjectsByProjectIdRequest,
  GetAllObjectsByProjectIdResponse,
  UpdateObjectNameRequest,
  UpdateObjectNameResponse,
  UpdateObjectScaleRequest,
  UserScaleList,
} from "./protosCompiled/object/object_pb";
import { useUnityBuildStore } from "../states/store";
import { Object as ProtoObject } from "./protosCompiled/object/object_pb";
import { TakeoffAssemblies } from "../types/AssemblyItemType";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const client = clients.objectServiceClient;

export class ObjectService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  private static uploadObjectToGCS = async (
    signedUrl: string,
    method: string,
    file: File
  ) => {
    const contentType = "application/pdf"; // TODO:  Create a function to parse the content type from the file extension
    const res = await fetch(signedUrl, {
      method: method,
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });

    if (!res.ok) {
      throw new Error(
        `HTTP status ${res.status}: Failed to handle file: ${file.name}`
      );
    }
    return res;
  };

  // Assuming this method is in your ObjectService class
  private static async getObjectFromGCS(signedUrl: string): Promise<string> {
    const res = await fetch(signedUrl, {
      method: "GET",
      cache: "no-store",
    });

    if (!res.ok) {
      throw new Error(`HTTP status ${res.status}: Failed to fetch file`);
    }

    const blob = await res.blob();
    const blobUrl = URL.createObjectURL(blob);

    return blobUrl;
  }

  public static getObjectListByProjectId = async (
    projectId: string
  ): Promise<GetAllObjectsByProjectIdResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new GetAllObjectsByProjectIdRequest();
      req.setProjectid(projectId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      client.getAllObjectsByProjectId(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  };

  public static async handlePUTGCSRequest(
    file: File,
    projectId: string
  ): Promise<string> {
    try {
      const object = new ProtoObject();
      object.setObjectname(file.name || "unknown");
      object.setProjectid(projectId);

      const request = new GeneratePUTSignedUrlRequest();
      request.setMethod("PUT");
      request.setObject(object);
      request.setSessiontoken(this.getMetadata().sessionToken);

      const response = await client.generatePUTSignedUrl(request, null);

      const objectDetails = response.getObjectdetailsList();
      if (objectDetails.length === 0) {
        throw new Error("No ObjectDetails returned from server");
      }

      const detail = objectDetails[0];
      const signedUrl = detail.getSignedurl();

      const res = await this.uploadObjectToGCS(signedUrl, "PUT", file);

      if (res.status === 200) {
        return detail.getObjectuuidnamestring();
      } else {
        throw new Error(`Failed to upload file: ${file.name}`);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Error with file ${file.name}: ${error.message}`);
      } else {
        console.error("An unexpected error occurred", error);
      }
      throw error;
    }
  }

  public static handleGETGCSRequest = async (
    request: GenerateGETSignedUrlRequest,
    objectName: string
  ) => {
    let signedUrl = "";
    try {
      const response = await client.generateGETSignedUrl(request, null);
      signedUrl = response.getSignedurl();
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Error with object ${objectName}: ${error.message}`);
      } else {
        console.error("An unexpected error occurred", error);
      }
    }

    try {
      const blobURL = await this.getObjectFromGCS(signedUrl);
      return blobURL;
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Error with object ${objectName}: ${error.message}`);
      } else {
        console.error("An unexpected error occurred", error);
      }
    }
  };

  public static handleGETGCSRequest_Preview = async (
    request: GenerateGETSignedUrlRequest,
    objectName: string
  ): Promise<string> => {
    let signedUrl = "";
    try {
      const response = await client.generateGETSignedUrl(request, null);
      signedUrl = response.getSignedurl();
      return signedUrl; // Return the signed URL directly
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Error with object ${objectName}: ${error.message}`);
      } else {
        console.error("An unexpected error occurred", error);
      }
      throw error;
    }
  };

  // Update the scale of an object
  public static updateObjectScale = async (
    objectId: string,
    userScaleList: UserScaleList.AsObject[]
  ) => {
    try {
      const request = new UpdateObjectScaleRequest();
      request.setObjectid(objectId);
      // Convert AsObject[] to UserScaleList[]
      const userScaleInstances = userScaleList.map((scaleObj) => {
        const userScale = new UserScaleList();
        userScale.setPagenumber(scaleObj.pagenumber);
        userScale.setScale(scaleObj.scale);
        return userScale;
      });

      request.setUserscalesList(userScaleInstances);

      const response = await client.updateObjectScale(request, null);
      return response;
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Error with object ${objectId}: ${error.message}`);
      } else {
        console.error("An unexpected error occurred", error);
      }
    }
  };

  // Evaluate if the assemblies objectId is the same as the selected objectId
  public static filterAssemblyByObjectIdandPageNumber(
    assemblies: Array<TakeoffAssemblies>,
    selectedObjectId: string,
    currentPage: number
  ) {
    return assemblies.filter((object: TakeoffAssemblies) => {
      return (
        object.assembliesList[0].objectid === selectedObjectId &&
        object.assembliesList[0].pagenumber === currentPage
      );
    });
  }

  public static async deleteObject(objectId: string) {
    const request = new DeleteObjectRequest();
    request.setObjectid(objectId);
    request.setSessiontoken(this.getMetadata().sessionToken);

    return new Promise<DeleteObjectResponse.AsObject>((resolve, reject) => {
      client.deleteObject(
        request,
        {},
        (err, response: DeleteObjectResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static async updateObjectName(objectId: string, objectName: string) {
    const request = new UpdateObjectNameRequest();
    request.setObjectid(objectId);
    request.setObjectname(objectName);
    request.setSessiontoken(this.getMetadata().sessionToken);

    return new Promise<UpdateObjectNameResponse.AsObject>((resolve, reject) => {
      client.updateObjectName(request, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }
}
