import { create } from "zustand";

export type KeyBoardEventStore = {
    continousMode: boolean;
    setContinousMode: (continousMode: boolean) => void;

    snapMode: boolean;
    setSnapMode: (snapMode: boolean) => void;

    enableCrosshair: boolean;
    setEnableCrosshair: (enableCrosshair: boolean) => void;

    isMeasuring: boolean;
    setIsMeasuring: (isMeasuring: boolean) => void;

    isViewingTakeoffPallette: boolean;
    setIsViewingTakeoffPallette: (isViewingTakeoffPallette: boolean) => void;

    showChatWindow: boolean;
    setShowChatWindow: (show: boolean) => void;
};

export const useKeyBoardEventStore = create<KeyBoardEventStore>((set) => ({
    continousMode: true,
    setContinousMode: (mode) => set({ continousMode: mode }),

    snapMode: true,
    setSnapMode: (mode) => set({ snapMode: mode }),

    enableCrosshair: false,
    setEnableCrosshair: (enableCrosshair) =>
        set({ enableCrosshair: enableCrosshair }),

    isMeasuring: false,
    setIsMeasuring: (measuring) => set({ isMeasuring: measuring }),

    isViewingTakeoffPallette: true,
    setIsViewingTakeoffPallette: (isViewingTakeoffPallette) =>
        set({ isViewingTakeoffPallette: isViewingTakeoffPallette }),

    showChatWindow: false,
    setShowChatWindow: (show) => set({ showChatWindow: show }),
}));