import React, { useCallback } from "react";
import { TextField } from "@mui/material";

// Example: You might have these helper functions defined elsewhere.
const roundToTwoDecimals = (value: string): string => {
  const num = parseFloat(value);
  return isNaN(num) ? value : num.toFixed(2);
};

type Field = "sellingprice";

interface SellingPriceInputProps {
  sellingPrice: string;
  setSellingPrice: React.Dispatch<React.SetStateAction<string>>;
  lastSellingPrice: string;
  setLastSellingPrice: React.Dispatch<React.SetStateAction<string>>;
  handleBlur: (field: Field, value: string) => void;
  handleChange: (
    field: Field,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SellingPriceInput: React.FC<SellingPriceInputProps> = ({
  sellingPrice,
  setSellingPrice,
  lastSellingPrice,
  setLastSellingPrice,
  handleBlur,
  handleChange,
}) => {
  // Optionally, you can add an onFocus to auto-select the text.
  const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  }, []);

  return (
    <TextField
      type="text"
      value={sellingPrice}
      onChange={handleChange("sellingprice", setSellingPrice)}
      onBlur={(e) => {
        // Format to two decimals on blur.
        const fixed = roundToTwoDecimals(e.target.value);
        setSellingPrice(fixed);
        if (fixed !== lastSellingPrice) {
          setLastSellingPrice(fixed);
          handleBlur("sellingprice", fixed);
        }
      }}
      onFocus={handleFocus}
      variant="outlined"
      size="small"
      fullWidth
      InputProps={{
        inputProps: {
          min: 0,
          max: 99999999.99,
          step: "0.01",
          "aria-label": "Selling price",
        },
      }}
    />
  );
};
