import { useCallback } from "react";
import { useStore } from "zustand";
import { QuoteCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { QuoteRow } from "./QuoteRow";
import { useGetDefaultQuoteExpenseTypes } from "../../hooks/useDefaultCloseoutHooks";
import { useUnityBuildStore } from "../../states/store";
import {
  useGetQuoteCloseouts,
  useCreateQuoteCloseoutMutation,
  useGetQuoteChangeOrderCloseouts,
  useCreateQuoteChangeOrderCloseoutMutation,
} from "../../hooks/useCloseoutHooks";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { QuoteChangeOrderCloseout } from "../../api/protosCompiled/changeOrderCloseout/changeOrderCloseout_pb";

export const QuoteHeader = () => {
  // Zustand state
  const { selectedEstimate, selectedChangeOrder } =
    useStore(useUnityBuildStore);

  // React-query state & hooks
  const { data: closeoutDefaults } = useGetDefaultQuoteExpenseTypes();

  const { data: estimateCloseout, refetch: refetchEstimateCloseout } =
    useGetQuoteCloseouts();

  const { data: changeOrderCloseout, refetch: refetchChangeOrderCloseout } =
    useGetQuoteChangeOrderCloseouts();

  const { mutateAsync: createEstimateCloseout } =
    useCreateQuoteCloseoutMutation();

  const { mutateAsync: createChangeOrderCloseout } =
    useCreateQuoteChangeOrderCloseoutMutation();

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimateCloseout
    : refetchChangeOrderCloseout;

  const createCloseout = selectedEstimate?.estimateid
    ? createEstimateCloseout
    : createChangeOrderCloseout;

  const closeout = selectedEstimate?.estimateid
    ? estimateCloseout
    : changeOrderCloseout;

  const newCloseoutType = selectedEstimate?.estimateid
    ? new QuoteCloseout()
    : new QuoteChangeOrderCloseout();

  const takeoffId = selectedEstimate?.estimateid
    ? selectedEstimate?.estimateid
    : selectedChangeOrder?.changeorderid ?? "";

  const handleAddDefaultType = useCallback(
    (e: SelectChangeEvent<string>) => {
      const newValue = e.target.value;
      const defaultCloseoutType = closeoutDefaults?.find(
        (closeoutDefault) => closeoutDefault.quotegroup === newValue
      );
      if (defaultCloseoutType) {
        newCloseoutType.setCloseoutdefaultquoteid(defaultCloseoutType.id);
        newCloseoutType.setEstimateid(takeoffId);
        newCloseoutType.setCloseoutdefaultquoteid(defaultCloseoutType.id);
        newCloseoutType.setQuotedcost(0);
        newCloseoutType.setAdjustedpercent(0);

        createCloseout(newCloseoutType, {
          onSuccess: () => {
            refetch();
          },
        }).catch((err) => console.error("Error creating factored labor:", err));
      }
    },
    // eslint-disable-next-line
    [
      closeoutDefaults,
      selectedEstimate,
      selectedChangeOrder,
      createCloseout,
      refetch,
    ]
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FormControl fullWidth variant="filled" sx={{ mb: 1 }}>
        <InputLabel id="labor-select-label">Select Quote Type</InputLabel>
        <Select
          labelId="labor-select-label"
          id="labor-select"
          label="Select Quote Type"
          value={"Select Quote Type"}
          onChange={handleAddDefaultType}
        >
          <MenuItem value="" disabled selected hidden>
            Select labor type
          </MenuItem>
          {closeoutDefaults &&
            closeoutDefaults.map((closeoutItem, index) => (
              <MenuItem value={closeoutItem.quotegroup} key={index}>
                {closeoutItem.quotegroup}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {/* Table Container wrapped in a Box that grows */}
      <Box sx={{ flex: 1, overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          sx={{
            height: "100%",
            overflowY: "auto",
            border: "1px solid black",
          }}
        >
          <Table stickyHeader sx={{ width: "100%", tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Quoted Cost</TableCell>
                <TableCell>% Adjustment</TableCell>
                <TableCell>Total Cost</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {closeout?.closeoutsList.map((closeoutItem, index) => (
                <QuoteRow
                  key={`${index}-${closeoutItem.closeoutdefaultquoteid}`}
                  closeout={closeoutItem}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
