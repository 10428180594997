import React, { useEffect, useState } from "react";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { useSetSelectedAssemblyFromAssemblyPicker } from "../../hooks/useSetSelectedAssembly";
// Local or old custom types:
import {
  BreakOut,
  TakeoffAssemblies,
  TakeoffAssembly,
  TakeoffAssemblyItem,
} from "../../types/AssemblyItemType";
// Possibly: import your new proto definitions if you prefer
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { useAssemblyStore } from "../../states/AssemblyStore";
import { CreateAssembly } from "../assembliesAndItems/assemblies/CreateAssembly";
import {
  useFetchChangeOrderBreakouts,
  useFetchEstimateBreakouts,
} from "../../hooks/useFetchBreakout";
import { ObjectService } from "../../api/ObjectService";
import { useHandleGlobalStateState } from "../../hooks/useHandleGlobalState";
import { generateAttributeLabel } from "../assembliesAndItems/items/AddedItemRow";
import { useDeleteContractChangeOrderAssemblyMutation } from "../../hooks/useCreateContractChangeOrderAssemblyMutation";
import { useDeleteProjectEstimateAssemblyMutation } from "../../hooks/useCreateProjectEstimateAssemblyMutation";

/** Example: compute item qty from the new single formula & breakout fields. */
export const getItemQtyByTakeoffVariable = (
  assembly: TakeoffAssembly,
  item: TakeoffAssemblyItem,
  localBreakouts: BreakOut[]
): number => {
  if (!item.itemqtyformula) return 0;

  const formula = item.itemqtyformula;
  const takeoffVariableType = formula.takeoffvariabletype.replace(/^"+|"+$/g, "");

  // Instead of using the stale multiplier in item.breakout,
  // find the current breakout data from localBreakouts
  const breakoutId = item.breakout?.breakoutid;
  const updatedBreakout =
    localBreakouts.find((b) => b.id === breakoutId) || item.breakout;
  const multiplier = updatedBreakout?.multiplier ?? 1;

  let qty = 0;
  switch (takeoffVariableType) {
    case "point":
      qty = Math.round((assembly.pointcount || 0) * (formula.itemqty || 0) * multiplier);
      break;
    case "segment":
      qty = Math.round((assembly.segmentcount || 0) * (formula.itemqty || 0) * multiplier);
      break;
    case "length":
      qty = Math.round(
        ((assembly.segmentlength || 0) * (formula.itemqty || 0) * multiplier) / (formula.forevery || 1)
      );
      break;
    case "area":
      qty = Math.round((assembly.measuredarea || 0) * (formula.itemqty || 0) * multiplier);
      break;
    default:
      qty = 0;
      break;
  }
  return qty < 0 ? 0 : qty;
};


type RenderTakeoffListProps = {
  searchTerm: string;
  setFilter: (filter: string) => void;
};

export function RenderTakeoffList({
  searchTerm,
  setFilter,
}: RenderTakeoffListProps) {

  const countedAssemblies = useStore(useUnityBuildStore).countedAssemblies;
  const filteredCountedAssemblies = useStore(useUnityBuildStore).filteredCountedAssemblies;
  const setFilteredCountedAssemblies = useStore(useUnityBuildStore).setFilteredCountedAssemblies;
  const selectedObject = useStore(useUnityBuildStore).selectedObject;
  const currentPage = useStore(useUnityBuildStore).currentPage;
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  const selectedChangeOrder = useStore(useUnityBuildStore).selectedChangeOrder;
  const selectedFilter = useStore(useUnityBuildStore).selectedFilter;
  const userBackgroundColor = useStore(useUnityBuildStore).userBackgroundColor;
  const breakoutFilterObject = useStore(useUnityBuildStore).breakoutFilterObject;
  const setBreakoutFilterObject = useStore(useUnityBuildStore).setBreakoutFilterObject;

  const assemblyOption = useStore(useAssemblyStore).assemblyOption;
  const currentTakeoffAssembly = useStore(useAssemblyStore).currentTakeoffAssembly;
  const isViewingAssemblyForm = useStore(useAssemblyStore).isViewingAssemblyForm;
  const setCurrentTakeoffAssembly = useStore(useAssemblyStore).setCurrentTakeoffAssembly;
  const setIsViewingAssemblyForm = useStore(useAssemblyStore).setIsViewingAssemblyForm;
  const setAssemblyOption = useStore(useAssemblyStore).setAssemblyOption;
  const setIsCreatingAssembly = useStore(useAssemblyStore).setIsCreatingAssembly;

  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [isRenaming, setIsRenaming] = useState(false);
  const [renameValue, setRenameValue] = useState("");

  const setSelectedAssembly = useSetSelectedAssemblyFromAssemblyPicker();

  const handleSetViewingState = useHandleGlobalStateState();

  const { data: estimatebreakouts } = useFetchEstimateBreakouts(
    selectedEstimate?.estimateid ?? ""
  );
  const { data: changeOrderBreakouts } = useFetchChangeOrderBreakouts(
    selectedChangeOrder?.changeorderid ?? ""
  );

  const currentBreakouts: BreakOut[] =
    estimatebreakouts?.breakoutsList ?? changeOrderBreakouts?.breakoutsList ?? []

  const handleSetAssembly = (assembly: TakeoffAssembly) => {
    if (currentTakeoffAssembly?.assemblyid !== assembly.assemblyid) {
      setSelectedAssembly({
        takeoffAssembly: assembly,
      });
    }
  };

  const handleRenameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRenameValue(e.target.value);
  };
  const handleRenameSubmit = () => {
    setIsRenaming(false);
  };

  const handleEditAssembly = (assembly: TakeoffAssembly) => {
    handleSetViewingState({
      resetOption: "resetAssembly",
      setStateFunctions: [
        {
          setStateFunction: setIsViewingAssemblyForm,
          prop: true,
        },
        {
          setStateFunction: setIsCreatingAssembly,
          prop: false,
        },
        {
          setStateFunction: setAssemblyOption,
          prop: "takeoff",
        },
      ],
    });
    setSelectedAssembly(assembly as any);
    setCurrentTakeoffAssembly(assembly as TakeoffAssembly);
  };

  //Delete takeoff assembly logic -----------------------------------------------
  const { mutateAsync: deleteEstimateAssembly } = useDeleteProjectEstimateAssemblyMutation();
  const { mutateAsync: deleteChangeOrderAssembly } = useDeleteContractChangeOrderAssemblyMutation();
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const handleSetShowDeleteWarning = (assembly: TakeoffAssembly, show: boolean) => {
    setShowDeleteWarning(show);
    setCurrentTakeoffAssembly(assembly as TakeoffAssembly);
  };

  const handleDeleteAssembly = async (assembly: TakeoffAssembly) => {
    if (selectedEstimate) {
      await deleteEstimateAssembly(assembly.assemblyid);
    } else if (selectedChangeOrder) {
      await deleteChangeOrderAssembly(assembly.assemblyid);
    }

    // Compute the updated assemblies array from the current state
    const updatedAssemblies = filteredCountedAssemblies
      .map((asm) => ({
        ...asm,
        assembliesList: asm.assembliesList.filter(
          (a) => a.assemblyid !== assembly.assemblyid
        ),
      }))
      .filter((asm) => asm.assembliesList.length > 0);

    // Pass the new array directly instead of using an updater function
    setFilteredCountedAssemblies(updatedAssemblies);

    setCurrentTakeoffAssembly(null);
    setShowDeleteWarning(false);
  };



  //-----------------------------------------------------------------------------

  const handleToggleExpandRow = (assemblyId: string) => {
    if (expandedRows.includes(assemblyId)) {
      setExpandedRows(expandedRows.filter((id) => id !== assemblyId));
    } else {
      setExpandedRows([...expandedRows, assemblyId]);
    }
  };

  //** Check if there's any item in this assembly that isQuoted */
  const hasQuotedItems = (assembly: TakeoffAssembly) => {
    // if it's an EstimateAssembly => assembly.assembliesList[0].estimateitemsList
    // if it's a ChangeOrderAssembly => assembly.assembliesList[0].changeorderitemsList
    const items = assembly.itemsList;
    return items.some((item: TakeoffAssemblyItem) => item.isquoted === true);
  };

  const hasMultiplierOverOne = (
    assembly: TakeoffAssembly,
    currentBreakouts: BreakOut[]
  ): boolean => {
    if (!assembly.itemsList?.[0]?.breakout?.breakoutid) return false;
    const updatedMultiplier =
      currentBreakouts.find(
        (b) => b.id === assembly.itemsList![0].breakout!.breakoutid
      )?.multiplier ||
      assembly.itemsList![0].breakout!.multiplier ||
      1;
    return updatedMultiplier > 1;
  };


  // No breakout filter => reset
  useEffect(() => {
    if (
      !breakoutFilterObject &&
      countedAssemblies &&
      selectedObject &&
      currentPage
    ) {
      const assemblies = ObjectService.filterAssemblyByObjectIdandPageNumber(
        countedAssemblies,
        selectedObject.id,
        currentPage
      );
      setFilteredCountedAssemblies(assemblies);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakoutFilterObject, countedAssemblies, selectedObject, currentPage]);

  const handleSetBreakoutFilter = (filter: string) => {
    const selectedBreakout = currentBreakouts.find((b) => b.id === filter);
    setBreakoutFilterObject(selectedBreakout || null);
    // If we found a breakout, filter, else revert
    if (selectedBreakout && countedAssemblies && selectedObject) {
      const assemblies = ObjectService.filterAssemblyByObjectIdandPageNumber(
        countedAssemblies,
        selectedObject.id,
        currentPage
      );
      // Filter by that breakout ID
      const filtered = assemblies.filter((asm: TakeoffAssemblies) => {
        const items =
          asm.assembliesList[0]?.itemsList ||
          asm.assembliesList[0]?.itemsList ||
          [];
        return items[0]?.breakout?.breakoutid === selectedBreakout.id;
      });
      setFilteredCountedAssemblies(filtered);
    } else {
      // No breakout filter
      if (countedAssemblies && selectedObject && currentPage) {
        const assemblies = ObjectService.filterAssemblyByObjectIdandPageNumber(
          countedAssemblies,
          selectedObject.id,
          currentPage
        );
        setFilteredCountedAssemblies(assemblies);
      }
    }
  };

  return (
    <div>
      {showDeleteWarning && (
        <Dialog open={true}>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this assembly?
            </DialogContentText>
            <DialogActions>
              <Button onClick={() => setShowDeleteWarning(false)}>Cancel</Button>
              <Button
                onClick={() =>
                  handleDeleteAssembly(currentTakeoffAssembly as TakeoffAssembly)
                }
              >
                Delete
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}

      {/* Possibly a Dialog for Creating or Editing an Assembly */}
      <Dialog
        open={isViewingAssemblyForm && assemblyOption === "takeoff"}
        fullScreen={true}
        style={{
          height: "100vh",
          backgroundColor: userBackgroundColor,
        }}
      >
        <DialogContent>
          <CreateAssembly
            assemblyOption="takeoff"
            currentAssembly={currentTakeoffAssembly as TakeoffAssembly}
          />
        </DialogContent>
      </Dialog>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <tr style={{ backgroundColor: "#f2f2f2", width: "100%" }}>
          <select
            onChange={(e) => handleSetBreakoutFilter(e.target.value)}
            value={breakoutFilterObject?.id || ""}
            style={{ width: "95%" }}
          >
            <option value="">Select Breakout Filter</option>
            {currentBreakouts?.map((breakout) => (
              <option key={breakout.id} value={breakout.id}>
                {breakout.breakoutname}
              </option>
            ))}
          </select>
          <select
            onChange={(e) => setFilter(e.target.value)}
            value={selectedFilter}
            style={{ width: "95%" }}
          >
            <option value="all">Select Takeoff Type Filter</option>
            <option value="count">Count</option>
            <option value="length">Length</option>
            <option value="area">Area</option>
          </select>
        </tr>
      </div>

      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "60%" }}>Description</th>
            {selectedFilter === "all" && (
              <>
                <th>Count</th>
                <th>Length</th>
                <th>Area</th>
              </>
            )}
            {selectedFilter === "count" && <th>Count</th>}
            {selectedFilter === "length" && (
              <>
                <th>Count</th>
                <th>Length</th>
              </>
            )}
            {selectedFilter === "area" && (
              <>
                <th>Count</th>
                <th>Length</th>
                <th>Area</th>
              </>
            )}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="TakeoffTableBody">
          {/*
            Instead of prefiltering by breakout here, we now return all countedAssemblies,
            and rely on the inner filter (flatMap) to check breakout and other criteria.
          */}
          {countedAssemblies
            ?.flatMap((asm: TakeoffAssemblies) =>
              asm.assembliesList.filter((assembly) => {
                // Ensure measurement type matches or filter is "all"
                const typeMatches =
                  selectedFilter === "all" ||
                  assembly.assemblymeasurementtype.toLowerCase() ===
                  selectedFilter.toLowerCase();

                // Ensure the assembly is related to the selected object
                const objectMatches = assembly.objectid === selectedObject?.id;

                // Check if the assembly name matches the search term (partial, case-insensitive)
                const searchTermNormalized = searchTerm.trim().toLowerCase();
                const searchMatches =
                  searchTermNormalized === "" ||
                  assembly.assemblyname.trim().toLowerCase().includes(searchTermNormalized);

                // Check breakout filter (exact match, case-insensitive)
                const breakoutMatches = breakoutFilterObject
                  ? assembly.itemsList.some(
                    (item) =>
                      item.breakout &&
                      item.breakout.breakoutname.trim().toLowerCase() ===
                      breakoutFilterObject.breakoutname.trim().toLowerCase()
                  )
                  : true;
                // Only include assemblies that match all criteria
                return typeMatches && objectMatches && searchMatches && breakoutMatches;
              })
            )
            .map((assembly) => {
              const isExpanded = expandedRows.includes(assembly.assemblyid);
              // Compute updated multiplier for display using currentBreakouts.
              const updatedMultiplier = assembly.itemsList?.[0]?.breakout?.breakoutid
                ? (currentBreakouts.find(
                  (b) => b.id === assembly.itemsList![0].breakout!.breakoutid
                )?.multiplier ||
                  assembly.itemsList![0].breakout!.multiplier ||
                  1)
                : 1;

              return (
                <React.Fragment key={assembly.assemblyid}>
                  <tr className="hoverable-row">
                    <td
                      onClick={() => handleSetAssembly(assembly)}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          assembly.itemsList?.length === 0
                            ? "#ffcccb"
                            : hasMultiplierOverOne(assembly, currentBreakouts)
                              ? "lightGreen"
                              : currentTakeoffAssembly?.assemblyid === assembly.assemblyid
                                ? "#add8e6"
                                : undefined,
                      }}
                    >
                      {isRenaming && currentTakeoffAssembly?.assemblyid === assembly.assemblyid ? (
                        <input
                          type="text"
                          value={renameValue}
                          onChange={handleRenameChange}
                          onBlur={handleRenameSubmit}
                          autoFocus
                        />
                      ) : (
                        `${hasQuotedItems(assembly) ? "Quoted, " : ""}${hasMultiplierOverOne(assembly, currentBreakouts)
                          ? `(${updatedMultiplier}x), `
                          : ""
                        }${assembly.assemblyname}`
                      )}


                    </td>
                    {/* Render relevant columns based on the filter */}
                    {selectedFilter === "all" && (
                      <>
                        <td>{assembly.pointcount || 0}</td>
                        <td>{`${Math.round(assembly.segmentlength || 0)}'`}</td>
                        <td>{(assembly.measuredarea || 0).toFixed(2)}</td>
                      </>
                    )}
                    {selectedFilter === "count" && (
                      <td>{assembly.pointcount || 0}</td>
                    )}
                    {selectedFilter === "length" && (
                      <>
                        <td>{assembly.pointcount || 0}</td>
                        <td>{`${Math.round(assembly.segmentlength || 0)}'`}</td>
                      </>
                    )}
                    {selectedFilter === "area" && (
                      <>
                        <td>{assembly.pointcount || 0}</td>
                        <td>{`${Math.round(assembly.segmentlength || 0)}'`}</td>
                        <td>{(assembly.measuredarea || 0).toFixed(2)}</td>
                      </>
                    )}
                    <td>
                      <button
                        onClick={() =>
                          handleToggleExpandRow(assembly.assemblyid)
                        }
                      >
                        {isExpanded ? "-" : "+"}
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleEditAssembly(assembly);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleSetShowDeleteWarning(assembly, true);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>

                  {/* Expanded Row */}
                  {isExpanded && (
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <td colSpan={6}>
                        <table
                          style={{
                            width: "95%",
                            marginLeft: "20px",
                          }}
                        >
                          <thead>
                            <tr style={{ backgroundColor: "#f2f2f2" }}>
                              <th colSpan={2}>
                                Breakout:{" "}
                                {assembly.itemsList?.[0]?.breakout?.breakoutname ||
                                  "None"}
                              </th>
                            </tr>
                            <tr>
                              <th>Item Name</th>
                              <th>QTY</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(assembly.itemsList || []).map((item: TakeoffAssemblyItem, idx: number) => {
                              const qty = getItemQtyByTakeoffVariable(assembly, item, currentBreakouts);
                              return (
                                <tr key={`${assembly.assemblyid}-${idx}`} className="hoverable-row">
                                  <td>
                                    {generateAttributeLabel(
                                      { attributevaluename: item.attribute?.attributevaluename || "Unnamed" },
                                      item
                                    )}
                                  </td>
                                  <td>{qty}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};  