import { useQuery } from "react-query";
import { useUnityBuildStore } from "../states/store";
import { ChangeOrderService as cos } from "../api/ChangeOrderService";
import { useStore } from "zustand";

export function useFetchChangeOrders() {
  const { selectedContract } = useStore(useUnityBuildStore);

  const { data, error, isLoading } = useQuery(
    "changeOrders",
    async () => {
      if (selectedContract?.contractid) {
        const response = await cos.getChangeOrders(selectedContract.contractid);
        return response.changeordersList;
      }
      throw new Error("No valid ID available for fetching change orders");
    },
    {
      enabled: !!selectedContract?.contractid,
    }
  );
  return { data, error, isLoading };
}
