/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: globalAssembly.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as globalAssembly_pb from './globalAssembly_pb'; // proto import: "globalAssembly.proto"


export class GlobalAssemblyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateGlobalAssembly = new grpcWeb.MethodDescriptor(
    '/GlobalAssemblyService/CreateGlobalAssembly',
    grpcWeb.MethodType.UNARY,
    globalAssembly_pb.CreateGlobalAssemblyRequest,
    globalAssembly_pb.CreateGlobalAssemblyResponse,
    (request: globalAssembly_pb.CreateGlobalAssemblyRequest) => {
      return request.serializeBinary();
    },
    globalAssembly_pb.CreateGlobalAssemblyResponse.deserializeBinary
  );

  createGlobalAssembly(
    request: globalAssembly_pb.CreateGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalAssembly_pb.CreateGlobalAssemblyResponse>;

  createGlobalAssembly(
    request: globalAssembly_pb.CreateGlobalAssemblyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.CreateGlobalAssemblyResponse) => void): grpcWeb.ClientReadableStream<globalAssembly_pb.CreateGlobalAssemblyResponse>;

  createGlobalAssembly(
    request: globalAssembly_pb.CreateGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.CreateGlobalAssemblyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalAssemblyService/CreateGlobalAssembly',
        request,
        metadata || {},
        this.methodDescriptorCreateGlobalAssembly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalAssemblyService/CreateGlobalAssembly',
    request,
    metadata || {},
    this.methodDescriptorCreateGlobalAssembly);
  }

  methodDescriptorUpdateGlobalAssembly = new grpcWeb.MethodDescriptor(
    '/GlobalAssemblyService/UpdateGlobalAssembly',
    grpcWeb.MethodType.UNARY,
    globalAssembly_pb.UpdateGlobalAssemblyRequest,
    globalAssembly_pb.UpdateGlobalAssemblyResponse,
    (request: globalAssembly_pb.UpdateGlobalAssemblyRequest) => {
      return request.serializeBinary();
    },
    globalAssembly_pb.UpdateGlobalAssemblyResponse.deserializeBinary
  );

  updateGlobalAssembly(
    request: globalAssembly_pb.UpdateGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalAssembly_pb.UpdateGlobalAssemblyResponse>;

  updateGlobalAssembly(
    request: globalAssembly_pb.UpdateGlobalAssemblyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.UpdateGlobalAssemblyResponse) => void): grpcWeb.ClientReadableStream<globalAssembly_pb.UpdateGlobalAssemblyResponse>;

  updateGlobalAssembly(
    request: globalAssembly_pb.UpdateGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.UpdateGlobalAssemblyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalAssemblyService/UpdateGlobalAssembly',
        request,
        metadata || {},
        this.methodDescriptorUpdateGlobalAssembly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalAssemblyService/UpdateGlobalAssembly',
    request,
    metadata || {},
    this.methodDescriptorUpdateGlobalAssembly);
  }

  methodDescriptorDeleteGlobalAssembly = new grpcWeb.MethodDescriptor(
    '/GlobalAssemblyService/DeleteGlobalAssembly',
    grpcWeb.MethodType.UNARY,
    globalAssembly_pb.DeleteGlobalAssemblyRequest,
    globalAssembly_pb.DeleteGlobalAssemblyResponse,
    (request: globalAssembly_pb.DeleteGlobalAssemblyRequest) => {
      return request.serializeBinary();
    },
    globalAssembly_pb.DeleteGlobalAssemblyResponse.deserializeBinary
  );

  deleteGlobalAssembly(
    request: globalAssembly_pb.DeleteGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalAssembly_pb.DeleteGlobalAssemblyResponse>;

  deleteGlobalAssembly(
    request: globalAssembly_pb.DeleteGlobalAssemblyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.DeleteGlobalAssemblyResponse) => void): grpcWeb.ClientReadableStream<globalAssembly_pb.DeleteGlobalAssemblyResponse>;

  deleteGlobalAssembly(
    request: globalAssembly_pb.DeleteGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.DeleteGlobalAssemblyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalAssemblyService/DeleteGlobalAssembly',
        request,
        metadata || {},
        this.methodDescriptorDeleteGlobalAssembly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalAssemblyService/DeleteGlobalAssembly',
    request,
    metadata || {},
    this.methodDescriptorDeleteGlobalAssembly);
  }

  methodDescriptorGetGlobalAssembliesByFolderId = new grpcWeb.MethodDescriptor(
    '/GlobalAssemblyService/GetGlobalAssembliesByFolderId',
    grpcWeb.MethodType.UNARY,
    globalAssembly_pb.GetGlobalAssembliesByFolderIdRequest,
    globalAssembly_pb.GetGlobalAssembliesByFolderIdResponse,
    (request: globalAssembly_pb.GetGlobalAssembliesByFolderIdRequest) => {
      return request.serializeBinary();
    },
    globalAssembly_pb.GetGlobalAssembliesByFolderIdResponse.deserializeBinary
  );

  getGlobalAssembliesByFolderId(
    request: globalAssembly_pb.GetGlobalAssembliesByFolderIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalAssembly_pb.GetGlobalAssembliesByFolderIdResponse>;

  getGlobalAssembliesByFolderId(
    request: globalAssembly_pb.GetGlobalAssembliesByFolderIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.GetGlobalAssembliesByFolderIdResponse) => void): grpcWeb.ClientReadableStream<globalAssembly_pb.GetGlobalAssembliesByFolderIdResponse>;

  getGlobalAssembliesByFolderId(
    request: globalAssembly_pb.GetGlobalAssembliesByFolderIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.GetGlobalAssembliesByFolderIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalAssemblyService/GetGlobalAssembliesByFolderId',
        request,
        metadata || {},
        this.methodDescriptorGetGlobalAssembliesByFolderId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalAssemblyService/GetGlobalAssembliesByFolderId',
    request,
    metadata || {},
    this.methodDescriptorGetGlobalAssembliesByFolderId);
  }

}

