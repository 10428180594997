import { create } from "zustand";
import { GlobalItem } from "../api/protosCompiled/globalItem/globalItem_pb";
import { GlobalAssembly } from "../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { AssemblyOptions } from "../types/FolderTypes";
import { TakeoffAssembly } from "../types/AssemblyItemType";

export type AssemblyStore = {
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;

  filteredItems: GlobalItem.AsObject[];
  setFilteredItems: (items: GlobalItem.AsObject[]) => void;

  assemblyItems: GlobalItem.AsObject[];
  setAssemblyItems: (items: GlobalItem.AsObject[]) => void;

  currentGlobalAssembly: GlobalAssembly.AsObject | null;
  setCurrentGlobalAssembly: (
    assembly:
      | GlobalAssembly.AsObject
      | null
      | ((
          prevAssembly: GlobalAssembly.AsObject | null
        ) => GlobalAssembly.AsObject | null)
  ) => void;

  currentTakeoffAssembly: TakeoffAssembly | null;
  setCurrentTakeoffAssembly: (
    assembly:
      | TakeoffAssembly
      | null
      | ((prevAssembly: TakeoffAssembly | null) => TakeoffAssembly | null)
  ) => void;

  isViewingAssemblyForm: boolean;
  setIsViewingAssemblyForm: (isViewingAssemblyForm: boolean) => void;

  isCreatingAssembly: boolean;
  setIsCreatingAssembly: (isCreatingAssembly: boolean) => void;

  assemblyOption: AssemblyOptions | "";
  setAssemblyOption: (assemblyOptions: AssemblyOptions | "") => void;
};

export const useAssemblyStore = create<AssemblyStore>((set) => ({
  isEditMode: false,
  setIsEditMode: (isEditMode) => set({ isEditMode }),
  
  filteredItems: [],
  setFilteredItems: (items) => set({ filteredItems: items }),

  assemblyItems: [],
  setAssemblyItems: (items) => set({ assemblyItems: items }),

  currentGlobalAssembly: null,
  setCurrentGlobalAssembly: (assembly) =>
    set((state) => ({
      currentGlobalAssembly:
        typeof assembly === "function"
          ? assembly(state.currentGlobalAssembly)
          : assembly,
    })),

  currentTakeoffAssembly: null,
  setCurrentTakeoffAssembly: (assembly) =>
    set((state) => ({
      currentTakeoffAssembly:
        typeof assembly === "function"
          ? assembly(state.currentTakeoffAssembly)
          : assembly,
    })),

  isViewingAssemblyForm: false,
  setIsViewingAssemblyForm: (isViewingAssemblyForm) =>
    set({ isViewingAssemblyForm }),

  isCreatingAssembly: false,
  setIsCreatingAssembly: (isCreatingAssembly) => set({ isCreatingAssembly }),

  assemblyOption: "",
  setAssemblyOption: (assemblyOption) => set({ assemblyOption }),
}));

export const defaultGlobalAssembly: GlobalAssembly.AsObject = {
  fileid: "",
  assemblyid: "",
  assemblyname: "",
  isactive: true,
  lastupdated: "",
  parentid: "",
  level: 0,
  entitytype: "",
  isfile: false,
  assemblymeasurementtype: "",
  itemsList: [],
  imageclassificationid: 0,
  createdbyuserid: "",
  createdbyaccountid: "",
};
