import React, { useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  Tooltip,
  IconButton,
  Paper,
  Grid,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useStore } from "zustand";
import {
  defaultGlobalItem,
  useGlobalItemStore,
} from "../../../states/globalItemStore";
import { AttributeGroup } from "../../../api/protosCompiled/globalItem/globalItem_pb";

export interface SelectedAttributesMap {
  [key: string]: string[];
}

interface AttributeValuesCheckboxesProps {
  selectedAttributeGroupId: string | null;
  selectedAttributes: SelectedAttributesMap;
  setSelectedAttributes: React.Dispatch<
    React.SetStateAction<SelectedAttributesMap>
  >;
  attributeGroups?: Array<AttributeGroup.AsObject>;
  handleCheckboxChange: (groupId: string, valueId: string) => void;
  itemName: string;
  setItemName: (name: string) => void;
  selectedUom: string;
  setSelectedUom: (uom: string) => void;
  handleCreateItem: () => void;
}

function naturalCompare(a: string, b: string): number {
  const chunkRegex = /(\d+|\D+)/g;

  const aChunks = a.match(chunkRegex) || [];
  const bChunks = b.match(chunkRegex) || [];

  const len = Math.max(aChunks.length, bChunks.length);

  for (let i = 0; i < len; i++) {
    const aChunk = aChunks[i] || "";
    const bChunk = bChunks[i] || "";

    const aNum = parseInt(aChunk, 10);
    const bNum = parseInt(bChunk, 10);

    const aIsNum = !isNaN(aNum);
    const bIsNum = !isNaN(bNum);

    if (aIsNum && bIsNum) {
      if (aNum !== bNum) return aNum - bNum;
    } else if (aIsNum && !bIsNum) {
      return -1;
    } else if (!aIsNum && bIsNum) {
      return 1;
    } else {
      const cmp = aChunk.localeCompare(bChunk);
      if (cmp !== 0) return cmp;
    }
  }

  return a.length - b.length;
}

const sortAndGroupAttributeValues = (
  attributeValues: AttributeGroup.AsObject["attributevaluesList"]
) => {
  const sortedValues = [...attributeValues].sort((a, b) =>
    naturalCompare(a.attributevaluename, b.attributevaluename)
  );

  const groupedValues: Record<
    string,
    AttributeGroup.AsObject["attributevaluesList"]
  > = {};
  for (const value of sortedValues) {
    const name = value.attributevaluename;
    if (!groupedValues[name]) {
      groupedValues[name] = [];
    }
    groupedValues[name].push(value);
  }

  return groupedValues;
};

export const AttributeValuesCheckboxes: React.FC<
  AttributeValuesCheckboxesProps
> = ({
  selectedAttributeGroupId,
  selectedAttributes,
  setSelectedAttributes,
  attributeGroups,
  handleCheckboxChange,
  itemName,
  setItemName,
  selectedUom,
  setSelectedUom,
  handleCreateItem,
}) => {
  const { setIsCreatingItem, setCurrentItem } = useStore(useGlobalItemStore);
  const [allChecked, setAllChecked] = useState(false);

  if (!selectedAttributeGroupId) return null;

  const currentGroup = attributeGroups?.find(
    (group) => String(group.attributegroupid) === selectedAttributeGroupId
  );

  const groupedAttributes = currentGroup?.attributevaluesList
  ? sortAndGroupAttributeValues(currentGroup.attributevaluesList)
  : {};


  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsCreatingItem(true);
    setCurrentItem(defaultGlobalItem);
    setItemName("");
    setSelectedUom("");
    setSelectedAttributes({});
  };

  const handleSelectAll = () => {
    const allValueIds =
      currentGroup?.attributevaluesList.map((val) =>
        val.attributevalueid.toString()
      ) || [];
    if (allChecked) {
      setSelectedAttributes({});
      setAllChecked(false);
    } else {
      setSelectedAttributes((prevSelected) => ({
        ...prevSelected,
        [selectedAttributeGroupId]: allValueIds,
      }));
      setAllChecked(true);
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Assign Attributes
        </Typography>
        <Tooltip title="Select one or more values that apply to this item. For example, if the attribute group is 'Size', you can select '3/4&quot;' or '1/2&quot;'">
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Paper
        sx={{
          maxHeight: 300,
          overflowY: "auto",
          border: "1px solid #ccc",
          padding: 2,
          borderRadius: 2,
        }}
      >
        {Object.entries(groupedAttributes).length ? (
          Object.entries(groupedAttributes).map(([name, values]) => (
            <Box
              key={name}
              sx={{
                mb: 2,
                p: 1,
                border: "1px solid #ddd",
                borderRadius: 1,
                backgroundColor: "#fafafa",
              }}
            >
            {values.map((valueObj) => {
              if (!valueObj) return null; // Safeguard against undefined objects

              const valueIdStr = valueObj.attributevalueid?.toString() || "";
              const validGroupId = selectedAttributeGroupId ? String(selectedAttributeGroupId) : "";

              const isChecked =
                (selectedAttributes[validGroupId] ?? []).includes(valueIdStr);

                return (
                  <FormControlLabel
                    key={valueObj.attributevalueid}
                    control={
                      <Checkbox
                        checked={Boolean(isChecked)}
                        onChange={() =>
                          handleCheckboxChange(
                            selectedAttributeGroupId,
                            valueIdStr
                          )
                        }
                      />
                    }
                    label={valueObj.attributevaluename}
                  />
                );
              })}
            </Box>
          ))
        ) : (
          <Typography>No attributes available for this group.</Typography>
        )}
      </Paper>
      <Grid
        container
        spacing={2}
        sx={{ mt: 2 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={4}>
          <Button onClick={handleSelectAll} variant="contained" fullWidth>
            {allChecked ? "Deselect All" : "Select All"}
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            onClick={handleCreateItem}
            disabled={!itemName || !selectedUom}
            variant="contained"
            color="success"
            fullWidth
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            onClick={handleCancel}
            disabled={!itemName || !selectedUom}
            variant="contained"
            color="secondary"
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
