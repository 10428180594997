import { create } from "zustand";
import { defaultFolder, FolderNode } from "../types/FolderTypes";

export type FolderStore = {
  isMoveMode: boolean;
  setIsMoveMode: (isMoveMode: boolean) => void;

  currentFolder: FolderNode | null;
  openRows: { [key: string]: boolean };
  editingFolderId: string | null;

  setCurrentFolder: (folder: FolderNode | null) => void;
  toggleRow: (key: string) => void;
  setEditingFolderId: (folderId: string | null) => void;
};

export const useFolderStore = create<FolderStore>((set) => ({
  isMoveMode: false,
  setIsMoveMode: (isMoveMode) => set({ isMoveMode }),

  currentFolder: defaultFolder,
  openRows: {},
  editingFolderId: null,

  setCurrentFolder: (folder) => set({ currentFolder: folder }),

  toggleRow: (key) =>
    set((state) => ({
      openRows: { ...state.openRows, [key]: !state.openRows[key] },
    })),

  setEditingFolderId: (folderId) => set({ editingFolderId: folderId }),
}));
