import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { IndirectLaborRowProps } from "./closeoutTypes";
import {
  useUpdateIndirectLaborCloseoutMutation,
  useDeleteIndirectLaborCloseoutMutation,
  useGetIndirectLaborCloseouts,
  useDeleteIndirectLaborChangeOrderCloseoutMutation,
  useGetIndirectLaborChangeOrderCloseouts,
  useUpdateIndirectLaborChangeOrderCloseoutMutation,
} from "../../hooks/useCloseoutHooks";
import { formatCurrency, formatNumber } from "./closeoutHelpers";
import { IndirectLaborCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { Button, TableCell, TableRow, TextField } from "@mui/material";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";

export const IndirectLaborRow: React.FC<IndirectLaborRowProps> = ({
  closeout,
}) => {
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  // Local state for input displays.
  const [percentageOfDirectLaborDisplay, setPercentageOfDirectLaborDisplay] =
    useState<string>(formatNumber(closeout.percentageofdirectlabor));

  const [laborRateDisplay, setLaborRateDisplay] = useState<string>(
    formatCurrency(closeout.laborrate)
  );
  const [burdenPercentDisplay, setBurdenPercentDisplay] = useState<string>(
    formatNumber(closeout.burdenpercent)
  );
  const [fringeDisplay, setFringeDisplay] = useState<string>(
    formatCurrency(closeout.fringe)
  );

  const { mutateAsync: updateEstimateLabor } =
    useUpdateIndirectLaborCloseoutMutation();
  const { mutateAsync: deleteEstimateLabor } =
    useDeleteIndirectLaborCloseoutMutation();
  const { refetch: refetchEstimateLabor } = useGetIndirectLaborCloseouts();

  const { mutateAsync: updateChangeOrderLabor } =
    useUpdateIndirectLaborChangeOrderCloseoutMutation();
  const { mutateAsync: deleteChangeOrderLabor } =
    useDeleteIndirectLaborChangeOrderCloseoutMutation();
  const { refetch: refetchChangeOrderLabor } =
    useGetIndirectLaborChangeOrderCloseouts();

  const updateLabor = selectedEstimate?.estimateid
    ? updateEstimateLabor
    : updateChangeOrderLabor;

  const deleteLabor = selectedEstimate?.estimateid
    ? deleteEstimateLabor
    : deleteChangeOrderLabor;

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimateLabor
    : refetchChangeOrderLabor;

  // Sync local state when the labor prop changes.
  useEffect(() => {
    setPercentageOfDirectLaborDisplay(
      formatNumber(closeout.percentageofdirectlabor)
    );
    setLaborRateDisplay(formatCurrency(closeout.laborrate));
    setBurdenPercentDisplay(formatNumber(closeout.burdenpercent));
    setFringeDisplay(formatCurrency(closeout.fringe));
  }, [closeout]);

  type Field = keyof Pick<
    IndirectLaborCloseout.AsObject,
    "percentageofdirectlabor" | "laborrate" | "burdenpercent" | "fringe"
  >;

  const handleFieldChange = useCallback(
    async (field: Field, value: string) => {
      // Allow only numbers with up to 2 decimals.
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!regex.test(value) || Number(value) > 99999999.99) return;
      await updateLabor(
        {
          ...closeout,
          [field]: value,
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    },
    [closeout, updateLabor, refetch]
  );

  const handleDelete = useCallback(() => {
    deleteLabor(closeout.closeoutid, {
      onSuccess: () => {
        refetch();
      },
    });
  }, [closeout, deleteLabor, refetch]);

  return (
    <TableRow>
      {/* Description */}
      <TableCell>{closeout.name}</TableCell>
      {/* Allocated Hours */}
      <TableCell>{formatNumber(closeout.allocatedhours)}</TableCell>
      {/* Percentage of Direct Labor */}
      <TableCell>
        <TextField
          type="text"
          value={percentageOfDirectLaborDisplay}
          onChange={(e) => setPercentageOfDirectLaborDisplay(e.target.value)}
          onBlur={(e) =>
            handleFieldChange("percentageofdirectlabor", e.target.value)
          }
        />
      </TableCell>
      {/* Labor Rate */}
      <TableCell>
        <TextField
          type="text"
          value={laborRateDisplay}
          onChange={(e) => setLaborRateDisplay(e.target.value)}
          onBlur={(e) => handleFieldChange("laborrate", e.target.value)}
        />
      </TableCell>
      {/* Subtotal */}
      <TableCell>{formatCurrency(closeout.subtotalcost)}</TableCell>
      {/* Burden Percent */}
      <TableCell>
        <TextField
          type="text"
          value={burdenPercentDisplay}
          onChange={(e) => setBurdenPercentDisplay(e.target.value)}
          onBlur={(e) => handleFieldChange("burdenpercent", e.target.value)}
        />
      </TableCell>
      {/* Fringe */}
      <TableCell>
        <TextField
          type="text"
          value={fringeDisplay}
          onChange={(e) => setFringeDisplay(e.target.value)}
          onBlur={(e) => handleFieldChange("fringe", e.target.value)}
        />
      </TableCell>
      {/* Burden Total */}
      <TableCell>{formatCurrency(closeout.burdentotalcost)}</TableCell>
      {/* Fringe Total */}
      <TableCell>{formatCurrency(closeout.fringetotalcost)}</TableCell>
      {/* Full Hourly Cost */}
      <TableCell>{formatCurrency(closeout.totalhourlycost)}</TableCell>
      {/* Full Cost */}
      <TableCell>{formatCurrency(closeout.totalcost)}</TableCell>
      <Button onClick={handleDelete} style={{ width: "40px" }}>
        <DeleteIcon />
      </Button>
    </TableRow>
  );
};
