import {
  Button,
  CircularProgress,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import { ItemTableProps } from "../../../types/FolderTypes";
import { useStore } from "zustand";
import {
  defaultGlobalItem,
  useGlobalItemStore,
} from "../../../states/globalItemStore";
import { GlobalItem } from "../../../api/protosCompiled/globalItem/globalItem_pb";
import {
  useDeleteItem,
  useFetchItemsByFileId,
} from "../../../hooks/useFetchItemsByFileId";
import { useAssemblyStore } from "../../../states/AssemblyStore";
import { useHandleMoveItemOrAssemblyFile } from "../folderManager/helpers";
import { useFolderStore } from "../../../states/folderStore";
import { useUnityBuildStore } from "../../../states/store";
import { Add, Cancel, Delete, Edit, MoveDown } from "@mui/icons-material";
import { TakeoffAssembly } from "../../../types/AssemblyItemType";

export const ItemTable: React.FC<ItemTableProps> = ({ folder }) => {
  const { isMoveMode, setIsMoveMode } = useStore(useFolderStore);
  //react-query states
  const { data: globalItems, isLoading } = useFetchItemsByFileId(
    folder.folderid
  );

  const { selectedBreakout } = useStore(useUnityBuildStore);

  const {
    setCurrentItem,
    setIsViewingItemForm,
    setIsCreatingItem,
  } = useStore(useGlobalItemStore);

  //local states
  const {
    assemblyOption,
    currentGlobalAssembly,
    currentTakeoffAssembly,
    setIsEditMode,
    setCurrentGlobalAssembly,
    setCurrentTakeoffAssembly,
  } = useStore(useAssemblyStore);

  //hooks
  const { mutate: deleteItem } = useDeleteItem();
  const moveCurrentItemFile = useHandleMoveItemOrAssemblyFile();

  const setCurrentAssembly = assemblyOption === "global" ? setCurrentGlobalAssembly : setCurrentTakeoffAssembly;

  // Track unique attribute/item combinations
  const processedCombinations = new Set<string>();

  if (!globalItems || isLoading) {
    return (
      <tr>
        <td colSpan={4}>
          <CircularProgress />
        </td>
      </tr>
    );
  }

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsCreatingItem(false);
    setIsMoveMode(false);
    setCurrentAssembly(null);
  };

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement>,
    item: GlobalItem.AsObject
  ) => {
    e.stopPropagation();
    setIsViewingItemForm(true);
    setIsCreatingItem(false);
    setCurrentItem(item);
    handleCancel(e);
    setIsEditMode(true);
    
  };

  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    item: GlobalItem.AsObject
  ) => {
    e.stopPropagation();
    deleteItem(item.itemid); //in this case it is the file id
    setCurrentItem(defaultGlobalItem);
    handleCancel(e);
  };

  const handleAddItemToAssembly = (
    item: GlobalItem.AsObject,
    assyIdx: number
  ) => {
    setIsViewingItemForm(false);

    if (currentGlobalAssembly) {
      setCurrentGlobalAssembly((prevAssembly) => {
        if (!prevAssembly) return null;

        return {
          ...prevAssembly,
          itemsList: [
            ...(prevAssembly.itemsList || []),
            {
              itemid: item.itemid,
              itemname: item.itemname,
              costs: {
                uomid: 0,
                itemunitcost: 0,
                hourlyproductionrate: 0,
                uomname: "",
                uomvalue: 0,
              },
              itemqtyformula: {
                itemqty: 1,
                forevery: 1,
                takeoffvariabletype: "point",
                and: false,
                or: false,
                itemid: item.itemid,
                formulaid: "",
                isquoted: item.isquoted || false,
                attributevalueid:
                  item.attributesList[assyIdx]?.attributevalueid || 0,
                quotegroupid: "",
              },
              isquoted: false,
              attribute: item.attributesList[assyIdx] || null,
              quotegroup: {
                id: "",
                quotegroup: "",
              },
            },
          ],
        };
      });
    } else if (currentTakeoffAssembly) {
      setCurrentTakeoffAssembly((prevAssembly: TakeoffAssembly | null) => {
        if (!prevAssembly) {
          return null;
        }

        return {
          ...prevAssembly,
          itemsList: [
            ...(prevAssembly.itemsList || []),
            {
              itemid: item.itemid,
              itemname: item.itemname,
              itemqtyformula: {
                formulaid: "",
                itemid: item.itemid,
                isquoted: item.isquoted || false,
                attributevalueid:
                  item.attributesList[assyIdx]?.attributevalueid || 0,
                quotegroupid: "",
                itemqty: 1,
                forevery: 1,
                takeoffvariabletype: "point",
                and: false,
                or: false,
              },
              isquoted: false,
              attribute: item.attributesList[assyIdx] || null,
              quotegroup: {
                id: "",
                quotegroup: "",
              },
              breakout: {
                breakoutmapid: "",
                breakoutid: selectedBreakout?.id || "",
                breakoutname: selectedBreakout?.breakoutname || "",
                multiplier: selectedBreakout?.multiplier || 1,
              },
            },
          ],
        };
      });
    }
  };

  return (
    <>
      {globalItems.map((item: GlobalItem.AsObject) =>
        item.attributesList.map((attribute, assyIdx) => {
          const uniqueKey = `${item.itemname}-${attribute.attributevaluename}`;
          if (processedCombinations.has(uniqueKey)) {
            // Skip duplicate combinations
            return null;
          }
          processedCombinations.add(uniqueKey);

          return (
            <TableRow hover key={uniqueKey} sx={{ zIndex: 10 }}>
              <TableCell>{attribute.attributevaluename || "N/A"}</TableCell>
              <TableCell>{item.itemname}</TableCell>
              <TableCell>{item.costsList[0]?.uomname || "N/A"}</TableCell>
              <TableCell style={{ width: "100px" }}>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleAddItemToAssembly(item, assyIdx);
                    }}
                    sx={{ color: "white" }}
                  >
                    <Add />
                  </Button>

                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(e, item);
                    }}
                  >
                    <Edit />
                  </Button>

                  {!isMoveMode ? (
                    <Button
                      variant="outlined"
                      size="small"
                      color="warning"
                      onClick={(e) => {
                        e.stopPropagation();
                        moveCurrentItemFile({
                          e: e,
                          item: item,
                          entitytype: "item",
                        });
                      }}
                    >
                      <MoveDown />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={handleCancel}
                      type="reset"
                    >
                      <Cancel />
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={(e) => handleDelete(e, item)}
                  >
                    <Delete />
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          );
        })
      )}
    </>
  );
};
