import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AuditTrail } from "./AuditTrail";
import { AiCountManager } from "./AiCountManager";
import { CustomTabPanel, a11yProps } from "../../customTabs/CustomTabPanel";
import { FolderNodes } from "../assembliesAndItems/folderManager/FolderNodes";
import { useFetchAssemblyFolders } from "../../hooks/useFetchFolders";
import { GetFoldersResponse } from "../../api/protosCompiled/folder/folder_pb";
import { useStore } from "zustand";
import Breakouts from "./Breakouts";
import { BreakoutSelector } from "./BreakoutSelector";
import { useAssemblyStore } from "../../states/AssemblyStore";
import { Paper, Typography, Box } from "@mui/material";
import { useUnityBuildStore } from "../../states/store";
import {
  useFetchChangeOrderBreakouts,
  useFetchEstimateBreakouts,
} from "../../hooks/useFetchBreakout";
import { useEffect, useState } from "react";

export default function TakeoffPallette() {
  const currentTakeoffAssembly = useStore(useAssemblyStore).currentTakeoffAssembly;
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  const selectedChangeOrder = useStore(useUnityBuildStore).selectedChangeOrder;
  const userBackgroundColor = useStore(useUnityBuildStore).userBackgroundColor;
  const selectedBreakout = useStore(useUnityBuildStore).selectedBreakout;
  const setSelectedBreakout = useStore(useUnityBuildStore).setSelectedBreakout;

  const [value, setValue] = useState(0);

  const { data: estimateBreakouts, isLoading: isLoadingEstimateBreakouts } =
    useFetchEstimateBreakouts(selectedEstimate?.estimateid || "");
  const {
    data: changeOrderBreakouts,
    isLoading: isLoadingChangeOrderBreakouts,
  } = useFetchChangeOrderBreakouts(selectedChangeOrder?.changeorderid || "");

  const breakout = estimateBreakouts ? estimateBreakouts : changeOrderBreakouts;
  const isLoading = isLoadingEstimateBreakouts || isLoadingChangeOrderBreakouts;

  useEffect(() => {
    if (!selectedBreakout && breakout && !isLoading) {
      setSelectedBreakout(breakout.breakoutsList[0]);
    }
  }, [isLoading, selectedBreakout, breakout, setSelectedBreakout]);

  const { data: folderData } = useFetchAssemblyFolders();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper
      sx={{
        height: "100%",
        backgroundColor: userBackgroundColor,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header */}
      <Typography
        sx={{
          width: "90%",
          p: "10px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        Counting: <strong>{currentTakeoffAssembly?.assemblyname}</strong>
      </Typography>
      <BreakoutSelector size="small" />

      {/* Tabs */}
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        scrollButtons="auto"
        aria-label="takeoff-tabs"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab label="Audit Trail" {...a11yProps(0)} />
        <Tab label="Assemblies" {...a11yProps(1)} />
        <Tab label="Breakouts" {...a11yProps(2)} />
        <Tab label="AI Count (Beta)" {...a11yProps(3)} />
      </Tabs>

      {/* Tab Panels container */}
      <Box sx={{ flexGrow: 1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
        <CustomTabPanel value={value} index={0}>
          <Box sx={{ flexGrow: 1, overflowY: "auto", maxHeight: "100%" }}>
            <AuditTrail />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box sx={{ flexGrow: 1, overflowY: "auto", maxHeight: "100%" }}>
            <FolderNodes
              context="secondary"
              folderType="assembly"
              folderData={folderData as GetFoldersResponse.AsObject}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box sx={{ flexGrow: 1, overflowY: "auto", maxHeight: "100%" }}>
            <Breakouts />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Box sx={{ flexGrow: 1, overflowY: "auto", maxHeight: "100%" }}>
            <AiCountManager />
          </Box>
        </CustomTabPanel>
      </Box>
    </Paper>
  );
}
