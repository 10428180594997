import { useMutation, useQuery, useQueryClient } from "react-query";
import { ItemService } from "../api/GlobalItemService";
import {
  GlobalItem,
} from "../api/protosCompiled/globalItem/globalItem_pb";
import { FolderService } from "../api/FolderService";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../states/store";

export function useFetchItemsByFileId(fileId: string) {
  return useQuery<GlobalItem.AsObject[], Error>(
    ["itemsByFileId", fileId],
    async () => {
      const res = await ItemService.getItemsByFileId(fileId);
      return res.itemsList || [];
    },
    {
      enabled: !!fileId,
      refetchOnWindowFocus: false,
    }
  );
}

export function useCreateOrUpdateItem() {
  const queryClient = useQueryClient();
  const { selectedEstimate } = useStore(useUnityBuildStore);
  return useMutation<void, Error, GlobalItem>(
    async (data: GlobalItem) => {
      await ItemService.createOrUpdateGlobalItem(data);
    },
    {
      onSuccess: (data, variables) => {
        // Refetch items for the parent folder
        const queryKey = ["itemsByFileId", variables.getParentid()];
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries("itemFolders");
        queryClient.invalidateQueries([
          "extension",
          selectedEstimate?.estimateid,
        ]);
      },
      onError: (err) => {
        console.error("Mutation error:", err);
      },
    }
  );
}
export function useDeleteItem() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, string>(
    async (itemId: string) => {
      await FolderService.deleteFolder(itemId);
    },
    {
      onSuccess: (data, itemId) => {
        // Refetch relevant queries
        queryClient.invalidateQueries("itemsByFileId");
        queryClient.invalidateQueries("itemFolders");
      },
      onError: (err) => {
        console.error("Error deleting item:", err);
      },
    }
  );
}
