import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { EquipmentRowProps } from "./closeoutTypes";
import { EquipmentCloseout } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { formatCurrency, formatNumber } from "./closeoutHelpers";
import {
  useDeleteEquipmentChangeOrderCloseoutMutation,
  useDeleteEquipmentCloseoutMutation,
  useGetEquipmentChangeOrderCloseouts,
  useGetEquipmentCloseouts,
  useUpdateEquipmentChangeOrderCloseoutMutation,
  useUpdateEquipmentCloseoutMutation,
} from "../../hooks/useCloseoutHooks";
import { Button, TableCell, TableRow, TextField } from "@mui/material";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";

export const EquipmentRow: React.FC<EquipmentRowProps> = ({ closeout }) => {
  const selectedEstimate = useStore(useUnityBuildStore).selectedEstimate;
  const [quantity, setQuantity] = useState<string>(
    formatNumber(closeout.quantity)
  );

  const [duration, setDuration] = useState<string>(
    formatNumber(closeout.duration)
  );
  const [unitcost, setUnitCost] = useState<string>(
    formatCurrency(closeout.unitcost)
  );

  const { mutateAsync: updateEstimateCloseout } =
    useUpdateEquipmentCloseoutMutation();
  const { mutateAsync: deleteEstimateCloseout } =
    useDeleteEquipmentCloseoutMutation();
  const { refetch: refetchEstimateCloseout } = useGetEquipmentCloseouts();

  const { mutateAsync: updateChangeOrderCloseout } =
    useUpdateEquipmentChangeOrderCloseoutMutation();
  const { mutateAsync: deleteChangeOrderCloseout } =
    useDeleteEquipmentChangeOrderCloseoutMutation();
  const { refetch: refetchChangeOrderCloseout } =
    useGetEquipmentChangeOrderCloseouts();

  const updateCloseout = selectedEstimate?.estimateid
    ? updateEstimateCloseout
    : updateChangeOrderCloseout;

  const deleteCloseout = selectedEstimate?.estimateid
    ? deleteEstimateCloseout
    : deleteChangeOrderCloseout;

  const refetch = selectedEstimate?.estimateid
    ? refetchEstimateCloseout
    : refetchChangeOrderCloseout;

  useEffect(() => {
    setQuantity(formatNumber(closeout.quantity));
    setDuration(formatNumber(closeout.duration));
    setUnitCost(formatCurrency(closeout.unitcost));
  }, [closeout]);

  type Field = keyof Pick<
    EquipmentCloseout.AsObject,
    "quantity" | "duration" | "unitcost"
  >;

  const handleFieldChange = useCallback(
    async (field: Field, value: string) => {
      // Allow only numbers with up to 2 decimals.
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!regex.test(value) || Number(value) > 99999999.99) return;
      await updateCloseout(
        {
          ...closeout,
          [field]: value,
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    },
    [closeout, updateCloseout, refetch]
  );

  const handleDelete = useCallback(() => {
    deleteCloseout(closeout.closeoutid, {
      onSuccess: () => {
        refetch();
      },
    });
  }, [closeout, deleteCloseout, refetch]);

  return (
    <TableRow>
      {/* Description */}
      <TableCell>{closeout.name}</TableCell>
      {/* Quantity */}
      <TableCell>
        <TextField
          type="text"
          value={quantity}
          onBlur={(e) => handleFieldChange("quantity", e.target.value)}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </TableCell>
      {/* Duration */}
      <TableCell>
        <TextField
          type="text"
          value={duration}
          onBlur={(e) => handleFieldChange("duration", e.target.value)}
          onChange={(e) => setDuration(e.target.value)}
        />
      </TableCell>
      {/* Unit Cost */}
      <TableCell>
        <TextField
          type="text"
          value={unitcost}
          onBlur={(e) => handleFieldChange("unitcost", e.target.value)}
          onChange={(e) => setUnitCost(e.target.value)}
        />
      </TableCell>
      {/* Total Cost */}
      <TableCell>{formatCurrency(closeout.totalcost)}</TableCell>
      <Button onClick={handleDelete} style={{ width: "40px" }}>
        <DeleteIcon />
      </Button>
    </TableRow>
  );
};
